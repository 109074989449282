import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectPracticeAppointmentTypes, selectAppointmentTypesErrorMessage } from '../../store/selector/practice-core-selector';
import { invokeGetAppointmentTypes } from '../../store/reducer/practice-core.reducer';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { PracticeAppointmentType } from '../../store/state/practice-core';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';

@Component({
  selector: 'app-appointment-type-dropdown',
  template: `
          <div class="form-group" [class.has-error]="hasError()">
              <label  *ngIf="label">{{ label }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>

              <ng-select 
                [appendTo]="appendTo"
                [items]="appointmentTypes$ | async" 
                [closeOnSelect]="true" 
                [multiple]="isMultiple"
                [searchable]="true" 
                bindLabel="practiceAppointmentName" 
                [placeholder]="placeholder"
                bindValue='practiceAppointmentTypeId' 
                [ngModel]="updatedValue"

                (ngModelChange)="selectBoxChanged($event)"
                (blur)="onTouched()"
              >
              </ng-select>

              <div class="validation_msg" *ngIf="errorMessage$ | async" >
                <span> error : {{errorMessage$ | async}}</span>
              </div>

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="label">
                </app-validation-error>
              </ng-container>
          </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppointmentTypeDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AppointmentTypeDropdownComponent),
      multi: true
    }
  ],
})

export class AppointmentTypeDropdownComponent extends BaseDestroyComponent implements ControlValueAccessor, Validator, OnInit {

  @Input() label!: string;
  @Input() required?: boolean | false;
  @Input() isMultiple!: boolean | false;
  @Output() changeEvent = new EventEmitter<any>();
  @Input() appendTo = "";
  @Input() placeholder!: string;
  
  control: AbstractControl | undefined;
  updatedValue: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled!: boolean;
  appointmentTypes!: PracticeAppointmentType[]
  appointmentTypes$: any = this.store.select((selectPracticeAppointmentTypes));
  errorMessage$ = this.store.select((selectAppointmentTypesErrorMessage));

  constructor(private store: Store, private authService: AuthService) { 
    super();
    this.placeholder = 'Select an Option'
   }

  ngOnInit(): void {
    this.store.dispatch(invokeGetAppointmentTypes({ practiceId: this.authService.getPracticeIDFromToken() }));
    this.subscriptions$.add(this.appointmentTypes$.subscribe((data: PracticeAppointmentType[]) => this.bindAppointmentType(data)));
  }

  bindAppointmentType(data: PracticeAppointmentType[]) {
    if (data) this.appointmentTypes = data;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.updatedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeEvent(event: any) {
    this.control?.markAsTouched();
    this.onChange(event)
  }

  selectBoxChanged(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
    const selectedApptType= this.appointmentTypes.filter(at=>at.practiceAppointmentTypeId === event)[0];
    this.changeEvent.emit(selectedApptType);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

}