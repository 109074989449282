import { Component, ElementRef, NgModule, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { selectChatEventState } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { ChatEventState } from 'src/app/infrastructure/signalr/store/signalr.state';
import { addPatientChatFromSignalR, addRecentPatientChatFromSignalR, closeErrorAlert, invokeRecentPatientChatCollection, updatePatientChatFromMarkAsReadSignalR } from '../../../store/chat-store/chat.action';
import { ChatReadEvent, PatientChat, PatientChatEvent, PatientChatStateEvent, RecentPatientChat } from '../../../store/chat-store/chat.state';
import { DateUtilService } from 'src/app/features/shared/services/date-util.service';
import { selectChatPageStateEvent, selectChatReadEvent, selectChosenPatientChat, selectRecentPatientChats } from '../../../store/chat-store/chat.selector';
import { PhoneNumberMaskRemoverDirective } from 'src/app/features/shared/directive/remove-phonenumber-masking.directive';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { PatientCommunication } from '../../../constant/patient-communication.constant';
import { PatientCommunicationActionService } from '../../../service/patient-communication-action-service';
import { PatientCommunicationPopUpService } from '../../../service/patient-communication-popup-service';
import { CustomValidatorService } from 'src/app/features/shared/utils/custom-validator.service';
import { DraggableComponent } from 'src/app/features/shared/components/draggable/draggable.component';

@Component({
  selector: 'app-chat-dialog-menu',
  templateUrl: './chat-dialog-menu.component.html',
  styleUrls: ['./chat-dialog-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatDialogMenuComponent extends BaseDestroyComponent implements OnInit {
  
  showChatModal = false;
  showPatientChatModal = false;
  isPatientNewChatExist = false;
  hasUnReadChat = false;
  patientCommunicationActivePopupLength: number = 0;
  patientCommunications: PatientCommunication[] = [];
  public recentPatientChat: any[] = [];

  chatEventState$ = this.store.select(selectChatEventState);
  chatPageStateEvent$ = this.store.select(selectChatPageStateEvent);
  patientChatCollection$ = this.store.select(selectChosenPatientChat);
  recentChatState$ = this.store.select(selectRecentPatientChats);
  selectChatReadEvent$ = this.store.select(selectChatReadEvent);
  
  constructor(private store: Store,
    private dateUtilService: DateUtilService,
    private alert: AlertService,
    private patientCommunicationActionService: PatientCommunicationActionService,
    private patientCommunicationPopUpService: PatientCommunicationPopUpService,
    private el: ElementRef, private draggableComponent: DraggableComponent) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.chatPageStateEvent$.subscribe((data?: PatientChatStateEvent) => this.handleChatPageEventState(data)));
    this.subscriptions$.add(this.chatEventState$.subscribe((data?: ChatEventState) => this.bindChatEventState(data)));
    this.subscriptions$.add(this.patientCommunicationActionService.onChatClick$.subscribe((data: PatientCommunication) => this.bindChatClick(data)));
    this.subscriptions$.add(this.patientCommunicationActionService.patientCommunications$.subscribe((data: PatientCommunication[]) => this.bindPatientCommunication(data)));
    this.subscriptions$.add(this.recentChatState$.subscribe((data?: RecentPatientChat[]) => this.bindRecentPatientChats(data)));
    this.subscriptions$.add(this.selectChatReadEvent$.subscribe((data?: ChatReadEvent| null) => this.bindChatReadEvent(data)));
    this.store.dispatch(invokeRecentPatientChatCollection({ pageSize: 0 }));
  }

  async handleChatPageEventState(data: PatientChatStateEvent | undefined) {
    if (!data) return;
    if (data.showErrorAlert) {
      await this.alert.failureAlert(data.errorMessage);
      this.store.dispatch(closeErrorAlert());
    }
  }

  bindPatientCommunication(data: PatientCommunication[]) {
    this.patientCommunicationActivePopupLength = data.length;
    this.patientCommunications = data;
    this.isPatientNewChatExist = (data.filter(_ => _.communicationType === 'PatientNewChat').length > 0);
  }

  bindChatEventState(data?: ChatEventState): void {
    if (data) {
      if (data.event === 'ReceivedSMS' || data.event === 'SendSMS') {
        const patient = data.PatientChatEventState?.patient;
        if (patient) {
          const receivedMessageChat: PatientChat = {
            textMessage: patient.receivedMessage,
            isInbound: (data.event === 'ReceivedSMS') ? true : false,
            time: this.dateUtilService.GetApplicationCurrentDateTime(),
            patientId: patient.patientId,
            practiceId: patient.practiceId,
            isOutbound: (data.event === 'ReceivedSMS') ? false : true,
            isMMS: false,
            patientChatCollectionId: 0,
            isRead: false,
            fileHtml: '',
            fileName: '',
            url: '',
            isArchived: false,
            patientPhone: PhoneNumberMaskRemoverDirective.removePhoneNumberFormatting(patient.patientPhone) ?? ''
          };
          this.store.dispatch(addPatientChatFromSignalR({ patientChat: receivedMessageChat }));
          this.updateRecentChatFromSignalREvent(patient, receivedMessageChat);
        }
      }
      if (data.event === 'MarkAsRead') {
        const patient = data.PatientChatEventState;
        if (patient) {
          const chatReadEvent: ChatReadEvent = {
            phoneNumber: PhoneNumberMaskRemoverDirective.removePhoneNumberFormatting(patient.patientPhone) ?? '',
            patientId: patient.patientId,
          }
          this.store.dispatch(updatePatientChatFromMarkAsReadSignalR({ markAsRead: chatReadEvent }));
        }
      }
    }
  }

  bindChatClick(data: PatientCommunication) {
    const popupExists = (this.patientCommunications.filter(_ => _.patientPrimaryPhone === data.patientPrimaryPhone
      && (_.communicationType === 'PatientChat' || _.communicationType === 'PatientNewChat')).length > 0);
    if (popupExists) return;
    if (data && data.communicationType === 'PatientChat') {
      this.openPatientChatmodal(data, 'PatientChat');
    }
    if (data && data.communicationType === 'PatientNewChat') {
      this.openPatientChatmodal(data, 'PatientNewChat');
    }
  }

  onChatClick() {
    if(this.isPatientNewChatExist) return;
    const patientCommunication: PatientCommunication = {
      communicationType: 'PatientNewChat',
    }
    this.patientCommunicationActionService.onChatClick(patientCommunication);
  }

  openPatientChatmodal(data: PatientCommunication, communicationType: string) {
    if (!this.patientCommunicationPopUpService.allowOpenPopup(this.patientCommunicationActivePopupLength)) return;
    this.patientCommunicationActionService.onAddPatientCommunication(data);
    this.patientCommunicationPopUpService.openPatientCommunicationPopup(communicationType, false, data, this.patientCommunicationActivePopupLength);
  }

  public bindRecentPatientChats(data?: RecentPatientChat[]): void {
    this.recentPatientChat = [];
    if (data === undefined || data === null) return;
    this.recentPatientChat = [...data];
    this.hasUnReadChat = (this.recentPatientChat.filter(_ => _.numberOfUnReadMessages > 0).length > 0);
  }
 
  updateRecentChatFromSignalREvent(apiResponse: PatientChatEvent, receivedMessageChat: PatientChat) {
    const recentPatientChat: RecentPatientChat = {
      patientId: apiResponse.patientId,
      practiceId: apiResponse.practiceId,
      patientFirstName: apiResponse.patientFirstName,
      patientLastName: apiResponse.patientLastName,
      practiceName: null,
      practiceNumber: null,
      callLogApiKey: null,
      textApiKey: null,
      dob: apiResponse.patientDob ?? '',
      primaryPhone: apiResponse.patientPhone,
      emailAddress: apiResponse.patientEmail,
      chatReceivedDate: receivedMessageChat.time,
      numberOfUnReadMessages: receivedMessageChat.isInbound ? 1 : 0,
      textMessage: receivedMessageChat.textMessage,
      isInbound: receivedMessageChat.isInbound,
      isOutbound: receivedMessageChat.isOutbound,
      isMMS: receivedMessageChat.isMMS,
      isArchived: receivedMessageChat.isArchived,
      automatedTemplateName: null
    };
    this.store.dispatch(addRecentPatientChatFromSignalR({ patientChat: recentPatientChat }));
  }

  bindChatReadEvent(data?: ChatReadEvent | null) {
    if (data && this.recentPatientChat) {
      this.recentPatientChat = this.recentPatientChat.map((chat: RecentPatientChat) => {
        if (CustomValidatorService.removeMobileNumberFormatting(chat.primaryPhone) === data.phoneNumber)
          return { ...chat, numberOfUnReadMessages: 0 };
        return { ...chat };
      });
      this.hasUnReadChat = (this.recentPatientChat.filter(_ => _.numberOfUnReadMessages > 0).length > 0);
    }
  }

  ngAfterViewInit() {
    this.draggableComponent.enableDrag(this.el);
  }

}