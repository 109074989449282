import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import { getDocumentCategories, getDocumentCategoriesFailure, getDocumentCategoriesSuccess } from './document-core.reducer';
import { DocumentAPIService } from '../../api/document-api.service';
import { selectDocumentCategories } from './document-core.selector';


@Injectable()
export class DocumentCoreEffect {
  constructor(
    private actions$: Actions,
    private documentAPIService: DocumentAPIService,
    private store: Store
  ) { }

  getDocumentCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getDocumentCategories),
      concatLatestFrom(() => this.store.select((selectDocumentCategories))),
      filter(([, categories]) => !categories || categories.length === 0),
      mergeMap(() => {
        return this.documentAPIService.getDocumentCategories().pipe(
          map((categories) => getDocumentCategoriesSuccess({ documentCategories: categories })),
          catchError(errorMessage => of(getDocumentCategoriesFailure({ errorMessage: errorMessage?.error })))
        )
      })
    )
  }
  );
}