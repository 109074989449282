// Angular Import
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

// project import
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { SharedModule } from './theme/shared/shared.module';
import { headerIconComponent } from './theme/layout/admin/nav-bar/header-icon/header-icon.component';
import { PatientSearchSuggesstionLoaderComponent } from './theme/layout/admin/nav-bar/patient-search-suggestion-loader.component';
import { PatientOverlayWizardLoaderComponent } from './theme/layout/admin/nav-bar/patient-overlay-wizard-loader.component';
import { CoreModule } from './infrastructure/core/core.module';
import { UserFeatureModule } from './features/user/user-feature.module';

import { ThemeModule } from './theme/theme.module';
import { GlobalErrorHandlerService } from './infrastructure/core/services/global-error-handler';
import { RouteChangeService } from './features/shared/services/route-change-service';
import { PatientLiveStatusModule } from './features/patient-live-status/patient-live-status.module';//need to remove the module here
import { PracticeCoreModule } from './features/practice/module/practice-core.module';
import { SharedFeatureModule } from './features/shared/shared-feature.module';
import { PracticeListEffect } from './features/practice/store/effect/practice-list.effect';
import { PracticeListReducer } from './features/practice/store/reducer/practice-list.reducer';
import { InLayService } from './features/shared/components/overlay/inlay.service';
import { PatientDashboardModule } from './features/patientdashboard/patient-dashboard.module';
import { UserFeatureCoreModule } from './features/user/user-feature-core.module';
import { PhoneModule } from './features/patient-communication/component/phone/phone.module';
import { ChatModule } from './features/patient-communication/component/chat/chat.module';

import { PracticeUserRoleAPIService } from './features/practice/api/practice-role-api.service';
import { OnlineSchedulingDashboardReducer } from './features/dashboard/store/online-schedule/online-schedule.reducer';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ConfigurationComponent,
    NavBarComponent,
    NavigationComponent,
    NavLeftComponent,
    NavRightComponent,
    NavSearchComponent,
    NavContentComponent,
    NavItemComponent,
    NavCollapseComponent,
    NavGroupComponent,
    headerIconComponent,
    PatientSearchSuggesstionLoaderComponent,
    PatientOverlayWizardLoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    UserFeatureModule,
    ThemeModule,
    HttpClientModule,
    PatientLiveStatusModule,
    PracticeCoreModule,
    SharedFeatureModule,
    PhoneModule,
    ChatModule,
    UserFeatureCoreModule,
    PatientDashboardModule,
    StoreModule.forFeature('practiceListStore', PracticeListReducer),
    StoreModule.forFeature('OnlineSchedulingDashboardStore', OnlineSchedulingDashboardReducer),
    EffectsModule.forFeature([PracticeListEffect]),
    EffectsModule.forRoot([]),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    DatePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    RouteChangeService,
    InLayService,
    PracticeUserRoleAPIService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
