import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, zip } from 'rxjs';
import { DragEndEvent, DragStartEvent, ReorderEvent } from 'devextreme/ui/sortable';

import {
  getPatientAllergies,
  invokeGetPatientLiveAppointmentsByDate, invokeGetPracticeChairConfigforPatientLiveStatus,
  invokePatientLiveSaveStatusAppointment, patientLiveStatusStartLoading, patientLiveStatusStopLoading,
  removeAppointmentsToliveStatusStore, resetLiveStatusAppointmentsAndChairConfig, resetPatientLiveStatusStateEvents, resetPatientLiveStatusStore,
  updateAppointmentsToliveStatusStore,
  updateHasFutureAppointmentToliveStatusStore
} from '../../store/patient-live-status-store/patient-live-status-action';
import { CommonUtilsService } from 'src/app/features/shared/utils/common-utils.service';
import { selectPatientAllergies, selectPatientAllergiesStateEvents, selectPatientLiveAppointments, selectPatientLiveStatusStateEvents, selectPracticeChairConfigurations } from '../../store/patient-live-status-store/patient-live-status.selector';
import { flowBarAppointmentsSortBy, patientLiveStatusPanel, patientLiveStatusPanels } from '../../constant/patient-live-status-constant';
import { SchedulerService } from 'src/app/features/scheduler/service/scheduler.service';
import { Appointments, SchedulerConfiguration, SchedulerStateEvents } from 'src/app/features/scheduler/store/scheduler-store/scheduler-state';
import { appointmentStatus, appointmentStatusesForRemoving, dayTypes } from 'src/app/features/shared/constant/shared-constant';
import { PatientLiveStatusService } from '../../service/patient-live-status.service';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { checkInStatus } from '../../constant/patient-live-status-constant';
import { resetSignalrStore } from 'src/app/infrastructure/signalr/store/signalr.action';
import { selectScheduleMessage } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { appointmentSignalREvent } from 'src/app/infrastructure/core/constant/signalr.constant';
import { DateUtilService } from 'src/app/features/shared/services/date-util.service';
import moment from 'moment';
import { NavigateURLService } from 'src/app/features/shared/services/navigate-url.service';
import { PatientAllergy, PatientAllergyStateEvent } from '../../store/patient-live-status-store/patient-live-status-state';
import { manageExamOveralyWizardInput } from 'src/app/features/scheduler/store/exam-store/exam-state';
import { PatientStatusInfo } from 'src/app/features/patient/store/state/patient-status.state';
import { selectPracticeFeaturesInfo } from 'src/app/features/practice/store/selector/practice-application-feature.selector';
import { practiceFeaturesInformation } from 'src/app/features/practice/store/state/practice-info';



@Component({
  selector: 'app-patient-live-status',
  templateUrl: './patient-live-status.component.html',
  styleUrls: ['./patient-live-status.component.scss'],
})

export class PatientLiveStatusComponent implements OnInit, OnChanges, OnDestroy {

  patientLiveAppointments!: Appointments[];
  scheduleConfiguration!: SchedulerConfiguration[];
  defaultLiveStatusPanels = patientLiveStatusPanels;
  patientLiveStatusPanels: patientLiveStatusPanel[] = [...this.defaultLiveStatusPanels].filter(stage => stage.value !== appointmentStatus.checkout);
  patientLiveAppointments$ = this.store.select(selectPatientLiveAppointments);
  sheduleConfigurations$ = this.store.select(selectPracticeChairConfigurations);
  scheduleMessage$ = this.store.select(selectScheduleMessage);
  patientLiveStatusStateEvents$ = this.store.select(selectPatientLiveStatusStateEvents);
  selectPatientAllergies$ = this.store.select(selectPatientAllergies);
  selectPatientAllergiesStateEvents$ = this.store.select(selectPatientAllergiesStateEvents);
  practiceFeature$ = this.store.select((selectPracticeFeaturesInfo));

  appointmentStatus = appointmentStatus;
  showSeatedModal = false;
  showPatientAgreement = false;
  showAppointmentSearch = false;
  isLoading = true;
  isAllergyLoading = true;
  practiceId!: number;
  seatedAppointment!: Appointments;
  currentLocationId!: number;
  patientAllergy: PatientAllergy[] = [];
  @Input() locationId!: number;
  private subscriptions$ = new Subscription();
  patientId: number | undefined;
  providerId: number | undefined;
  patientExam!: manageExamOveralyWizardInput;
  practicePolicies: number[] = [];
  nextVistDate: string = '';
  patientStatusId: number | undefined;
  nextVisitAppointmentTypeId: number | undefined;
  patientStatusIds: number[] = [];
  examResultIds: number[] = [];
  selectedPatientName!: string;
  hasMiscPlan!: boolean;
  dismissedAppointmentId = 0;
  isDismissAppointmentLoading= false;
  flowBarSequence = flowBarAppointmentsSortBy.appointmentTime;
  isConfigApiLoaded = false;
  patientLanguagePreference?: string;
  isPatientLiveStatusCheckoutEnabled = false;

  constructor(
    private store: Store,
    private commonUtilsService: CommonUtilsService,
    private schedulerService: SchedulerService,
    private patientLiveStatusService: PatientLiveStatusService,
    private authService: AuthService,
    private dateUtilService: DateUtilService,
    private navigateURLService: NavigateURLService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    let locationId = changes['locationId'] && changes['locationId']['currentValue'];
    if (locationId) {
      this.isConfigApiLoaded = false;
      this.currentLocationId = locationId;
      this.practiceId = parseInt(this.authService.getPracticeIDFromToken());
      this.store.dispatch(resetLiveStatusAppointmentsAndChairConfig());
      this.store.dispatch(patientLiveStatusStartLoading());
      this.store.dispatch(invokeGetPracticeChairConfigforPatientLiveStatus(
        { practiceId: this.practiceId, practiceLocationId: locationId }
      ))

      this.store.dispatch(invokeGetPatientLiveAppointmentsByDate(
        { GetPatientAppointmentsByDate: this.createPostParams(locationId) }
      ));
    }
  }

  ngOnInit() {
    this.subscribeAndHandleUpdates(this.practiceFeature$, this.getPracticeFeatures.bind(this));
    this.practiceId = parseInt(this.authService.getPracticeIDFromToken());
    this.subscriptions$.add(this.scheduleMessage$.subscribe((oModel: any) => { this.appointmentsSignalrUpdate(oModel); }));
    this.subscribeAndHandleUpdates(this.patientLiveAppointments$, this.getPatientLiveAppointments.bind(this));
    this.subscribeAndHandleUpdates(this.patientLiveStatusStateEvents$, this.stateEventChanged.bind(this));
    this.subscribeAndHandleUpdates(this.selectPatientAllergies$, this.bindPatientAllergies.bind(this));
    this.subscribeAndHandleUpdates(this.selectPatientAllergiesStateEvents$, this.bindPatientAllergyStateEvent.bind(this));
  }

  createPostParams(locationId: number) {
    return {
      practiceLocationId: locationId,
      startDate: this.commonUtilsService.formatDate(this.dateUtilService.GetApplicationCurrentDateTime()),
      endDate: this.commonUtilsService.formatDate(this.dateUtilService.GetApplicationCurrentDateTime()),
      pageNumber: 0,
      pageSize: 0
    }
  }

  subscribeAndHandleUpdates<T>(observable: Observable<T>, updateFunction: (data: T) => void) {
    this.subscriptions$.add(observable.subscribe(updateFunction));
  }

  getPatientLiveAppointments(appointments: Appointments[]) {
    if (appointments && this.isConfigApiLoaded) {
      this.assignAppointments(appointments);
    }
  }

  subscribeToConfigurationsAndAppointments() {
    this.subscriptions$.add(zip(this.sheduleConfigurations$, this.patientLiveAppointments$)
      .subscribe(([scheduleConfig, appointments]) => {
        if (scheduleConfig.practiceChairConfigId > 0) {
          this.isConfigApiLoaded = true;
          this.flowBarSequence = scheduleConfig.chairFlowBarSequence;
          if (this.isPatientLiveStatusCheckoutEnabled) {
            this.patientLiveStatusPanels = [...this.defaultLiveStatusPanels];
          }
          else {
            this.patientLiveStatusPanels = [...this.defaultLiveStatusPanels].filter(stage => stage.value !== appointmentStatus.checkout)
          }
          this.assignAppointments(appointments);
          this.store.dispatch(patientLiveStatusStopLoading());
        }
      }));
  }

  assignAppointments(appointments: Appointments[]) {
    this.patientLiveAppointments = appointments;
    this.patientLiveStatusPanels = this.mapPatientLiveAppointment(this.patientLiveAppointments);
    if (!this.isPatientLiveStatusCheckoutEnabled) {
      this.patientLiveStatusPanels = this.patientLiveStatusPanels.filter(stage => stage.value !== appointmentStatus.checkout)
    }
  }

  stateEventChanged(data: SchedulerStateEvents) {
    this.isLoading = data.loading ?? false;
  }

  mapPatientLiveAppointment(appointments: Appointments[]) {
    this.patientLiveStatusPanels = this.patientLiveStatusPanels.map(panel => {
      return { ...panel, appointments: [] };
    });
    appointments.forEach(appointment => {
      const matchingPanel = this.patientLiveStatusPanels.find(panel => panel.value === appointment.patientAppointmentStatusName);
      if (matchingPanel) {
        matchingPanel.appointments.push(appointment);
      }
    });
    this.patientLiveStatusPanels = this.patientLiveStatusPanels.map(panel => {
      return {
        ...panel,
        appointments: panel.appointments.sort((a, b) => {
          if (panel.value === this.appointmentStatus.checkIn && a.patientCheckInTime && b.patientCheckInTime && this.flowBarSequence === flowBarAppointmentsSortBy.checkInTime) {
            return new Date(a.patientCheckInTime).getTime() - new Date(b.patientCheckInTime).getTime();
          }
          return new Date(a.patientAppointmentTime).getTime() - new Date(b.patientAppointmentTime).getTime();
        })
      };
    });
    return this.patientLiveStatusPanels;
  }

  appointmentDragStart(event: DragStartEvent) {
    event.itemData = event.fromData.appointments[event.fromIndex];
  }

  appointmentReorder(event: ReorderEvent) {
    const { fromData, fromIndex, toData, toIndex, itemData } = event;
    fromData.appointments.splice(fromIndex, 1);
    toData.appointments.splice(toIndex, 0, itemData);
  }

  appointmentDrop(event: DragEndEvent) {
    const { fromData, fromIndex, toData, toIndex, itemData } = event;
    this.selectedPatientName = itemData.patientName;
    fromData.appointments.splice(fromIndex, 1);

    toData.appointments.splice(toIndex, 0, itemData);

    const appointmentStatusId = this.schedulerService.mapAppointmentStatusId(toData.value);
    const updatedItemData = { ...itemData, patientAppointmentStatusId: appointmentStatusId };

    if(itemData.patientAppointmentStatusName === this.appointmentStatus.seated && toData.value !== this.appointmentStatus.seated){
      updatedItemData.chairEvent = '';
      updatedItemData.priority = 0;
    }
    if(itemData.patientAppointmentStatusName !== this.appointmentStatus.seated && toData.value === this.appointmentStatus.seated){
      updatedItemData.chairEvent = '';
      updatedItemData.priority = 0;
    }
    if (toData.value === this.appointmentStatus.seated) {
      updatedItemData.actualChairId = updatedItemData.originalChairId;
      this.showSeatedModal = true;
      this.seatedAppointment = updatedItemData;
      return;
    } else if (toData.value === this.appointmentStatus.active || toData.value === this.appointmentStatus.checkIn || toData.value === this.appointmentStatus.checkout) {
      updatedItemData.actualChairId = null;
    } else {
      this.showSeatedModal = false;
    }

    this.checkInFlow(toData, itemData);

    this.dismissFlow(toData, itemData);

    const appointment = this.schedulerService.mapAppointmentModel(updatedItemData);
    this.store.dispatch(invokePatientLiveSaveStatusAppointment({ appointment, action: '' }));
  }

  private checkInFlow(toData: any, itemData: any) {
    if (toData.value === this.appointmentStatus.checkIn) {
      this.patientId = itemData.patientId;
      if (itemData.practicePolicies && itemData.practicePolicies.length > 0) {
        this.showPatientAgreement = true;
        this.practicePolicies = itemData.practicePolicies;
        this.patientLanguagePreference = itemData.patientLanguagePreference;
      }
    }
    else {
      this.showPatientAgreement = false;
      this.practicePolicies = [];
      this.patientLanguagePreference = undefined;
    }
  }

  private dismissFlow(toData: any, itemData: any) {
    if (toData.value === this.appointmentStatus.completed) {
      this.dismissedAppointmentId = itemData.patientAppointmentId;
      this.isDismissAppointmentLoading = true;
    }
    else {
      this.showAppointmentSearch = false;
    }
  }

  private setPropertiesForDismissFlow(itemData: any) {
    this.selectedPatientName = itemData.patientName;
    this.patientStatusIds = itemData.practiceAppointmentTypePatientStatuses;
    this.examResultIds = itemData.patientExamResults;
    this.nextVisitAppointmentTypeId = itemData.nextVisitAppointmentTypeId;
    this.patientStatusId = itemData.patientStatusId;
    this.patientId = itemData.patientId;
    this.providerId = itemData.providerId;
    this.hasMiscPlan = (itemData.hasMiscTreatmentPlan && itemData.practiceTreatmentOptionId) ? true : false;
    this.setExam(itemData);
    this.setNextVisitDate(itemData);
    this.showAppointmentSearch = true;
  }

  private setExam(itemData: any) {
    let exam: manageExamOveralyWizardInput = {
      patientId: itemData.patientId,
      patientExamId: itemData.patientExamId,
      patientAppointmentId: itemData.patientAppointmentId,
      practiceId: itemData.practiceId,
      practiceLocationId: itemData.practiceLocationId,
      patientExamDate: itemData.patientAppointmentTime,
      patientName: itemData.patientName,
      patientTreatmentContractId: 0,
    };
    this.patientExam = exam;
  }

  private setNextVisitDate(itemData: any) {
    const dw = itemData.dw;
    const nvWeeks = itemData.nvprocedureWeeks;
    if (dw && nvWeeks) {
      let searchDate = this.dateUtilService.GetApplicationCurrentDateTime();
      const date = dw === dayTypes.day ? this.dateUtilService.addDaysWithDate(searchDate, nvWeeks) :
        dw === dayTypes.week ? this.dateUtilService.addDaysWithDate(searchDate, (nvWeeks * 7)) :
          this.dateUtilService.addMonthsWithDate(searchDate, nvWeeks);

      this.nextVistDate = date;
    }
    else {
      this.nextVistDate = '';
    }
  }

  viewAppointmentSearch(itemData: any){
    this.setPropertiesForDismissFlow(itemData);
  }

  getPatientTimeStatus(appointmentUtctime: string, checkinTime: string): { difference: string, status: string } {
    return this.patientLiveStatusService.calculateTimeDifference(appointmentUtctime, checkinTime);
  }

  getStatusColor(status: string): string {
    switch (status) {
      case checkInStatus.late:
        return '#ff5370';
      case checkInStatus.early:
        return '#2ed8b6';
      default:
        return '';
    }
  }

  closePopup(willClose: boolean, isSeated: boolean, isAgreement: boolean, isAppointmentSearch: boolean = false) {
    if (willClose) {
      if(isSeated){
        this.showSeatedModal = false;
        this.showSeatedModal = Object.assign({}, false);
      }
      else if(isAgreement){
        this.showPatientAgreement = false;
        this.showPatientAgreement = Object.assign({}, false);
      }
      else if(isAppointmentSearch){
        this.showAppointmentSearch = false;
        this.showAppointmentSearch = Object.assign({}, false);
      }

      this.store.dispatch(resetPatientLiveStatusStateEvents());
    }
    else {
      this.patientLiveStatusPanels = this.mapPatientLiveAppointment(this.patientLiveAppointments);
    }
  }

  patientStatusChanged(patientStatus: PatientStatusInfo){
    const updatedAppointments = this.patientLiveAppointments.map(item => {
      if (item.patientId === patientStatus.patientId) {
        return { ...item, patientStatusId: patientStatus.patientStatusId };
      } else {
        return item;
      }
    });
    this.patientLiveAppointments = updatedAppointments;
    this.patientLiveStatusPanels = this.mapPatientLiveAppointment(this.patientLiveAppointments);
  }

  hasFutureAppointmentUpdate(appointment: any){
    if(this.patientLiveAppointments.find(x=> x.patientId === appointment.patientId)){
      this.store.dispatch(updateHasFutureAppointmentToliveStatusStore({ appointment }));
    }
  }

  appointmentsSignalrUpdate(oModel: any) {

    // Check if the model is valid and corresponds to the appointmentSignalREvent
    if (!oModel || oModel.event !== appointmentSignalREvent) { return; }

    const { data } = oModel;
    if(this.dismissedAppointmentId === data.patientAppointmentId){
      this.dismissedAppointmentId = 0;
      this.isDismissAppointmentLoading = false;
      this.setPropertiesForDismissFlow(data);
    }
    this.hasFutureAppointmentUpdate(data);

    // Check if the practice and location in the data are valid
    if (!this.schedulerService.isValidPracticeAndLocation(this.practiceId, this.locationId, data)) { return; }

    // Find the appointment to be updated in the patientLiveAppointments array
    const appointmentToBeUpdated = this.patientLiveAppointments.find((appt) => appt.patientAppointmentId === data.patientAppointmentId);

    // Check if the appointment time is within the scheduled date
    const isScheduleDate = this.schedulerService.isScheduleDate(
      moment(new Date()).toDate(),
      moment(new Date()).toDate(),
      data.patientAppointmentTime
    );

    if (isScheduleDate) {
      // Handle case when appointment needs to be added or updated
      if (!appointmentToBeUpdated) {
        if (!appointmentStatusesForRemoving.includes(data.patientAppointmentStatusId)) {
          // Add appointment to live status store
          this.store.dispatch(updateAppointmentsToliveStatusStore({ appointment: data }));
        }
      } else {
        // Update or remove appointment based on status
        if (!appointmentStatusesForRemoving.includes(data.patientAppointmentStatusId)) {
          this.store.dispatch(updateAppointmentsToliveStatusStore({ appointment: data }));
          if ((this.isNotNullOrEmpty(appointmentToBeUpdated.chairEvent) || this.isNotNullOrEmpty(data.chairEvent)) &&
            appointmentToBeUpdated.chairEvent !== data.chairEvent) {
            this.store.dispatch(
              invokeGetPatientLiveAppointmentsByDate({
                GetPatientAppointmentsByDate: this.createPostParams(this.currentLocationId),
              })
            );
          }
        } else {
          this.store.dispatch(removeAppointmentsToliveStatusStore({ appointment: data }));
        }
      }
    } else {
      // Handle case when appointment needs to be removed
      if (appointmentToBeUpdated) {
        this.store.dispatch(removeAppointmentsToliveStatusStore({ appointment: data }));
      } else {
        return;
      }
    }
  }

  redirectToPatientSummaryDashboardRoute(patientId: number) {
    return this.navigateURLService.getPatientSummaryURL(patientId);
  }

  isNotNullOrEmpty(value: string): boolean {
    return value !== null && value !== undefined && value !== '';
  }
  bindPatientAllergyStateEvent(data: PatientAllergyStateEvent) {
    this.isAllergyLoading = data.loading ?? false;
  }

  bindPatientAllergies(data: PatientAllergy[]) {
    this.patientAllergy = data.filter(allergy => allergy.allergyName !== 'None of the Above');
  }

  getPatientAllergies(patientId: number) {
    this.store.dispatch(getPatientAllergies({ patientId: patientId }));
  }

  getTitle(title: string) {
    if (this.selectedPatientName) {
      return `${title} <span class='sub-title'> - <span class=" text-primary">${this.selectedPatientName}</span></span>`;
    } else {
      return `${title}`;
    }
  }

  getPracticeFeatures(features: practiceFeaturesInformation) {
    if(features.practiceId > 0) {
      this.isPatientLiveStatusCheckoutEnabled = features.isPatientLiveStatusCheckoutEnabled;
      this.subscribeToConfigurationsAndAppointments();
    }
  }

  ngOnDestroy() {
    this.store.dispatch(resetPatientLiveStatusStore());
    this.store.dispatch(resetSignalrStore());
    this.subscriptions$.unsubscribe();
  }

}