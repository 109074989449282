import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
    AbstractControl, ControlValueAccessor, NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';

@Component({
    selector: 'app-form-control-password-textbox',
    template: `
                <div class="form-group mb-2" [class.has-error]="hasError()">
                    <label [for]="label" *ngIf="label">{{ label }}
                        <span *ngIf="required || requiredField" class="required_field ms-1">*</span>
                    </label>
                    <div class="position-relative">
                        <input 
                            [type]="passwordInputType" 
                            [class]="'form-control w-100 ' + (label ? '' : 'm_t')" 
                            [placeholder]="setPlaceHolder" 
                            [value]="value" 
                            [minLength]="minlength"
                            [maxLength]="maxlength"
                            (input)="onInputEvent($event)"
                            (focus)="onFocusEvent()"
                            [(ngModel)]="value"
                            #inputField="ngModel"
                            (blur)="onBlurEvent()"
                            [id]="label"                            
                            noPrefixWhitespace
                            (keydown)="onKeyDown($event)"
                        />
                        <i class="feather {{ passwordInputType == 'password' ? ' icon-eye' : ' icon-eye-off' }} password-show position-absolute f-16 top-0 right-0 py-2 p-r-30 cursor-pointer" id="togglePassword"
                        *ngIf="value && value.trim() !== '' && inputField?.valid" (click)="togglePasswordEvent()"></i>
                    </div>
                    <ng-container *ngIf="hasError()">
                        <app-validation-error [errors]="control?.errors" [fieldName]="!label ? placeholder : label" [showDefaultMinlengthError]=showDefaultMinlengthError>
                        </app-validation-error>
                    </ng-container>
            </div>
           `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormControlPasswordTextboxComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormControlPasswordTextboxComponent),
            multi: true
        }
    ],
    styles: ['.m_t{margin-top:0.5rem}']
})
export class FormControlPasswordTextboxComponent implements ControlValueAccessor, Validator {

    @Input() label!: string;
    @Input() required?: boolean | false;
    @Input() placeholder?: string;
    @Input() maxlength?: number;
    @Input() minlength?: number;
    @Input() focusPlaceholder?: string;
    @Input() emptyStringToNull = false;
    @Input() requiredField?: boolean | false;
    @Input() showDefaultMinlengthError?: boolean = true;

    setPlaceHolder: string | undefined
    value: any;
    onChange: any = () => { };
    onTouched: any = () => { };
    control: AbstractControl | undefined;
    passwordInputType: string ='password';

    @Output() inputBlur = new EventEmitter<boolean>();
    @Output() onInputChange = new EventEmitter<string>();
    @Output() togglePassword = new EventEmitter<string>();


    constructor() {
        this.label = '';
        this.maxlength = 100;
        this.minlength = 8;
    }

    ngOnInit() {
        this.setPlaceHolder = this.placeholder || this.label;
    }

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        this.control = control;
        return null;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    updateTheValue(event: any) {
        this.value = (event.target as HTMLInputElement).value;
        this.onChange(this.value);
        this.control?.markAsTouched();
    }

    onBlurEvent(): void {
        this.onTouched()
        this.inputBlur.emit();
        this.setPlaceHolder = this.placeholder || this.label;
    }

    onInputEvent(event: any): void {
        this.convertEmptyStringToNull(event);
        this.updateTheValue(event);
        this.onInputChange.emit(this.value);
    }
    togglePasswordEvent(): void {
        this.passwordInputType === 'password' ? this.passwordInputType = 'text' : this.passwordInputType = 'password';
    }

    onFocusEvent(): void {
        this.setPlaceHolder = this.focusPlaceholder || this.placeholder || this.label;
    }

    hasError(): boolean | undefined {
        return this.control?.invalid && (this.control?.touched || this.control?.dirty);
    }

    convertEmptyStringToNull(event?: Event) {
        if (this.emptyStringToNull && event) {
            const value = (event.target as HTMLInputElement).value;
            if (value === '') {
                this.control?.patchValue(null);
            }
        }
    }
    onKeyDown(event: KeyboardEvent) {
        if ((event.key === 'z' && event.ctrlKey)) {
            // Handle Ctrl+Z key presses as needed.
            event.preventDefault(); // Prevent the default behavior if necessary.
        }
    }
}