import { Action } from '@ngrx/store';

export interface HigherOrderReducerConfig {
  loadingProperty: string;
}

type Reducer<S, A extends Action> = (state: S | undefined, action: A) => S;

interface APIResponseAction extends Action {
  errorMessage: string;
}


export function withStartLoading<S, A extends APIResponseAction>(reducer: Reducer<S, A>, config: HigherOrderReducerConfig): Reducer<S, A> {
  return (state: S | undefined, action: A) => {
    const loadingProperty = config.loadingProperty;
    if (action.type.endsWith('start loading')) {
      return {
        ...(state as S),
        [loadingProperty]: {
          loading: true,
        },
      };
    }
    else if (action.type.endsWith('stop loading')) {
      return {
        ...(state as S),
        [loadingProperty]: {
          loading: false,
        },
      };
    }
    else if (action.type.endsWith('API Success')) {
      const modifiedState = {
        ...(state as S),
        [loadingProperty]: {
          loading: false,
        },
      };
      const nextState = reducer(modifiedState, action);

      return nextState;
    }
    else if (action.type.endsWith('API Failure')) {
      const modifiedState = {
        ...(state as S),
        [loadingProperty]: {
          showErrorAlert: true,
          loading: false,
          isFormSubmitted: false,
          errorMessage: action.errorMessage
        },
      };
      const nextState = reducer(modifiedState, action);

      return nextState;
    }
    else if (action.type.endsWith('close error alert')) {
      const modifiedState = {
        ...(state as S),
        [loadingProperty]: {
          showErrorAlert: false,
          loading: false,
        },
      };
      const nextState = reducer(modifiedState, action);

      return nextState;
    }
    else if (action.type.endsWith('close success alert')) {
      const modifiedState = {
        ...(state as S),
        [loadingProperty]: {
          showSuccessAlert: false,
        },
      };
      const nextState = reducer(modifiedState, action);

      return nextState;
    }
    else if (action.type.endsWith('Update form Submit Key')) {
      const modifiedState = {
        ...(state as S),
        [loadingProperty]: {
          isFormSubmitted: false,
        },
      };
      const nextState = reducer(modifiedState, action);

      return nextState;
    }
    else if (action.type.endsWith('API Success with alert message')) {
      const modifiedState = {
        ...(state ?? {}) as S,
        [loadingProperty as keyof S]: {
          ...(state?.[loadingProperty as keyof S] as any),
          showSuccessAlert: true,
          loading: false,
          isFormSubmitted: false,
        },
      };
      const nextState = reducer(modifiedState, action);

      return nextState;
    }
    return reducer(state, action);
  };
}
