import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[printSection]'
})
export class PrintSectionDirective {
  constructor(private el: ElementRef) {
    el.nativeElement.classList.add('printSection');
  }
}

@Directive({
  selector: '[printHide]'
})
export class PrintHideDirective {
  constructor(private el: ElementRef) {
    el.nativeElement.classList.add('printHide');
  }
}

@Directive({
  selector: '[printRemove]'
})
export class PrintRemoveDirective {
  constructor(private el: ElementRef) {
    el.nativeElement.classList.add('printRemove');
  }
}

@Directive({
  selector: '[printOnly]'
})
export class PrintOnlyDirective {
  constructor(private el: ElementRef) {
    el.nativeElement.classList.add('printOnly');
  }
}

@Directive({
  selector: '[printAvoidBreak]'
})
export class PrintAvoidBreakDirective {
  constructor(private el: ElementRef) {
    el.nativeElement.classList.add('avoidPageBreak');
  }
}

@Directive({
  selector: '[printBtn]'
})
export class PrintBtnDirective {
  @Input('printFileName') printFileName: string = '';
  private originalTitle = document.title;
  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.listen(el.nativeElement, 'click', () => {
      setTimeout(() => {
        if(this.printFileName) {
          document.title = this.printFileName || this.originalTitle;
        }
        window.print();
        if(this.printFileName) {
          document.title = this.originalTitle;
        }
      });
    });
  }
}

// @Directive({
//   selector: '[printIf]'
// })
// export class PrintIfDirective {
//   @Input() set appPrintIf(value: boolean) {
//     const method = value ? 'addClass' : 'removeClass';
//     this.renderer[method](this.el.nativeElement, 'printSection');
//   }

//   constructor(private el: ElementRef, private renderer: Renderer2) {}
// }

@Directive({
  selector: '[printLandscape]'
})
export class PrintLandscapeDirective {
  constructor() {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode('@page{size:landscape;}'));
    document.head.appendChild(style);
  }
}