import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { practiceUserConstants } from 'src/app/features/practice/constant/practice-constant';
import { invokePracticeUserCloseErrorAlert, invokePracticeUserCloseSuccessAlert, invokeUserForgotPassword, invokeValidateEmail, resetPracticeUserState } from 'src/app/features/practice/store/user-creation/practice-user.action';
import { selectIsValidEmail, selectPracticeUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.selector';
import { practiceUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.state';
import { AlertService } from 'src/app/features/shared/utils/alert.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotpasswordComponent {
  userEmail: string = "";
  isLoading = false;
  showValidationError= false;
  showInvalidError = false;

  private subscriptions$ = new Subscription();

  selectPracticeUserStateEvent$ = this.store.select(selectPracticeUserStateEvent);
  selectIsValidEmail$ = this.store.select(selectIsValidEmail);  
  constructor(
    private store: Store,
    private alert: AlertService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.subscriptions$.add(this.selectIsValidEmail$.subscribe((data: boolean | undefined) => this.bindIsValidEmail(data)));
    this.subscriptions$.add(this.selectPracticeUserStateEvent$.subscribe((data: practiceUserStateEvent) => this.bindPracticeUserStateEvent(data)));
  }
  async bindPracticeUserStateEvent(data: practiceUserStateEvent) {
    this.isLoading = data.loading!;
    if (data.showSuccessAlert) {
      await this.alert.successAlert(data.successMessage ?? practiceUserConstants.practiceUserForgotPassword);
      this.store.dispatch(invokePracticeUserCloseSuccessAlert());
      this.router.navigateByUrl('/login');
    }
    if (data.showErrorAlert) {
      await this.alert.failureAlert(data.errorMessage);
      this.store.dispatch(invokePracticeUserCloseErrorAlert());
    }
  }
  ngOnDestroy(): void {
    this.showInvalidError = false;
    this.subscriptions$.unsubscribe();
    this.store.dispatch(resetPracticeUserState());
  }
  bindIsValidEmail(data: boolean | undefined){
    this.showInvalidError = (data == undefined || data )? false : true;
    if(data){
      this.showInvalidError = false;
      let successMessage = practiceUserConstants.practiceUserForgotPassword;
      this.store.dispatch((invokeUserForgotPassword({ email: this.userEmail , successMessage: successMessage })));
    }
  }
  onInputChange(event: any){
    this.showInvalidError = false;
    this.showValidationError = !event.target.value;
    this.userEmail = event.target.value;
  }
  forgotPassword() {
    if(!this.userEmail ){
      this.showValidationError  = true;
      return;
    }
    this.store.dispatch((invokeValidateEmail({ email:this.userEmail })));
  }
}
