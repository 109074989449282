import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { StateEvent } from 'src/app/features/shared/types/state-events-info';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { signalrCloseErrorAlert, startSignalRConnection } from 'src/app/infrastructure/signalr/store/signalr.action';
import { selectConnectionStateEvents, selectConnectionStatus } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { signalRConnectionStatus } from 'src/app/infrastructure/core/constant/signalr.constant';
import { featureName, moduleName, operationName } from 'src/app/features/user/constant/user-role-constant';

@Component({
  selector: 'app-signalr-connection-status',
  templateUrl: './signalr-connection-status.component.html',
  styles: ['.signalrStatus{padding:8px;}']
})
export class SignalrConnectionStatusComponent implements OnInit, OnDestroy {

  connectionStatus = {
    status: "",
    shortTerm: "",
    title:"",
    image: ""
  };
  private subscriptions$ = new Subscription();

  connectionStatus$ = this.store.select(selectConnectionStatus);
  connectionStateEvent$ = this.store.select(selectConnectionStateEvents);
  signalRConnectionStatus = signalRConnectionStatus;

  constructor(
    private store: Store,
    private alert: AlertService
  ) { }

  scheduleFeaturePermissionCreate = { moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.create}

  ngOnInit(): void {
    this.subscribeAndHandleUpdates(this.connectionStatus$, this.connectionStatusEvent.bind(this));
    this.subscribeAndHandleUpdates(this.connectionStateEvent$, this.handleStateEvent.bind(this));
  }

  subscribeAndHandleUpdates<T>(observable: Observable<T>, updateFunction: (data: T) => void) {
    this.subscriptions$.add(observable.subscribe(updateFunction));
  }

  connectionStatusEvent(status: string) {
    this.connectionStatus = this.createConnectionStatusObject(status);
  }

  createConnectionStatusObject(status: string) {
    const shortTerm = this.getShortTermKey(status);
    const image = this.getImagePathByStatus(status);

    return {
      status: status,
      shortTerm: shortTerm,
      title:`signalr ${status}`,
      image: image
    };
  }

  getImagePathByStatus(status: string) {
    const imageMap = {
      [signalRConnectionStatus.connected.name]: signalRConnectionStatus.connected.image,
      [signalRConnectionStatus.disconnected.name]: signalRConnectionStatus.disconnected.image,
      [signalRConnectionStatus.reconnecting.name]: signalRConnectionStatus.reconnecting.image,
      [signalRConnectionStatus.connectionError.name]: signalRConnectionStatus.connectionError.image,
      [signalRConnectionStatus.connecting.name]: signalRConnectionStatus.connecting.image,
      [signalRConnectionStatus.disconnecting.name]: signalRConnectionStatus.disconnecting.image
    };

    return imageMap[status] || "";
  }

  getShortTermKey(status: string) {
    const shortTermMap = {
      [signalRConnectionStatus.connected.name]: signalRConnectionStatus.connected.shortTerm,
      [signalRConnectionStatus.disconnected.name]: signalRConnectionStatus.disconnected.shortTerm,
      [signalRConnectionStatus.reconnecting.name]: signalRConnectionStatus.reconnecting.shortTerm,
      [signalRConnectionStatus.connectionError.name]: signalRConnectionStatus.connectionError.shortTerm,
      [signalRConnectionStatus.connecting.name]: signalRConnectionStatus.connecting.shortTerm,
      [signalRConnectionStatus.disconnecting.name]: signalRConnectionStatus.disconnecting.shortTerm
    };

    return shortTermMap[status] || "";
  }

  async handleStateEvent(data?: StateEvent) {
    if (!data) return;
    if (data.showErrorAlert) {
      await this.alert.failureAlert('Signalr Connection-' + data.errorMessage)
      this.store.dispatch(signalrCloseErrorAlert());
    }
  }

  reConnectSignalr() {
    if (this.connectionStatus.status === signalRConnectionStatus.disconnected.name ||
      this.connectionStatus.status === signalRConnectionStatus.connectionError.name) {
      this.store.dispatch(startSignalRConnection());
    }
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}