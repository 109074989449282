<dx-scroll-view class="chair_main_section" direction="vertical" showScrollbar="always">
    <div class="chair_section" [ngClass]="{'condensed_view': chairConfig.chairStyle !== 'Normal View'}" [hidden]="isLoading">
        <ng-container *ngFor="let sheduleConfiguration of chairLiveAppointments; let i = index">
            <div class="chair_section_item" [style.background-image]="colors[i % colors.length].color">
                <div class="chair_section_item_title d-flex align-items-center justify-content-center">
                    <p class="f-w-500">{{sheduleConfiguration.chairName}}</p>
                </div>
                <dx-sortable class="sortable-cards" group="chairGroup" [dropFeedbackMode]="'indicate'"
                    [data]="sheduleConfiguration" (onDragStart)="onTaskDragStart($event)"
                    (onAdd)="onAppointmentDrop($event)">

                    <div class="appt_card card" *ngFor="let appt of sheduleConfiguration.appointments">
                        <div class="appt_type" [ngStyle]="{'background':appt.practiceAppointmentColor}"></div>
                        <div class="d-flex gap-1">
                            <div class="h-100 col-2"
                                *ngIf="appt.chairEvent===actionChairMenuItems.CallDoctor || appt.chairEvent===actionChairMenuItems.CallEmergency">
                                <h1 class="mb-0"
                                    [ngClass]="{'text-primary': appt.chairEvent===actionChairMenuItems.CallDoctor, 
                                                'text-danger': appt.chairEvent===actionChairMenuItems.CallEmergency }">
                                    {{appt.priority}}</h1>
                            </div>
                        <div [ngClass]="appt.chairEvent===actionChairMenuItems.CallDoctor || appt.chairEvent===actionChairMenuItems.CallEmergency ? 'col-10':'col-12'">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="patient_chair_name text-truncate mb-0">{{sheduleConfiguration.chairName}}</div>
                                <div class="d-flex align-items-center gap-1">
                                    <i class="fa fa-solid fa-user f-10"
                                        [ngClass]="{
                                            'text-primary': appt.chairEvent===actionChairMenuItems.CallDoctor,
                                            'text-danger': appt.chairEvent===actionChairMenuItems.CallEmergency,
                                            'text-success': appt.chairEvent===actionChairMenuItems.DoctorIsPresent,
                                            'text-secondary': appt.chairEvent===actionChairMenuItems.Doctorleft}"></i>
                                    <div ngbDropdown #primary="ngbDropdown" container="body">
                                        <i class="feather icon-more-vertical mt-1 dropdown-remove f-10" ngbDropdownToggle
                                            (click)="$event.stopPropagation(); primary.open();"></i>
                                        <div class="mt-2" ngbDropdownMenu aria-labelledby="dropdownManual">
                                            <button class="w-100 m-0" ngbDropdownItem *ngFor="let item of getActionMenuItems(appt)"
                                                (click)="onItemClick(item, appt)">{{item.name}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="patient_appt_name text-truncate chair_patient_name">{{appt.patientName}}
                            </div>
                            <div>
                                <p class="text-truncate mb-0 patient_appt_type f-10">{{appt.practiceAppointmentName}}</p>
                                <p class="patient_appt_type mb-0 f-12" [ngClass]="{'blink_appt_5m': appt.isLastFiveMinutes,
                                                    'blink_appt_2m': appt.isLastTwoMinutes,
                                                    'blink_appt_0m': appt.isTimeEnded}">
                                
                                        (E: <app-timer [startTime]="appt.patientSeatedTime" [isUTCStartTime]="true" ></app-timer>
                                        R: <app-timer [countdown]="true" [startTime]="appt.patientSeatedTime"  [isUTCStartTime]="true" [duration]="appt.patientAppointmentDuration" 
                                         (remainingTickEvent)="onTimeChange($event, appt)" ></app-timer>)
    
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>

                </dx-sortable>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="isLoading">
            <app-patient-live-status-skeleton class="chair_live_status_loader"></app-patient-live-status-skeleton>
    </ng-container>
</dx-scroll-view>