import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    PatientBasicInfoModel,
    PatientNameSearchResultModel, PatientSearchFilterModel, PatientSearchResultModel,
    PracticeLocation, PracticePatientStatus
} from "../types/patient-search-type";
import { patientDashboardApiAssert } from "../constant/patient-dashboard-api-asset";
import { AuthService } from "../../user/services/authentication/auth.service";
import { CommonUtilsService } from "../../shared/utils/common-utils.service";

@Injectable({ providedIn: 'root' })

export class PatientSearchAPIService {

    constructor(private http: HttpClient,
        private authService: AuthService,
        private commonUtilsService: CommonUtilsService
    ) { }

    getPatientSearchData(currentFilter: PatientSearchFilterModel) {
        const param = {
            'patientId': currentFilter.patientId ? currentFilter.patientId : null
            , 'firstName': currentFilter.firstName ? currentFilter.firstName : null
            , 'lastName': currentFilter.lastName ? currentFilter.lastName : null
            , 'patientPreferredName': currentFilter.patientPreferredName ? currentFilter.patientPreferredName : null
            , 'patientPrimaryPhone': currentFilter.patientPrimaryPhone ? this.commonUtilsService.removePhoneNumberFormat(currentFilter?.patientPrimaryPhone) : null
            , 'patientPrimaryEmailAddress': currentFilter.patientPrimaryEmailAddress ? currentFilter.patientPrimaryEmailAddress : null
            , 'patientDOB': currentFilter.patientDOB ? this.commonUtilsService.convertToServerDateFormat(currentFilter.patientDOB) : null
            , 'patientFinancialResponsiblePersonFirstName': currentFilter.patientFinancialResponsiblePersonFirstName ? currentFilter.patientFinancialResponsiblePersonFirstName : null
            , 'patientFinancialResponsiblePersonLastName': currentFilter.patientFinancialResponsiblePersonLastName ? currentFilter.patientFinancialResponsiblePersonLastName : null
            , 'patientStatusId': currentFilter.patientStatusId ? currentFilter.patientStatusId : null
            , 'practiceLocationId': currentFilter.practiceLocationId ? currentFilter.practiceLocationId : null
            , 'sortColumn': currentFilter.SortColumn ? currentFilter.SortColumn : 'patientId'
            , 'sortOrder': currentFilter.SortOrder ? currentFilter.SortOrder : 'asc'
            , 'pageNumber': currentFilter.tackAfter ? currentFilter.tackAfter : 1
            , 'pageSize': currentFilter.pageSize ? currentFilter.pageSize : 10
            , 'showInActivePatients': currentFilter.showInActivePatients ?? false
        };
        return this.http.post<PatientSearchResultModel[]>(patientDashboardApiAssert.searchPatient, param);
    }


    getBulkEmailPatientSearchData(currentFilter: PatientSearchFilterModel) {
        const param = this.mapfilterMapping(currentFilter);
        return this.http.post<PatientSearchResultModel[]>(patientDashboardApiAssert.bulkPatientSearch, param);
    }

    
    getBulkBasicPatientSearchData(currentFilter: PatientSearchFilterModel) {
        const param = this.mapfilterMapping(currentFilter);
        return this.http.post<PatientBasicInfoModel[]>(patientDashboardApiAssert.bulkBasicPatientSearch, param);
    }

    private mapfilterMapping(currentFilter: PatientSearchFilterModel) {
        return {
            'practiceId': this.authService.getPracticeIDFromToken(),
            'patientId': currentFilter.patientId ? currentFilter.patientId : null,
            'firstName': currentFilter.firstName ? currentFilter.firstName : null,
            'lastName': currentFilter.lastName ? currentFilter.lastName : null,
            'patientPreferredName': currentFilter.patientPreferredName ? currentFilter.patientPreferredName : null,
            'patientPrimaryPhone': currentFilter.patientPrimaryPhone ? this.commonUtilsService.removePhoneNumberFormat(currentFilter?.patientPrimaryPhone) : null,
            'patientPrimaryEmailAddress': currentFilter.patientPrimaryEmailAddress ? currentFilter.patientPrimaryEmailAddress : null,
            'patientDOB': currentFilter.patientDOB ? this.commonUtilsService.convertToServerDateFormat(currentFilter.patientDOB) : null,
            'patientFinancialResponsiblePersonFirstName': currentFilter.patientFinancialResponsiblePersonFirstName ? currentFilter.patientFinancialResponsiblePersonFirstName : null,
            'patientFinancialResponsiblePersonLastName': currentFilter.patientFinancialResponsiblePersonLastName ? currentFilter.patientFinancialResponsiblePersonLastName : null,
            'patientStatusId': currentFilter.patientStatusId ? currentFilter.patientStatusId : null,
            'practiceLocationId': currentFilter.practiceLocationId ? currentFilter.practiceLocationId : null,
            'sortColumn': currentFilter.SortColumn ? currentFilter.SortColumn : 'patientId',
            'sortOrder': currentFilter.SortOrder ? currentFilter.SortOrder : 'asc',
            'pageNumber': currentFilter.tackAfter ? currentFilter.tackAfter : 1,
            'pageSize': currentFilter.pageSize ? currentFilter.pageSize : 10,
            'showInActivePatients': currentFilter.showInActivePatients ?? false,
            'filter': currentFilter.filter ? currentFilter.filter : null,
            'fromDate': currentFilter.fromDate ? currentFilter.fromDate : null,
            'toDate': currentFilter.toDate ? currentFilter.toDate : null,
            'fromDays': currentFilter.fromDays ? currentFilter.fromDays : null,
            'toDays': currentFilter.toDays ? currentFilter.toDays : null
        };
    }

    getPatientNameData(firstName?: string, lastName?: string) {
        const param = new HttpParams().set('firstName', firstName ?? '').set('lastName', lastName ?? '');
        return this.http.get<PatientNameSearchResultModel[]>(patientDashboardApiAssert.getPatientByName, { params: param });
    }

    getPracticePatientStatus() {
        const param = { 'practiceId': this.authService.getPracticeIDFromToken() };
        return this.http.get<PracticePatientStatus[]>(patientDashboardApiAssert.getPatientStatuses, { params: param });
    }

    getPracticeLocation() {
        return this.http.get<PracticeLocation[]>(patientDashboardApiAssert.getPracticeLocations);
    }
}