import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[numericDecimalOnly]'
})
export class NumericDecimalOnlyDirective {

    @Output() directiveComplete = new EventEmitter<void>();
    constructor(private el: ElementRef, private control: NgControl) { }
    @HostListener('input', ['$event'])

    onInput(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        let value = input.value;
        value = value.replace(/[^0-9.]/g, '');
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
            const parts = value.split('.');
            value = parts[0] + '.' + parts.slice(1, 2).join('');
        }
        const decimalParts = value.split('.');
        if (decimalParts[1] && decimalParts[1].length > 2) {
            value = decimalParts[0] + '.' + decimalParts[1].substring(0, 2);
        }
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue) && numericValue > 99999.99) {
            value = (Math.floor(numericValue / 10)).toString();
        }
        this.control.control?.setValue(value, { emitEvent: false });
        input.value = value;
        this.directiveComplete.emit();
    }
}