import { createAction, props } from "@ngrx/store";
import { practiceFeaturesInformation } from "../state/practice-info";

export const invokePracticeFeatureConfig = createAction(
    '[GetPracticeFeatureConfig API] Invoke GetPracticeFeatureConfig API',
    props<{ practiceId: number }>()
  );

  export const PracticeFeatureConfigSuccess = createAction(
    '[PracticeFeatureConfigSuccess API] PracticeFeatureConfigSuccess API Success',
    props<{ practiceFeatures: practiceFeaturesInformation }>()
  );
  
  export const PracticeFeatureConfigFailure = createAction(
    '[PracticeFeatureConfigFailure API] PracticeFeatureConfigFailure API Failure',
    props<{ errorMessage: string }>()
  );

  export const clearPracticeApplicationFeatureState = createAction('[Practice Application Feature State] clear state');