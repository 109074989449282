import { Pipe, PipeTransform } from '@angular/core';
import { DateUtilService } from '../services/date-util.service';

@Pipe({
    name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
    transform(inputDateTime: string): string {
        if(!inputDateTime)
             return "";

        const datetime = new Date(inputDateTime);
        if (isNaN(datetime.getDate())) {
            return "Invalid date";
        }
      
        let hours = datetime.getHours();
        const minutes = ('0' + datetime.getMinutes()).slice(-2);
        const ampm = datetime.getHours() >= 12 ? 'PM' : 'AM';
      
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    }
}