import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, map } from 'rxjs';

import { selectLocations, selectLocationStateEvent } from '../../store/selector/practice-core-selector';
import { invokeGetLocationByEmail, invokePracticeUserLocationByPracticeId } from '../../store/reducer/practice-core.reducer';
import { CoreStateEvent, PracticeLocation } from '../../store/state/practice-core';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { AppSessionStorageService } from 'src/app/features/shared/services/app-session-storage.service';
import { AppLocalStorageService } from 'src/app/features/shared/services/app-local-storage.service';
import { applicationStorageKeys } from 'src/app/features/user/constant/user-role-constant';
import { AlertService } from 'src/app/features/shared/utils/alert.service';

@Component({
  selector: 'app-location-dropdown',
  template: `
          <div class="from-control" [class.has-error]="hasError()">
              <label class="mb-2 fw-bold"  *ngIf="label">{{ label }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>
              <ng-select 
                [items]="locations" 
                [closeOnSelect]="true" 
                [multiple]="isMultiple"
                [searchable]="true" 
                [clearable]="clearable"

                bindLabel="practiceLocationName" 
                [placeholder]="!placeholder ? label : placeholder"
                bindValue='practiceLocationId' 
                [ngModel]="updatedValue"
                [loading] = "isLoading"
                [disabled]="disabled"
                (ngModelChange)="selectBoxChanged($event)"
                (blur)="onTouched()"
              >
              </ng-select>
          </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LocationDropdownComponent),
      multi: true
    }
  ],
})

export class LocationDropdownComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {

  @Input() label!: string;
  @Input() required?: boolean | false;
  @Input() isMultiple!: boolean | false;
  @Input() emailId!: string;
  @Input() placeholder?: string;
  @Input() clearable: boolean;
  @Input() showActiveLocation?: boolean = true;
  @Input() loadDefaultLocation?: boolean = true;
  @Input() isLoginPage?: boolean = false;

  @Output() changeEvent = new EventEmitter<any>();
  @Output() successEvent = new EventEmitter<any>();


  control: AbstractControl | undefined;
  updatedValue: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled!: boolean;
  currentpracticeId: string = '';
  isSuperAdmin: boolean = false;
  locations: PracticeLocation[] = [];
  errorMessage: string = "";
  isLoading:boolean = false;

  selectlocations$: any = this.store.select((selectLocations));
  locationStateEvent$: any = this.store.select((selectLocationStateEvent));
  private subscriptions$ = new Subscription();

  constructor(private store: Store, private authService: AuthService, private sessionStorageService: AppSessionStorageService, 
    private appLocalStorageService: AppLocalStorageService, private alert: AlertService, private cdRef: ChangeDetectorRef) {
    this.clearable = false;
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authService.getIsSuperAdminRoleFromToken();
    this.sessionStorageService.getObservable(applicationStorageKeys.selectedPracticeIdBySA).subscribe((value: string) => {
      if (this.isSuperAdmin && this.currentpracticeId !== value) {
          this.currentpracticeId = value;
          this.invokeLocationAPI(value);
      }
    });
    this.appLocalStorageService.getObservable(applicationStorageKeys.loginUserLocationId).subscribe((value: string) => {
        this.setLocation();
    });
    this.initialSetup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['emailId'] && changes['emailId'].currentValue) {
      this.emailId = changes['emailId'].currentValue;
      this.initialSetup();
    }
  }

  initialSetup() {
    this.subscriptions$.add(this.locationStateEvent$.subscribe((data: CoreStateEvent) => { this.bindLocationState(data);  }));

    if (!this.emailId) {
      this.emailId = this.authService.getEmailFromToken();
    }
    this.invokeLocationAPI(this.authService.getPracticeIDFromToken());
  }
  invokeLocationAPI(practiceId: string){
    if (this.isLoginPage && this.emailId) {
      this.store.dispatch(invokeGetLocationByEmail({ email: this.emailId }));
    } else if(practiceId){
      this.store.dispatch(invokePracticeUserLocationByPracticeId({ practiceId: Number(practiceId) }));
    }
    this.subscriptions$.add(this.selectlocations$.subscribe((locations: PracticeLocation[]) => { 
      this.bindLocations(locations);
      this.cdRef.detectChanges();
    }));
  }
 
  async bindLocationState(data: CoreStateEvent){
   this.isLoading = data?.loading;
    if (data?.errorMessage && this.emailId) {
      this.errorMessage = data.errorMessage;
      this.control?.patchValue(null);
      await this.alert.failureAlert(data.errorMessage);
    }
  }
  private bindLocations(locations: PracticeLocation[]) {
    if (locations.length > 0) {
      this.sortLocations(locations);
      this.setLocation();
      this.successEvent.emit(true)
    }
    else {
      this.locations = [];
      if(this.control?.value && this.control?.value.length > 0) {
        this.control?.patchValue(null);
      }
    }
  }
  setLocation() {
    const logginedLocationId = this.authService.getLocationIdFromToken();
    if(logginedLocationId === 0){
      this.control?.patchValue(null);
    }
    if (this.loadDefaultLocation && this.locations.length > 0) {
      const defaultLocationId = logginedLocationId ? logginedLocationId : this.locations[0].practiceLocationId;
      this.control?.patchValue(defaultLocationId);
    }
  }
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.updatedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeEvent(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
  }

  selectBoxChanged(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
    this.changeEvent.emit(event);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  private sortLocations(locations: PracticeLocation[]) {
    if (!locations || locations.length == 0) {
      this.locations = [];
    }
    const isActiveAvailable = locations.some(x => x.isActive !== undefined);
    const locationList = this.showActiveLocation && isActiveAvailable ? locations.filter(x => x.isActive) : locations;

    this.locations = [...locationList].sort((a, b) =>
      a.practiceLocationName!.localeCompare(b.practiceLocationName!)
    );
  }

}