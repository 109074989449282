import { MenuItem } from '../store/menu/menu.state';
import { moduleName,featureName,operationName, roles } from 'src/app/features/user/constant/user-role-constant';

export const DashboardSubMenuItems: MenuItem[] = [
    {
        id: 1,
        title: 'Clinical',
        type: 'item',
        url: '/dashboard/clinical',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/Dashboard.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.all }])
    },
    {
        id: 2,
        title: 'Framework',
        type: 'item',
        url: '/uiframework',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/framework.svg',
        hidden: true
    },
    {
        id: 3,
        title: 'Tracker',
        type: 'item',
        url: '/dashboard/patienttracker',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/tracker.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.serviceContract,featureName:featureName.serviceContract,operationName:operationName.all }])
    },
    {
        id: 4,
        title: 'Financial',
        type: 'item',
        url: '/dashboard/financial/patientOverdue',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/profit.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.paymentManagement,featureName:featureName.paymentPatient,operationName:operationName.all },
                { moduleName:moduleName.paymentManagement,featureName:featureName.paymentOtherThanCard,operationName:operationName.all },
                { moduleName: moduleName.paymentManagement, featureName: featureName.paymentInsurance, operationName: operationName.all }])
    },
    {
        id: 5,
        title: 'Claim',
        type: 'item',
        url:  '/dashboard/insurance/pendingInvoice',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/insurance.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([
                { moduleName:moduleName.insurance,featureName:featureName.insuranceClaim,operationName:operationName.all },
                { moduleName:moduleName.paymentManagement,featureName:featureName.paymentInsurance,operationName:operationName.all },
            ])
    },
    {
        id: 6,
        title: 'Verification',
        type: 'item',
        url: '/dashboard/insuranceverification',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/verification.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.patient,featureName:featureName.insuranceBenefit,operationName:operationName.all }])
    },
    {
        id: 7,
        title: 'Welcome',
        type: 'item',
        url:  '/dashboard/welcome',
        classes: 'nav-item',
        hidden: true
    },  
    {
        id: 8,
        title: 'Bulk Comm',
        type: 'item',
        url: '/dashboard/bulkcommunication',
        classes: 'nav-item',
        icon: 'assets/images/communication-blue.png',
        hiddenFunction:  (userRole, userAuthService, practiceFeatureService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.patient,featureName:featureName.patient,operationName:operationName.all }]) || !practiceFeatureService?.hasPracticeFeaturePermission("isBulkEmailEnabled")
    },

    //We will remove this after the custom stepper component is fully integrated.
    {
        id: 9,
        title: 'Wizard',
        type: 'item',
        url: '/dashboard/wizard',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/framework.svg',
        hidden: true
    },
    {
        id: 10,
        title: 'Task',
        type: 'item',
        url: '/dashboard/task',
        classes: 'nav-item',
        icon: 'assets/images/svg/task.svg',
        hiddenFunction: (userRole, userAuthService, practiceFeatureService) =>
            !userAuthService?.hasPermission([
                { moduleName: moduleName.task, featureName: featureName.taskManagement, operationName: operationName.all }
            ]) || !practiceFeatureService?.hasPracticeFeaturePermission("isTaskEnabled")
    },
];

export const ChatDashboardSubMenuItems: MenuItem[] = [
    {
        id: 1,
        title: 'Chat',
        type: 'item',
        url: '/chat',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/chat.svg',
        hiddenFunction: (userRole, userAuthService) =>
            !userAuthService?.hasPermission([{ moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.create }]),
    }
];
