import { Component, EventEmitter, Injectable, Input, Output, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MASK_PATTERN } from 'src/app/features/shared/constant/shared-pattern-regex-asset';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const parts = value.split(this.DELIMITER);
      if (parts.length === 3) {
        const [month, day, year] = parts.map(part => parseInt(part, 10));
        return { year, month, day };
      }
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? `${this.zeroPadding(date.month)}/${this.zeroPadding(date.day)}/${date.year}`
      : null;
  }

  private zeroPadding(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    const parts = value.split(this.DELIMITER);
    if (parts.length === 3) {
      const [month, day, year] = parts.map(part => parseInt(part, 10));
      return { year, month, day };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? `${this.zeroPadding(date.month)}/${this.zeroPadding(date.day)}/${date.year}`
      : '';
  }

  private zeroPadding(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styles: [
    `
  ::ng-deep .form-control-has-error{
    &.form-control.ng-dirty.ng-invalid, &.has-error .form-control  {background-position: right calc(0.375em + 30px) center !important}
  }
  ::ng-deep .has-error .form-control-has-error  {background-position: right calc(0.375em + 30px) center !important}
  `
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ],
})

export class DatePickerComponent implements ControlValueAccessor, Validator {
  @Input() placeholder!: string;
  @Input() labelName?: string;
  @Input() required: boolean = true;


  @Output() dateChangeEvent = new EventEmitter<any>();
  control: AbstractControl | undefined;
  onChange: any = () => { };
  onTouched: any = () => { };

  dateFormat: string;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  selectedDate: Date | undefined

  constructor() {
    const todayDate = new Date();
    this.dateFormat = MASK_PATTERN.date;
    this.maxDate = { year: new Date().getFullYear(), month: todayDate.getMonth() + 1, day: todayDate.getDate() }
    this.minDate = { year: 1900, month: 1, day: 1 }
  }

  datePickerChangeEvent() {
    this.onChange(this.selectedDate);
    this.dateChangeEvent.emit(this.selectedDate);
    this.onTouched();
  }

  hasError(): boolean | undefined {
     return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
  
    if (this.control?.touched && !this.selectedDate) {
      return { required: true }; 
    }
    if (this.selectedDate) {
      const isValidDateFormat = this.isValidDateFormat(this.selectedDate);
      if (!isValidDateFormat) {
        return { invalidDateFormat: true }; 
      }
    }
    return null;
  }

  writeValue(value: any): void {
    if (value) {
      this.selectedDate = value;
    } else {
      this.selectedDate = undefined;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private isValidDateFormat(value: any): boolean {
    if (typeof value !== 'string') {
      return false;
    }

    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(value);
  }

}
