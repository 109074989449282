<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
  [ngClass]="!minimizePhone ? 'call-state-root' : 'minimize-dailer'" (click)="onRootContainerClick($event)">

  <div class="content h-100" [ngClass]="eCallState === CallStateEnum.DIALPAD ? 'content' : 'content' ">
    <div class="d-flex justify-content-between px-2 py-2" [ngClass]="!minimizePhone ? 'border-bottom' : ''">
      <h6 class="h6 mb-0">Phone </h6>
      <div class="d-flex align-items-center">
        <!-- <i class="feather {{ !minimizePhone ? 'icon-minimize' : ' icon-maximize' }} f-20 cursor-pointer" (click)="minimizePhonePopup()"></i> -->
        <i class="feather icon-x f-20 cursor-pointer" aria-label="Close" (click)="closeModal()"></i>
      </div>
    </div>
     
    <div class="d-flex flex-column h-100" [class.d-none]="minimizePhone">
      <ng-container *ngIf="dialerClick === 'dial'" [ngSwitch]="eCallState">
        <app-call-dialpad *ngSwitchCase="CallStateEnum.DIALPAD" [phoneNumber]="phoneNumber"
          (oCallClicked)="fnCallButtonEmitHandler($event)"></app-call-dialpad>
        <app-call-progress *ngSwitchCase="CallStateEnum.PROGRESS" class="call-progress" [activeContact]="activeContact"
          [phoneNumber]="phoneNumber" [sCallConnectTime]="sCallConnectTime" [sDisplayName]="sDisplayName"
          (oEndCallClicked)="fnCallButtonEmitHandler($event)" [patientId]="patientId"></app-call-progress>
        <app-call-progress *ngSwitchCase="CallStateEnum.INCOMING" class="call-progress" [activeContact]="activeContact"
          [phoneNumber]="phoneNumber" [bIsIncomingCall]="true" (oAcceptCallClicked)="fnAcceptIncomingCallEmitHandler()"
          (oEndCallClicked)="fnDeclineIncomingCallEmitHandler()" [sDisplayName]="sDisplayName"
          [sCallConnectTime]="sCallConnectTime" [patientId]="patientId"></app-call-progress>
        <app-call-progress *ngSwitchCase="CallStateEnum.CALL_IN_PROGRESS" class="call-progress"
          [activeContact]="activeContact" [phoneNumber]="phoneNumber" [bIsCallInProgress]="true" [sDisplayName]="sDisplayName"
          [sCallConnectTime]="sCallConnectTime" (oEndCallClicked)="fnEndCallEmitHandler()" [patientId]="patientId"></app-call-progress>
      </ng-container>
      <ng-container *ngIf="dialerClick === 'recent'">
        <div class="calllog custom-scroll-y ps-0 pe-2 mx-2">
          <ng-container *ngIf="recentLogloading">
            <div class="mt-3">
              <app-lookup-edit-list-skeleton class="remove-card-shadow remove-padding-skeleton" [fields]="4"></app-lookup-edit-list-skeleton>
            </div>
          </ng-container>
          <ng-container *ngIf="!recentLogloading && recentCallLogs.length > 0">
            <div class="d-flex py-2 gap-2 justify-content-between border-bottom" *ngFor="let contact of recentCallLogs">
              <div class="cl-main-us d-flex align-items-center">
                <div class="us-icon-cl">
                  <!-- <img src="assets/images/{{contact.icon}}" width="28" height="28"> -->
                  <i *ngIf="contact.isVoiceMail" class="feather icon-phone-voicemail f-20 text-brown"style="color: #bfa087;"></i>
                  <i *ngIf="contact.isMissedCall " class="feather icon-phone-missed f-20 text-danger"></i>
                  <i *ngIf="contact.isOutgoingCall" class="feather icon-phone-outgoing f-20 text-success"></i>
                  <i *ngIf="contact.isIncomingCall && !contact.isMissedCall " class="feather icon-phone-incoming f-20 text-info"></i>
                </div>
                <div class="cl-text">
                  <h3 class="mb-1">{{contact.phone ? contact.patientName : 'Unknown'}}</h3>
                  <p class="mb-0">{{contact.phone ? contact.phone : 'Unknown'}}</p>
                </div>
              </div>
              <div class="d-flex align-items-center cl-icons gap-1">
                <i class="feather icon-phone f-20 text-primary cursor-pointer" (click)="onCallIconClick(contact)" *applicationFeature="'isVoIpenabled'"></i>
                <i class="feather icon-message-square text-secondary f-20 cursor-pointer" (click)="onChatIconClick(contact)" *applicationFeature="'isChatEnabled'"></i>
                <!-- <div (click)="onCallIconClick(contact)">
                  <img src="assets/images/icons/missed-call.png" width="20" height="20" class="img-fluid">
                </div>
                <div class="col-6">                  
                  <div (click)="onChatIconClick(contact)">
                    <img src="assets/images/icons/chat.png" width="20" height="20" class="img-fluid">
                  </div>
                </div> -->
              </div>
            </div>
          </ng-container>
          <div *ngIf="!recentLogloading && recentCallLogs.length ===0">
            <div class="w-100 text-center p-3">
              <img src="../../../../../assets/images/svg/no-search-icon.svg" class="w-25" />
              <h5 class="ms-2 f-18 mt-2"> No Record Found</h5>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="dialerClick === 'contact'">
        <app-patient-search-suggestion class="search-patient-common" [isFromChat]="true"></app-patient-search-suggestion>
      </ng-container>
  
      <div class="bottommenu mt-auto border-top" [hidden]="isIncomingCall">
        <div class="bmenuitem cursor-pointer" [ngClass]="{'active': dialerClick === 'recent', 'disabled-div': disableRecentButton}" (click)="fnShowData('recent')">
          <i class="fa fa-solid  f-14 fa-history"></i>
          <p class="f-12 mt-1">Recent</p>
        </div>
        <div class="bmenuitem cursor-pointer" [ngClass]="{'active': dialerClick === 'dial'}" (click)="fnShowData('dial')">
          <i class="fa fa-solid f-14 fa-keyboard"></i>
          <p class="f-12 mt-1">Keyboard</p>
        </div>
        <div class="bmenuitem cursor-pointer" [ngClass]="{'active': dialerClick === 'contact', 'disabled-div': disablePatientSearchButton}"
          (click)="fnShowData('contact')">
          <i class="fa fa-solid f-14 fa-user"></i>
          <p class="f-12 mt-1">Patients</p>
        </div>
      </div>
    </div>
 
  </div>
</div>