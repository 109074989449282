import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { selectCallEventState } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { CallEventState } from 'src/app/infrastructure/signalr/store/signalr.state';
import { setDialPadPhoneNumber } from '../../../store/phone-store/phone-call.action';
import { ActiveContactCallEvent } from '../../../store/phone-store/phone-call.state';
import { PatientCommunication } from '../../../constant/patient-communication.constant';
import { PatientCommunicationActionService } from '../../../service/patient-communication-action-service';
import { PatientCommunicationPopUpService } from '../../../service/patient-communication-popup-service';
import { PatientCommunicationCallEventService } from '../../../service/patient-communication-call-event-service';
import { CommonUtilsService } from 'src/app/features/shared/utils/common-utils.service';

@Component({
  selector: 'app-phone-menu',
  templateUrl: './phone-menu.component.html',
  styleUrls: ['./phone-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhoneMenuComponent extends BaseDestroyComponent implements OnInit{
  isDialPopUpExist =false;
  callId = '';
  patientCommunicationActivePopupLength: number = 0;
  patientCommunicationActivePopup: PatientCommunication[] = [];

  callEventState$ = this.store.select(selectCallEventState);

  constructor(private store: Store,
    private patientCommunicationActionService: PatientCommunicationActionService,
    private patientCommunicationPopUpService : PatientCommunicationPopUpService,
    private patientCommunicationCallEventService: PatientCommunicationCallEventService,
    private commonUtilsService: CommonUtilsService,) {
    super();
  } 

  ngOnInit(): void {
    this.subscriptions$.add(this.callEventState$.subscribe((data?: CallEventState) => this.bindCallEventState(data)));
    this.subscriptions$.add(this.patientCommunicationActionService.onCallClick$.subscribe((data: PatientCommunication) => this.bindCallClick(data)));
    this.subscriptions$.add(this.patientCommunicationActionService.patientCommunications$.subscribe((data: PatientCommunication[]) => this.bindPatientCommunicationActivePopup(data)));
  }

  async bindCallEventState(data?: CallEventState) {
    if (data && data.event) {
      if (data.event === 'ReceivedCall') {
        const phoneCall = data.phoneCallEventState;
        const callerId = this.commonUtilsService.formatCallerId(phoneCall!.callerId);

        if (this.validateCallIdExists(phoneCall?.callId ?? '')) return;

        let activeContactCallEvent!: ActiveContactCallEvent
        if (callerId) {
          activeContactCallEvent = {
            primaryPhone: callerId,
            callId: (phoneCall) ? phoneCall.callId : ''
          }
        }
        const isPopupClosed = await this.patientCommunicationPopUpService.closeChatPopupToAcceptIncomingCall();
        if (!isPopupClosed) return;
        this.openPatientCallPopup(true, 'PatientIncomingCall', activeContactCallEvent);
      }
      if (data.event === 'AnsweredCall') {
        this.patientCommunicationCallEventService.onAnsweredCall(data.phoneCallEventState!);
      }
      if (data.event === 'CompletedCall') {
        this.patientCommunicationCallEventService.onCompletedCall(data.phoneCallEventState!);
      }
    }
  }

  validateCallIdExists(callId: string): boolean {
    const iscallIdExists = (this.patientCommunicationActivePopup.filter(_ => _.communicationType === 'PatientIncomingCall' && (_.callId) && _.callId === callId).length > 0);
    return iscallIdExists
  }

  bindPatientCommunicationActivePopup(data: PatientCommunication[]) {
    this.patientCommunicationActivePopup = data;
    this.patientCommunicationActivePopupLength = data.length;
    this.isDialPopUpExist = (data.filter(_ => _.communicationType === 'PatientCallDial').length > 0);
  }

  bindCallClick(data: PatientCommunication) {
    let activeContactCallEvent!: ActiveContactCallEvent
    if (data.patientPrimaryPhone) {
      activeContactCallEvent = {
        primaryPhone: data.patientPrimaryPhone,
        patientLastName: data.patientLastName,
        patientFirstName: data.patientFirstName,
        patientId: data.patientId
      }
      this.store.dispatch(setDialPadPhoneNumber({ activeContactCallEvent: activeContactCallEvent }));
    }
    this.openPatientCallPopup(false, 'PatientCallDial', activeContactCallEvent);
  }

  openPatientCallPopup(isIncomingCall: boolean = false, communicationType?: string, activeContactCallEvent?: ActiveContactCallEvent) {
    if (this.isDialPopUpExist && !isIncomingCall) return;
    if (!this.patientCommunicationPopUpService.allowOpenPopup(this.patientCommunicationActivePopupLength)) return;
    const patientCommunicationActivePopup: PatientCommunication = {
      communicationType: communicationType ?? 'PatientCallDial',
      patientFirstName: activeContactCallEvent?.patientFirstName,
      patientLastName: activeContactCallEvent?.patientLastName,
      patientId: activeContactCallEvent?.patientId,
      patientPrimaryPhone: activeContactCallEvent?.primaryPhone ?? '',
      callId: activeContactCallEvent?.callId
    }
    this.patientCommunicationActionService.onAddPatientCommunication(patientCommunicationActivePopup);
    this.patientCommunicationPopUpService.openPatientCommunicationPopup(patientCommunicationActivePopup.communicationType!, isIncomingCall, activeContactCallEvent, this.patientCommunicationActivePopupLength);
  }
}