import { NAME_VALUE_NUM_TYPE, NAME_VALUE_TYPE } from "../../shared/types/shared-types";

export const CATEGORY_TEYPES: NAME_VALUE_NUM_TYPE[] = [
    { name: 'Date', value: 1 },
    { name: 'Month', value: 2 },
    { name: 'Quarter', value: 3 },
    { name: 'Year', value: 4 },
    { name: 'Custom', value: 5 },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const SERVICECONTRACT_CATEGORY_TYPES = [
    { name: 'All', value: null },
    { name: 'Treatment', value: "Treatment" },
    { name: 'Miscellaneous', value: "Misc" },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const SERVICECONTRACT_CATEGORY = {
    Treatment: 'Treatment',
    Miscellaneous: 'Miscellaneous',
}

export const CATEGORY = {
    Date: 'Date',
    Month: 'Month',
    Quarter: 'Quarter',
    Year: 'Year',
    Custom: 'Custom'
}

export const QUARTER_TYPES: NAME_VALUE_NUM_TYPE[] = [
    { name: 'Q1', value: 1 },
    { name: 'Q2', value: 2 },
    { name: 'Q3', value: 3 },
    { name: 'Q4', value: 4 },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const MONTHS: NAME_VALUE_NUM_TYPE[] = [
    { name: 'January', value: 1, shortName: "Jan" },
    { name: 'February', value: 2, shortName: "Feb" },
    { name: 'March', value: 3, shortName: "Mar" },
    { name: 'April', value: 4, shortName: "Apr" },
    { name: 'May', value: 5, shortName: "May" },
    { name: 'June', value: 6, shortName: "Jun" },
    { name: 'July', value: 7, shortName: "Jul" },
    { name: 'August', value: 8, shortName: "Aug" },
    { name: 'September', value: 9, shortName: "Sep" },
    { name: 'October', value: 10, shortName: "Oct" },
    { name: 'November', value: 11, shortName: "Nov" },
    { name: 'December', value: 12, shortName: "Dec" },
];

export const AGE_GROUP_RANGE: NAME_VALUE_NUM_TYPE[] = [
    { name: '0-10', value: 1 },
    { name: '11-20', value: 2 },
    { name: '21-30', value: 3 },
    { name: '31-40', value: 4 },
    { name: '41-50', value: 5 },
    { name: '51-60', value: 6 },
    { name: '61-70', value: 7 },
    { name: '71-80', value: 8 },
    { name: '81-90', value: 9 },
    { name: '91-100', value: 10 },
    { name: '> 100', value: 11 }
];

export const PRODUCTION_AMOUNT_RANGE: NAME_VALUE_NUM_TYPE[] = [
    { name: '$0-$1000', value: 1 },
    { name: '$1001-$2000', value: 2 },
    { name: '$2001-$3000', value: 3 },
    { name: '$3001-$4000', value: 4 },
    { name: '$4001-$5000', value: 5 },
    { name: '$5001-$6000', value: 6 },
    { name: '$6001-$7000', value: 7 },
    { name: '$7001-$8000', value: 8 },
    { name: '$8001-$9000', value: 9 },
    { name: '$9001-$10000', value: 10 },
    { name: '> $10000', value: 11 }
];

export const TREATMENT_PLAN_RANGE: NAME_VALUE_NUM_TYPE[] = [
    { name: '0 Months - 6 Months', value: 1 },
    { name: '7 Months - 12 Months', value: 2 },
    { name: '13 Months - 18 Months', value: 3 },
    { name: '19 Months - 24 Months', value: 4 },
    { name: '> 2 Years and <= 3 Years', value: 5 },
    { name: '> 3 Years and <= 4 Years', value: 6 },
    { name: '> 4 Years and <= 5 Years', value: 7 }
];

export const AGEGROUP = [
    { name: 'All', value: null },
    { name: 'Adult', value: 'Adult' },
    { name: 'Child', value: 'Child' }
]

export const APPOINTMENTCONFIRM: NAME_VALUE_TYPE[] = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' }
]

export const paymentMethods = [
    { name: "All", value: null },
    { name: "Cash", value: "Cash" },
    { name: "Paper Check", value: "PaperCheck" },
    { name: "Cashier Check", value: "CashierCheck" },
    { name: "Desktop Transaction", value: "DesktopTransaction" },
    { name: "Other Collection", value: "OtherCollection" },
    { name: "Credit or Debit Card", value: "CreditOrDebitCard" },
    { name: "ACH", value: "ACH" },
    { name: "Wallet", value: "Wallet" },
    { name: "Void", value: "Void" },
    { name: "Care Credit", value: "CareCredit" }
]

export const paidBy = [
    { name: "All", value: null },
    { name: "Patient", value: "Patient" },
    { name: "Insurance", value: "Insurance" }
]

export const transactionStatus = [
    { name: "All", value: null },
    { name: "Yet to attempt", value: "Yet to attempt" },
    { name: "Pending ", value: "Pending" },
    { name: "Completed  ", value: "Completed" }
]

export const dueType = [
    { name: "All", value: null },
    { name: "Down Payment", value: "Down Payment" },
    { name: "Due Payment", value: "Due Payment" }
]

export const reportName = {
    dailyInvoice: "dailyinvoice",
    appointmentDetail: "Appointment_Detail_Report",
    appointmentDetailWithPayment: "Appointment_Detail_Report_With_Payment",
    appointmentSummary: "Appointment_Summary_Report",
    examResult: "Exam_Result_Report",
    conversionDeail: "Conversion_Detail_Report",
    conversionSummary: "Conversion_Summary_Report",
    delinquentResult: "Delinquent_Result_Report",
    daySheet: "Day_Sheet_Report",
    receivable: "receivable",
    insuranceAR: "Insurance_Receivable_Report",
    patientAR: "Patient_Receivable_Report",
    monthlyBalanceSheet: "monthlyBalanceSheet",
    doNotPuruseReport: "Do_Not_Puruse_Report",
    referralReport: "Referral_Report",
    edsClaimReport: "Eds_Claim_Report",
    patientStatusReport: "Patient_Status_Report",
    comprehensivePatientReport: 'Comprehensive Patient Report'
}

export const reportMessage = {
    dailyInvoiceDateWarningMessage: "The interval between the 'start' and 'end' dates exceeds 31 days. Please select a different date range which is less than 31 days.",
    exportWarningMessage: "Export is allowed only within 365 days. The date difference between the 'from' and 'to' dates exceeds 365 days. Please choose different dates.",
    startDateEndDateWarningMessage: "Start date must be earlier than the end date."
}

export const abbreviatedMonthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const browserNames = {
    ipadSafari: "ipadSafari"
}

export const sortingValues = {
    desc: 'desc' as const,
    asc: 'asc' as const,
}

export const formatPattern = {
    dateTime: 'MM/dd/yyyy hh:mm a',
    date: 'MM/dd/yyyy',
    time: 'hh:mm a'
}

export const defaultSortColumn = {
    appointmentDetail: "appointmentDateTime",
    appointmentSummary: "practiceAppointmentName",
    examResult: "patientFirstName",
    conversionDetail: "patientFirstName",
    conversionSummary: "practiceAppointmentGroupName",
    treatmentContractDateOfContractSigned:"treatmentContractDateOfContractSigned",
    transactionDate:"transactionDate",
    contractId:"patienttreatmentcontractid",
    claimSubmitDate: "claimSubmitDate"
}

export const reportConst = {
    examResultTxRecommended: "Tx Recommended"
}

export const officeCollectedPaymentMethods = ["Cash", "PaperCheck", "CashierCheck", "DesktopTransaction", "OtherCollection", "Wallet", "CareCredit"]

export const cardCollectedPaymentMethods = ["CreditOrDebitCard", "ACH", "Void"]

export const receivableType = {
    patientar: 'patient',
    insurancear: 'insurance'
}

export const followupStatusTypes = {
    donotpursue: 'Do not pursue',
    declineTreatment: 'Decline Treatment'
}

export const referralByConst = {
    clinicOrDoctor: "Clinic or Doctor",
    friendsOrFamily: "Friends or Family"
}

export const yesOrNoConstant = {
    yes: "Yes",
    no: "No"
}

export const daySummaryTooltip = {
    totalDeposit: '(Total Office Collection + Total Card Collection + Total Insurance)',
    totalDepositWithoutOffice: '(Total Card Collection + Total Insurance)',
    totalDepositWithoutCard: '(Total Office Collection + Total Insurance)'
}

export const TREATMENT_STATUS = [
    { name: 'All', value: null },
    { name: 'Active', value: 'Active' },
    { name: 'Dismissed', value: 'Dismissed' },
]

export const PATIENT_STATUS = [
    { name: 'All', value: null },
    { name: 'Active', value: 'Active' },
    { name: 'Inactive', value: 'Inactive' }
]

export const paymentType = {
    wallet: "wallet",
    interest: "interest"
}

export const transactionCategory = {
    refund: "refund"
}

export const AGE_RANGES = {
    1: { minAge: 0, maxAge: 10 },
    2: { minAge: 11, maxAge: 20 },
    3: { minAge: 21, maxAge: 30 },
    4: { minAge: 31, maxAge: 40 },
    5: { minAge: 41, maxAge: 50 },
    6: { minAge: 51, maxAge: 60 },
    7: { minAge: 61, maxAge: 70 },
    8: { minAge: 71, maxAge: 80 },
    9: { minAge: 81, maxAge: 90 },
    10: { minAge: 91, maxAge: 100 },
    11: { minAge: 101, maxAge: null }
  };

  export const PRODUCTION_AMOUNT_RANGE_MAP: { [key: number]: { minAmount: number, maxAmount: number | null } } = {
    1: { minAmount: 0, maxAmount: 1000 },
    2: { minAmount: 1001, maxAmount: 2000 },
    3: { minAmount: 2001, maxAmount: 3000 },
    4: { minAmount: 3001, maxAmount: 4000 },
    5: { minAmount: 4001, maxAmount: 5000 },
    6: { minAmount: 5001, maxAmount: 6000 },
    7: { minAmount: 6001, maxAmount: 7000 },
    8: { minAmount: 7001, maxAmount: 8000 },
    9: { minAmount: 8001, maxAmount: 9000 },
    10: { minAmount: 9001, maxAmount: 10000 },
    11: { minAmount: 10001, maxAmount: null }
  };

  export const TREATMENT_PLAN_DURATION_MAP: { [key: number]: { minDuration: number, maxDuration: number } } = {
    1: { minDuration: 0, maxDuration: 6 },
    2: { minDuration: 7, maxDuration: 12 },
    3: { minDuration: 13, maxDuration: 18 },
    4: { minDuration: 19, maxDuration: 24 },
    5: { minDuration: 25, maxDuration: 36 },
    6: { minDuration: 37, maxDuration: 48 },
    7: { minDuration: 49, maxDuration: 60 },
  };