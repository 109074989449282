import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { REGEX_PATTERN } from '../constant/shared-pattern-regex-asset';

@Directive({
    selector: '[appRemovePhoneNumberMaskingCharacters]',
})
export class PhoneNumberMaskRemoverDirective {
    @Input() formGroup: FormGroup | null = null;

    static checkPhoneNumberMasking(property: any): boolean {
        const phoneNumberPattern = REGEX_PATTERN.phoneNumber;
        return phoneNumberPattern.test(property);
    }

    static removePhoneNumberFormatting(phonenumber: string): string | null {
        return phonenumber ? phonenumber.replace(/\D/g, '') : null;
    }

    @HostListener('submit', ['$event'])
    onSubmit(event: Event) {
        if (this.formGroup?.valid) {
            const formValue = this.formGroup.value;
            for (const propertyName of Object.keys(formValue)) {
                const property = formValue[propertyName];
                if (property && PhoneNumberMaskRemoverDirective.checkPhoneNumberMasking(property)) {
                    this.formGroup.patchValue({
                        [propertyName]: PhoneNumberMaskRemoverDirective.removePhoneNumberFormatting(property)
                    });
                }
            }
        }
    }
}