import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { invokeGetUserEmailByUserID, invokePracticeUserCloseErrorAlert, invokePracticeUserCloseSuccessAlert, invokeUserResetPassword, resetPracticeUserState } from 'src/app/features/practice/store/user-creation/practice-user.action';
import { selectCurrentUserEmail, selectPracticeUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.selector';
import { ResetPassword, practiceUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.state';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { practiceUserConstants } from 'src/app/features/practice/constant/practice-constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidatorService } from 'src/app/features/shared/utils/custom-validator.service';
import {passwordGuidelines} from '../../../constant/login-constant';
import { AuthService } from '../../../services/authentication/auth.service';
import { UserRoleService } from '../../../services/user-role.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetpasswordComponent {
  userId: string | null = null;
  code: string | null = null;
  userEmail: string = "";
  passwordForm: FormGroup;
  showValidationError = false;
  newPassword: string | undefined;
  reEnterPassword: string | undefined;
  showMisMatchPasswordError = false;
  showNewPasswordError = false;
  resetpasswordRequest!: ResetPassword | undefined;
  passwordGuidelines : string[] = [];

  private subscriptions$ = new Subscription();
  selectPracticeUserStateEvent$ = this.store.select(selectPracticeUserStateEvent);
  selectCurrentUserEmail$ = this.store.select(selectCurrentUserEmail);


  constructor(private route: ActivatedRoute, private store: Store, private alert: AlertService, 
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userRoleService: UserRoleService) {
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'] || null;
      this.code = params['code'] || null;
    });
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidatorService.emailValidator()]],
      newPassword: ['', [Validators.required, CustomValidatorService.passwordValidator()]],
      reEnterPassword: ['', [Validators.required, CustomValidatorService.passwordValidator()]],
    });
    this.passwordGuidelines = passwordGuidelines;
  }
  ngOnInit() {
    this.store.dispatch(invokeGetUserEmailByUserID({ userId: this.userId! }));
    this.subscriptions$.add(this.selectCurrentUserEmail$.subscribe((data: any) =>  this.bindUserEmail(data)));
    this.subscriptions$.add(this.selectPracticeUserStateEvent$.subscribe((data: practiceUserStateEvent) => this.bindPracticeUserStateEvent(data)));
  }
  bindUserEmail(data: any) {
    if (data) {
      this.passwordForm.patchValue({
        email: data.email
      });
    }
  }
  async bindPracticeUserStateEvent(data: practiceUserStateEvent) {
    if (data.showSuccessAlert) {
      await this.alert.successAlert(data.successMessage ?? practiceUserConstants.practiceUserChangePassword);
      this.store.dispatch(invokePracticeUserCloseSuccessAlert());
      this.userRoleService.loadLandingPageByUserRolePermission(this.authService.getUserRoleFromToken())
    }
    if (data.showErrorAlert) {
      await this.alert.failureAlert(data.errorMessage);
      this.store.dispatch(invokePracticeUserCloseErrorAlert());
    }
  }
  ngOnDestroy(): void {
    this.showValidationError = false;
    this.subscriptions$.unsubscribe();
    this.store.dispatch(resetPracticeUserState());
  }
  submitChangePassword() {
    if (this.passwordForm.valid && !this.showMisMatchPasswordError && !this.showNewPasswordError) {
      let successMessage = practiceUserConstants.practiceUserChangePassword;
      this.resetpasswordRequest = {
        email : this.passwordForm.value.email,
        password: this.passwordForm.value.newPassword,
        token:  this.code!
      }
    this.store.dispatch(invokeUserResetPassword({ resetPassword: this.resetpasswordRequest , successMessage: successMessage }));
    }
  }
  newPasswordChange(event:string){
    if(this.reEnterPassword)
      this.showMisMatchPasswordError = event != this.reEnterPassword
  }
  reEnterPasswordChange(event:string){
    this.reEnterPassword = event;
    this.showMisMatchPasswordError = event != this.passwordForm.value.newPassword
  }
  togglePassword(){
  }
}
