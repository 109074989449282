import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { Appointment, Appointments, ScheduleChairConfiguration, SchedulerStateEvents } from 'src/app/features/scheduler/store/scheduler-store/scheduler-state';
import { selectPatientLiveStatusStateEvents, selectPracticeChairs } from '../../store/patient-live-status-store/patient-live-status.selector';
import { SchedulerService } from 'src/app/features/scheduler/service/scheduler.service';
import { invokePatientLiveSaveStatusAppointment } from '../../store/patient-live-status-store/patient-live-status-action';
import { appointmentStatus, appointmentStatusID } from 'src/app/features/shared/constant/shared-constant';
import { chairStatusMessages } from '../../constant/patient-live-status-constant';

@Component({
  selector: 'app-seated-patient-status-modal',
  templateUrl: './seated-patient-status-modal.component.html',
})
export class SeatedPatientStatusModalComponent implements OnInit, OnDestroy {

  patientAppointmentForm!: FormGroup;
  sheduleConfigurations!: ScheduleChairConfiguration[];
  isFormSubmitted =false;
  sheduleConfigurations$ = this.store.select(selectPracticeChairs);
  PatientLiveStatusStateEvents$ = this.store.select(selectPatientLiveStatusStateEvents);
  IsAppointmentExistInsameChair = false;
  chairWarningMessage = chairStatusMessages.appointmentExistsAlertMessage

  @Input() appointment!: Appointment;
  @Output() popupCloseEvent = new EventEmitter<boolean>();
  @Input() patientAppointments!: Appointments[]
  private subscriptions$ = new Subscription();


  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private schedulerService: SchedulerService,
  ) {
    this.patientAppointmentForm = this.formBuilder.group({
      position: [null, Validators.required]
    })
  }

  ngOnInit() {
    this.IsAppointmentExistInsameChair = this.patientAppointments.filter((appointment: Appointment) => appointment.patientAppointmentStatusId === appointmentStatusID.seated).
      some((appointment: Appointment) => appointment.actualChairId === this.appointment.originalChairId);
    this.patientAppointmentForm.patchValue({ position: this.appointment.originalChairId })
    this.subscribeAndHandleUpdates(this.sheduleConfigurations$, this.scheduleConfig.bind(this));
    this.subscribeAndHandleUpdates(this.PatientLiveStatusStateEvents$, this.stateEventChanged.bind(this));
  }

  subscribeAndHandleUpdates<T>(observable: Observable<T>, updateFunction: (data: T) => void) {
    this.subscriptions$.add(observable.subscribe(updateFunction));
  }

  scheduleConfig(scheduleConfig: ScheduleChairConfiguration[]) {
    if (scheduleConfig) {
      this.sheduleConfigurations = scheduleConfig;
    }
  }

  saveAppointment() {
    if (this.IsAppointmentExistInsameChair) return;
    this.appointment.actualChairId = this.patientAppointmentForm.value.position;
    const appointment = this.schedulerService.mapAppointmentModel(this.appointment);
    const action = appointment.patientAppointmentStatusId === appointmentStatusID.seated ? appointmentStatus.seated : "";
    this.store.dispatch(invokePatientLiveSaveStatusAppointment({ appointment, action: action }));
    this.schedulerService.navigateToTXCard(this.appointment.patientId);
  }

  async stateEventChanged(data: SchedulerStateEvents) {
    if (!data.loading && data.actionType == appointmentStatus.seated) {
      this.popupCloseEvent.emit(true)
    }
    if (data) {
      this.isFormSubmitted = data.isFormSubmitted ?? false;
    }
  }

  chairChanged(chairId: number) {
    this.IsAppointmentExistInsameChair = this.patientAppointments.filter((appointment: Appointment) => appointment.patientAppointmentStatusId === appointmentStatusID.seated)
      .some((appointment: Appointment) => appointment.actualChairId === chairId);
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}