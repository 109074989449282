import { createSelector, createFeatureSelector } from '@ngrx/store';
import { PhoneCallState } from './phone-call.state';

// Selector to get the feature state
export const selectPhoneCallState = createFeatureSelector<PhoneCallState>('phoneCallStore');

// Selectors for specific pieces of state
export const selectDialCallToken = createSelector(
  selectPhoneCallState,
  (state: PhoneCallState) => state.dialCallInfo
);

export const selectPhoneStateEvent = createSelector(
  selectPhoneCallState,
  state => state.phoneCallStateEvent
);

export const selectActiveContactCallEvent = createSelector(
  selectPhoneCallState,
  state => state.activeContactCallEvent
);

export const selectRecentcall = createSelector(
  selectPhoneCallState,
  (state: PhoneCallState) => state.recentCallLogs
);

export const selectOnCallInprogressphoneNumber = createSelector(
  selectPhoneCallState,
  (state: PhoneCallState) => state.onCallInprogressphoneNumber
);