<app-card [cardTitle]="'Additional Information'">

    <form [formGroup]="aditionalInformation">

        <div class="row">

            <div class="col-lg-3">
                <app-form-control-textbox label="Preferred Name" focusPlaceholder="John" formControlName="preferredName"
                    [maxlength]="101" [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-3">
                <app-form-control-textbox label="Secondary Email" formControlName="secondaryEmail" [maxlength]="101"
                    [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-3">
                <app-form-control-textmask label="Phone" formControlName="secondaryPhone"
                    [appNumberMask]="maskingType.Phone" placeholder="Phone" [required]="true">
                </app-form-control-textmask>
            </div>

            <div class="col-lg-12">
                <div class="border bg-waring p-2">
                    <pre class="mb-0">{{this.aditionalInformation.value | json}}</pre>
                    <div>
                        <span class="fw-bold">Form Status : </span>
                        <span class="fw-bold"
                            [ngClass]="{'text-danger':!this.aditionalInformation.valid,'text-success': this.aditionalInformation.valid}">
                            {{this.aditionalInformation.status}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </form>

</app-card>