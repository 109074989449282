import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChatHistoryService {

    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    public formatDateToDDMonthYYYY(date: Date): string {
        return `${date.getDate()}, ${this.months[date.getMonth()]} ${date.getFullYear()}`;
    }

    public getChatSeparatorByDate(date: Date): string {
        const currentDate = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const oneWeek = oneDay * 7;
        const oneMonth = oneDay * 30; // Approximation for a month

        // Check for dates before this year
        if (currentDate.getFullYear() !== date.getFullYear()) {
            return this.getDayNameFromDate(date) + ', ' + this.formatMonthDay(date).split(' ')[0] + ', ' + this.formatMonthDay(date).split(' ')[1] + ' ' + date.getFullYear();
        }
        // Check for Today
        if (Math.abs(currentDate.getTime() - date.getTime()) < oneDay) {
            return 'Today';
        }
        // Check for Yesterday
        currentDate.setDate(currentDate.getDate() - 1);
        if (Math.abs(currentDate.getTime() - date.getTime()) < oneDay) {
            return 'Yesterday';
        }
        // Default case
        return this.getDayNameFromDate(date) + ', ' + this.formatMonthDay(date).split(' ')[0] + ', ' + this.formatMonthDay(date).split(' ')[1];
    }

    public getDayNameFromDate(date: Date): string {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[date.getDay()];
    }

    public formatMonthDay(date: Date): string {
        const month = this.months[date.getMonth()];
        const day = date.getDate();
        return month + ' ' + day;
    }

    getFormattedDateWithTime(date: Date): string {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;

        const formattedTime = `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;

        return formattedTime;
    }

}