import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { selectPatientLiveStatusStateEvents, selectPracticeLiveStatusLocation } from '../../store/patient-live-status-store/patient-live-status.selector';
import { PracticeLocation, SchedulerStateEvents } from 'src/app/features/scheduler/store/scheduler-store/scheduler-state';
import { invokeGetPracticeLocationsforLiveStatus } from '../../store/patient-live-status-store/patient-live-status-action';
import { PatientLiveStatusService } from '../../service/patient-live-status.service';
import { PatientLiveStatusSubscriptionService } from '../../service/patient-live-status-subscription.service';
import { selectConnectionStatus, selectSignalrScheduleErrorStateEvent } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { StateEvent } from 'src/app/features/shared/types/state-events-info';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { signalrCloseScheduleErrorAlert, startSignalRConnection } from 'src/app/infrastructure/signalr/store/signalr.action';
import { signalRConnectionStatus } from 'src/app/infrastructure/core/constant/signalr.constant';
import { AppLocalStorageService } from 'src/app/features/shared/services/app-local-storage.service';
import { applicationStorageKeys } from 'src/app/features/user/constant/user-role-constant';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';

@Component({
  selector: 'app-live-status-location',
  templateUrl: './live-status-location.component.html',
  styles: [`.subscription_status_section{position: absolute; top: 8px; right: 10px;z-index:1001}.live_location{z-index: 0}`]
})
export class LiveStatusLocationComponent implements OnInit, OnDestroy {

  liveLocation: FormGroup;
  locationId!: number;
  practiceLocation: PracticeLocation[] = [];
  isLoading: boolean = false;
  connectionStatus = "";
  @Output() liveLocationChanged = new EventEmitter();

  practiceLocation$ = this.store.select(selectPracticeLiveStatusLocation);
  patientLiveStatusStateEvents$ = this.store.select(selectPatientLiveStatusStateEvents);
  signalrScheduleErrorStateEvent$ = this.store.select(selectSignalrScheduleErrorStateEvent);
  connectionStatus$ = this.store.select(selectConnectionStatus);

  private subscriptions$ = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private patientLiveStatusService: PatientLiveStatusService,
    private patientLiveStatusSubscriptionService: PatientLiveStatusSubscriptionService,
    private alert: AlertService,
    private authService: AuthService,
    private appLocalStorageService: AppLocalStorageService
  ) {
    this.store.dispatch(startSignalRConnection());
    this.store.dispatch(invokeGetPracticeLocationsforLiveStatus());
    this.liveLocation = this.formBuilder.group({
      locationId: [null]
    });
  }

  ngOnInit() {
    this.subscribeAndHandleUpdates(this.practiceLocation$, this.getPracticeLocation.bind(this));
    this.subscribeAndHandleUpdates(this.patientLiveStatusStateEvents$, this.stateEventChanged.bind(this));
    this.subscribeAndHandleUpdates(this.patientLiveStatusStateEvents$, this.signalrSchedulerStateEventChange.bind(this));
    this.subscribeAndHandleUpdates(this.connectionStatus$, this.connectionStatusEvent.bind(this));
    this.appLocalStorageService.getObservable(applicationStorageKeys.loginUserLocationId).subscribe((value: string) => {
      this.initiateLocationComponent(value);
    });
  }

  initiateLocationComponent(loginLocationId: string) {
    const liveLocationId = this.patientLiveStatusService.getLocationId();
    this.locationId = liveLocationId ? liveLocationId : Number(loginLocationId);
    this.patientLiveStatusService.updateLocationIdToStorage(this.locationId);
    if (this.locationId) {
      this.liveLocation.patchValue({ locationId: this.locationId });
      this.liveLocationChanged.emit(this.liveLocation.value.locationId);
    }
  }

  subscribeAndHandleUpdates<T>(observable: Observable<T>, updateFunction: (data: T) => void) {
    this.subscriptions$.add(observable.subscribe(updateFunction));
  }

  async signalrSchedulerStateEventChange(data?: StateEvent) {
    if (!data) return;
    if (data.showErrorAlert) {
      await this.alert.failureAlert(data.errorMessage)
      this.store.dispatch(signalrCloseScheduleErrorAlert());
    }
  }

  getPracticeLocation(locations: PracticeLocation[]) {
    if (locations) {
      if (locations.length === 0) return;
      this.practiceLocation = [...locations].sort((a, b) =>
        a.practiceLocationName!.localeCompare(b.practiceLocationName!)
      );
      if (this.connectionStatus === signalRConnectionStatus.connected.name && this.locationId)
        this.patientLiveStatusSubscriptionService.manageSignalRLocationSubscribtion(this.locationId, true);
   
      if(!this.practiceLocation.find(x => x.practiceLocationId === this.locationId)){
        this.updateToLoginLocation();
      }
    }
  }

  private updateToLoginLocation() {
    this.locationId = this.authService.getLoginLocationIdFromToken();
    if (this.locationId) {
      this.patientLiveStatusService.updateLocationIdToStorage(this.locationId);
      this.liveLocation.patchValue({ locationId: this.locationId });
      this.liveLocationChanged.emit(this.liveLocation.value.locationId);
    }
  }

  connectionStatusEvent(status: string) {
    this.connectionStatus = status;
    if (this.connectionStatus === signalRConnectionStatus.connected.name && this.locationId) {
      this.patientLiveStatusSubscriptionService.manageSignalRLocationSubscribtion(this.locationId, false);
      this.patientLiveStatusSubscriptionService.manageSignalRLocationSubscribtion(this.locationId, true);
    }
  }

  stateEventChanged(data: SchedulerStateEvents) {
    this.isLoading = data.isLoadingLocations;
  }

  liveLocationChangeEvent(event: PracticeLocation) {
    const { practiceLocationId } = event;
    this.patientLiveStatusService.updateLocationIdToStorage(practiceLocationId);
    this.patientLiveStatusSubscriptionService.manageSignalRLocationSubscribtion(this.locationId, false);
    this.patientLiveStatusSubscriptionService.manageSignalRLocationSubscribtion(practiceLocationId, true);
    this.liveLocationChanged.emit(practiceLocationId);
    this.locationId = this.liveLocation.value.locationId;
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

}
