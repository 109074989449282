<div class="dropdown" autoClose="outside" ngbDropdown placement="auto" style="position:inherit;"
    [autoClose]="this.showSeatedModal || this.showPatientAgreement || this.showAppointmentSearch ? false : true">

    <div ngbDropdownToggle href="javascript:" [hidden]="this.isLoading">
        <ul class="nav flex-nowrap gap-1 live-status-bar-border">
            <li class="nav-item position-relative d-flex align-items-center wid-65 my-1 hei-30" *ngFor="let panel of patientLiveStatusPanels">
                <div class="d-flex flex-column lh-1 align-items-center justify-content-center position-relative wid-65 pe-4">
                    <img [src]="panel.image" class="wid-15">
                    <span class="text-truncate text-secondary fw-bold f-10" style="margin-top: 2px !important;" >{{panel.shorthandLabel}}</span>
                    <div  [ngClass]="{ 'blink': panel.appointments.length > 0 && panel.value === appointmentStatus.checkIn}">
                        <span *ngIf="panel.appointments.length > 0" [ngClass]="panel.className" class="f-14 position-absolute wid-30 count-position rounded fw-bold text-white">
                            {{panel.appointments.length}}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <ng-container *ngIf="this.isLoading">
       <app-patient-live-status-skeleton [skeletonCount]="isPatientLiveStatusCheckoutEnabled ? 5 : 4"></app-patient-live-status-skeleton>
    </ng-container>

    <div ngbDropdownMenu class="dropdown-menu patient_live_status_panel">
        
            <div class="sortable_stage_panel">

                <div class="sortable_stage bg-light"
                    *ngFor="let panel of patientLiveStatusPanels; let listIndex = index">
                    <p class="m-b-0">{{panel.label}}</p>
                    <dx-scroll-view class="scrollable-list" direction="vertical" showScrollbar="always">
                        <dx-sortable group="chairGroup" class="sortable-cards" [data]="panel"
                            (onDragStart)="appointmentDragStart($event)" (onAdd)="appointmentDrop($event)">

                            <div class="appt_card card" *ngFor="let appointment of panel.appointments">
                                <div class="appt_type" [ngStyle]="{'background':appointment.practiceAppointmentColor}">
                                </div>
                                <div class="patient_appt_name d-flex justify-content-between">
                                    <a draggable="false" [routerLink]="redirectToPatientSummaryDashboardRoute(appointment.patientId)" class="text-primary cursor-pointer">
                                        <span *ngIf="panel.value === appointmentStatus.checkout || panel.value === appointmentStatus.completed"
                                            [ngClass]="appointment.hasFutureAppointment ? 'text-success' : 'text-danger'">{{appointment.patientName}}</span>
                                        <span
                                            *ngIf="panel.value !== appointmentStatus.checkout && panel.value !== appointmentStatus.completed">{{appointment.patientName}}</span>
                                    </a>
                                    <div class="d-flex gap-1 align-items-center">
                                        <div ngxTippy="{{appointment.patientLanguagePreference === 'English' ? 'English' : 'Spanish'}}"  [tippyProps]="{ trigger: 'mouseenter'}">
                                            <ng-container *ngIf="appointment.patientLanguagePreference === 'English'">
                                            <img src="assets/images/EN.png" class="wid-20">
                                            </ng-container>
                                            <ng-container *ngIf="appointment.patientLanguagePreference !== 'English'">
                                                <img src="assets/images/ES.png" class="wid-20">
                                                </ng-container>
                                        </div>
                                        <div *ngIf="appointment.patientAppointmentNotes" class="badge bg-green-primary hei-20 wid-20 p-1 img-radius" ngxTippy="Notes : {{appointment.patientAppointmentNotes}}, Type : {{appointment.practiceAppointmentName}}"  [tippyProps]="{ trigger: 'mouseenter'}">
                                            N
                                        </div>
                                        <div *ngIf="!appointment.patientAppointmentNotes" class="badge bg-green-primary hei-20 wid-20 p-1 img-radius" ngxTippy="Type : {{appointment.practiceAppointmentName}}"  [tippyProps]="{ trigger: 'mouseenter'}">
                                            N
                                        </div>
                                        <div *ngIf="appointment.hasPastDue">
                                            <div class="badge badge-light-danger hei-20 wid-20 p-1 img-radius"
                                                *ngIf="appointment.hasInsurancePastDue && appointment.hasPatientPastDue"
                                                ngxTippy="Patient & Insurance Past Due">
                                                <i class="fa fa-solid fa-dollar-sign f-12"></i>
                                            </div>
                                            <div class="badge badge-light-danger hei-20 wid-20 p-1 img-radius"
                                                *ngIf="appointment.hasInsurancePastDue && !appointment.hasPatientPastDue"
                                                ngxTippy="Insurance Past Due" [tippyProps]="{ trigger: 'mouseenter'}">
                                                <i class="fa fa-solid fa-dollar-sign f-12"></i>
                                            </div>
                                            <div class="badge badge-light-danger hei-20 wid-20 p-1 img-radius"
                                                *ngIf="!appointment.hasInsurancePastDue && appointment.hasPatientPastDue"
                                                ngxTippy="Patient Past Due" [tippyProps]="{ trigger: 'mouseenter'}">
                                                <i class="fa fa-solid fa-dollar-sign f-12"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="patient_appt_time"><i
                                            class="icon feather icon-clock m-r-5"></i>{{appointment.patientAppointmentTime |
                                        date:'shortTime'}}
                                        <ng-container *ngIf="panel.value === appointmentStatus.checkIn">
                                            <div *ngIf="appointment.appointmentUtctime && appointment.patientCheckInTime" class="patient_appt_time"
                                                [style.color]="getStatusColor(getPatientTimeStatus(appointment.appointmentUtctime, appointment.patientCheckInTime).status)">
                                                <i class="icon feather icon-clock m-r-5"></i>
                                                {{getPatientTimeStatus(appointment.appointmentUtctime,
                                                appointment.patientCheckInTime).difference}}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div>                            
                                        <ng-container *ngIf="panel.value === appointmentStatus.checkout || panel.value === appointmentStatus.completed">
                                            <img src="assets/images/calendar-dismisse-view.png" (click)="viewAppointmentSearch(appointment)" class="me-1">
                                        </ng-container>
                                        <span *ngIf="appointment.isPatientHasAllergy" (click)="getPatientAllergies(appointment.patientId)"
                                            [ngxTippy]="infoTemplate" [tippyProps]="{ placement: 'right-start', trigger: 'click' }"
                                            class="badge bg-danger hei-20 wid-20 p-1" title="Medical Allergy">
                                            <i class="fa fa-solid fa-plus"></i></span>
                                    </div>

                                    <ng-template #infoTemplate>
                                        <div class="wid-140">
                                            <div class="row justify-content-between">
                                                <ng-container *ngIf="isAllergyLoading else allergytip">
                                                    <img src="assets/images/svg/spinner-loader.svg" class="wid-50 mx-auto">
                                                </ng-container>
                                                <ng-template #allergytip>
                                                    <div *ngFor="let allergy of patientAllergy" class="col-6 p-l-10 p-r-10 mb-1">
                                                        <span class="f-10 fw-normal text-white text-wrap mb-0">
                                                            {{allergy?.allergyName ?? allergy?.otherAllergies}}</span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>

                        </dx-sortable>
                    </dx-scroll-view>
                    
                </div>

            </div>
        </div>
</div>

<ng-container *ngIf="showSeatedModal">
    <app-popup-modal #SeatedUploadModal title="Select the appointment's position." size="large"
        (onClose)="showSeatedModal = false;closePopup(false, true, false)" [closeModel]="showSeatedModal">
        <app-seated-patient-status-modal [appointment]="seatedAppointment" (popupCloseEvent)="closePopup($event, true, false)"
            [patientAppointments]="this.patientLiveAppointments"></app-seated-patient-status-modal>
    </app-popup-modal>
</ng-container>

<ng-container *ngIf="showPatientAgreement">
    <app-popup-modal #agreementModal [title]="getTitle('Patient Agreement')" size="extralarge"
        (onClose)="showPatientAgreement = false;closePopup(false, false, true)" [closeModel]="showPatientAgreement">
       <app-patient-live-agreement [patientId]="patientId" [practicePolicies]="practicePolicies"  [patientLanguagePreference]="patientLanguagePreference"
        (onPopupClose)="closePopup(true, false, true)"></app-patient-live-agreement>
    </app-popup-modal>
</ng-container>

<ng-container *ngIf="showAppointmentSearch">
    <app-popup-modal #appointmentSearchModal [title]="getTitle('Appointment Search')" size="extralarge"
        (onClose)="showAppointmentSearch = false;closePopup(false, false, false, true)"
        [closeModel]="showAppointmentSearch">
        <ng-container *ngIf="isDismissAppointmentLoading">
            <app-form-skeleton [fields]="5"></app-form-skeleton>
        </ng-container>
        <ng-container *ngIf="!isDismissAppointmentLoading">
        <app-patient-live-status-appointment-search [patientId]="patientId" [providerId]="providerId"
            [patientExam]="patientExam" [patientStatusId]="patientStatusId" [patientStatusIds]="patientStatusIds" [examResultIds]="examResultIds"
            [appointmentTypeId]="nextVisitAppointmentTypeId" [hasMiscPlan]="hasMiscPlan"
            [nextVistDate]="nextVistDate" (patientStatusChange)="patientStatusChanged($event)"></app-patient-live-status-appointment-search>
        </ng-container>
    </app-popup-modal>
</ng-container>
