import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
})
export class CardComponent {
  // public props
  @Input() cardTitle: string;
  @Input() hidHeader: boolean;
  @Input() customHeader: boolean;
  @Input() isCardFooter: boolean;
  @Input() footerClass!: string;
  @Input() blockClass!: string;
  @Input() headerClass!: string;

  constructor() {
    this.hidHeader = false;
    this.cardTitle = 'Card Title';
    this.customHeader = false;
    this.isCardFooter = false;
  }
}
