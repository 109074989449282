<div class="dialer-container" [ngClass]="{'call-in-progress': bCallInProgress}" >
    <!-- <div class="phone-display"> -->
        <div class="form-group my-1">
            <div class="col-form-label text-center dialed-numbers text-dark">{{dialedNumbers}}</div>
        </div>
    <!-- </div> -->
    <div class="dialpad">
        <div class="number-btn rounded-circle" (click)="addNumber('1')">
            <div class="number">1</div><div class="alphabet">ABC</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('2')">
            <div class="number">2</div><div class="alphabet">DEF</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('3')">
            <div class="number">3</div><div class="alphabet">GHI</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('4')">
            <div class="number">4</div><div class="alphabet">JKL</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('5')">
            <div class="number">5</div><div class="alphabet">MNO</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('6')">
            <div class="number">6</div><div class="alphabet">PQR</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('7')">
            <div class="number">7</div><div class="alphabet">ST</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('8')">
            <div class="number">8</div><div class="alphabet">UV</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('9')">
            <div class="number">9</div><div class="alphabet">WX</div>
        </div>
        <div class="number-btn rounded-circle pt-2" (click)="$event.stopPropagation()">*</div>
        <div class="number-btn rounded-circle" (click)="addNumber('0')">
            <div class="number">0</div><div class="alphabet">YZ</div>
        </div>
        <div class="number-btn rounded-circle" (click)="$event.stopPropagation()">#</div>
        <div class="hide-btn">
            <ng-content></ng-content>
        </div>
        <!-- <div [ngClass]="{'end-call': bCallInProgress, 'disabled': !bCallInProgress && dialedNumbers.length < 10}" class="call-btn rounded-circle" (click)="makeCall()" title="{{bCallInProgress ? 'End call' : 'Transfer'}}">
            <span class="material-icons"> {{bCallInProgress ? 'call' : 'phone_callback'}} </span>
        </div> -->
        <div [ngClass]="{'end-call': bCallInProgress, 'disabled': !bCallInProgress && dialedNumbers.length < 10}" class="call-btn rounded-circle" (click)="makeCall()" title="{{bCallInProgress ? 'End call' : 'Transfer'}}">
            <i class="fa fa-solid fa-phone-slash"> </i>
        </div>
        <i class="fa fa-clear-btn material-symbols-outlined" (click)="clear()" title="Clear">
            backspace
        </i>
    </div>
</div>