<div class="row">
    <div class="col-12">
        <div class="signature-wrapper">
            <canvas #signaturePad width="700px" height="240px" style="touch-action: none;width:100%;height:100%;background:#fff;"></canvas>
        </div>
        <div class="d-flex justify-content-end mt-3">
            <button type="button" class="btn btn-outline-primary" *ngIf="!isSaving && (!this.documentGuid)"
                [disabled]="isdisableSignButton" (click)="onClear()">Clear
            </button>
            <button type="button" class="btn btn-light-primary ms-3" *ngIf="(!this.documentGuid)"
                [disabled]="isdisableSignButton" (click)="onSave()">
                <span *ngIf="isSaving"><i class="fa fa-regular fa-circle-notch fa-spin m-r-5"> </i>Loading...</span>
                <span *ngIf="!isSaving">Sign</span>
            </button>
            <button type="button" class="btn btn-outline-primary ms-3" *ngIf="!isSaving && (this.documentGuid)"
                (click)="onClear()">Reset
            </button>
        </div>
    </div>
</div>