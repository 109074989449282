import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomWizardService {

  private currentStepIndexSubject = new BehaviorSubject<number>(0);
  private currentStepButtonsSubject = new BehaviorSubject<any>(null);
  private headerStepNavigationSubject = new BehaviorSubject<any>(null);

  currentStepIndex$: Observable<number> = this.currentStepIndexSubject.asObservable();
  currentStepActions$: Observable<any> = this.currentStepButtonsSubject.asObservable();
  headerStepNavigation$: Observable<any> = this.headerStepNavigationSubject.asObservable();

  constructor() { }

  setStepIndex(activeStepIndex: number) {
    this.currentStepButtonsSubject.next(null);
    this.headerStepNavigationSubject.next(null);
    this.currentStepIndexSubject.next(activeStepIndex);
  }

  handleStepAction(object: any) {
    this.currentStepButtonsSubject.next(object)
  }

  headerStepNavigation(object: any) {
    this.headerStepNavigationSubject.next(object)
  }

}
