import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ngbpopup-action',
  template: `
<div ngbDropdown #primary="ngbDropdown" container="body">
    <div class="d-flex align-items-center justify-content-center wid-20 hei-20 bg-dark text-white img-radius p-1 cursor-pointer dropdown-toggle dropdown-remove"
        role="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
        <i class="fa fa-solid fa-info f-12"></i>
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownManual" class="rounded-3 ngbdropdown-popup p-0">
        <div class="m-0 py-3">
            <div class="d-flex gap-2 align-items-center border-bottom border-info px-3 pb-2">
                <img src="assets/images/svg/info-note.svg"><h6 class="h6 text-info mb-0" *ngIf="label">{{label}}</h6>
            </div>
            <div class="custom-scroll custom-scroll-y ngbdropdown-wid px-3 mt-2">
                <div [innerHtml]="(notes || '') | safeHtml" class="text-wrap text-break"></div>
                <ng-container *ngIf="showName">
                  <div class="d-flex align-items-center pt-2 gap-2" *ngFor="let data of names">
                  <div class="initial_avator">
                    {{ data.charAt(0).toUpperCase() }}
                  </div>
                  <div class="d-flex gap-1">
                    <p class="mb-1">{{ data }}</p>
                  </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
  `,
  styleUrls: ['./ngbpopup-action.component.scss']
})
export class NgbpopupActionComponent {
  @Input() notes: string = '';
  @Input() label: string = '';
  @Input() names: string[] = [];
  @Input() showName: boolean = false;
}
