import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[numericOnly]'
})
export class NumericOnlyDirective {

    constructor(private el: ElementRef, private control: NgControl) { }

    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        let value = input.value;
        value = value.replace(/[^0-9]/g, '');
        this.control.control?.setValue(value, { emitEvent: false });
        input.value = value;
    }
}