import { Component, Input, TemplateRef } from '@angular/core';
import { wizardSteps, wizardStepAction, wizardStepbuttons, wizardHederNavAction } from '../../types/shared-types';
import { Subscription } from 'rxjs';
import { CustomWizardService } from './custom-wizard.service';

@Component({
  selector: 'app-custom-wizard',
  templateUrl: './custom-wizard.component.html',
  styleUrls: ['./custom-wizard.component.scss']
})
export class CustomWizardComponent {

  @Input() steps: wizardSteps[] = [];
  @Input() templates: TemplateRef<any>[] = [];
  @Input() disableNavigation = false;
  activeStepIndex: number = 0;

  private subscriptions$ = new Subscription();

  constructor(
    private customWizardService: CustomWizardService,
  ) { }

  ngOnInit(): void {
    this.subscriptions$.add(this.customWizardService.currentStepIndex$.subscribe((stepIndex: number) => this.getStepIndex(stepIndex)));
  }

  getStepIndex(stepIndex: number) {
    this.activeStepIndex = stepIndex;
  }

  handleStepsAction(step: wizardSteps, action: wizardStepbuttons) {
    const model: wizardStepAction = {
      title: step.title,
      action: action,
      stepIndex: this.activeStepIndex
    }
    this.customWizardService.handleStepAction(model);
  }

  headerStepNavigation(step: wizardSteps, stepIndex: number) {
    const model: wizardHederNavAction = {
      title: step.title,
      activeStepIndex: this.activeStepIndex,
      stepIndex: stepIndex,
    }
    this.customWizardService.headerStepNavigation(model);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
