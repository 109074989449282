import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MASK_PATTERN } from '../../../constant/shared-pattern-regex-asset';
import { CalendarType, DateInputFillMode, DateInputRounded, DateInputSize } from '@progress/kendo-angular-dateinputs';
import { ScreenSizeService } from '../../../services/screen-size.service';
import { deviceWidth } from '../../../constant/shared-constant';

@Component({
  selector: 'app-form-control-datetimepicker',
  template: `  <div class="form-group" [class.has-error]="hasError()">

  <label [for]="labelName" *ngIf="labelName">{{ labelName }}
    <span *ngIf="required" class="required_field ms-1">*</span>
  </label>

  <kendo-datetimepicker
    [incompleteDateValidation]="true"
    formatPlaceholder="wide"
    [placeholder]="placeholder"
    format={{dateTimeFormat}}
    [size]="size"
    [rounded]="rounded"
    [fillMode]="fillMode"
    [calendarType]="calendarType"
    [popupSettings]="{ animate: false }"
    [id]="labelName"
    [disabledDates]="disabledDates"
    [value]="selectedDate"
    (valueChange)="onChange($event)"
    (blur)="onTouched()"
    [readOnlyInput]="readOnlyValue" 
  >
  </kendo-datetimepicker>

  <ng-container *ngIf="hasError()">
    <app-validation-error [errors]="control?.errors" [fieldName]="labelName">
    </app-validation-error> 
  </ng-container>
</div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlDateTimepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlDateTimepickerComponent),
      multi: true
    }
  ],
})
export class FormControlDateTimepickerComponent implements OnInit {

  @Input() labelName?: string;
  @Input() required?: boolean;
  @Input() placeholder!: string;
  @Input() stepsMinuts?: number = 15;
  @Input() FastNavigation?: boolean;
  @Input() size: DateInputSize;
  @Input() rounded: DateInputRounded;
  @Input() fillMode: DateInputFillMode;
  @Input() calendarType: CalendarType;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() inputDate?: Date;
  @Input() disableFutureDates!: boolean;

  dateTimeFormat: string;
  steps!: any;
  control: AbstractControl | undefined;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled: boolean;
  selectedDate: Date;
  disabledDates: (date: Date) => boolean;
  readOnlyValue = false

  constructor(private screenSizeService: ScreenSizeService) {
    this.dateTimeFormat = MASK_PATTERN.dateTime;
    this.steps = { minute: this.stepsMinuts };
    this.placeholder = ''
    this.disabled = false;
    this.selectedDate = this.inputDate ?? new Date();
    this.FastNavigation = true;  //enable fast navigation
    this.size = 'large';  //small medium(Default) large none
    this.rounded = 'medium';  //small medium(Default) large full none
    this.fillMode = 'solid';  //solid(Default) flat outline none
    this.calendarType = 'infinite'  // infinite(default) classic
    this.minDate = new Date(1900, 0, 1);
    this.maxDate = new Date();
    const today = new Date();
    this.disabledDates = (date: Date): boolean => {
      return date > today;
    };
  }

  ngOnInit(): void {
    if (!this.disableFutureDates) {
      this.disabledDates = (): boolean => {
        return false;
      };
    }

    this.screenSizeService.screenWidth$.subscribe((screenInfo) => {
      if (screenInfo[0] <= deviceWidth.desktop) {
        this.readOnlyValue = true
      } else {
        this.readOnlyValue = false
      }
    });
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    if (value instanceof Date) {
      this.selectedDate = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

}
