import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, zip } from 'rxjs';
import { DragEndEvent, DragStartEvent } from 'devextreme/ui/sortable';
import { ChangeDetectorRef } from '@angular/core';

import { selectPatientLiveAppointments, selectPatientLiveStatusStateEvents, selectPracticeChairConfigurations } from '../../store/patient-live-status-store/patient-live-status.selector';
import { Appointment, Appointments, SchedulerConfiguration, SchedulerStateEvents } from 'src/app/features/scheduler/store/scheduler-store/scheduler-state';
import { chairLiveStatusPanel, chairStatusMessages, placeholderChairColor } from '../../constant/patient-live-status-constant';
import { appointmentStatus, appointmentStatusID } from 'src/app/features/shared/constant/shared-constant';
import { SchedulerService } from 'src/app/features/scheduler/service/scheduler.service';
import { invokePatientLiveSaveStatusAppointment, updateAppointmentsToliveStatusStore } from '../../store/patient-live-status-store/patient-live-status-action';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { ChairActionMenus, actionChairMenuItems } from 'src/app/features/shared/constant/shared-action-menu-item';
import { NavigateURLService } from 'src/app/features/shared/services/navigate-url.service';
import { ChaiActionMenu } from 'src/app/features/shared/types/shared-types';

@Component({
  selector: 'app-chair-live-status',
  templateUrl: './chair-live-status.component.html',
  styleUrls: ['./chair-live-status.component.scss']
})
export class ChairLiveStatusComponent implements OnInit, OnDestroy {

  isLoading = false;
  actionListItems: Array<string> = Object.values(actionChairMenuItems);
  chairLiveAppointments!: chairLiveStatusPanel[];
  chairConfig!: SchedulerConfiguration;
  actionChairMenuItems = actionChairMenuItems;
  ChairActionMenus = ChairActionMenus;

  sheduleConfigurations$ = this.store.select(selectPracticeChairConfigurations);
  patientAppointments$ = this.store.select(selectPatientLiveAppointments);
  patientAppointmentsOnly$ = this.store.select(selectPatientLiveAppointments);
  patientLiveStatusStateEvents$ = this.store.select(selectPatientLiveStatusStateEvents);
  private subscriptions$ = new Subscription();
  colors = placeholderChairColor;
  constructor(
    private store: Store,
    private schedulerService: SchedulerService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private navigateURLService: NavigateURLService,
  ) { }

  ngOnInit(): void {
    this.subscribeToConfigurationsAndAppointments();
    this.subscribeAndHandleUpdates(this.patientAppointmentsOnly$, this.seatedAppointmentsForChair.bind(this));
    this.subscribeAndHandleUpdates(this.patientLiveStatusStateEvents$, this.stateEventChanged.bind(this));
  }

  subscribeAndHandleUpdates<T>(observable: Observable<T>, updateFunction: (data: T) => void) {
    this.subscriptions$.add(observable.subscribe(updateFunction));
  }

  stateEventChanged(data: SchedulerStateEvents) {
    this.isLoading = data.loading ?? false;
    this.cdr.detectChanges();
  }

  scheduleConfig(scheduleConfig: SchedulerConfiguration) {
    if (scheduleConfig) {
      this.chairConfig = scheduleConfig;
      this.chairLiveAppointments = scheduleConfig.chairs?.map(item => {
        return {
          practiceChairId: item.practiceChairId,
          chairName: item.chairName,
          chairDescription: item.chairDescription,
          isActive: item.isActive,
          appointments: []
        };
      });
    }
  }

  appointmentChangeOnly(appointments: Appointments[]) {
    this.seatedAppointmentsForChair(appointments);
  }

  seatedAppointmentsForChair(appointments: Appointments[]) {
    if (appointments) {
      this.chairLiveAppointments?.forEach(schedule => {
        schedule.appointments = [];

        appointments
          .filter(appointment =>
            schedule.practiceChairId === appointment.actualChairId &&
            appointment.patientAppointmentStatusId === appointmentStatusID.seated
          )
          .forEach(appointment => {
            if (!Object.isExtensible(appointment)) {
              appointment = { ...appointment };
            }
            appointment.elapsedTime = this.schedulerService.getElapseTime(appointment.patientSeatedTime);
            appointment.remainingTime = this.schedulerService.getRemainingTime(appointment.patientSeatedTime, appointment.patientAppointmentDuration);
            schedule.appointments.push(appointment);
          });
      });
    }
  }

  onTaskDragStart(event: DragStartEvent) {
    event.itemData = event.fromData.appointments[0];
  }

  onAppointmentDrop(event: DragEndEvent) {
    const { fromData, fromIndex, toData, toIndex, itemData } = event;
    const { appointments: fromAppointments, value: fromValue } = fromData;
    if (toData.appointments.length > 0) {
      this.alertService.warningAlert(chairStatusMessages.appointmentExistsAlertMessage);
      return;
    }
    fromAppointments.splice(fromIndex, 1);
    toData.appointments.splice(toIndex, 0, itemData);

    let updatedItemData = { ...itemData, actualChairId: toData.practiceChairId }

    updatedItemData = { ...updatedItemData, patientAppointmentStatusId: appointmentStatusID.seated, chairEvent: '' }

    const appointment = this.schedulerService.mapAppointmentModel(updatedItemData);
    this.store.dispatch(invokePatientLiveSaveStatusAppointment({ appointment, action: '' }));
    if (fromValue !== appointmentStatus.seated) {
      this.schedulerService.navigateToTXCard(appointment.patientId);
    }
  }

  subscribeToConfigurationsAndAppointments() {
    this.subscriptions$.add(zip(this.sheduleConfigurations$, this.patientAppointments$)
      .subscribe(([scheduleConfig, appointments]) => {
        this.scheduleConfig(scheduleConfig);
        this.seatedAppointmentsForChair(appointments);
      }));
  }

  getActionMenuItems(appointment: Appointment): ChaiActionMenu[] {
    const chairMenus = ChairActionMenus;
    const chairActionMenuItems: ChaiActionMenu[] = [];
    chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.ViewPatient)!);
    chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.ViewImages)!);
    chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.TxCard)!);
    if (appointment.chairEvent != actionChairMenuItems.CallDoctor)
      chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.CallDoctor)!);
    if (appointment.chairEvent != actionChairMenuItems.CallEmergency)
      chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.CallEmergency)!);
    if (appointment.chairEvent === actionChairMenuItems.CallDoctor || appointment.chairEvent === actionChairMenuItems.CallEmergency) {
      chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.DoctorIsPresent)!);
      chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.CancelCall)!);
    }
    if (appointment.chairEvent === actionChairMenuItems.DoctorIsPresent) {
      chairActionMenuItems.push(chairMenus.find(item => item.value === actionChairMenuItems.Doctorleft)!);
    }
    return chairActionMenuItems;
  }

  onItemClick(menu: ChaiActionMenu, appointment: Appointment): void {
    const patientId = appointment.patientId;
    switch (menu.value) {
      case actionChairMenuItems.ViewPatient:
        this.navigateURLService.navigateToPatientSummary(patientId);
        break;
      case actionChairMenuItems.ViewImages:
        this.navigateURLService.navigateToImageSummary(patientId);
        break;
      case actionChairMenuItems.TxCard:
        this.navigateURLService.navigateToTreatmentCardSummary(patientId);
        break;
      case actionChairMenuItems.CallDoctor:
        this.updateAppointmentChairEvent(appointment, actionChairMenuItems.CallDoctor);
        break;
      case actionChairMenuItems.CallEmergency:
        this.updateAppointmentChairEvent(appointment, actionChairMenuItems.CallEmergency);
        break;
      case actionChairMenuItems.DoctorIsPresent:
        this.updateAppointmentChairEvent(appointment, actionChairMenuItems.DoctorIsPresent);
        break;
      case actionChairMenuItems.Doctorleft:
        this.updateAppointmentChairEvent(appointment, actionChairMenuItems.Doctorleft);
        break;
      case actionChairMenuItems.CancelCall:
        this.updateAppointmentChairEvent(appointment, actionChairMenuItems.CancelCall);
        break;
      default:
        break;
    }
  }

  updateAppointmentChairEvent(appointment: Appointment, action: string) {
    const updatedItemData = { ...appointment, chairEvent: action }
    this.store.dispatch(invokePatientLiveSaveStatusAppointment({ appointment: updatedItemData, action }));
  }

  onTimeChange(remainingTime: number, appointment: Appointments) {
    if (remainingTime <= 300 && remainingTime >= 120) {
      if (!appointment.isLastFiveMinutes && !appointment.isLastTwoMinutes && !appointment.isTimeEnded) {
        appointment = { ...appointment, isLastFiveMinutes: true, isLastTwoMinutes: false, isTimeEnded: false }
        this.updateAppointmentToStore(appointment);
      }
    }
    else if (remainingTime < 120 && remainingTime > 0) {
      if (!appointment.isLastTwoMinutes && (!appointment.isTimeEnded || appointment.isLastFiveMinutes)) {
        appointment = { ...appointment, isLastTwoMinutes: true, isLastFiveMinutes: false, isTimeEnded: false }
        this.updateAppointmentToStore(appointment);
      }
    }
    else if (remainingTime == 0) {
      if (!appointment.isTimeEnded && (appointment.isLastTwoMinutes || !appointment.isLastFiveMinutes)) {
        appointment = { ...appointment, isTimeEnded: true, isLastTwoMinutes: false, isLastFiveMinutes: false }
        this.updateAppointmentToStore(appointment);
      }
    }
    else {
      if (appointment.isLastFiveMinutes || appointment.isLastTwoMinutes || appointment.isTimeEnded) {
        appointment = { ...appointment, isLastFiveMinutes: false, isLastTwoMinutes: false, isTimeEnded: false }
        this.updateAppointmentToStore(appointment);
      }
    }
  }

  updateAppointmentToStore(appointment: Appointments) {
    this.store.dispatch(updateAppointmentsToliveStatusStore({ appointment: appointment }));
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }


}