import { createAction, props } from '@ngrx/store';
import { ActiveContactCallEvent, AnsweredCallDialCallInfo, DialCallInfo, DialCallToken, RecentCallLogs } from './phone-call.state';

// PhoneCall Actions

export const dialPhoneCall = createAction('[PhoneCall] API Post Dial Token PhoneCall', props<{ dialCallInfo: DialCallInfo, successMessage: string }>());
export const dialPhoneCallSuccess = createAction('[PhoneCall] Dial Token PhoneCall Success', props<{ dialCallToken: DialCallToken, successMessage: string }>());
export const dialPhoneCallFailure = createAction('[PhoneCall] Dial Token PhoneCall Failure', props<{ error: any }>());
export const resetdialPhoneCall = createAction('[PhoneCall] reset dial Phone Call');

//Event Action
export const phoneCallsStopLoading = createAction('[PatientPhoneCall] Patient PhoneCalls Stop Loading');
export const handlePatientPhoneCallsFailure = createAction('[PhoneCallFailure] Patient PhoneCalls Load Failure', props<{ error: any }>());
export const closeErrorAlert = createAction('[PatientPhoneCall Close Error Alert] close error alert');
export const closeSuccessAlert = createAction('[PatientPhoneCall Close Success Alert] Close Success Alert');

export const resetPhoneCallState = createAction('[ResetPhoneCall] Reset PhoneCall State');
export const invokeGetRecentCallLog = createAction('[GetRecentCallLogs API] Invoke ',props<{practiceId: number,practiceLocationId: number, startDate: string | null, endDate: string| null}>());
export const getRecentCallSuccessLog = createAction('[GetRecentCallLogs API] Success', props<{ recentCallLogs: RecentCallLogs[]}>());
export const getRecentCallFailureLog = createAction('[GetRecentCallLogs API] Failure', props<{ error: any }>());

export const setDialPadPhoneNumber = createAction('[PatientPhoneCall]set DialPad PhoneNumber ', props<{ activeContactCallEvent: ActiveContactCallEvent }>());
export const resetDialPadPhoneNumber = createAction('[PatientPhoneCall] reset DialPad PhoneNumber');

export const invokeAnsweredCall = createAction('[PhoneCall] invokeA nsweredCall PhoneCall', props<{ dialCallInfo: AnsweredCallDialCallInfo }>());
export const answeredCallSuccess = createAction('[PhoneCall]  answeredCall Success PhoneCall');

export const setonCallInprogressPhoneNumber = createAction('[PatientPhoneCall] set onCallInprogress PhoneNumber ', props<{ onCallInprogressphoneNumber: string }>());
export const resetonCallInprogressPhoneNumber = createAction('[PatientPhoneCall] reset onCallInprogressPhoneNumber');

