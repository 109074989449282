import { Component, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WebcamComponent {
  @Output() pictureTaken = new EventEmitter<File>();
  public showWebcam = true;
  public errors: WebcamInitError[] = [];
  webcamImage!: WebcamImage | null;

  private trigger: Subject<void> = new Subject<void>();

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  public retake(): void {
    this.webcamImage = null;
  }

  handleCapturedImage() {
    if(!this.webcamImage) return;
    const arr = this.webcamImage.imageAsDataUrl.split(',');
    if (arr[0]) {
      const mimeMatch = arr[0].match(/:(.*?);/);
      const mime = mimeMatch ? mimeMatch[1] : '';
      const base64Data = arr[1];
      const binaryData = Buffer.from(base64Data, 'base64');
      const blob = new Blob([binaryData], { type: mime });
      const file: File = new File([blob], 'toothimage.jpg', { type: 'image/jpeg' });
      this.pictureTaken.emit(file);
    }
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
}
