import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { DocumentDownloadInfo, getImage, getImageForUnAuthurizedUser } from '../../../store/document-download-store/document-download.reducer';
import { selectDocumentsDownloadInfo } from '../../../store/document-download-store/document-download.selector';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { documentGuidNullValue, uploadType } from '../../../constant/document-message.constant';
import { PatientImage } from 'src/app/features/patient-image/store/patient-image-store/patient-image-info';
import { PopupModalComponent } from 'src/app/features/shared/components/modal/popup-modal.component';
import { DocumentUploadState } from '../../../store/document-upload-store/document-upload.state';
import { DownloadDocumentFileService } from '../../../service/download-document-file.service';
import { AlertService } from 'src/app/features/shared/utils/alert.service';

@Component({
  selector: 'app-image-download',
  templateUrl: './image-download.component.html',
})
export class DocumentDownloadComponent extends BaseDestroyComponent implements OnInit, OnChanges {
  @Input() documentGuid?: string;
  @Input() showDownload?: boolean = true;
  @Input() showUpload?: boolean = true;
  @Input() showEdit?: boolean = false;
  @Input() showRemove?: boolean = false;
  @Input() showWebcam?: boolean = false;
  @Input() showAcquireImage?: boolean = false;
  @Input() showViewer?: boolean = false;
  @Input() showReset?: boolean = false;
  @Input() convertToBase64src?: boolean = false;
  @Input() isAuthenticated?: boolean = true;


  @Input() defaultImagePath?: string;
  @Input() imageName?: string;
  @Input() patientImage?: PatientImage;
  @Input() uploadDocumentType: string = uploadType.document;
  @Input() uploadFileType?: string;
  @Input() documentName?: string;

  @Output() removeImage: EventEmitter<void> = new EventEmitter<void>()
  @Output() viewImage: EventEmitter<void> = new EventEmitter<void>()
  @Output() resetImage: EventEmitter<void> = new EventEmitter<void>()
  @Output() editImage: EventEmitter<void> = new EventEmitter<void>()
  @Output() downloadImage: EventEmitter<void> = new EventEmitter<void>()
  @Output() updatedImage: EventEmitter<DocumentUploadState | PatientImage> = new EventEmitter<DocumentUploadState | PatientImage>()

  imageSrc: SafeUrl | undefined;
  documentData$ = this.store.select(selectDocumentsDownloadInfo);
  documentDownloadInfoList?: DocumentDownloadInfo[];
  showImageUpload?: boolean = false;
  isWebcamUpload?: boolean = false;
  isLoadingImage?: boolean = false;
  isDownloading = false;
  containerId = "dwtcontrolContainer";
  isAcquireImageUpload?: boolean = false

  constructor(private store: Store, private sanitizer: DomSanitizer,
    private downloadDocumentFileService: DownloadDocumentFileService,
    private alert: AlertService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['documentGuid'] && changes['documentGuid'].firstChange === false)
      this.handleDownload()
  }

  ngOnInit() {
    this.subscriptions$.add(this.documentData$.subscribe((data: DocumentDownloadInfo[] | undefined) => this.bindImage(data)));
    this.handleDownload();
  }

  bindImage(data: DocumentDownloadInfo[] | undefined): void {
    if (data && this.documentGuid) {
      this.documentDownloadInfoList = data;
      const documentDownloadInfo = this.findDocument();
      if (documentDownloadInfo) {
        this.setImageSrc(documentDownloadInfo);
        this.isLoadingImage = false;
      } else {
        this.imageSrc = undefined;
      }
    }
  }

  private async setImageSrc(documentDownloadInfo: DocumentDownloadInfo): Promise<void> {
    if (documentDownloadInfo.documentData === 'imageNotFound') {
      this.imageSrc = 'assets/images/maintenance/image-notfound.png';
      this.resetImageFlags(false);
      return;
    }
    const safeUrl = this.safeUrl(documentDownloadInfo.documentData);
    if (!this.convertToBase64src) {
      this.imageSrc = safeUrl;
    }
    if (this.convertToBase64src && documentDownloadInfo.documentData) {
      try {
        const base64data = await this.downloadDocumentFileService.convertBlobToBase64(documentDownloadInfo.documentData);
        this.imageSrc = 'data:image/png;base64,' + base64data;
      } catch (error) {
        this.alert.failureAlert(error);
      }
    }
  }

  private findDocument() {
    return this.documentDownloadInfoList?.find(info => info.downloadedDocumentGuid === this.documentGuid);
  }

  handleDownload() {
    if (this.documentGuid && this.documentGuid !== documentGuidNullValue) {
      const documentDownloadInfo = this.findDocument();
      if (documentDownloadInfo) {
        this.setImageSrc(documentDownloadInfo);
      }
      else {
        this.isLoadingImage = true;
        if(this.isAuthenticated){
         this.store.dispatch(getImage({ documentGuid: this.documentGuid }));
        }else{
          this.store.dispatch(getImageForUnAuthurizedUser({ documentGuid: this.documentGuid }));
        }
      }
    }
    else
      this.imageSrc = undefined
  }

  private safeUrl(dataUrl: string): SafeUrl | undefined {
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }

  private resetImageFlags(value: boolean): void {
    this.showEdit = value;
    this.showWebcam = value;
    this.showAcquireImage = value;
    this.showViewer = value;
    this.showReset = value;
    this.showDownload = value;
    this.showRemove = value;
  }

  @ViewChild('imageUploadModal', { static: false }) imageUploadModal!: PopupModalComponent;
  updateimageUpload(documentsInfo: DocumentUploadState): void {
    if (this.imageUploadModal) this.imageUploadModal.closeModal();

    if (uploadType.toothImage && this.patientImage) {
      const documentUploadResponse = documentsInfo?.documents[0]?.documentUploadResponse;
      if (documentUploadResponse) {
        this.documentGuid = documentUploadResponse.patientCroppedImageGuid ?? documentUploadResponse.patientOriginalImageGuid
        this.handleDownload();
        const updatedPatientImage = this.mapUpdatedToothImage(documentsInfo);
        this.updatedImage.emit(updatedPatientImage);
      }
    }
    else {
      this.documentGuid = documentsInfo?.documents[0]?.documentUploadResponse?.documentGuid;
      this.updatedImage.emit(documentsInfo);
    }
  }

  showImageUploadfunc(isWebcamUpload: boolean,isAcquireImageUpload:boolean) {
    this.showImageUpload = true;
    this.isWebcamUpload = isWebcamUpload;
    this.isAcquireImageUpload = isAcquireImageUpload;
  }

  private mapUpdatedToothImage(documentsInfo: DocumentUploadState) {
    const documentResponse = documentsInfo?.documents[0]?.documentUploadResponse;
    if (!this.patientImage) return;
    const updatedPatientImage = { ...this.patientImage };
    updatedPatientImage.createdDate = documentResponse?.createdDate;
    updatedPatientImage.patientImageTypeName = documentResponse?.patientImageTypeName
    updatedPatientImage.patientImageDate = new Date(documentResponse?.patientImageDate ?? new Date());
    if (updatedPatientImage.patientImageId !== 0) {
      updatedPatientImage.patientCroppedImageGuid = documentResponse?.patientCroppedImageGuid;
      updatedPatientImage.croppedDocumentExtension = documentResponse?.croppedDocumentExtension
    }
    else {
      updatedPatientImage.patientImageId = documentResponse?.patientImageId ?? 0;
      updatedPatientImage.patientOriginalImageGuid = documentResponse?.patientOriginalImageGuid;
      updatedPatientImage.originalDocumentExtension = documentResponse?.originalDocumentExtension
    }
    return updatedPatientImage;
  }

  browserDownloadImage() {
    if (this.isDownloading === false) {
      this.isDownloading = true;
      this.downloadImage.emit();

      setTimeout(() => {
        this.isDownloading = false;
      }, 2000);
    }
  }

  edit() {
    this.editImage.emit();
  }
}