import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { deviceWidth } from '../constant/shared-constant';


@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private screenWidthSubject = new BehaviorSubject<[width: number, device: string]>([window.innerWidth, 'desktop']);
  screenWidth$ = this.screenWidthSubject.asObservable();

  constructor() {
    window.addEventListener('resize', this.onResize.bind(this));
    this.onResize();
  }

  private onResize() {
    let device = '';
    if (window.innerWidth < deviceWidth.mobile) {
      device = 'mobile';
    }
    else if (window.innerWidth < deviceWidth.mobilePortrait) {
      device = 'mobilePortrait';
    }
    else if (window.innerWidth < deviceWidth.iPadPortrait) {
      device = 'iPadPortrait';
    }
    else if (window.innerWidth < deviceWidth.tablet) {
      device = 'tablet';
    }
    else if (window.innerWidth < deviceWidth.desktop) {
      device = 'desktop';
    }
    else if (window.innerWidth < deviceWidth.desktopMd) {
      device = 'desktopMd';
    }
    else if (window.innerWidth < deviceWidth.desktopXl) {
      device = 'desktopXl';
    }
    else {
      device = 'desktopXXl';
    }
    const tuple: [number, string] = [window.innerWidth, device];
    this.screenWidthSubject.next(tuple);
  }

  getScreenWidth(): number {
    return this.screenWidthSubject.value[0];
  }

  public IsiPadPortrait(){
    return this.screenWidthSubject.value[1] === 'iPadPortrait';
  }

  public isDesktopAbove1200(){
    return this.screenWidthSubject.value[0] > 1200;
  }
}
