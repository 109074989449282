import { environment } from "src/environments/environment";

const PRACTICE_API_BASE_URL = environment.application.api_base_practice_url;

function practiceApiUrl(endpoint: string) {
    return `${PRACTICE_API_BASE_URL}${endpoint}`;
}

export const practiceRoleApiAsset = {
    getPracticesRoles: practiceApiUrl("PracticeRole/GetRoles"),
    getPracticeUsers: practiceApiUrl("PracticeRole/GetPracticeUsers"),
    getPermissionTypes: practiceApiUrl("Core/GetPermissionTypes"),
    getPracticeModules: practiceApiUrl("Core/GetPracticeModules"),
    getPracticeModuleFeatures: practiceApiUrl("Practice/GetPracticeModuleFeatures"),
    getPermissionOperations: practiceApiUrl("Core/GetPermissionOperations"),
    savePracticeRole: practiceApiUrl("PracticeRole/SavePracticeRole"),
    getPracticeRolePermissions: practiceApiUrl("PracticeRole/GetPracticeRolePermissions"),
    getPracticeUserPermissions: practiceApiUrl("PracticeRole/GetPracticeUserPermissions"),
    savePracticeUserPermission: practiceApiUrl("PracticeRole/SavePracticeUserPermission"),
    savePracticeRolePermission: practiceApiUrl("PracticeRole/SavePracticeRolePermission"),
    getLoggedInUserPermissions: practiceApiUrl("PracticeRole/GetLoggedInUserPermissions")
}