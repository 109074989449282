import { Component, EventEmitter, Input, NgModuleRef, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicModuleService } from 'src/app/features/shared/services/dynamic-load-module-component';

@Component({
  selector: 'app-patient-live-agreement',
  templateUrl: './patient-live-agreement.component.html'
})
export class PatientLiveAgreementComponent implements OnInit {
  @Input() practicePolicies: number[] = [];
  @Input() patientId: number | undefined;
  @Input() patientLanguagePreference: string | undefined;
  @Output() onPopupClose = new EventEmitter<boolean>();
  
  patientAgreementComponentRef: any;
  patientAgreementModuleRef!: NgModuleRef<any>;
  @ViewChild('patientAgreement', { read: ViewContainerRef, static: true }) _patientAgreementContainer!: ViewContainerRef;

  constructor(private dynamicModuleService: DynamicModuleService) { }
  
  async ngOnInit() {
    this.patientAgreementComponentRef = await this.dynamicModuleService.loadModuleAndComponent("patientAgreement", this.patientAgreementComponentRef,
      this.patientAgreementModuleRef, this._patientAgreementContainer, { patientId: this.patientId, practicePolicies: this.practicePolicies, patientLanguagePreference: this.patientLanguagePreference });
      this.patientAgreementComponentRef.instance.onPopupClose.subscribe((message: boolean) => {
         this.onPopupClose.emit(message);
      });
  }

}
