import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoreState } from './core-info';

export const selectPracticeCoreInfo = createFeatureSelector<CoreState>('coreLookupStore');

export const selectUSStateList = createSelector(
  selectPracticeCoreInfo, coreState => { return coreState?.usStateInfo?.usStates || [] }
);

export const selectUSStateErrorMessage = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.usStateInfo?.coreStateEvent?.errorMessage;
});

export const selectTimeZoneList  = createSelector(
  selectPracticeCoreInfo, coreState => { return coreState?.timeZoneInfo?.timeZones || []});

export const selectTimeZoneErrorMessage = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.timeZoneInfo?.coreStateEvent?.errorMessage;
});
