export const routeNames = {
  superAdminPractice:  'practice/practice-personal-lookup/personal',
  practiceInformation : 'practice/practice-personal-lookup/personal/:id',
  patientLoginInfo: 'patientinfo/patientLoginInfo/patientsummary/',
  practices: 'admin',
  home: 'dashboard/welcome',
  clinical:'dashboard/clinical',
  tracker:'dashboard/patienttracker',
  financial: 'dashboard/financial',
  insurance: 'dashboard/insurance/pendingInvoice',
  report:"report/dashboard",
  remittance: "dashboard/insurance/processed",
  onlineScheduleSuccess: 'online-scheduling/success',
  onlinePatientReferralSuccess: 'patient-referral/success',
  onlinePatientReferral: 'patient-referral/id',
  selfCheckIn: 'self-checkin',
  patientAppointmentConfirmationSuccess: 'appointmentconfirmation/success'
}

export const roles = {
   superAdmin : 'Super Admin',
   practiceAdmin: 'Practice Admin',
   patient: 'Patient',
   patientFRP: 'PatientFRP',
   practiceGroupAdmin : "Practice Group Admin",
   doctor: 'Doctor',
   selfCheckIn: 'Self Check In'
 }
export const userRoles = {
  noAccessUserRoles: ["Super Admin", 'PatientFRP', 'Patient'],
  accessRolesForSA: ['Practice Admin', 'Doctor']
}

export const applicationStorageKeys = {
   selectedPracticeIdBySA: 'selectedPracticeIdBySA',
   selectedPracticeGuidBySA: 'selectedPracticeGuidBySA',
   loginUserLocationId : 'loginUserLocationId',
   authUser: 'authUser',
   PATIENT_PIN_KEY: 'patientspin',
   loggedInUserPermission: 'loggedInUserPermission',
   practiceFeaturePermission: 'practiceFeaturePermission',
}

export const menuKeys={
  report : "Report",
  practice: "practice"
}

export const moduleName = {
  practice: "Practice Management Module",
  patient: "Patient Management Module",
  scheduler: "Schedule Management Module",
  insurance: "Insurance Management Module",
  serviceContract: "Service Contract Management Module",
  paymentManagement: "Payment Management Module",
  report: "Report Management Module",
  reward: "Patient Rewards Management Module",
  task : "Task Management Module",
  referral: "Patient Referral Module",
}

export const featureName = {
  //Shared feature
  patientSearch: "Patient Search",

  // practice
  practicePersonalInfo: "Practice Information Feature",
  practiceList: "Practice List",
  //payment
  paymentPatient: "Patient Payment Feature",
  paymentInsurance: "Insurance Payment Feature",
  paymentOtherThanCard: "Patient Payment Method Other Than Card",
  //schedule
  schedule:'Schedule Feature',
  //patient
  patient: "Patient Information Feature",
  insuranceBenefit: "Insurance Benefit Feature",
  //insurance
  insuranceClaim: "Insurance Claim Feature",
  //Service Contract
  serviceContract: "Service Contract Feature",
  //Others
  report: "Report Feature",
  patientRewards: "Patient Rewards Feature",
  taskManagement: "Task Management Feature",
  patientReferral: "Patient Referral Feature", 
  chatDashbard: 'ChatDashboard',
  selfCheckin: "Self CheckIn Feature",
}

export const operationName = {
  create: "Create",
  write: "Edit",
  view: "View",
  delete: "Delete",
  all: "All"
}