<div class="validation_msg" *ngIf="errors">

    <!-- generic message -->
    <span *ngIf="errors?.required">{{errorMessage ? errorMessage : fieldName}} is required.</span>
    <span *ngIf="errors?.minlength && showDefaultMinlengthError">That's a bit short! Please enter a minimum of
        {{errors?.minlength?.requiredLength}}.</span>
    <span *ngIf="errors?.maxlength">That's a bit long! Please limit your input{{ showLength ? ' to ' + errors?.maxlength?.requiredLength + ' characters or fewer' : '' }}.
    </span>
    <span *ngIf="errors?.email">{{fieldName}} is invalid.</span>
    <span *ngIf="errors?.isEmailAddressSame">New email address could not be same as current.</span>
    <span *ngIf="errors?.pattern">{{fieldName}} is invalid.</span>
    <span *ngIf="errors?.emptySpace"> {{fieldName}} is required.</span>
    <span *ngIf="errors?.authorize"> Authorization is required.</span>
    <span *ngIf="errors?.notNumericPercentage">Percentage should be in digits only.</span>
    <span *ngIf="errors?.maxPercentageExceeded">{{fieldName}} must not be greater than
        {{errors.maxPercentageExceeded}}</span>

    <!-- phone and tax -->
    <span *ngIf="errors?.mobileNumber">Invalid Format.</span>
    <span *ngIf="errors?.taxId">Invalid Tax Id.</span>
    <span *ngIf="errors?.max">Maximum amount is {{errors?.max.max}}</span>

    <!-- date and dob -->
    <span *ngIf="errors?.dob">Invalid DOB Format.</span>
    <span *ngIf="errors?.dobbelow18">Age must be greater than 18.</span>
    <span *ngIf="errors?.outofrange">Date is out of range.</span>
    <span *ngIf="errors?.yearoutofrange">Year is out of range.</span>
    <span *ngIf="errors?.dateRangeInvalid">End date cannot be less than start date.</span>
    <span *ngIf="errors?.invalidDateFormat">Valid Date Format (MM/DD/YYYY)</span>

    <!-- Amount field -->
    <span *ngIf="errors?.invalidAmount">Invalid amount</span>
    <span *ngIf="errors?.maxAmountExceeded">The maximum amount allowed is {{errors.maxAmountExceeded}}</span>
    <span *ngIf="errors?.notNumeric">Amount should be in digits only.</span>
    <span *ngIf="errors?.whitespaceOnly">{{fieldName}} is Invalid.</span>
    <span *ngIf="errors?.invalidAmountDecimal"> The maximum allowed amount is 2 decimals. </span>
    <span *ngIf="errors?.multipleDots">Invalid amount: multiple dots detected.</span>
    <span *ngIf="errors?.divisibleBy25">Invalid amount: The amount must be divisible by 25.</span>

    <!-- Password erros -->
    <span *ngIf="errors?.minlength && !showDefaultMinlengthError">A minimum of 8 characters are required.</span>
    <span *ngIf="errors?.hasUppercase">A minimum of 1 uppercase is required.</span>
    <span *ngIf="errors?.hasLowercase">A minimum of 1 lowercase is required.</span>
    <span *ngIf="errors?.hasDigit">A minimum of 1 digit is required.</span>
    <span *ngIf="errors?.noSpace">It cannot contain any spaces.</span>
    <span *ngIf="errors?.hasSpecialChar">The following special characters are only allowed (!@#$%^&*)</span>

    <!-- Min Date error -->
    <span *ngIf="errors?.minDateError">Date must be greater than the allowed minimum date</span>
</div>