import { environment } from "src/environments/environment";

const USER_API_BASE_URL = environment.application.api_base_user_url;

function userApiUrl(endpoint: string) {
    return `${USER_API_BASE_URL}${endpoint}`;
}

export const userApiAssert = {
    login: userApiUrl("User/Login"),
    logout: userApiUrl("User/Logout"),
    refreshToken: userApiUrl("User/RefreshToken"),
    validateEmail: userApiUrl("User/validateEmail")
}