import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DateUtilService } from '../../services/date-util.service';

@Component({
  selector: 'app-timer',
  template: `
    {{ displayTime }}
  `,
})
export class TimerComponent implements OnInit {
  @Input() startTime!: string;
  @Input() isUTCStartTime = false;
  @Input() countdown = false;
  @Input() duration = 0;
  @Output() remainingTickEvent: EventEmitter<number> = new EventEmitter<number>(); // Event emitter to send updated time

  displayTime!: string;
  startTimeWithFormat!: string;

  constructor(private dateUtilService: DateUtilService) { }

  ngOnInit() {
    if (this.countdown) {
      this.startCountDown();
    } else {
      this.startCountUp();
    }
    this.startTimeWithFormat = this.isUTCStartTime === true?
      this.dateUtilService.convertToUTCDateTimeFormat(this.startTime):  this.startTime;
  }

  startCountUp() {
    setInterval(() => {
      const timeDifference = this.dateUtilService.getTimeDifferenceFromNow(this.startTimeWithFormat, this.isUTCStartTime);
      const elapsedSeconds = Math.floor(timeDifference / 1000);
      this.displayTime = this.formatTime(elapsedSeconds);
    }, 1000);
  }

  startCountDown() {
    const interval = setInterval(() => {
      const timeDifference = this.dateUtilService.getTimeDifferenceFromNow(this.startTimeWithFormat, this.isUTCStartTime);
      const elapsedSeconds = Math.floor(timeDifference / 1000);
      const remainingTime = (this.duration * 60) - elapsedSeconds;
      if (remainingTime <= 0) {
        this.displayTime = '00:00';
        this.remainingTickEvent.emit(0);
        clearInterval(interval);
      }
      else {
        this.displayTime = this.formatTime(remainingTime);
        this.remainingTickEvent.emit(remainingTime);
      }

    }, 1000);

  }

  formatTime(totalSeconds: number): string {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${this.padTime(minutes ?? 0)}:${this.padTime(seconds ?? 0)}`;
  }

  padTime(val: number): string {
    return val > 0 && val < 10 ? `0${val}` : `${val}`;
  }

}
