import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
})
export class ValidationErrorComponent {
  @Input() errors: any;
  @Input() fieldName?:string
  @Input() errorMessage?:string
  @Input() showLength?=true;
  @Input() showDefaultMinlengthError?= true;
}