    <div class="auth-wrapper align-items-stretch aut-bg-img w-75% overflow-hidden login-main">
      <div class="flex-grow-1 row">
        <div class="col-xl-9 col-lg-7 col-md-6 login-background  login-head">
          <div class="h-100 d-md-flex align-items-center auth-side-img row login-image login-box">
            <div class="col-sm-10 auth-content m-l-auto p-4 login-stage">
              <h3>OrthoPMS</h3>
              <h1 class="my-3">Welcome Back!</h1>
              <h4 class="font-weight-normal login-font">Sign in to your account and explore OrthoPMS. It's smart, efficient, and simplifies your work.</h4>
            </div>
          </div>
        </div>
    <div class="col-xl-3 col-lg-5 col-md-6 auth-side-form login-content login-background">
      <div class="auth-content w-100">
        <h3 class="mb-4 d-block d-xl-none d-lg-none"></h3>
        <h3 class="mb-4 f-w-400">SignIn</h3>
        <form [formGroup]="loginForm" (ngSubmit)="login()">

          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"><i class="feather icon-mail"></i></span>
            <input type="text" id="username" placeholder="Email Id" formControlName="username" class="form-control rounded-end"  (input)="loginEmailChange()"
              [ngClass]="{ 'is-invalid': isFormSubmitted && formField['username'].errors }"  (blur)="onUserNameBlur()"/>
            <div class="validation_msg" *ngIf="isFormSubmitted || formField['username'].touched">
              <span *ngIf="formField['username'].hasError('required')">User Name is required</span>
              <span *ngIf="formField['username'].hasError('pattern')">User Name is invalid. Please check that you have entered a
                valid User Name.</span>
            </div>
          </div>

          <div class="input-group mb-4">
            <span class="input-group-text" id="basic-addon2"><i class="feather icon-lock"></i></span>
            <input [type]="passwordInputType" id="password" placeholder="Password"  maxlength="21" formControlName="password" (input)="clearErrorMessage()"
              class="form-control rounded-end"  (keydown)="onKeyDown($event)"
              [ngClass]="{ 'is-invalid': isFormSubmitted && loginForm.controls['password'].errors}" />
            <i class="feather {{ passwordInputType == 'password' ? ' icon-eye' : ' icon-eye-off' }}  password-show f-12" style="z-index: 99;" id="togglePassword" 
              *ngIf="loginForm.value.password"
              (click)="togglePasswordEvent()"></i>
            <div class="validation_msg" *ngIf="isFormSubmitted || formField['password'].touched">
              <div *ngIf="formField['password'].hasError('required')">Password is required</div>
            </div>
          </div>

          <div class="input-group mb-4 location-dropdown-panel"  *ngIf="showLocation" >
            <span class="input-group-text" id="basic-addon2"><i class="feather icon-map-pin"></i></span>
            <app-location-dropdown class="form-control border-0 p-0 rounded-end" placeholder="Location" formControlName="locationId" [isLoginPage]="showLocation"
              [required]="false" [emailId]="loginForm.value.username" [loadDefaultLocation]="true">
            </app-location-dropdown>
          </div>
          <div class="d-grid">
            <button type="submit" class="btn btn-block btn-primary mb-0 signin-border" [disabled]="isSignInEnabled">
              <span class="me-2" *ngIf="isLoading">
                <img src="../../../../../../assets/images/svg/spinner-loader.svg">
              </span>
              <span *ngIf="!isLoading">Signin</span>
            </button>
          </div>
          <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{ errorMessage }}</div>
        </form>
        <div class="text-center">
          <div class="saprator my-4 mt-3"><span>OR</span></div>
          <p class="mb-3 text-muted mt-3"><a class="f-w-400" [routerLink]="['/forgot-Password']">Forgot password </a></p>
        </div>
      </div>
    </div>
  </div>
</div>