import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class InLayService {
    private showInlaySubject: Subject<boolean> = new Subject<boolean>();
    private inlayTemplateContentSubject: Subject<any> = new Subject<any>();
    private loadingInlaySubject: Subject<boolean> = new Subject<boolean>();


    showInlay(event: boolean): void {
        this.showInlaySubject.next(event);
    }

    get showInlayResponse$() {
        return this.showInlaySubject.asObservable();
    }
    
    inlayTemplateContent(event: any): void {
        this.inlayTemplateContentSubject.next(event);
    }

    get inlayTemplateContentResponse$() {
        return this.inlayTemplateContentSubject.asObservable();
    }


    loadingInlay(event: boolean): void {
        this.loadingInlaySubject.next(event);
    }

    get loadingInlayResponse$() {
        return this.loadingInlaySubject.asObservable();
    }
}