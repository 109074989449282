import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { patientCommunicationApiAsset } from "../constant/patient-communication-api-asset";
import { AuthService } from "../../user/services/authentication/auth.service";
import { CommunicationSms, CommunicationStatusState } from "../store/patient-communication.state";
import { ChatReadEvent, ChosenPatientChat, PatientChatCollection, RecentPatientChat, UnreadPatientsChatCollection } from "../store/chat-store/chat.state";

@Injectable()

export class ChatApiService {

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  public getPatientChatCollection(patientPhone?: any) {
    const param = { 'practiceId': this.authService.getCurrentPracticeId(), 'practiceLocationId': '', 'patientPhone': patientPhone }
    return this.http.get<PatientChatCollection>(patientCommunicationApiAsset.getPatientChatCollection, { params: param });
  }

  public getRecentPatientChatCollection() {
    let params = { practiceId: this.authService.getCurrentPracticeId() }
    return this.http.get<RecentPatientChat[]>(patientCommunicationApiAsset.getRecentPatientChatCollection, { params: params });
  }

  sendChatSms(chatSMS: CommunicationSms) {
    return this.http.post<CommunicationStatusState>(patientCommunicationApiAsset.sendSms, chatSMS);
  }

  public getPatientByPhone(phone: string) {
    return this.http.get<ChosenPatientChat[]>(patientCommunicationApiAsset.getPatientByPhone + '?practiceId=' + this.authService.getCurrentPracticeId() + '&phone=' + phone);
  }

  markAsRead(chatread: ChatReadEvent) {
    return this.http.post<CommunicationStatusState>(patientCommunicationApiAsset.markAsRead, chatread);
  }

  public getUnreadPatientsChatCollection() {
    let params = { practiceId: this.authService.getCurrentPracticeId() }
    return this.http.get<UnreadPatientsChatCollection[]>(patientCommunicationApiAsset.getUnreadChatCollection, { params: params });
  }
}