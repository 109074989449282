<span class="badge signalrStatus hei-35 wid-35 d-flex align-items-center justify-content-center p-0 me-1" [ngxTippy]="this.connectionStatus.title" [tippyProps]="{ trigger: 'mouseenter'}"
    [ngStyle]="{
        'cursor':(this.connectionStatus.status === signalRConnectionStatus.disconnected.name || 
        this.connectionStatus.status === signalRConnectionStatus.connectionError.name) ? 'pointer' : 'default'}"
    (click)="reConnectSignalr()" *appUserHasPermission="scheduleFeaturePermissionCreate">
    <ng-container *ngIf="this.connectionStatus.shortTerm !== signalRConnectionStatus.reconnecting.shortTerm && this.connectionStatus.shortTerm !== signalRConnectionStatus.disconnected.shortTerm">
        <img class="wid-20" src="{{this.connectionStatus.image}}">
    </ng-container>
    <img [class.d-none]="this.connectionStatus.shortTerm !== signalRConnectionStatus.reconnecting.shortTerm" class="wid-20" src="assets/images/svg/signal-Reconnecting.svg">
    <img [class.d-none]="this.connectionStatus.shortTerm !== signalRConnectionStatus.disconnected.shortTerm" class="wid-20" src="assets/images/svg/signal-Disconnected.svg">
</span>