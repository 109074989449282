<app-card [cardTitle]="'Personal Information'">

    <form [formGroup]="primaryInformation">

        <div class="row">

            <div class="col-lg-4">
                <app-form-control-textbox label="First Name" focusPlaceholder="John" formControlName="firstName"
                    [maxlength]="101" [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-4">
                <app-form-control-textbox label="Last Name" focusPlaceholder="Smith" formControlName="lastName"
                    [maxlength]="101" [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-4">
                <app-form-control-textmask label="Phone" formControlName="phone" [appNumberMask]="maskingType.Phone"
                    placeholder="Phone" [required]="true">
                </app-form-control-textmask>
            </div>

            <div class="col-lg-4">
                <app-form-control-textbox label="Email" formControlName="email" [maxlength]="101" [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-12">
                <div class="border bg-waring p-2">
                    <pre>{{this.primaryInformation.value | json}}</pre>
                    <div>
                        <span class="fw-bold">Form Status : </span>
                        <span class="fw-bold"
                            [ngClass]="{'text-danger':!this.primaryInformation.valid,'text-success': this.primaryInformation.valid}">
                            {{this.primaryInformation.status}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </form>

</app-card>