import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { MenuItem } from "./menu.state";
import { AuthService } from "src/app/features/user/services/authentication/auth.service";
import { featureName, moduleName, operationName, roles } from "src/app/features/user/constant/user-role-constant";
import { DashboardSubMenuItems } from "../../constant/menu-item-constant";
import { UserAuthService } from "src/app/features/user/services/user-auth.sevice";
import { practiceMenuItemConstant as practiceSubMenuItemConstant } from "../../constant/menu-practice-constant";
import { superAdminSubMenuItems } from "../../constant/menu-superadmin-constant";
import { ReportSubMenuItems } from "../../constant/menu-item-report.contant";
import { PatientSubMenuItems } from "../../constant/menu-item-patient-constant";
import { UserRoleService } from "src/app/features/user/services/user-role.service";
import { PracticeUserPermission } from "src/app/features/practice/store/user-roles-store/user-role-state";
import { PracticeFeaturePermissionService } from "src/app/features/user/services/practice-feature-permission-service";

@Injectable()
export class MenuService {
    userRole = "";
    mainMenuItems: MenuItem[] = []
    practiceId = 0;
    permission: PracticeUserPermission | null = null;

    constructor(private authService: AuthService, private userRoleService: UserRoleService,
        private userAuthService: UserAuthService, private practiceFeatureService: PracticeFeaturePermissionService) {
        this.generateMainMenus();
    }
    generateMainMenus() {
        this.permission = this.authService.getUserRolesAndPermission();
        this.userRole = this.authService.getUserRoleFromToken();
        this.practiceId = this.authService.getPracticeIDFromToken();
        if (this.userRole) {
            this.mainMenuItems = [
                {
                    id: 1,
                    title: 'Dashboard',
                    type: 'item',
                    url: (this.userRole === roles.patient || this.userRole === roles.patientFRP) ? '/dashboard' :  this.userRoleService.getRouteUrlByUserPermission(this.permission),
                    classes: 'nav-item',
                    icon: 'assets/images/menuicon/dashboard.png',
                    children: this.getDashboadSubMenuWithFunction(),
                    hidden: !this.userAuthService?.hasPermission([
                        { moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.all},
                        { moduleName:moduleName.serviceContract,featureName:featureName.serviceContract,operationName:operationName.all},
                        { moduleName:moduleName.insurance,featureName:featureName.insuranceClaim,operationName:operationName.all },
                        { moduleName:moduleName.paymentManagement,featureName:featureName.paymentPatient,operationName:operationName.all },
                        { moduleName:moduleName.paymentManagement,featureName:featureName.paymentInsurance,operationName:operationName.all },
                        { moduleName:moduleName.paymentManagement,featureName:featureName.paymentOtherThanCard,operationName:operationName.all },
                        { moduleName:moduleName.patient,featureName:featureName.insuranceBenefit,operationName:operationName.all }
                      ]),
                },
                {
                    id: 2,
                    title: 'Patient',
                    type: 'item',
                    url: '/patient',
                    classes: 'nav-item',
                    icon: 'assets/images/menuicon/patient.png',
                    children: this.getPatientSubMenuWithFunction(),
                    hidden: true
                },
                {
                    id: 3,
                    title: 'Schedule',
                    type: 'item',
                    url: '/schedule',
                    classes: 'nav-item',
                    icon: 'assets/images/menuicon/schedule.png',
                    children: this.getScheduleSubMenu(),
                    hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.create }])
                },
                {
                    id: 4,
                    title: 'Report',
                    type: 'item',
                    url: '/report/dashboard',
                    classes: 'nav-item',
                    icon: 'assets/images/menuicon/Report.png',
                    children: this.getReportSubMenuWithFunction(),
                    hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.report,featureName:featureName.report,operationName:operationName.create}])
                },
                {
                    id: 5,
                    title: 'Practice',
                    type: 'item',
                    url: `practice/practice-personal-lookup/personal/${this.practiceId}`,
                    classes: 'nav-item',
                    icon: 'assets/images/menuicon/dental-clinic.png',
                    children: this.getPracticeSubMenuWithFunction(),
                    hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all}])
                },
                {
                    id: 6,
                    title: 'Admin',
                    type: 'item',
                    url: '/admin',
                    classes: 'nav-item',
                    icon: 'assets/images/menuicon/dental-clinic.png',
                    children: this.getsuperAdminSubMenuWithFunction(),
                    hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practiceList,operationName:operationName.create}])
                },
                {
                    id: 7,
                    title: 'Payment',
                    type: 'item',
                    url: '/Payment',
                    classes: 'nav-item',
                    icon: 'assets/images/menuicon/Report.png',
                    children: this.getPaymentSubMenu(),
                    hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.paymentManagement,featureName:featureName.paymentInsurance,operationName:operationName.create }]),
                },
                {
                    id: 8,
                    title: 'chat',
                    type: 'item',
                    url: '/chat',
                    classes: 'nav-item',
                    icon: 'src="assets/images/menuicon/sub-menu/chat.svg"',
                    children: this.getchatSubMenuWithFunction(),
                    hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.patient,featureName:featureName.chatDashbard,operationName:operationName.all }]),
                },
            ];
        }
    }
    getMainMenu(): Observable<MenuItem[]> {
        if (this.mainMenuItems.length === 0 ||  this.practiceId !== this.authService.getPracticeIDFromToken()) {
            this.generateMainMenus();
        }
        return of(this.mainMenuItems);
    }
    resetMainMenuItems(): Observable<MenuItem[]> {
        return of(this.mainMenuItems = []);
    }
    /**
        * @deprecated Use `practiceMenuItemConstant` instead. Author Amzath
        * 
     */
    getPracticeSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Info',
                type: 'item',
                url: 'practice/practice-personal-lookup/personal/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/user.svg'
            },
            {
                id: 2,
                title: 'Lookup',
                type: 'item',
                url: 'practice/practice-lookup/locations/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/dentist.svg'
            },
            {
                id: 3,
                title: 'Users',
                type: 'item',
                url: 'practice/practice-users-lookup/users/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/roles.svg',
            },
            {
                id: 4,
                title: 'Schedule',
                type: 'item',
                url: 'practice/schedule-setup/chairsetup/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/schedule.svg',
                hidden: (this.userRole === roles.superAdmin)
            },
            {
                id: 5,
                title: 'Tx Card',
                type: 'item',
                url: 'practice/txcard-configuration/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/tx-card.svg',
            },
            {
                id: 6,
                title: 'Template',
                type: 'item',
                url: 'practice/template-config/letter/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/letter.svg'
            },
            {
                id: 7,
                title: 'Treatment',
                type: 'item',
                url: 'practice/practice-treatment/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/treatment.svg'
            },
            {
                id: 8,
                title: 'Medical',
                type: 'item',
                url: 'practice/practice-medical/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/medical.svg'
            },
            {
                id: 9,
                title: 'Assess',
                type: 'item',
                url: 'practice/practice-forms/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/forms.svg'
            }
        ]
    }
    /**
        * @deprecated Use `PatientSubMenuItems` instead. Author Amzath
        * 
     */
    getPatientSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Patient',
                type: 'item',
                url: '/patientinfo/patientdashboard/:id/patientsummary/:id',
                classes: 'nav-item ',
                icon: 'assets/images/menuicon/sub-menu/username.svg',
                hidden: (this.userRole === roles.patient || this.userRole === roles.patientFRP)
            },
            {
                id: 2,
                title: 'Patient',
                type: 'item',
                url: '/patientinfo/patientLoginInfo/patientsummary/:id',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/username.svg',
                hidden: !(this.userRole === roles.patient || this.userRole === roles.patientFRP)
            },
            {
                id: 3,
                title: 'Contract',
                type: 'item',
                url: !(this.userRole === roles.patient || this.userRole === roles.patientFRP) 
                     ? '/patientinfo/patientdashboard/:id/contractsummary/:id' 
                     : '/patientinfo/patientLoginInfo/contractsummary/:id',
                classes: 'nav-item custom-menu-width-25',
                icon: 'assets/images/menuicon/sub-menu/contractcard.svg',
            },
            {
                id: 4,
                title: 'TX Card',
                type: 'item',
                url: '/patientinfo/patientdashboard/:id/txcardsummary/:id',
                classes: 'nav-item custom-menu-width-25',
                icon: 'assets/images/menuicon/sub-menu/tx-chart.svg',
                hidden: (this.userRole === roles.patient || this.userRole === roles.patientFRP)
            },
            {
                id: 5,
                title: 'Insurance',
                type: 'item',
                url: !(this.userRole === roles.patient || this.userRole === roles.patientFRP) 
                     ? '/patientinfo/patientdashboard/:id/insurancesummary/:id'
                     : '/patientinfo/patientLoginInfo/insurancesummary/:id',
                classes: 'nav-item custom-menu-width-30',
                icon: 'assets/images/menuicon/sub-menu/insurance-menu.svg'
            },
            {
                id: 6,
                title: 'Card',
                type: 'item',
                url: !(this.userRole === roles.patient || this.userRole === roles.patientFRP) 
                    ?'/patientinfo/patientdashboard/:id/cardsummary/:id'
                    :'/patientinfo/patientLoginInfo/cardsummary/:id',
                classes: 'nav-item custom-menu-width-25',
                icon: 'assets/images/svg/add-card.svg'
            }
            ,
            {
                id: 7,
                title: 'Image',
                type: 'item',
                url: '/patientinfo/patientdashboard/:id/imagesummary/:id',
                classes: 'nav-item custom-menu-width-25',
                icon: 'assets/images/payment-imaging.png',
                hidden: (this.userRole === roles.patient || this.userRole === roles.patientFRP)
            }
        ]
    }

    getScheduleSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Schedule',
                type: 'item',
                url: '/schedule',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/schedule.svg',
                hidden:  !this.userAuthService?.
                    hasPermission([{ moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.create }]),
            }
        ]
    }
    /**
        * @deprecated Use `ReportSubMenuItems` instead. Author Amzath
        * 
     */
    getReportSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Dashboard',
                type: 'item',
                url: 'report/dashboard',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/report.svg',
            },
            {
                id: 2,
                title: 'Heartbeat',
                type: 'item',
                url: 'report/heartbeat',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/exam-report.svg',
                children: this.getExamReportSubMenu(),
            },
            {
                id: 3,
                title: 'Appointment',
                type: 'item',
                url: 'report/appointment',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/schedule-report.svg',
                children: this.getScheduleReportSubMenu(),
            },
            {
                id: 4,
                title: 'Collection',
                type: 'item',
                url: 'report/collection',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/payment-report.svg',
                children: this.getPaymentReportSubMenu(),
            },
            {
                id: 5,
                title: 'Contract',
                type: 'item',
                url: 'report/contract',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/contract-report.svg',
                children: this.getContractReportSubMenu(),
            }
        ]
    }
     /**
        * @deprecated Use `DashboardSubMenuItems` instead. Author Amzath
        * 
     */
    getDashboadSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Clinical',
                type: 'item',
                url: '/dashboard/clinical',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/Dashboard.svg',
                hidden: (this.userRole === roles.patientFRP || this.userRole === roles.patient)
            },
            {
                id: 2,
                title: 'Financial',
                type: 'item',
                url: '/dashboard/financial',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/profit.svg',
                hidden:  (this.userRole ===  roles.patientFRP || this.userRole ===  roles.patient)
            },
            {
                id: 3,
                title: 'Insurance',
                type: 'item',
                url: '/dashboard/insurance',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/insurance.svg',
                hidden: (this.userRole !== roles.superAdmin)
            },
            {
                id: 4,
                title: 'Framework',
                type: 'item',
                url: '/uiframework',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/framework.svg',
                hidden: true
            }
        ]
    }

    getPaymentSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Payment',
                type: 'item',
                url: '/payment/insurance-payment',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/payment-dollar.svg',
                hidden:  !this.userAuthService?.
                    hasPermission([{ moduleName:moduleName.paymentManagement,featureName:featureName.paymentInsurance,operationName:operationName.create }]),
            }
        ]
    }

    /**
        * @deprecated Use `superAdminSubMenuItems` instead. Author Amzath
        * 
     */
    getSuperAdminSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Admin',
                type: 'item',
                url: '/admin',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/users.svg',
                hidden: !(this.userRole === roles.superAdmin)
            }
        ]
    }
    /**
        * @deprecated Use `ReportSubMenuItems` instead. Author Amzath
        * 
     */
    getExamReportSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Conversion',
                type: 'item',
                url: 'report/heartbeat/conversion',
                classes: 'nav-item'
            },
            {
                id: 1,
                title: 'Result',
                type: 'item',
                url: 'report/heartbeat/results',
                classes: 'nav-item'
            }
        ]
    }
    /**
        * @deprecated Use `ReportSubMenuItems` instead. Author Amzath
        * 
     */
    getScheduleReportSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Summary',
                type: 'item',
                url: 'report/appointment/summary',
                classes: 'nav-item'
            },
            {
                id: 2,
                title: 'Detail',
                type: 'item',
                url: 'report/appointment/detail',
                classes: 'nav-item'
            },
            {
                id: 3,
                title: 'Detail with Payment',
                type: 'item',
                url: 'report/appointment/detail-with-payment',
                classes: 'nav-item'
            }
        ]
    }
    /**
        * @deprecated Use `ReportSubMenuItems` instead. Author Amzath
        * 
     */
    getPaymentReportSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Detail',
                type: 'item',
                url: 'report/collection/detail',
                classes: 'nav-item',
            },
            {
                id: 2,
                title: 'Daily Invoice',
                type: 'item',
                url: 'report/collection/daily-invoice',
                classes: 'nav-item',
            }
        ]
    }
    /**
        * @deprecated Use `ReportSubMenuItems` instead. Author Amzath
        * 
     */
    getContractReportSubMenu(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Starts',
                type: 'item',
                url: 'report/contract/starts',
                classes: 'nav-item'
            }
        ]
    }
    
    private processMenuItems(menuItems: MenuItem[]): MenuItem[] {
        return menuItems.map((item) => {
            let newItem = { ...item };

            if (item.hiddenFunction) {
                newItem = { ...newItem, hidden: item.hiddenFunction(this.userRole, this.userAuthService, this.practiceFeatureService) };
            }

            if (item.urlFunction) {
                newItem = { ...newItem, url: item.urlFunction({ practiceId: this.practiceId }) };
            }

            return newItem;
        });
    }

    getDashboadSubMenuWithFunction(): MenuItem[] {
        return this.processMenuItems(DashboardSubMenuItems);
    }

    getPracticeSubMenuWithFunction(): MenuItem[] {
        return this.processMenuItems(practiceSubMenuItemConstant);
    }

    getsuperAdminSubMenuWithFunction(): MenuItem[] {
        return this.processMenuItems(superAdminSubMenuItems);
    }
    
    getReportSubMenuWithFunction(): MenuItem[] {
        return this.processMenuItems(ReportSubMenuItems);
    }

    getPatientSubMenuWithFunction(): MenuItem[] {
        return this.processMenuItems(PatientSubMenuItems);
    }
    getchatSubMenuWithFunction(): MenuItem[] {
        return [
            {
                id: 1,
                title: 'Chat',
                type: 'item',
                url: '/chat',
                classes: 'nav-item',
                icon: 'assets/images/menuicon/sub-menu/chat.svg',
                hiddenFunction: (userRole, userAuthService) =>
                    !userAuthService?.hasPermission([{ moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.view }]),
            }
        ]
    }
}