import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GetPatientLiveAppointmentsByDate, PatientAllergy } from '../store/patient-live-status-store/patient-live-status-state';
import { patientLiveStatusApiAsset } from '../constant/patient-live-status-api-asset';
import { Appointments, Appointment, SchedulerConfiguration, PracticeLocation } from '../../scheduler/store/scheduler-store/scheduler-state';

@Injectable()
export class PatientLiveStatusApiService {

  constructor(private http: HttpClient) { }

  GetPatientAppointmentsByDate(payLoad: GetPatientLiveAppointmentsByDate) {
    const params = new HttpParams()
      .set('practiceLocationId', payLoad.practiceLocationId)
      .set('startDate', payLoad.startDate)
      .set('endDate', payLoad.endDate)
      .set('pageNumber', payLoad.pageNumber)
      .set('pageSize', payLoad.pageSize);
    return this.http.get<Appointments[]>(patientLiveStatusApiAsset.getPatientAppointmentsByDate, { params: params });
  }

  savePatientAppointment(appointment: Appointment) {
    return this.http.post<Appointments>(patientLiveStatusApiAsset.savePatientAppointment, appointment);
  }

  GetPracticeChairConfig(practiceId: number, practiceLocationId: number) {
    const params = new HttpParams()
      .set('practiceId', practiceId.toString())
      .set('practiceLocationId', practiceLocationId.toString());
    return this.http.get<SchedulerConfiguration[]>(patientLiveStatusApiAsset.getPracticeChairConfigByLocationId, { params: params });
  }

  getPracticeLocations() {
    return this.http.get<PracticeLocation[]>(patientLiveStatusApiAsset.getPracticeLocations);
  }

  getPatientAllergies(patientId: number) {
    const params = new HttpParams()
      .set('patientId', patientId.toString());
    return this.http.get<PatientAllergy[]>(patientLiveStatusApiAsset.getPatientAllergies, { params: params });
  }

}