import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InLayService } from './inlay.service';
import { DebounceService } from '../../services/debounce-service';
import { OverlayBaseComponent, Permission } from './overlay.base.component';
import { UserAuthService } from 'src/app/features/user/services/user-auth.sevice';

@Component({
  selector: 'app-overlay',
  template: `
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <ng-template #offcanvas let-offcanvas>
          <ng-container *ngIf="isOverlayAuthorized">
            <div class="offcanvas-header justify-content-between" *ngIf="!this.hideHeader">
              <h5 class="offcanvas-title" id="offcanvasBottomLabel" [innerHTML]=title></h5>
              <button type="button" class="btn btn-light-primary wid-35 hei-35 d-flex align-items-center justify-content-center border-radius-50"
                aria-label="Close" (click)="closeOffcanvas()" [title]="'Close'" *ngIf='isShowCloseButton'>
                <i class="feather icon-x f-18"></i>
              </button>
            </div>
            <div class="offcanvas-body">
              <ng-container *ngIf="hideHeader && isShowInLay">
                <button type="button" class="btn btn-light-primary wid-35 hei-35 d-flex align-items-center justify-content-center border-radius-50 inlay_close_button"
                aria-label="Close" [title]="'Close'" (click)="closeOffcanvas()" *ngIf='isShowCloseButton'>
                <i class="feather icon-x f-18"></i> 
                </button> 
              </ng-container>
              <ng-content></ng-content>
            </div>
          </ng-container>
          <div *ngIf="!isOverlayAuthorized && isCloseOverlayAuthorized">
              <app-page-no-permission [redirectSamePage]="true" (backToSamePage)="closeAuthorizedOverlay()"></app-page-no-permission>
          </div>
        </ng-template>
      </div>
    </div>
  `,
})

export class OverlayComponent extends OverlayBaseComponent implements OnInit, OnChanges, OnDestroy , AfterViewInit  {

  @Input() title?: string;
  @Input() position?: string;
  @Input() canvasSize?: string;
  @Input() closeOverlay: boolean;
  @Input() isShowCloseButton?: boolean;
  @Input() isShowInLay?: boolean;
  @Input() isFullOverlay?: boolean = false;
  @Input() hideHeader?: boolean;
  @Input() permissionSets: Permission[] = [];
  isEmitted = false;
  isCloseOverlayAuthorized = true;

  @Output() onClose = new EventEmitter();

  @ViewChild('offcanvas', { static: true }) offcanvas!: TemplateRef<any>;
  /* 
  position top bottom  need to apply below class
  tb_canvas_sm, tb_canvas_md, tb_canvas_lg, tb_canvas_xl
  =====================================================
  position right left  need to apply below class
  rl_canvas_xl, rl_canvas_lg, rl_canvas_md, rl_canvas_sm
 */
  subscription$: Subscription = new Subscription();
  constructor(private offcanvasService: NgbOffcanvas, private router: Router,
    private inLayService: InLayService, private debounceService: DebounceService, authService: UserAuthService) {
    super(authService);
    this.closeOverlay = false;
    this.isShowCloseButton = true;
    this.isShowInLay = false;
    this.hideHeader = false;
    this.closeOffcanvas();
  }

  override ngOnInit(): void {
    this.permissionSet = this.permissionSets;
    super.ngOnInit();
    if (this.isShowInLay) {
      this.inLayService.showInlay(true);
    }
    this.debounceService.debounce(this.title ?? '', () => {
      this.openOffCanvas(this.position);
    }, 10);
    this.subscription$.add(
      this.router.events.subscribe(() => {
        if (!this.isEmitted) {
          this.closeOffcanvas();
        }
      }));
  }

  ngOnChanges(): void {
    if (this.closeOverlay) {
      this.closeOffcanvas();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isShowInLay) 
           window.scrollTo(0, 0);
    }, 100);
  }

  openOffCanvas(position: string | undefined) {
    let canvasPosition: NgbOffcanvasOptions = this.configureCanvasPosition(position);
    if (!this.isFullOverlay) {
      this.configureOverlaySettings(canvasPosition);
    }
    this.configureDismissHandler(canvasPosition);
    this.executeOffCanvas(canvasPosition);
  }

  private configureCanvasPosition(position: string | undefined): NgbOffcanvasOptions {
    let canvasPosition: NgbOffcanvasOptions = {};
    switch (position) {
      case 'top':
        canvasPosition = { position: 'top', scroll: true, backdrop: true, panelClass: this.canvasSize ,keyboard : this.isFullOverlay ? false : true };
        break;
      case 'bottom':
        canvasPosition = { position: 'bottom', scroll: true, backdrop: true, panelClass: this.canvasSize ,keyboard : this.isFullOverlay ? false : true };
        break;
      case 'right':
        canvasPosition = { position: 'end', scroll: true, backdrop: true, panelClass: this.canvasSize ,keyboard : this.isFullOverlay ? false : true };
        break;
      case 'left':
        canvasPosition = { scroll: true, backdrop: true, panelClass: this.canvasSize ,keyboard : this.isFullOverlay ? false : true };
        break;
    }
    return canvasPosition;
  }

  private configureOverlaySettings(canvasPosition: NgbOffcanvasOptions): void {
    
    canvasPosition.container = this.isShowInLay ? '.inlayhost' : '.overlayhost';
    canvasPosition.backdrop = this.isShowInLay ? false : true;
    canvasPosition.scroll = this.isShowInLay ? true : false;
    canvasPosition.animation = this.isShowInLay ? false : true;
    canvasPosition.panelClass = `${canvasPosition.panelClass} ${this.isShowInLay ? 'Inlay_design' : 'overlay_design'}`;
    canvasPosition.keyboard = this.isShowInLay ? false : true;
  }

  private configureDismissHandler(canvasPosition: NgbOffcanvasOptions): void {
    canvasPosition.beforeDismiss = () => {
      this.isEmitted = true;
      return true;
    };
  }

  private executeOffCanvas(canvasPosition: NgbOffcanvasOptions): void {
    document.body.classList.add('offcanvas-open');
    this.offcanvasService.open(this.offcanvas, canvasPosition).result.then(
      (result) => {
      },
      (reason) => {
        document.body.classList.remove('offcanvas-open');
        this.onClose.emit();
      }
    );
  }

  closeOffcanvas() {
    document.body.classList.remove('offcanvas-open');
    this.offcanvasService.dismiss();
    this.inLayService.showInlay(false);
  }

  closeAuthorizedOverlay() {
    this.isCloseOverlayAuthorized = false;
    this.closeOffcanvas();
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}