import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectPracticeProviders, selectProvidersErrorMessage } from '../../store/selector/practice-core-selector';
import { invokeGetProviders } from '../../store/reducer/practice-core.reducer';
import { Subscription, map } from 'rxjs';
import { PracticeProvider } from '../../store/state/practice-core';

@Component({
  selector: 'app-provider-dropdown',
  template: `
  
          <div class="form-group" [class.has-error]="hasError()">
              <label *ngIf="label">{{ label }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>
              
              <ng-select 
                [items]="providers" 
                [closeOnSelect]="true" 
                [multiple]="isMultiple"
                [searchable]="true" 
                bindLabel="providerName" 
                [placeholder]="placeholder"
                bindValue='providerId' 
                [ngModel]="updatedValue"
                
                (ngModelChange)="selectBoxChanged($event)"
                (blur)="onTouched()"
              >
              </ng-select>

              <div class="validation_msg" *ngIf="errorMessage$ | async" >
                <span> error : {{errorMessage$ | async}}</span>
              </div>

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="label">
                </app-validation-error>
              </ng-container>
          </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProviderDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProviderDropdownComponent),
      multi: true
    }
  ],
})

export class ProviderDropdownComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {

  @Input() label!: string;
  @Input() required?: boolean | false;
  @Input() isMultiple!: boolean | false;
  @Input() showActiveProviders?: boolean = true;
  @Input() enableAllProviders?: boolean = false;
  @Input() placeholder!: string;

  control: AbstractControl | undefined;
  updatedValue: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled!: boolean;
  providers: PracticeProvider[] = [];

  private subscriptions$ = new Subscription();
  selectProviders$: any = this.store.select((selectPracticeProviders));
  errorMessage$ = this.store.select((selectProvidersErrorMessage));

  constructor(private store: Store) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.showActiveProviders = changes['showActiveProviders']?.currentValue;
    this.subscriptions$.add(this.selectProviders$.subscribe((practiceProvider: PracticeProvider[]) => { this.bindProviders(practiceProvider); }));
  }

  ngOnInit(): void {
    this.store.dispatch(invokeGetProviders());
    this.subscriptions$.add(this.selectProviders$.subscribe((practiceProvider: PracticeProvider[]) => { this.bindProviders(practiceProvider); }));
  }

  bindProviders(providers: PracticeProvider[]) {
    if (!providers || providers.length === 0) {
      this.providers = [];
    }
    const providerList = this.showActiveProviders ? [...providers].filter(x => x.isActive) : [...providers];
    this.providers = [...providerList].map((item) => {
      let label = '';
      if (item.lastName && item.firstName) {
        label = `${item.lastName} , ${item.firstName}`;
      } else {
        label = item.providerInitials;
      }

      return { ...item, providerName: item.providerName ?? label, disabled: this.enableAllProviders ? false : (!item.isActive ?? true) };
    }).sort((a, b) => a.providerName.localeCompare(b.providerName));
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.updatedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeEvent(event: any) {
    this.control?.markAsTouched();
    this.onChange(event)
  }

  selectBoxChanged(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }
}