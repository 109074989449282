import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-modal',
  template: `
    <ng-template #popupmodal let-modal>
      <div class="modal-header patient-chart-customize justify-content-between border-0">
       <h5 class="modal-title" id="exampleModalLiveLabel" [innerHTML]=title></h5>
        <button type="button" class="btn btn-light-primary wid-35 hei-35 d-flex align-items-center justify-content-center border-radius-50"
          aria-label="Close" (click)="closeModal()"  *ngIf="showCloseIcon">
          <i class="feather icon-x f-18"></i>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
})

export class PopupModalComponent implements OnInit, OnChanges {

  @Input() size?: string;
  @Input() title?: string;
  @Input() closeModel: boolean;
  @Input() customClass?: string;
  @Input() backdrop: boolean | undefined;
  @Input() showCloseIcon: boolean = true;

  @Output() onClose = new EventEmitter();
  @ViewChild('popupmodal', { static: true }) popupmodal!: TemplateRef<any>;

  constructor(private modalService: NgbModal) {
    this.closeModel = false;
    this.customClass = '';
  }
  
  private modalRef: NgbModalRef | null = null;

  ngOnInit() {
    this.openModal();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['closeModel']?.firstChange) {
      this.modalService.dismissAll();
    }
  }


  openModal() {
    let modalOptions: NgbModalOptions = { backdrop: this.backdrop ? 'static' : undefined }; 
    modalOptions.windowClass =  this.customClass;
    switch (this.size) {
      case 'small':
        modalOptions.size = 'sm';
        break;
      case 'large':
        modalOptions.size = 'lg';
        break;
      case 'extralarge':
        modalOptions.size = 'xl';
        break;
      case 'fullscreen':
        modalOptions.fullscreen = true;
        break;
      case 'center':
        modalOptions.centered = true;
        break;
      case 'medium':  
        modalOptions.ariaLabelledBy = 'modal-basic-title';
        break;
    }
    this.modalRef = this.modalService.open(this.popupmodal, modalOptions);
    this.modalRef.result.then(
      (result) => {},
      (reason) => {
        this.onClose.emit();
      }
    );
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close('Close click');
      this.onClose.emit();
    }
  }
}
 