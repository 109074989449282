<div class="main_body_section" [ngClass]="{ 'main_body_patient_section': isPatientUser}">

  <!-- Side nav bar -->
    <app-navigation *ngIf="!isSelfCheckinUser" class="pcoded-navbar navbar-collapsed" [ngClass]="{
      'theme-horizontal': this.gradientConfig['layout'] === 'horizontal',
      'mob-open': navCollapsedMob}" (NavCollapsedMob)="navMobClick()"></app-navigation>

  <!-- Top nav bar-->
  <app-nav-bar
    class="main_header navbar pcoded-header navbar-expand-lg navbar-light flex-column pcoded-header-customize"
    (showChair)="chairHideShowEvent()" (patientLiveStatusEvent)="showHidePatientLiveStatus()"></app-nav-bar>

  <!-- Main content -->
  <div class="pcoded-main-container"
    [ngClass]="{'right_sidebar_opened': this.isVisibleChairLiveStatusPanel && !this.hideChair, '': !this.isVisibleChairLiveStatusPanel,'self-check-in-space':isSelfCheckinUser}">
    <div class="pcoded-wrapper"
      [ngClass]="{ container: this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2' }">
      <div class="pcoded-content">
        <div class="pcoded-inner-content">

          <div class="main-body position-relative">
            <div class="page-wrapper">
              <div [hidden]="isShowInlay">
                <router-outlet></router-outlet>
              </div>
            </div>
            <ng-container>
              <div *applicationFeature="'isVoIpenabled'">
                <app-phone-menu *appUserHasPermission="chatAndPhoneModulePermissionCreate"></app-phone-menu>
              </div>
              <div *applicationFeature="'isChatEnabled'">
                <app-chat-dialog-menu *appUserHasPermission="chatAndPhoneModulePermissionCreate"></app-chat-dialog-menu>
              </div>
            </ng-container>
            <!-- Inlay Section -->
            <div class="inlayhost"></div>
            <div class="chatPosition"></div>
          </div>

          <!-- Overlary Section -->
          <div class="overlayhost"></div>

        </div>
      </div>
    </div>
  </div>

  <!-- Right side bar -->
  <div class="right_sidebar_section hide_xs bg-white" *ngIf="this.isVisibleChairLiveStatusPanel && !this.hideChair">
    <app-chair-live-status></app-chair-live-status>
  </div>

  <div *ngIf="!this.hideChair" class="hide_sidebar_icon hide_xs"
    [title]="this.isVisibleChairLiveStatusPanel ? 'Hide Chair Live Status' : 'Show Chair Live Status'"
    [ngClass]="{'show-panel': isVisibleChairLiveStatusPanel, 'hide-panel': !isVisibleChairLiveStatusPanel}" (click)="chairHideShowEvent()">
    <i class="fa fa-solid collaspse_icon bg-info"
      [ngClass]="{'fa-angle-double-left': !this.isVisibleChairLiveStatusPanel, 'fa-angle-double-right': this.isVisibleChairLiveStatusPanel}"></i>
  </div>

  <app-configuration></app-configuration>

</div>