import { ChaiActionMenu } from "../types/shared-types"

export const actionMenuItems =
{ 
    Assessment: "Findings",
    ManageAssess: "Mng Findings",
    Schedule: "Schedule",
    DeclineTx: "Decline Tx",
    DoNotPursue: "Do Not Pursue",
    FollowUp:"Follow-Up",
    ViewPaymentPlan: "View Payment Plan",
    SignContract: "Sign Contract",
    CancelSignAtHome: "Cancel Sign At Home",
    ResendPatientForm: "Resend Patient Form",
    AddNotes: "Add Notes", 
    SendNotification: "Send Notification",
    SendWelcomeEmail: "Send Welcome Email",
}

export const actionChairMenuItems =
{
    ViewPatient: "ViewPatient",
    ViewImages: "ViewImages",
    TxCard: "TxCard",
    CallDoctor: "CallDoctor",
    CallEmergency: "CallEmergency",
    DoctorIsPresent: "DoctorIsPresent",
    Doctorleft: "DoctorLeft",
    CancelCall: "CancelCall",
}

export const ChairActionMenus: ChaiActionMenu[] = [
  { name: 'View Patient', value: 'ViewPatient' },
  { name: 'View Images', value: 'ViewImages' },
  { name: 'Tx Card', value: 'TxCard' },
  { name: 'Call Doctor', value: 'CallDoctor' },
  { name: 'Call Emergency', value: 'CallEmergency' },
  { name: 'Doctor is Present', value: 'DoctorIsPresent' },
  { name: 'Doctor left', value: 'DoctorLeft' },
  { name: 'Cancel Call', value: 'CancelCall' }
]

export const overDueMenuItems =
{ 
    FollowUp:"Follow-Up",
    PayNow: "Pay Now",
    ShowInvoices: "Show Invoices"
}