import { Injectable, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientCommunicationActionService } from './patient-communication-action-service';
import { CallStateComponent } from '../component/phone/call-state/call-state.component';
import { ChatContentsComponent } from '../component/chat/chat-contents/chat-contents.component';
import { NewChatComponent } from '../component/chat/new-chat/new-chat.component';
import { AlertService } from '../../shared/utils/alert.service';
import { PatientCommunication, patientCommunicationConstants } from '../constant/patient-communication.constant';

@Injectable({
  providedIn: 'root'
})
export class PatientCommunicationPopUpService {

  private modalService = inject(NgbModal);
  private activePatientCommunicationPopups: PatientCommunication[] = [];
  openedPopups: any[] = [];
  maxAllowedPopups = 5;
  
  constructor(
    private patientCommunicationActionService: PatientCommunicationActionService, private alert: AlertService) {
    this.activePatientCommunicationPopups = this.patientCommunicationActionService.getActivePatientCommunicationPopups();
  }

  openPatientCommunicationPopup(communicationType: string, isIncomingCall: boolean = false, patientCommunciation?: any, activePopupLength: number = 0) {
    let modalRef: any
    if (communicationType === 'PatientCallDial' || communicationType === 'PatientIncomingCall') {
      modalRef = this.modalService.open(CallStateComponent, {
        container: ".chatPosition",
        backdrop: false,
        windowClass: `custom_window_class`,
      });
      modalRef.componentInstance.isIncomingCall = isIncomingCall;
    }
    if (communicationType === 'PatientChat') {
      modalRef = this.modalService.open(ChatContentsComponent, {
        container: ".chatPosition",
        backdrop: false,
        windowClass: `custom_window_class`,
      });
      modalRef.componentInstance.patientCommunication = patientCommunciation;

    }
    if (communicationType === 'PatientNewChat') {
      modalRef = this.modalService.open(NewChatComponent, {
        container: ".chatPosition",
        backdrop: false,
        windowClass: `custom_window_class`
      });
    }
    this.openedPopups.push(modalRef);
    modalRef.componentInstance.popupIndex = (activePopupLength - 1);
    modalRef.componentInstance.onClose.subscribe((index: number) => {
      this.closePopup(index);
    });
    document.body.classList.add('offcanvas-Chat');
  }

  closePopup(index: number) {
    const modalRef = this.openedPopups[index];
    if (modalRef) {
      modalRef.close();
      this.openedPopups.splice(index, 1);
      this.patientCommunicationActionService.onRemovePatientCommunication(index);
      if (this.openedPopups.length === 0) {
        document.body.classList.remove('offcanvas-Chat');
      }
    }
  }

  allowOpenPopup(length: number): boolean {
    if ((length + 1) > this.maxAllowedPopups) {
      this.alert.successToast(patientCommunicationConstants.communicationMaxPopup);
      return false;
    }
    return true;
  }

  closeChatPopupToAcceptIncomingCall(): boolean {
    if ((this.activePatientCommunicationPopups.length < this.maxAllowedPopups))
      return true;
    const chatPopups = this.activePatientCommunicationPopups.filter(popup => popup.communicationType === 'PatientNewChat' || popup.communicationType === 'PatientChat');
    if (chatPopups.length > 0) {
      const firstChatPopupIndex = this.activePatientCommunicationPopups.indexOf(chatPopups[0]);
      if (firstChatPopupIndex !== -1 && (this.activePatientCommunicationPopups.length === this.maxAllowedPopups)) {
        this.closePopup(firstChatPopupIndex);
        return true;
      }
    }
    return false;
  }

  resetOpenedPopups() {
    this.openedPopups = [];
  }
}