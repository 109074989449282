import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError,  map, mergeMap } from 'rxjs/operators';

import { PhoneCallApiService } from '../../api/phone-call.api.service';
import { getRecentCallSuccessLog, dialPhoneCall, dialPhoneCallFailure, dialPhoneCallSuccess, invokeGetRecentCallLog, invokeAnsweredCall, answeredCallSuccess } from './phone-call.action';

@Injectable()
export class PhoneCallEffects {

  getDialToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dialPhoneCall),
      mergeMap(action =>
        this.phoneCallApiService.getDialToken(action.dialCallInfo).pipe(
          map(dialToken => dialPhoneCallSuccess({ dialCallToken:dialToken, successMessage: action.successMessage })),
          catchError(error => of(dialPhoneCallFailure({ error: error })))
        )
      )
    )
  }
  );
  getRecentCallLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeGetRecentCallLog),
      mergeMap(action =>
        this.phoneCallApiService.getRecentCallLogs(action.practiceId,action.practiceLocationId,action.startDate,action.endDate).pipe(
          map(recentCallLogs => getRecentCallSuccessLog({ recentCallLogs: recentCallLogs})),
          catchError(error => of(dialPhoneCallFailure({ error: error })))
        )
      )
    )
  });
  callAnswered$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeAnsweredCall),
      mergeMap(action =>
        this.phoneCallApiService.callAnswered(action.dialCallInfo).pipe(
          map(recentCallLogs => answeredCallSuccess()),
          catchError(error => of(dialPhoneCallFailure({ error: error })))
        )
      )
    )
  });
  
  constructor(
    private actions$: Actions,
    private phoneCallApiService: PhoneCallApiService,
    private store: Store
  ) { }
}
