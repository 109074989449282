import { createAction, props } from "@ngrx/store";
import { OnlineSchedulingPatient,  OnlineSchedulingInformation, OnlinePracticeReferralConfiguration, OnlinePatientReferral, ConvertPatientReferraInformation, ConvertedPatientReferral } from "./online-schedule.state";

export const invokeConvertOnlineSchedulingPatientAppointment = createAction('[ConvertOnlineSchedulingPatientAppointment API] ConvertOnlineSchedulingPatientAppointment',
 props<{ convertPatient: OnlineSchedulingPatient , successMessage: string}>());
export const convertOnlineSchedulingPatientAppointmentSuccess = createAction('[ConvertOnlineSchedulingPatientAppointment API] ConvertOnlineSchedulingPatientAppointment Success', props<{ response: any }>());
export const convertOnlineSchedulingPatientAppointmentFailure = createAction('[ConvertOnlineSchedulingPatientAppointment API] ConvertOnlineSchedulingPatientAppointment Failure', props<{ errorMessage: string }>());

export const resetOnlineScheduleStore = createAction('[resetOnlineScheduleStore] resetOnlineScheduleStore');
export const enableOnlineSchedulePatientDashboard = createAction('[enableOnlineSchedulePatientDashboard] enableOnlineSchedulePatientDashboard',props<{ showOnlineScheduleDashboard: boolean }>());
export const resetOnlineScheduleStateEvent = createAction('[resetOnlineScheduleStateEvent] resetOnlineScheduleStateEvent');

export const invokeGetOnlineSchedule = createAction('[Online Schedule] Invoke Online Schedule', props<{ practiceId: number }>());
export const onlineGetScheduleSuccess = createAction('[Online Schedule] Online Schedule Success', props<{ onlineScheduleInformation: OnlineSchedulingInformation }>());
export const onlineGetScheduleFailure = createAction('[Online Schedule] Online Schedule Failure', props<{ errorMessage: string }>());

export const invokeUpdateOnlineSchedule = createAction('[UpdatePracticeOnlineSchedulingConfiguration] Update Practice Online Scheduling Invoke', props<{ updateOnlineScheduleInformation: OnlineSchedulingInformation}>());
export const updateOnlineScheduleSuccess = createAction('[UpdatePracticeSchedulingConfiguration] Update Practice Online Scheduling Success', props<{ updateOnlineScheduleInformation: OnlineSchedulingInformation}>());
export const updateOnlineScheduleFailure = createAction('[UpdatePracticeOnlineSchedulingConfiguration] Update Practice Online Scheduling Failure', props<{ errorMessage: string }>());

// Online Patient Referral

export const invokeGetPracticeReferralConfiguration = createAction('[invokeGetPracticeReferralConfiguration] Invoke Online Patient Referral', props<{ practiceId: number}>());
export const getPracticeReferralConfigurationSuccess = createAction('[invokeGetPracticeReferralConfiguration] Online Patient Referral Success', props<{ onlinePatientReferralConfiguration: OnlinePracticeReferralConfiguration }>());
export const getPracticeReferralConfigurationFailure = createAction('[invokeGetPracticeReferralConfiguration] Online Patient Referral Failure', props<{ errorMessage: string }>());

export const invokeSavePracticeReferralConfiguration = createAction('[invokeSavePracticeReferralConfiguration] Invoke Save Online Patient Referral', props<{ onlinePracticeReferralConfiguration: OnlinePracticeReferralConfiguration, successMessage: string  }>());
export const savePracticeReferralConfigurationSuccess = createAction('[invokeSavePracticeReferralConfiguration] Online Save Patient Referral Success', props<{ onlinePatientReferralConfiguration: OnlinePracticeReferralConfiguration }>());
export const savePracticeReferralConfigurationFailure = createAction('[invokeSavePracticeReferralConfiguration] Online Save Patient Referral Failure', props<{ errorMessage: string }>());

export const invokeGetPracticeReferrals = createAction('[invokeGetPracticeReferrals] Invoke Get Online Patient Referrals', props<{ practiceId: number }>())
export const getPracticeReferralsSuccess = createAction('[invokeGetPracticeReferrals] Online Get Patient Referrals Success', props<{ onlinePatientReferrals: OnlinePatientReferral[] }>());
export const getPracticeReferralsFailure = createAction('[invokeGetPracticeReferrals] Online Get Patient Referrals Failure', props<{ errorMessage: string }>());

export const convertReferralPatientInformation = createAction('[convertReferralPatientInformation] Convert Referral Patient Info', props<{convertPatientInformation : ConvertPatientReferraInformation | null}>() );

export const invokeRemovePracticeReferral = createAction('[invokeRemovePracticeReferral] Remove Referral Patient Info', props<{removePatient : any, successMessage: string}>());
export const removePracticeReferralSuccess = createAction('[invokeRemovePracticeReferral] Remove Referral Success', props<{referralInfo : any}>());
export const removePracticeReferralFailure = createAction('[invokeRemovePracticeReferral] Remove Referral Failure', props<{ errorMessage: string }>()); 

export const invokeSaveConvertedPatientReferral = createAction('[SaveConvertedPatient] Save Converted Patient Referral API', props<{ convertedPatientReferral: ConvertedPatientReferral }>());
export const saveConvertedPatientReferralSuccess = createAction('[SaveConvertedPatient] Save Converted Patient Referral API Success', props<{ convertPatientInfo: ConvertPatientReferraInformation }>());
export const saveConvertedPatientReferralFailure = createAction('[SaveConvertedPatient] Save Converted Patient Referral API Failure', props<{ errorMessage: string }>()); 