import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PracticeLocationsInfo } from '../state/practice-location';
import { PracticeInformation } from '../state/practice-info';
import { PracticeList } from '../state/practice-info';

// practice information store
export const selectPracticeSetupInfo = createFeatureSelector<PracticeInformation>('practiceInformationStore');

export const selectPracticeInfoStateEvent = createSelector(
    selectPracticeSetupInfo,
    (state: PracticeInformation) => state.practiceInfoStateEvents
);

export const selectPersonalInfo = createSelector(
    selectPracticeSetupInfo,
    (state: PracticeInformation) => state.practicePersonalInformation
);

export const selectFeaturesInfo = createSelector(
    selectPracticeSetupInfo,
    (state: PracticeInformation) => state.practiceFeaturesInformation
);

export const selectPaymentInfo = createSelector(
    selectPracticeSetupInfo,
    (state: PracticeInformation) => state.practicePaymentInformation
);

// practice location store
export const selectPracticeLocationInfo = createFeatureSelector<PracticeLocationsInfo>('practiceLocationStore');

export const selectPracticeLocationByShowAll = (isShowAll: boolean, filterBy: string) =>
    createSelector(selectPracticeLocationInfo, state =>
        state.practiceLocations
            .filter(profile => isShowAll || profile.isActive)
            .filter(profile => !filterBy ||
                profile.practiceLocationName?.replace(/\s+/g, '').toLocaleLowerCase()
                    .includes(filterBy?.replace(/\s+/g, '').toLocaleLowerCase()))
                    .sort((a: any, b : any) => a.practiceLocationName.localeCompare(b.practiceLocationName))
    );


export const selectPracticeLocationByLocationId = (locationId: number) =>
    createSelector(selectPracticeLocationInfo, (practiceLocationInfo: PracticeLocationsInfo) => {
        if (locationId == 0) {
            return practiceLocationInfo.selectedPracticeLocation;
        }
        const _location = practiceLocationInfo.practiceLocations.filter((_) => _.practiceLocationId == locationId);
        if (_location.length == 0) {
            return null;
        }
        return _location[0];
    });

export const selectPracticeId = () =>
    createSelector(selectPracticeLocationInfo, (practiceLocationInfo: PracticeLocationsInfo) => {
        return practiceLocationInfo.practiceId ?? 0;
    });

export const selectShowAll = () =>
    createSelector(selectPracticeLocationInfo, (practiceLocationinfo: PracticeLocationsInfo) => {
        return practiceLocationinfo.filterLocation.isShowAll;
    });

export const selectLocationStateEvents = createSelector(
    selectPracticeLocationInfo,
    (state: PracticeLocationsInfo) => state.locationStateEvents
);

export const selectPracticeLocations = createSelector(
    selectPracticeLocationInfo,
    (state: PracticeLocationsInfo) => state.practiceLocations
);

export const selectPracticeLocation = createSelector(
    selectPracticeLocationInfo,
    (state: PracticeLocationsInfo) => state.selectedPracticeLocation
);


// practice list store
export const selectPracticesDetails = createFeatureSelector<PracticeList>('practiceListStore');

export const selectPracticeListInfoStateEvent = createSelector(
    selectPracticesDetails,
    (state: PracticeList) => state.practiceListStateEvent
);

export const selectPracticesList = createSelector(
    selectPracticesDetails,
    (state: PracticeList) => state.practiceListModel
);

