import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, catchError, filter,switchMap, 
    delayWhen, takeUntil, take } from 'rxjs/operators';
import { from, of,timer,interval } from 'rxjs';
import { Store } from '@ngrx/store';

import { signalRConnectionMessages, signalRSubscribtionMethods } from '../../core/constant/signalr.constant';
import { SignalrService } from '../services/signalr.service';
import { DateUtilService } from 'src/app/features/shared/services/date-util.service';
import { startSignalRConnection, signalRConnectionSuccess, signalRConnectionError, stopSignalRConnection,
     locationGroupSubscribe, locationGroupSubscribeSuccess, locationGroupSubscribeError, locationGroupUnsubscribe,
     locationGroupUnsubscribeSuccess, locationGroupUnsubscribeError, signalRConnectionStopped, practiceGroupSubscribe, practiceGroupSubscribeSuccess, practiceGroupSubscribeError, practiceGroupUnsubscribe, practiceGroupUnsubscribeSuccess, practiceGroupUnsubscribeError } from './signalr.action';
     import { signalRConnectionStatus } from '../../core/constant/signalr.constant';
import { selectConnectionStatus } from './signalr.selector';
import { selectLoggedOut } from 'src/app/features/user/store/selectors/authentication.selector';
import { UserAuthService } from 'src/app/features/user/services/user-auth.sevice';
import { moduleName,featureName,operationName } from 'src/app/features/user/constant/user-role-constant';
import { environment } from 'src/environments/environment';

@Injectable()
export class SignalrEffects {
    
    constructor(private actions$: Actions, private signalRService: SignalrService,
         private dateUtilService: DateUtilService
         ,private store: Store
         ,private userAuthService: UserAuthService) { }

    startConnection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(startSignalRConnection),
            filter(() => this.signalRService.scheduleHubConnection.state !== signalRConnectionStatus.connected.name),
            mergeMap(() =>
                from(this.signalRService.scheduleHubConnection.start()).pipe(
                    map(() => {
                        console.log("Connection status  :" + this.signalRService.scheduleHubConnection.state);
                        this.signalRService.setupConnectionHandlers();
                        this.signalRService.processPracticeScheduleChangedEvent();
                        
                        this.signalRService.processReceivedCallEvent();
                        this.signalRService.processAnsweredCall();
                        this.signalRService.processCompletedCall();
                        this.signalRService.processReceivedMessage();
                        this.signalRService.processSendMessage();
                        this.signalRService.processMarkAsRead();
                        return signalRConnectionSuccess({date: this.dateUtilService.GetApplicationCurrentDateTime()});
                    }),
                    catchError((error) => {
                        console.log(`${signalRConnectionMessages.connectingError} ${error}`);
                        return of(signalRConnectionError({ error, date: this.dateUtilService.GetApplicationCurrentDateTime() }));
                    })
                )
            )
        );
    });
    startConnectionIfDisconnected$ = createEffect(() => {
        //Start the interval : If the user has permission start the interval
        //Start the interval : if connection status is NOT connected start the interval
        //Stop Interval : if the user logged out stop the interval
        //Kick starting the connection : if the connection is kickstarted wait for XXX seconds before next attempt
        return this.store.select(selectConnectionStatus).pipe(
            filter(status => this.userAuthService
                    .hasPermission(
                        [{moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.create}])
                    && status !== signalRConnectionStatus.connected.name),
            switchMap(() => 
             timer(environment.application.schedulerConfig.signalRKickOffIntervalTimeInMs, environment.application.schedulerConfig.signalRKickOffIntervalTimeInMs).pipe(
                takeUntil(this.store.select(selectLoggedOut).pipe(filter(loggedOut => loggedOut))),
                switchMap(() => {
                const kickStarted = this.signalRService.startConnectionIfDisconnected();
                return of(kickStarted).pipe(
                    delayWhen(started => started ? timer(environment.application.schedulerConfig.signalRKickOffIntervalTimeInMs * 2) : timer(0))
                );
            }))
        ));
    }, { dispatch: false });
    stopConnection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(stopSignalRConnection),
            switchMap(() =>
                // Poll the connection status every 500 milliseconds
                interval(500).pipe(
                    // Stop polling after 3 attempts
                    take(3),
                    // Only proceed with stopping the connection when the connection status is 'connected'
                    filter(() => this.signalRService.scheduleHubConnection.state === signalRConnectionStatus.connected.name),
                    take(1),
                    switchMap(() =>
                        from(this.signalRService.scheduleHubConnection.stop()).pipe(
                            map(() => {
                                console.log(signalRConnectionMessages.disconnected);
                                this.signalRService.stopSignalREventConnection();
                                return signalRConnectionStopped({ date: this.dateUtilService.GetApplicationCurrentDateTime() });
                            }),
                            catchError((error) => {
                                console.log(`${signalRConnectionMessages.disconnected} ${error}`);
                                return of(signalRConnectionError({ error, date: this.dateUtilService.GetApplicationCurrentDateTime() }));
                            })
                        )
                    )
                )
            )
        );
    }, { dispatch: false });

    locationGroupSubscribe$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(locationGroupSubscribe),
            mergeMap(({ location }) =>
                from(this.signalRService.scheduleHubConnection.invoke(signalRSubscribtionMethods.groupSubscribe, location.practiceLocationGuid)).pipe(
                    map(() => locationGroupSubscribeSuccess({ location })),
                    catchError((error) => of(locationGroupSubscribeError({ error: 'Location Group Subscribe '+error , location})))
                )
            )
        )
    }
    );

    locationGroupUnsubscribe$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(locationGroupUnsubscribe),
            mergeMap(({ locationGuid }) =>
                from(this.signalRService.scheduleHubConnection.invoke(signalRSubscribtionMethods.groupUnSubscribe, locationGuid)).pipe(
                    map(() => locationGroupUnsubscribeSuccess({ locationGuid })),
                    catchError((error) => of(locationGroupUnsubscribeError({ error: 'Location Group Unsubscribe '+error , locationGuid })))
                )
            )
        )
    }
    );

    
    practiceGroupSubscribe$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(practiceGroupSubscribe),
            mergeMap(({ practiceGuid }) =>
                from(this.signalRService.scheduleHubConnection.invoke(signalRSubscribtionMethods.groupSubscribeByPracticeId, practiceGuid)).pipe(
                    map(() => practiceGroupSubscribeSuccess({ practiceGuid })),
                    catchError((error) => of(practiceGroupSubscribeError({ error: 'practice Group Subscribe ' + error, practiceGuid })))
                )
            )
        )
    }
    );

    practiceGroupUnsubscribe$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(practiceGroupUnsubscribe),
            mergeMap(({ practiceGuid }) =>
                from(this.signalRService.scheduleHubConnection.invoke(signalRSubscribtionMethods.groupUnSubscribeByPracticeId, practiceGuid)).pipe(
                    map(() => practiceGroupUnsubscribeSuccess({ practiceGuid })),
                    catchError((error) => of(practiceGroupUnsubscribeError({ error: 'practice  Group Unsubscribe ' + error, practiceGuid })))
                )
            )
        )
    }
    );

}