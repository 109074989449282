import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {
  transform(dueDate: string): string {
    const dueDateObj = new Date(dueDate);
    const day = dueDateObj.getDate();
    
    let dayStr: string;
    if (10 <= day % 100 && day % 100 <= 20) {
      dayStr = day + 'th';
    } else {
      const suffixes: { [key: number]: string } = { 1: 'st', 2: 'nd', 3: 'rd' };
      dayStr = day + (suffixes[day % 10] || 'th');
    }

    return `#${dayStr} of the month`;
  }
}
