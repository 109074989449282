<div [ngClass]="!minimizeChat ? 'chat-contents-root' : 'minimize-dailer'">
  <div class="d-flex flex-column h-100"
  [ngStyle]="{'padding-right': activeContact !== null && activeContact?.patientId??0 !== 0 ? '' : ''}">
  <div class="header d-flex px-2 py-1 content-header justify-content-between shadow-sm" #matDlgHeader cdkDrag cdkDragRootElement=".cdk-overlay-pane"
      cdkDragBoundary=".cdk-overlay-container">
      <ng-container *ngIf="activeContact?.patientId??0 !== 0">
        <div class="col-9">
          <div class="d-flex gap-2 align-items-center">
            <div class="d-flex wid-25 hei-25 align-items-center justify-content-center bg-primary text-white img-radius f-10">
              {{activeContact?.patientLastName.charAt(0)}}{{activeContact?.patientFirstName.charAt(0)}}</div>
            <div class="col-10">
              <h6 class="m-0 text-title h6 flex-grow-1 text-truncate"
                title="{{activeContact?.patientFirstName}} {{activeContact?.patientLastName}}">
                {{activeContact?.patientLastName}} ,{{activeContact?.patientFirstName}}
              </h6>
        <p class="f-12 mb-0">{{activeContact?.patientPrimaryPhone??this.activeContact?.patientPrimaryPhone}}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-9" *ngIf="activeContact?.patientId === 0">
        <div class="d-flex gap-2 align-items-center">
          <div class="d-flex wid-25 hei-25 align-items-center justify-content-center bg-primary text-white img-radius f-10">
            PP</div>
          <div class="col-10">
            <h6 class="m-0 text-title h6 flex-grow-1 text-truncate">Potential patient</h6>
            <p class="f-12 mb-0">{{activeContact?.patientPrimaryPhone?? this.activeContact?.patientPrimaryPhone}}</p>
          </div>
        </div>

      </div>
      <div class="d-flex align-items-center gap-1">
        <i class="feather icon-phone f-20 text-primary" (click)="onCallClick()" *applicationFeature="'isVoIpenabled'"></i>
        <!-- <i class="feather {{ !minimizeChat ? 'icon-minimize' : ' icon-maximize' }} f-20 cursor-pointer" (click)="minimizeChatPopup()"></i> -->
        <i class="feather icon-x f-20 cursor-pointer" aria-label="Close" (click)="closeModal()"></i>  
      </div>
      
    </div>
   
      <div class="d-flex flex-column chat-light-bg" [class.d-none]="minimizeChat">
    <div class="o-hidden chat-topbar">
      <a appSidebarToggler="chat-sidebar" class="link-icon d-md-none">
        <i class="icon-regular i-Right ms-0 me-3"></i>
      </a>
      <!-- <div  class="header" #matDlgHeader cdkDrag cdkDragRootElement=".cdk-overlay-pane"
        cdkDragBoundary=".cdk-overlay-container">
        <ng-container *ngIf="activeContact?.patientId??0 !== 0">
          <p class="m-0 text-title text-16 flex-grow-1 patient-name position-absolute chat-header-position-change"
            title="{{activeContact?.patientFirstName}} {{activeContact?.patientLastName}}">
            {{activeContact?.patientFirstName}} {{activeContact?.patientLastName}}<br><span
              class="text-12">{{activeContact?.primaryPhone??this.activeContact?.patientPhone}}</span>
          </p>
  
        </ng-container>
        <div class="position-absolute chat-header-position-change" *ngIf="activeContact?.patientId === 0">
          <p class="m-0 text-title f-22 flex-grow-1 patient-name fw-bold">Potential patient</p>
          <p class="f-12">{{activeContact?.primaryPhone?? this.activeContact?.patientPhone}}</p>
  
        </div>
      </div> -->
    </div>
    <ng-container *ngIf="loadingCollection else page">
      <app-chat-skeleton [fields]="2"></app-chat-skeleton>
    </ng-container>
    <ng-template #page>
    <!-- <ng-template #chatarea> -->
      <div class="chat-box box-shadow-1 ">
      <div  class="chat-content rtl-ps-none mb-4 mb-sm-1 chat-content-height custom-scroll-y mx-1"
        *ngIf="chatCollection"  #bottomElement>
        <ng-container *ngFor="let chat of chatCollection;let i= index">
          <div class="d-flex align-items-center my-2 mx-2 position-relative justify-content-center" *ngIf="chat.separatorStarted">
            <div class="border position-absolute w-100"></div>
            <div class="border border-radius-20 py-2 px-3 d-flex align-items-center justify-content-center bg-white z-index">
              <h6 class="h6 mb-0 fw-normal f-12">{{chat.separatorDate}}</h6>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2 mx-2" *ngIf="chat.isUnreadStarted">
            <div class="border w-100 text-decoration-underline text-decoration-style"></div>
            <h6 class="h6 mb-0 ffw-normal">Unread</h6>
            <div class="border w-100 text-decoration-underline text-decoration-style"></div>
          </div>
          <!-- chat.isInbound -->
          <div *ngIf="chat.isInbound" class="d-flex flex-column user pe-2 pe-lg-0 ms-2 mb-1">
            <div class="d-flex gap-1">
              <div class="wid-25 hei-25">
                <div class="d-flex wid-25 hei-25 align-items-center justify-content-center bg-success text-white img-radius f-12"
                  *ngIf="chat.separatorPatientAvatarStarted">
                  <span>{{activeContact?.patientFirstName.charAt(0)}}</span>
                </div>
              </div>
              <div>
                <h6 class="h6 mb-0 f-12 f-w-500" *ngIf="chat.separatorPatientAvatarStarted"> {{ activeContact?.patientLastName }} <span
                    *ngIf="activeContact?.patientFirstName">, {{ activeContact?.patientFirstName }}</span>
                </h6>
                <div class="d-flex" *ngIf="chat.separatorPatientAvatarStarted">
                  <span class="text-small time f-10">{{chat.time | timeAgo}}</span>
                </div>
                  <div class="chat-Inbound-width d-flex">
                    <div class="bg-success px-2 py-2 shadow-sm chat-ingoing">
                      <div *ngIf="!chat.isMMS; else mmsChat" class="message flex-grow-1 text-white">
                        <p class="m-0 f-12 text">{{chat.textMessage}}</p>
                      </div>
                    </div>
                    <ng-template #mmsChat>
                      <ng-container [ngSwitch]="fnGetFileType(chat)">
                        <div class="imgContainer me-auto" *ngSwitchCase="MediaType.Image" (click)="openImageInNewTab(chat)">
                          <img class="imgItem" [src]="chat.url">
                        </div>
                        <video *ngSwitchCase="MediaType.Video" class="videoItem mx-auto" controls width="220" height="110">
                          <source [src]="chat.url" type="video/mp4">
                          <source [src]="chat.url" type="video/webm">
                          <source [src]="chat.url" type="video/flv">
                          <source [src]="chat.url" type="video/mkv">
                          <source [src]="chat.url" type="video/ogg">
                          <source [src]="chat.url" type="video/3gp">
                        </video>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
            </div>
          </div>
          <!-- chat.isOutbound -->
          <div *ngIf="chat.isOutbound" class="d-flex flex-column align-items-end offset-sm-0 ps-2 ps-lg-0 justify-content-end me-2 mb-1">
            <div class="d-flex justify-content-end" *ngIf="chat.separatorPracticeAvatarStarted">
              <span class="text-small time f-10">{{chat.time | timeAgo}}</span>
            </div>
            <div class="chat-outbound-width d-flex align-items-end">
              <div class="bg-light-primary px-2 py-2 shadow-sm chat-outgoing">
                <div *ngIf="!chat.isMMS; else mmsChat" class="message flex-grow-1">
                  <p class="m-0 f-12 text">{{chat.textMessage}}</p>
                </div>
              </div>
              <ng-template #mmsChat>
                <ng-container [ngSwitch]="fnGetFileType(chat)">
                  <div class="imgContainer ms-auto" *ngSwitchCase="MediaType.Image" (click)="openImageInNewTab(chat)">
                    <img class="imgItem" [src]="chat.url">
                  </div>
                  <video *ngSwitchCase="MediaType.Video" class="videoItem ms-auto" controls width="220" height="110">
                    <source [src]="chat.url" type="video/mp4">
                    <source [src]="chat.url" type="video/webm">
                    <source [src]="chat.url" type="video/flv">
                    <source [src]="chat.url" type="video/mkv">
                    <source [src]="chat.url" type="video/ogg">
                    <source [src]="chat.url" type="video/3gp">
                  </video>
                  <div class="message flex-grow-1">
                    <a [href]="chat.url" *ngSwitchCase="MediaType.Document" class="chat-url">{{chat.fileName}}</a>
                    <p class="m-0 text-14 text">{{chat.textMessage}}</p>
                    <div class="d-flex justify-content-end">
                      <span class="text-small mt-1 time">{{chat.time | dateTimeFormat}}</span>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </div>

          
          
        </ng-container>
      </div>
      <div class="chat-input-area bg-white" *ngIf="chatCollection && !loadingCollection">
        <form #msgForm="ngForm" class="inputForm d-flex flex-column align-items-center"
          (keyup.enter)="sendMessage($event)">
          <div class="d-flex w-100 position-relative">
  
            <textarea class="form-control" #msgInput ngModel placeholder="Type your message" name="message" id="message"
              cols="30" rows="3" (click)="onMessageTextareaClick()"></textarea>
          </div>
          <div class="d-flex align-items-end w-100 bg-white px-2 my-2 justify-content-between rounded">
            <!-- <div class="d-flex gap-2">
              <i class="feather icon-upload f-16 text-secondary"></i>
              <i class="fa fa-solid fa-reply-all f-16 text-secondary"></i>
              <i class="fa fa-solid fa-envelope f-16 text-secondary"></i>
            </div> -->
            <i class="fa fa-solid fa-paper-plane text-primary f-16 cursor-pointer ms-auto" (click)="sendMessage($event)"></i>
          </div>
          <!-- <div class="d-flex w-100 justify-content-between">
            <div class="d-flex w-30 align-self-center ">
              <div class="align-self-center m-2">
                <input type="file" multiple #hiddenFileInput (change)="fnOnFileSelect($event)" style="display: none;">
                <i class="i-Mail-Add-fileUploadBtn" (click)="hiddenFileInput.click()"
                  title="Select file(s) to upload"></i>
              </div>
              <div *ngIf="items.length > 0" class=" align-self-center" style="justify-content: center;">
              </div>
            </div>
          </div> -->
        </form>
      </div>
  
      <div class="spinner-glow spinner-glow-warning" *ngIf="loadingCollection"></div>
  
      <div class="app-inro-circle top-0 mt-5 h-100" *ngIf="!chatCollection">
        <div
          class="chat-box box-shadow-1 chat-right ng-tns-c58-1 mx-2 py-5 h-100 d-flex justify-content-center flex-column">
          <div class="border rounded-circle big-bubble greay-bubble"
            [@animate]="{value:'*',params:{scale:'.2',delay:'400ms', duration: '400ms'}}">
            <!-- <i class="i-Speach-Bubbles"></i> -->
            <img src="assets/images/icons/chat-big.png">
          </div>
          <p class="text-16" [@animate]="{value:'*',params:{y:'120px',delay:'600ms', duration: '400ms'}}">Select a contact
            and start chat.</p>
        </div>
      </div>
      </div>
  </ng-template>
      </div>
  
</div>
</div>

<ng-template #fullLoadingDlg>
  <div class="k-block full-loading">
    <kendo-loader [type]="'infinite-spinner'" [themeColor]="'info'" [size]="'medium'"></kendo-loader>
  </div>
</ng-template>