import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import {  Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import { PracticeCoreAPIService } from '../../api/practice-core-info-api.service';
import {
  invokeGetStates, invokeGetTimeZones,
  getStatesSuccess, getTimeZonesSuccess, getStatesFailure, getTimeZonesFailure
} from './core-lookup.reducer';
import { selectUSStateList, selectTimeZoneList } from './core-lookup.selector';


@Injectable()
export class CoreLookupEffect {
  constructor(
    private actions$: Actions,
    private practiceCoreAPIService: PracticeCoreAPIService,
    private store: Store
  ) { }

  loadStateList$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(invokeGetStates),
      concatLatestFrom(() => this.store.select(selectUSStateList)),
      filter(([, usStatelist]) => !usStatelist || usStatelist.length === 0),
      mergeMap(() => {
        return this.practiceCoreAPIService.getStates().pipe(
          map((data) => getStatesSuccess({ states: data.sort((a, b) => a.stateName.localeCompare(b.stateName)) })),
          catchError(errorMessage => of(getStatesFailure({ errorMessage: errorMessage?.error })))
        );
      })
    ) }
  );

  loadTimeZoneList$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(invokeGetTimeZones),
      concatLatestFrom(() => this.store.select((selectTimeZoneList))),
      filter(([, timeZonelist]) => !timeZonelist || timeZonelist.length === 0),
      mergeMap(() => {
        return this.practiceCoreAPIService.getTimeZones().pipe(
          map((data) => getTimeZonesSuccess({ timeZones: data.sort((a, b) => a.timeZoneName.localeCompare(b.timeZoneName)) })),
          catchError(errorMessage => of(getTimeZonesFailure({ errorMessage: errorMessage?.error })))
        );
      })
    ) }
  );
}