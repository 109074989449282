import {
  Component, EventEmitter, NgModuleRef, Output, ViewChild,
  ViewContainerRef, ViewEncapsulation, createNgModule, Injector, OnInit
} from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { MenuItem } from 'src/app/theme/store/menu/menu.state';
import { UserAuthService } from 'src/app/features/user/services/user-auth.sevice';
import { moduleName,featureName,operationName, applicationStorageKeys } from 'src/app/features/user/constant/user-role-constant';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DebounceService } from 'src/app/features/shared/services/debounce-service';
import { PracticeUserPermission } from 'src/app/features/practice/store/user-roles-store/user-role-state';
import { UserRoleService } from 'src/app/features/user/services/user-role.service';
import { AppSessionStorageService } from 'src/app/features/shared/services/app-session-storage.service';


@Component({
  selector: 'app-header-icon',
  templateUrl: './header-icon.component.html',
  styleUrls: ['./header-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class headerIconComponent extends BaseDestroyComponent implements OnInit {

  showPatientwizard = false;
  showPatientLiveStatusPanel = false;
  scheduleModuleRef!: NgModuleRef<any>;
  scheduleOverlayComponentRef: any;
  isScheduleRoute = true;
  userRole = "";
  headerMenuItems: MenuItem[] = []
  practiceId = 0;
  loading = false;
  permission: PracticeUserPermission | null = null;
  
  @Output() patientLiveStatusEvent = new EventEmitter<boolean>();
  @ViewChild('scheduleOverlay', { read: ViewContainerRef, static: true }) _patientwizardcontainer!: ViewContainerRef;

  patientSearchFeaturePermission = { moduleName: '',featureName:featureName.patientSearch,operationName:operationName.all}
  patientFeatureCreatePermission = { moduleName:moduleName.patient,featureName:featureName.patient,operationName:operationName.create}

  constructor(
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userAuthService: UserAuthService,
    private alert: AlertService,
    private offcanvasService: NgbOffcanvas,
    private debounceService: DebounceService,
    private userRoleService: UserRoleService,
    private sessionStorageService: AppSessionStorageService
  ) {
    super();
    this.generateHeaderMenus();
   
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.route.url.subscribe(url => {
      this.handleRouteChange();
    }));
    this.subscriptions$.add(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleRouteChange();
      }
    }));
    this.sessionStorageService.getObservable(applicationStorageKeys.selectedPracticeIdBySA).subscribe((value: string) => {
      if(value && Number(this.practiceId) !== Number(value))
      this.generateHeaderMenus();
    })
  }

  handleRouteChange() {
    const currentRoute = this.router.url.split('/');
    const routeName = currentRoute.find(route => route !== '');
    this.isScheduleRoute = routeName !== 'schedule';
  }

  showHidePatientLiveStatus() {
    this.patientLiveStatusEvent.emit();
  }

  async showScheduler() {
    try {
      this.offcanvasService.dismiss();
      this.scheduleOverlayComponentRef?.destroy();
      if (!this.scheduleModuleRef) {
        const scheduleModuleRef = await import('../../../../../features/scheduler/scheduler.module');
        this.scheduleModuleRef = createNgModule(scheduleModuleRef.SchedulerModule, this.injector);
      }
      if (this.scheduleModuleRef) {
        const scheduleOverlayComponent = await import('../../../../../features/scheduler/component/schedule-overlay/schedule-overlay.component');
        this.scheduleOverlayComponentRef = this._patientwizardcontainer?.createComponent(scheduleOverlayComponent.ScheduleOverlayComponent);
        this.scheduleOverlayComponentRef.instance.closeScheduleOverlay.subscribe(() => {
          this.setScheduleMenuItemDisable(false);
        });
      }
    }
    catch (ex) {
      this.alert.warningAlertAutoClose("We have experienced an issue while loading your request. Please try again later.");
    }
    finally {
    }
  }


  generateHeaderMenus() {
    this.userRole = this.authService.getUserRoleFromToken();
    this.practiceId = this.authService.getPracticeIDFromToken();
    this.permission = this.authService.getUserRolesAndPermission();
    
    if (this.userRole) {
      this.headerMenuItems =
       [
          {
            id: 1,
            title: 'Schedule',
            type: 'item',
            url: '',
            classes: '',
            icon: 'assets/images/scehude-calendar.png',
            children: undefined,
            hidden: this.isScheduleRoute && !this.userAuthService?.hasPermission([{ moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.create }]),
            disable:false,
          },
          {
            id: 2,
            title: 'Dashboard',
            type: 'item',
            url: this.userRoleService.getRouteUrlByUserPermission(this.permission),
            classes: '',
            icon: 'assets/images/menuicon/sub-menu/cilnical.svg',
            children: undefined,
            hidden: !this.userAuthService?.hasPermission([
              { moduleName:moduleName.scheduler,featureName:featureName.schedule,operationName:operationName.all},
              { moduleName:moduleName.serviceContract,featureName:featureName.serviceContract,operationName:operationName.all},
              { moduleName:moduleName.insurance,featureName:featureName.insuranceClaim,operationName:operationName.all },
              { moduleName:moduleName.paymentManagement,featureName:featureName.paymentPatient,operationName:operationName.all },
              { moduleName:moduleName.paymentManagement,featureName:featureName.paymentInsurance,operationName:operationName.all },
              { moduleName:moduleName.paymentManagement,featureName:featureName.paymentOtherThanCard,operationName:operationName.all },
              { moduleName:moduleName.patient,featureName:featureName.insuranceBenefit,operationName:operationName.all }
            ]),
            disable:false,
          },
          {
            id: 3,
            title: 'Report',
            type: 'item',
            url: '/report/dashboard',
            classes: '',
            icon: 'assets/images/report-icon.png',
            children: undefined,
            hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.report,featureName:featureName.report,operationName:operationName.all}]),
            disable:false,
          },
          {
            id: 4,
            title: 'Practice configuration ',
            type: 'item',
            url: `practice/practice-personal-lookup/personal/${this.practiceId}`,
            classes: '',
            icon: 'assets/images/practice-icon.png',
            children: undefined,
            disable:false,
            hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all}])
          },
          {
            id: 5,
            title: 'Admin',
            type: 'item',
            url: '/admin',
            classes: '',
            icon: 'assets/images/admin-icon.png',
            children: undefined,
            disable:false,
            hidden: !this.userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practiceList,operationName:operationName.all}])
          },
        ]
      }
  }

  headerMenuClick(item:any) {
    switch (item.title) {
      case 'Schedule':
        this.setScheduleMenuItemDisable(true);
        this.showScheduler();
        break;
      case 'Live Status':
        this.showHidePatientLiveStatus();
        break;
      default:
        this.router.navigate([item.url]);
        break;
    }
  }

  menuClickHandler(item:any) {
    this.debounceService.debounce('headerMenuClick', () => {
      this.headerMenuClick(item);
    });
  }

  setScheduleMenuItemDisable(disabled: boolean) {
    this.headerMenuItems = this.headerMenuItems.map((item) => {
      if (item.title === 'Schedule') {
        return { ...item, disable: disabled };
      } else {
        return { ...item };
      }
    });
  }
}