import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appScrollToFirstInvalidControl]'
})

export class ScrollToFirstInvalidDirective {
  @Input() formGroup: FormGroup | null = null;

  constructor(private el: ElementRef) { }

  static scrollToElement(element: HTMLElement) {
    if (element) {
      const distance = window.pageYOffset - Math.abs(element.getBoundingClientRect().y);
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.getBoundingClientRect().top + window.scrollY - 150,//
      });

      setTimeout(() => {
        element.focus();
        element.blur(); // Trigger error messages
        element.focus();
      }, distance);
    }
  }

  static markFormGroupTouched(formGroup: FormGroup | null) {
    (<any>Object).values(formGroup?.controls).forEach((control: FormGroup) => {
      control.markAsTouched();

      if (control.controls) {
        ScrollToFirstInvalidDirective.markFormGroupTouched(control);
      }
    });
  }

  @HostListener('submit', ['$event'])
  onSubmit(event: Event) {
    event.preventDefault();

    if (!this.formGroup?.valid) {
      ScrollToFirstInvalidDirective.markFormGroupTouched(this.formGroup);


      const formControlInvalid = this.el.nativeElement.querySelector('.form-control.ng-invalid');

      if (formControlInvalid) {
        return ScrollToFirstInvalidDirective.scrollToElement(formControlInvalid);
      } else {
        const formGroupInvalid = this.el.nativeElement.querySelectorAll('form .ng-invalid');
        if (formGroupInvalid && formGroupInvalid.length) {
          return ScrollToFirstInvalidDirective.scrollToElement(formGroupInvalid[0]);
        }
      }

      return ScrollToFirstInvalidDirective.scrollToElement(this.el.nativeElement);
    }
  }
}