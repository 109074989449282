import {HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BrowserLoggerService } from './logger.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorIntercept implements HttpInterceptor {
    private isRedirecting = false;

    constructor(
        private loggerService: BrowserLoggerService,
        private router: Router
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (!this.isRedirecting && !this.isAlreadyOnSessionTimeoutPage()) {
                        this.isRedirecting = true;  
                        const returnUrl = this.router.url;
                        // Handle the 401 error by redirecting to the "session-timeout" page
                        this.router.navigate(['/session-timeout'], { queryParams: { returnUrl } })
                        .then(() => {
                        // Reset the flag after the navigation completes
                        this.isRedirecting = false;
                        });
                    }

                    // Return an empty observable to prevent the error from propagating
                    return new Observable<HttpEvent<any>>();
                } else if (error.error instanceof ErrorEvent) {
                    // Client-side error
                    const errorMessage = `Http Error: ${error.error.message}`;
                    this.loggerService.error(errorMessage);
                    return throwError(errorMessage);
                } else {
                    let message = '';

                    if (error?.error?.errors) {
                        message = error.error.errors[0]?.description;
                    } else if (error?.error?.error) {
                        message = error.error.error;
                    } else if (error?.error) {
                        message = error.error;
                    } else {
                        message = error.message;
                    }
                    const errorMessage = `Http Error Status: ${error.status}\nMessage: ${message}`;
                    this.loggerService.error(errorMessage);
                    throw(errorMessage);
                }
            })
        );
    }

    private isAlreadyOnSessionTimeoutPage(): boolean {
        const currentUrl = this.router.url.split('?')[0]; // Get the base URL without query parameters
        return currentUrl === '/session-timeout';
    }
}
