<div class="row m-0 py-2" [ngClass]="{'global-px': this.pageIdentifier === this.patientSearchType.bulkEmailPatientSearch}">
    <div class="col-sm-12">
        <div [ngClass]="this.pageIdentifier === this.patientSearchType.bulkEmailPatientSearch? 'card p-3 global-mb z-index': 'mb-2 mt-4'">
            <form class="editForm" [formGroup]="patientSearchFilter" appScrollToFirstInvalidControl>
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Last name, First name"
                                formControlName="patientName" (input)="onPatientNameChange(patientSearchFilter)" />
                            <app-validation-error *ngIf="isValidField('patientName')"
                                [errors]="patientSearchFilter.controls['patientName'].errors"
                                fieldName="Patient Name"></app-validation-error>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search by email"
                                formControlName="patientPrimaryEmailAddress"
                                (input)="onPatientEmailChange(patientSearchFilter)" />
                            <app-validation-error *ngIf="isValidField('patientPrimaryEmailAddress')"
                                [errors]="patientSearchFilter.controls['patientPrimaryEmailAddress'].errors"
                                fieldName="Email Address"></app-validation-error>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <app-form-control-textmask label="" placeholder="Search by phone number"
                            formControlName="patientPrimaryPhone" [appNumberMask]="maskingType.Phone" (input)="onPhoneNumberChange(patientSearchFilter)">
                        </app-form-control-textmask>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <ng-select [items]="practiceLocation$ | async" [multiple]="true" [closeOnSelect]="true"
                                [searchable]="true" bindLabel="practiceLocationName" id="exampleFormControlSelect1"
                                placeholder="Search by location" formControlName="selectedPracticeLocations"
                                (change)="selectPracticeLocation($event,patientSearchFilter)">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row patientsearchfilter">
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <ng-select [items]="practicePatientStatus$ | async" [multiple]="true" [closeOnSelect]="true"
                                [searchable]="true" bindLabel="patientStatusName" id="exampleFormControlSelect2"
                                placeholder="Search by status" formControlName="selectedPatientStatusId"
                                (change)="selectPracticePatientStatus($event,patientSearchFilter)" appendTo="body">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="FRP Last name, First name"
                                formControlName="patientFinancialResponsiblePerson"
                                (input)="onFRPNameChange(patientSearchFilter)" />
                            <app-validation-error *ngIf="isValidField('patientFinancialResponsiblePerson')"
                                [errors]="patientSearchFilter.controls['patientFinancialResponsiblePerson'].errors"
                                fieldName="FRP Name"></app-validation-error>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search by preferred name"
                                formControlName="patientPreferredName"
                                (input)="onPatientPreferredNameChange(patientSearchFilter)" />
                            <app-validation-error *ngIf="isValidField('patientPreferredName')"
                                [errors]="patientSearchFilter.controls['patientPreferredName'].errors"
                                fieldName="Patient Preferred Name"></app-validation-error>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="row global-px align-items-center">
                            <div class="col-7">
                                <app-form-control-textmask label="" placeholder="Search by DOB (mm/dd/yyyy)" formControlName="patientDOB"
                                    [appNumberMask]="maskingType.DOB" (input)="onPatientDOBChange(patientSearchFilter)">
                                </app-form-control-textmask>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <app-form-control-checkbox labelName="Show All" formControlName="showInActivePatients"
                                    (checkBoxChangeEvent)="toggleShowActivePatient($event)"></app-form-control-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row patientsearchfilter" *ngIf="this.pageIdentifier === this.patientSearchType.bulkEmailPatientSearch">
                    <div class="col-lg-3 col-md-6">
                        <div class="form-group">
                            <ng-select [items]="this.bulkFilterTypes" [multiple]="false" [closeOnSelect]="true"
                                [searchable]="false" bindValue="filterValue" bindLabel="filterName" id="filterTypePatientSearch"
                                placeholder="Search by Type" formControlName="filter"
                                (change)="handleBulkFilterChange($event)" appendTo="body">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6" *ngIf="showAppointmentDateRange">
                        <div class="form-group">
                            <div class="communication-data-range">
                                <app-form-control-daterange #dateRangeComponent class="w-75" class="daterange-space-remove"
                                    placeholder="Select date range"
                                    (setRange)="setFilterDatesByDateRange($event)"></app-form-control-daterange>
                           
                                    <div class="validation_msg" *ngIf="patientSearchFilter.get('fromDate')?.hasError('required') && patientSearchFilter.get('fromDate')?.touched">
                                        From Date and To Date is required
                                    </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-lg-3 col-md-6" *ngIf="showPastdueDaysRange">
                        <app-form-control-number-textbox label="" formControlName="fromDays" class="no-margin-top" placeholder="From Days"
                        [maxlength]="3"  [required]="showPastdueDaysRange">
                        </app-form-control-number-textbox>
                    </div>
                    <div class="col-lg-3 col-md-6" *ngIf="showPastdueDaysRange">
                        <app-form-control-number-textbox label="" formControlName="toDays" class="no-margin-top" placeholder="To Days"
                        [maxlength]="3"  [required]="showPastdueDaysRange">
                        </app-form-control-number-textbox>
                    </div>
                   
                </div>
                <div class="form-group text-right mb-0">
                    <ng-container *ngIf="selectedPatientsBasicInfo.length > 0">
                        <span *appUserHasPermission="bulkCommunicationCreateFeaturePermission">
                            <button type="button" class="btn btn-light-primary me-2" (click)="submitSelectedPatients()">
                                Bulk Communication |
                                <span class="ms-2"  *ngIf="selectAllLoading === true">
                                    <img src="assets/images/svg/spinner-loader.svg">
                                </span>
                                <span class="ms-2 fw-bold" *ngIf="selectAllLoading === false">
                                   {{selectedPatientsBasicInfo.length}}
                                </span> 
                            </button>
                        </span>
                    </ng-container>
                    <button type="submit" *ngIf="!isAutoSearch" class="btn btn-light-primary me-2" (click)="searchPatients()"><i
                        class="fa fa-sharp fa-regular fa-search me-2"></i>Search</button>
                    <button type="button" class="btn btn-light-secondary" (click)="clearFilter(patientSearchFilter)"><i
                            class="fa fa-sharp fa-regular fa-ban me-2"></i>Clear</button>
                </div>
            </form>
        </div>
        <app-patient-search-result (closeSearchResultOverlay)="closeOverlay()" [allowToSelectPatient]="allowToSelectPatient"  (selectionChange)="handleSelectedPatients($event)" [pageIdentifier]="pageIdentifier"></app-patient-search-result>
    </div>
</div>