import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, of, catchError } from 'rxjs';
import {
  invokeCreatePracticeUser, invokeCreatePracticeUserFailure, invokeCreatePracticeUserSuccess,
  invokeGeneratePasswordResetTokenByEmail,
  invokeGeneratePasswordResetTokenByEmailFailure,
  invokeGeneratePasswordResetTokenByEmailSuccess,
  invokeGetAllUsers,
  invokeGetAllUsersFailure,
  invokeGetAllUsersSuccess,
  invokeGetPracticeUserByEmail,
  invokeGetPracticeUserByEmailFailure,
  invokeGetPracticeUserByEmailSuccess,
  invokeGetUserEmailByUserID,
  invokeGetUserEmailByUserIDFailure,
  invokeGetUserEmailByUserIDSuccess,
  invokeGetUsersByRoles,
  invokeGetUsersByRolesFailure,
  invokeGetUsersByRolesSuccess,
  invokeMarkAsActiveFailure,
  invokeMarkAsActiveSuccess,
  invokeMarkAsActiveUser,
  invokeMarkAsInActiveUser,
  invokePracticeUserLocation, invokePracticeUserLocationFailure, invokePracticeUserLocationSuccess,
  invokeUpdatePracticeUser, invokeUpdatePracticeUserFailure, invokeUpdatePracticeUserSuccess, invokeUpdateSignature, invokeUpdateSignatureFailure,
   invokeUpdateSignatureSuccess, invokeUserChangePassword,   invokeUserChangePasswordFailure, invokeUserChangePasswordSuccess,
    invokeUserForgotPassword, invokeUserForgotPasswordFailure, invokeUserForgotPasswordSuccess, invokeUserResetPassword, invokeUserResetPasswordFailure, 
    invokeUserResetPasswordSuccess, invokeValidateEmail, invokeValidateEmailFailure, invokeValidateEmailSuccess, validateSecurityPin, validateSecurityPinFailure, validateSecurityPinSuccess
} from './practice-user.action';
import { PracticeUserAPIService } from '../../api/practice-user-api.service';

@Injectable()
export class PracticeUserEffect {
  invokeUserForgotPassword: any;
  constructor(
    private actions$: Actions,
    private store: Store,
    private practiceUserAPIService: PracticeUserAPIService
  ) { }

  loadAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGetAllUsers),
      switchMap((action) => {
        return this.practiceUserAPIService.getAllUsers(action.practiceId).pipe(
          map(users => invokeGetAllUsersSuccess({ users })),
          catchError(errorMessage => of(invokeGetAllUsersFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  loadUserByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGetPracticeUserByEmail),
      switchMap((action) => {
        return this.practiceUserAPIService.getPracticeUserByEmail(action.email).pipe(
          map(practiceUser => invokeGetPracticeUserByEmailSuccess({ practiceUser })),
          catchError(errorMessage => of(invokeGetPracticeUserByEmailFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  loadLocationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokePracticeUserLocation),
      switchMap((action) => {
        return this.practiceUserAPIService.getPracticeLocations().pipe(
          map(practiceUserLocations => invokePracticeUserLocationSuccess({ practiceUserLocations })),
          catchError(errorMessage => of(invokePracticeUserLocationFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )

  createPracticeInfoAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreatePracticeUser),
      switchMap((action) => {
        return this.practiceUserAPIService.createPracticeUser(action.practiceUser).pipe(
          map(practiceUser => invokeCreatePracticeUserSuccess({ practiceUser })),
          catchError(errorMessage => of(invokeCreatePracticeUserFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  updatePracticeInfoAPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdatePracticeUser),
      switchMap((action) => {
        return this.practiceUserAPIService.updatePracticeUser(action.practiceUser).pipe(
          map(practiceUserDetails => invokeUpdatePracticeUserSuccess({ practiceUser: practiceUserDetails })),
          catchError(errorMessage => of(invokeUpdatePracticeUserFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  markAsActiveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeMarkAsActiveUser),
      switchMap((action) => {
        return this.practiceUserAPIService.markAsActiveUser(action.email).pipe(
          map(practiceUserDetails => invokeMarkAsActiveSuccess({ practiceUser: practiceUserDetails })),
          catchError(errorMessage => of(invokeMarkAsActiveFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  markAsInActiveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeMarkAsInActiveUser),
      switchMap((action) => {
        return this.practiceUserAPIService.markAsInActiveUser(action.email).pipe(
          map(practiceUserDetails => invokeMarkAsActiveSuccess({ practiceUser: practiceUserDetails })),
          catchError(errorMessage => of(invokeMarkAsActiveFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUserForgotPassword),
      switchMap((action) => {
        return this.practiceUserAPIService.forgotPassword(action.email).pipe(
          map(forgotPassword => invokeUserForgotPasswordSuccess({ forgotPassword: forgotPassword })),
          catchError(errorMessage => of(invokeUserForgotPasswordFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUserResetPassword),
      switchMap((action) => {
        return this.practiceUserAPIService.resetPassword(action.resetPassword).pipe(
          map(response => invokeUserResetPasswordSuccess({ response: response })),
          catchError(errorMessage => of(invokeUserResetPasswordFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUserChangePassword),
      switchMap((action) => {
        return this.practiceUserAPIService.changePassword(action.changePassword).pipe(
          map(response => invokeUserChangePasswordSuccess({ response: response })),
          catchError(errorMessage => of(invokeUserChangePasswordFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  getUserEmailByUserID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGetUserEmailByUserID),
      switchMap((action) => {
        return this.practiceUserAPIService.getUserEmailByUserID(action.userId).pipe(
          map(userEmail => invokeGetUserEmailByUserIDSuccess({ userEmail: userEmail })),
          catchError(errorMessage => of(invokeGetUserEmailByUserIDFailure({ errorMessage: errorMessage })))
        );
      })
    )
  )
  generatePasswordResetTokenByEmail$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeGeneratePasswordResetTokenByEmail),
    switchMap((action) => {
      return this.practiceUserAPIService.generatePasswordResetTokenByEmail(action.email).pipe(
        map(token => invokeGeneratePasswordResetTokenByEmailSuccess({ token: token })),
        catchError(errorMessage => of(invokeGeneratePasswordResetTokenByEmailFailure({ errorMessage: errorMessage })))
      );
    })
  )
)
updateSignature$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeUpdateSignature),
    switchMap((action) => {
      return this.practiceUserAPIService.updateSignature(action.updateSignature).pipe(
        map(user => invokeUpdateSignatureSuccess({ practiceUser : user})),
        catchError(errorMessage => of(invokeUpdateSignatureFailure({ errorMessage: errorMessage })))
      );
    })
  )
)
ValidateEmail$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeValidateEmail),
    switchMap((action) => {
      return this.practiceUserAPIService.validateEmail(action.email).pipe(
        map(response => invokeValidateEmailSuccess({ response : response})),
        catchError(errorMessage => of(invokeValidateEmailFailure({ errorMessage: errorMessage })))
      );
    })
  )
)
  
validateSecurityPinAPI$ = createEffect(() => {
  return this.actions$.pipe(
      ofType(validateSecurityPin),
      switchMap(({securityPin}) => {
          return this.practiceUserAPIService.validateSecurityPin(securityPin).pipe(
              map((isValidPin) => validateSecurityPinSuccess({isValidPin})),
              catchError((error) => of(validateSecurityPinFailure({ errorMessage: error })))
          );
      })
  );
});

loadUsersByRoles$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeGetUsersByRoles),
    switchMap((action) => {
      return this.practiceUserAPIService.getUsersByRoles(action.getUsersByRoles).pipe(
        map(users => invokeGetUsersByRolesSuccess({ users })),
        catchError(errorMessage => of(invokeGetUsersByRolesFailure({ errorMessage: errorMessage })))
      );
    })
  )
)
}