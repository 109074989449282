import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { userApiAssert } from '../constant/user-api-asset';
import { ChangePassword, ForgotPassword, GetUsersByRoles, PracticeUser, PracticeUserLocation, ResetPassword, UpdateSignature, User } from '../store/user-creation/practice-user.state';
import { practiceApiAssert } from '../constant/practice-api-asset';


@Injectable({
	providedIn: 'root'
})

export class PracticeUserAPIService {

	constructor(private http: HttpClient) { }

	getAllUsers(practiceId: number) {
		const param = { 'practiceId': practiceId }
		return this.http.get<User[]>(userApiAssert.getAllUsers, { params: param });
	}
	getPracticeUserByEmail(userEmail: string){
		const param = {'email' : userEmail }
        return this.http.get<User>(userApiAssert.getUserByEmail, { params: param });
    }
	getPracticeLocations(){
        return this.http.get<PracticeUserLocation[]>(practiceApiAssert.getPracticeLocations)
    }
	createPracticeUser(practiceUser: PracticeUser) {
		return this.http.post<User>(userApiAssert.createUser, practiceUser);
	}
	updatePracticeUser(practiceUser: PracticeUser) {
		return this.http.post<User>(userApiAssert.updateProfile, practiceUser);
	}
	markAsActiveUser(userEmail: string) {
        return this.http.post<User>(userApiAssert.markAsActive, {"email": userEmail});
	}
	markAsInActiveUser(userEmail: string) {
        return this.http.post<User>(userApiAssert.markAsInActive, {"email": userEmail});
	}
	forgotPassword(userEmail: string) {
        return this.http.post<ForgotPassword>(userApiAssert.forgotPassword, {"email": userEmail});
	}
	resetPassword(resetPassword: ResetPassword) {
        return this.http.post<User>(userApiAssert.resetPassword, resetPassword);
	}
	changePassword(changePassword: ChangePassword) {
        return this.http.post<User>(userApiAssert.changePassword, changePassword);
	}
	getUserEmailByUserID(userId:string){
		const param = {'userId' : userId }
		return this.http.get<string>(userApiAssert.getUserEmailByUserID, { params: param })
	}
	generatePasswordResetTokenByEmail(email:string){
		const param = {'email' : email }
		return this.http.get(userApiAssert.generatePasswordResetTokenByEmail, {params: param,responseType: 'text'});
	}
	updateSignature(updateSignature: UpdateSignature){
		return this.http.post<User>(userApiAssert.updateSignature, updateSignature);
	}
	validateEmail(email: string) {
        return this.http.post<any>(userApiAssert.validateEmail, {"emailAddress": email});
	}
	validateSecurityPin(securityPin: any){
		return this.http.post<boolean>(userApiAssert.validatePin,  securityPin );
	}
	getUsersByRoles(getUsersByRoles: GetUsersByRoles[]) {
		return this.http.post<User[]>(userApiAssert.getUsersByRoles, getUsersByRoles);
	}
}