<div class="header_section primary_header gap-2 my-auto" 
[ngClass]="{ 'patient_header_card': isPatientUser, 'admin_header_card': isSuperAdmin, 
'patient_live_status_with_checkout':this.isPatientLiveStatusCheckoutEnabled }">
  
  <ng-container>
    <div *appUserHasPermission="practiceListFeaturePermission">
      <div class="header_item header_location_section">
        <form [formGroup]="practicesForm" class="remove-form-group-bottom">
          <app-form-control-selectbox [items]="practices" bindValue="practiceId" [clearable]="false" (changeEvent)="onPracticeChange()"
            bindLabel="practiceName" formControlName="practiceId">
          </app-form-control-selectbox>
        </form>
      </div>
    </div>
    <div class="header_item header_icons" 
      [ngClass]="{'hide_ipad': IsVisiblePatientLiveStatusPanel,'': !IsVisiblePatientLiveStatusPanel}"
      [ngStyle]="{ 'display': !IsVisiblePatientLiveStatusPanel ? 'block' : 'none' }">
      <app-header-icon class="locator-search" (patientLiveStatusEvent)="showHidePatientLiveStatus()"></app-header-icon>
    </div>
  </ng-container>
  <div class="d-flex nav-center-panel">
    <div class="nav-location" *appUserHasPermission="patientLiveStatusFeaturePermission">
    <div class="d-flex bg-white hei-45 align-items-center px-3 nav-center-box-panel">
      <div class="header_item header_location_section header_location_section_live_bar">
        <app-live-status-location (liveLocationChanged)="getLiveLocationID($event)"></app-live-status-location>
      </div>
      <div class="header_item patient_live_status_panel_count cursor-pointer">
        <app-patient-live-status [locationId]="this.liveLocationId"></app-patient-live-status>
      </div>
    </div>
  </div>
    <div class="header_right_section ms-auto">
      <div class="d-flex gap-2 justify-content-end align-items-center">
        <div *applicationFeature="'isChatEnabled'">
          <div *appUserHasPermission="patientChatDashboardPermission"
            class="header_menu_icon header_menu_icon-tab header_pay_icon cursor-pointer" title="Chat"
            (click)="navigateToChatDashboard()">
            <img class="wid-25 next-app-icon" src="assets/images/menuicon/sub-menu/chat.svg">
          </div>
        </div>
          <app-signalr-connection-status></app-signalr-connection-status>
          <div class="header_item cursor-pointer"
            [ngClass]="{'hide_ipad': IsVisiblePatientLiveStatusPanel,'': !IsVisiblePatientLiveStatusPanel}">
            <a class="mobile-menu" id="mobile-collapse" title="Click to Menu Collapse" (click)="showSideNavigation = true"><span></span></a>
          </div>
      </div>
    </div></div>
  </div>
<ng-container *ngIf="showSideNavigation">
  <app-overlay position="right" canvasSize="rl_canvas_sm" (onClose)="showSideNavigation=!showSideNavigation"
    [isShowInLay]="false">
    <div class="pt-2 pb-2 user-profile-section">
      <div class="d-flex align-items-center">
        <div class="initial_avator">
          {{loggedInUserName.charAt(0).toUpperCase()}}
        </div>
        <h6 class="h6 mb-0 ms-2">
          {{loggedInUserName}}
        </h6>
      </div>
      <div class="mt-2 pt-2 user-profile-divider">
        <a [routerLink]="'/change-password'" class="m-l-10" (click)="showSideNavigation=!showSideNavigation">
          <i class="fa fa-solid fa-unlock"></i> <span class="m-l-10">Change Password</span>
        </a>
      </div>
    </div>
    <div class="main_menu_section pt-2">
      <div class="main_menu_list">
        <ul>
          <li><app-logout></app-logout></li>
        </ul>
      </div>
    </div>
  </app-overlay>
</ng-container>