import { MenuItem } from '../store/menu/menu.state';
import { moduleName,featureName,operationName, roles } from 'src/app/features/user/constant/user-role-constant';


export const PatientSubMenuItems: MenuItem[] = [
    {
        id: 1,
        title: 'Patient',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/patientsummary/:id',
        classes: 'nav-item ',
        icon: 'assets/images/menuicon/sub-menu/username.svg',
        hiddenFunction:  (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP ) ? true : !userAuthService?.
            hasPermission([
                {moduleName:moduleName.patient,featureName:featureName.patient,operationName:operationName.all },
                {moduleName:moduleName.serviceContract,featureName:featureName.patient,operationName:operationName.view },
                {moduleName:moduleName.insurance,featureName:featureName.patient,operationName:operationName.view },
                {moduleName:moduleName.paymentManagement,featureName:featureName.patient,operationName:operationName.view },
                {moduleName:moduleName.scheduler,featureName:featureName.patient,operationName:operationName.view },
                { moduleName:moduleName.report, featureName: featureName.patient, operationName:operationName.view }
            ]),
    },
    {
        id: 2,
        title: 'Patient',
        type: 'item',
        url: '/patientinfo/patientLoginInfo/patientsummary/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/username.svg',
        hiddenFunction:  (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP ) ? false : true,
    },
    {
        id: 3,
        title: 'Contract',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/contractsummary/:id', 
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/menuicon/sub-menu/contractcard.svg',
        hiddenFunction:  (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP ) ? true : !userAuthService?.
        hasPermission([{ moduleName:moduleName.serviceContract,featureName:featureName.serviceContract,operationName:operationName.create }
        ]),
    },
    {
        id: 4,
        title: 'TX Card',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/txcardsummary/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/menuicon/sub-menu/tx-chart.svg',
        hiddenFunction:  (userRole, userAuthService) =>  (userRole == roles.patient || userRole == roles.patientFRP ) ? true :  !userAuthService?.
            hasPermission([{ moduleName:moduleName.patient, featureName:featureName.patient, operationName:operationName.all }]),
    },
    {
        id: 5,
        title: 'Insurance',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/insurancesummary/:id',
        classes: 'nav-item custom-menu-width-30',
        icon: 'assets/images/menuicon/sub-menu/insurance-menu.svg',
        hiddenFunction:  (userRole, userAuthService) =>  (userRole == roles.patient || userRole == roles.patientFRP ) ? true :  !userAuthService?.
            hasPermission([{ moduleName:moduleName.patient,featureName:featureName.insuranceBenefit,operationName:operationName.all },
                { moduleName:moduleName.insurance,featureName:featureName.insuranceClaim,operationName:operationName.all },
                { moduleName:moduleName.patient,featureName:featureName.patient,operationName:operationName.all }]),
    },
    {
        id: 6,
        title: 'Card',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/cardsummary/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/svg/add-card.svg',
        hiddenFunction:  (userRole, userAuthService) =>  (userRole == roles.patient || userRole == roles.patientFRP ) ? true :  !userAuthService?.
            hasPermission([{ moduleName:moduleName.paymentManagement,featureName:featureName.paymentPatient,operationName:operationName.all },
                {moduleName:moduleName.paymentManagement,featureName:featureName.paymentOtherThanCard,operationName:operationName.all} ]),
    },
    {
        id: 7,
        title: 'Image',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/imagesummary/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/payment-imaging.png',
        hiddenFunction:  (userRole, userAuthService) =>  (userRole == roles.patient || userRole == roles.patientFRP ) ? true : !userAuthService?.
            hasPermission([{ moduleName:moduleName.patient,featureName:featureName.patient,operationName:operationName.all }])
    },
    {
        id: 8,
        title: 'Contract',
        type: 'item',
        url: '/patientinfo/patientLoginInfo/contractsummary/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/menuicon/sub-menu/contractcard.svg',
        hiddenFunction:  (userRole, userAuthService) =>  (userRole == roles.patient || userRole == roles.patientFRP ) ? false : true
    },
    {
        id: 9,
        title: 'Insurance',
        type: 'item',
        url: '/patientinfo/patientLoginInfo/insurancesummary/:id',
        classes: 'nav-item custom-menu-width-30',
        icon: 'assets/images/menuicon/sub-menu/insurance-menu.svg',
        hiddenFunction:  (userRole, userAuthService) =>  (userRole == roles.patient || userRole == roles.patientFRP ) ? false : true,
    },
    {
        id: 10,
        title: 'Card',
        type: 'item',
        url: '/patientinfo/patientLoginInfo/cardsummary/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/svg/add-card.svg',
        hiddenFunction:  (userRole, userAuthService) =>  (userRole == roles.patient || userRole == roles.patientFRP ) ? false :true,
    },
    {
        id: 11,
        title: 'Document',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/document/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/svg/payment-document.svg',
        hiddenFunction: (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP) ? true : !userAuthService?.
            hasPermission([{ moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.all }]),
    },
    {
        id: 12,
        title: 'Appt History',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/appointmentsummery/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/svg/appt-history.svg',
        hiddenFunction: (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP) ? true : !userAuthService?.
            hasPermission([{ moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.all }]),
    },
    {
        id: 13,
        title: 'Letter',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/letters/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/letter-icon.png',
        hiddenFunction: (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP) ? true : !userAuthService?.
            hasPermission([{ moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.all }]),
    },
    {
        id: 14,
        title: 'Comm',
        type: 'item',
        url: '/patientinfo/patientdashboard/:id/communications/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/communication-blue.png',
        hiddenFunction: (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP) ? true : !userAuthService?.
            hasPermission([{ moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.all }]),
    },
    {
        id: 15,
        title: 'Document',
        type: 'item',
        url: '/patientinfo/patientLoginInfo/document/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/svg/payment-document.svg',
        hiddenFunction: (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP) ? false : true,
    },
    {
        id: 16,
        title: 'Appt History',
        type: 'item',
        url: '/patientinfo/patientLoginInfo/appointmentsummery/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/svg/appt-history.svg',
        hiddenFunction: (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP) ? false : true,
    },
    {
        id: 17,
        title: 'Letter',
        type: 'item',
        url: '/patientinfo/patientLoginInfo/letters/:id',
        classes: 'nav-item custom-menu-width-25',
        icon: 'assets/images/letter-icon.png',
        hiddenFunction: (userRole, userAuthService) => (userRole == roles.patient || userRole == roles.patientFRP) ? false : true,
    },
] 