import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import html2canvas from 'html2canvas';
import { AlertService } from '../../shared/utils/alert.service';
import { CommonUtilsService } from '../../shared/utils/common-utils.service';
import { getDocumentCategories } from '../store/document-core-store/document-core.reducer';
import { Observable, Subject, Subscription } from 'rxjs';
import { addDocuments, resetDocumentUploadState, uploadDocument } from '../store/document-upload-store/document-upload.reducer';
import { uploadType } from '../constant/document-message.constant';
import { selectDocumentUploadState } from '../store/document-upload-store/document-upload.selector';
import { DocumentUploadResponse, DocumentUploadState } from '../store/document-upload-store/document-upload.state';

@Injectable()
export class uploadDocumentFileService implements OnDestroy {

  private subscriptions$ = new Subscription();
  private uploadResponseSubject = new Subject<DocumentUploadResponse>();

  documentUploadInfo$ = this.store.select(selectDocumentUploadState);
  documentUploadState !: DocumentUploadState;

  constructor(private store: Store, private alert: AlertService) {
    this.store.dispatch(getDocumentCategories());
    this.subscriptions$.add(this.documentUploadInfo$.subscribe((data: DocumentUploadState) => this.bindDocumentInformation(data)));
  }

  uploadHtml2CanvasImage(elementId: string, fileName: string, categoryId: number, subcategoryId: number): Observable<DocumentUploadResponse> {
    const canvasElement = document.getElementById(elementId);

    if (!canvasElement) {
      return new Observable<any>((observer) => {
        observer.error('Canvas element not found');
      });
    }

    return new Observable<any>((observer) => {
      html2canvas(canvasElement).then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const imageFile = new File([blob], fileName + '.png', { type: 'image/png' });
            this.uploadFile(imageFile, categoryId, subcategoryId).subscribe((response) => {
              observer.next(response);
              observer.complete();
              this.store.dispatch(resetDocumentUploadState())
            });
          } else {
            observer.error('Blob not created');
          }
        }, 'image/png');
      });
    });
  }

  showDocumentCategoryError(errorMessage: string | undefined): void {
    if (errorMessage)
      this.alert.failureAlert(errorMessage);
  }

  uploadFile(file: File, categoryId: number, subcategoryId: number): Observable<DocumentUploadResponse> {
    const files: File[] = [file];
    this.store.dispatch(addDocuments({ documents: files }));

    const formData: FormData = this.mapDocumentFormData(file, categoryId, subcategoryId);
    this.store.dispatch(uploadDocument({ document: file, formData, uploadType: uploadType.document }));

    this.subscriptions$.add(
      this.documentUploadInfo$.subscribe((data: DocumentUploadState) => {
        if (data.documents && data.documents[0]?.completed && data.documents[0]?.documentUploadResponse) {
          this.uploadResponseSubject.next(data.documents[0].documentUploadResponse);
        }
      })
    );
    return this.uploadResponseSubject.asObservable(); // Return the Observable for upload response
  }

  bindDocumentInformation(data: DocumentUploadState) {
    if (data) this.documentUploadState = data
  }

  private mapDocumentFormData(file: File, categoryId: number, subcategoryId: number) {
    const formData: FormData = new FormData();
    formData.append('File', file);
    formData.append('DocumentCategoryId', categoryId.toString());
    formData.append('DocumentSubCategoryId', subcategoryId.toString());
    return formData;
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
