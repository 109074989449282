<div class="dialing-container p-2"
    [ngClass]="{'dialpad-shown': bShowDialpad || bShowTransferDialpad, 'incoming-call': bShowIncomingCallUI}">


    <ng-container *ngIf="!bShowDialpad && !bShowTransferDialpad; else dialpad">
        <div class="topdial">
            <div class="detail">
                <!-- <div class="display-picture">
                    <img src="./assets/images/user-profile-round.png" alt="">
                </div> -->
                <div class="phone-display">
                    <div class="status">{{sCallStatus}}</div>
                    <div *ngIf="sDisplayName !== ''" class="display-name cursor-pointer text-decoration-underline" (click)="goToPatientSummary()">{{sDisplayName}}</div>
                    <div class="phone-number">{{phoneNumber}}</div>
                    <div *ngIf="!!callTime" class="timer">{{callTime}}</div>
                </div>
            </div>
            <div class="actions d-flex" *ngIf="(this.phoneNumber === this.onCallInprogressphoneNumber)">
                <div class="action-btn" (click)="onMuteUnmuteClick()">
                    <i class="fa fa-solid {{bIsMuted ? 'fa-microphone-slash' : 'fa-microphone'}} "></i>
                    <span>
                        {{bIsMuted ? 'unmute' : 'mute'}}
                    </span>
                </div>

                <div class="action-btn" (click)="onDialpadClick()">
                    <i class="fa fa-regular fa-keyboard"></i>
                    <span>
                        dialpad
                    </span>
                </div>
                <!-- <div class="action-btn disabled" (click)="onHoldClick()">
                    <i class="fa fa-solid fa-pause"></i>
                    <span>pause</span>
                </div> -->
            </div>
        </div>
        <div class="bottom-dial">
            <div class="call-action-buttons my-auto">
                <div class="end-btn" (click)="endCall()" title="End call">
                    <i class="fa fa-solid fa-phone-slash"></i>
                </div>

                <div class="accept-btn" (click)="acceptCall()" title="Accept call">
                    <i class="fa fa-solid fa-phone-volume"></i>
                </div>
            </div>
        </div>

    </ng-container>
    <ng-template #dialpad>
        <app-call-progress-dialpad [bCallInProgress]='bShowDialpad' class="dialpad" (oCallClicked)="endCall()">
            <div class="hide-text" (click)="onHideClick()">Hide</div>
        </app-call-progress-dialpad>
        <!-- <div class="hide-btn" (click)="onHideClick()">Hide</div> -->
    </ng-template>
</div>