import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-skeleton',
  template: `
            <app-card [hidHeader]="true" class="appcard-global-mb">
              <div class="skeleton m-0 p-0">
                  <div class="row">
                      <div class="col-lg-4" *ngFor="let count of repeatHtmlElement(fields)">
                          <div class="line m-b-5" style="height:15px;width:60%"></div>
                          <div class="line m-b-20" style="height:35px;width:100%"></div>
                      </div>
                  </div>
              </div>
          </app-card>
          `,
})
export class FormSkeletonComponent {
  @Input() fields: number | undefined;

  repeatHtmlElement(count: number | undefined): Array<number> {
    return Array(count);
  }
}
