import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
    AbstractControl, ControlValueAccessor, NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';

@Component({
    selector: 'app-form-control-number-textbox',
    template: `
                <div class="form-group" [class.has-error]="hasError()">
                    <label *ngIf="label && (showlabel)">{{ label }}
                      <span *ngIf="required || requiredField" class="required_field ms-1">*</span>
                    </label>
                    <input 
                        type="text" 
                        [class]="'form-control ' + (label ? '' : 'm_t')" 
                        [placeholder]="setPlaceHolder" 
                        [value]="value"
                        [maxLength]="maxlength"
                        [disabled]="disabledField || disabled"
                        (input)="onInputEvent($event)"
                        (focus)="onFocusEvent()"
                        (blur)="onBlurEvent()"
                        [id]="uniqueId ? label + '_' + uniqueId : label"
                        (directiveComplete)="onChangeEvent($event)"
                        numericDecimalOnly
                    />
                    <ng-container *ngIf="hasError()">
                        <app-validation-error [errors]="control?.errors" [fieldName]="!label ? placeholder : label">
                        </app-validation-error>
                    </ng-container>
            </div>
           `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormControlNumberTextboxComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormControlNumberTextboxComponent),
            multi: true
        }
    ],
    styles: [`
    .m_t { margin-top: 0.5rem; }
    /* Hide the arrow-up and arrow-down icons for number input */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
  `]
})
export class FormControlNumberTextboxComponent implements ControlValueAccessor, Validator {

    @Input() label!: string;
    @Input() required?: boolean | false;
    @Input() placeholder?: string;
    @Input() maxlength?: number = 5;
    @Input() focusPlaceholder?: string;
    @Input() emptyStringToNull = false;
    @Input() disabledField?: boolean | false;
    @Input() requiredField?: boolean | false;
    @Input() uniqueId: string = '';
    @Input() showlabel?: boolean = true;

    setPlaceHolder: string | undefined
    value: any;
    onChange: any = () => { };
    onTouched: any = () => { };
    control: AbstractControl | undefined;
    disabled: boolean;

    @Output() inputBlur = new EventEmitter<boolean>();
    @Output() onInputChange = new EventEmitter<string>();

    constructor() {
        this.disabled = false;
        this.label = '';
    }

    ngOnInit() {
        this.setPlaceHolder = this.placeholder || this.label;
    }

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        this.control = control;
        return null;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    updateTheValue(event: any) {
        this.value = (event.target as HTMLInputElement).value;
        this.onChange(this.value);
        this.control?.markAsTouched();
    }

    onBlurEvent(): void {
        this.onTouched()
        this.inputBlur.emit();
        this.setPlaceHolder = this.placeholder || this.label;
    }

    onInputEvent(event: any): void {
        this.convertEmptyStringToNull(event);
        this.updateTheValue(event);
    }
    onChangeEvent(event: any) {
        this.onInputChange.emit(this.value);
    }
    onFocusEvent(): void {
        this.setPlaceHolder = this.focusPlaceholder || this.placeholder || this.label;
    }

    hasError(): boolean | undefined {
        return this.control?.invalid && (this.control?.touched || this.control?.dirty);
    }

    convertEmptyStringToNull(event?: Event) {
        if (this.emptyStringToNull && event) {
            const value = (event.target as HTMLInputElement).value;
            if (value === '') {
                this.control?.patchValue(null);
            }
        }
    }
    onKeyPress(event: KeyboardEvent) {
        const inputValue = (event.target as HTMLInputElement).value.toString();
        if (this.maxlength !== undefined && inputValue.length >= this.maxlength) {
            event.preventDefault();
        }
    }
}