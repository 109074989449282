import { Component, EventEmitter, OnDestroy, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, map } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  selectPatientSearchFilter, selectPracticeLocation, selectPracticePatientStatus, selectPatientSearchStateEvent,
  selectBulkEmailPatientSearchFilter,
  selectBulkEmailPatientSearchStateEvent
} from '../../store/selectors/patient-search.selector';
import {
  bulkPatientSearchStateDestroy,
  bulkSelectedPatients,
  closeErrorAlert, invokedBulkPatientSearchFilter, invokedPatientSearchFilter, invokedPracticeLocationAPI,
} from '../../store/actions/patient-search.action';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { CustomValidatorService } from 'src/app/features/shared/utils/custom-validator.service';
import { CommonUtilsService } from 'src/app/features/shared/utils/common-utils.service';
import { PatientBasicInfoModel, PatientSearchFilterModel, PatientSearchStateEvent, PracticeLocation, PracticePatientStatus } from '../../types/patient-search-type';
import { MASK_PATTERN } from 'src/app/features/shared/constant/shared-pattern-regex-asset';
import { DebounceService } from 'src/app/features/shared/services/debounce-service';
import { bulkPatientSearchFilterTypes, patientSearchMessage, patientSearchTypes } from '../../constant/patient-dashboard-api-asset';
import { MaskingType } from 'src/app/features/shared/constant/shared-constant';
import { DateRange } from 'src/app/features/shared/types/shared-types';
import { FormControlDateRangeComponent } from 'src/app/features/shared/components/form-controls/form-control-daterange/form-control-daterange.component';
import { featureName, moduleName, operationName } from 'src/app/features/user/constant/user-role-constant';

@Component({
  selector: 'app-patient-search-filter',
  templateUrl: './patient-search-filter.component.html',
})

export class PatientSearchFilterComponent implements OnInit, OnDestroy {
  @Input() allowToSelectPatient = false;
  @Input() isAutoSearch = true;
  @Input() pageIdentifier = patientSearchTypes.patientSearch;
  @Output() submitPatients: EventEmitter<void> = new EventEmitter<void>();

  patientSearchFilter: FormGroup;
  isLoading: boolean | undefined;
  maskTeleUS = MASK_PATTERN.usMobile;
  maskDateSlash = MASK_PATTERN.dob;
  maskingType = MaskingType;
  bulkFilterTypes = bulkPatientSearchFilterTypes;
  patientSearchType = patientSearchTypes;
  private subscriptions$ = new Subscription();

  bulkCommunicationCreateFeaturePermission = { moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.create};

  patientSearchFilter$ = this.store.select(selectPatientSearchFilter);
  practicePatientStatus$ = this.store.select(selectPracticePatientStatus).pipe(map(this.sortPracticePtStatus));
  practiceLocation$ = this.store.select(selectPracticeLocation).pipe(map(this.sortPracticeLocation));
  isLoading$ = this.store.select(selectPatientSearchStateEvent);

  bulkEmailPatientSearchFilter$ = this.store.select(selectBulkEmailPatientSearchFilter);
  bulkStateEvent$ = this.store.select(selectBulkEmailPatientSearchStateEvent);
  
  @Output() closeSearchResultOverlay = new EventEmitter<boolean>();
  formSearchSubmitted = false;

  showAppointmentDateRange = false;
  showPastdueDaysRange= false;
  showPatientStatus = false;
  selectAllLoading = false;
  selectedPatientsBasicInfo: PatientBasicInfoModel[] = [];

  constructor(private store: Store,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private commonUtilsService: CommonUtilsService,
    private debounceService: DebounceService) {

    this.patientSearchFilter = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      patientName: [''],
      patientPreferredName: ['', [Validators.maxLength(100)]],
      patientDOB: ['', { asyncValidators: [CustomValidatorService.dobFromatValidator()] }],
      patientPrimaryPhone: [''],
      patientPrimaryEmailAddress: ['', [Validators.maxLength(100)]],
      patientFinancialResponsiblePersonFirstName: [''],
      patientFinancialResponsiblePersonLastName: [''],
      patientFinancialResponsiblePerson: ['', [Validators.maxLength(100)]],
      patientStatusId: [''],
      selectedPatientStatusId: [[]],
      practiceLocationId: [''],
      selectedPracticeLocations: [[]],
      SortOrder: [''],
      SortColumn: [''],
      tackAfter: [''],
      pageSize: [''],
      patientId: [''],
      showInActivePatients: [false],
      filter: [null],
      fromDate: [''],
      toDate: [''],
      fromDays: [''],
      toDays: [''],
    });
  }

  ngOnInit(): void {
    this.store.dispatch(invokedPracticeLocationAPI({pageIdentifier: this.pageIdentifier}));
    const bulkEmail =patientSearchTypes.bulkEmailPatientSearch;
    if(this.pageIdentifier === bulkEmail){
      this.subscriptions$.add(this.bulkEmailPatientSearchFilter$.subscribe(async (data: PatientSearchFilterModel) => this.bindPatientSearchFilterInformation(data)))
      this.subscriptions$.add(this.bulkStateEvent$.subscribe(async (data: PatientSearchStateEvent) => this.handlePatientSearchStateEvent(data)));
    }
    else{
      this.subscriptions$.add(this.patientSearchFilter$.subscribe(async (data: PatientSearchFilterModel) => this.bindPatientSearchFilterInformation(data)))
      this.subscriptions$.add(this.isLoading$.subscribe(async (data: PatientSearchStateEvent) => this.handlePatientSearchStateEvent(data)));
    }
  }

  async bindPatientSearchFilterInformation(data: PatientSearchFilterModel) {
    if (data) {
      this.patientSearchFilter.patchValue(data);
    }
  }

  async handlePatientSearchStateEvent(data: PatientSearchStateEvent) {
    this.isLoading = data.loading;
    this.selectAllLoading= data.selectAllLoading??false;
    if (data.showErrorAlert) {
      await this.alert.failureAlert(data.errorMessage);
      this.store.dispatch(closeErrorAlert({pageIdentifier: this.pageIdentifier}));
    }
  }

  onPatientEmailChange(patientSearchFilter: FormGroup): void {
    this.getPatients();
  }

  onPhoneNumberChange(patientSearchFilter: FormGroup): void {
    this.getPatients();
  }

  private sortPracticePtStatus(practicePtStatus: PracticePatientStatus[]) {
    if (!practicePtStatus) {
      return practicePtStatus;
    }
    return practicePtStatus.slice().sort((a, b) => {
      const aValue = a.patientStatusName || '';
      const bValue = b.patientStatusName || '';
      return aValue.localeCompare(bValue);
    });
  }

  private sortPracticeLocation(practiceLocation: PracticeLocation[]) {
    if (!practiceLocation) {
      return practiceLocation;
    }
    return practiceLocation.slice().sort((a, b) => {
      const aValue = a.practiceLocationName || '';
      const bValue = b.practiceLocationName || '';
      return aValue.localeCompare(bValue);
    });
  }

  selectPracticeLocation(event: PracticeLocation[], patientSearchFilter: FormGroup): void {
    patientSearchFilter.value.practiceLocationId = event.map(item => item.practiceLocationId).join(',');
    this.dispatchPatientSearchResultAPI(patientSearchFilter.value);
  }

  selectPracticePatientStatus(event: PracticePatientStatus[], patientSearchFilter: FormGroup): void {
    patientSearchFilter.value.patientStatusId = event.map(item => item.patientStatusId).join(',');
    this.dispatchPatientSearchResultAPI(patientSearchFilter.value);
  }

  handleBulkFilterChange(filter: {filterValue:number}): void {
    this.clearBulkEmailPatientSearchFilter();
    if (filter) {
      if (filter.filterValue === 1) {
        this.showAppointmentDateRange = true;
        this.patientSearchFilter.get('fromDate')?.setValidators([Validators.required]);
        this.patientSearchFilter.get('toDate')?.setValidators([Validators.required]);
      }
      else if (filter.filterValue === 2) {
        this.showPastdueDaysRange = true;
      this.patientSearchFilter.get('fromDays')?.setValidators([Validators.required]);
      this.patientSearchFilter.get('toDays')?.setValidators([Validators.required]);
      }
    }
    this.patientSearchFilter.get('fromDays')?.updateValueAndValidity();
    this.patientSearchFilter.get('toDays')?.updateValueAndValidity();
    this.patientSearchFilter.get('fromDate')?.updateValueAndValidity();
    this.patientSearchFilter.get('toDate')?.updateValueAndValidity();
  }

  onFRPNameChange(patientSearchFilter: FormGroup): void {
    const frpFullName = this.commonUtilsService.splitFullName(patientSearchFilter.value.patientFinancialResponsiblePerson)
    patientSearchFilter.value.patientFinancialResponsiblePersonLastName = this.commonUtilsService.trimAndLowerCase(frpFullName.lastName);
    patientSearchFilter.value.patientFinancialResponsiblePersonFirstName = this.commonUtilsService.trimAndLowerCase(frpFullName.firstName);
    this.getPatients();
  }

  onPatientPreferredNameChange(patientSearchFilter: FormGroup): void {
    this.getPatients();
  }

  onPatientDOBChange(patientSearchFilter: FormGroup): void {
    if (this.patientSearchFilter.valid) {
      this.getPatients();
    }
  }

  clearFilter(patientSearchFilter: FormGroup) {
    this.patientSearchFilter.reset();
    this.patientSearchFilter.patchValue({
      showInActivePatients: false
    });
    this.clearBulkEmailPatientSearchFilter();

    if (this.isAutoSearch === true)
      this.dispatchPatientSearchResultAPI(patientSearchFilter.value);
  }

  isValidField(field: string) {
    return this.commonUtilsService.isValidField(field, this.patientSearchFilter)
  }

  getPatients() {
    if (this.isAutoSearch === true) {
      this.debounceService.debounce('getPatients', () => {
        this.dispatchPatientSearchResultAPI(this.patientSearchFilter.value);
      });
    }
  }

  async searchPatients() {
    if(this.selectedPatientsBasicInfo.length > 0){
      const response = await this.alert.yesNoWarningAlert(patientSearchMessage.newSearchConfimation)
      if (!response.isConfirmed) {
        return;
      }
    } 
    this.selectedPatientsBasicInfo = [];
    this.formSearchSubmitted = true;
    this.dispatchPatientSearchResultAPI(this.patientSearchFilter.value);
    this.formSearchSubmitted = false;
  }

  dispatchPatientSearchResultAPI(patientSearchFilterValue: PatientSearchFilterModel) {
    if (this.isAutoSearch === true || this.formSearchSubmitted === true) {
      if (this.patientSearchFilter.valid) {
        patientSearchFilterValue = { ...patientSearchFilterValue, 
          patientId: null,
          formSubmitted: true,
          patientPrimaryEmailAddress: patientSearchFilterValue.patientPrimaryEmailAddress?.trim(),
         }
        if (this.pageIdentifier === this.patientSearchType.bulkEmailPatientSearch) {
          this.store.dispatch(invokedBulkPatientSearchFilter({ patientSearchFilter: patientSearchFilterValue, pageIdentifier: this.pageIdentifier }));
        }
        else {
          this.store.dispatch(invokedPatientSearchFilter({ patientSearchFilter: patientSearchFilterValue, pageIdentifier: this.pageIdentifier }));
        }
      }
    }
  }

  onPatientNameChange(patientSearchFilter: FormGroup): void {
    const patientName = this.commonUtilsService.splitFullName(patientSearchFilter.value.patientName);
    patientSearchFilter.value.lastName = this.commonUtilsService.trimAndLowerCase(patientName.lastName);
    patientSearchFilter.value.firstName = this.commonUtilsService.trimAndLowerCase(patientName.firstName);
    this.getPatients();
  }

  closeOverlay() {
    this.closeSearchResultOverlay.emit(true);
  }

  toggleShowActivePatient(event: boolean) {
    this.patientSearchFilter.patchValue({
      showInActivePatients: event
    });
    this.dispatchPatientSearchResultAPI(this.patientSearchFilter.value);
  }

  submitSelectedPatients() {
    if(this.selectedPatientsBasicInfo.length === 0 || this.selectAllLoading) return;
   
    this.store.dispatch(bulkSelectedPatients({ patients: this.selectedPatientsBasicInfo }));
    this.submitPatients.emit();
  }

  handleSelectedPatients(patients: any) {
    this.selectedPatientsBasicInfo = patients;
  }

  setFilterDatesByDateRange(dateRange: DateRange | null) {
    if (dateRange) {
      this.patientSearchFilter.patchValue({
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate
      });
    }
    else {
      this.patientSearchFilter.patchValue({ fromDate: null, toDate: null });
    }
  }

  clearBulkEmailPatientSearchFilter() {
    this.clearDateRangeForm();
    this.showAppointmentDateRange=false;
    this.showPastdueDaysRange=false;
    this.clearFormValidation();
  }

  @ViewChild('dateRangeComponent') dateRangeComponent!: FormControlDateRangeComponent;
  clearDateRangeForm(): void {
   if(this.dateRangeComponent) this.dateRangeComponent.clearDateRange();
  }

  clearFormValidation() {
    this.patientSearchFilter.get('fromDays')?.clearValidators();
    this.patientSearchFilter.get('fromDays')?.updateValueAndValidity();
    this.patientSearchFilter.get('toDays')?.clearValidators();
    this.patientSearchFilter.get('toDays')?.updateValueAndValidity();
    this.patientSearchFilter.get('fromDate')?.clearValidators();
    this.patientSearchFilter.get('fromDate')?.updateValueAndValidity();
    this.patientSearchFilter.get('toDate')?.clearValidators();
    this.patientSearchFilter.get('toDate')?.updateValueAndValidity();
    this.resetFormSubmittedState();
  }

  resetFormSubmittedState() {
    this.patientSearchFilter.markAsPristine();
    this.patientSearchFilter.markAsUntouched();
    this.patientSearchFilter.updateValueAndValidity();
  }
 
  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    if(this.pageIdentifier===this.patientSearchType.bulkEmailPatientSearch)
      this.store.dispatch(bulkPatientSearchStateDestroy());
  }
}