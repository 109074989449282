import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectPracticeLetterTemplates, selectLetterTemplatesErrorMessage } from '../../store/selector/practice-core-selector';
import { invokeGetLetterTemplates } from '../../store/reducer/practice-core.reducer';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { LetterTemplate } from '../../store/state/practice-core';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';

@Component({
  selector: 'app-letter-template-dropdown',
  template: `
          <div class="form-group" [class.has-error]="hasError()">
              <label *ngIf="label">{{ label }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>

              <ng-select 
                [items]="letterTemplates" 
                [closeOnSelect]="true" 
                [multiple]="isMultiple"
                [searchable]="true" 
                bindLabel="practiceLetterTemplateName" 
                [placeholder]="label"
                bindValue='practiceLetterTemplateId' 
                [ngModel]="updatedValue"
                
                (ngModelChange)="selectBoxChanged($event)"
                (blur)="onTouched()"
              >
              </ng-select>

              <div class="validation_msg" *ngIf="errorMessage$ | async" >
                <span> error : {{errorMessage$ | async}}</span>
              </div>

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="label">
                </app-validation-error>
              </ng-container>
          </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LetterTemplateDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LetterTemplateDropdownComponent),
      multi: true
    }
  ],
})

export class LetterTemplateDropdownComponent extends BaseDestroyComponent implements ControlValueAccessor, Validator, OnInit {

  @Input() label!: string;
  @Input() required?: boolean | false;
  @Input() isMultiple!: boolean | false;
  @Input() showTxCardEnabled!: boolean | false;

  control: AbstractControl | undefined;
  updatedValue: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled!: boolean;

  letterTemplates$: any = this.store.select((selectPracticeLetterTemplates));
  errorMessage$ = this.store.select((selectLetterTemplatesErrorMessage));
  letterTemplates!: LetterTemplate[];

  constructor(private store: Store, private authService: AuthService) { super() }

  ngOnInit(): void {
    this.store.dispatch(invokeGetLetterTemplates({ practiceId: this.authService.getPracticeIDFromToken() }));
    this.subscriptions$.add(this.letterTemplates$.subscribe(async (data: LetterTemplate[]) => this.bindPatientLetterTemplate(data)));

  }

  bindPatientLetterTemplate(data: LetterTemplate[]) {
    if (!data) return;
    if (this.showTxCardEnabled)
      this.letterTemplates = data.filter(l => l.showTemplateInTreatmentCard)
    else
      this.letterTemplates = data
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.updatedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeEvent(event: any) {
    this.control?.markAsTouched();
    this.onChange(event)
  }

  selectBoxChanged(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

}