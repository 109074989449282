export const REGEX_PATTERN = {
    onlyNumeric: '^[0-9]*$',
    usMobile: /(\d{3})(\d{3})(\d{4})/,
    URL :'((http|https)://)?(www.)?[a-zA-Z0-9@:%_\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%"._\\+~#?&//=]*)',
    amount: /^(?:[0-9]\d{0,4}|99999)?$/,
    percentage: /^(100(\.0{1,2})?|[0-9]?\d)$/,
    onlyAlphabetAndSpace: '[A-Za-z ]*',
    onlyAlphanumeric: '[A-Za-z0-9]*',
    OnlyAlphanumericAndSpace: '[A-Za-z0-9 ]*',
    alphanumericAndSpecialCharacters: '[A-Za-z0-9 @.,?!\\-\\/;:()&*_+\\[\\]{}#]*', 
    onlyAlphabetSpacesAndHypens: '[A-Za-z -]*',
    zipcode: '^(?!00000)[0-9]+$',
    groupNpi: /^[a-zA-Z0-9]+$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/,
    multiemail: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:[,;]\s*|$))+$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/,
    phoneNumber: /^\(\d{3}\)\s?\d{3}-\d{4}$/,
    mobileNumber: /^\+?1?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
    taxId: /^(?!00)\d{2}\-\d{7}$/,
    city: /^[A-Za-z -]*$/,
    dobRegex: /^(0[1-9]|1[012])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/,
    ssnNumber: /^\d{3}-\d{2}-\d{4}$/,
    amountDecimal: /^(?:\d{1,5}(\.\d{0,2})?|99999(\.00)?)?$/,
    monthDayYearFormat : /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/
}

export const MASK_PATTERN = {
    usMobile: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    dob: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    date: 'MM/dd/yyyy',
    dateTime: 'MM/dd/yyyy hh:mm a',
    time: 'hh:mm a',
    usTaxId: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    ssn: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
}