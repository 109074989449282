import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MASK_PATTERN } from '../constant/shared-pattern-regex-asset';


@Injectable()
export class CommonUtilsService {
  static validateAllFormFields: any;
  static isValidField: any;

  constructor() { }

  validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
  }

  isValidField(field: string, formGroup: FormGroup) {
    return formGroup.get(field)?.invalid && (formGroup.get(field)?.touched || formGroup.get(field)?.dirty);
  }

  trimAndLowerCase(str: string | null | undefined): string {
    return (!str) ? "" : str.toLocaleLowerCase().trim();
  }

  splitFullName(fullName: string): { firstName: string, lastName: string } {
    return CommonUtilsService.splitFullName(fullName);
  }

  static splitFullName(fullName: string): { firstName: string, lastName: string } {
    if(!fullName) return { firstName: "", lastName: "" };
    fullName = fullName?.trim();
    const parts = fullName?.split(",")
    const lastName = parts[0] ?? "";
    const firstName = parts[1] ?? "";
    return { firstName: firstName.trim(), lastName : lastName.trim() };
  }

  generateInitial(fullName: string, splitBy = ' ', isReverse = false): string {
    // Remove leading/trailing spaces and convert the string to lowercase
    const trimmedName = fullName.trim().toLowerCase();
  
    // Replace any comma with the specified splitBy character
    const formattedName = trimmedName.replace(',', splitBy);
  
    // Split the formatted name into an array of individual names
    const names = formattedName.split(splitBy);
  
    // Initialize the result variable as an empty string
    let result = '';
  
    // Iterate over each name
    for (const name of names) {
      // If the name is not empty, append the first character to the result
      if (name.length > 0) {
        result += name.charAt(0);
      }
    }
  
    // Reverse the initials if isReverse is set to true
    if (isReverse) {
      result = result.split('').reverse().join('');
    }
  
    if (result.length > 1) {
      const firstCharacter = result.charAt(0);
      const lastCharacter = result.charAt(result.length - 1);
      result = firstCharacter + lastCharacter
    }
  
    return result.toUpperCase();
  }
  
  
  calculateYearAndMonth(startDate: Date, endDate: Date): { years: number, months: number } {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const currentYear = endDate.getFullYear();
    const currentMonth = endDate.getMonth();

    let years = currentYear - startYear;
    let months = currentMonth - startMonth;

    if (months < 0) { years--; months += 12; }

    return { years, months };
  }

  convertToServerDateFormat(dateString: string): string {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const month = parts[0].padStart(2, '0');
      const day = parts[1].padStart(2, '0');
      const year = parts[2];
      return `${year}-${month}-${day}`;
    }
    return dateString;
  }

  convertToServerDateTimeFormat(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const milliseconds = ('00' + date.getMilliseconds()).slice(-3);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  validateAllFormFieldsAndscrollTop(formGroup: FormGroup) {
    let firstInvalidControl: HTMLElement | undefined;
    let firstInvalidControlId: string = "";

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        if (control.invalid && !firstInvalidControl) {
          const invalidControl = document.querySelector(`[formcontrolname="${field}"]`);
          firstInvalidControlId = field;
          if (invalidControl instanceof HTMLElement) {
            firstInvalidControl = invalidControl;
          }
        }
      } else if (control instanceof FormGroup) {
        this.validateAllFormFieldsAndscrollTop(control);
      }
    });

    if (firstInvalidControl) {
      firstInvalidControl?.focus();
      firstInvalidControl?.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getConvertedAmount(amount: number): number {
     return Number(amount.toFixed(2));
  }

  formatPhoneNumber(phoneNumber: string | null | undefined) {
    if (!phoneNumber) {
        return phoneNumber;
    }

    const usMobileFormat = MASK_PATTERN.usMobile;
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    let formattedPhoneNumber = '';
    let numericIndex = 0;

    for (const formatPart of usMobileFormat) {
        if (numericIndex >= numericPhoneNumber.length) {
            break; 
        }

        if (formatPart instanceof RegExp) {
            formattedPhoneNumber += numericPhoneNumber[numericIndex++];
        } else {
            formattedPhoneNumber += formatPart;
        }
    }
    return formattedPhoneNumber;
  }

  removePhoneNumberFormat(phoneNumber: string | null | undefined) {
    if (!phoneNumber) {
        return phoneNumber;
    }
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');  
    return numericPhoneNumber;
  }

  formatCallerId(callerId: string) {
    // Remove leading '+1' from the callerId
    return callerId.replace(/^\+1/, '');
  }
}