import { createAction, props } from '@ngrx/store';
import { ChatReadEvent, ChosenPatientChat, PatientChat, PatientChatCollection, RecentPatientChat, UnreadPatientsChatCollection } from './chat.state';
import { CommunicationSms, CommunicationStatusState } from '../patient-communication.state';

// Chat Actions

export const invokePatientChatCollection = createAction('[Chat] API Get Patient Chat Collection', props<{ patientId: number, patientPhone?: string }>());
export const patientChatCollectionSuccess = createAction('[Chat] Patient Chat Collection Success', props<{ patientChatCollection: PatientChatCollection, patientId: number, patientPhone?: string }>());

export const invokeRecentPatientChatCollection = createAction('[Chat] API Get Recent Patient Chat Collection', props<{ pageSize: number }>());
export const recentPatientChatCollectionSuccess = createAction('[Chat] Recent Patient Chat Collection Success', props<{ recentPatientChatCollection: RecentPatientChat[] }>());

export const chatSendSms = createAction('[ChatSendSms] API Create Patient Chat Sms', props<{ communicationSms: CommunicationSms, patientChat:PatientChat }>());
export const chatSentSmsSuccess = createAction('[ChatSendSms] Patient Chat Sms Sent Success', props<{ communicationsStatus: CommunicationStatusState, patientChat:PatientChat }>());

export const invokeGetPatientByPhone=  createAction('[Patient Chat] API Get Patient info by phone number', props<{ phone: string }>());
export const getPatientByPhoneSuccess = createAction('[Patient Chat] Get Patient info by phone number Success', props<{ patient: ChosenPatientChat[],patientPhone: string }>());

export const addPatientChatFromSignalR = createAction('[PatientChat] Add Patient Chat Collection FromSignalR', props<{ patientChat: PatientChat }>());
export const resetPatientChatFromSignalR = createAction('[PatientChat] reset Patient Chat Collection FromSignalR', props<{ patientChat: PatientChat }>());

//Event Action
export const resetChosenPatientChat = createAction('[PatientChat] Reset Chosen Patient Chat');
export const chatStopLoading = createAction('[PatientChat] Patient Chat Stop Loading');
export const handlePatientChatFailure = createAction('[ChatFailure] Patient Chat Load Failure', props<{ error: any }>());
export const closeErrorAlert = createAction('[PatientChat Close Error Alert] close error alert');
export const closeSuccessAlert = createAction('[PatientChat Close Success Alert] Close Success Alert');

export const resetChatState = createAction('[ResetChat] Reset Chat State');
export const resetChosenPatientChatState = createAction('[ResetChat] Reset Chosen Patient Chat  State');

export const addRecentPatientChatFromSignalR = createAction('[PatientChat] add RecentPatientChat From SignalR', props<{ patientChat: RecentPatientChat }>());

export const invokeMarkAsRead = createAction('[PatientChat] invoke MarkAsRead', props<{ chatReadEvent: ChatReadEvent }>());
export const markAsReadSuccess = createAction('[PatientChat] invoke MarkAsRead Success', props<{ communicationsStatus: CommunicationStatusState, chatReadEvent: ChatReadEvent }>());
export const updatePatientChatFromMarkAsReadSignalR = createAction('[PatientChat] update PatientChat From MarkAsRead SignalR', props<{ markAsRead: ChatReadEvent }>());
export const resetPatientChatFromMarkAsReadSignalR = createAction('[PatientChat] reset PatientChat From MarkAsRead SignalR');


export const invokeGetUnreadPatientChatCollection = createAction('[Chat invokeGetUnreadPatientChatCollection] API invokeGetUnreadPatientChatCollection');
export const getUnreadPatientChatCollectionSuccess = createAction('[Chat invokeGetUnreadPatientChatCollection] API invokeGetUnreadPatientChatCollection Success', props<{ unreadPatientsChatCollection: UnreadPatientsChatCollection[]}>());

