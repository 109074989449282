import { createAction, props } from "@ngrx/store";
import { DefaultTemplate, LetterTemplate, LetterTemplatePlaceHolder, LetterTemplateStatus, ScheduleInterval, TemplateCategory } from "./letter-configuration.state";


export const invokeLetterTemplates = createAction('[GetLetterTemplates API] Invoke start loading', props<{ practiceId: number }>());
export const letterTemplatesSuccess = createAction('[GetLetterTemplates] API Success', props<{ letterTemplates: LetterTemplate[] }>());
export const letterTemplatesFailure = createAction('[GetLetterTemplates] API Failure', props<{ errorMessage: string }>());

export const invokeDefaultTemplates = createAction('[GeDefaultTemplates API] Invoke start loading', props<{ templateCategoryId: number }>());
export const defaultTemplatesSuccess = createAction('[GeDefaultTemplates] API Success', props<{ defaultTemplates: DefaultTemplate[] }>());

export const invokeLetterTemplatePlaceHolders = createAction('[GetLetterTemplatePlaceHolders API] Get PlaceHolders');
export const letterTemplatePlaceHoldersSuccess = createAction('[GetLetterTemplatePlaceHolders] API PlaceHolders Success', props<{ letterTemplatePlaceHolders: LetterTemplatePlaceHolder[] }>());

export const invokeScheduleIntervals = createAction('[GetScheduleIntervals API] Get ScheduleIntervals');
export const scheduleIntervalsSuccess = createAction('[GetScheduleIntervals] API ScheduleIntervals Success', props<{ scheduleIntervals: ScheduleInterval[] }>());

export const invokeTemplateCategories = createAction('[GetTemplateCategories API] Get TemplateCategories for notes');
export const templateCategoriesSuccess = createAction('[GetTemplateCategories] API TemplateCategory Success', props<{ templateCategories: TemplateCategory[] }>());

export const invokeLetterTemplateById = createAction('[GetLetterTemplateById API] Invoke letter template by id', props<{ practiceId: number, practiceLetterTemplateId :number }>());
export const getLetterTemplateByIdSuccess = createAction('[GetLetterTemplateById] API Success', props<{ letterTemplate: LetterTemplate }>());

export const letterTemplatesCloseSuccessAlert = createAction('[LetterTemplates] close success alert');
export const letterTemplatesCloseErrorAlert = createAction('[LetterTemplates] close error alert');
export const letterTemplatesStopLoading = createAction('[LetterTemplates] stop loading');

export const invokeUpdateLetterTemplate = createAction('[SaveLetterTemplate] Invoke', props<{ letterTemplate: LetterTemplate, successMessage: string }>());
export const updateLetterTemplateSuccess = createAction('[SaveLetterTemplate] API Success with alert message', props<{ letterTemplates: LetterTemplate }>());

export const invokeUpdateLetterTemplateStatus = createAction('[SaveLetterTemplateStatus] Invoke', props<{ letterTemplateStatus: LetterTemplateStatus, successMessage: string }>());

export const selectedLetterTemplate = createAction('[LetterTemplate] selected Letter Template', props<{ letterTemplate: LetterTemplate }>());

export const resetLetterTemplate = createAction('[LetterTemplate] clear letter template state');

export const invokeGetPracticeTemplatesByCategoryById = createAction('[GetPracticeTemplatesByCategoryById  API] Invoke start loading', props<{ templateCategoryId: number }>());

export const updateTemplateCollapseState =  createAction('[updateTemplateCollapseState] Update Template Collapse State', props<{ isTemplateCollapsed: boolean }>());
