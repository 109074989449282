import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base-destroy',
  template:''
})
export class BaseDestroyComponent implements OnDestroy {
  protected subscriptions$: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
