<app-custom-wizard [steps]="wizardSteps" [templates]="[first,second,third]"></app-custom-wizard>

<ng-template #first>
  <app-first (stepSuccessEvent)="handleSuccessEvent($event)"></app-first>
</ng-template>

<ng-template #second>
  <app-second (stepSuccessEvent)="handleSuccessEvent($event)"></app-second>
</ng-template>

<ng-template #third>
  <app-third (stepSuccessEvent)="handleSuccessEvent($event)" (closeOverlayEvent)="closeOverlay()"></app-third>
</ng-template>