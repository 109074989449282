import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PatientSearchState } from "../state/patient-search-state";

const selectPatientSearchfeatureState = createFeatureSelector<PatientSearchState>('patientSearchStore')

export const selectPatientSearcResult = createSelector(
    selectPatientSearchfeatureState,
    state => state.patientSearchResult
)

export const selectPatientSearchFilter = createSelector(
    selectPatientSearchfeatureState,
    state => state.patientSearchFilter
)

export const selectPatientNameSearchResult = createSelector(
    selectPatientSearchfeatureState,
    state => state.patientNameSearchResult
)

export const selectPracticePatientStatus = createSelector(
    selectPatientSearchfeatureState,
    state => state.practicePatientStatus
)

export const selectPracticeLocation = createSelector(
    selectPatientSearchfeatureState,
    state => state.practiceLocation.filter((_) => _.isActive == true)
)

export const selectPatientSearchStateEvent = createSelector(
    selectPatientSearchfeatureState,
    state => state.patientSearchStateEvent
)

export const selectBulkEmailPatientSearcResult = createSelector(
    selectPatientSearchfeatureState,
    state => state.bulkEmailPatientSearchResult
)

export const selectBulkEmailPatientSearchFilter = createSelector(
    selectPatientSearchfeatureState,
    state => state.bulkEmailPatientSearchFilter
)

export const selectBulkEmailPatientSearchStateEvent = createSelector(
    selectPatientSearchfeatureState,
    state => state.bulkEmailPatientSearchStateEvent
)

export const selectBulkEmailBasicPatientSearcResult = createSelector(
    selectPatientSearchfeatureState,
    state => state.bulkEmailBasicPatientSearchResult
)

export const selectSelectedPatients = createSelector(
    selectPatientSearchfeatureState,
    state => state.selectedPatients
)

export const selectSelectedPatientIds = createSelector(
    selectPatientSearchfeatureState,
    state => state.selectedPatients?.map((_) => _.patientId)
)

export const selectTestPatientResult = createSelector(
    selectPatientSearchfeatureState,
    state => state.testPatientResult
)