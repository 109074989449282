import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PatientTreatmentContractPlanState } from "./contract-plan-dashboard.state";

const selectPatientTreatmentContractPlanFeatureState = createFeatureSelector<PatientTreatmentContractPlanState>('patientTreatmentContractPlanStore');

export const selectPatientTreatmentContractPlans = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientTreatmentContractPlans
);

export const selectPatientTreatmentInstallmentPlans = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientTreatmentInstallmentPlans
);

export const selectPatientTreatmentInstallmentPlan = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientTreatmentInstallmentPlan
);

export const selectPatientTreatmentContractPlantateEvent = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientTreatmentContractPlantateEvent
);

export const selectPatientSummaryData = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientSummaryData
)

export const selectPatientTreatmentInvoices = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientTreatmentInvoices
)
export const selectPatientFrpList = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientFrps
)

export const selectPatientLedger = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientLedger
)

export const selectinstallmentPlanSummary = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.installmentPlanSummary
)

export const selectPatientTreatmentInvoiceHistory = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientTreatmentInvoiceHistory
)

export const selectPatientTreatmentInvoiceReceiptHistory = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientTreatmentInvoiceReceiptHistory
)

export const selectPatientPaymentTransaction = createSelector(
    selectPatientTreatmentContractPlanFeatureState,
    state => state.patientPaymentTransaction
)