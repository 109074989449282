import { NgModule } from '@angular/core';
import { UserHasPermissionDirective } from './directive/user-has-permission.directive';
import { UserRoleService } from './services/user-role.service';

@NgModule({
  declarations: [
    UserHasPermissionDirective
  ],
  imports: [
  ],
  providers: [UserRoleService],
  exports: [UserHasPermissionDirective]
})
export class UserFeatureCoreModule { }
