import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { PatientLedger, PatientLedgerFilter, PatientTreatmentContract, PatientTreatmentInstallmentPlan, PatientTreatmentInvoiceHistory } from "../store/contract-plan-dashboard/contract-plan-dashboard.state";
import {
    invokePatientTreatmentContractLedgersAPI, invokeSelectedInstallmentPlanInvoice,
    invokeSelectedInstallmentPlanSummary, invokeSelectedPatientTreatmentInstallmentPlan
} from "../store/contract-plan-dashboard/contract-plan-dashboard.action";
import { TRANSACTION_CATEGORIES, lendingType } from "../constant/contract-plan.constant";

@Injectable()

export class TreatmentContractPlanService {

    constructor(private store: Store) { }

    selectTreatmentInstallmentPlan(installmentPlan: PatientTreatmentInstallmentPlan) {
        this.store.dispatch(invokeSelectedPatientTreatmentInstallmentPlan({ patientTreatmentInstallmentPlan: installmentPlan }));
        this.store.dispatch(invokeSelectedInstallmentPlanInvoice({ PatientTreatmentInvoices: installmentPlan.patientTreatmentInvoices }));
        this.store.dispatch(invokeSelectedInstallmentPlanSummary({ installmentPlanSummary: installmentPlan.installmentSummary! }));
    }

    invokePatientTreatmentContractLedgers(practiceId: number, patientId: number, patientTreatmentContractId: number | null
        , practiceInsuranceCompanyId: number | null, ledgerName: string | null) {
        const patientLedgerFilter: PatientLedgerFilter = {
            practiceId: practiceId,
            patientId: patientId,
            patientTreatmentContractId: patientTreatmentContractId,
            practiceInsuranceCompanyId: practiceInsuranceCompanyId,
            ledgerName: ledgerName ?? '',
        }
        this.store.dispatch(invokePatientTreatmentContractLedgersAPI({ patientLedgerFilter: patientLedgerFilter }));
    }

    mapPatientTreatmentInstallmentPlan(contractPlan: PatientTreatmentContract,
        installmentPlan: PatientTreatmentInstallmentPlan, misIndex: number): PatientTreatmentInstallmentPlan {
        const newInstallmentPlan: PatientTreatmentInstallmentPlan = {
            ...installmentPlan,
            patientName: contractPlan.patientName,
            patientFirstName: contractPlan.patientFirstName,
            patientLastName: contractPlan.patientLastName,
            treatmentContractIssuedDate: contractPlan.patientTimeZoneDateOfContractSigned,
            patientFinancialResponsiblePersonId: contractPlan.patientFinancialResponsiblePersonId,
            patientFinancialResponsiblePersonName: installmentPlan.patientFinancialResponsiblePersonName,
            practiceTreatmentOptionName: (contractPlan.isMiscServiceContract) ? "Miscellaneous " + misIndex : contractPlan.practiceTreatmentOptionName,
            practiceTreatmentOptionDescription: contractPlan.practiceTreatmentOptionDescription,
            practiceTreatmentTypeName: contractPlan.practiceTreatmentTypeName,
            practiceTreatmentTypeDescription: contractPlan.practiceTreatmentTypeDescription,
            practiceTreatmentTypeId: contractPlan.practiceTreatmentTypeId,
            practiceTreatmentOptionId: contractPlan.practiceTreatmentOptionId,
            isSelected: false,
            patientTreatmentPlanCost: contractPlan.patientTreatmentPlanCost,
            patientResponsibility: contractPlan.patientResponsibility,
            totalAdditionalCost: contractPlan.totalAdditionalCost,
            totalCourtesies: contractPlan.totalCourtesies,
            totalDiscount: contractPlan.totalDiscount,
            totalInsuranceResponsibility: contractPlan.totalInsuranceResponsibility,
            totalNetworkDiscount: contractPlan.totalNetworkDiscount,
            downPaymentDiscount: contractPlan.downPaymentDiscount,
            totalInterestCharged: contractPlan.totalInterestCharged,
            practiceLocationName: contractPlan.practiceLocationName,
            totalWalletAmount: contractPlan.totalWalletAmount,
            patientWalletAmount: contractPlan.patientWalletAmount,
            isMiscServiceContract: contractPlan.isMiscServiceContract,
            hasWallet:contractPlan.hasWallet,
            locationId: contractPlan.patientLocationId,
            patientTreatmentPlanId: contractPlan.patientTreatmentPlanId,
            walletAmount: installmentPlan.walletAmount 

        };
        return newInstallmentPlan;
    }

    mapPatientLedgerByPatientPaymentTransaction(ledger: PatientLedger[]): PatientLedger[] {
        const patientLedgerCharge = ledger.filter(_ => _.transactionCategory === TRANSACTION_CATEGORIES.CHARGE).map((item) => ({
            ...item,
            principletransactionAmount: (item.lendingType == lendingType.principle) ? item.transactionAmount : 0,
            interestTransactionAmount: (item.lendingType == lendingType.interest) ? item.transactionAmount : 0,
        }));

        const patientAdjustment = ledger.filter(_ => _.transactionCategory === TRANSACTION_CATEGORIES.ADJUSTMENT).map((item) => ({
            ...item,
            principletransactionAmount: (item.lendingType == lendingType.principle) ? item.transactionAmount : 0,
            interestTransactionAmount: (item.lendingType == lendingType.interest) ? item.transactionAmount : 0,
        }));

        let patientLedgerPayment: PatientLedger[] = [];

        const ledgerPayment = ledger.filter(_ => _.transactionCategory === TRANSACTION_CATEGORIES.PAYMENT || _.transactionCategory === TRANSACTION_CATEGORIES.REFUND);

        const groupedData: { [key: number]: PatientLedger } = {};
        for (const item of ledgerPayment) {
            const transactionId = item.patientPaymentTransactionId ?? 0;
            if (!groupedData[transactionId]) {
                groupedData[transactionId] = {
                    ...item,
                    principletransactionAmount: 0,
                    interestTransactionAmount: 0
                };
            }
            if (item.lendingType === lendingType.principle) {
                groupedData[transactionId].principletransactionAmount += item.transactionAmount;
            } else if (item.lendingType === lendingType.interest) {
                groupedData[transactionId].interestTransactionAmount += item.transactionAmount;
            }
            groupedData[transactionId].transactionAmount = groupedData[transactionId].principletransactionAmount + groupedData[transactionId].interestTransactionAmount
            patientLedgerPayment = Object.values(groupedData);
        }

        const mergedLedger = [...patientLedgerCharge, ...patientLedgerPayment, ...patientAdjustment];
        mergedLedger.sort((a, b) => a.patientLedgerId - b.patientLedgerId);
        return mergedLedger;
    }

    mapInvoiceHistoryByPatientPaymentTransaction(invoiceHistory: PatientTreatmentInvoiceHistory[]): PatientTreatmentInvoiceHistory[] {
        let updatedInvoiceHistory: PatientTreatmentInvoiceHistory[] = [];
        const groupedData: { [key: string]: PatientTreatmentInvoiceHistory } = {};
        for (const item of invoiceHistory) {
            const transactionId = item.patientPaymentTransactionId ?? 0;
            const invoiceId = item.patientTreatmentInvoiceId;
            const compoundKey = `${transactionId}-${invoiceId}`;

            if (!groupedData[compoundKey]) {
                groupedData[compoundKey] = {
                    ...item,
                    principletransactionAmount: 0,
                    interestTransactionAmount: 0,
                };
            }
            if (item.lendingType === lendingType.principle) {
                groupedData[compoundKey].principletransactionAmount += item.principal;
            } else if (item.lendingType === lendingType.interest) {
                groupedData[compoundKey].interestTransactionAmount += item.interest;
            }
            groupedData[compoundKey].transactionAmount = groupedData[compoundKey].principletransactionAmount + groupedData[compoundKey].interestTransactionAmount;

            updatedInvoiceHistory = Object.values(groupedData);
        }
        updatedInvoiceHistory.sort((a, b) => a.patientPaymentTransactionId - b.patientPaymentTransactionId);
        return updatedInvoiceHistory;
    }

    getFormattedDate(date: Date): string {
        return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', });
    }

    getPaidStatus(status: string): string {
        switch (status) {
            case 'Past Due':
                return 'Past'
            case 'Upto Date':
                return 'No Due'
            case 'Fully Paid':
                return 'Paid'
            default:
                return status
        }
    }
}