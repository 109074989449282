import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectedPracticeId } from 'src/app/theme/store/menu/menu.actions';
import { AuthService } from '../../user/services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeIdSelectionService {

  constructor(
    private store: Store,
    private authService: AuthService,
  ) {}

  public updateMenuPracticeId() {
    const practiceIdString = this.authService.getPracticeIDFromToken();
        if(isNaN(parseInt(practiceIdString)) === false){
            const practiceId = parseInt(practiceIdString);
            this.store.dispatch(selectedPracticeId({ practiceId}));
        }
  }
}