import { Injectable } from '@angular/core';
import { checkInStatus } from '../constant/patient-live-status-constant';
import { AppSessionStorageService } from 'src/app/features/shared/services/app-session-storage.service';
import { liveLocationStorageKey } from '../constant/patient-live-status-constant';

@Injectable()
export class PatientLiveStatusService {

  constructor(
    private appSessionStorageService: AppSessionStorageService,
  ) { }

  calculateTimeDifference(appointmentTime: string, checkinTime: string): { difference: string, status: string } {
    const checkinTimestamp = new Date(checkinTime).getTime();
    const appointmentTimestamp = new Date(appointmentTime).getTime();

    const timeDifference = appointmentTimestamp - checkinTimestamp;
    const isFuture = checkinTimestamp < appointmentTimestamp;

    const status = isFuture ? checkInStatus.early :
      checkinTimestamp > appointmentTimestamp ? checkInStatus.late : checkInStatus.onTime;

    const hours = Math.floor(Math.abs(timeDifference) / (60 * 60 * 1000));
    const minutes = Math.floor((Math.abs(timeDifference) % (60 * 60 * 1000)) / (60 * 1000));

    const formattedHours = hours > 0 ? `${hours} h ` : '';
    const formattedDifference = `${formattedHours}${minutes} m`;

    return { difference: formattedDifference, status: status };
  }

  getLocationId() {
    const storedLocationId = this.appSessionStorageService.getItem(liveLocationStorageKey);
    return Number(storedLocationId);
  }

  updateLocationIdToStorage(practiceLocationId: number) {
    this.appSessionStorageService.setItem(liveLocationStorageKey, practiceLocationId.toString());
  }

}
