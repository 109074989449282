// Angular Import
import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { GradientConfig } from 'src/app/app-config';
import { Location } from '@angular/common';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationComponent implements OnInit {
  isRtl!: boolean; // rtl type
  isCollapsedMenu = false;
  menuFixedLayout = false; // menu/navbar fixed flag
  boxLayout = false; // box layout flag
  headerColor!: string; // header background color
  headerBackColor!: string;
  gradientConfig!: object;
  darkLayout = false;
  headerFixed = false; // header fixed flag

  // constructor
  constructor(private zone: NgZone, private location: Location) {}

  // life cycle event
  ngOnInit() {
    document.querySelector('.pcoded-navbar')?.classList.add('menu-light');
    if ((this.headerColor = GradientConfig.header_back_color)) {
      document.querySelector('.pcoded-header')?.classList.add(this.headerColor);
    }
    if ((this.darkLayout = GradientConfig.isDarkLayout)) {
      document.querySelector('.pcoded-navbar')?.classList.add('navbar-dark');
      document.querySelector('.pcoded-navbar')?.classList.add('brand-dark');
      document.querySelector('body')?.classList.add('gradient-dark');
      document.querySelector('.pcoded-header')?.classList.add('header-dark');
      document.querySelector('.pcoded-navbar')?.classList.remove('menu-light');
    }
    if ((this.isRtl = GradientConfig.isRtlLayout)) {
      document.querySelector('body')?.classList.add('gradient-rtl');
    }
    if ((this.menuFixedLayout = GradientConfig.nav_fixed_layout)) {
      document.querySelector('.pcoded-navbar')?.classList.remove('menupos-static');
      document.querySelector('.pcoded-navbar')?.classList.add('menupos-fixed');
    }
    if ((this.headerFixed = GradientConfig.header_fixed_layout)) {
      document.querySelector('.pcoded-header')?.classList.add('headerPos-fixed');
    }
    if ((this.boxLayout = GradientConfig.box_layout)) {
      document.querySelector('body')?.classList.add('container');
      document.querySelector('body')?.classList.add('box-layout');
    }
    if ((this.isCollapsedMenu = GradientConfig.collapse_menu)) {
      document.querySelector('.pcoded-navbar')?.classList.add('navbar-collapsed');
    }
  }
}
