import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectedPatientId } from 'src/app/theme/store/menu/menu.actions';

@Injectable({
  providedIn: 'root'
})
export class PatientIdSelectionService {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  public updateMenuPatientId() {
    let currentRoute = this.router.routerState.root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    const id = currentRoute.snapshot.paramMap.get('id');
    const idproperty = currentRoute.snapshot.data['IdProperty'];
    if(id && idproperty === 'PatientId')
    {
      const patientId = parseInt(id);
      this.store.dispatch(selectedPatientId({patientId}));
    }
  }
}