import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PracticeLocation } from '../store/state/practice-location';
import {
	PracticePaymentInformation, PracticePersonalInformation,
	practiceFeaturesInformation
} from '../store/state/practice-info';
import { practiceApiAssert as practiceApiAsset } from '../constant/practice-api-asset';
import { LetterTemplate, PracticeAppointmentType, PracticeCurrentDateTime, PracticeProvider } from '../store/state/practice-core';

@Injectable({
	providedIn: 'root'
})

export class PracticeInfoAPIService {

	constructor(private http: HttpClient) { }

	getPracticeInfo(practiceId: number) {
		const param = new HttpParams().set('practiceId', practiceId);
		return this.http.get<PracticePersonalInformation>(practiceApiAsset.getPracticeById, { params: param });
	}

	updatePracticeInfo(practiceInfo: PracticePersonalInformation) {
		return this.http.post<PracticePersonalInformation>(practiceApiAsset.savePractice, practiceInfo);
	}

	getPracticeFeature(practiceId: number) {
		const param = new HttpParams().set('practiceId', practiceId);
		return this.http.get<practiceFeaturesInformation>(practiceApiAsset.getPracticeFeatureConfig, { params: param });
	}

	updatePracticeFeature(practiceFeature: practiceFeaturesInformation) {
		return this.http.post<practiceFeaturesInformation>(practiceApiAsset.savePracticeFeatureConfig, practiceFeature);
	}

	getPracticePaymentInfo(practiceId: number) {
		const param = new HttpParams().set('practiceId', practiceId);
		return this.http.get<PracticePaymentInformation>(practiceApiAsset.getPracticeFeeConfig, { params: param });
	}

	updatePracticePaymentInfo(practicePaymentInfo: PracticePaymentInformation) {
		return this.http.post<PracticePaymentInformation>(practiceApiAsset.savePracticeFeeConfig, practicePaymentInfo);
	}

	getPracticeLocations(practiceId: number) {
		const param = new HttpParams().set('practiceId', practiceId);
		return this.http.get<PracticeLocation[]>(practiceApiAsset.getPracticeLocations, { params: param });
	}

	updatePracticeLocation(practiceLocation: PracticeLocation) {
		return this.http.post<PracticeLocation[]>(practiceApiAsset.savePracticeLocation, practiceLocation);
	}

	getPracticeProviders() {
		return this.http.get<PracticeProvider[]>(practiceApiAsset.getPracticeProviders);
	}

	getPracticesDetails() {
		return this.http.get<PracticePersonalInformation[]>(practiceApiAsset.getPractices)
	}

	getPracticeLocationsByEmail(email: string) {
		const param = new HttpParams().set('email', email);
		return this.http.get<PracticeLocation[]>(practiceApiAsset.getPracticeLocationsByEmail, { params: param });
	}

	getPracticeAppointmentTypes(practiceId: number) {
		const params = { 'practiceId': practiceId.toString() }
		return this.http.get<PracticeAppointmentType[]>(practiceApiAsset.getPracticeAppointmentType, { params: params });
	}

	getPracticeLetterTemplateNames(practiceId: number) {
		const param = { 'practiceId': practiceId.toString() };
		return this.http.get<LetterTemplate[]>(practiceApiAsset.getPracticeLetterTemplateNames, { params: param });
	}
	getPracticesByEmail(email: string) {
		const param = new HttpParams().set('email', email);
		return this.http.get<PracticePersonalInformation[]>(practiceApiAsset.getPracticesByEmail, { params: param })
	}

	getPracticeCurrentDateTime(practiceId: number) {
		const param = new HttpParams().set('practiceId', practiceId);
		return this.http.get<PracticeCurrentDateTime>(practiceApiAsset.getPracticeCurrentDateTime, { params: param })
	}
}