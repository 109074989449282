import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { practiceFeaturesInformation } from "../../practice/store/state/practice-info";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { selectPracticeFeaturesInfo } from "../../practice/store/selector/practice-application-feature.selector";


@Directive({
    selector: '[applicationFeature]'
})

export class ApplicationFeatureDirective implements OnInit, OnDestroy {
    @Input('applicationFeature') featureKey!: keyof practiceFeaturesInformation;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private store: Store,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }

    ngOnInit(): void {
        const practiceFeature$ = this.store.select(selectPracticeFeaturesInfo);
        this.subscriptions.add(practiceFeature$.subscribe((data: practiceFeaturesInformation) => this.bindFeaturesInformation(data)));
    }

    private bindFeaturesInformation(data: practiceFeaturesInformation): void {
        this.viewContainer.clear();
        const isFeatureEnabled = data[this.featureKey] as boolean;
        if (isFeatureEnabled) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}