import { createReducer, on } from '@ngrx/store';
import { locationGroupSubscribe, locationGroupSubscribeError, locationGroupSubscribeSuccess, locationGroupUnsubscribeError, locationGroupUnsubscribeSuccess,
   practiceGroupSubscribeError,
   practiceGroupUnsubscribeError,
   processMarkAsReadEvent,
   processPhoneCallEvent,
   processReceivedChatEvent,
   processSendChatEvent,
   resetPhoneCallEvent,
   resetSignalrStore,
   scheduleEventReceived, signalRConnectionError, signalRConnectionStopped, signalRConnectionSuccess, signalrCloseErrorAlert, 
   signalrCloseScheduleErrorAlert, startSignalRConnection, startSignalRReconnecting, stopSignalRConnection } from './signalr.action';
import { signalRConnectionStatus, appointmentSignalREvent } from '../../core/constant/signalr.constant';
import { SignalRState } from './signalr.state';

export const initialSignalRState: SignalRState = {
    connectionStatus: 'disconnected',
    connectionUpdatedDate: null,
    scheduleEventState: {
        lastMessageReceivedAt: null,
        scheduleMessage: undefined,
        eventName: '',
        subscribeLocationGuid: '',
        isLocationSubscribed: false,
        isPracticeSubscribed:false
    }
};

export const signalrReducer = createReducer(
    initialSignalRState,
    on(startSignalRConnection, (state): SignalRState => ({
      ...state,
      connectionStatus: signalRConnectionStatus.connecting.name,
    })),
    on(startSignalRReconnecting, (state, { error, date }): SignalRState => ({
      ...state,
      connectionStatus: signalRConnectionStatus.reconnecting.name,
      connectionUpdatedDate:date,
      connectionStateEvent:{
        ...state.connectionStateEvent, errorMessage:error
      }
    })),
    on(signalRConnectionSuccess, (state, {  date }): SignalRState => ({
      ...state,
      connectionStatus: signalRConnectionStatus.connected.name,
      connectionUpdatedDate:date
    })),
    on(signalRConnectionError, (state, { error,date }): SignalRState => ({
      ...state,
      connectionStatus: signalRConnectionStatus.disconnected.name,
      connectionStateEvent: { ...state.connectionStateEvent, showErrorAlert: true, errorMessage: 'Connection failed- '+ error },
      connectionUpdatedDate:date
    })),
    on(stopSignalRConnection, (state): SignalRState => ({
      ...state,
      connectionStatus: signalRConnectionStatus.disconnecting.name,
    })),
    on(signalRConnectionStopped, (state, {date}): SignalRState => ({
      ...state,
      connectionStatus: signalRConnectionStatus.disconnected.name,
      connectionUpdatedDate:date
    })),
    on(signalrCloseErrorAlert, (state): SignalRState => ({
      ...state,
      connectionStateEvent: { ...state.connectionStateEvent, showErrorAlert: false, errorMessage: undefined},
    })),
    on(locationGroupSubscribe, (state): SignalRState => ({
      ...state,
      scheduleEventState: {
        ...state.scheduleEventState,
        subscribeLocationGuid:'',
        subscribeLocation:undefined,
        isLocationSubscribed: false
      },
  })),
    on(locationGroupSubscribeSuccess, (state, { location }): SignalRState => ({
        ...state,
        scheduleEventState: {
          ...state.scheduleEventState,
          subscribeLocationGuid:location.practiceLocationGuid,
          subscribeLocation:location,
          isLocationSubscribed: true
        },
    })),
    on(locationGroupSubscribeError, (state, {error, location }): SignalRState => ({
        ...state,
        scheduleEventState: {
          ...state.scheduleEventState,
          subscribeLocationGuid:location.practiceLocationGuid,
          subscribeLocation:location,
          isLocationSubscribed: false,
          scheduleErrorStateEvent:{...state.scheduleEventState.scheduleErrorStateEvent, showErrorAlert:true, errorMessage: error}
        },
    })),
  on(practiceGroupSubscribeError, (state, { error, practiceGuid }): SignalRState => ({
    ...state,
    scheduleEventState: {
      ...state.scheduleEventState,
      isPracticeSubscribed: false,
      scheduleErrorStateEvent: { ...state.scheduleEventState.scheduleErrorStateEvent, showErrorAlert: true, errorMessage: error }
    },
  })),
    on(locationGroupUnsubscribeSuccess, (state): SignalRState => ({
      ...state,
      scheduleEventState: {
        ...state.scheduleEventState, subscribeLocationGuid:'',subscribeLocation:undefined, isLocationSubscribed: false
      },
  })),
  on(locationGroupUnsubscribeError, (state, {error, locationGuid }): SignalRState => ({
      ...state,
      scheduleEventState: {
        ...state.scheduleEventState,
        subscribeLocationGuid:locationGuid,
        scheduleErrorStateEvent:{...state.scheduleEventState.scheduleErrorStateEvent, showErrorAlert:true, errorMessage: error}
      },
  })),
  on(practiceGroupUnsubscribeError, (state, { error, practiceGuid }): SignalRState => ({
    ...state,
    scheduleEventState: {
      ...state.scheduleEventState,
      scheduleErrorStateEvent: { ...state.scheduleEventState.scheduleErrorStateEvent, showErrorAlert: true, errorMessage: error }
    },
  })),
    on(signalrCloseScheduleErrorAlert, (state): SignalRState => ({
      ...state,
      scheduleEventState: {
        ...state.scheduleEventState,
      scheduleErrorStateEvent:{...state.scheduleEventState.scheduleErrorStateEvent, showErrorAlert:false, errorMessage: undefined}
      }
    })),
    on(scheduleEventReceived, (state, { model, date }): SignalRState => ({
      ...state,
      scheduleEventState: {
        ...state.scheduleEventState,
        scheduleMessage: model,
        eventName: appointmentSignalREvent,
        lastMessageReceivedAt: date,
      },
    })),
    on(scheduleEventReceived, (state, { model, date }): SignalRState => ({
      ...state,
      scheduleEventState: {
        ...state.scheduleEventState,
        scheduleMessage: model,
        eventName: appointmentSignalREvent,
        lastMessageReceivedAt: date,
      },
    })),
    on(processPhoneCallEvent, (state, { event, callState }): SignalRState => ({
      ...state,
      callEventState: {
        event: event,
        phoneCallEventState: callState,
      },
    })),
    on(resetPhoneCallEvent, (state): SignalRState => ({
      ...state,
      callEventState: {
        event: '',
        phoneCallEventState: initialSignalRState.callEventState?.phoneCallEventState
      }
    })),
    on(processReceivedChatEvent, (state, { event, receivedChatState }): SignalRState => ({
      ...state,
      chatEventState: {
        event: event,
        PatientChatEventState: receivedChatState,
      },
    })),
    on(processSendChatEvent, (state, { event, receivedChatState }): SignalRState => ({
      ...state,
      chatEventState: {
        event: event,
        PatientChatEventState: receivedChatState,
      },
    })),
    on(processMarkAsReadEvent, (state, { event, receivedChatState }): SignalRState => ({
      ...state,
      chatEventState: {
        event: event,
        PatientChatEventState: receivedChatState,
      },
    })),
    on(resetSignalrStore, () => initialSignalRState)
  );