import { environment } from "src/environments/environment";

const PRACTICE_API_BASE_URL = environment.application.api_base_practice_url;

function practiceApiUrl(endpoint: string) {
    return `${PRACTICE_API_BASE_URL}${endpoint}`;
}

export const practiceApiAssert = {
    getPracticeById: practiceApiUrl("Practice/GetPracticeById"),
    savePractice: practiceApiUrl("Practice/SavePractice"),
    getPracticeFeatureConfig: practiceApiUrl("Practice/GetPracticeFeatureConfiguration"),
    savePracticeFeatureConfig: practiceApiUrl("Practice/SavePracticeFeatureConfiguration"),
    getPracticeFeeConfig: practiceApiUrl("Practice/GetPracticeFeeConfiguration"),
    savePracticeFeeConfig: practiceApiUrl("Practice/SavePracticeFeeConfiguration"),
    getPracticeLocations: practiceApiUrl("PracticeLocation/GetPracticeLocations"),
    savePracticeLocation: practiceApiUrl("PracticeLocation/SavePracticeLocation"),
    getPractices: practiceApiUrl("Practice/GetPractices"),
    getPracticeProviders: practiceApiUrl("Provider/GetProviders"),
    getPracticeLocationsByEmail: practiceApiUrl("Practice/GetPracticeLocationsByEmail"),
    getRoles: practiceApiUrl("PracticeRole/GetRoles"),
    getPracticeAppointmentType: practiceApiUrl("Practice/GetPracticeAppointmentTypes"),
    getPracticeLetterTemplateNames: practiceApiUrl("Practice/GetPracticeLetterTemplateNames"),
    getPracticesByEmail:practiceApiUrl("Practice/GetPracticesByEmail"),
    getPracticeCurrentDateTime: practiceApiUrl("Practice/GetPracticeCurrentDateTime"),
}