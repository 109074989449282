import { Injectable } from '@angular/core';
import { KendoGridPdfMargin } from '../types/kendo-grid-pdf-margin ';

@Injectable()

export class KendoControlService implements KendoGridPdfMargin {
    top: string;
    left: string;
    right: string;
    bottom: string;

    constructor() {
        this.top = '9cm';
        this.left = '1cm';
        this.right = '1cm';
        this.bottom = '2cm';
    }

    getDefaultMargins(): KendoGridPdfMargin {
        return {
            top: this.top,
            left: this.left,
            right: this.right,
            bottom: this.bottom,
        };
    }
}