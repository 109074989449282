import { Injectable } from "@angular/core";
import { patientDashboardNavigateAsset, NAVIGATE_URL_CONSTANTS } from "../../patientdashboard/constant/patient-dashboard-api-asset";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class NavigateURLService {

    constructor(private router: Router) {

    }

    private navigateToURL(url: string) {
        this.router.navigateByUrl(url);
    }

    private navigateToNewTab(url: string) {
        const newurl = this.router.serializeUrl(this.router.createUrlTree([url]));
        window.open(newurl, '_blank');
    }

    private getSummaryURL(patientId: number, summaryType: string): string {
        const newTabUrl = `/${patientDashboardNavigateAsset.goToPatientSummary}/${patientId}/${summaryType}/${patientId}`;
        return newTabUrl;
    }

    getPatientSummaryURL(patientId: number): string {
        return this.getSummaryURL(patientId, NAVIGATE_URL_CONSTANTS.PATIENT_SUMMARY);
    }

    getTreamentCardSummaryURL(patientId: number): string {
        return this.getSummaryURL(patientId, NAVIGATE_URL_CONSTANTS.TX_CARD_SUMMARY);
    }

    getImageSummarySummaryURL(patientId: number): string {
        return this.getSummaryURL(patientId, NAVIGATE_URL_CONSTANTS.IMAGE_SUMMARY);
    }

    getContractSummaryURL(patientId: number): string {
        return this.getSummaryURL(patientId, NAVIGATE_URL_CONSTANTS.CONTRACT_SUMMARY);
    }

    navigateToPatientSummary(patientId: number) {
        const newTabUrl = this.getPatientSummaryURL(patientId);
        this.navigateToNewTab(newTabUrl);
    }

    navigateToTreatmentCardSummary(patientId: number) {
        const newTabUrl = this.getTreamentCardSummaryURL(patientId);
        this.navigateToNewTab(newTabUrl);
    }

    navigateToImageSummary(patientId: number) {
        const newTabUrl = this.getImageSummarySummaryURL(patientId);
        this.navigateToNewTab(newTabUrl);
    }

    navigateToContractSummary(patientId: number) {
        const newTabUrl = this.getContractSummaryURL(patientId);
        this.navigateToNewTab(newTabUrl);
    }

    getLoginURL() {
        const currentDomain = window.location.origin;
        return `${currentDomain}/${'login'}`;
    }

    getBaseUrl() {
        const currentDomain = window.location.origin;
        return currentDomain;
    }

    getPaymentWidgetURL(patientid:number,treatmentplanId:number) {
        const currentDomain = window.location.origin;
        return `${currentDomain}/${patientDashboardNavigateAsset.patientPaymentPlanWidet}/${patientid}/${treatmentplanId}`;
    }

    getPatientLoginPatientSummaryURL(patientId: number) {
        const newTabUrl = [NAVIGATE_URL_CONSTANTS.PATIENT_SUMMARY, patientId]
        return newTabUrl;
    }
}