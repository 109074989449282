import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../../../features/user/services/authentication/auth.service';
import { switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();

    if (!authToken) return next.handle(req.clone({}));

    const expirationTime = this.getExpirationTime();
    const currentTime = Math.floor(Date.now() / 1000);
    const refreshThreshold = environment.application.refreshThreshold;
    const isRefreshToken = environment.application.isRefreshToken;
    if (isRefreshToken == true && expirationTime &&
      expirationTime.getTime() / 1000 - currentTime <= refreshThreshold
      && !this.isRefreshTokenRequest(req)) {
      return this.authService.refreshToken().pipe(
        switchMap((response) => {
          const token = response?.accessToken ?? "";
          const modifiedRequest = this.cloneRequestWithToken(req, token);
          return next.handle(modifiedRequest);
        }))
    }
    else {
      const modifiedRequest = this.cloneRequestWithToken(req, authToken);
      return next.handle(modifiedRequest);
    }
  }

 
  private cloneRequestWithToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const practiceId = this.authService.getCurrentPracticeId();
    const practiceGuid = this.authService.getCurrentPracticeGuid()

    if (practiceId && practiceGuid) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'PracticeId': practiceId,
          'PracticeGuid': practiceGuid
        }
      });
    }
    else {
      return request.clone({
        setHeaders: { Authorization: `Bearer ${token}` }
      });
    }
  }

  private isRefreshTokenRequest(request: HttpRequest<any>): boolean {
    return request.url.includes('/RefreshToken');
  }

  private getExpirationTime(): Date | null {

    const token: any = this.authService.getTokenContext();
    const expirationTime = token["exp"];
    return expirationTime ? new Date(parseInt(expirationTime, 10) * 1000) : null;
  }
}

