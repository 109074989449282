//angular import
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//project import
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedFeatureModule } from '../../shared/shared-feature.module';
import { ProviderDropdownComponent } from '../component/practice-core/provider-dropdown.component';
import { practiceCoreReducer } from '../store/reducer/practice-core.reducer';
import { PracticeCoreEffect } from '../store/effect/practice-core.effect';
import { LocationDropdownComponent } from '../component/practice-core/location-dropdown.component';
import { PracticeLogoComponent } from '../component/practice-core/practice-logo.component';
import { DocumentFeatureModule } from '../../document/document-feature.module';
import { AppointmentTypeDropdownComponent } from '../component/practice-core/appointment-type-dropdown.component';
import { LetterTemplateDropdownComponent } from '../component/practice-core/letter-template-dropdown.component';


@NgModule({
  declarations: [
    ProviderDropdownComponent,
    LocationDropdownComponent,
    PracticeLogoComponent,
    AppointmentTypeDropdownComponent,
    LetterTemplateDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedFeatureModule,
    DocumentFeatureModule,

    StoreModule.forFeature('practiceCoreStore', practiceCoreReducer),
    EffectsModule.forFeature([PracticeCoreEffect]),
  ],
  exports:[
    ProviderDropdownComponent,
    LocationDropdownComponent,
    PracticeLogoComponent,
    AppointmentTypeDropdownComponent,
    LetterTemplateDropdownComponent
  ]
})
export class PracticeCoreModule {}
