<!-- Print Ledger Table Section-->
<table class="print_table ledger_print_table">
    <thead>
        <tr>
            <th>DATE</th>
            <th>DESCRIPTION</th>
            <th>METHOD</th>
            <th>CHARGE/<br>ADJUSTMENT</th>
            <th>PAYMENT</th>
            <th>PRINCIPAL</th>
            <th>INTEREST</th>
            <th>BALANCE</th>
        </tr>
        <tr class="spacer-row">
            <td class="border-none"></td>
        </tr>
    </thead>
    <ng-container *ngFor="let ledger of LedgerDetails; let i = index">
        <ng-container *ngIf="(ledger.transactionAmount>0) && ledger.transactionDescription != creditDebitPlan.creditPaymentPlan 
            && ledger.transactionDescription != creditDebitPlan.debitPaymentPlan">
            <tbody>
                    <tr style="page-break-inside: avoid;">
                        <td>{{ledger.transactionDate | dateFormat}}</td>
                        <td>
                            <ng-container
                                *ngIf="(!ledger.practiceInsuranceCompanyId || selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId)">
                                {{ledger.transactionDescription}}
                            </ng-container>
                            <ng-container
                                *ngIf="(ledger.practiceInsuranceCompanyId) && !selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId">
                                {{ledger.practiceInsuranceCompanyName}}
                            </ng-container>
                        </td>
                        <td>
                            {{ledger.paymentMethod == 'CreditOrDebitCard' ?
                            'Card': ledger.paymentMethod }}
                        </td>
                        <td>
                            <ng-container
                                *ngIf="ledger.transactionCategory==='Charge' || ledger.transactionCategory==='Adjustment'">
                                <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                    <span
                                        [ngClass]="ledger.transactionCategory==='Adjustment' ? 'text-pink' : '' ">${{ledger.transactionAmount
                                        | number:'1.2-2'}}</span>
                                </ng-container>
                                <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                    <span
                                        [ngClass]="ledger.transactionCategory==='Adjustment' ? 'text-warning' : '' ">(${{ledger.transactionAmount
                                        | number:'1.2-2'}})</span>
                                </ng-container>
                            </ng-container>
                            <ng-container
                                *ngIf="ledger.transactionCategory!='Charge' && ledger.transactionCategory!='Adjustment'">
                                $0.00
                            </ng-container>
                        </td>
                        <td>
                            <ng-container
                                *ngIf="(ledger.transactionCategory=='Payment' || ledger.transactionCategory=='Refund')">
                                <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                    <span class="text-purple">${{ledger.transactionAmount | number:'1.2-2'}}</span>
                                </ng-container>
                                <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                    <span class="text-success">(${{ledger.transactionAmount |
                                        number:'1.2-2'}})</span>
                                </ng-container>
                            </ng-container>
                            <ng-container
                                *ngIf="(ledger.transactionCategory!='Payment' && ledger.transactionCategory!='Refund')">
                                $0.00</ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="ledger.principletransactionAmount!=0">
                                <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                    ${{ledger.principletransactionAmount | number:'1.2-2'}}
                                </ng-container>
                                <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                    (${{ledger.principletransactionAmount | number:'1.2-2'}})
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="ledger.principletransactionAmount===0">
                                $0.00</ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="ledger.interestTransactionAmount!=0">
                                <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                    ${{ledger.interestTransactionAmount | number:'1.2-2'}}
                                </ng-container>
                                <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                    (${{ledger.interestTransactionAmount | number:'1.2-2'}})
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="ledger.interestTransactionAmount===0">$0.00
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="ledger.balance >=0">
                                ${{ledger.balance | number:'1.2-2'}}
                            </ng-container>
                            <ng-container *ngIf="ledger.balance <0">
                                (${{-ledger.balance | number:'1.2-2'}})
                            </ng-container>
                        </td>
                    </tr>
                    <tr class="subrow" style="page-break-inside: avoid;">
                        <td [colSpan]="8">
                            <div class="d-flex flex-column gap-2">
                                <div class="d-flex flex-row gap-2 justify-content-between">
                                    <div><label class="f-w-600 me-1">USER INFO</label> {{ledger.createdBy}}</div>
                                    <div><label class="f-w-600 me-1">CATEGORY </label>
                                        {{ledger.transactionCategory}}</div>
                                    <div>
                                        <ng-container *ngIf="ledger.transactionCategory==='Payment'">
                                            <label class="f-w-600 me-1">CONFIRMATION #</label>
                                            <ng-container *ngIf="(ledger.transactionId)">
                                                ...{{ ledger.transactionId.toString().slice(-5) }}</ng-container>
                                            <ng-container *ngIf="(!ledger.transactionId)">
                                                {{ ledger.patientPaymentTransactionId }}</ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="d-flex flex-row">
                                    <div><label class="f-w-600 me-1">NOTES</label> {{ledger.notes}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="spacer-row" style="page-break-inside: avoid;">
                        <td class="border-none"></td>
                    </tr>
            </tbody>
        </ng-container>
    </ng-container>
    <tfoot>
        <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td>
                <ng-container *ngIf="this.totalCharge >=0 && !this.isWalletLedger">
                    ${{this.totalCharge | number:'1.2-2'}}
                </ng-container>
                <ng-container *ngIf="this.totalCharge <0 && !this.isWalletLedger">
                    (${{this.totalCharge | number:'1.2-2'}})
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="!this.isWalletLedger">
                    (${{this.totalPaymentCollected | number:'1.2-2'}})
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="this.totalPrincipal >=0 && !this.isWalletLedger">
                    ${{this.totalPrincipal | number:'1.2-2'}}
                </ng-container>
                <ng-container *ngIf="this.totalPrincipal <0 && !this.isWalletLedger">
                    (${{this.totalPrincipal | number:'1.2-2'}})
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="this.totalInterest >=0 && !this.isWalletLedger">
                    ${{this.totalInterest | number:'1.2-2'}}
                </ng-container>
                <ng-container *ngIf="this.totalInterest <0 && !this.isWalletLedger">
                    (${{this.totalInterest | number:'1.2-2'}})
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="this.totalInterest >=0">
                    ${{this.totalBalance | number:'1.2-2'}}
                </ng-container>
                <ng-container *ngIf="this.totalInterest <0">
                    (${{this.totalBalance | number:'1.2-2'}})
                </ng-container>
            </td>
        </tr>
    </tfoot>
</table>