import { environment } from "src/environments/environment";

const PRACTICE_API_BASE_URL = environment.application.api_base_practice_url;
const SCHEDULE_API_BASE_URL = environment.application.api_base_schedule_url;
const SIGNALR_API_BASE_URL = environment.application.api_base_signalr_api_url;
const PATIENT_API_BASE_URL = environment.application.api_base_patient_url;

function signalrApiUrl(endpoint: string) {
    return `${SIGNALR_API_BASE_URL}${endpoint}`;
}

function practiceApiUrl(endpoint: string) {
    return `${PRACTICE_API_BASE_URL}${endpoint}`;
}

function scheduleApiUrl(endpoint: string) {
    return `${SCHEDULE_API_BASE_URL}${endpoint}`;
}

function patientApiUrl(endpoint: string) {
    return `${PATIENT_API_BASE_URL}${endpoint}`;
}

export const patientLiveStatusApiAsset = {
    getPatientAppointmentsByDate: scheduleApiUrl("Schedule/GetPatientAppointmentsByDate"),
    savePatientAppointment: signalrApiUrl("Notification/SavePatientAppointment"),
    getPracticeChairConfigByLocationId: practiceApiUrl("Practice/GetPracticeChairConfigByLocationId"),
    getPracticeLocations: practiceApiUrl("PracticeLocation/GetPracticeLocations"),
    getPatientAllergies: patientApiUrl("Patient/GetPatientAllergies"),
}