export const letterTemplateConstants = {
  letterTemplateCreateSuccess: 'Template created successfully.',
  letterTemplateUpdateSuccess: 'Template updated successfully.',
  letterTemplateDeactivateWarning: 'We would like to confirm if you want to deactivate this template. Are you sure?',
  letterTemplateActivateSuccess: 'Template activated successfully.',
  letterTemplateDeactivateSuccess: 'Template deactivated successfully.',
  letterTemplateActivateWarning: 'We would like to confirm if you want to activate this template. Are you sure?',
  remainderTemplateUpdateWarning: 'We would like to confirm if you want to update this template. Are you sure?',
  letterTemplateExistsWarning: 'Sorry, This template name is already exists.',
  letterTemplateExistsAndInActiveWarning: 'Sorry, This template name is already exists and deactivated.',
  remainderTemplateAndIntervalExistWarning: 'Sorry, a template with the same category and schedule interval already exists.',
  remainderTemplateAndIntervalExistsAndInActiveWarning: 'Sorry, a template with the same category and schedule interval already exists and deactivated.',
  remainderTemplateExistWarning: 'Sorry, a template with the same category already exists.',
  remainderTemplateExistsAndInActiveWarning: 'Sorry, a template with the same category already exists and deactivated.',
  mediaLibraryCreateSuccess: "Library created successfully.",
  mediaLibraryUpdateSuccess: "Library updated successfully.",
  mediaLibraryDeactivateWarning: "We would like to confirm if you want to deactivate this library. Are you sure?",
  mediaLibraryDeactivatSuccess: 'Library deactivated successfully.',
  mediaLibraryActivateWarning: 'We would like to confirm if you want to activate this library. Are you sure?',
  mediaLibraryActivateSuccess: 'Library activated successfully.',
  mediaFileAddedSuccess: 'Media File added successfully.',
  mediaFileUpdateSuccess: 'Media File updated successfully.',
  mediaFileDeleteWarning: "We would like to confirm if you want to delete this media file. Are you sure?",
  mediaFileDeleteSuccess: 'Media File deleted successfully.'
}

export enum templateCategory {
  LetterTemplate = "Letter Template",
  CustomTemplate = "Custom Template",
  ReminderTemplate = "Reminder Template",
  Miscellaneous = "Miscellaneous",
  PatientFollowup = "Patient Followup",
  InCompletePatientForm = "Incomplete Patient Form",
  UserWelcomeEmail = "User Welcome Email",
  BirthdayReminder = "Birthday Reminder",
  SignAtHomeTemplate = "Sign at Home Template"
}

export const remainderType = {
  Before: "Before",
  After: "After",
  None: " None "
}

export const skipTemplateCategory: string[] = [
  templateCategory.Miscellaneous,
  templateCategory.PatientFollowup
]

export const transactionCategories: string[] = [
  ...skipTemplateCategory.filter(category => category !== templateCategory.InCompletePatientForm),
  templateCategory.LetterTemplate,
  templateCategory.CustomTemplate
]

export const allTemplateCategory = {
  ...templateCategory,
  appointmentReminder: 'Appointment Reminder',
  appointmentConfirmation: 'Appointment Confirmation',
  unConfirmedAppointment: 'UnConfirmed Appointment',
  paymentReminder: 'Payment Reminder',
  pastDueReminder: 'Past Due Reminder',
  processedPaymentNotification: 'Processed Payment Notification',
  failedPaymentNotification: 'Failed Payment Notification',
}

export const templateCategories = [
  {
    templateCategoryId: 1,
    templateCategoryName: templateCategory.LetterTemplate,
  },
  {
    templateCategoryId: 10,
    templateCategoryName: templateCategory.CustomTemplate,
  },
  {
    templateCategoryId: 11,
    templateCategoryName: templateCategory.InCompletePatientForm,
  },
  {
    templateCategoryId: 12,
    templateCategoryName: templateCategory.UserWelcomeEmail,
  }
];

export const mediaLibraryCategoryImage = [
  {type: 'Audio', imageSrc: 'assets/images/svg/audio-player.svg'} ,
  {type: 'Document', imageSrc: 'assets/images/svg/file.svg'} ,
  {type: 'Image', imageSrc: 'assets/images/svg/payment-imaging.svg'}, 
  {type: 'Video', imageSrc: 'assets/images/svg/video-player.svg'} 
];
export const defaultImagesrc = "assets/images/svg/file.svg";

export const mediaLibraryCategory={
  Audio: 'Audio',
  Document: 'Document',
  Image: 'Image',
  Video: 'Video'
}