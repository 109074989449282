import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectOnCallInprogressphoneNumber } from '../../../store/phone-store/phone-call.selector';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';

declare const IVLConnect: any;
declare const IVLDisconnect: any;
declare const sendTone: any;
declare const Transfer: any;


@Component({
  selector: 'app-call-progress-dialpad',
  templateUrl: './call-progress-dialpad.component.html',
  styleUrls: ['./call-progress-dialpad.component.scss']
})
export class CallProgressDialpadComponent extends BaseDestroyComponent  implements OnInit {
  @Input() public phoneNumber = '';
  @Input() public bCallInProgress!: boolean;
  @Output() public oCallClicked: EventEmitter<string> = new EventEmitter<string>();

  public dialedNumbers = '';
  onCallInprogressphoneNumber = '';

  selectOnCallInprogressphoneNumber$ = this.store.select(selectOnCallInprogressphoneNumber);

  constructor(private store: Store) { super(); }

  ngOnInit(): void {
    this.subscriptions$.add(this.selectOnCallInprogressphoneNumber$.subscribe((data: string) =>
      this.onCallInprogressphoneNumber = data
    ));
    if (this.bCallInProgress)
      this.phoneNumber = '';
  }

  public addNumber(sNumber: string): void {
    sendTone(sNumber);
    this.dialedNumbers += sNumber;
  }

  public clear(): void {
    if (this.dialedNumbers.length > 0) {
      this.dialedNumbers = this.dialedNumbers.substring(0, this.dialedNumbers.length - 1);
    }
  }

  @HostListener('document:EndOfCandidates', ['$event'])
  fnHandleWebRtcEvent(event: any) {
    this.oCallClicked.emit(!this.bCallInProgress ? this.phoneNumber : '');
  }

  public makeCall(): void {
    const model: any = {};
    model.NumberToDial = this.phoneNumber;
    if (!this.bCallInProgress) {
      Transfer(this.dialedNumbers);
      return;
    }

    if (this.bCallInProgress) {
      try {
        if (!this.onCallInprogressphoneNumber)
          IVLDisconnect();
      }
      catch (e) {
        console.log('Error in disconnecting call ' + e);
      }
      this.oCallClicked.emit('');
    }
  }
}
