<div class="row">
    <form *ngIf="isActiveUpload">
        <div class="fileupload" *ngIf="!isWebcamUpload && !isAcquireImageUpload" >
            <div class="drop-area" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
                <label class="d-flex justify-content-center align-items-center flex-column p-3 image-upload-section">
                    <img src="assets/images/dragupload.png">
                    <div class="mt-3 text-center">
                        <span class="btn btn-light-primary"> Choose file</span><br>
                        <h6 class="mt-3 h6 f-w-300"> or drag file in here</h6>
                    </div>
                    <input type="file" class="btn btn-light-primary d-none" [accept]="getAcceptedFileTypes()"
                        (change)="onFileSelected($event)" [multiple]="isAllowMultiple" />
                </label>
            </div>
            <div class="fileupload-validation">
                <div *ngIf="fileDraggingValidation">
                    <div class="error-message">{{fileDraggingValidation}}</div>
                </div>
                <div *ngIf="exceedFileLimit">
                    <div class="error-message">You are allowed to upload a maximum of {{this.maxFiles}} files at a given
                        time. If you like to upload more than {{this.maxFiles}}, you can close this popup and upload the next
                        ten files.
                    </div>
                </div>
                <div *ngFor="let error of fileExistErrors">
                    <div class="error-message">{{error.fileName}} - The file already exists.
                    </div>
                </div>
                <div *ngFor="let error of fileTypeErrors">
                    <div class="error-message">{{error.fileName}} - Invalid file type. Allowed types:
                        {{getAcceptedFileTypes()}}
                    </div>
                </div>
                <div *ngFor="let error of fileSizeErrors">
                    <div class="error-message">{{error.fileName}} - File size is {{error.fileSize}} this exceeds the
                        limit.
                        Maximum size: {{maxFileSize}} MB</div>
                </div>
            </div>
        </div>
        <div class="webcam" *ngIf="isWebcamUpload">
            <app-webcam (pictureTaken)="handleWebCamImage($event)"></app-webcam>
        </div>
        <div class="webcam" *ngIf="isAcquireImageUpload">
            <div class="drop-area">
                <label class="d-flex justify-content-center align-items-center flex-column p-3 image-upload-section">
                    <img src="assets/images/dragupload.png"/>
                    <app-dynamsoft (acquiredImage)="handleWebCamImage($event)"></app-dynamsoft>
                </label>
            </div>
        </div>
    </form>

    <div class="px-3 my-2">
        <div class="p-3 mt-2 rounded-3 bg-light" *ngFor="let item of documentUploadState.documents">
            <div class="d-flex">
                <div class="d-flex gap-2 col-md-11 col-10">
                    <div>
                        <i class="fa fa-regular f-32 text-primary"
                            [ngClass]="getFileTypeClass(item.document?.type??'')"></i>
                    </div>
                    <div>
                        <h6 class="h6 mb-1 text-dark f-w-500"> {{ item.document?.name }}</h6>
                        <h6 class="h6 f-12 text-dark f-w-200">{{ getFileSize(item.document?.size??0)}}</h6>
                    </div>
                </div>
                <div class="col-md-1 col-2">
                    <div class="d-flex flex-column">
                        <i class="feather icon-x text-dark cursor-pointer f-22 text-end" *ngIf="isActiveUpload"
                            (click)="removeUploadFile(item)"></i>
                        <h6 class="h6 f-12 text-dark f-w-200 text-end">{{item.progress===100 && !item.completed? 99 : item.progress
                            }} %</h6>
                    </div>
                </div>
            </div>
            <div class="d-flex bg-light-dark" style="border-radius: 10px;">
                <div class="border border-primary bg-white w-100" style="border-radius: 10px;">
                    <div class="border bg-primary p-2" style="border-radius: 10px;"
                        [style.width.%]="item.progress===100 && !item.completed? 99 : item.progress">
                    </div>
                </div>
            </div>
            <div *ngIf="item.error">
                <div class="error-message">Upload Failed- {{item.error}} </div>
            </div>
        </div>
    </div>
    <div class="my-2" *ngIf="isAllowMultiple">
        <div *ngIf="submitValidation">
            <div class="error-message">{{submitValidation}}</div>
        </div>
        <button class="btn btn-light-primary float-end" type="button"
            (click)="submitUploadFiles()">{{submitButtonName}}</button>
    </div>
</div>