import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-skeleton',
  template: `
            <div class="skeleton m-0 p-0">
                <div class="row" >
                    <div class="col-12">
                        <div class="line m-b-20" style="height:55px;width:100%">
                            <div class="row" style="padding:10px">
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <div class="row m-0" *ngFor="let count of repeatHtmlElement(rows)">
                    <div class="col-2">
                        <div class="line m-b-20" style="height:35px;width:100%"></div>
                    </div>
                    <div class="col-2">
                        <div class="line m-b-20" style="height:35px;width:100%"></div>
                    </div>
                    <div class="col-2">
                        <div class="line m-b-20" style="height:35px;width:100%"></div>
                    </div>
                    <div class="col-2">
                        <div class="line m-b-20" style="height:35px;width:100%"></div>
                    </div>
                    <div class="col-2">
                        <div class="line m-b-20" style="height:35px;width:100%"></div>
                    </div>
                    <div class="col-2">
                        <div class="line m-b-20" style="height:35px;width:100%"></div>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-12">
                        <div class="line m-b-20" style="height:55px;width:100%">
                            <div class="row" style="padding:10px">
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            <div class="col-6">
                            </div>
                            <div class="col-2">
                                <div class="line m-b-20" style="height:35px;width:100%"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          `,
})
export class TableSkeletonComponent {
    @Input() rows: number | undefined;

    repeatHtmlElement(count: number | undefined): Array<number> {
      return Array(count);
    }
}
