import { createReducer, on } from '@ngrx/store';
import { MenuNavigation } from './menu.state';
import { getMenuItemsSuccess, updateMenuId, selectedPracticeId, selectedPatientId, resetMenuState,  updateSelectedSubMenuItem } from './menu.actions';

const initialState: MenuNavigation = {
  menuItems: [],
  selectedMenuId: 1,
  selectedSubmenuId: 0,
  selectedPracticeId: 0,
  selectedPatientId: 0,
  selectedSubMenuItem: {
    id: 0,
    title: '',
    type: 'item',
    translate: '',
    icon: '',
    hidden: false,
    url: '',
    classes: '',
    exactMatch: false,
    external: false,
    target: false,
    breadcrumbs: false,
    badge: {
      title: '',
      type: '',
    },
    children: undefined
  }
};

export const menuReducer = createReducer(
  initialState,
  on(getMenuItemsSuccess, (state, { payload }) : MenuNavigation => ({
    ...state,
    menuItems: payload,
  })),
  on(updateMenuId, (state, { menuId: menuId }): MenuNavigation  => ({
    ...state,
    selectedMenuId: menuId,
  })),
  on(selectedPracticeId, (state, { practiceId }) : MenuNavigation => ({
    ...state,
    selectedPracticeId: practiceId,
  })),
  on(selectedPatientId, (state, { patientId }) : MenuNavigation => ({
    ...state,
    selectedPatientId: patientId,
  })),
  on(resetMenuState, () : MenuNavigation => (initialState)),
  on(updateSelectedSubMenuItem, (state, { menuItem: menuItem } ) : MenuNavigation => ({
    ...state,
    selectedSubMenuItem: menuItem
  })),
  
);