import { Component, EventEmitter, Input, Output, forwardRef,TemplateRef } from '@angular/core';
import {
    AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors,
    Validator
} from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { SelectBoxItem } from '../../../types/select-box-item';

@Component({
    selector: 'app-multiselect-custom-control',
    template: `
    <div class="form-group" [class.has-error]="hasError()">
        <label *ngIf="label">{{ label }}
            <span *ngIf="required" class="required_field ms-1">*</span>
        </label>
      <div class="multi-select-box" [ngClass]="{'card-design':cardDesign}">
        <ng-template #defaultTemplate let-item="item">
          <div [class.selected]="isSelected(item)"
              [class.inactive]="!item.active"
              [class.inactive]="readonly"
              (click)="onItemClick(item)"
              class="selectable-box">
            <!-- Icon -->
            <img *ngIf="item.imageSrc" [src]="item.imageSrc" class="item-icon" alt="{{item.value}} icon">
            <i *ngIf="item.icon" [class]="item.icon" class="item-icon"></i>
            
            <!-- Value -->
            {{ item.value }}
          </div>
        </ng-template>
        <ng-container *ngFor="let item of items">
          <ng-container *ngTemplateOutlet="itemTemplate || defaultTemplate; context: { item: item }"></ng-container>
        </ng-container>
        <ng-container *ngIf="hasError()">
            <app-validation-error [errors]="control?.errors" [fieldName]="errorMessage ? errorMessage :  !label ? placeholder : label">
            </app-validation-error>
        </ng-container>
      </div>
    </div>
    `,
  styleUrls: ['./multiselect-custom-control.component.scss'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MultiSelectCustomControlComponent),
        multi: true,
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => MultiSelectCustomControlComponent),
        multi: true,
      },
    ]
  })
  export class MultiSelectCustomControlComponent implements ControlValueAccessor, Validator  {
  
    @Input() items: SelectBoxItem[] = [];
    @Input() itemTemplate: TemplateRef<any> | undefined;
    @Input() multiple: boolean = false;
    @Input() required: boolean = false;
    @Input() label!: string;
    @Input() errorMessage!: string;
    @Input() placeholder?: string;
    @Input() disabled?: boolean | false;
    @Input() readonly: boolean = false;
    @Output() itemSelected = new EventEmitter<SelectBoxItem>();
    @Output() itemUnselected = new EventEmitter<SelectBoxItem>();
    @Input() cardDesign: boolean = false;
  
    selectedItems: SelectBoxItem[] = [];
    control: AbstractControl | undefined;
    
    constructor() {
        this.disabled = false;
    }

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        this.control = control;
      if (this.required && this.selectedItems.length === 0) {
        return { required: true };
      }
      return null;
    }
  
    onChange = (value: any) => {};
    onTouched = () => {};
  
    writeValue(value: any): void {
      this.selectedItems = value ? value : [];
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onTouched = fn;
    }
    registerOnChange(fn: any): void {
      this.onChange = fn;
    }
  
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
  
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
  
    onItemClick(item: SelectBoxItem): void {
      if (!item.active || this.readonly) {
        return;
      }
  
      if (this.multiple) {
        const index = this.selectedItems.findIndex(selected => selected.id === item.id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
          this.itemUnselected.emit(item);
        } else {
          this.selectedItems.push(item);
          this.itemSelected.emit(item);
        }
      } else {
        const prevSelected = this.selectedItems[0];
        if (prevSelected?.id === item.id) {
          this.selectedItems = [];
          this.itemUnselected.emit(item);
        } else {
          this.selectedItems = [item];
          if (prevSelected) {
            this.itemUnselected.emit(prevSelected);
          }
          this.itemSelected.emit(item);
        }
      }
  
      this.onChange(this.selectedItems);
      this.onTouched();
    }
  
    isSelected(item: SelectBoxItem): boolean {
      return this.selectedItems.some(selected => selected.id === item.id);
    }
  
    isImageIcon(icon: string): boolean {
      return icon.endsWith('.png') || icon.endsWith('.jpg') || icon.endsWith('.svg');
    }

    hasError(): boolean | undefined {
        return this.control?.invalid && (this.control?.touched || this.control?.dirty);
    }
  }
  