
import { createAction, props } from '@ngrx/store';
import { ReceivedPatientChatEvent } from 'src/app/features/patient-communication/store/chat-store/chat.state';
import { CallEvent } from 'src/app/features/patient-communication/store/phone-store/phone-call.state';
import { PracticeLocation } from 'src/app/features/scheduler/store/scheduler-store/scheduler-state';

export const startSignalRConnection = createAction('[SignalR] API Start Connection');
export const startSignalRReconnecting = createAction('[SignalR] Start Reconnection', props<{ error: string, date: Date }>());
export const signalRConnectionSuccess = createAction('[SignalR] Connection Success', props<{ date: Date }>());
export const signalRConnectionError = createAction('[SignalR] Connection Error', props<{ error: string, date: Date }>());
export const stopSignalRConnection = createAction('[SignalR] API Stop Connection');
export const signalRConnectionStopped = createAction('[SignalR] Connection Stopped', props<{ date: Date }>());
export const scheduleEventReceived = createAction('[SignalR] Event Received', props<{ model: any, date: Date }>());
export const processPhoneCallEvent = createAction('[SignalR Call] process Phone Call Event Received', props<{ event: string, callState: CallEvent }>());
export const resetPhoneCallEvent = createAction('[SignalR Call] reset process Phone Call Event Received');
export const processReceivedChatEvent = createAction('[SignalR Chat] process Chat Event Received', props<{ event: string, receivedChatState: ReceivedPatientChatEvent }>());
export const processSendChatEvent = createAction('[SignalR Chat] process Chat Event Send', props<{ event: string, receivedChatState: ReceivedPatientChatEvent }>());
export const processMarkAsReadEvent = createAction('[SignalR Chat] process Chat Event MarkAsRead', props<{ event: string, receivedChatState: ReceivedPatientChatEvent }>());
export const locationGroupSubscribe = createAction('[SignalR] API  Location Group Subscribe', props<{ location: PracticeLocation }>());
export const locationGroupSubscribeSuccess = createAction('[SignalR] Location Group Subscribe Success', props<{ location: PracticeLocation }>());
export const locationGroupSubscribeError = createAction('[SignalR] Location Group Subscribe Error', props<{ error: any; location: PracticeLocation  }>());
export const locationGroupUnsubscribe = createAction('[SignalR] API Location Group Unsubscribe', props<{ locationGuid: string }>());
export const locationGroupUnsubscribeSuccess = createAction('[SignalR] Location Group Unsubscribe Success', props<{ locationGuid: string }>());
export const locationGroupUnsubscribeError = createAction('[SignalR] Location Group Unsubscribe Error', props<{ error: any; locationGuid: string }>());
export const signalrCloseErrorAlert = createAction('[SignalR] close error alert');
export const signalrCloseScheduleErrorAlert = createAction('[SignalR] close schedule error alert');
export const resetSignalrStore = createAction('[SignalR] reset signalr store');
    
export const practiceGroupSubscribe = createAction('[SignalR] API  practice Group Subscribe', props<{ practiceGuid: string }>());
export const practiceGroupSubscribeSuccess = createAction('[SignalR] practice Group Subscribe Success', props<{ practiceGuid: string }>());
export const practiceGroupSubscribeError = createAction('[SignalR] practice Group Subscribe Error', props<{ error: string , practiceGuid: string  }>());
export const practiceGroupUnsubscribe = createAction('[SignalR] API practice Group Unsubscribe', props<{ practiceGuid: string }>());
export const practiceGroupUnsubscribeSuccess = createAction('[SignalR] practice Group Unsubscribe Success', props<{ practiceGuid: string }>());
export const practiceGroupUnsubscribeError = createAction('[SignalR] practice Group Unsubscribe Error', props<{ error: any; practiceGuid: string }>());