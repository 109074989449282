import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { dialPhoneCall, resetdialPhoneCall } from '../../../store/phone-store/phone-call.action';
import { selectSubcripedLocation } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { PracticeLocation } from 'src/app/features/scheduler/store/scheduler-store/scheduler-state';
import { DialCallInfo } from '../../../store/phone-store/phone-call.state';
import { selectDialCallToken, selectOnCallInprogressphoneNumber } from '../../../store/phone-store/phone-call.selector';
import { AppLocalStorageService } from 'src/app/features/shared/services/app-local-storage.service';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { patientCommunicationConstants } from '../../../constant/patient-communication.constant';

declare const IVLConnect: any;
declare const IVLDisconnect: any;

@Component({
  selector: 'app-call-dialpad',
  templateUrl: './call-dialpad.component.html',
  styleUrls: ['./call-dialpad.component.scss']
})
export class CallDialpadComponent extends BaseDestroyComponent implements OnInit {

  @Input() public phoneNumber = '';
  @Input() public bCallInProgress!: boolean;
  @Output() public oCallClicked: EventEmitter<string> = new EventEmitter<string>();
  
  practiceLocation!: PracticeLocation;
  dialToken!: DialCallInfo; 
  onCallInprogressphoneNumber = '';

  subcribedLocation$ = this.store.select(selectSubcripedLocation);
  dialCallToken$ = this.store.select(selectDialCallToken);
  selectOnCallInprogressphoneNumber$ = this.store.select(selectOnCallInprogressphoneNumber);

  constructor(
    private store: Store,
    private appLocalStorageService: AppLocalStorageService,
    private auth: AuthService,
    private alert: AlertService,
    ) { super(); }

  ngOnInit(): void {

    this.subscriptions$.add(this.subcribedLocation$.subscribe((data?: PracticeLocation) => this.bindPracticeLocation(data)));
    this.subscriptions$.add(this.dialCallToken$.subscribe((data?: DialCallInfo) => this.bindDialToken(data)));
    this.subscriptions$.add(this.selectOnCallInprogressphoneNumber$.subscribe((data: string) =>
      this.onCallInprogressphoneNumber = data
    ));

    if(this.bCallInProgress)
      this.phoneNumber = '';
  }

  public addNumber(sNumber: string): void {
    this.phoneNumber = this.phoneNumber.concat(sNumber);
  }

  public bindDialToken(data?: DialCallInfo): void {
    if (data && data.isInvoke) return;
    if (data && data.callerId) {
      this.dialToken = data;
      if (!this.dialToken.token) {
        this.alert.successToast(patientCommunicationConstants.practiceLocationCommunicationPhoneNumberIsRequired);
        this.store.dispatch(resetdialPhoneCall());
        return;
      }
      if (this.dialToken.token && this.dialToken.webSocketConnections.length > 0) {
        const webSocketConnections = [...this.dialToken.webSocketConnections];
        this.appLocalStorageService.setItem("dialToken", this.dialToken.token);
        IVLConnect(webSocketConnections[0]);
      }
    }
  } 

  bindPracticeLocation(data?: PracticeLocation): void {
    if(data)
      this.practiceLocation = data;
  }
  public clear(): void {
    if(this.phoneNumber.length > 0) {
      this.phoneNumber = this.phoneNumber.substring(0, this.phoneNumber.length-1);
    }
  }

  @HostListener('document:EndOfCandidates', ['$event'])
  fnHandleWebRtcEvent(event:any) {
    this.oCallClicked.emit(!this.bCallInProgress ? this.phoneNumber : '');
  }

  public makeCall(): void {
    if (this.onCallInprogressphoneNumber) {
      this.alert.successToast(patientCommunicationConstants.communicationInprogressCall);
      return;
    }
    if (!this.practiceLocation) {
      this.alert.successToast(patientCommunicationConstants.communicationLocationIsRequired);
      return;
    }
    const model: any = {};
    model.NumberToDial = this.phoneNumber;
    model.practiceId = this.auth.getPracticeIDFromToken();
    model.practiceLocationId = this.practiceLocation.practiceLocationId;
    model.callerId = this.practiceLocation.practiceLocationCommunicationPhoneNumber;
    model.callerIdName = this.practiceLocation.practiceLocationName;

    this.store.dispatch(dialPhoneCall({dialCallInfo: model,successMessage: 'Call connected successfully'}))

    if (this.bCallInProgress) {
      IVLDisconnect();
    }
  }
}
