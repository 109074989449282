import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientSearchFormstatusComponent } from '../components/patient-search-formstatus/patient-search-formstatus.component';
import { SharedFeatureModule } from '../../shared/shared-feature.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { PatientInformationFeatureModule } from '../../patient/patient-information-feature.module';


@NgModule({
    declarations: [
        PatientSearchFormstatusComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        SharedModule,
        SharedFeatureModule,
    ],
    exports: [
        PatientSearchFormstatusComponent
    ]
})

export class PatientDashboardCoreModule {

}