import { Component, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/features/shared/utils/alert.service';

@Component({
  selector: 'app-dynamsoft',
  template: `<div id="dwtcontrolContainer" hidden="true"></div>`,
  encapsulation: ViewEncapsulation.None,
})
export class DynamsoftComponent implements OnInit {
  @Output() acquiredImage = new EventEmitter<File>();

  containerId = "dwtcontrolContainer";
  DWObject: WebTwain | any = null;

  constructor(private alert: AlertService) { }

  ngOnInit() {
    Dynamsoft.DWT.Containers = [{
      WebTwainId: 'dwtObject',
      ContainerId: this.containerId,
      Width: '300px',
      Height: '400px'
    }];
    Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      this.Dynamsoft_OnReady();
    });
    Dynamsoft.DWT.ProductKey = environment.dynamSoft.licenseKey;
    Dynamsoft.DWT.ResourcesPath = 'assets/dwt-resources';
    Dynamsoft.DWT.Load();
  }

  Dynamsoft_OnReady() {
    this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
    this.acquireImage();
  }

  acquireImage() {
    if (this.DWObject) {
      this.DWObject.SelectSourceAsync().then(() => {
        return this.DWObject.AcquireImageAsync({
          IfCloseSourceAfterAcquire: true,
        }).then(() => {
          this.Upload();
        });
      }).catch((exp: any) => {
        this.alert.failureAlert(exp.message)
      });
    }
  }

  Upload() {
    if (this.DWObject && this.DWObject.HowManyImagesInBuffer > 0) {
      const imgAry = [this.DWObject.CurrentImageIndexInBuffer];
      const fileBlob = this.DWObject.ConvertToBlob(imgAry, Dynamsoft.DWT.EnumDWT_ImageType.IT_JPG);
      const file = new File([fileBlob], 'acquireImage.jpg', { type: 'image/jpeg' });
      this.acquiredImage.emit(file);
    } else {
      this.alert.failureAlert("There is no image in buffer.")
    }
  }
}