import { createAction, createReducer, on, props } from '@ngrx/store';
import { DocumentUpload, DocumentUploadResponse, DocumentUploadState } from './document-upload.state';

export const addDocuments = createAction('[Document Upload] Add Documents', props<{ documents: File[] }>());
export const removeDocument = createAction('[Document Upload] Remove Documents',
  props<{ documentUploaded: DocumentUpload }>());

export const uploadDocument = createAction('[Document Upload] Upload Document',
  props<{document:File, formData:FormData, uploadType:string,documentId?:string}>());

export const uploadProgress = createAction('[Document Upload] Upload Progress',
  props<{ document: File, progress: number }>());
export const uploadComplete = createAction('[Document Upload] Upload Complete',
  props<{ document: File, documentUploadResponse: DocumentUploadResponse }>());
export const uploadFailure = createAction('[Document Upload] Upload Failure',
  props<{ document: File, error: string }>());

export const resetDocumentUploadState = createAction('[Document Upload] Reset State');

const initialState: DocumentUploadState = {
  documents: []
};

export const documentUploadReducer = createReducer(
  initialState,
  on(addDocuments, (state, { documents }) => {
    const documentUploads = documents.map(document => ({
      document,
      progress: 0,
      completed: false
    }));
    return { ...state, documents: [...state.documents, ...documentUploads] };
  }),
  on(uploadDocument, (state, { document, documentId }) => {
    const updatedDocuments = state.documents.map(documentUpload =>
      documentUpload.document === document ? {
        ...documentUpload, progress: 0, completed: false, documentId:documentId  
      } :
        documentUpload
    );
    return { ...state, documents: updatedDocuments };
  }),
  on(uploadProgress, (state, { document, progress }) => {
    const updatedDocumet = state.documents.map(documentUpload =>
      documentUpload.document === document ? { ...documentUpload, progress } : documentUpload
    );
    return { ...state, documents: updatedDocumet };
  }),
  on(uploadComplete, (state, { document, documentUploadResponse }) => {
    const updatedDocumet = state.documents.map(documentUpload =>
      documentUpload.document === document ?
        { ...documentUpload, completed: true, documentUploadResponse: documentUploadResponse } :
        documentUpload
    );
    return { ...state, documents: updatedDocumet };
  }),
  on(removeDocument, (state, { documentUploaded }) => {
    const updatedDocuments = state.documents.filter(documentUpload =>
      documentUpload !== documentUploaded
    );
    return { ...state, documents: updatedDocuments };
  }),
  on(uploadFailure,(state,{ document, error })=>{
    const updatedDocumet = state.documents.map(documentUpload =>
      documentUpload.document === document ? { ...documentUpload, error } : documentUpload
    );
    return { ...state, documents: updatedDocumet };
  }),
  on(resetDocumentUploadState, ():DocumentUploadState => initialState)
);