import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserRoutingModule } from './router/user-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffect } from './store/effects/authentication.effect';
import { AuthenticationReducer } from './store/reducers/authentication.reducer';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { AuthService } from './services/authentication/auth.service';
import { PracticeCoreModule } from '../practice/module/practice-core.module';
import { ResetpasswordComponent } from './components/authentication/resetpassword/resetpassword.component';
import { ChangepasswordComponent } from './components/authentication/changepassword/changepassword.component';
import { PracticeUserReducer } from '../practice/store/user-creation/practice-user.reducer';
import { PracticeUserEffect } from '../practice/store/user-creation/practice-user.effect';
import { ForgotpasswordComponent } from './components/authentication/forgotpassword/forgotpassword.component';
import { SharedFeatureModule } from '../shared/shared-feature.module';
import { UserFeatureCoreModule } from './user-feature-core.module';
import { SecurityPinValidationComponent } from './components/security-pin-validation/security-pin-validation.component';


@NgModule({
  declarations: [
    LogoutComponent,
    ResetpasswordComponent,
    ChangepasswordComponent,
    ForgotpasswordComponent,
    SecurityPinValidationComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PracticeCoreModule,
    SharedFeatureModule,
    UserFeatureCoreModule,
    StoreModule.forFeature('authenticationStore', AuthenticationReducer),
    StoreModule.forFeature('authenticationStore', AuthenticationReducer),
    StoreModule.forFeature('practiceUserStore', PracticeUserReducer),
    EffectsModule.forFeature([AuthenticationEffect,PracticeUserEffect])
  ],
  providers: [AuthService],
  exports: [
    LogoutComponent,
    SecurityPinValidationComponent
  ]
})
export class UserFeatureModule { }
