import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { AppLocalStorageService } from 'src/app/features/shared/services/app-local-storage.service';
import { selectCallEventState, selectSubcripedLocation } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { CallEventState } from 'src/app/infrastructure/signalr/store/signalr.state';
import { ActiveContactCallEvent, AnsweredCallDialCallInfo, CallEvent, PhoneCallStateEvent } from '../../../store/phone-store/phone-call.state';
import { selectActiveContactCallEvent, selectOnCallInprogressphoneNumber, selectPhoneStateEvent } from '../../../store/phone-store/phone-call.selector';
import {
  setDialPadPhoneNumber, invokeAnsweredCall, resetDialPadPhoneNumber, setonCallInprogressPhoneNumber,
  resetdialPhoneCall
} from '../../../store/phone-store/phone-call.action';

import { RecentCallLogs } from '../../../store/phone-store/phone-call.state';
import { selectRecentcall } from '../../../store/phone-store/phone-call.selector';
import { invokeGetRecentCallLog } from '../../../store/phone-store/phone-call.action';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { DateUtilService } from 'src/app/features/shared/services/date-util.service';
import { CallDialpadComponent } from '../call-dialpad/call-dialpad.component';
import { PracticeLocation } from 'src/app/features/scheduler/store/scheduler-store/scheduler-state';
import { CustomValidatorService } from 'src/app/features/shared/utils/custom-validator.service';
import { PatientCommunication, patientCommunicationConstants } from '../../../constant/patient-communication.constant';
import { PatientCommunicationActionService } from '../../../service/patient-communication-action-service';
import { PatientCommunicationCallEventService } from '../../../service/patient-communication-call-event-service';
import { resetPhoneCallEvent } from 'src/app/infrastructure/signalr/store/signalr.action';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { CommonUtilsService } from 'src/app/features/shared/utils/common-utils.service';

declare const IVLConnect: any;
declare const IVLDisconnect: any;
declare const sendTone: any;

export enum CallStateEnum {
  NONE = -1,
  DIALPAD,
  PROGRESS,
  ENDED,
  DIALING,
  INCOMING,
  CALL_IN_PROGRESS
}

@Component({
  selector: 'app-call-state',
  templateUrl: './call-state.component.html',
  styleUrls: ['./call-state.component.scss']
})
export class CallStateComponent extends BaseDestroyComponent implements OnInit {
  @ViewChild(CallDialpadComponent, { static: false }) callDialpadComponent!: CallDialpadComponent;
  @Input() public isIncomingCall!: boolean;
  @Input() public popupIndex!: number;

  @Output() onClose = new EventEmitter<number>();


  public sSearchPatientNameText = '';
  public readonly CallStateEnum: any = CallStateEnum;
  public sDisplayName = '';
  public sCallerName = '';
  public phoneNumber = '';
  public activeContact: any;
  public bCallInProgress = false;
  public eCallState: CallStateEnum = CallStateEnum.DIALPAD;
  public bIsMatDialogMinimized = false;
  public sCallConnectTime!: string;
  public dialerClick = 'dial';
  public patientData = [];
  public arrPatients = [];
  public bFetchedPatients = true;
  public disableRecentButton = false;
  public disablePatientSearchButton = false;
  recentLogloading = false;
  practiceLocation!: PracticeLocation;
  recentCallLogs: RecentCallLogs[] = []
  patientCommunications: PatientCommunication[] = [];
  practiceId = 0;
  callDialComponent: any;
  onCallInprogressphoneNumber = '';
  minimizePhone: boolean = false;
  callId = '';
  patientId = 0;
  callEventState$ = this.store.select(selectCallEventState);
  selectActiveContactCallEvent$ = this.store.select(selectActiveContactCallEvent);
  recentCallLogs$ = this.store.select(selectRecentcall)
  subcribedLocation$ = this.store.select(selectSubcripedLocation);
  selectPhoneStateEvent$ = this.store.select(selectPhoneStateEvent);
  selectOnCallInprogressphoneNumber$ = this.store.select(selectOnCallInprogressphoneNumber);


  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private appLocalStorageService: AppLocalStorageService,
    private authService: AuthService,
    private dateUtilService: DateUtilService,
    private patientCommunicationActionService: PatientCommunicationActionService,
    private patientCommunicationCallEventService: PatientCommunicationCallEventService,
    private alert: AlertService,
    private commonUtilsService: CommonUtilsService,) {
    super();
  }


  ngOnInit(): void {
    this.practiceId = this.authService.getPracticeIDFromToken();
    this.subscriptions$.add(this.callEventState$.subscribe((data?: CallEventState) => this.ReceivedCallEvent(data)));
    this.subscriptions$.add(this.selectActiveContactCallEvent$.subscribe((data?: ActiveContactCallEvent) => this.bindActiveContactCallEvent(data)));
    this.subscriptions$.add(this.recentCallLogs$.subscribe((data?: RecentCallLogs[]) => this.bindRecentCallLog(data)));
    this.subscriptions$.add(this.subcribedLocation$.subscribe((data?: PracticeLocation) => this.bindPracticeLocation(data)));
    this.subscriptions$.add(this.selectPhoneStateEvent$.subscribe((data: PhoneCallStateEvent) => this.bindPhoneStateEvent(data)));
    this.subscriptions$.add(this.patientCommunicationActionService.patientCommunications$.subscribe((data: PatientCommunication[]) => this.bindPatientCommunication(data)));
    this.subscriptions$.add(this.patientCommunicationCallEventService.onAnsweredCall$.subscribe((data: CallEvent) => this.onAnsweredCallEvent(data)));
    this.subscriptions$.add(this.patientCommunicationCallEventService.onCompletedCall$.subscribe((data: CallEvent) => this.onCompletedCallEvent(data)));
    this.subscriptions$.add(this.selectOnCallInprogressphoneNumber$.subscribe((data: string) => this.onCallInprogressphoneNumber = data));
  }

  ngAfterViewInit(): void {
    this.callDialComponent = this.callDialpadComponent;
  }

  onClickphone(): void {
    this.callDialComponent.phoneNumber = this.phoneNumber
    this.callDialComponent.makeCall();
  }

  bindPracticeLocation(data?: PracticeLocation): void {
    if (data)
      this.practiceLocation = data;
  }

  bindPhoneStateEvent(data: PhoneCallStateEvent): void {
    if (data)
      this.recentLogloading = data.loading ?? false;
    if (data.showSuccessAlert) {
    }
  }

  ReceivedCallEvent(data?: CallEventState): void {
    if (data && data.event && this.isIncomingCall) {
      if (data.event === 'ReceivedCall') {
        const phoneCall = data.phoneCallEventState;
        const callerId = this.commonUtilsService.formatCallerId(phoneCall!.callerId);
        if (this.phoneNumber && this.phoneNumber != callerId)
          return;
        if (this.callId) return;
        this.callId = (phoneCall) ? phoneCall.callId : '';
        this.dialerClick = 'dial'
        this.eCallState = CallStateEnum.INCOMING;
        this.phoneNumber = callerId;
        this.activeContact = phoneCall;
        this.sDisplayName = `${phoneCall?.patientLastName} ${phoneCall?.patientFirstName}`
        this.patientId = (phoneCall) ? phoneCall.patientId : 0;
        this.disableActionByCallState();
      }
    }
  }

  onAnsweredCallEvent(phoneCall: CallEvent) {
    if (phoneCall && this.isIncomingCall) {
      const callerId = this.commonUtilsService.formatCallerId(phoneCall!.callerId);
      if (this.phoneNumber && this.phoneNumber != callerId)
        return;
      if (this.eCallState === CallStateEnum.CALL_IN_PROGRESS) {
        this.eCallState = CallStateEnum.CALL_IN_PROGRESS;
        this.sCallConnectTime = phoneCall?.callConnect ?? '';
        this.activeContact = phoneCall;
        this.store.dispatch(setonCallInprogressPhoneNumber({ onCallInprogressphoneNumber: callerId }));
      }
      else {
        this.activeContact = null;
        this.phoneNumber = '';
        this.fnEndCallEmitHandler();
      }
      this.store.dispatch(resetPhoneCallEvent());
    }
  }

  onCompletedCallEvent(phoneCall: CallEvent) {
    if (phoneCall && this.isIncomingCall) {
      const callerId = this.commonUtilsService.formatCallerId(phoneCall!.callerId);
      if (this.callId !== phoneCall.callId)
        return;
      if (this.phoneNumber && this.phoneNumber != callerId)
        return;
      this.endOnCallInprogress();
      this.activeContact = null;
      this.phoneNumber = '';
      this.fnEndCallEmitHandler();
      this.store.dispatch(resetPhoneCallEvent());
    }
  }

  bindRecentCallLog(data?: RecentCallLogs[]): void {
    if (data)
      this.recentCallLogs = data;
  }

  onRootContainerClick(oEvent: Event): void {
    // if(!!this.oMatDialogData) {
    //   this.oDialogSvc.fnDialogClicked(oEvent, this.oChatDialogRef, this.oRenderer, this.oMatDlg);
    // }
  }

  onHeaderClick(): void {
    // if(!!this.oChatDialogRef) {
    //   this.bIsMatDialogMinimized = false;
    //   this.oChatDialogRef.removePanelClass('mat-dialog-collapsed');
    // }
  }

  onMinimizeIconClick(event: Event) {
    event.stopPropagation();
    // if(!!this.oChatDialogRef) {
    //   this.bIsMatDialogMinimized = true;
    //   this.oChatDialogRef.addPanelClass('mat-dialog-collapsed');
    // }
  }

  onCloseIconClick(event: Event) {
    event.stopPropagation();
    // if(!!this.oChatDialogRef) {
    //   this.oChatDialogRef.close();
    // }
  }

  fnCallButtonEmitHandler(nDialedNumber: any) {
    if (this.isIncomingCall && this.onCallInprogressphoneNumber) return;
    if (!this.isIncomingCall && this.onCallInprogressphoneNumber && nDialedNumber) return;
    if (!this.isIncomingCall && nDialedNumber && this.onCallInprogressphoneNumber === nDialedNumber) return;
    if (nDialedNumber) {
      this.sCallConnectTime = new Date(Date.now()).toISOString();
      this.eCallState = CallStateEnum.PROGRESS;
      this.phoneNumber = nDialedNumber;
      this.store.dispatch(setonCallInprogressPhoneNumber({ onCallInprogressphoneNumber: this.phoneNumber }));
    }
    else {
      this.store.dispatch(setonCallInprogressPhoneNumber({ onCallInprogressphoneNumber: '' }));
      this.store.dispatch(resetdialPhoneCall());
      this.eCallState = CallStateEnum.DIALPAD;
    }
    this.disableActionByCallState();
  }

  fnAcceptIncomingCallEmitHandler() {
    if (this.onCallInprogressphoneNumber) {
      this.alert.successToast(patientCommunicationConstants.communicationInprogressCall);
      return;
    }
    // Need to call the api to decline incoming-call
    this.appLocalStorageService.setItem("dialToken", this.activeContact.callPickupToken);
    IVLConnect(this.activeContact.webSocketConnections[0]);
    this.sCallConnectTime = new Date(Date.now()).toISOString();
    this.eCallState = CallStateEnum.CALL_IN_PROGRESS;
    this.invokeAnsweredCall();
    this.disableActionByCallState();
  }

  private invokeAnsweredCall() {
    const dialCallInfo: AnsweredCallDialCallInfo = {
      callerId: this.phoneNumber,
      callerIdName: this.phoneNumber,
      callId: this.phoneNumber,
      dialedNumber: this.practiceLocation.practiceLocationCommunicationPhoneNumber,
      customerGuid: this.practiceLocation.practiceLocationGuid,
      groupName: "string",
      callStart: "string",
      callConnect: "string",
      answeredBy: "string",
    };
    this.store.dispatch(invokeAnsweredCall({ dialCallInfo: dialCallInfo }));
  }

  fnDeclineIncomingCallEmitHandler() {
    if (!this.isIncomingCall && this.onCallInprogressphoneNumber) return;
    this.dialerClick = 'dial';
    this.eCallState = CallStateEnum.DIALPAD;
    this.disableActionByCallState();
    if (this.isIncomingCall) this.store.dispatch(resetdialPhoneCall());
    if (this.isIncomingCall) this.closeModal();
  }

  fnEndCallEmitHandler() {
    // Need to call the api to decline incoming-call
    this.endOnCallInprogress();
    this.eCallState = CallStateEnum.DIALPAD;
    this.dialerClick = 'dial';
    this.disableActionByCallState();
    if (this.isIncomingCall) this.store.dispatch(resetdialPhoneCall());
    if (this.isIncomingCall) this.closeModal();
    if (!this.onCallInprogressphoneNumber)
      IVLDisconnect();
  }

  endOnCallInprogress() {
    if (this.onCallInprogressphoneNumber && this.onCallInprogressphoneNumber === this.phoneNumber)
      this.store.dispatch(setonCallInprogressPhoneNumber({ onCallInprogressphoneNumber: '' }));
  }

  fnSendUserInputHandler(input: number) {
    sendTone(input);
  }

  fnShowData(showData: string) {
    this.dialerClick = showData;
    //const practiceLocationId = this.callDialComponent.practiceLocation.practiceLocationId;
    const currentEndDate = this.dateUtilService.GetApplicationCurrentDateTime();
    const currentStartDate = this.dateUtilService.GetApplicationCurrentDateTime();
    const startDateCall = this.datePipe.transform(currentStartDate.setDate(currentStartDate.getDate() - 1), 'MM/dd/yyyy');
    const endDateCall = this.datePipe.transform(currentEndDate.setDate(currentEndDate.getDate() + 1), 'MM/dd/yyyy');
    this.invokeRecentCallLog(showData, '', startDateCall, endDateCall);
  }

  private invokeRecentCallLog(showData: string, practiceLocationId: any, startDateCall: string | null, endDateCall: string | null) {
    if (showData === 'recent') {
      this.store.dispatch(invokeGetRecentCallLog({ practiceId: this.practiceId, practiceLocationId: practiceLocationId, startDate: startDateCall, endDate: endDateCall }));
    }
  }

  bindActiveContactCallEvent(data?: ActiveContactCallEvent) {
    if (data && data.primaryPhone && !this.isIncomingCall) {
      if (this.eCallState === CallStateEnum.INCOMING || this.eCallState === CallStateEnum.CALL_IN_PROGRESS
        || this.eCallState === CallStateEnum.PROGRESS)
        return;
      this.phoneNumber = data.primaryPhone;
      const activeContact: ActiveContactCallEvent = {
        primaryPhone: data.primaryPhone,
        patientLastName: data.patientLastName,
        patientFirstName: data.patientFirstName,
      };
      this.sDisplayName = `${activeContact.patientLastName} ${activeContact.patientFirstName}`
      this.activeContact = activeContact;
      this.dialerClick = 'dial';
      this.eCallState = CallStateEnum.DIALPAD;
      this.patientId = data.patientId ?? 0
      this.disableActionByCallState();
      this.store.dispatch(resetDialPadPhoneNumber());
    }
  }

  onCallIconClick(contact: any) {
    if (contact) {
      const sLastName = contact.patientName.split(' ').pop();
      const sFirstName = contact.patientName.split(' ').at(0);
      this.phoneNumber = (contact.isIncomingCall || contact.isMissedCall ? CustomValidatorService.removeMobileNumberFormatting(contact.from)
        : CustomValidatorService.removeMobileNumberFormatting(contact.to)) ?? '';
      if (!this.phoneNumber) return;
      this.sCallConnectTime = new Date(Date.now()).toISOString();
      this.sDisplayName = contact.patientName.replace(/[^A-Za-z ]/g, '')
      const activeContactCallEvent: ActiveContactCallEvent = {
        primaryPhone: this.phoneNumber,
        patientLastName: sLastName,
        patientFirstName: sFirstName,
        patientId: contact.patientId
      }
      this.store.dispatch(setDialPadPhoneNumber({ activeContactCallEvent: activeContactCallEvent }));
    }
  }

  disableActionByCallState() {
    if (this.eCallState === CallStateEnum.PROGRESS || this.eCallState === CallStateEnum.INCOMING) {
      this.disableRecentButton = true;
      this.disablePatientSearchButton = true;
    }
    if (this.eCallState === CallStateEnum.DIALPAD) {
      this.disableRecentButton = false;
      this.disablePatientSearchButton = false;
    }
  }

  onChatIconClick(contact: any) {
    if (contact) {
      const patientName = this.commonUtilsService.splitFullName(contact.patientName);
      const sLastName = patientName.lastName;
      const sFirstName = patientName.firstName;
      this.phoneNumber = (contact.isIncomingCall || contact.isMissedCall ? CustomValidatorService.removeMobileNumberFormatting(contact.from)
        : CustomValidatorService.removeMobileNumberFormatting(contact.to)) ?? '';
      if (!this.phoneNumber) return;
      const patient: PatientCommunication = {
        communicationType: 'PatientChat',
        patientFirstName: sFirstName,
        patientLastName: sLastName,
        patientId: contact.patientId,
        patientName: `${sLastName}, ${sFirstName}`,
        patientPrimaryPhone: this.phoneNumber,
      }
      this.patientCommunicationActionService.onChatClick(patient);
    }
  }

  closeModal() {
    if (this.eCallState === CallStateEnum.INCOMING || this.eCallState === CallStateEnum.CALL_IN_PROGRESS
      || this.eCallState === CallStateEnum.PROGRESS)
      return;
    this.onClose.emit(this.popupIndex);
  }

  minimizePhonePopup() {
    this.minimizePhone = !this.minimizePhone;
  }

  bindPatientCommunication(data: PatientCommunication[]) {
    this.patientCommunications = data;
    if (this.isIncomingCall && this.phoneNumber && this.phoneNumber === this.commonUtilsService.formatCallerId(this.activeContact.callerId)) {
      const indexes = this.patientCommunications
        .map((communication, index) =>
          communication.communicationType === 'PatientIncomingCall' &&
            communication.patientPrimaryPhone === this.commonUtilsService.formatCallerId(this.activeContact.callerId)? index : -1)
        .find(index => index !== -1);
      this.popupIndex = indexes ?? 0;
    }
    else if (!this.isIncomingCall) {
      const indexes = this.patientCommunications
        .map((communication, index) =>
          communication.communicationType === 'PatientCallDial' ? index : -1)
        .find(index => index !== -1);
      this.popupIndex = indexes ?? 0;
    }
  }

}