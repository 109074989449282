import { createAction, createReducer, on, props } from '@ngrx/store';
import { LetterTemplate, PracticeAppointmentType, PracticeCoreInfo, PracticeCurrentDateTime, PracticeLocation, PracticeProvider } from '../state/practice-core';
import { PracticePersonalInformation } from '../state/practice-info';

export const invokeGetProviders = createAction('[GetProviders API] invoke GetProviders API');
export const getProvidersSuccess = createAction(
  '[GetProviders API] GetProviders API Success', props<{ practiceProviders: PracticeProvider[] }>());
export const getProvidersFailure = createAction(
  '[GetProviders API] GetProviders API Failure', props<{ errorMessage: string }>());

export const invokeGetLocationByEmail = createAction('[GetLocations API] invoke GetLocations API',
  props<{ email: string }>()
);
export const getLocationsSuccess = createAction(
  '[GetLocations API] GetLocations API Success', props<{ practiceLocations: PracticeLocation[] }>());
export const getLocationsFailure = createAction(
  '[GetLocations API] GetLocations API Failure', props<{ errorMessage: string }>());

export const invokeGetAppointmentTypes = createAction('[GetAppointmentType API] Dropdown GetAppointmentType API',  props<{ practiceId: number }>());
export const getAppointmentTypesSuccess = createAction('[GetAppointmentType API] Dropdown AppointmentType API Success', props<{ appointmentTypes: PracticeAppointmentType[] }>());
export const getAppointmentTypesFailure = createAction('[GetAppointmentType API]  Dropdown AppointmentType API Failure', props<{ errorMessage: string }>());

export const invokeGetLetterTemplates = createAction('[LetterTemplates DropDown API] Dropdown LetterTemplates API',  props<{ practiceId: number }>());
export const getLetterTemplatesSuccess = createAction('[LetterTemplates DropDown API] Dropdown LetterTemplates API Success', props<{ letterTemplates: LetterTemplate[] }>());
export const getLetterTemplatesFailure = createAction('[LetterTemplates DropDown API] Dropdown LetterTemplates API Failure', props<{ errorMessage: string }>());

export const invokeGetPracticeCoreInfo = createAction('[GetPracticeCoreI API] Invoke GetPracticecore Info API', props<{ practiceId: number }>());
export const getPracticeCoreInfoSuccess = createAction('[GetPracticeCore API] GetPracticeCoreInfo API Success',
  props<{ practicePersonalInfo: PracticePersonalInformation }>());
export const GetPracticeCoreInfoFailure = createAction('[GetPracticeCoreInfo API] GetPracticeCoreinfo API Failure', props<{ errorMessage: string }>());

export const invokePracticeUserLocationByPracticeId = createAction('[GetPracticeUserLocationByPracticeId] Invoke ', props<{ practiceId: number }>());
export const invokePracticeUserLocationByPracticeIdSuccess = createAction('[GetPracticeUserLocationByPracticeId] API Success', props<{ practiceLocations: PracticeLocation[] }>());
export const invokePracticeUserLocationByPracticeIdFailure = createAction('[GetPracticeUserLocationByPracticeId] API Failure', props<{ errorMessage: string }>());
export const resetPracticeCoreState = createAction('[resetPracticeCoreState]')
export const stopPracticeLocationLoading = createAction('[stopPracticeLocationLoading] stopPracticeLocationLoading');

export const invokeGetPracticeCurrentDateTime = createAction('[invokeGetPracticeCurrentDateTime] Invoke invokeGetPracticeCurrentDateTime', props<{practiceId: number}>());
export const getPracticeCurrentDateTimeSuccess = createAction('[invokeGetPracticeCurrentDateTime] API Success', props<{ practiceCurrentDateTime:  PracticeCurrentDateTime}>());
export const getPracticeCurrentDateTimeFailure = createAction('[invokeGetPracticeCurrentDateTime] API Failure', props<{ errorMessage: string }>());

export const initialState: Readonly<PracticeCoreInfo> = {};

export const practiceCoreReducer = createReducer(
  initialState,
  on(getProvidersSuccess, (state, { practiceProviders }): PracticeCoreInfo => {
    return ({
      ...state,
      practiceProviderCoreInfo: {
        ...state.practiceProviderCoreInfo, practiceProviders: practiceProviders
      }
    });
  }),
  on(getProvidersFailure, (state, { errorMessage }): PracticeCoreInfo => {
    return ({
      ...state, practiceProviderCoreInfo: {
        ...state.practiceProviderCoreInfo, coreStateEvent: {
          ...state.practiceProviderCoreInfo?.coreStateEvent,
          errorMessage: "Failed to load the Doctors list" + (errorMessage ? " - " + errorMessage : ''),
          loading:false
        }
      }
    });
  }),
  on(invokeGetLocationByEmail, (state): PracticeCoreInfo => ({
    ...state,
    practiceLocationsCoreInfo: {
      practiceLocations:[],
      coreStateEvent: {
        errorMessage: "",
        loading: true
      }
    }
  })),
  on(getLocationsSuccess, (state, { practiceLocations }): PracticeCoreInfo => ({
    ...state,
    practiceLocationsCoreInfo: {
      practiceLocations: practiceLocations,
      coreStateEvent: {
        errorMessage: "",
        loading: false
      }
    }
  })),
  on(getLocationsFailure, (state, { errorMessage }): PracticeCoreInfo => ({
    ...state,
    practiceLocationsCoreInfo: {
      coreStateEvent: {
        errorMessage: "Failed to load the Location list" + (errorMessage ? " - " + errorMessage : ''),
        loading: false
      }
    }
  })),
  on(getAppointmentTypesSuccess, (state, { appointmentTypes }): PracticeCoreInfo => {
    return ({
      ...state,practiceAppointmentTypeCoreInfo: {
        ...state.practiceAppointmentTypeCoreInfo, practiceAppointmentTypes: appointmentTypes.filter(a=>a.isActive)
        .sort((a, b) => a.practiceAppointmentName.localeCompare(b.practiceAppointmentName)),
      }});
  }),
  on(getAppointmentTypesFailure, (state, { errorMessage }): PracticeCoreInfo => {
    return ({
      ...state, practiceAppointmentTypeCoreInfo: {
        ...state.practiceAppointmentTypeCoreInfo, coreStateEvent: {
          ...state.practiceAppointmentTypeCoreInfo?.coreStateEvent,
          errorMessage: "Failed to load the appointment types " + (errorMessage ? " - " + errorMessage : ''),
          loading:false
        }
      }
    });
  }),
  on(getLetterTemplatesSuccess, (state, { letterTemplates }): PracticeCoreInfo => {
    return ({
      ...state,practiceLetterTemplateCoreInfo: {
        ...state.practiceLetterTemplateCoreInfo, practiceLetterTemplates: letterTemplates.filter(l=>l.isActive)
        .sort((a, b) => a.practiceLetterTemplateName.localeCompare(b.practiceLetterTemplateName)),
      }});
  }),
  on(getLetterTemplatesFailure, (state, { errorMessage }): PracticeCoreInfo => {
    return ({
      ...state, practiceLetterTemplateCoreInfo: {
        ...state.practiceLetterTemplateCoreInfo, coreStateEvent: {
          ...state.practiceLetterTemplateCoreInfo?.coreStateEvent,
          errorMessage: "Failed to load the appointment types " + (errorMessage ? " - " + errorMessage : ''),
          loading:false
        }
      }
    });
  }),
  on(getPracticeCoreInfoSuccess, (state, { practicePersonalInfo }): PracticeCoreInfo => {
    return ({
      ...state, practicePersonalCoreInfo: {
        ...state.practicePersonalCoreInfo, practicePersonalInformation: practicePersonalInfo
      }
    });
  }),
  on(GetPracticeCoreInfoFailure, (state, { errorMessage }): PracticeCoreInfo => {
    return ({
      ...state, practicePersonalCoreInfo: {
        ...state.practicePersonalCoreInfo, coreStateEvent: {
          ...state.practicePersonalCoreInfo?.coreStateEvent,
          errorMessage: "Failed to load the practice info" + (errorMessage ? " - " + errorMessage : ''),
          loading:false
        }
      }
    });
  }),
  on(invokePracticeUserLocationByPracticeId, (state): PracticeCoreInfo => ({
    ...state,
    practiceLocationsCoreInfo: {
      coreStateEvent: {
        errorMessage: "",
        loading: true
      }
    }
  })),
  on(invokePracticeUserLocationByPracticeIdSuccess, (state, { practiceLocations }): PracticeCoreInfo => ({
    ...state,
    practiceLocationsCoreInfo: {
      practiceLocations: practiceLocations,
      coreStateEvent: {
        errorMessage: "",
        loading: false
      }
    }
  })),
  on(invokePracticeUserLocationByPracticeIdFailure, (state, { errorMessage }): PracticeCoreInfo => ({
    ...state,
    practiceLocationsCoreInfo: {
      coreStateEvent: {
        errorMessage: errorMessage ? errorMessage : "Failed to load the practice location",
        loading: false
      }
    }
  })),
  on(stopPracticeLocationLoading, (state): PracticeCoreInfo => {
    return ({
      ...state, practiceLocationsCoreInfo: {
        ...state.practiceLocationsCoreInfo, coreStateEvent: {
          errorMessage: "",
          loading: false
        }
      }
    });
  }),

  on(invokeGetPracticeCurrentDateTime, (state, { practiceId: practiceid }): PracticeCoreInfo => ({
    ...state,
    practiceLocationsCoreInfo: {
      coreStateEvent: {
        errorMessage: "",
        loading: true
      }
    }
  })),
  on(getPracticeCurrentDateTimeSuccess, (state, { practiceCurrentDateTime }): PracticeCoreInfo => ({
    ...state,
    practiceCurrentDateTime: practiceCurrentDateTime,
    practiceLocationsCoreInfo: {
      coreStateEvent: {
        errorMessage: '',
        loading: false
      }
    }
  })),
  on(getPracticeCurrentDateTimeFailure, (state, { errorMessage }): PracticeCoreInfo => {
    return ({
      ...state, practiceLocationsCoreInfo: {
        ...state.practiceLocationsCoreInfo, coreStateEvent: {
          errorMessage: errorMessage,
          loading: false
        }
      }
    });
  }),

  on(resetPracticeCoreState, () => initialState),
  );