import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DxScrollViewModule, DxSortableModule } from 'devextreme-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopupModule, PopupService } from '@progress/kendo-angular-popup';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconModule } from '@progress/kendo-angular-icons';

import { PatientLiveStatusComponent } from './component/patient-live-status/patient-live-status.component';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { patientLiveStatusReducer } from './store/patient-live-status-store/patient-live-status.reducer';
import { PatientLiveStatusEffect } from './store/patient-live-status-store/patient-live-status.effect';
import { PatientLiveStatusApiService } from './api/patient-live-status-api.service';
import { SchedulerService } from '../scheduler/service/scheduler.service';
import { PatientLiveStatusService } from './service/patient-live-status.service';
import { SharedFeatureModule } from '../shared/shared-feature.module';
import { SeatedPatientStatusModalComponent } from './component/seated-patient-status-modal/seated-patient-status-modal.component';
import { ChairLiveStatusComponent } from './component/chair-live-status/chair-live-status.component';
import { LiveStatusLocationComponent } from './component/live-status-location/live-status-location.component';
import { PatientLiveStatusSubscriptionService } from './service/patient-live-status-subscription.service';
import { SignalrConnectionStatusComponent } from './component/signalr-connection-status/signalr-connection-status.component';
import { SignalrModule } from 'src/app/infrastructure/signalr/signalr.module';
import { PatientLiveStatusSkeletonComponent } from './component/patient-live-status-skeleton/patient-live-status-skeleton.component';
import { LocationSubscriptionStatusComponent } from './component/location-subscription-status/location-subscription-status.component';
import { SharedFeatureKendoModule } from '../shared/shared-feature-kendo.module';
import { RouterModule } from '@angular/router';
import { CdTimerModule } from 'angular-cd-timer';
import { UserFeatureCoreModule } from '../user/user-feature-core.module';
import { PatientLiveStatusAppointmentSearchComponent } from './component/patient-live-status-appointment-search/patient-live-status-appointment-search.component';
import { PatientLiveAgreementComponent } from './component/patient-live-agreement/patient-live-agreement.component';



@NgModule({
  declarations: [
    PatientLiveStatusComponent,
    SeatedPatientStatusModalComponent,
    ChairLiveStatusComponent,
    LiveStatusLocationComponent,
    SignalrConnectionStatusComponent,
    PatientLiveStatusSkeletonComponent,
    LocationSubscriptionStatusComponent,
    PatientLiveStatusAppointmentSearchComponent,
    PatientLiveAgreementComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SignalrModule,
    DxScrollViewModule,
    DxSortableModule,
    SharedFeatureModule,
    NgSelectModule,
    RouterModule.forChild([]),
    StoreModule.forFeature('patientLiveStatusStore', patientLiveStatusReducer),
    EffectsModule.forFeature([PatientLiveStatusEffect]),
    SharedFeatureKendoModule,
    PopupModule,
    ButtonsModule,
    IconModule,
    CdTimerModule,
    UserFeatureCoreModule
  ],
  exports: [
    PatientLiveStatusComponent,
    ChairLiveStatusComponent,
    LiveStatusLocationComponent,
    SignalrConnectionStatusComponent
  ],
  providers: [
    PatientLiveStatusApiService,
    SchedulerService,
    PatientLiveStatusService,
    PatientLiveStatusSubscriptionService,
    PopupService
  ]

})
export class PatientLiveStatusModule { }
