import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ChatState } from './chat.state';

// Selector to get the feature state
export const selectChatState = createFeatureSelector<ChatState>('chatStore');

// Selectors for specific pieces of state
export const selectRecentPatientChats = createSelector(
  selectChatState,
  (state: ChatState) => state.recentPatientChats
);

export const selectChosenPatientChat = createSelector(
  selectChatState,
  (state: ChatState) => state.chosenPatientChat
);

export const selectPatientChatCollection = createSelector(
  selectChatState,
  (state: ChatState) => state.chosenPatientChat?.patientChatCollection
);

export const selectChatPageStateEvent = createSelector(
  selectChatState,
  state => state.chatStateEvent
);

export const selectPatientChatCollections = createSelector(
  selectChatState,
  state => state.patientChatCollection
);

export const selectTrackingPatientChat = createSelector(
  selectChatState,
  state => state.trackingPatientChat
);

export const selectChatReadEvent = createSelector(
  selectChatState,
  state => state.chatReadEvent
);

export const selectUnreadPatientsChatCollection = createSelector(
  selectChatState,
  state => state.unreadPatientsChatCollection
);

