import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable()
export class AlertService {
  static showAlert: any;
  static successAlert: any;
  static failureAlert: any;
  static warningAlert: any;
  static successToast: any;
  static failureToast: any;
  static warningToast: any;
  static yesNoWarningAlert: any;
  htmlContent = `
  <div id="message-container" class="message-container">
    <p id="message">We are experiencing an issue. Please contact administrator for more details. </p>
    <div id="view-more" class="view-more" onclick="viewMore()">View More</div>
  </div>
  <style>
    .message-container {
      max-height: 100px;
      overflow: hidden;
      transition: max-height 0.3s ease;
    }
    .message-container.expanded {
      max-height: none;
    }
    .view-more {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }
  </style>
`;

  constructor() { }

  showAlert(object: SweetAlertOptions) {
    return Swal.fire({
      title: object.title,
      titleText: object.titleText,
      html: object.html,
      text: object.text,
      icon: object.icon ?? undefined,//'success' | 'error' | 'warning' | 'info' | 'question'
      iconColor: object.iconColor,
      iconHtml: object.iconHtml,
      footer: object.footer,
      backdrop: object.backdrop ?? true,
      toast: object.toast ?? false,//neet to set position and timer property
      width: object.width ?? '32em',
      padding: object.padding ?? '0 0 1.25em',
      color: object.color,
      background: object.background,
      position: object.position ?? 'center',/* 'top-start'| 'top-end'| 'top-left'| 'top-right'| 'center' | 'center-start' | 'center-end'
      | 'center-left' | 'center-right' | 'bottom'| 'bottom-start'| 'bottom-end'| 'bottom-left'| 'bottom-right'*/
      timer: object.timer,
      timerProgressBar: object.timerProgressBar ?? false,
      allowOutsideClick: object.allowOutsideClick ?? true,
      allowEscapeKey: object.allowEscapeKey ?? true,
      allowEnterKey: object.allowEnterKey ?? true,
      showConfirmButton: object.showConfirmButton ?? true,
      showDenyButton: object.showDenyButton ?? false,
      showCancelButton: object.showCancelButton ?? false,
      confirmButtonText: object.confirmButtonText ?? 'OK',
      denyButtonText: object.denyButtonText ?? 'No',
      cancelButtonText: object.cancelButtonText ?? 'Cancel',
      confirmButtonColor: object.confirmButtonColor,
      denyButtonColor: object.denyButtonColor,
      cancelButtonColor: object.cancelButtonColor,
      focusConfirm: object.focusConfirm ?? true,
      focusDeny: object.focusDeny ?? false,
      focusCancel: object.focusCancel ?? false,
      showCloseButton: object.showCloseButton ?? false,
      closeButtonHtml: object.closeButtonHtml,
    });
  }

  async successAlert(text: string) {
    return Swal.fire({
      text: text,
      icon: 'success',
      allowOutsideClick: false
    });
  }

  async successAlertAutoClose(text: string) {
    return Swal.fire({
      text: text,
      icon: 'success',
      timer: 2000,
      allowOutsideClick: false,
      timerProgressBar: true
    });
  }

  async failureAlert(text: any) {
    text = this.DisplayDefaultTextIfNeeded(text);
    if (environment.application.hideTechnicalError) {
      return Swal.fire({
        title: 'Error Encountered.',
        text: this.alertText(text),
        html: this.htmlContent,
        icon: 'error',
        allowOutsideClick: false,
        didRender: () => this.viewMoreErrorMessage(this.alertText(text))
      });
    }
    return Swal.fire({
      title: 'Error Encountered.',
      text: this.alertText(text),
      icon: 'error',
      allowOutsideClick: false,
    });
  }

  viewMoreErrorMessage(text: string) {
      const viewMore = document.getElementById('view-more');
      if(viewMore) {
        viewMore.addEventListener('click', () => {
        const messageContainer = document.getElementById('message-container');
        const message = document.getElementById('message');
        message!.innerHTML += text;
        messageContainer!.classList.toggle('expanded');
        viewMore.style.display = 'none';
      });
    }
  }

  async warningAlert(text: string) {
    return Swal.fire({
      title: 'Attention!',
      text: text,
      icon: 'warning',
      allowOutsideClick: false
    });
  }

  async warningAlertAutoClose(text: string) {
    return Swal.fire({
      title: 'Attention!',
      text: text,
      icon: 'warning',
      timer: 2000,
      allowOutsideClick: false,
      timerProgressBar: true
    });
  }

  async successToast(text: string, autoCloseTimer?: number) {
    return Swal.fire({
      icon: 'success',
      text: text,
      toast: true,
      timer: autoCloseTimer ?? 3000,
      position: 'top-end',
      showConfirmButton: false,
      timerProgressBar: true,
    });
  }

  async failureToast(text: string) {
    text = this.DisplayDefaultTextIfNeeded(text);
    return Swal.fire({
      icon: 'error',
      text: text,
      toast: true,
      timer: 3000,
      position: 'top-end',
      showConfirmButton: false,
      timerProgressBar: true,
    });
  }

  async warningToast(text: string) {
    return Swal.fire({
      icon: 'warning',
      text: text,
      toast: true,
      timer: 3000,
      position: 'top-end',
      showConfirmButton: false,
      timerProgressBar: true,
    });
  }

  async yesNoWarningAlert(text: string) {
    return Swal.fire({
      icon: 'question',
      text: text,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      showCancelButton: true,
      allowOutsideClick: false
    });
  }

  async twoButtonAlert(text: string, firstButton: string, secondButton: string) {
    return Swal.fire({
      icon: 'success',
      text: text,
      cancelButtonText: firstButton,
      confirmButtonText: secondButton,
      showCancelButton: true,
      allowOutsideClick: false,
      title: (!secondButton) ? 'Great job!' : '',
      timer: (!secondButton) ? 2000 : 0,
      showConfirmButton: (!secondButton) ? false : true,
      timerProgressBar: (!secondButton) ? true : false,
    });
  }

  private DisplayDefaultTextIfNeeded(text: any) {
    if(!text  || (text && text.length <= 0)) {
      return text = 'Something went wrong. An error occurred during this operation. If this issue persists, please reach out to the administrator.';
    }
    return text;
  }

  alertText(text: any) {
    if (typeof text === 'object') {
      return JSON.stringify(text);
    }
    else {
      return text;
    }
  }

  async yesNoWarningAlertWithTemplate(html: string) {
    return Swal.fire({
      icon: 'question',
      html: html,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      showCancelButton: true,
      allowOutsideClick: false
    });
  }

}