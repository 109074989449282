import { Component, EventEmitter, Input, NgModuleRef, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { PatientStatusInfo } from 'src/app/features/patient/store/state/patient-status.state';
import { manageExamOveralyWizardInput } from 'src/app/features/scheduler/store/exam-store/exam-state';
import { DynamicModuleService } from 'src/app/features/shared/services/dynamic-load-module-component';
import { NavigateURLService } from 'src/app/features/shared/services/navigate-url.service';

@Component({
  selector: 'app-patient-live-status-appointment-search',
  templateUrl: './patient-live-status-appointment-search.component.html'
})
export class PatientLiveStatusAppointmentSearchComponent implements OnInit {
  @Input() patientId: number | undefined;
  @Input() providerId: number | undefined;
  @Input() patientStatusId!: number | undefined;
  @Input() patientExam!: manageExamOveralyWizardInput;
  @Input() appointmentTypeId!: number | undefined;
  @Input() nextVistDate!: string;
  @Input() patientStatusIds: number[] = [];
  @Input() examResultIds: number[] = [];
  @Input() hasMiscPlan: boolean = false;
  @Output() patientStatusChange = new EventEmitter();

  isPatientStatusHide = false;
  isPatientExamHide = false;
  isAppointmentStatusHide = false;
  isFromLiveStatus = true;

  patientStatusComponentRef: any;
  patientStatusModuleRef!: NgModuleRef<any>;
  @ViewChild('patientStatus', { read: ViewContainerRef, static: true }) _patientStatusContainer!: ViewContainerRef;

  patientExamComponentRef: any;
  patientExamModuleRef!: NgModuleRef<any>;
  @ViewChild('patientExamRef', { read: ViewContainerRef, static: true }) _patientExamContainer!: ViewContainerRef;

  appoitmentSearchComponentRef: any;
  appoitmentSearchModuleRef!: NgModuleRef<any>;
  @ViewChild('appointmentSearch', { read: ViewContainerRef, static: true }) _appoitmentSearchContainer!: ViewContainerRef;

  constructor(private navigateURLService: NavigateURLService,
    private dynamicModuleService: DynamicModuleService) { }

  async ngOnInit() {
   this.patientStatusComponentRef = await this.dynamicModuleService.loadModuleAndComponent("patientStatus", this.patientStatusComponentRef, 
   this.patientStatusModuleRef, this._patientStatusContainer,  { patientId: this.patientId, patientStatusId: this.patientStatusId, patientStatusIds: this.patientStatusIds});
   this.patientStatusComponentRef.instance.successEvent.subscribe((patientStatusInfo: PatientStatusInfo) => {
    this.patientStatusChange.emit(patientStatusInfo);
 });

   if(this.patientExam.patientExamId){
    this.patientExamComponentRef = await this.dynamicModuleService.loadModuleAndComponent("patientExam", this.patientExamComponentRef, 
    this.patientExamModuleRef, this._patientExamContainer,  { patientExam: this.patientExam, examResultIds: this.examResultIds, isFromLiveStatus: this.isFromLiveStatus});
   }

    this.appoitmentSearchComponentRef = await this.dynamicModuleService.loadModuleAndComponent("appointmentSearch", this.appoitmentSearchComponentRef,
      this.appoitmentSearchModuleRef, this._appoitmentSearchContainer, {
        practiceLocationId: this.patientExam.practiceLocationId, isFromLiveStatus: this.isFromLiveStatus, appointmentTypeId: this.appointmentTypeId
      , patientId: this.patientId, providerId: this.providerId, nextVistDate: this.nextVistDate
    });
  }
 

  openPatient(patientId: number) {
    return this.navigateURLService.getContractSummaryURL(patientId);
  }

  patientStatusHideShow() {
    this.isPatientStatusHide = !this.isPatientStatusHide;
  }

  patientExamHideShow() {
    this.isPatientExamHide = !this.isPatientExamHide;
  }

  appointmentStatusHideShow() {
    this.isAppointmentStatusHide = !this.isAppointmentStatusHide;
  }
  
}
