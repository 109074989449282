import { environment } from "src/environments/environment";

const USER_API_BASE_URL = environment.application.api_base_user_url;

function userApiUrl(endpoint: string) {
    return `${USER_API_BASE_URL}${endpoint}`;
}

export const userApiAssert = {
    getAllUsers: userApiUrl("User/GetAllUsers"),
    createUser: userApiUrl("User/CreateUser"),
    updateProfile: userApiUrl("User/UpdateProfile"),
    getUserByEmail: userApiUrl("User/GetUserByEmail"),
    markAsActive: userApiUrl("User/MarkAsActive"),
    markAsInActive: userApiUrl("User/MarkAsInActiveUser"),
    forgotPassword: userApiUrl("User/ForgotPassword"),
    resetPassword: userApiUrl("User/ResetPassword"),
    changePassword: userApiUrl("User/ChangePassword"),
    getUserEmailByUserID: userApiUrl("User/GetUserEmailById"),
    generatePasswordResetTokenByEmail: userApiUrl("User/GeneratePasswordResetTokenByEmail"),
    updateSignature: userApiUrl("User/UpdateSignature"),
    validateEmail: userApiUrl("User/ValidateEmail"),
    validatePin: userApiUrl("User/ValidatePin"),
    getUsersByRoles: userApiUrl("User/GetUsersByRoles"),
}