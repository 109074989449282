import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseDocumentUploadComponent } from '../base-document-upload/base-document-upload.component';
import { Buffer } from 'buffer';
import { selectDocumentUploadState } from '../../store/document-upload-store/document-upload.selector';
import { DocumentUploadState } from '../../store/document-upload-store/document-upload.state';
import { resetDocumentUploadState } from '../../store/document-upload-store/document-upload.reducer';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent extends BaseDocumentUploadComponent implements OnInit, OnDestroy {

  @Input() filename!: string;
  @Input() isdisableSignButton!: boolean;

  @Output() onSignEvent: EventEmitter<{ base64Value: string | null, documentGuid: string | null }> = new EventEmitter<{ base64Value: string | null, documentGuid: string | null }>();
  @Output() disableSignButton = new EventEmitter<boolean>();

  private signaturePad!: SignaturePad;
  @ViewChild('signaturePad', { static: true }) signaturePadElement!: ElementRef;

  public value = '';
  public strokeWidth = 4;
  public isSaving: boolean = false;
  public isdisabledSignatuePad: boolean = false;
  documentGuid: string = '';

  documentUploadInfo$ = this.store.select(selectDocumentUploadState);

  override  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.initializeSignaturePad();
    this.subscriptions$.add(this.documentUploadInfo$.subscribe((data: DocumentUploadState) => this.bindDocumentInformation(data)));
  }

  bindDocumentInformation(data: DocumentUploadState) {
    if (data) {
      if (data.documents.length > 0) {
        if (data.documents[0].progress === 100
          && data.documents[0].completed
          && data.documents[0].document?.name === this.filename
        ) {
          this.isSaving = false;
          this.documentGuid = data.documents[0]?.documentUploadResponse?.documentGuid ?? '';
          this.isdisabledSignatuePad = true;
          this.signaturePad.off();
          const eventData = {
            base64Value: this.value,
            documentGuid: this.documentGuid
          };
          this.disableSignButton.emit(false);
          this.onSignEvent.emit(eventData);
        }
      }
    }
  }

  public onSave() {
    if (this.signaturePad.isEmpty()) {
      this.value = '';
      const eventData = {
        base64Value: null,
        documentGuid: null
      };
      this.onSignEvent.emit(eventData);
      return;
    }
    this.value = this.signaturePad.toDataURL();
    this.disableSignButton.emit(true);
    this.store.dispatch(resetDocumentUploadState());
    this.isSaving = true;
    this.signaturePad.off();
    this.isdisabledSignatuePad = true;
    this.base64ToFile(this.value, this.filename);
  }

  public onClear() {
    this.documentGuid = '';
    this.value = '';
    this.signaturePad.on();
    this.isdisabledSignatuePad = false;
    const eventData = {
      base64Value: null,
      documentGuid: null
    };
    this.signaturePad.clear();
    this.disableSignButton.emit(false);
    this.onSignEvent.emit(eventData);
  }

  base64ToFile(base64: string, filename: string) {
    const arr = base64.split(',');
    if (arr[0]) {
      const mimeMatch = arr[0].match(/:(.*?);/);
      const mime = mimeMatch ? mimeMatch[1] : '';
      const base64Data = arr[1];
      const binaryData = Buffer.from(base64Data, 'base64');
      const blob = new Blob([binaryData], { type: mime });
      const file: File = new File([blob], filename, { type: 'image/jpeg' });
      this.uploadFile(file);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.resizeCanvas();
  }

  private initializeSignaturePad(): void {
    const canvas = this.signaturePadElement.nativeElement;
    if (this.signaturePad) {
      this.signaturePad.clear();
      this.signaturePad.off();
    }
    this.signaturePad = new SignaturePad(canvas, {
      throttle: 16,
      minWidth: 2,
      maxWidth: 2,
    });
    this.resizeCanvas();
  }

  private resizeCanvas(): void {
    const canvas = this.signaturePadElement.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const existingData = this.signaturePad.isEmpty() ? null : this.signaturePad.toDataURL();
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    if (existingData) {
      this.signaturePad.fromDataURL(existingData);
    }
  }

}
