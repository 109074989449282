import { createReducer, on } from '@ngrx/store';
import { PhoneCallState } from './phone-call.state';
import { closeErrorAlert, closeSuccessAlert, dialPhoneCall, dialPhoneCallFailure, dialPhoneCallSuccess, getRecentCallFailureLog,
   getRecentCallSuccessLog, setDialPadPhoneNumber, invokeGetRecentCallLog, phoneCallsStopLoading, resetPhoneCallState, resetDialPadPhoneNumber, setonCallInprogressPhoneNumber, resetonCallInprogressPhoneNumber, resetdialPhoneCall } from './phone-call.action';

const initialState: PhoneCallState = {
  phoneCallStateEvent: {
    DailerStatus: '',
    CallStatus: '',
    loading: false,
  },
  recentCallLogs: [],
  onCallInprogressphoneNumber: ''
};

export const phoneCallReducer = createReducer(
  initialState,
  on(dialPhoneCall, (state, action): PhoneCallState => ({
    ...state,
    dialCallInfo: {
      ...state.dialCallInfo, 
      numberToDial:action.dialCallInfo?.numberToDial??'',
      practiceLocationId: action.dialCallInfo?.practiceLocationId??0,
      practiceId: action.dialCallInfo?.practiceId??0,
      practiceLocationPhoneNumber: action.dialCallInfo?.practiceLocationPhoneNumber??'',
      callerId: action.dialCallInfo?.callerId??'',
      callerIdName: action.dialCallInfo?.callerIdName??'',
      token: '',
      webSocketConnections: [],
      isInvoke :true
    },
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: true
    } 
  })),
  on(dialPhoneCallSuccess, (state, action):PhoneCallState => ({
    ...state,
    dialCallInfo: {
      ...state.dialCallInfo, 
      numberToDial:state.dialCallInfo?.numberToDial??'',
      practiceLocationId: state.dialCallInfo?.practiceLocationId??0,
      practiceId: state.dialCallInfo?.practiceId??0,
      practiceLocationPhoneNumber: state.dialCallInfo?.practiceLocationPhoneNumber??'',
      callerId: state.dialCallInfo?.callerId??'',
      callerIdName: state.dialCallInfo?.callerIdName??'',
      token: action.dialCallToken?.token,
      webSocketConnections: action.dialCallToken?.webSocketConnections,
      isInvoke :false
    },
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: false,
      toastMessage: action.successMessage,
      showSuccessToast: true
    } 
  })),
  on(dialPhoneCallFailure, (state, action):PhoneCallState => ({
    ...state,
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: false,
      errorMessage: action.error.message,
      showErrorAlert: true
    } 
  })),
  on(phoneCallsStopLoading, (state):PhoneCallState => ({
    ...state,
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: false,
    }
  })),
  on(closeErrorAlert, (state):PhoneCallState => ({
    ...state,
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: false,
      showErrorAlert:false
    }
  })),
  on(closeSuccessAlert, (state):PhoneCallState => ({
    ...state,
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: false,
      showSuccessAlert: false,
      toastMessage: undefined,
    }
  })),
  on(setDialPadPhoneNumber, (state, action): PhoneCallState => ({
    ...state,
    activeContactCallEvent: action.activeContactCallEvent
  })),
  on(resetDialPadPhoneNumber, (state, action): PhoneCallState => ({
    ...state,
    activeContactCallEvent: initialState.activeContactCallEvent
  })), 
  on(invokeGetRecentCallLog, (state):PhoneCallState => ({
    ...state,
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: true,
      showSuccessAlert: false,
    }
  })),
  on(getRecentCallSuccessLog, (state,action):PhoneCallState => ({
    ...state,
    recentCallLogs: action.recentCallLogs,
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      loading: false,
      showSuccessAlert: true,
      toastMessage: undefined,
    }
  })),
  on(getRecentCallFailureLog, (state):PhoneCallState => ({
    ...state,
    phoneCallStateEvent: {
      ...state.phoneCallStateEvent,
      showSuccessAlert: false,
      showErrorAlert: true,
      toastMessage: undefined,
    }
  })),
  on(setonCallInprogressPhoneNumber, (state, action): PhoneCallState => ({
    ...state,
    onCallInprogressphoneNumber: action.onCallInprogressphoneNumber
  })),
  on(resetonCallInprogressPhoneNumber, (state): PhoneCallState => ({
    ...state,
    onCallInprogressphoneNumber: ''
  })),
  on(resetdialPhoneCall, (state): PhoneCallState => ({
    ...state,
    dialCallInfo: initialState.dialCallInfo
  })),
  on(resetPhoneCallState, () => initialState)
);