import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { selectedPracticeId } from 'src/app/theme/store/menu/menu.actions';
import { selectPracticesList } from '../../practice/store/selector/practice-info.selector';
import { PracticePersonalInformation } from '../../practice/store/state/practice-info';
import { applicationStorageKeys } from '../../user/constant/user-role-constant';

@Injectable({
    providedIn: 'root'
})

export class AppSessionStorageService {

    private subjects = new Map<string, BehaviorSubject<string>>();

    practices: PracticePersonalInformation[] = [];

    private subscriptions$ = new Subscription();
    selectPracticesList$ = this.store.select((selectPracticesList));

    constructor(private store: Store) {
        this.subscriptions$.add(this.selectPracticesList$.subscribe((data: PracticePersonalInformation[]) => this.bindPractices(data)));

        window.addEventListener('storage', (event: StorageEvent) => {
            const key = event.key;
            if (key && this.subjects.has(key)) {
                this.subjects.get(key)!.next(event.oldValue!);
            }
        });
    }

    getItem(key: string): string {
        return sessionStorage.getItem(key) || '';
    }

    setItem(key: string, value: string): void {
        sessionStorage.setItem(key, value);
        if (this.subjects.has(key)) {
            this.subjects.get(key)!.next(value);
        } else {
            this.subjects.set(key, new BehaviorSubject<string>(value));
        }
        if (key === applicationStorageKeys.selectedPracticeIdBySA && this.practices.length > 0) {
            this.store.dispatch(selectedPracticeId({ practiceId: Number(value.toString()) }));
            this.setPracticeGuidInSessionStorage();
        }
    }
    
    getObservable(key: string): Observable<string> {
        if (!this.subjects.has(key)) {
            this.subjects.set(key, new BehaviorSubject<string>(sessionStorage.getItem(key)!));
        }
        return this.subjects.get(key)!.asObservable();
    }

    bindPractices(data: PracticePersonalInformation[]) {
        if (data && data.length > 0) {
            this.practices = data;
            this.setPracticeGuidInSessionStorage();
        }
    }

    setPracticeGuidInSessionStorage() {
        const practiceId = sessionStorage.getItem(applicationStorageKeys.selectedPracticeIdBySA)
        const selectedPractice = this.practices.find(x => x.practiceId === Number(practiceId));
        if (selectedPractice) {
            sessionStorage.setItem(applicationStorageKeys.selectedPracticeGuidBySA, selectedPractice.practiceGuid!);
        }
    }

    clear() {
        sessionStorage.clear();
    }
}