import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUser, Tokens, ValidUserInfo } from '../store/state/auth-user';
import { userApiAssert } from '../constant/user-api-asset';

@Injectable({
	providedIn: 'root'
})

export class AuthenticationAPIService {

	constructor(private http: HttpClient) { }

	login(username: string, password: string) {
		return this.http.post<AuthUser>(userApiAssert.login, { username, password });
	}

	validateEmail(emailAddress: string) {
		return this.http.post<ValidUserInfo>(userApiAssert.validateEmail, { emailAddress });
	}

	logout() {
		return this.http.post(userApiAssert.logout, {}, { responseType: 'text' });
	}

	refreshToken(accessToken: string, refreshToken: string) {
		return this.http.post<Tokens>(userApiAssert.refreshToken, { accessToken, refreshToken });
	}
}