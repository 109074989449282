import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DraggableComponent {
  private pos1 = 0;
  private pos2 = 0;
  private pos3 = 0;
  private pos4 = 0;
  private isDragging = false;

  constructor() {}

  enableDrag(elementRef: ElementRef) {
    const element = elementRef.nativeElement.querySelector('#draggable-div');
    element.addEventListener('pointerdown', (event: PointerEvent) => this.onPointerDown(event));
    document.addEventListener('pointerup', () => this.onPointerUp());
    document.addEventListener('pointermove', (event: PointerEvent) => this.onPointerMove(event, element));
  }

  private onPointerDown(event: PointerEvent) {
    event.preventDefault();
    this.pos3 = event.clientX;
    this.pos4 = event.clientY;
    this.isDragging = true;
  }

  private onPointerUp() {
    this.isDragging = false;
  }

  private onPointerMove(event: PointerEvent, element: HTMLElement) {
    if (!this.isDragging) return;

    event.preventDefault();

    const clientX = event.clientX;
    const clientY = event.clientY;

    this.elementDrag(clientX, clientY, element);
  }

  private elementDrag(clientX: number, clientY: number, element: HTMLElement) {
    this.pos1 = this.pos3 - clientX;
    this.pos2 = this.pos4 - clientY;
    this.pos3 = clientX;
    this.pos4 = clientY;

    let newTop = element.offsetTop - this.pos2;
    let newLeft = element.offsetLeft - this.pos1;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const elementWidth = element.offsetWidth;
    const elementHeight = element.offsetHeight;

    if (newTop < 0) newTop = 0;
    if (newLeft < 0) newLeft = 0;
    if (newTop + elementHeight > windowHeight) newTop = windowHeight - elementHeight;
    if (newLeft + elementWidth > windowWidth) newLeft = windowWidth - elementWidth;

    element.style.top = newTop + 'px';
    element.style.left = newLeft + 'px';
  }
}
