import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentState } from './document-download.reducer';

export const selectDocumentState = createFeatureSelector<DocumentState>('documentDownloadStore');

export const selectDocumentsDownloadInfo = createSelector(
  selectDocumentState,
  (state: DocumentState) => state.documentsDownloadInfo
);

export const selectDocumentData = (documentGuid: string) => createSelector(
  selectDocumentState,
  (state: DocumentState) => {
    const documentDownloadInfo = state.documentsDownloadInfo?.find(info => info.downloadedDocumentGuid === documentGuid);
    return documentDownloadInfo ? documentDownloadInfo.documentData : null;
  }
);

export const selectdocumentDownloadInfoState = createSelector(
  selectDocumentState,
  (state: DocumentState) => state.documentDownloadInfoStateEvent
);