import { createReducer, on } from '@ngrx/store';
import { ChatState } from './chat.state';
import {
  addPatientChatFromSignalR, addRecentPatientChatFromSignalR, chatSendSms, chatSentSmsSuccess, chatStopLoading, closeErrorAlert, closeSuccessAlert,
  getPatientByPhoneSuccess,
  getUnreadPatientChatCollectionSuccess,
  handlePatientChatFailure,
  invokeGetPatientByPhone,
  invokeGetUnreadPatientChatCollection,
  invokeMarkAsRead,
  invokePatientChatCollection, invokeRecentPatientChatCollection,
  markAsReadSuccess,
  patientChatCollectionSuccess, recentPatientChatCollectionSuccess,
  resetChatState,
  resetChosenPatientChat,
  resetChosenPatientChatState,
  resetPatientChatFromMarkAsReadSignalR,
  resetPatientChatFromSignalR,
  updatePatientChatFromMarkAsReadSignalR
} from './chat.action';
import { communicationStatusMessage } from '../../constant/patient-communication.constant';
const initialState: ChatState = {
  chatStateEvent: {
    loading: false,
    patientChatloading: false,
    patientId: 0,
    patientPhone: '',
    phoneNumberSearchloading: false,
    chatLogloading: false,
  },
  recentPatientChats: [],
  patientChatCollections: [],
  trackingPatientChat: null,
  chatReadEvent: null,
  unreadPatientsChatCollection: []
};

export const chatReducer = createReducer(
  initialState,
  on(invokePatientChatCollection, (state,action): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      patientChatloading: true,
      patientId: action.patientId,
      patientPhone: action.patientPhone,
    }
  })),
  on(invokeRecentPatientChatCollection, (state): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      loading: true,
      chatLogloading: true,
    }
  })),
  on(invokeGetPatientByPhone, (state): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      phoneNumberSearchloading: true,
    }
  })),
  on(patientChatCollectionSuccess, (state, action): ChatState => {
    return {
      ...state,
      patientChatCollection: {
        ...action.patientChatCollection,
        patientId: action.patientId,
        patientPhone: action.patientPhone,
      },
      chatStateEvent: {
        ...state.chatStateEvent,
        patientChatloading: false,
        patientId: action.patientId,
        patientPhone: action.patientPhone,
      }
    };
  }),
  on(addPatientChatFromSignalR, (state, { patientChat }): ChatState => {
    return {
      ...state,
      chatStateEvent: {
        ...state.chatStateEvent,
        patientId: patientChat.patientId,
        patientPhone: patientChat.patientPhone,
      },
      trackingPatientChat: patientChat
    };
  }),
  on(resetPatientChatFromSignalR, (state, { patientChat }): ChatState => {
    return {
      ...state,
      chatStateEvent: {
        ...state.chatStateEvent,
        patientId: 0,
        patientPhone: '',
      },
      trackingPatientChat: null
    };
  }),
  on(recentPatientChatCollectionSuccess,
    (state, { recentPatientChatCollection }): ChatState => ({
      ...state,
      recentPatientChats: recentPatientChatCollection,
      chatStateEvent: {
        ...state.chatStateEvent,
        loading: false,
        chatLogloading: false,
      }
    })
  ),
  on(chatSendSms, (state, { communicationSms, patientChat }): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      patientId: communicationSms.patientId,
      patientPhone: communicationSms.toNumber,
    }
  })),
  on(chatSentSmsSuccess, (state, { communicationsStatus, patientChat }): ChatState => {
    if (communicationsStatus.message === communicationStatusMessage.Success) {
      if (
        state.chatStateEvent.patientId === patientChat.patientId ||
        state.chatStateEvent.patientPhone === patientChat.patientPhone
      ) {
        return {
          ...state,
          chatStateEvent: {
            ...state.chatStateEvent,
            patientId: patientChat.patientId,
            patientPhone: patientChat.patientPhone,
          },
        };
      }
    }
    return { ...state };
  }),
  on(getPatientByPhoneSuccess, (state, { patient, patientPhone }): ChatState => {
    const activePatient = patient?.[0];
    if (activePatient?.patientId) {
      return {
        ...state,
        chosenPatientChat: { ...activePatient, patientPhone:patientPhone, 
          //patientChatCollection: [] 
        },
        chatStateEvent: {
          ...state.chatStateEvent,
          loading: false,
          phoneNumberSearchloading : false
        }
      };
    }
    else
    {
       return {
         ...state,
         chosenPatientChat: {
           patientId: 0,
           patientPhone: patientPhone,
           patientFirstName: 'Patient',
           patientLastName: 'Potential, ',
           patientChatCollection: []
         },
         chatStateEvent: {
           ...state.chatStateEvent,
           loading: false,
           phoneNumberSearchloading: false
         }
       }
    }
  }),
  on(resetChosenPatientChat, (state): ChatState => ({
    ...state,
    chosenPatientChat: undefined
  })),
  on(handlePatientChatFailure, (state,{error}): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      loading: false,
      showErrorAlert: true,
      errorMessage: error,
      patientChatloading: false,
      phoneNumberSearchloading: false,
      chatLogloading: false
    }
  })),
  on(chatStopLoading, (state): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      loading: false,
    }
  })),
  on(closeErrorAlert, (state): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      loading: false,
      showErrorAlert: false
    }
  })),
  on(closeSuccessAlert, (state): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      loading: false,
      showSuccessAlert: false,
      toastMessage: undefined,
    }
  })),
  on(resetChosenPatientChatState, (state): ChatState => ({
    ...state,
    chosenPatientChat: initialState.chosenPatientChat
  })),
  on(resetChatState, () => initialState),
  on(addRecentPatientChatFromSignalR, (state, { patientChat }) => {
    const existingChatIndex = state.recentPatientChats.findIndex(chat => chat.primaryPhone === patientChat.primaryPhone);
    const updatedChats = existingChatIndex !== -1
      ? [
        patientChat,
        ...state.recentPatientChats.slice(0, existingChatIndex),
        ...state.recentPatientChats.slice(existingChatIndex + 1)
      ]
      : [patientChat, ...state.recentPatientChats];
    return {
      ...state,
      recentPatientChats: updatedChats
    };
  }),
  on(invokeMarkAsRead, (state, { chatReadEvent }): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      patientId: chatReadEvent.patientId,
      patientPhone: chatReadEvent.phoneNumber,
    }
  })),
  on(markAsReadSuccess, (state, { communicationsStatus, chatReadEvent }): ChatState => {
    if (communicationsStatus.message === communicationStatusMessage.Success) {
      if (
        state.chatStateEvent.patientId === chatReadEvent.patientId ||
        state.chatStateEvent.patientPhone === chatReadEvent.phoneNumber
      ) {
        return {
          ...state,
          chatStateEvent: {
            ...state.chatStateEvent,
            patientId: chatReadEvent.patientId,
            patientPhone: chatReadEvent.phoneNumber,
          },
        };
      }
    }
    return { ...state };
  }),
  on(updatePatientChatFromMarkAsReadSignalR, (state, { markAsRead }): ChatState => ({
    ...state,
    chatReadEvent: markAsRead
  })),
  on(resetPatientChatFromMarkAsReadSignalR, (state): ChatState => ({
    ...state,
    chatReadEvent: initialState.chatReadEvent
  })),


  on(invokeGetUnreadPatientChatCollection, (state): ChatState => ({
    ...state,
    chatStateEvent: {
      ...state.chatStateEvent,
      chatLogloading: true
    },
  })),

  on(getUnreadPatientChatCollectionSuccess, (state, { unreadPatientsChatCollection }): ChatState => ({
    ...state,
    unreadPatientsChatCollection: unreadPatientsChatCollection,
    chatStateEvent: {
      ...state.chatStateEvent,
      chatLogloading: false
    },
  })),

);