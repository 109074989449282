import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PatientCommunication } from '../constant/patient-communication.constant';

@Injectable({
  providedIn: 'root'
})
export class PatientCommunicationActionService {

  private activePatientCommunicationPopups: PatientCommunication[] = [];

  private fromChatPatientSearchSubject: Subject<boolean> = new Subject<boolean>();
  private onCallClickSubject: Subject<PatientCommunication> = new Subject<PatientCommunication>();
  private onChatClickSubject: Subject<PatientCommunication> = new Subject<PatientCommunication>();
  private patientCommunicationsSubject: Subject<PatientCommunication[]> = new Subject<PatientCommunication[]>();
  private onRemovePatientCommunicationSubject: Subject<number> = new Subject<number>();
  private onAddPatientCommunicationSubject: Subject<PatientCommunication> = new Subject<PatientCommunication>();

  fromChatPatientSearch(event: boolean): void {
    this.fromChatPatientSearchSubject.next(event);
  }

  get fromChatPatientSearch$() {
    return this.fromChatPatientSearchSubject.asObservable();
  }

  onCallClick(event: PatientCommunication): void {
    this.onCallClickSubject.next(event);
  }

  get onCallClick$() {
    return this.onCallClickSubject.asObservable();
  }

  onChatClick(event: PatientCommunication): void {
    this.onChatClickSubject.next(event);
  }

  get onChatClick$() {
    return this.onChatClickSubject.asObservable();
  }

  patientCommunications(event: PatientCommunication[]): void {
    this.patientCommunicationsSubject.next(event);
  }
  get patientCommunications$() {
    return this.patientCommunicationsSubject.asObservable();
  }

  onRemovePatientCommunication(index: number): void {
    this.activePatientCommunicationPopups.splice(index, 1);
    this.patientCommunicationsSubject.next(this.activePatientCommunicationPopups);
    this.onRemovePatientCommunicationSubject.next(index);
  }
  get onRemovePatientCommunication$() {
    return this.onRemovePatientCommunicationSubject.asObservable();
  }

  onAddPatientCommunication(event: PatientCommunication): void {
    this.activePatientCommunicationPopups.push(event);
    this.patientCommunicationsSubject.next(this.activePatientCommunicationPopups);
    this.onAddPatientCommunicationSubject.next(event);
  }

  get onAddPatientCommunication$() {
    return this.onAddPatientCommunicationSubject.asObservable();
  }

  getActivePatientCommunicationPopups(): PatientCommunication[] {
    return this.activePatientCommunicationPopups;
  }

  resetActivePatientCommunicationPopups() {
    this.activePatientCommunicationPopups = [];
  }
}