import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class DebounceService {
  private debounceTimers: { [key: string]: NodeJS.Timeout } = {};

  constructor() {}

  debounce(key: string, callback: () => void, debounceTime: number = 500) {
    clearTimeout(this.debounceTimers[key]);
    this.debounceTimers[key] = setTimeout(callback, debounceTime);
  }
}