import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() name: string = '';
  @Input() imageUrl: string = '';
  @Input() initialsCount: number = 1;
  @Input() size: string = '';

  get initials(): string {
    const nameParts = this.name.trim().split(' ');
    let initials = nameParts.map(part => part[0].toUpperCase()).join('');
    return initials.substring(0, this.initialsCount);
  }

  get avatarSize(): string {
    const sizeMap: { [key: string]: string } = {
      xs: '30px',
      sm: '35px',
      md: '45px',
      lg: '50px',
      xl: '60px'
    };

    return sizeMap[this.size] || '40px';
  }
}
