import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
    transform(inputDateTime: string): string {
        if (!inputDateTime)
            return "";
    
        const datetime = new Date(inputDateTime);
        if (isNaN(datetime.getDate())) {
            return "Invalid date";
        }
        const month = ('0' + (datetime.getMonth() + 1)).slice(-2);
        const day = ('0' + datetime.getDate()).slice(-2);
        const year = datetime.getFullYear();
        const hours = ('0' + ((datetime.getHours() + 11) % 12 + 1)).slice(-2); 
        const minutes = ('0' + datetime.getMinutes()).slice(-2);
        const ampm = datetime.getHours() >= 12 ? 'PM' : 'AM';
    
        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
    }
}