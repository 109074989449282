import { createAction, props } from '@ngrx/store';
import { AuthUser, ValidUserInfo } from '../state/auth-user';

const TYPE = '[Auth]';

export const logIn = createAction(`${TYPE} Login`, props<{ username: string, password: string, loginLocationId: number, returnUrl:string  }>());

export const logInSuccess = createAction(`${TYPE} Login Success`, props<{ authUser: AuthUser }>());

export const logInFailure = createAction(`${TYPE} Login Failure`, props<{ error: string }>());

export const logout = createAction(`${TYPE} Logout`);

export const logoutSuccess = createAction(`${TYPE} Logout Success`);

export const logoutFailure = createAction(`${TYPE} Logout Failure`, props<{ error: string }>());

export const validateEmail = createAction(`${TYPE} Validate Username email`, props<{ username: string }>());

export const validateEmailSuccess = createAction(`${TYPE} Validate Email Success`, props<{ user: ValidUserInfo }>());

export const validateEmailFailure = createAction(`${TYPE} Validate Email Failure`, props<{ error: string }>());

export const resetErrorMessage = createAction(`${TYPE} resetErrorMessage`);

