import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectUSStateErrorMessage, selectUSStateList } from '../../../store/core-lookup-store/core-lookup.selector';
import { invokeGetStates } from '../../../store/core-lookup-store/core-lookup.reducer';
import { CountryState } from '../../../store/core-lookup-store/core-info';

@Component({
  selector: 'app-us-states-dropdown',
  template: `
            <div class="form-group" [class.has-error]="hasError()">
              <label *ngIf="label">{{ label }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>

              <ng-select 
                [items]="usStates$ | async" 
                [closeOnSelect]="true"
                [searchable]="true" 
                bindLabel="stateName" 
                placeholder="Select State"
                bindValue='stateName' 
                [ngModel]="updatedValue"
                [disabled]="disabledField || disabled" 
                [clearable]="clearable"

                (ngModelChange)="selectBoxChanged($event)"
                (blur)="onTouched()"
              >
              </ng-select>

              <div class="validation_msg" *ngIf="errorMessage$ | async" >
                <span> error : {{errorMessage$ | async}}</span>
              </div>

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="errorMessage ? errorMessage :  label">
                </app-validation-error>
              </ng-container>

            </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UsStatesDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UsStatesDropdownComponent),
      multi: true
    }
  ],
})
export class UsStatesDropdownComponent implements ControlValueAccessor, Validator, OnInit {

  @Input() label?: string;
  @Input() required?: boolean | false;
  @Input() disabledField!: boolean | false;
  @Input() clearable!: boolean;
  @Input() errorMessage?: string;


  control: AbstractControl | undefined;
  updatedValue: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled!: boolean;

  usStates$: any = this.store.select(selectUSStateList);
  errorMessage$: any = this.store.select(selectUSStateErrorMessage);

  constructor(private store: Store) { this.clearable = true; }

  ngOnInit(): void {
    this.store.dispatch(invokeGetStates());
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.updatedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeEvent(event: any) {
    this.control?.markAsTouched();
    this.onChange(event)
  }

  selectBoxChanged(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }
}