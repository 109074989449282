import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DocumentAPIService } from '../../api/document-api.service';
import * as documentDownloadAction from './document-download.reducer';

@Injectable()
export class DocumentDownloadEffects {
  constructor(private actions$: Actions, private documentService: DocumentAPIService) { }

  getImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentDownloadAction.getImage),
      mergeMap((action) =>
        this.documentService.downloadDocument(action.documentGuid).pipe(
          map((response) => {
            const file = new Blob([response], { type: 'image/*' });
            const url = URL.createObjectURL(file);
            return documentDownloadAction.documentDownloaded({ documentData: url, downloadedDocumentGuid: action.documentGuid });
          }),
          catchError(() => {
            return of(documentDownloadAction.documentDownloaded({ documentData: 'imageNotFound', downloadedDocumentGuid: action.documentGuid }));
          })
        )
      )
    )
  });

  downloadDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentDownloadAction.documentDownload),
      mergeMap((action) =>
        this.documentService.downloadDocument(action.documentGuid).pipe(
          map((response) => {
            const file = new Blob([response], { type: 'application/octet-stream' }); // Adjust the MIME type according to your file type
            const url = URL.createObjectURL(file);
            const downloadedFileName = action.fileName ?? "document";

            const link = document.createElement('a') as HTMLAnchorElement;
            link.href = url;
            link.download = downloadedFileName;
            link.click();

            URL.revokeObjectURL(url);
            link.remove();
            return documentDownloadAction.documentDownloaded({ documentData: url, downloadedDocumentGuid: action.documentGuid });
          }),
          catchError((error: any) =>
            of(documentDownloadAction.documentDownloadFailed({ erroeMessage: error }))
          )
        )
      )
    )
  }
  );

  getImageForOnlineSchedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentDownloadAction.getImageForUnAuthurizedUser),
      mergeMap((action) =>
        this.documentService.downloadDocumentForUnAuthurizedUser(action.documentGuid).pipe(
          map((response) => {
            const file = new Blob([response], { type: 'image/*' });
            const url = URL.createObjectURL(file);
            return documentDownloadAction.documentDownloaded({ documentData: url, downloadedDocumentGuid: action.documentGuid });
          }),
          catchError((error: any) =>
            of(documentDownloadAction.documentDownloadFailed({ erroeMessage: error }))
          )
        )
      )
    )
  }
  );
}