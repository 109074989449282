import { Injectable } from '@angular/core';
import { DateUtilService } from './date-util.service';

@Injectable({
  providedIn: 'root',
})
export class AgeCalculatorService {
  constructor(private dateUtilService: DateUtilService) {}

  calculateAge(date: Date): { years: number, months: number } {
    const now = this.dateUtilService.GetApplicationCurrentDateTime();

    const nowMonth = now.getMonth() + 1; // months from 1-12
    const nowDay = now.getDate();
    const nowYear = now.getFullYear();

    const dob = new Date(date);
    const myMonthBirth = dob.getMonth() + 1;
    const myDayBirth = dob.getDate();
    const myYearBirth = dob.getFullYear();

    let years = nowYear - myYearBirth; // not your age yet
    let months = nowMonth - myMonthBirth;

    if (nowMonth < myMonthBirth || (nowMonth === myMonthBirth && nowDay < myDayBirth)) {
      years -= 1;
      months += 12;
    }
    
    if (nowDay < myDayBirth) {
      months -= 1;
    }

    return { years, months };
}

  isPatientBelowEighteen(inputDate: string) {
    if(!inputDate) return true;
    const date = new Date(inputDate);
    const patientAge =  this.calculateAge(date);
    const isPatientBelowEighteen = patientAge.years < 18;
    return isPatientBelowEighteen;
  }

}
