import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor,
  NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';

@Component({
  selector: 'app-form-control-radio',
  template: `
            <div class="form-group">

              <label  *ngIf="labelName">{{ labelName }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>

              <div>
                <div class="radio d-inline radio-success radio-infill" *ngFor="let option of options">
                  <input 
                    type="radio" 
                    [name]="formControlName" 
                    [checked]="option[bindValue] === this.value"
                    id="{{formControlName}}_{{option[bindLabel]}}{{uniqueId}}"
                    [disabled]="disabled"
                    [value]="option[bindValue]"

                    (change)="onRadioBoxChange($event)" 
                    (blur)="onTouched()"
                  />
                  <label for="{{formControlName}}_{{option[bindLabel]}}{{uniqueId}}" class="cr">{{option[bindLabel]}}</label>
                  <span class="badge bg-success badge-top ms-2 text-white" *ngIf="showCount">{{option.count}}</span>
                </div>
              </div>

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="errorMessage ? errorMessage : labelName">
                </app-validation-error> 
              </ng-container>
              
            </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlRadioButtonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlRadioButtonComponent),
      multi: true
    }
  ],
})
export class FormControlRadioButtonComponent implements ControlValueAccessor, Validator {

  @Input() formControlName?: string;
  @Input() labelName?: string;
  @Input() required?: boolean | false;
  @Input() options!: any;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() bindText: string;
  @Input() uniqueId?: number;
  @Input() errorMessage!: string;
  @Input() showCount?: boolean | false;
  @Output() radioChangeEvent = new EventEmitter<any>();

  value: any;
  control: AbstractControl | undefined;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled: boolean;

  constructor() {
    this.bindLabel = '';
    this.bindValue = '';
    this.disabled = false;
    this.value = '';
    this.bindText = '';
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onRadioBoxChange(event: any) {
    this.value = (event.target as HTMLInputElement).value;
    this.onChange(this.value);
    this.control?.markAsTouched();
    this.radioChangeEvent.emit(this.value);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }
}