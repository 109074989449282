import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { selectPracticeLiveStatusLocation } from '../store/patient-live-status-store/patient-live-status.selector';
import { PracticeLocation } from '../../scheduler/store/scheduler-store/scheduler-state';
import { PatientLiveStatusService } from './patient-live-status.service';
import { locationGroupSubscribe, locationGroupUnsubscribe, practiceGroupSubscribe, practiceGroupUnsubscribe } from 'src/app/infrastructure/signalr/store/signalr.action';
import { AuthService } from '../../user/services/authentication/auth.service';


@Injectable()
export class PatientLiveStatusSubscriptionService {

  private practiceLocation$ = this.store.select(selectPracticeLiveStatusLocation);
  private subscriptions$ = new Subscription();
  private practiceLocations: PracticeLocation[] = [];

  constructor(
    private store: Store,
    private patientLiveStatusService: PatientLiveStatusService,
    private authService : AuthService

  ) {
    this.subscribeAndHandleUpdates(this.practiceLocation$, this.getPracticeLocation.bind(this));
  }

  private subscribeAndHandleUpdates<T>(observable: Observable<T>, updateFunction: (data: T) => void) {
    this.subscriptions$.add(observable.subscribe(updateFunction));
  }

  private getPracticeLocation(locations: PracticeLocation[]) {
    if (locations) {
      if (locations.length === 0) return;
      this.practiceLocations = locations;
    }
  }

  manageSignalRLocationSubscribtion(locationId: number, subscribe: boolean) {
    const selectedLocation = this.practiceLocations.find(location => location.practiceLocationId === locationId);
    if (selectedLocation?.practiceLocationGuid) {
      if (!subscribe) {
        this.store.dispatch(locationGroupUnsubscribe({locationGuid: selectedLocation.practiceLocationGuid}))
        this.store.dispatch(practiceGroupUnsubscribe({practiceGuid: this.authService.getUserPracticeGUIDIdFromToken()}))
      } else {
        this.store.dispatch(locationGroupSubscribe({location:selectedLocation}))
        this.store.dispatch(practiceGroupSubscribe({practiceGuid:this.authService.getUserPracticeGUIDIdFromToken()}))
      }
    }
  }

  unsubscribeSignalRLocation() {
    let locationId = this.patientLiveStatusService.getLocationId();
    this.manageSignalRLocationSubscribtion(locationId, false)
  }

}