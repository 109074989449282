export const sharedFeaturePermission = [
    {
      "moduleName": "",
      "featurePermissions": [
        {
          "featureName": "Patient Search",
          "operations": ["Create"]
        },
        {
          "featureName": "Patient Information Feature",
          "operations": ["View"]
        }        
      ]
    }
  ]