import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PracticeCoreInfo } from "../state/practice-core";

export const selectPracticeCoreInfo = createFeatureSelector<PracticeCoreInfo>('practiceCoreStore');

export const selectPracticeProviders = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practiceProviderCoreInfo?.practiceProviders || [];
  }
);

export const selectProvidersErrorMessage = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practiceProviderCoreInfo?.coreStateEvent?.errorMessage;
});

export const selectLocations = createSelector(
  selectPracticeCoreInfo, coreState => {
    const locations = coreState?.practiceLocationsCoreInfo?.practiceLocations
    if (!locations) return [];
    return locations.filter(location => location.practiceLocationName !== '');
  }
);

export const selectLocationStateEvent = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practiceLocationsCoreInfo?.coreStateEvent;
});


export const selectPracticePersonalCoreInfo = createSelector(
  selectPracticeCoreInfo, coreState =>
      coreState?.practicePersonalCoreInfo?.practicePersonalInformation
);

export const selectPracticeInfoErrorMessage = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practicePersonalCoreInfo?.coreStateEvent?.errorMessage;
});

export const selectPracticeAppointmentTypes = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practiceAppointmentTypeCoreInfo?.practiceAppointmentTypes || [];
  }
);

export const selectPracticeLetterTemplates = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practiceLetterTemplateCoreInfo?.practiceLetterTemplates || [];
  }
);

export const selectAppointmentTypesErrorMessage = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practiceAppointmentTypeCoreInfo?.coreStateEvent?.errorMessage;
});

export const selectLetterTemplatesErrorMessage = createSelector(
  selectPracticeCoreInfo, coreState => {
    return coreState?.practiceLetterTemplateCoreInfo?.coreStateEvent?.errorMessage;
});

export const selectPracticeCurrentDateTime = createSelector(
  selectPracticeCoreInfo,
  state => state.practiceCurrentDateTime
)