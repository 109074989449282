import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { SharedFeatureModule } from './shared-feature.module';
import { FormsModule } from '@angular/forms';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopupService } from '@progress/kendo-angular-popup';
import { PopupModule } from '@progress/kendo-angular-popup';

import { FormControlDatepickerComponent } from './components/form-controls/form-control-datepicker/form-control-datepicker.component';
import { FormControlDateRangeComponent } from './components/form-controls/form-control-daterange/form-control-daterange.component';
import { FormControlDateTimepickerComponent } from './components/form-controls/form-control-datetimepicker/form-control-datetimepicker.component';
import { KendoControlService } from './services/kendo-control-service';
import { KendoGridService } from './services/kendo-grid.service';
import { KendoGridComponent } from './components/grid/kendo-grid.component';
import { FormControlTimepickerComponent } from './components/form-controls/form-control-timepicker/form-control-timepicker.component';

@NgModule({
    declarations: [
        FormControlDatepickerComponent,
        FormControlDateRangeComponent,
        FormControlDateTimepickerComponent,
        FormControlTimepickerComponent,
        KendoGridComponent
    ],
    imports: [
        PopupModule,
        FormsModule,
        DateInputsModule,
        CommonModule,
        SharedFeatureModule,
        DatePipe
    ],
    providers: [
        PopupService,
        KendoControlService,
        KendoGridService
    ],
    exports: [
        FormControlDatepickerComponent,
        FormControlDateRangeComponent,
        FormControlDateTimepickerComponent,
        FormControlTimepickerComponent
    ]
})
export class SharedFeatureKendoModule { }