import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-modal',
  template: `
    <ng-template #dialogmodal let-modal>
      <div class="modal-header patient-chart-customize justify-content-between border-0 bg-light-primary">
        <h5 class="modal-title" id="exampleModalLiveLabel">{{title}}</h5>
        <div class="d-flex gap-2">
          <i class="feather {{ !minimizeChat ? 'icon-minimize' : ' icon-maximize' }} f-24 cursor-pointer" (click)="minimizeChatPopup()"></i>
          <i class="feather icon-x f-24 cursor-pointer" aria-label="Close" (click)="closeModal()"></i>
        </div>
      </div>
      <div class="modal-body" *ngIf="!minimizeChat">
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
})

export class DialogModalComponent implements OnInit, OnChanges {

  @Input() size?: string;
  @Input() title?: string;
  @Input() closeModel: boolean;
  @Input() customClass?: string;
  @Input() backdrop: boolean | undefined;

  @Output() onClose = new EventEmitter();
  @ViewChild('dialogmodal', { static: true }) dialogModal!: TemplateRef<any>;
  minimizeChat = false;

  constructor(private modalService: NgbModal) {
    this.closeModel = false;
    this.customClass = '';
  }
  
  private modalRef: NgbModalRef | null = null;

  ngOnInit() {
    this.openModal();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['closeModel']?.firstChange) {
      this.modalService.dismissAll();
    }
  }


  openModal() {
    let modalOptions: NgbModalOptions = { backdrop: false };
    modalOptions.windowClass =  this.customClass;
    switch (this.size) {
      case 'small':
        modalOptions.size = 'sm';
        break;
      case 'large':
        modalOptions.size = 'lg';
        break;
      case 'extralarge':
        modalOptions.size = 'xl';
        break;
      case 'fullscreen':
        modalOptions.fullscreen = true;
        break;
      case 'center':
        modalOptions.centered = true;
        break;
      case 'medium':  
        modalOptions.ariaLabelledBy = 'modal-basic-title';
        break;
    }
    this.modalRef = this.modalService.open(this.dialogModal, modalOptions);
    this.modalRef.result.then(
      (result) => {},
      (reason) => {
        this.onClose.emit();
      }
    );
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close('Close click');
      this.onClose.emit();
    }
  }

  minimizeChatPopup() {
    this.minimizeChat = !this.minimizeChat;
  }
}
 