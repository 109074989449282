import { createFeatureSelector, createSelector } from "@ngrx/store";
import { patientLiveStatus } from "./patient-live-status-state";


export const selectPatientLiveStatus = createFeatureSelector<patientLiveStatus>('patientLiveStatusStore');

export const selectPatientLiveAppointments = createSelector(
    selectPatientLiveStatus, state => state.patientAppointments
);

export const selectPatientLiveStatusStateEvents = createSelector(
    selectPatientLiveStatus, state => state.patientLiveStatusStateEvents
);

export const selectPracticeChairs = createSelector(
    selectPatientLiveStatus, state => state.practiceChairConfigurations.chairs
);

export const selectPracticeChairConfigurations = createSelector(
    selectPatientLiveStatus, state => state.practiceChairConfigurations
);

export const selectPracticeLiveStatusLocation = createSelector(
    selectPatientLiveStatus, state => state.practiceLocation
);

export const selectPatientAllergies = createSelector(
    selectPatientLiveStatus, state => state.patientAllergies
);

export const selectPatientAllergiesStateEvents = createSelector(
    selectPatientLiveStatus, state => state.patientAllergiesStateEvents
);