<div class="auth-wrapper align-items-stretch aut-bg-img w-75% overflow-hidden login-main">
    <div class="flex-grow-1 row">
        <div class="col-xl-9 col-lg-7 col-md-6 login-responsive">
            <div class="h-100 d-md-flex align-items-center auth-side-img row">
                <div class="col-sm-10 auth-content w-auto m-auto p-3 ps-5">
                    <h3 class="text-white">OrthoPMS</h3>
                    <h4 class="text-white font-weight-normal">Enter your email address, and a reset link will be emailed to you.</h4>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-5 col-md-6 auth-side-form login-content">
            <div class="auth-content w-100">
                <h3 class="mb-4 d-block d-xl-none d-lg-none">OrthoPMS</h3>
                <h3 class="mb-4 f-w-400">Forgot password</h3>
                <div>
                    <div class="input-group mb-4 reset-password-panel">
                        <span class="input-group-text" id="basic-addon3"><i class="feather icon-mail"></i></span>
                        <input type="email" class="form-control" placeholder="Email Address" aria-label="Username"
                            aria-describedby="basic-addon3" [value]="userEmail" (input)="onInputChange($event)" 
                            [ngClass]="(showValidationError || showInvalidError) ? 'ng-dirty ng-invalid' : '' "/>
                        <p class="validation_msg mb-0" *ngIf="showValidationError">
                            Email is required.
                        </p>
                        <p class="validation_msg mb-0" *ngIf="showInvalidError">
                            Email address is invalid 
                        </p>
                    </div>
                    <div class="d-grid">
                        <button class="btn btn-block btn-primary mb-4" (click)="forgotPassword()">
                            <span *ngIf="isLoading"><i class="fa fa-regular fa-circle-notch fa-spin m-r-5"></i></span>
                            <span *ngIf="!isLoading">Send Link</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>