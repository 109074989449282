import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-patient-live-status-skeleton',
  template: `
        <div class="patient_live_status_loader">
          <ul class="skeleton p-0">
            <ng-container *ngFor="let count of repeatHtmlElement(skeletonCount)">
              <li class="line"></li>
            </ng-container>
          </ul>
        </div>
  `,
  styles: [`
          .patient_live_status_loader {
            width: 100%;
            border-radius: 4px;
          }
          .patient_live_status_loader ul {
            margin: 0px;
            list-style-type: none;
            display: flex;
            gap: 0px 30px;
            margin-right:20px
          }
          .patient_live_status_loader ul li {
            width: 50px;
            height: 30px !important;
          }
          .chair_live_status_loader .patient_live_status_loader,
          .chair_live_status_loader .skeleton{
            background:none !important;
          }
          .chair_live_status_loader .patient_live_status_loader ul {
            flex-direction: column !important;
            gap: 10px 0px !important;
          }
          .chair_live_status_loader .patient_live_status_loader ul li {
            width: 100% !important; 
            height:90px !important;
          }
       `],
  encapsulation: ViewEncapsulation.None
})
export class PatientLiveStatusSkeletonComponent {
  @Input() skeletonCount = 4;

  repeatHtmlElement(count: number | undefined): Array<number> {
    return Array(count);
  }
}
