// Angular Import
import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';

import { GradientConfig } from 'src/app/app-config';
import { ChairLiveStatusService } from 'src/app/features/patient-live-status/service/chair-live-status.service';
import { InLayService } from 'src/app/features/shared/components/overlay/inlay.service';
import { UserAuthService } from 'src/app/features/user/services/user-auth.sevice';
import { featureName, moduleName, operationName } from 'src/app/features/user/constant/user-role-constant';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { Store } from '@ngrx/store';
import { invokePracticeFeatureConfig } from 'src/app/features/practice/store/action/practice-application-feature.action';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {
  // public props
  gradientConfig;
  navCollapsed: boolean;
  navCollapsedMob: boolean;
  windowWidth: number;
  hideChair!: boolean;
  isVisiblesecondaryHeader = true;
  isVisibleChairLiveStatusPanel = true;
  isShowInlay = false;
  isPatientUser = false;
  isSelfCheckinUser = false;
  private practiceId!: number;

  private subscriptions$ = new Subscription();

  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private chairLiveStatusService: ChairLiveStatusService,
    private inLayService : InLayService,
    private userAuthService: UserAuthService,
    private authService: AuthService,
    private store: Store,
  ) {
    this.gradientConfig = GradientConfig;
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if (
      current_url === baseHref + '/layout/collapse-menu' ||
      current_url === baseHref + '/layout/box' ||
      (this.windowWidth >= 992 && this.windowWidth <= 1024)
    ) {
      GradientConfig.collapse_menu = true;
    }

    this.navCollapsed = this.windowWidth >= 992 ? GradientConfig.collapse_menu : false;
    this.navCollapsedMob = false;
    this.practiceId = this.authService.getPracticeIDFromToken();
  }
  chatAndPhoneModulePermissionCreate = { moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.create }
  
  // life cycle event
  ngOnInit() {
    this.isPatientUser = this.authService.getIsPatientUserRoleFromToken();
    this.isSelfCheckinUser = this.authService.getIsSelfChickinUserRoleFromToken();
    if (this.authService.getPracticeIDFromToken() && !this.authService.getIsSuperAdminRoleFromToken()) {
      this.store.dispatch(invokePracticeFeatureConfig({ practiceId: this.practiceId }));
    }
    const hasPermissionToPatientLiveChair = this.userAuthService
      .hasPermission([{ moduleName: moduleName.scheduler, featureName: featureName.schedule, operationName: operationName.create }]);
    this.hideChair = !hasPermissionToPatientLiveChair;
    if (this.windowWidth < 992) {
      GradientConfig.layout = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar')?.classList.add('menupos-static');
        (document.querySelector('#nav-ps-gradient-able') as HTMLElement).style.maxHeight = '100%'; // 100%
      }, 500);
    }
    this.subscriptions$.add(this.inLayService.showInlayResponse$.subscribe((data: boolean) => this.showOrHideInlay(data)));
  }
  
  // public method
  navMobClick() {
    if (this.windowWidth < 992) {
      if (this.navCollapsedMob && !document.querySelector('app-navigation.pcoded-navbar')?.classList.contains('mob-open')) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }

  chairHideShowEvent() {
    this.isVisibleChairLiveStatusPanel = !this.isVisibleChairLiveStatusPanel;
    this.chairLiveStatusService.chairCloseEvent(this.isVisibleChairLiveStatusPanel);
  }
 
  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
  showHidePatientLiveStatus() {
    this.isVisiblesecondaryHeader = !this.isVisiblesecondaryHeader;
  }
  async showOrHideInlay(data: boolean) {
    setTimeout(() => {
      this.isShowInlay = data;
    }, 10);
  }
}
