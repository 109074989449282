import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noPrefixWhitespace]'
})
export class NoPrefixWhitespaceDirective {
  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    if (inputValue.startsWith(' ') || inputValue.startsWith('\t')) {
      inputElement.value = inputValue.trimStart();
      event.preventDefault();
    }
  }
}
