import { createAction, props } from '@ngrx/store';
import { MenuItem } from './menu.state';

export const invokeGetMenu = createAction('[get Menu] Invoke getMenu');

export const getMenuItemsSuccess = createAction(
  '[get Menu] Invoke getMenu Success',
  props<{ payload: MenuItem[] }>()
)
export const getMenuItemsFailure = createAction(
  '[get Menu] Invoke getMenu Failure',
  props<{ error: string }>()
)

export const updateMenuId = createAction('[Update Menu Id] Update Menu Id', props<{ menuId: number }>());

export const selectedPracticeId = createAction('[Update Menu Id] Selected Practice Id', props<{ practiceId: number }>());

export const selectedPatientId = createAction('[Update Menu Id] Selected Patient Id', props<{ patientId: number }>());

export const resetMenuState = createAction('reset Menu State');

export const updateSelectedSubMenuItem = createAction('[Update Selected SubMenu Item] Update Selected SubMenu Item', props<{ menuItem: MenuItem }>());