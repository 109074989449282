import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-control-videoplayer',
  template: `
            <div class="d-flex gap-2">
            <label *ngIf="label">{{ label }} </label>
            <video class="videoItem mx-auto rounded-4" controls width="100%" height="100%">
                    <source [src]="src" [type]="videoType">
            </video>
        </div>
            `,
})

export class FormControlVideoplayerComponent {

  @Input() label!: string;
  @Input() errorMessage!: string;
  @Input() required?: boolean | false;
  @Input() placeholder?: string;
  @Input() requiredField?: boolean | false;
  @Input() src!: string | undefined;
  @Input() videoType!: string;

  isPlaying: boolean = false;
  sourceUrl: string | undefined;
  @ViewChild('videoPlayerRef') videoPlayerRef: any;

  constructor() { }

  ngOnInit() {
    this.sourceUrl = this.src;
  }

  ngAfterViewInit() {
    this.videoPlayerRef?.nativeElement.addEventListener('ended', () => {
      this.isPlaying = false;
    });
    this.videoPlayerRef.nativeElement.addEventListener('pause', () => {
      this.isPlaying = false;
    });
  }

  get iconPlayClass(): string {
    if (!this.isPlaying) {
      return 'icon-play'
    }
    return this.videoPlayerRef?.nativeElement?.paused ? 'icon-play' : 'icon-pause';
  }

  playVideo(): void {
    const videoPlayer = this.videoPlayerRef.nativeElement as HTMLVideoElement;
    const currentlyPlayingVideo = document.querySelector('video[playing]');

    if (currentlyPlayingVideo && currentlyPlayingVideo !== videoPlayer) {
      (currentlyPlayingVideo as HTMLVideoElement).pause();
      currentlyPlayingVideo.removeAttribute('playing');
    }

    if (this.isPlaying) {
      videoPlayer.pause();
    } else {
      videoPlayer.play();
    }

    if (!videoPlayer.paused) {
      videoPlayer.setAttribute('playing', 'true');
    } else {
      videoPlayer.removeAttribute('playing');
    }

    this.isPlaying = !this.isPlaying;
  }

  ngOnDestroy() {
    if (this.isPlaying) {
      this.videoPlayerRef.nativeElement.pause();
      this.videoPlayerRef.nativeElement.removeAttribute('playing');
      this.isPlaying = false;
    }
    this.videoPlayerRef?.nativeElement.removeEventListener('ended', () => {
      this.isPlaying = false;
    });

    this.videoPlayerRef.nativeElement.removeEventListener('pause', () => {
      this.isPlaying = false;
    });
  }
}