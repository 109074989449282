import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { featureName, moduleName, roles, routeNames } from "../constant/user-role-constant";
import { PracticeUserPermission } from "../../practice/store/user-roles-store/user-role-state";
import { AuthService } from "./authentication/auth.service";

@Injectable({ providedIn: 'root' })
export class UserRoleService {

    constructor(private router: Router, private authService: AuthService) {
    }

    createUserPermissionObject(moduleName: string, featureName: string, operationName: string) {
        return {
            moduleName: moduleName,
            featureName: featureName,
            operationName: operationName
        };
    }

    loadLandingPageByUserRolePermission(loggedInUserRole: string, permission: PracticeUserPermission | null = null, returnUrl: string | null = null) {
        if (returnUrl && returnUrl?.trim().length > 0 && returnUrl !== '/') {
            this.redirectToAndReload(returnUrl);
            return;
        }
        if (loggedInUserRole === roles.patient || loggedInUserRole == roles.patientFRP) {
            this.router.navigateByUrl(routeNames.patientLoginInfo)
        } else if (loggedInUserRole === roles.superAdmin) {
            this.router.navigateByUrl(routeNames.practices)
        } else if (loggedInUserRole === roles.selfCheckIn) {
            this.router.navigateByUrl(routeNames.selfCheckIn)
        }
        else {
            if (!permission) {
                permission = this.authService.getUserRolesAndPermission();
            }
            const routeName = this.getRouteUrlByUserPermission(permission);
            this.redirectToAndReload(routeName);
        }
    }

    redirectToAndReload(route: string): void {
        this.router.navigateByUrl(route).then();
    }

    getRouteUrlByUserPermission(permission: PracticeUserPermission | null = null): string {
        const moduleNameToRouteName: { [key: string]: string } = {
            [moduleName.scheduler]: routeNames.clinical,
            [moduleName.serviceContract]: routeNames.tracker,
            [moduleName.paymentManagement]: routeNames.financial,
            [moduleName.insurance]: routeNames.insurance,
            [moduleName.report]: routeNames.report,
            [moduleName.practice]: routeNames.practiceInformation,
            [moduleName.patient]: routeNames.home
        };
    
        const moduleOrder: string[] = [
            moduleName.scheduler,
            moduleName.serviceContract,
            moduleName.paymentManagement,
            moduleName.insurance,
            moduleName.report,
            moduleName.practice,
            moduleName.patient,
        ];
    
        const modulePermissions = permission?.modulePermissions || [];
    
        for (const name of moduleOrder) {
            const module = modulePermissions.find(module => module.moduleName === name);
            if (module) {
                return moduleNameToRouteName[name];
            }
        }

        return '';
    }
}