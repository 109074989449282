<div class="auth-wrapper align-items-stretch aut-bg-img w-75% overflow-hidden login-main">
    <div class="flex-grow-1 row">
        <div class="col-xl-9 col-lg-7 col-md-6 login-responsive">
            <div class="h-100 d-md-flex align-items-center auth-side-img row">
                <div class="col-sm-10 auth-content w-auto m-auto p-3 ps-5">
                    <h3 class="text-white">OrthoPMS</h3>
                    <h1 class="text-white my-4">Change securely!</h1>
                    <h4 class="text-white font-weight-normal">Change your account password and make your self more
                        securely login on OPMS.</h4>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-5 col-md-6 auth-side-form login-content">
            <div class="auth-content w-100">
                <h3 class="mb-4 d-block d-xl-none d-lg-none">OrthoPMS</h3>
                <h3 class="mb-4 f-w-400">Reset your password</h3>
                <form class="form" [formGroup]="passwordForm" (ngSubmit)="submitChangePassword()">
                    <div>
                        <div class="mb-2">
                            <app-form-control-password-textbox label="New Password" formControlName="newPassword"
                                (togglePassword)="togglePassword()" placeholder="New Password" [minlength]="8"  (onInputChange)="newPasswordChange($event)"
                                [required]="true" [showDefaultMinlengthError]="false">
                            </app-form-control-password-textbox>
                            <h6 class="fw-normal error-message mb-0" *ngIf="showNewPasswordError">
                                Enter new password
                            </h6>
                        </div>
                        <div class="mb-4">
                            <app-form-control-password-textbox label="Re-Type New Password"
                                formControlName="reEnterPassword" placeholder="Re-Type New Password" [minlength]="8"
                                [required]="true" (onInputChange)="reEnterPasswordChange($event)" [showDefaultMinlengthError]="false">
                            </app-form-control-password-textbox>
                            <h6 class="fw-normal error-message mb-0" *ngIf="showMisMatchPasswordError">
                                Password doesn't match
                            </h6>
                        </div>
                        <div class="d-grid">
                            <app-form-control-submit class="text-right" labelName="Reset password" [parentForm]="passwordForm" [cancelButton]="false"> 
                            </app-form-control-submit>
                        </div>
                    </div>
                </form>
                <div>
                    <h3 class="mt-3 mb-3 f-w-400">Password Guidelines</h3>
                    <ul style="list-style-type:none" class="p-0"> 
                        <li *ngFor="let row of passwordGuidelines" class="mb-1">
                            <i class="fa fa-check"></i> <span class="p-l-10">{{row}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>        
    </div>
</div>