import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { environment } from 'src/environments/environment';
import { resetDocumentUploadState } from '../../../store/document-upload-store/document-upload.reducer';
import { selectDocumentUploadState } from '../../../store/document-upload-store/document-upload.selector';
import { DocumentUploadState, ValidationError } from '../../../store/document-upload-store/document-upload.state';
import { documentMessageConstants } from '../../../constant/document-message.constant';
import { BaseDocumentUploadComponent } from '../../base-document-upload/base-document-upload.component';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent extends BaseDocumentUploadComponent implements OnInit, OnDestroy {
  @Input() submitButtonName?: string = "Save";
  @Input() maxFileSize = 20; // Default value: 20 MB
  @Input() allowedFileTypes?: string[] = [];
  @Input() isAllowMultiple?: boolean = false;
  @Input() maxFiles: number = environment.application.documentUploadMaxFiles;
  @Input() existingFileList?: string[] = [];
  @Input() isWebcamUpload?: boolean = false;
  @Input() isAcquireImageUpload?: boolean = false;
  @Output() submitClick: EventEmitter<DocumentUploadState> = new EventEmitter<DocumentUploadState>()

  fileTypeErrors: ValidationError[] = [];
  fileSizeErrors: ValidationError[] = [];
  fileExistErrors: ValidationError[] = [];
  exceedFileLimit = false;
  fileDraggingValidation: string | null = null;
  isFileUploaded: boolean | null = null;
  submitValidation: string | null = null;
  documentUploadState: DocumentUploadState = { documents: [] }
  isActiveUpload = true;

  documentUploadInfo$ = this.store.select(selectDocumentUploadState);

  override async ngOnInit(): Promise<void> {
    super.ngOnInit()
    this.subscriptions$.add(this.documentUploadInfo$.subscribe((data: DocumentUploadState) => this.bindDocumentInformation(data)));
    this.setMaxFilesCount();
  }

  setMaxFilesCount() {
    if (!this.isAllowMultiple) this.maxFiles = 1
  }

  bindDocumentInformation(data: DocumentUploadState) {
    if (data) {
      this.documentUploadState = data;
      if (this.isAllowMultiple === false && this.documentUploadState.documents[0]) {
        this.isActiveUpload = false;
        if (this.documentUploadState.documents[0]?.completed)
          this.submitUploadFiles();
      }
    }
  }

  onFileSelected(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files) this.uploadSeletedFile(files);
  }

  handleWebCamImage(file: File) {
    this.uploadFile(file);
  }

  private uploadSeletedFile(files: FileList) {
    this.clearValidations();
    if (files && files.length > 0) {
      const uploadedFiles = this.documentUploadState?.documents?.length ?? 0;
      const uploadFiles = files.length ?? 0
      if ((uploadedFiles + uploadFiles) > this.maxFiles) {
        this.exceedFileLimit = true
        return;
      }
      else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if(this.validateFile(file))
            this.uploadFile(file);
        }
      }
    }
  }

  private clearValidations() {
    this.fileSizeErrors = [];
    this.fileTypeErrors = [];
    this.fileExistErrors = [];
    this.exceedFileLimit = false;
    this.fileDraggingValidation = null;
    this.submitValidation=null;
  }

  onDrop(event: DragEvent): void {
    this.fileDraggingValidation = null;
    event.preventDefault();
    const files = event.dataTransfer?.files;

    if (files && files.length > 0) {
      const containsFolders = Array.from(files).some(file => file.type === '');

      if (containsFolders) {
        this.fileDraggingValidation = documentMessageConstants.dragfolderWarning;
      } else {
        this.uploadSeletedFile(files);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  submitUploadFiles(): void {
    this.submitValidation = null;
    if ((this.documentUploadState?.documents?.length ?? 0) === 0) {
      this.submitValidation = documentMessageConstants.requiredFile
    }
    else if (!this.documentUploadState.documents.every((document) => document?.completed ?? false)) {
      this.submitValidation = documentMessageConstants.incompletedProgress
    }
    else {
      this.submitClick.emit(this.documentUploadState);
    }
  }

  getFileSize(size: number): string {
    if (size == 0) return '0 KB'
    if (size >= 1000000) { // Convert to MB if size is larger than or equal to 1MB
      const fileSizeInMB = (size / 1000000).toFixed(2);
      return fileSizeInMB + ' MB';
    } else { // Otherwise, display size in KB
      const fileSizeInKB = (size / 1000).toFixed(2);
      return fileSizeInKB + ' KB';
    }
  }

  getFileTypeClass(fileType: string): string {
    if (fileType.includes('.doc')) {
      return 'fa-file-word';
    } else if (fileType.includes('image')) {
      return 'fa-file-image';
    } else if (fileType.includes('audio')) {
      return 'fa-file-audio';
    } else if (fileType.includes('video')) {
      return 'fa-file-video';
    } else if (fileType.includes('/psd')) {
      return 'fa-file-pdf';
    }
    else {
      return 'fa-file-alt';
    }
  }

  validateFile(file: File): boolean {
    let isValid = true;
    const extensionAllowed = this.allowedFileTypes?.some(type => file.name.endsWith(type));
    const mimeTypeAllowed = this.allowedFileTypes?.includes(file.type);

    if (this.allowedFileTypes && this.allowedFileTypes?.length > 0 && (!(extensionAllowed || mimeTypeAllowed))) {
      this.fileTypeErrors.push({ fileName: file?.name });
      isValid = false;
    }
    if (file.size > (this.maxFileSize * 1024 * 1024)) {
      this.fileSizeErrors.push({ fileName: file?.name, fileSize: this.getFileSize(file.size) })
      isValid = false;
    }
    if ((this.existingFileList && this.existingFileList.includes(file.name)) ||
      this.documentUploadState?.documents.some(f => f.document?.name && f.document?.name === file.name)) {
      this.fileExistErrors.push({ fileName: file?.name })
      isValid = false;
    }
    return isValid;
  }


  getAcceptedFileTypes(): string {
    return (this.allowedFileTypes && this.allowedFileTypes.length > 0) ? this.allowedFileTypes.join(',') : '';
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetDocumentUploadState());
    this.submitClick.unsubscribe()
  }
}
