import { createReducer, on } from "@ngrx/store";
import { OnlineSchedulingDashboardState } from "./online-schedule.state";
import { convertOnlineSchedulingPatientAppointmentFailure, convertOnlineSchedulingPatientAppointmentSuccess, convertReferralPatientInformation, enableOnlineSchedulePatientDashboard
    ,getPracticeReferralConfigurationFailure, getPracticeReferralConfigurationSuccess, 
    getPracticeReferralsFailure, getPracticeReferralsSuccess, invokeConvertOnlineSchedulingPatientAppointment, invokeGetOnlineSchedule, 
     invokeGetPracticeReferralConfiguration, invokeGetPracticeReferrals, invokeRemovePracticeReferral, invokeSaveConvertedPatientReferral, invokeSavePracticeReferralConfiguration, invokeUpdateOnlineSchedule,
    onlineGetScheduleFailure, onlineGetScheduleSuccess, removePracticeReferralFailure, removePracticeReferralSuccess, resetOnlineScheduleStateEvent, resetOnlineScheduleStore, 
    saveConvertedPatientReferralFailure, saveConvertedPatientReferralSuccess, 
    savePracticeReferralConfigurationFailure, savePracticeReferralConfigurationSuccess, updateOnlineScheduleFailure, updateOnlineScheduleSuccess } from "./online-schedule.action";

export const initialState: OnlineSchedulingDashboardState = {
    onlineSchedulingDashboardStateEvent: {
        errorMessage: '',
        showErrorAlert: false,
        loading: false,
        showSuccessAlert: false,
        isFormSubmitted: false,
        successMessage: ''
    },
    showOnlineScheduleDashboard: false,
    onlineScheduleInformation: {
        practiceOnlineSchedulingConfigurationId: 0,
        practiceId: 0,
        onlineAppointmentSlotLimit: 0,
        isOnlineVirtualAppointment: false,
        onlineVirtualAppointmentTypeId: null,
        isOnlineInPersonAppointment: false,
        onlineInPersonAppointmentTypeId: null,
        onlineSchedulingWelcomeMessage: '',
        onlineSchedulingSuccessMessage: '',
        isActive: false
    },
    onlinePracticeReferralConfiguration: null,
    onlinePatientReferrals: [],
    convertPatientInformation: null,
    OnlineSchedulingPatient: null,
    convertedPatientReferral: {
        practiceId: 0,
        practiceReferralId: 0,
        patientId: 0,
    }
}

export const OnlineSchedulingDashboardReducer = createReducer(
    initialState,
    on(invokeConvertOnlineSchedulingPatientAppointment, (state,action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            isFormSubmitted: true,
            successMessage: action.successMessage
        }
    })),
    on(convertOnlineSchedulingPatientAppointmentSuccess, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        OnlineSchedulingPatient: action.response,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            showErrorAlert: false,
            isFormSubmitted: false,
            showSuccessAlert:true           
        }
    })),
    on(convertOnlineSchedulingPatientAppointmentFailure, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            errorMessage: action.errorMessage,
            showErrorAlert: true,
            isFormSubmitted: false,
            showSuccessAlert:false,
            successMessage: ''
        }
    })),
    on(enableOnlineSchedulePatientDashboard, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        showOnlineScheduleDashboard: action.showOnlineScheduleDashboard
       
    })),
    on(resetOnlineScheduleStateEvent, (state): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: initialState.onlineSchedulingDashboardStateEvent
    })),
    on(onlineGetScheduleSuccess, (state, action): OnlineSchedulingDashboardState  => ({
        ...state,
        onlineScheduleInformation : action.onlineScheduleInformation,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading:false,
            showErrorAlert : false,
            showSuccessAlert: false, 
        }
    })),
    on(updateOnlineScheduleSuccess,(state, action): OnlineSchedulingDashboardState =>({
        ...state,
        onlineScheduleInformation : action.updateOnlineScheduleInformation,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading:false,
            showSuccessAlert: true,
            isFormSubmitted: false,
            showErrorAlert : false,
                     
        }
    })),
    on(invokeGetOnlineSchedule, (state) : OnlineSchedulingDashboardState =>({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading : true,
            showErrorAlert : false,
            isFormSubmitted: false,
            showSuccessAlert: false,  
        }
    })),
    on(invokeUpdateOnlineSchedule,(state): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading : true,
            isFormSubmitted: true,
            showSuccessAlert: false,
            showErrorAlert : false, 
    }
    })),
    on(onlineGetScheduleFailure,(state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading : false,
            showErrorAlert : true,
        }
    })),
    on(updateOnlineScheduleFailure,(state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            isFormSubmitted: false,
            errorMessage: action.errorMessage,
            showErrorAlert : true,
        }
    })),
    on(resetOnlineScheduleStore, (): OnlineSchedulingDashboardState => initialState),

    //Online patient referral
    on(invokeGetPracticeReferralConfiguration, (state): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading: true
        }
    })),
    on(getPracticeReferralConfigurationSuccess, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlinePracticeReferralConfiguration: action.onlinePatientReferralConfiguration,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            showErrorAlert: false,
            loading: false
        }
    })),
    on(getPracticeReferralConfigurationFailure, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            errorMessage: action.errorMessage,
            showErrorAlert: true,
            loading: false
        }
    })),
    on(invokeSavePracticeReferralConfiguration, (state,action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            isFormSubmitted: true,
            successMessage: action.successMessage
        }
    })),
    on(savePracticeReferralConfigurationSuccess, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlinePracticeReferralConfiguration: action.onlinePatientReferralConfiguration,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            showErrorAlert: false,
            isFormSubmitted: false,
            showSuccessAlert: true
        }
    })),
    on(savePracticeReferralConfigurationFailure, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            errorMessage: action.errorMessage,
            showErrorAlert: true,
            isFormSubmitted: false
        }
    })),
    on(invokeGetPracticeReferrals, (state,action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading: true
        }
    })),
    on(getPracticeReferralsSuccess, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlinePatientReferrals: action.onlinePatientReferrals,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            loading: false
        }
    })),
    on(getPracticeReferralsFailure, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            errorMessage: action.errorMessage,
            showErrorAlert: true,
            loading: false
        }
    })),
    on(convertReferralPatientInformation, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
      convertPatientInformation: action.convertPatientInformation
    })),
    on(invokeRemovePracticeReferral, (state,action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            isFormSubmitted: true,
            successMessage: action.successMessage
        }
    })),
    on(removePracticeReferralSuccess, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            isFormSubmitted: false,
            showSuccessAlert: true
        }
    })),
    on(removePracticeReferralFailure, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            errorMessage: action.errorMessage,
            showErrorAlert: true,
            isFormSubmitted: false
        }
    })),
    on(invokeSaveConvertedPatientReferral, (state): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            isFormSubmitted: true,
            loading: true,
            showSuccessAlert: false,
            showErrorAlert: false,
        }
    })),
    on(saveConvertedPatientReferralSuccess, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        convertPatientInfo: action.convertPatientInfo,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            isFormSubmitted: false,
            loading: false,
            showSuccessAlert: true,
        }
    })),
    on(saveConvertedPatientReferralFailure, (state, action): OnlineSchedulingDashboardState => ({
        ...state,
        onlineSchedulingDashboardStateEvent: {
            ...state.onlineSchedulingDashboardStateEvent,
            errorMessage: action.errorMessage,
            showErrorAlert: true,
            isFormSubmitted: false
        }
    }))
)
