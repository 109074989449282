import { createAction, props } from "@ngrx/store";
import { MedicalCondition } from "./medical-conditions.state";

export const invokeMedicalConditions = createAction('[GetMedicalConditions API] Invoke start loading');
export const medicalConditionsSuccess = createAction('[GetMedicalConditions] API Success', props<{ medicalConditions: MedicalCondition[] }>());
export const medicalConditionsFailure = createAction('[GetMedicalConditions] API Failure', props<{ errorMessage: string }>());

export const startMedicalConditionsLoading = createAction('[MedicalConditions] start loading');
export const medicalConditionsCloseSuccessAlert = createAction('[MedicalConditions] close success alert');
export const medicalConditionsCloseErrorAlert = createAction('[MedicalConditions] close error alert');
export const medicalConditionsStopLoading = createAction('[MedicalConditions] stop loading');

export const invokeUpdateMedicalCondition = createAction('[SaveMedicalCondition] Invoke', props<{ medicalCondition: MedicalCondition, successMessage: string }>());
export const updateMedicalConditionSuccess = createAction('[SaveMedicalCondition] API Success with alert message', props<{ medicalConditions: MedicalCondition[] }>());
export const updateMedicalConditionFailure = createAction('[SaveMedicalCondition] API Failure', props<{ errorMessage: string }>());
export const selectedMedicalCondition = createAction('[MedicalCondition] selected medical condition', props<{ medicalCondition: MedicalCondition }>());
export const resetMedicalConditionState = createAction('[MedicalCondition] clear medicalcondition state');