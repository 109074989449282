import { createAction, props } from "@ngrx/store";
import { Allergies } from "./allergies.state";

export const invokeAllergies = createAction(
    '[GetAllergies] Invoke GetAllergies start loading'
);

export const getAllergiesSuccess = createAction(
    '[GetAllergies] Success', props<{ allergies: Allergies[] }>()
);

export const getAllergiesFailure = createAction(
    '[GetAllergies] Failure', props<{ errorMessage: string }>()
);

export const invokeUpdateAllergy = createAction(
    '[SaveAllergy] invoke', props<{ allergies: Allergies, successMessage: string  }>()
);

export const updateAllergySuccess = createAction(
    '[SaveAllergy] Success', props<{ allergies: Allergies[] }>()
);

export const updateAllergyFailure = createAction(
    '[SaveAllergy] Failure', props<{ errorMessage: string }>()
);

export const allergiesCloseSuccessAlert = createAction(
    '[Completed]  Allergies Success Alert'
);

export const allergiesCloseErrorAlert = createAction(
    '[Error] Allergies Alert'
);

export const resetallergiesState = createAction('[Allergies] clear allergies state');