import { MenuItem } from '../store/menu/menu.state';
import { moduleName,featureName,operationName } from 'src/app/features/user/constant/user-role-constant';

export const superAdminSubMenuItems: MenuItem[] = [
        {
            id: 1,
            title: 'Admin',
            type: 'item',
            url: '/admin',
            classes: 'nav-item',
            icon: 'assets/images/menuicon/sub-menu/users.svg',        
            hiddenFunction:  (userRole, userAuthService) => 
                !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practiceList,operationName:operationName.all }])
        }
    ]
