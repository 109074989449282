import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';

import { ChatApiService } from '../../api/chat.api.service';
import {
  chatSendSms, chatSentSmsSuccess, getPatientByPhoneSuccess,
  getUnreadPatientChatCollectionSuccess,
  handlePatientChatFailure, invokeGetPatientByPhone,
  invokeGetUnreadPatientChatCollection,
  invokeMarkAsRead,
  invokePatientChatCollection, invokeRecentPatientChatCollection,
  markAsReadSuccess,
  patientChatCollectionSuccess, recentPatientChatCollectionSuccess
}
  from './chat.action';

@Injectable()
export class ChatEffects {

  constructor(
    private actions$: Actions,
    private chatApiService: ChatApiService,
    private store: Store
  ) { }

  chatSendSms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chatSendSms),
      concatMap(action =>
        this.chatApiService.sendChatSms(action.communicationSms).pipe(
          map(status => chatSentSmsSuccess({ communicationsStatus: status, patientChat: action.patientChat })),
          catchError(error => of(handlePatientChatFailure({ error: error })))
        )
      )
    )
  });

  recentPatientChatCollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeRecentPatientChatCollection),
      mergeMap(action =>
        this.chatApiService.getRecentPatientChatCollection().pipe(
          map(recentPatientChatCollection => recentPatientChatCollectionSuccess({ recentPatientChatCollection })),
          catchError(error => of(handlePatientChatFailure({ error: error })))
        )
      )
    )
  });

  getPatientByPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeGetPatientByPhone),
      mergeMap(action =>
        this.chatApiService.getPatientByPhone(action.phone).pipe(
          map(patient => getPatientByPhoneSuccess({ patient, patientPhone: action.phone.toString() })),
          catchError(error => of(handlePatientChatFailure({ error: error })))
        )
      )
    )
  });

  patientChatCollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokePatientChatCollection),
      mergeMap(action =>
        this.chatApiService.getPatientChatCollection(action.patientPhone).pipe(
          map(patientChatCollection => patientChatCollectionSuccess({ patientChatCollection, patientId: action.patientId, patientPhone: action.patientPhone })),
          catchError(error => of(handlePatientChatFailure({ error: error })))
        )
      )
    )
  });

  markAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeMarkAsRead),
      mergeMap(action =>
        this.chatApiService.markAsRead(action.chatReadEvent).pipe(
          map(status => markAsReadSuccess({ communicationsStatus: status, chatReadEvent: action.chatReadEvent })),
          catchError(error => of(handlePatientChatFailure({ error: error })))
        )
      )
    )
  });

  GetRecentPatientChatCollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeGetUnreadPatientChatCollection),
      mergeMap(action =>
        this.chatApiService.getUnreadPatientsChatCollection().pipe(
          map(unreadPatientsChatCollection => getUnreadPatientChatCollectionSuccess({ unreadPatientsChatCollection})),
          catchError(error => of(handlePatientChatFailure({ error: error })))
        )
      )
    )
  });

  
}
