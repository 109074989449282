import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PracticeUserState} from './practice-user.state';

export const selectPracticeUserStore = createFeatureSelector<PracticeUserState>('practiceUserStore');

export const selectPracticeUsers = createSelector(
    selectPracticeUserStore,
    state=> state.practiceUsers
);
export const selectCurrentPracticeUser = createSelector(
    selectPracticeUserStore,
    state=> state.practiceUser
);

export const selectPracticeUserLocation = createSelector(
    selectPracticeUserStore,
    state=> state.practiceUserLocations
);
export const selectPracticeUserStateEvent = createSelector(
    selectPracticeUserStore,
    state=> state.practiceUserStateEvent
);

export const selectCurrentUserEmail = createSelector(
    selectPracticeUserStore,
    state=> state.currentUserEmail
);

export const selectSelectedUserId = createSelector(
    selectPracticeUserStore,
    state=> state.selectedUserEmail 
);
export const selectIsValidEmail = createSelector(
    selectPracticeUserStore,
    state=> state.isValidEmail 
);

export const selectIsValidSecurityPin = createSelector(
    selectPracticeUserStore,
    state=> state.isValidSecurityPin 
);
export const selectUsersByRoles = createSelector(
    selectPracticeUserStore,
    state=> state.practiceUsers
);

