import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { practiceFeaturesInformation } from "../../practice/store/state/practice-info";
import { PracticeInfoAPIService } from "../../practice/api/practice-info-api.service";
import { applicationStorageKeys } from '../constant/user-role-constant';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PracticeFeaturePermissionService {

    constructor(private practiceInfoService: PracticeInfoAPIService) { }

    setPracticeFeaturesAndPermission(practiceId: number):Observable<practiceFeaturesInformation> {
        return this.practiceInfoService.getPracticeFeature(practiceId).pipe(
            map((permissions) => {
                localStorage.setItem(applicationStorageKeys.practiceFeaturePermission, JSON.stringify(permissions));
                return permissions;
            })
        );
    }

    hasPracticeFeaturePermission(featureKey: keyof practiceFeaturesInformation): boolean {
        const practiceFeatures = JSON.parse(localStorage.getItem(applicationStorageKeys.practiceFeaturePermission) ?? "{}");
        return practiceFeatures?.[featureKey];
    }
}
