import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { LogoutService } from '../../../services/logout-service';
import { Store } from '@ngrx/store';
import { selectAuthUser } from '../../../store/selectors/authentication.selector';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { AuthUser } from '../../../store/state/auth-user';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit, OnDestroy {

  @Output() logOutEvent = new EventEmitter();

  private subscriptions$ = new Subscription();
  isLoading: boolean = false;
  authUser$ = this.store.select((selectAuthUser));
  isFormSubmitted: boolean = false;

  constructor(
    private logoutService: LogoutService,
    private store: Store,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.subscriptions$.add(this.authUser$.subscribe((data: AuthUser) => this.handleLogout(data)));
  }

  logOut(): void {
    this.isFormSubmitted = true;
    this.isLoading = true;
    this.logoutService.logOut();
    this.logOutEvent.emit();
  }

  handleLogout(data: AuthUser): void {
    if (!this.isFormSubmitted) {
      return;
    }

    if (this.hasErrorMessage(data)) {
      this.showErrorMessage(data?.authStateEvents?.errorMessage);
    } else if (!data.valid) {
      this.resetLogoutState();
    }
  }

  private hasErrorMessage(data: AuthUser): boolean {
    return !!data?.authStateEvents?.errorMessage;
  }

  private showErrorMessage(message?: string): void {
    this.isLoading = false;
    this.isFormSubmitted = false;
    this.alertService.failureAlert(message);
  }

  private resetLogoutState(): void {
    this.logoutService.resetState();
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
