import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PinturaEditorComponent } from '@pqina/angular-pintura';
import { setPlugins, plugin_crop, plugin_finetune, plugin_filter, plugin_annotate, getEditorDefaults, processImage } from '@pqina/pintura';

import { selectDocumentsDownloadInfo } from '../../../store/document-download-store/document-download.selector';
import { DocumentDownloadInfo, getImage } from '../../../store/document-download-store/document-download.reducer';
import { documentGuidNullValue } from '../../../constant/document-message.constant';
import { selectDocumentUploadState } from '../../../store/document-upload-store/document-upload.selector';
import { DocumentUploadState } from '../../../store/document-upload-store/document-upload.state';
import { BaseDocumentUploadComponent } from '../../base-document-upload/base-document-upload.component';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate, processImage);

@Component({
  selector: 'app-image-editor-tool',
  template: `<div class="position-relative">
                <div [ngClass]="processPercentage > 0 ? 'imageEditorOpacity':''">
                  <div *ngIf="processPercentage > 0" class="w-100 text-center ">
                    <h6 class="fw-normal">Uploading image... {{processPercentage}}%</h6>
                  </div>
                </div>
                <div style="height: 70vh;" *ngIf="imageSrc">
                  <pintura-editor #editor [src]="imageSrc"  (close)="handleEditorClose()" (process)="handleEditorProcess($event)" [options]="options"></pintura-editor>
                </div>
            </div>`,
            styleUrls: ['./image-editor-tool.component.scss']
})
export class ImageEditorToolComponent extends BaseDocumentUploadComponent implements OnInit, OnDestroy {
  @Input() documentGuid!: string;
  @Input() cropAspectRatio?: number = 2 / 1;
  @Input() cropEnableImageSelection?: boolean = true;
  @Input() enableButtonExport?: boolean = true;
  @Input() cropList?= [
    [undefined, 'Custom'],
    [1, 'Square'],
    [4 / 3, 'Landscape'],
    [3 / 4, 'Portrait'],
  ];
  @Output() submitClick: EventEmitter<DocumentUploadState> = new EventEmitter<DocumentUploadState>();
  @Output() closeEditor= new EventEmitter<void>();

  imageSrc: string | undefined;
  processPercentage = 0;
  documentData$ = this.store.select(selectDocumentsDownloadInfo);
  documentDownloadInfoList?: DocumentDownloadInfo[];

  @ViewChild('inlineEditor') inlineEditor?: PinturaEditorComponent<any> = undefined;
  @ViewChild('editor') editor?: any;

  documentUploadState: DocumentUploadState = { documents: [] }

  documentUploadInfo$ = this.store.select(selectDocumentUploadState);
  options!: any
  uploading = false;

  override async ngOnInit(): Promise<void> {
    super.ngOnInit()
    this.pinturaOptionConfig();
    this.subscriptions$.add(this.documentData$.subscribe((data: DocumentDownloadInfo[] | undefined) => this.bindImage(data)));
    this.subscriptions$.add(this.documentUploadInfo$.subscribe((data: DocumentUploadState) => this.bindDocumentInformation(data)));
    await this.handleDownload();
  }

  private pinturaOptionConfig() {
    const cropRatio = this.cropList?.find(item => item[1] === this.patientImage?.patientImageTypeName)?.[0] ?? this.cropAspectRatio;
    this.options = {
      ...getEditorDefaults(),
      imageCropAspectRatio: cropRatio,
      cropEnableImageSelection: this.cropEnableImageSelection,
      cropEnableButtonFlipVertical: true,
      cropSelectPresetOptions: [
        [
          'Crop', this.cropList
        ],
      ],
      enableButtonClose:true,
      enableButtonExport: this.enableButtonExport,
    };
  }

  handleEditorClose(): void {
    this.closeEditor.emit();
  }

  bindDocumentInformation(data: DocumentUploadState) {
    if (data && this.uploading === true) {
      if (data.documents && data.documents.length > 0){
        const document = data.documents.find(x=>x.documentId===this.patientImage?.patientImageId.toString())
        if(!document) return
        this.documentUploadState =  {...data,documents: [document]};
        this.processPercentage = document.progress===100 ? 99:  document.progress;
        if (document.completed && document.documentUploadResponse) {
          this.processPercentage = 100;
          this.submitUploadFiles();
          this.uploading = false;
        }
      }
    }
  }

  async handleEditorProcess(imageWriterResult: any): Promise<void> {
    this.uploading = true;
    await this.uploadFile(imageWriterResult.dest);
  }

  submitUploadFiles(): void {
    this.submitClick.emit(this.documentUploadState);
  }

  //Load the image
  async bindImage(data: DocumentDownloadInfo[] | undefined): Promise<void> {
    if (data && this.documentGuid) {
      this.documentDownloadInfoList = data;
      const documentDownloadInfo = this.findDocument();
      const safeUrl = documentDownloadInfo ? await this.safeUrl(documentDownloadInfo.documentData) : undefined;
      this.imageSrc = safeUrl;
    }
  }
  private safeUrl(dataUrl: string): string | undefined {
    return dataUrl;
  }

  private findDocument() {
    return this.documentDownloadInfoList?.find(info => info.downloadedDocumentGuid === this.documentGuid);
  }

  handleDownload() {
    if (this.documentGuid && this.documentGuid !== documentGuidNullValue) {
      const documentDownloadInfo = this.findDocument();
      if (documentDownloadInfo) {
        this.imageSrc = documentDownloadInfo ? this.safeUrl(documentDownloadInfo.documentData) : undefined;
      }
      else
        this.store.dispatch(getImage({ documentGuid: this.documentGuid }));
    }
    else
      this.imageSrc = undefined
  }

  handleButtonClick(): void {
    this.editor.editor.processImage().then((imageWriterResult: any) => {
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.submitClick.unsubscribe()
  }
}
