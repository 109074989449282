import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateUtilService } from './date-util.service';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';

@Injectable()

export class KendoGridService {

    constructor(
        private datePipe: DatePipe,
        private dateUtilService: DateUtilService
    ) { }

    getGridPageSizes() {
        return [10, 20, 50, 100];
    }

    getGridButtonCount() {
        return 3;
    }

    getCurrentDate() {
        return this.datePipe.transform(this.dateUtilService.GetApplicationCurrentDateTime(), 'MM/dd/yyyyThh:mm')
    }

    getDefaultGridState(field: string, dir: 'asc' | 'desc') {
        const state: State = {
            skip: 0,
            take: 10,
            sort: [{ field: field, dir: dir }]
        };
        return state;
    }

    getDefaultSort(field: string, dir: 'asc' | 'desc') {
        const sort: SortDescriptor[] = [
            {
                field: field,
                dir: dir,
            },
        ];
        return sort;
    }

    setGridStateSort(state: State) {
        if (state.sort) {
            state.sort = state.sort.map((s) => ({
                ...s,
                dir: s.dir ?? 'asc',
            }));
        }
        return state;
    }

    setGridStatePagination(state: State, skip: number, take: number) {
        if (state) {
            state.skip = skip;
            state.take = take;
        }
        return state;
    }

    resetAfterReport(dataResult: any, state: State) {
        const gridDataResult: GridDataResult = {
            data: dataResult.length > 0 ? [...dataResult].slice(state.skip, ((state.skip ?? 0) + (state.take ?? 10))) : [],
            total: dataResult && dataResult.length > 0 ? (dataResult[0].totalNumberOfRecords ?? dataResult.length) : 0
        };
        return gridDataResult;
    }

    exportPdfExcel(grid: GridComponent | undefined, isPdf: boolean, isExcel: boolean) {
        if (isPdf)
            grid?.saveAsPDF();
        if (isExcel) {
            grid?.saveAsExcel();
        }
    }

    getExportPDFFileName(filePrefix: string): string {
        return `${filePrefix}_${this.getCurrentDate()}.pdf`;
    }

    getExportExcelFileName(filePrefix: string): string {
        return `${filePrefix}_${this.getCurrentDate()}.xlsx`;
    }
}