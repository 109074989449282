import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneMenuComponent } from './phone-menu/phone-menu.component';
import { CallProgressComponent } from './call-progress/call-progress.component';
import { CallStateComponent } from './call-state/call-state.component';
import { CallDialpadComponent } from './call-dialpad/call-dialpad.component';
import { CallProgressDialpadComponent } from './call-progress-dialpad/call-progress-dialpad.component';
import { SharedFeatureModule } from 'src/app/features/shared/shared-feature.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PhoneCallEffects } from '../../store/phone-store/phone-call.effect';
import { PhoneCallApiService } from '../../api/phone-call.api.service';
import { phoneCallReducer } from '../../store/phone-store/phone-call.reducer';
import { PatientDashboardModule } from 'src/app/features/patientdashboard/patient-dashboard.module';

@NgModule({
  declarations: [
    CallProgressComponent,
    CallStateComponent,
    CallDialpadComponent,
    CallProgressDialpadComponent,
    PhoneMenuComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedFeatureModule,
    StoreModule.forFeature('phoneCallStore', phoneCallReducer),
    EffectsModule.forFeature([PhoneCallEffects]),
    PatientDashboardModule
  ],
  exports: [
    PhoneMenuComponent
  ],
  providers: [
    PhoneCallApiService
  ],
})
export class PhoneModule { }
