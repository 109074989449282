import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private userAgent: string = window.navigator.userAgent.toLowerCase();

  getBrowserName(): string {
    if (this.userAgent.includes('chrome')) {
      return 'chrome';
    } else if (this.userAgent.includes('firefox')) {
      return 'firefox';
    } else if (this.userAgent.includes('edge')) {
      return 'edge';
    } else if (this.userAgent.includes('msie') || this.userAgent.includes('trident')) {
      return 'ie';
    } else if (this.userAgent.toLowerCase().includes('safari') && !this.userAgent.includes('crios')) {
      return 'ipadSafari';
    } else if (this.userAgent.includes('crios')) {
      return 'ipadChrome';
    } else {
      return 'unknown';
    }
  }
}
