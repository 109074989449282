import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
    transform(date: Date, patientcurentDate: Date | null): string {
        const currentDate = patientcurentDate ? patientcurentDate : new Date();
        const inputDate = new Date(date);
        const timeDiff = new Date(currentDate).getTime() - new Date(inputDate).getTime();
        const seconds = Math.floor(timeDiff / 1000);
        if (seconds < 60) {
            return 'Just now';
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (seconds < 86400) {
            const hours = Math.floor(seconds / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (seconds < 2592000) {
            const days = Math.floor(seconds / 86400);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (seconds < 604800) {
            const weeks = Math.floor(seconds / 604800);
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (seconds < 31536000) {
            const months = Math.floor(seconds / 2592000);
            if (months === 1) {
                return '1 month ago';
            } else {
                return `${months} months ago`;
            }
        } else {
            const years = Math.floor(seconds / 31536000);
            if (years === 1) {
                return '1 year ago';
            } else {
                return `${years} years ago`;
            }
        }
    }
}