import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CustomWizardService } from '../custom-wizard.service';
import { wizardHederNavAction, wizardStepAction } from '../../../types/shared-types';
import { MaskingType } from '../../../constant/shared-constant';
import { CustomValidatorService } from '../../../utils/custom-validator.service';
import { CommonUtilsService } from '../../../utils/common-utils.service';


@Component({
  selector: 'app-first',
  templateUrl: './first.component.html',
})
export class FirstComponent implements OnInit, OnDestroy {

  activeStepIndex = 0;
  personalInformation: FormGroup;
  maskingType = MaskingType;

  @Output() stepSuccessEvent = new EventEmitter<any>();
  @ViewChild('child') child!: any;
  private subscriptions$ = new Subscription();

  constructor(
    private customWizardService: CustomWizardService,
    private fb: FormBuilder,
    private commonUtilsService: CommonUtilsService,
  ) {

    this.personalInformation = this.fb.group({
      firstName: ['', [Validators.required, CustomValidatorService.generalNameValidator()]],
      lastName: ['', [Validators.required, CustomValidatorService.generalNameValidator()]],
      email: ['', [Validators.required, CustomValidatorService.emailValidator()]],
      phone: ['', CustomValidatorService.phoneValidator(true)]
    })

  }

  ngOnInit(): void {
    this.subscriptions$.add(
      this.customWizardService.currentStepActions$.subscribe((stepAction: wizardStepAction) => this.handleStepAction(stepAction))
    );
    this.subscriptions$.add(
      this.customWizardService.headerStepNavigation$.subscribe((headerStepAction: wizardHederNavAction) => this.handleHeaderStepNavigation(headerStepAction))
    );
  }

  handleStepAction(stepAction: wizardStepAction) {
    if (!!stepAction) {
      if (stepAction.title == "Personal Information") {
        this.activeStepIndex = stepAction.stepIndex;
        this.handleFirstStepAction(stepAction.action.value);
      }
    }
  }

  handleHeaderStepNavigation(headerStepAction: wizardHederNavAction) {
    if (!!headerStepAction) {
      if (headerStepAction.activeStepIndex === this.activeStepIndex) {
        this.submitForm()
      }
    }
  }

  handleFirstStepAction(actionValue: string): void {
    switch (actionValue) {
      case 'next':
        this.submitForm()
        break;
      default:
        return
    }
  }

  submitForm() {
    if (this.personalInformation.valid && this.child?.aditionalInformation.valid) {
      this.goToNextStep();
    }
    else {
      this.commonUtilsService.validateAllFormFields(this.personalInformation);
      this.commonUtilsService.validateAllFormFields(this.child?.aditionalInformation);
    }
  }

  goToNextStep() {
    this.activeStepIndex = this.activeStepIndex + 1;
    this.stepSuccessEvent.emit(this.activeStepIndex);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}