import {
  Component, OnInit, TemplateRef,
  ViewChild, ElementRef, HostListener, OnDestroy, Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  selectPatientNameSearchResult, selectPatientSearchFilter,
  selectPatientSearchStateEvent
} from '../../store/selectors/patient-search.selector';
import {
  closeErrorAlert, invokedPatientNameAPI,
  invokedPatientSearchFilter,
  patientSearchStateDestroy
} from '../../store/actions/patient-search.action';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { CommonUtilsService } from 'src/app/features/shared/utils/common-utils.service';
import {
  PatientNameSearchResultModel, PatientSearchFilterModel,
  PatientSearchStateEvent
} from '../../types/patient-search-type';
import { Router } from '@angular/router';
import { patientDashboardNavigateAsset, patientSearchTypes } from '../../constant/patient-dashboard-api-asset';
import { selectedPatientId } from 'src/app/theme/store/menu/menu.actions';
import { DebounceService } from 'src/app/features/shared/services/debounce-service';
import { PatientPinService } from 'src/app/features/shared/services/patient-pin.service';
import { InLayService } from 'src/app/features/shared/components/overlay/inlay.service';
import { AppSessionStorageService } from 'src/app/features/shared/services/app-session-storage.service';
import { applicationStorageKeys } from 'src/app/features/user/constant/user-role-constant';
import { PatientCommunication } from 'src/app/features/patient-communication/constant/patient-communication.constant';
import { PatientCommunicationActionService } from 'src/app/features/patient-communication/service/patient-communication-action-service';
import { NavigateURLService } from 'src/app/features/shared/services/navigate-url.service';

@Component({
  selector: 'app-patient-search-suggestion',
  templateUrl: './patient-search-suggestion.component.html',
  styleUrls: ['./patient-search-suggestion.component.scss'],
})

export class PatientSearchSuggestionComponent implements OnInit, OnDestroy {
  patientSearchFilter: FormGroup;
  searchKeyword = 'patientName';
  isLoading: boolean | undefined;
  patientNameloading = false;
  spinnerloading : boolean = false;

  @ViewChild('patientList') patientList!: ElementRef;
  @ViewChild('patientSearchInput')
  patientSearchInput!: ElementRef;
  private subscriptions$ = new Subscription();
  dropdownVisible = false;
  onSearchInputFocuse = false;
  dropdownItems = [];
  activeItemIndex = -1;
  pinNoRecordFound = false;
  searchPatientsByFilter = false;
  searchResultFromChat = false;
  
  @Input() isFromChat= false;
  
  patientNameSearchResult!: PatientNameSearchResultModel[];
  patientNameSearchResultCopy: PatientNameSearchResultModel[] = [];

  patientSearchFilter$ = this.store.select(selectPatientSearchFilter);
  patientNameSearchResult$ = this.store.select(selectPatientNameSearchResult);
  isLoading$ = this.store.select(selectPatientSearchStateEvent);

  constructor(private store: Store,
    private formBuilder: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private alert: AlertService,
    private commonUtilsService: CommonUtilsService,
    private router: Router,
    private debounceService: DebounceService,
    private patientPinService: PatientPinService,
    private appSessionStorageService: AppSessionStorageService,
    private inLayService: InLayService,
    private patientCommunicationActionService : PatientCommunicationActionService,
    private navigateURLService: NavigateURLService) {

    this.patientSearchFilter = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      patientName: [''],
      patientPreferredName: [''],
      patientDOB: [''],
      patientPrimaryPhone: [''],
      patientPrimaryEmailAddress: [''],
      patientFinancialResponsiblePersonFirstName: [''],
      patientFinancialResponsiblePersonLastName: [''],
      patientFinancialResponsiblePerson: [''],
      patientStatusId: [''],
      selectedPatientStatusId: [[]],
      practiceLocationId: [''],
      selectedPracticeLocations: [[]],
      SortOrder: [''],
      SortColumn: [''],
      tackAfter: [''],
      pageSize: [''],
      patientId: [''],
      showInActivePatients: [false],
    });
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.patientCommunicationActionService.fromChatPatientSearch$.subscribe((data: boolean) => this.searchResultFromChat = data));
    this.subscriptions$.add(this.patientSearchFilter$.subscribe(async (data: PatientSearchFilterModel) => this.bindPatientSearchFilterInformation(data)));
    this.subscriptions$.add(this.isLoading$.subscribe(async (data: PatientSearchStateEvent) => this.handlePatientSearchStateEvent(data)));
    this.subscriptions$.add(this.patientNameSearchResult$.subscribe(async (data: PatientNameSearchResultModel[]) => this.bindPatientNameSearchResult(data)));
    this.subscriptions$.add(this.appSessionStorageService.getObservable(applicationStorageKeys.selectedPracticeIdBySA).subscribe((data: string) => this.bindPracticeChanges(data)));
    setTimeout(() => {
      this.patientCommunicationActionService.fromChatPatientSearch(this.isFromChat);
      if (this.isFromChat && this.patientNameSearchResult.length === 0)
        this.onPatientNameChange(this.patientSearchFilter);
    }, 100);
  }

  async bindPatientNameSearchResult(data: PatientNameSearchResultModel[]) {
    this.patientNameSearchResult = data;
    this.patientNameSearchResultCopy = data;
    this.patientNameSearchResult.length > 0 ? this.showDropdown() : this.hideDropdown();
  }

  bindPracticeChanges(data: string) {
    if (data) {
      this.clearFilter();
      this.clearPatientNameSearchResult();
    }
  }

  private clearPatientNameSearchResult() {
    this.patientNameSearchResult = [];
    this.patientNameSearchResultCopy = [];
  }

  private navigateToURL(url: string) {
    this.router.navigateByUrl(url);
  }

  async bindPatientSearchFilterInformation(data: PatientSearchFilterModel) {
    if (data) {
      this.patientSearchFilter.patchValue(data)
    }
  }

  async handlePatientSearchStateEvent(data: PatientSearchStateEvent) {
    this.isLoading = data.loading;
    this.patientNameloading = data.loading ?? true;
    this.spinnerloading = data.patientNameloading?? false;
    if (data.showErrorAlert) {
      const response = await this.alert.failureAlert(data.errorMessage);
      if (response.isConfirmed)
        this.store.dispatch(closeErrorAlert({}));
    }
  }

  openTop(searchfilter: TemplateRef<string>) {
    this.offcanvasService.dismiss();
    this.inLayService.showInlay(false);
    this.searchPatientsByFilter = true;
    this.offcanvasService.open(searchfilter, {
      position: 'top', panelClass: 'search-filter-overlay', backdropClass: 'search-filter-overlay-canvas'
    }).result.then(
      () => {
        this.searchPatientsByFilter = false;
        this.closeTop();
      },
      () => {
        this.searchPatientsByFilter = false;
        this.closeTop();
      }
    );
  }

  closeOffcanvas(offcanvas: NgbOffcanvas) {
    offcanvas.dismiss();
  }

  closeTop() {
    this.offcanvasService.dismiss();
    this.dispatchPatientSearchFilter(this.patientSearchFilter.value);
  }
  
  clearFilter() {
    this.patientSearchFilter.reset();
    this.dispatchPatientSearchFilter(this.patientSearchFilter.value);
  }

  openPin(){
    if(!this.dropdownVisible){
      this.patientNameSearchResult = [...this.patientPinService.getPatientsList()];
      setTimeout(() => {
        if(this.patientNameSearchResult.length > 0){
          this.showDropdown();
          this.pinNoRecordFound = false;
        }
        else{
          this.hideDropdown();
          this.pinNoRecordFound = true;
        }
      }, 100);
    }
  }

  onPatientNameChange(patientSearchFilter: FormGroup): void {
    const patientName = this.commonUtilsService.splitFullName(patientSearchFilter.value.patientName);
    const updatedFilterValue = {
      ...patientSearchFilter.value,
      lastName: this.commonUtilsService.trimAndLowerCase(patientName.lastName),
      firstName: this.commonUtilsService.trimAndLowerCase(patientName.firstName),
      patientId: null,
    };
    this.spinnerloading = true;
    this.debounceService.debounce('getPatientsSuggestion', () => {
      this.patientCommunicationActionService.fromChatPatientSearch(this.isFromChat);
      this.store.dispatch(invokedPatientNameAPI({ patientSearchFilter: updatedFilterValue }));
    });
  }

  onPatientNameKeyPressChange(item: PatientNameSearchResultModel, patientSearchFilter: FormGroup): void {
    this.patientSearchInput.nativeElement.blur();
    if (!item) return;
    this.goToPatientSummary(patientSearchFilter.value.patientId);
  }

  selectPatientName(item: PatientNameSearchResultModel): void {
    if (!item) return;
    this.goToPatientSummary(item.patientId);
  }

  dispatchPatientSearchFilter(patientSearchFilterValue: PatientSearchFilterModel) {
    this.store.dispatch(invokedPatientSearchFilter({ patientSearchFilter: patientSearchFilterValue }));
  }

  goToPatientSummary(patientId: number) {
    this.router.navigate([patientDashboardNavigateAsset.goToPatientSummary, patientId,'patientsummary',patientId]);
    this.store.dispatch(selectedPatientId({ patientId }));
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    if (!this.isFromChat)
      this.store.dispatch(patientSearchStateDestroy());
  }

  getInitials(name: string) {
    const formatName = name.replace(/\W*(\w)\w*/g, '$1').toUpperCase()
    return formatName.slice(0, 1);
  }

  setActive(itemIndex: number) {
    this.activeItemIndex = itemIndex;
  }

  onSearchInputFocused() {
    this.patientNameSearchResult = this.patientNameSearchResultCopy;
    this.onSearchInputFocuse = true;
    this.activeItemIndex = -1;
    this.pinNoRecordFound = false;
    (this.patientNameSearchResult.length > 0) ? this.showDropdown() : this.hideDropdown();
    this.patientCommunicationActionService.fromChatPatientSearch(this.isFromChat);
    if (this.patientSearchFilter.value.patientName)
      this.onPatientNameChange(this.patientSearchFilter);
  }

  onSearchInputBlurred() {
    this.hideDropdown();
  }

  showDropdown() {
    this.dropdownVisible = true;
  }

  hideDropdown() {
    this.dropdownVisible = false;
  }

  onKeydown(event: KeyboardEvent, searchfilter: TemplateRef<string>) {
    const lastIndex = this.patientNameSearchResult.length - 1;
    let patientName: PatientNameSearchResultModel;
    switch (event.key) {
      case "ArrowDown":
        this.activeItemIndex = (this.activeItemIndex === lastIndex) ? 0 : this.activeItemIndex + 1;
        break;
      case "ArrowUp":
        this.activeItemIndex = (this.activeItemIndex === 0) ? lastIndex : this.activeItemIndex - 1;
        if (this.activeItemIndex < -1) this.activeItemIndex = lastIndex;
        break;
      case "Enter":
        patientName = this.patientNameSearchResult[this.activeItemIndex];
        if (this.activeItemIndex >= 0 && patientName)
          this.selectPatientName(patientName);
        else this.onPatientNameKeyPressChange(patientName, this.patientSearchFilter);
        if (!patientName) {
          this.openTop(searchfilter);
        }
        this.onSearchInputBlurred();
        this.patientSearchInput.nativeElement.blur();
        break;
    }
  }

  onClickedOutside() {
    this.onSearchInputFocuse = false;
    this.pinNoRecordFound = false;
    if (this.dropdownVisible) {
      this.dropdownVisible = false;
    }
  }

  onCallClick(data: PatientNameSearchResultModel) {
    const patientCommunication: PatientCommunication = {
      communicationType: 'PatientCallDial',
      patientFirstName: data.patientFirstName,
      patientLastName: data.patientLastName,
      patientName: data.patientName,
      patientId: data.patientId,
      patientPrimaryPhone: data.patientFormattedPrimaryPhone,
    }
    this.patientCommunicationActionService.onCallClick(patientCommunication);
  }

  onChatClick(data: PatientNameSearchResultModel) {
    const patientCommunication: PatientCommunication = {
      communicationType: 'PatientChat',
      patientFirstName: data.patientFirstName,
      patientLastName: data.patientLastName,
      patientId: data.patientId,
      patientPrimaryPhone: data.patientFormattedPrimaryPhone,
    }
    this.patientCommunicationActionService.onChatClick(patientCommunication);
  }

  @HostListener('document:keydown', ['$event'])

  handleKeyboardEvent(event: KeyboardEvent) {
    if(!this.patientList) return;
    const lastIndex = this.patientNameSearchResult.length - 1;
    if (event.key === "ArrowDown") {
      if (this.patientNameSearchResult && lastIndex != this.activeItemIndex) {
        this.patientList.nativeElement.scrollTo({
          top: this.patientList.nativeElement.scrollTop + 45,
          behavior: 'smooth'
        });
      }
      if (this.activeItemIndex === 0)
        this.patientList.nativeElement.scrollTop = this.activeItemIndex - this.patientList.nativeElement.offsetTop;
      if (this.activeItemIndex === lastIndex) {
        this.patientList.nativeElement.scrollTop = this.patientList.nativeElement.scrollHeight;
      }
    }
    if (event.key === "ArrowUp") {
      if (this.activeItemIndex === lastIndex) {
        this.patientList.nativeElement.scrollTop = this.patientList.nativeElement.scrollHeight;
      }
      else {
        this.patientList.nativeElement.scrollTo({
          top: this.patientList.nativeElement.scrollTop - 45,
          behavior: 'smooth'
        });
      }
    }
  }
 
  navigateToTXCard(item: PatientNameSearchResultModel) {
    const newTabUrl = this.navigateURLService.getTreamentCardSummaryURL(item.patientId);
    this.navigateToURL(newTabUrl);
    this.onClickedOutside();
  }

  navigateToImage(item: PatientNameSearchResultModel) {
    const newTabUrl = this.navigateURLService.getImageSummarySummaryURL(item.patientId);
    this.navigateToURL(newTabUrl);
    this.onClickedOutside();
  }

  navigateToPatientSummary(item: PatientNameSearchResultModel) {
    this.onClickedOutside();
    return this.navigateURLService.navigateToPatientSummary(item.patientId);
  }

}