<div class="position-relative live_location">
    <div class="position-absolute z-index-9999 mt-2 m-l-5">
        <i class="feather icon-map-pin f-16 text-secondary"></i>
    </div>
    <form class="m-l-10 margin-left-location" [formGroup]="liveLocation">
        <div class="from-control">
            <ng-select [items]="practiceLocation" [closeOnSelect]="true" [clearable]="false"
                bindLabel="practiceLocationName" placeholder="search location" bindValue='practiceLocationId'
                formControlName="locationId" [loading]="isLoading" (change)="liveLocationChangeEvent($event)">
                <ng-template ng-label-tmp let-item="item">
                    <div ngxTippy="{{ item.practiceLocationName }}" class="z-index text-truncate">
                        {{ item.practiceLocationName }}
                    </div>
                </ng-template>
            </ng-select >
        </div>
    </form>
    <div class="subscription_status_section">
        <app-location-subscription-status></app-location-subscription-status>
    </div>
    
</div>