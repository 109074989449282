import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MaskingType } from '../constant/shared-constant';

export type MaskType = MaskingType.Phone | MaskingType.TaxId | MaskingType.SSN | MaskingType.DOB;

@Directive({
  selector: '[appNumberMask]'
})
export class NumberMaskDirective implements AfterViewInit {

  @Input('appNumberMask') maskType: MaskType = MaskingType.Phone;

  private phoneRegex: RegExp = new RegExp(/^\(\d{3}\) \d{3}-\d{4}$/g);
  private taxRegex: RegExp = new RegExp(/^\d{2}-\d{7}$/g);
  private ssnRegex: RegExp = new RegExp(/^\d{3}-\d{2}-\d{4}$/g);
  private dobRegex: RegExp = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/g);

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.applyMask(this.el.nativeElement.querySelector('input').value);
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1 || event.ctrlKey || event.metaKey) {
      return;
    }
    const inputValue: string | undefined = this.el.nativeElement.value;
    if (!inputValue) {
      return;
    }
    const position: number = this.el.nativeElement.selectionStart || 0;
    let nextValue = '';

    if (event.key === 'Backspace') {
      nextValue = inputValue.slice(0, position - 1) + inputValue.slice(position);
    } else {
      nextValue = inputValue.slice(0, position) + event.key + inputValue.slice(position);
    }

    if (nextValue && !this.isValidFormat(nextValue)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.applyMask(value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = (event.clipboardData || (window as any).clipboardData);
    let pastedText = clipboardData?.getData('text').replace(/[^0-9]/g, '') || '';
    const inputElement = this.el.nativeElement.querySelector('input') as HTMLInputElement;
    const position = inputElement.selectionStart || 0;
    const currentValue = inputElement.value || '';
    const nextValue = currentValue.slice(0, position) + pastedText + currentValue.slice(position);
    this.applyMask(nextValue, true);
  }

  private isValidFormat(value: string): boolean {
    if (this.maskType === MaskingType.Phone) {
      return this.phoneRegex.test(value);
    } else if (this.maskType === MaskingType.TaxId) {
      return this.taxRegex.test(value);
    } else if (this.maskType === MaskingType.SSN) {
      return this.ssnRegex.test(value);
    } else if (this.maskType === MaskingType.DOB) {
      return this.dobRegex.test(value);
    }
    return false;
  }

  private applyMask(value: string, isPaste: boolean = false) {
    if (!value) {
      return;
    }

    const inputElement = this.el.nativeElement.querySelector('input') as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart || 0;
    let maskedValue = value.replace(/[^0-9]/g, '');

    if (this.maskType === MaskingType.Phone) {
      if (maskedValue.length > 6) {
        maskedValue = `(${maskedValue.slice(0, 3)}) ${maskedValue.slice(3, 6)}-${maskedValue.slice(6, 10)}`;
      } else if (maskedValue.length > 3) {
        maskedValue = `(${maskedValue.slice(0, 3)}) ${maskedValue.slice(3, 6)}`;
      } else if (maskedValue.length > 0) {
        maskedValue = `(${maskedValue.slice(0, 3)}`;
      }
    }
    else if (this.maskType === MaskingType.TaxId) {
      if (maskedValue.length > 2) {
        maskedValue = `${maskedValue.slice(0, 2)}-${maskedValue.slice(2, 9)}`;
      }
    }
    else if (this.maskType === MaskingType.SSN) {
      if (maskedValue.length > 5) {
        maskedValue = `${maskedValue.slice(0, 3)}-${maskedValue.slice(3, 5)}-${maskedValue.slice(5, 9)}`;
      } else if (maskedValue.length > 3) {
        maskedValue = `${maskedValue.slice(0, 3)}-${maskedValue.slice(3, 5)}`;
      } else if (maskedValue.length > 0) {
        maskedValue = `${maskedValue.slice(0, 3)}`;
      }
    } else if (this.maskType === MaskingType.DOB) {
      if (maskedValue.length > 4) {
        maskedValue = `${maskedValue.slice(0, 2)}/${maskedValue.slice(2, 4)}/${maskedValue.slice(4, 8)}`;
      } else if (maskedValue.length > 2) {
        maskedValue = `${maskedValue.slice(0, 2)}/${maskedValue.slice(2, 4)}`;
      } else if (maskedValue.length > 0) {
        maskedValue = `${maskedValue.slice(0, 2)}`;
      }
    }
    this.ngControl.control?.setValue(maskedValue, { emitEvent: false });
    setTimeout(() => {
      if (!isPaste) {
        inputElement.selectionStart = cursorPosition + (maskedValue.length - value.length);
        inputElement.selectionEnd = inputElement.selectionStart;
        // inputElement.focus();
      }
      const inputEvent = new Event('input', { bubbles: true });
      this.el.nativeElement.dispatchEvent(inputEvent);
    }, 0);
  }
}