import { NAME_VALUE_TYPE } from "../../shared/types/shared-types"

export const practiceConstants = {
    practiceSuccessAlertTitle: 'Good job!',
    practiceInfoUpdateSuccess: 'Awesome! personal information have been successfully updated.',
    practiceFeatureUpdateSuccess: 'Awesome! features information have been successfully updated.',
    practicePaymentUpdateSuccess: 'Awesome! payment information have been successfully updated.',
    practiceLocationCreatedSuccess: 'Awesome! location created successfully',
    practiceLocationUpdateSuccess: 'Awesome! location updated successfully',
    practiceLocationActivateSuccess: 'Awesome! location activated successfully',
    practiceLocationDeactivateSuccess: 'Awesome! location deactivated successfully',
    practiceLocationActivateWarning: 'We would like to confirm if you want to activate this location. Are you sure?',
    removePracticeLogoWarning: 'Just to confirm, you want to delete practice logo?',
    removePracticeSignatureWarning: 'Just to confirm, you want to delete practice signature?',
    practiceLocationDeactivateWarning: 'We would like to confirm if you want to deactivate this location. Are you sure?',
    practiceLocationMinimumOneWarning: "Deactivating all locations is not allowed; at least one location should be maintained as active.",

    practiceCreateRoleSuccess: 'Awesome! The new role has been created successfully.',
    practiceRolePermissionsSuccess: 'The role permissions have been updated successfully.',
    practiceUserPermissionsSuccess: 'The user permissions have been updated successfully.',
    practiceLocationExistsWarning: 'This location is already exists',
}
export const practiceUserConstants = {
    practiceUserCreateSuccess: 'Awesome! User created successfully.',
    practiceUserUpdateSuccess: 'Awesome! User updated successfully.',
    practiceUserMarkAsActivate: 'Awesome! User activated successfully.',
    practiceUserMarkAsInActivate: 'Awesome! User deactivated successfully.',
    practiceUserForgotPassword: 'Awesome! Password reset instructions has been sent to user email address.',
    practiceUserChangePassword: 'Awesome! Password updated successfully.',
}

export const permissionOperations = {
    view: 1,
    edit: 2,
    create: 3,
    delete: 4,
    all: 5,
}

export const permissionTypes = {
    users: 1,
    roles: 2,
}

export const PracticeMenuItem =
{
    practicePageTitle: 'Assess',
    practicePageIdProperty: 'practiceId'
}

export const Practice_Billing_Types: NAME_VALUE_TYPE[] = [
    { name: "Practice", value: "UsePractice" },
    { name: "Location", value: "UseLocation" },
    { name: "Other", value: "UseOther" }
]

export const Practice_Billing_Type = {
    UsePractice: "UsePractice",
    UseLocation: "UseLocation",
    UseOther: "UseOther"
}
