import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ngb-dropdown-action',
  template: `
    <div ngbDropdown #primary="ngbDropdown" container="body">
      <button type="button" class="btn btn-light-primary btn-sm dropdown-toggle dropdown-remove" role="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
        <i class="feather icon-more-vertical"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownManual">
        <button *ngFor="let item of dropdownItems" class="w-100 m-0 py-2" ngbDropdownItem (click)="menuClick(item)">
          {{ item }}
        </button>
      </div>
    </div>
  `,
})
export class NgbDropdownActionComponent {

  @Input() dropdownItems: any;
  @Output() menuClickEvent = new EventEmitter();

  menuClick(item:any) {
    this.menuClickEvent.emit(item)
  }
}
