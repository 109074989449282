import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { practiceUserConstants } from 'src/app/features/practice/constant/practice-constant';
import {
   invokePracticeUserCloseErrorAlert, invokePracticeUserCloseSuccessAlert,
  invokeUserChangePassword, resetPracticeUserState
} from 'src/app/features/practice/store/user-creation/practice-user.action';
import { selectPracticeUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.selector';
import { ChangePassword, practiceUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.state';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { CustomValidatorService } from 'src/app/features/shared/utils/custom-validator.service';
import {passwordGuidelines} from '../../../constant/login-constant';
import { AuthService } from '../../../services/authentication/auth.service';
import { UserRoleService } from '../../../services/user-role.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html'
})
export class ChangepasswordComponent {
  passwordForm: FormGroup;
  loading = false;
  userId = "";
  isLoading = false;
  showMisMatchPasswordError = false;
  showNewPasswordError = false;
  changePassword: ChangePassword | undefined;
  passwordGuidelines : string[] = [];

  private subscriptions$ = new Subscription();
  selectPracticeUserStateEvent$ = this.store.select(selectPracticeUserStateEvent);

  constructor(private store: Store, private alert: AlertService, private formBuilder: FormBuilder, private route: ActivatedRoute,
    private authService: AuthService,
    private userRoleService: UserRoleService) {
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidatorService.emailValidator()]],
      currentPassword: ['', [Validators.required, CustomValidatorService.passwordValidator()]],
      newPassword: ['', [Validators.required, CustomValidatorService.passwordValidator()]],
      reEnterPassword: ['', [Validators.required, CustomValidatorService.passwordValidator()]],
    });
    this.passwordGuidelines = passwordGuidelines;
  }
  ngOnInit() {
    this.passwordForm.patchValue({
      email: this.authService.getEmailFromToken()
    });
    this.subscriptions$.add(this.selectPracticeUserStateEvent$.subscribe((data: practiceUserStateEvent) => this.bindPracticeUserStateEvent(data)));
  }
  ngOnDestroy(): void {
    this.showMisMatchPasswordError = false;
    this.subscriptions$.unsubscribe();
    this.store.dispatch(resetPracticeUserState());
  }
  bindPracticeUserStateEvent(data: practiceUserStateEvent) {
    this.loading = data.loading!;
    if (data.showSuccessAlert) {
      this.alert.successAlert(data.successMessage ?? practiceUserConstants.practiceUserChangePassword);
      this.store.dispatch(invokePracticeUserCloseSuccessAlert());
      this.backToHomeClick();
    }
    if (data.showErrorAlert) {
      this.alert.failureAlert(data.errorMessage);
      this.store.dispatch(invokePracticeUserCloseErrorAlert());
    }
  }
  backToHomeClick(){
    this.passwordForm.reset();
    this.passwordForm?.markAsUntouched();
    const loggedInUserRole = this.authService.getUserRoleFromToken();
    this.userRoleService.loadLandingPageByUserRolePermission(loggedInUserRole);
 }
  submitChangePassword() {
    if (this.passwordForm.valid && !this.showMisMatchPasswordError && !this.showNewPasswordError) {
      let successMessage = practiceUserConstants.practiceUserChangePassword;
      this.changePassword = {
        email: this.passwordForm.value.email,
        currentPassword: this.passwordForm.value.currentPassword,
        newPassword: this.passwordForm.value.newPassword
      }
      this.store.dispatch(invokeUserChangePassword({ changePassword: this.changePassword, successMessage: successMessage }));
    }
  }
  reEnterPasswordChange(event: string) {
    this.showMisMatchPasswordError = event != this.passwordForm.value.newPassword
  }
  newPasswordChange(event: string) {
    this.showNewPasswordError = event == this.passwordForm.value.currentPassword;
    if (this.passwordForm.value.reEnterPassword)
      this.showMisMatchPasswordError = event != this.passwordForm.value.reEnterPassword
  }
  togglePassword() {
  }
}
