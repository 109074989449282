import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resetSecurityPinState, validateSecurityPin } from 'src/app/features/practice/store/user-creation/practice-user.action';
import { selectIsValidSecurityPin, selectPracticeUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.selector';
import { practiceUserStateEvent } from 'src/app/features/practice/store/user-creation/practice-user.state';

@Component({
  selector: 'app-security-pin-validation',
  template: `
  <ng-container>
          <div class="h-35">
              <form [formGroup]="securityPinForm" appScrollToFirstInvalidControl>
                  <div class="w-100">
                      <app-form-control-password-textbox label="Staff Pin" formControlName="securityPin" [required]="true"
                          placeholder="Enter the pin number" [minlength]="4" [maxlength]="4" (onInputChange)=onPinChange() numericOnly />
                  </div>
                  <span *ngIf="errorMessage" class="text-danger f-15">{{errorMessage}}</span>
                  <div class="text-right">
                      <app-form-control-submit [isSubmitted]="isConfirmLoading" labelName="Confirm" [parentForm]="securityPinForm" [cancelButton]="false"
                          (onClick)="onSubmit()"> </app-form-control-submit>
                  </div>
              </form>
          </div>
</ng-container>
  `
})
export class SecurityPinValidationComponent {

  securityPinForm!: FormGroup;
  isValidPin: boolean = false;
  isConfirmLoading: boolean = false;
  errorMessage: string | undefined;
  inputType: string = "password"

  @Output() securityPinValidatedEvent = new EventEmitter<boolean>();

  private subscription$ = new Subscription();
  selectIsValidSecurityPin$ = this.store.select(selectIsValidSecurityPin);
  selectPracticeUserStateEvent$ = this.store.select(selectPracticeUserStateEvent);

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit() {
    this.subscription$.add(this.selectIsValidSecurityPin$.subscribe((data: boolean | undefined) => this.bindIsValidPin(data)));
    this.subscription$.add(this.selectPracticeUserStateEvent$.subscribe((data: practiceUserStateEvent) => this.bindPracticeUserStateEvent(data)));
    this.securityPinForm = this.formBuilder.group({
      securityPin: ["", [Validators.required, Validators.maxLength(4)]]
    });
  }

  bindIsValidPin(data: boolean | undefined) {
    this.isValidPin = data!
    if (this.isValidPin) {
      this.securityPinValidatedEvent.emit(true);
    }
  }

  bindPracticeUserStateEvent(data: practiceUserStateEvent) {
    this.isConfirmLoading = data.isValidatePinLoading;
    this.errorMessage = data.errorMessage
  }

  onPinChange(){
    this.errorMessage = "";
  }

  onSubmit() {
    if (this.securityPinForm.valid) {
      const securityPin = {
        pin: this.securityPinForm.value.securityPin
      }
      this.store.dispatch(validateSecurityPin({ securityPin: securityPin }))
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
    this.store.dispatch(resetSecurityPinState());
  }
}
