import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppLocalStorageService {

  private subjects = new Map<string, BehaviorSubject<string>>();

  constructor() {
    window.addEventListener('storage', (event: StorageEvent) => {
      const key = event.key;
      if (key && this.subjects.has(key)) {
        this.subjects.get(key)!.next(event.oldValue!);
      }
    });
  }

  getItem(key: string): string {
    return localStorage.getItem(key) || '';
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
    if (this.subjects.has(key)) {
      this.subjects.get(key)!.next(value);
    } else {
      this.subjects.set(key, new BehaviorSubject<string>(value));
    }
  }

  getObservable(key: string): Observable<string> {
    if (!this.subjects.has(key)) {
      this.subjects.set(key, new BehaviorSubject<string>(localStorage.getItem(key)!));
    }
    return this.subjects.get(key)!.asObservable();
  }

  clear(): void {
    localStorage.clear();
  }
}