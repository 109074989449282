import { Component } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { LogoutService } from 'src/app/features/user/services/logout-service';
import { AppLocalStorageService } from 'src/app/features/shared/services/app-local-storage.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-session-timeout',
  template: `
          <div class="col-lg-12 p-4">

            <div class="d-flex justify-content-center">
              <img width="30%" src="assets/images/maintenance/session_timeout.png">
            </div>

            <div style="width:40%;margin:20px auto" class="text-center">
                <p class="f-20 m-0 f-w-400 mb-4">Your session has timed out.</p>
                <p class="f-20 m-0 f-w-400 mb-4">For security purposes, your session has been inactive for too long, and as a result, it has timed out.
                   We apologize for any inconvenience caused.</p>
                   <p class="f-20 m-0 f-w-400 mb-2">To continue using our website, please log in again.</p>
                   <button class="btn btn-light-primary m-t-40" (click)="redirectToLogin()">Back to login</button>
            </div>

        </div>
        `,
})
export class SessionTimeoutComponent {
  returnUrl!: string;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private logoutService: LogoutService,
    private appLocalStorageService: AppLocalStorageService,
    private router: Router,
    private route: ActivatedRoute
  ){  }
  ngOnInit() {
    console.log('SessionTimeoutComponent');
    this.offcanvasService.dismiss('Close click');
    this.appLocalStorageService.clear();
    this.logoutService.sessionTimeout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  redirectToLogin() {
    this.router.navigate(['/login'], { queryParams: { returnUrl: this.returnUrl } });
  }
}