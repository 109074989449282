import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CallEvent } from '../store/phone-store/phone-call.state';
@Injectable({
  providedIn: 'root'
})
export class PatientCommunicationCallEventService {

  private onReceivedCallSubject: Subject<CallEvent> = new Subject<CallEvent>();
  private onAnsweredCallSubject: Subject<CallEvent> = new Subject<CallEvent>();
  private onCompletedCallSubject: Subject<CallEvent> = new Subject<CallEvent>();
  private onCallInprogressSubject: Subject<string> = new Subject<string>();

  onReceivedCall(event: CallEvent): void {
    this.onReceivedCallSubject.next(event);
  }

  get onReceivedCall$() {
    return this.onReceivedCallSubject.asObservable();
  }

  onAnsweredCall(event: CallEvent): void {
    this.onAnsweredCallSubject.next(event);
  }

  get onAnsweredCall$() {
    return this.onAnsweredCallSubject.asObservable();
  }

  onCompletedCall(event: CallEvent): void {
    this.onCompletedCallSubject.next(event);
  }

  get onCompletedCall$() {
    return this.onCompletedCallSubject.asObservable();
  }

  onCallInprogress(event: string): void {
    this.onCallInprogressSubject.next(event);
  }

  get onCallInprogress$() {
    return this.onCallInprogressSubject.asObservable();
  }

}