import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
    AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors,
    Validator
} from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-multiselect-checkbox',
    template: `
            <div class="row">
                <div class="col-custom-5 col-sm-6" *ngFor="let option of options">
                    <div class="form-group mb-0">
                        <div class="checkbox checkbox-fill">
                        <input 
                                    type="checkbox" 
                                    [name]="option.name + '_' + option.id" 
                                    [id]="option.name + '_' + option.id"
                                    [checked]="selectedValues.includes(option.id)"

                                    (change)="onCheckboxChange(option.id)" 
                                />
                                <label [for]="option.name + '_' + option.id" class="cr d-inline">{{option.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectCheckboxComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiselectCheckboxComponent),
            multi: true
        }
    ],
})
export class MultiselectCheckboxComponent implements ControlValueAccessor, Validator {
    @Input() options: any[] = [];
    @Input() selectedValues: number[] = [];

    @Output() checkBoxChangeEvent = new EventEmitter<boolean>();
    @Output() selectionChanged = new EventEmitter<[number[], number]>();

    value: boolean;
    control: AbstractControl | undefined;
    onChange: any = () => { };
    onTouched: any = () => { };
    disabled: boolean;

    constructor() {
        this.disabled = false;
        this.value = false;
    }

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        this.control = control;
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.value = value ?? false;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onCheckboxChange(optionId: number) {
        if (this.selectedValues.includes(optionId)) {
            this.selectedValues = this.selectedValues.filter(id => id !== optionId);
        } else {
            this.selectedValues.push(optionId);
        }
        const tuple: [number[], number] = [this.selectedValues, optionId];
        this.selectionChanged.emit(tuple);
    }

    hasError(): boolean | undefined {
        return this.control?.invalid && (this.control?.touched || this.control?.dirty);
    }
}
