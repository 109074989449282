import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import {
    getPatientAllergies,
    getPatientAllergiesFailure,
    getPatientAllergiesSuccess,
    getPatientAppointmentsByDateFailure, getPatientAppointmentsByDateSuccess,
    getPracticeChairConfigforPatientLiveStatusFailure, getPracticeChairConfigforPatientLiveStatusSuccess,
    getPracticeLocationsforLiveStatusFailure, getPracticeLocationsforLiveStatusSuccess,
    invokeGetPatientLiveAppointmentsByDate, invokeGetPracticeChairConfigforPatientLiveStatus,
    invokeGetPracticeLocationsforLiveStatus, invokePatientLiveSaveStatusAppointment,
    savePatientLiveSaveStatusAppointmentFailure, savePatientLiveSaveStatusAppointmentSuccess
} from './patient-live-status-action';
import { PatientLiveStatusApiService } from '../../api/patient-live-status-api.service';



@Injectable()
export class PatientLiveStatusEffect {

    constructor(
        private actions$: Actions,
        private patientLiveStatusApiService: PatientLiveStatusApiService,
    ) { }

    getPatientAppointmentsByDate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetPatientLiveAppointmentsByDate),
            switchMap((action) => {
                return this.patientLiveStatusApiService.GetPatientAppointmentsByDate(action.GetPatientAppointmentsByDate).pipe(
                    map(appointments => getPatientAppointmentsByDateSuccess({ appointments })),
                    catchError(error => of(getPatientAppointmentsByDateFailure({ error })))
                );
            })
        )
    })

    savePatientAppointment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokePatientLiveSaveStatusAppointment),
            switchMap((action) => {
                return this.patientLiveStatusApiService.savePatientAppointment(action.appointment).pipe(
                    map(appointment => savePatientLiveSaveStatusAppointmentSuccess({ appointment, action: action.action })),
                    catchError(error => of(savePatientLiveSaveStatusAppointmentFailure({ error })))
                );
            })
        )
    })

    getPracticeScheduleConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetPracticeChairConfigforPatientLiveStatus),
            switchMap((action) => {
                return this.patientLiveStatusApiService.GetPracticeChairConfig(action.practiceId, action.practiceLocationId).pipe(
                    map(schedulerConfiguration => getPracticeChairConfigforPatientLiveStatusSuccess({ schedulerConfiguration })),
                    catchError(error => of(getPracticeChairConfigforPatientLiveStatusFailure({ error })))
                );
            })
        )
    })

    getPracticeLocation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetPracticeLocationsforLiveStatus),
            switchMap(() => {
                return this.patientLiveStatusApiService.getPracticeLocations().pipe(
                    map(practiceLocation => getPracticeLocationsforLiveStatusSuccess({ practiceLocation })),
                    catchError(error => of(getPracticeLocationsforLiveStatusFailure({ error })))
                );
            })
        )
    })

    getPatientAllergies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getPatientAllergies),
            switchMap((action) => {
                return this.patientLiveStatusApiService.getPatientAllergies(action.patientId).pipe(
                    map(patientAllergies => getPatientAllergiesSuccess({ patientAllergies })),
                    catchError(error => of(getPatientAllergiesFailure({ error })))
                );
            })
        )
    })

}