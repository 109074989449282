import { createAction, props } from '@ngrx/store';
import { PracticePersonalInformation } from '../state/practice-info';

export const invokeGetPractices = createAction(
  '[GetPractices API] invoke GetPractices API'
);

export const GetPracticesSuccess = createAction(
  '[GetPractices API] GetPractices API Success',
  props<{ practiceListDetails: PracticePersonalInformation[] }>()
);

export const GetPracticesFailure = createAction(
  '[GetPractices API] GetPractices API Failure',
  props<{ errorMessage: string }>()
);

export const closeErrorAlert = createAction(
  '[Error Alert] Alert key Updated',
);
export const closeSuccessAlert = createAction(
  '[Success Alert] Alert key Updated',
);
export const startLoading = createAction('[Practice List] start loading');

export const invokeGetPracticesByEmail = createAction(
  '[GetPracticesByEmail API] invoke GetPracticesByEmail API', props<{email:string}>()
);

export const GetPracticesByEmailSuccess = createAction(
  '[GetPracticesByEmail API] GetPracticesByEmail API Success',
  props<{ practiceListDetails: PracticePersonalInformation[] }>()
);

export const GetPracticesByEmailFailure = createAction(
  '[GetPracticesByEmail API] GetPracticesByEmail API Failure',
  props<{ errorMessage: string }>()
);

