import {SelectOptions } from "../../shared/types/shared-types";

export const onlineScheduleDashboardActionMenu = {
    'Convert': 'Convert',
    'Remove': 'Remove'
}

export const onlineScheduleMessage = {
    'ConvertPatientSuccess': "Patient converted successfully.",
    'RemoveOnlinePatientConfirmation': "Are you sure you want to remove this booking?",
    'RemoveOnlinePatientSuccess': "Patient removed successfully."

}

export const onlinePatientReferralMessage = {
    'RemoveReferralPatientConfirmation': "Are you sure you want to remove {{patientName}} patient?",
    'RemoveReferralPatientSuccess': "Patient removed successfully."
}

export const patientTracker = {
    NoExam: '1',
    NoShow: '2',
    NoTreatment: '6',
    TXRecommended: '4',
    StartContract: '3',
    SignAtHome: '5',
    Referral: '7',
}

export const chatDashboardOption = {
    Recent: '1',
    Unread: '2',
    Archived: '3',
}
export const patientTrackerOutCome: SelectOptions[] = [
    { bindLabel: "Tx Recommended", bindValue: 'Tx Recommended', type: 'ExamResultCategory' },
    { bindLabel: "No Result", bindValue: 'No Result', type: 'ExamResultCategory' },
    { bindLabel: "Observation", bindValue: 'Observation', type: 'ExamResultCategory' },
    { bindLabel: "Active", bindValue: 'Active', type: 'AppointmentStatus' },
    { bindLabel: "Noshow", bindValue: 'Noshow', type: 'AppointmentStatus' },
    { bindLabel: "Cancelled", bindValue: 'Cancelled', type: 'AppointmentStatus' },
];

export const appointmentStatus = {
    Active: 'Active',
    CheckIn: 'CheckIn',
    Checkout: 'Checkout',
    Completed: 'Completed',
    Noshow: 'Noshow',
    Cancelled: 'Cancelled',
    PatientCanceled: 'PatientCanceled',
    PracticeCanceled: 'PracticeCanceled',
}

export const examResultCategory = {
    TxRecommended: 'Tx Recommended',
    NoResult: 'No Result',
    NoTreatment: 'No Treatment',
    Observation: 'Observation',
    Specialist: 'Specialist',
}

export const patientTrackerStatus = {
    NoExam: 'No Exam',
    Noshow: 'No Show',
    NoTreatment: 'No Tx',
    RPSignedContract: 'RP Signed Contract',
    TxRecommended: 'Tx Recommended',
}

export const actionMenuItemsForInsuranceVerification =
{
    FollowUp: "Follow-Up",
    Reverify: "Reverify"
}

export const followupFilterTypes = {
    Today: 'Today',
    ThisWeek: 'ThisWeek',
    Past: 'Past',
    Future: 'Future',
    All: 'All',
    None: 'None'
}

export const insuranceVerificationCategory = {
    verified: 'Verified',
    moreInformationNeeded: 'More Information Needed',
    unabletoVerify: 'Unable to verify',
    notVerified: 'Not Verified',
    noInsurance: 'No Insurance',
    ageLimit: 'Age Limit',
    lTMReached: 'LTM Reached',
    insuranceNotExist: 'Insurance Not Exist',
    preAuthorization: 'Pre-Authorization',
    inProgress: 'In Progress',
    paused: 'Paused',
    coPayBased: 'Co-Pay Based',
    reVerify: 'Reverify',
}
