import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, FormGroup, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';

@Component({
  selector: 'app-form-control-toggle-button',
  template: `
            <div class="form-group">
              <div class="switch switch-success d-inline m-r-10">
                <input type="checkbox"  
                  [name]="labelName" 
                  [id]="labelName"
                  [checked]="value"
                  [disabled]="disabled"
                  [disabled]="disabled"

                  (change)="onToggleChange($event)" 
                  (blur)="onTouched()"
                />
                <label [for]="labelName" class="cr"></label>
              </div>

              <label [for]="labelName" class="ms-2">{{labelName}}</label>

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="labelName">
                </app-validation-error>
              </ng-container>
            </div>
          `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlToggleButtonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlToggleButtonComponent),
      multi: true
    }
  ],
})
export class FormControlToggleButtonComponent implements ControlValueAccessor, Validator {

  @Input() labelName!: string;
  @Input() formControlName?: string;
  @Input() groupName?: FormGroup;
  @Output() checkBoxChangeEvent = new EventEmitter<boolean>();

  value: boolean;
  control: AbstractControl | undefined;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled: boolean;

  constructor() {
    this.disabled = false;
    this.value = false;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.value = value ?? false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onToggleChange(event: any) {
    this.value = (event.target as HTMLInputElement).checked;
    this.onChange(this.value);
    this.control?.markAsTouched();
    this.checkBoxChangeEvent.emit(this.value);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }
}