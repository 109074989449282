import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meridianTimeFormat'
})
export class MeridianTimeFormatPipe implements PipeTransform {
  transform(time: string): string {
    const [hours, minutes] = time.split(':');
    let formattedHours = parseInt(hours, 10);
    let meridian = 'AM';
    if (formattedHours >= 12) {
      meridian = 'PM';
      formattedHours = formattedHours - 12;
    }
    
    if (formattedHours === 0) 
      formattedHours = 12;

    const paddedHours = this.padWithZero(formattedHours);
    const paddedMinutes = this.padWithZero(parseInt(minutes, 10));
    const formattedTime = `${paddedHours}:${paddedMinutes} ${meridian}`;

    return formattedTime;
  }

  private padWithZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}