import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppLocalStorageService } from './app-local-storage.service';
import { PatientNameSearchResultModel } from '../../patientdashboard/types/patient-search-type';
import { AuthService } from '../../user/services/authentication/auth.service';
import { applicationStorageKeys } from '../../user/constant/user-role-constant';

@Injectable({
    providedIn: 'root'
})
export class PatientPinService {

    private patientsSubject = new BehaviorSubject<PatientNameSearchResultModel[]>([]);

    constructor(
        private appLocalStorageService: AppLocalStorageService,
        private authService: AuthService) {
        
        if(this.isCrossPracticeExist()) this.clearStorage();

        const patients = this.getPatientFromStorage();
        this.appLocalStorageService.setItem(applicationStorageKeys.PATIENT_PIN_KEY, JSON.stringify(patients));
        this.patientsSubject.next(patients.reverse());
    }

    private isCrossPracticeExist(): boolean {
        const patients = this.getPatientFromStorage();
        const practiceId = this.authService.getPracticeIDFromToken();
        return patients.some(p => p.practiceId !== practiceId);
    }

    private clearStorage() {
        this.appLocalStorageService.setItem(applicationStorageKeys.PATIENT_PIN_KEY, JSON.stringify([]));    
    }

    private getPatientFromStorage(): PatientNameSearchResultModel[] {
        if(this.appLocalStorageService.getItem(applicationStorageKeys.PATIENT_PIN_KEY)){
            return JSON.parse(this.appLocalStorageService.getItem(applicationStorageKeys.PATIENT_PIN_KEY)) || [];
        }
        return [];
    }

    getPatientsList(): PatientNameSearchResultModel[] {
        if(this.isCrossPracticeExist()) this.clearStorage();
        return this.getPatientFromStorage().reverse();
    }

    getPatients(): Observable<PatientNameSearchResultModel[]> {
        return this.patientsSubject.asObservable();
    }

    addPatient(patient: PatientNameSearchResultModel): void {
        if (this.isCrossPracticeExist()) this.clearStorage();
        const patients = this.getPatientFromStorage();
        const existingPatientIndex = patients.findIndex(p => p.patientId === patient.patientId);

        if (existingPatientIndex !== -1) {
            patients[existingPatientIndex] = patient;
        } else {
            if (patients.length >= 12) {
                patients.shift();
            }
            patients.push(patient);
        }
        
        this.appLocalStorageService.setItem(applicationStorageKeys.PATIENT_PIN_KEY, JSON.stringify(patients));
        this.patientsSubject.next(patients);
    }
}