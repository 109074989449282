import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    transform(items: any[] | undefined, field: string, value: any | undefined | null): any[] {
        if (!items || !Array.isArray(items) || !field) {
            return [];
        }
        if(typeof value === 'boolean')
            return items.filter(item => item[field] === value);
        if(!value)  return items;
        return items.filter(item => item[field].toLowerCase().includes(value.toLowerCase()));
    }
}