import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@angular/forms';

import { closeErrorAlert, invokeGetPatientByPhone, invokeGetUnreadPatientChatCollection, invokeRecentPatientChatCollection, resetChosenPatientChatState } from '../../../store/chat-store/chat.action';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { selectChatPageStateEvent, selectChatReadEvent, selectChosenPatientChat, selectRecentPatientChats, selectUnreadPatientsChatCollection } from '../../../store/chat-store/chat.selector';
import { ChatReadEvent, ChosenPatientChat, PatientChatStateEvent, RecentPatientChat, UnreadPatientsChatCollection } from '../../../store/chat-store/chat.state';
import { PatientCommunication } from '../../../constant/patient-communication.constant';
import { PatientCommunicationActionService } from '../../../service/patient-communication-action-service';
import { PatientCommunicationPopUpService } from '../../../service/patient-communication-popup-service';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { CustomValidatorService } from 'src/app/features/shared/utils/custom-validator.service';
import { MaskingType } from 'src/app/features/shared/constant/shared-constant';
import { CommonUtilsService } from 'src/app/features/shared/utils/common-utils.service';
import { chatActionGenericKeys } from '../../../constant/patient-communication.constant';

@Component({
  selector: 'app-new-chat',
  templateUrl: './new-chat.component.html',
  styleUrls: ['./new-chat.component.scss']
})
export class NewChatComponent extends BaseDestroyComponent implements OnInit {

  chatActions = chatActionGenericKeys;
  public dialerClick = this.chatActions.chat;
  patientName: string = '';
  patientCommunicationActivePopupLength: number = 0;
  public oPatient: any = {};
  public arrRecentPatients: any[] = [];
  public patientData: any[] = [];
  public sSearchPatientNameText = '';
  patientCommunications: PatientCommunication[] = [];

  showPatientChatDialog = false;
  bShowProgress = false;
  phoneNumberSearchloading = false;
  minimizeChat: boolean = false;
  recentChatloading = false;
  hasUnReadChat = false;
  maskingType = MaskingType;
  newChat: FormGroup;
  public unreadPatients: any[] = [];


  recentChatState$ = this.store.select(selectRecentPatientChats);
  patientChatCollection$ = this.store.select(selectChosenPatientChat);
  chatPageStateEvent$ = this.store.select(selectChatPageStateEvent);
  selectChatReadEvent$ = this.store.select(selectChatReadEvent);
  selectUnreadPatientsChatCollection$ = this.store.select(selectUnreadPatientsChatCollection);

  @Input() public popupIndex!: number;
  @Output() onClose = new EventEmitter<number>();

  constructor(private store: Store,
    private patientCommunicationActionService: PatientCommunicationActionService,
    private patientCommunicationPopUpService: PatientCommunicationPopUpService,
    private alert: AlertService,
    private formBuilder: FormBuilder,
    private commonUtilsService: CommonUtilsService

  ) {
    super();
    this.newChat = this.formBuilder.group({
      patientPhone: ['', CustomValidatorService.phoneValidator(true)],
    });
  }

  ngOnInit(): void {
    this.bindPatientCommunication(this.patientCommunicationActionService.getActivePatientCommunicationPopups())
    this.subscriptions$.add(this.recentChatState$.subscribe((data?: RecentPatientChat[]) => this.bindRecentPatientChats(data)));
    this.subscriptions$.add(this.patientChatCollection$.subscribe((data?: ChosenPatientChat) => this.bindPaitentChat(data)));
    this.subscriptions$.add(this.patientCommunicationActionService.patientCommunications$.subscribe((data: PatientCommunication[]) => this.bindPatientCommunication(data)));
    this.subscriptions$.add(this.chatPageStateEvent$.subscribe((data?: PatientChatStateEvent) => this.handleChatPageEventState(data)));
    this.subscriptions$.add(this.selectChatReadEvent$.subscribe((data?: ChatReadEvent | null) => this.bindChatReadEvent(data)));
    this.subscriptions$.add(this.selectUnreadPatientsChatCollection$.subscribe((unreadPatients: UnreadPatientsChatCollection[]) => this.bindUnreadPatientsChatCollection(unreadPatients)));

  }

  public onStartChatClick(): void {
    const phone = this.commonUtilsService.removePhoneNumberFormat(this.newChat.value.patientPhone);
    if (phone) {
      this.store.dispatch(invokeGetPatientByPhone({ phone: phone }));
    }
  }

  public fnShowData(dataDisplay: string): void {
    this.dialerClick = dataDisplay;
    if (dataDisplay === this.chatActions.recent)
      this.store.dispatch(invokeRecentPatientChatCollection({ pageSize: 0 }));
    if (dataDisplay === this.chatActions.unread) {
      this.store.dispatch(invokeGetUnreadPatientChatCollection());
    }
  }

  public bindRecentPatientChats(data?: RecentPatientChat[]): void {
    this.arrRecentPatients = [];
    if (data === undefined || data === null) return;
    this.arrRecentPatients = [...data];
    this.hasUnReadChat = (this.arrRecentPatients.filter(_ => _.numberOfUnReadMessages > 0).length > 0);
  }

  bindPatientCommunication(data: PatientCommunication[]) {
    this.patientCommunicationActivePopupLength = data.length;
    this.patientCommunications = data;
    const indexes = this.patientCommunications
      .map((communication, index) => communication.communicationType === 'PatientNewChat' ? index : -1)
      .find(index => index !== -1);
    this.popupIndex = indexes ?? 0;
  }

  async handleChatPageEventState(data: PatientChatStateEvent | undefined) {
    if (!data) return;
    if (data) {
      this.phoneNumberSearchloading = data.phoneNumberSearchloading ?? false
      this.recentChatloading = data.chatLogloading ?? false
    }
    if (data.showErrorAlert) {
      await this.alert.failureAlert(data.errorMessage)
      this.store.dispatch(closeErrorAlert())
    }
  }

  bindPaitentChat(data?: ChosenPatientChat): void {
    this.patientName = "Potential Patient";
    if (data) {
      this.patientName = `${data.patientLastName} ${data.patientFirstName}`;
      const patientCommunication: PatientCommunication = {
        communicationType: 'PatientChat',
        patientFirstName: data.patientFirstName,
        patientLastName: data.patientLastName,
        patientId: data.patientId,
        patientName: this.patientName,
        patientPrimaryPhone: data.patientFormattedPrimaryPhone ?? data.patientPhone,
      }
      const popupExists = (this.patientCommunications.filter(_ => _.patientPrimaryPhone === this.newChat.value.patientPhone
        && (_.communicationType === 'PatientChat' || _.communicationType === 'PatientNewChat')).length > 0)
      if (popupExists) return;
      this.openPatientChatmodal(patientCommunication, 'PatientChat');
    }
  }

  openPatientChatmodal(data: PatientCommunication, communicationType: string) {
    if (!this.patientCommunicationPopUpService.allowOpenPopup(this.patientCommunicationActivePopupLength)) return;
    this.patientCommunicationActionService.onAddPatientCommunication(data);
    this.patientCommunicationPopUpService.openPatientCommunicationPopup(communicationType, false, data, this.patientCommunicationActivePopupLength);
  }

  closeModal() {
    this.onClose.emit(this.popupIndex);
    this.store.dispatch(resetChosenPatientChatState());
  }

  public fnGetPatientData() {
    // this.oPatientSvc.fnGetPatients(20, 1, 'PatientId', 'desc').subscribe((oData: any) => {
    //   this.patientData = oData;
    //})
  }

  onSearchPatientValueChange(sSearchText: string): void {
    // if (!sSearchText) {
    //   this.fnGetPatientData();
    //   return;
    // };


    // this.bFetchedPatients = false;
    // this.oPatientSvc.getPatientsByName(sSearchText, 20)
    //   .subscribe((results: any[]) => {
    //     if (this.sSearchPatientNameText.trim().toLowerCase() !== sSearchText.trim().toLowerCase()) return;
    //     this.patientData = [];
    //     this.patientData = results;
    //     this.bFetchedPatients = true;
    //     // this.bFetchingPatients = false;
    //   });
  }

  public onChatIconClick(oCall: any): void {
    // console.log(oCall);
    // const sPatientPhone = oCall.primaryPhone;
    // const sDialogId: string = 'chat_' + oCall.patientId + (oCall.patientId === 0 ? '_' + sPatientPhone : '');

    // const sFirstName = oCall.patientFirstName;
    // const sLastName = oCall.patientLastName;

    // const oChatMatDlg: MatDialogRef<any, any> = this.oMatDlg.getDialogById(sDialogId);
    // if (!!oChatMatDlg) {
    //   if (oChatMatDlg.componentInstance.bIsMatDialogMinimized) {
    //     oChatMatDlg.componentInstance.bIsMatDialogMinimized = false;
    //     oChatMatDlg.removePanelClass('mat-dialog-collapsed');
    //   }
    //   return;
    // }

    // const nDialogs: number = this.oMatDlg.openDialogs.length;
    // if (nDialogs === 4) {
    //   alert('Please close current dialog(s) and try again');
    //   return;
    // }

    // this.oMatDlg.open(ChatContentsComponent, {
    //   id: sDialogId,
    //   hasBackdrop: false,
    //   panelClass: ["mat-dialog-zero-p", "mat-dialog-ms-" + nDialogs, "mat-dialog-mt-auto", "mat-dialog-chat-contents"],
    //   height: "500px",
    //   width: "300px",
    //   disableClose: true,

    //   data: {
    //     contact: {
    //       patientId: oCall.patientId,
    //       primaryPhone: sPatientPhone,
    //       patientFirstName: sFirstName,
    //       patientLastName: sLastName
    //     },
    //     sDialogId: sDialogId
    //   }
    // });
  }

  public onCallIconClick(oCall: any): void {
    // console.log(oCall);
    // const sPatientPhone = (oCall.isIncomingCall || oCall.isMissedCall ? oCall.from : oCall.phone);
    // const sDialogId: string = 'call_' + oCall.patientId + (oCall.patientId === 0 ? '_' + sPatientPhone : '');
    // const sFirstName = oCall.patientFirstName;
    // const sLastName = oCall.patientLastName;

    // const oCallMatDlg: MatDialogRef<any, any> = this.oMatDlg.getDialogById(sDialogId);
    // if (!!oCallMatDlg) {
    //   if (oCallMatDlg.componentInstance.bIsMatDialogMinimized) {
    //     oCallMatDlg.componentInstance.bIsMatDialogMinimized = false;
    //     oCallMatDlg.removePanelClass('mat-dialog-collapsed');
    //   }
    //   return;
    // }

    // const nDialogs: number = this.oMatDlg.openDialogs.length;
    // if (nDialogs === 4) {
    //   alert('Please close current dialog(s) and try again');
    //   return;
    // }

    // this.oMatDlg.open(CallStateComponent, {
    //   id: sDialogId,
    //   hasBackdrop: false,
    //   panelClass: ["mat-dialog-zero-p", "mat-dialog-ms-" + nDialogs, "mat-dialog-mt-auto", "mat-dialog-call-state"],
    //   height: "500px",
    //   width: "300px",
    //   disableClose: true,

    //   data: {
    //     contact: {
    //       patientId: oCall.patientId,
    //       primaryPhone: sPatientPhone,
    //       patientFirstName: sFirstName,
    //       patientLastName: sLastName
    //     },
    //     sDialogId: sDialogId,
    //     phoneNumber: sPatientPhone,
    //   }
    // });
  }

  //public onCallClick(oCall: any): void {
  // const sPatientPhone = oCall.patientPhone;
  // const sDialogId: string = 'call_' + oCall.patientId + (oCall.patientId === 0 ? '_' + sPatientPhone : '');
  // const sFirstName = oCall.patientFirstName;
  // const sLastName = oCall.patientLastName;

  // const oCallMatDlg: MatDialogRef<any, any> = this.oMatDlg.getDialogById(sDialogId);
  // if (!!oCallMatDlg) {
  //   if (oCallMatDlg.componentInstance.bIsMatDialogMinimized) {
  //     oCallMatDlg.componentInstance.bIsMatDialogMinimized = false;
  //     oCallMatDlg.removePanelClass('mat-dialog-collapsed');
  //   }
  //   return;
  // }

  // const nDialogs: number = this.oMatDlg.openDialogs.length;
  // if (nDialogs === 4) {
  //   alert('Please close current dialog(s) and try again');
  //   return;
  // }

  // this.oMatDlg.open(CallStateComponent, {
  //   id: sDialogId,
  //   hasBackdrop: false,
  //   panelClass: ["mat-dialog-zero-p", "mat-dialog-ms-" + nDialogs, "mat-dialog-mt-auto", "mat-dialog-call-state"],
  //   height: "500px",
  //   width: "300px",
  //   disableClose: true,

  //   data: {
  //     contact: {
  //       patientId: oCall.patientId,
  //       primaryPhone: sPatientPhone,
  //       patientFirstName: sFirstName,
  //       patientLastName: sLastName
  //     },
  //     sDialogId: sDialogId,
  //     phoneNumber: sPatientPhone,
  //   }
  // });
  //}
  //public onChatClick(oCall: any): void {
  // const sPatientPhone = oCall.patientPhone;

  // const sDialogId: string = 'chat_' + oCall.patientId + (oCall.patientId === 0 ? '_' + sPatientPhone : '');

  // const sFirstName = oCall.patientFirstName;
  // const sLastName = oCall.patientLastName;

  // const oChatMatDlg: MatDialogRef<any, any> = this.oMatDlg.getDialogById(sDialogId);
  // if (!!oChatMatDlg) {
  //   if (oChatMatDlg.componentInstance.bIsMatDialogMinimized) {
  //     oChatMatDlg.componentInstance.bIsMatDialogMinimized = false;
  //     oChatMatDlg.removePanelClass('mat-dialog-collapsed');
  //   }
  //   return;
  // }

  // const nDialogs: number = this.oMatDlg.openDialogs.length;
  // if (nDialogs === 4) {
  //   alert('Please close current dialog(s) and try again');
  //   return;
  // }

  // this.oMatDlg.open(ChatContentsComponent, {
  //   id: sDialogId,
  //   hasBackdrop: false,
  //   panelClass: ["mat-dialog-zero-p", "mat-dialog-ms-" + nDialogs, "mat-dialog-mt-auto", "mat-dialog-chat-contents"],
  //   height: "500px",
  //   width: "300px",
  //   disableClose: true,

  //   data: {
  //     contact: {
  //       patientId: oCall.patientId,
  //       primaryPhone: sPatientPhone,
  //       patientFirstName: sFirstName,
  //       patientLastName: sLastName
  //     },
  //     sDialogId: sDialogId
  //   }
  // });
  //}

  onCallClick(data: RecentPatientChat) {
    const patientCommunication: PatientCommunication = {
      communicationType: 'PatientCallDial',
      patientFirstName: data.patientFirstName,
      patientLastName: data.patientLastName,
      patientName: `${data.patientLastName}, ${data.patientFirstName}`,
      patientId: data.patientId,
      patientPrimaryPhone: CustomValidatorService.removeMobileNumberFormatting(data.primaryPhone) ?? '',
    }
    this.patientCommunicationActionService.onCallClick(patientCommunication);
  }

  onChatClick(data: RecentPatientChat) {
    const patientCommunication: PatientCommunication = {
      communicationType: 'PatientChat',
      patientFirstName: data.patientFirstName,
      patientLastName: data.patientLastName,
      patientId: data.patientId,
      patientPrimaryPhone: CustomValidatorService.removeMobileNumberFormatting(data.primaryPhone) ?? '',
    }
    this.patientCommunicationActionService.onChatClick(patientCommunication);
  }

  minimizeChatPopup() {
    this.minimizeChat = !this.minimizeChat;
  }

  bindChatReadEvent(data?: ChatReadEvent | null) {
    if (data && this.arrRecentPatients) {
      this.arrRecentPatients = this.arrRecentPatients.map((chat: RecentPatientChat) => {
        if (CustomValidatorService.removeMobileNumberFormatting(chat.primaryPhone) === data.phoneNumber)
          return { ...chat, numberOfUnReadMessages: 0 };
        return { ...chat };
      });
      this.hasUnReadChat = (this.arrRecentPatients.filter(_ => _.numberOfUnReadMessages > 0).length > 0);
    }
  }

  bindUnreadPatientsChatCollection(unreadPatients: UnreadPatientsChatCollection[]) {
    this.unreadPatients = unreadPatients || [];
  }
}
