import { Injectable, SecurityContext } from '@angular/core';
import { Store } from '@ngrx/store';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { documentDownload } from '../store/document-download-store/document-download.reducer';
import html2canvas from 'html2canvas';

@Injectable()
export class DownloadDocumentFileService {
  private isDownloadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDownloading$: Observable<boolean> = this.isDownloadingSubject.asObservable();


  constructor(private store: Store, private sanitizer: DomSanitizer) { }

  downloadDocument(documentGuid: string, fileName: string): void {
    this.store.dispatch(documentDownload({ documentGuid, fileName }));
  }

  downloadHtml2CanvasImage(elementId: string, fileName: string): void {
    this.isDownloadingSubject.next(true);
    const element = document.getElementById(elementId);
    if (element) {
      html2canvas(element, {
        useCORS: true,
        allowTaint: true,
      }).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = fileName + '.png';
        link.click();
      }).finally(() => {
        this.isDownloadingSubject.next(false);
      });
    }
  }

  async convertBlobToBase64(safeUrl: SafeUrl | null): Promise<string> {
    if (!safeUrl) {
      throw new Error('safeUrl is null');
    }
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, safeUrl.toString());
    if (!sanitizedUrl) {
      throw new Error('Invalid URL');
    }
    try {
      const response = await fetch(sanitizedUrl);
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result?.toString().split(',')[1];
          resolve(base64data || '');
        };
        reader.readAsDataURL(blob);
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  getDocuemntSafeUrl(documentUrl: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
  }
}
