<div [ngClass]="patientStatusIds.length > 0 ? 'd-block' : 'd-none'">
    <div class="border mb-3 rounded position-relative"
        [ngClass]="isPatientStatusHide ? 'd-flex justify-content-between align-items-center py-2 px-3' : 'p-3 pb-0'">
        <h6 class="h6 mb-0" [class.d-none]="!isPatientStatusHide">Patient Status</h6>
        <button class="btn btn-sm btn-light-secondary"
            [ngClass]="!isPatientStatusHide ? ' position-absolute right-0 top-0 me-3 mt-2' : '' "
            (click)="patientStatusHideShow()"><i
                class="fa fa-regular {{ !isPatientStatusHide ? 'fa-chevron-up' : ' fa-chevron-down' }}  me-2"></i>{{
            !isPatientStatusHide ? 'Hide' : ' show' }}</button>
        <div [class.d-none]="isPatientStatusHide">
            <ng-template #patientStatus></ng-template>
        </div>
    </div>
</div>

<div [ngClass]="patientExam.patientExamId && examResultIds.length > 0 ? 'd-block' : 'd-none'">
    <div class="border mb-3 rounded" [ngClass]="isPatientExamHide ? 'py-2 px-3' : 'p-3 pb-0 '">
        <div class="d-flex"
            [ngClass]="!isPatientExamHide ? 'justify-content-end' : 'justify-content-between align-items-center' ">
            <h6 class="h6 mb-0" [class.d-none]="!isPatientExamHide">Patient Exam</h6>
            <button class="btn btn-sm btn-light-secondary" [ngClass]="!isPatientExamHide ? ' mb-2' : '' "
                (click)="patientExamHideShow()"><i
                    class="fa fa-regular {{ !isPatientExamHide ? 'fa-chevron-up' : ' fa-chevron-down' }}  me-2"></i>{{
                !isPatientExamHide ? 'Hide' : ' show' }}</button>
        </div>
        <div [class.d-none]="isPatientExamHide">
            <ng-template #patientExamRef></ng-template>
        </div>
    </div>
</div>

<div class="border mb-3 rounded position-relative"
    [ngClass]="isAppointmentStatusHide ? 'd-flex justify-content-between align-items-center py-2 px-3' : 'p-3'">
    <h6 class="h6 mb-0" [class.d-none]="!isAppointmentStatusHide">Appointment Slot</h6>
    <button class="btn btn-sm btn-light-secondary"
        [ngClass]="!isAppointmentStatusHide ? ' position-absolute right-0 top-0 me-3 mt-2' : '' "
        (click)="appointmentStatusHideShow()"><i
            class="fa fa-regular {{ !isAppointmentStatusHide ? 'fa-chevron-up' : ' fa-chevron-down' }}  me-2"></i>{{
        !isAppointmentStatusHide ? 'Hide' : ' show' }}</button>
    <div [class.d-none]="isAppointmentStatusHide">
        <ng-template #appointmentSearch></ng-template>
    </div>
</div>

<div class="d-flex justify-content-end" *ngIf="hasMiscPlan">
    <a class="btn btn-light-primary gap-2 align-items-center" [routerLink]="openPatient(patientId ?? 0)"
        [target]="'_blank'">
        Open Contract Summary
    </a>
</div>