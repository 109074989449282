import { Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MASK_PATTERN } from '../../../constant/shared-pattern-regex-asset';

@Component({
  selector: 'app-form-control-timepicker',
  template: `  <div class="form-group" [class.has-error]="hasError()">

  <label [for]="labelName" *ngIf="labelName">{{ labelName }}
    <span *ngIf="required" class="required_field ms-1">*</span>
  </label>

  <kendo-timepicker
    formatPlaceholder="wide"
    [placeholder]="placeholder"
    [id]="labelName"
    [value]="selectedDate"
    [format]=timeFormat
    (valueChange)="onChange($event)"
    (blur)="onTouched()"
    [readOnlyInput]="readOnlyValue" 
    [min]="minTime"
    [max]="maxTime"
    [steps]="steps"
    [disabled]="isDisabled"
  >
  </kendo-timepicker>

  <ng-container *ngIf="hasError()">
    <app-validation-error [errors]="control?.errors" [fieldName]="labelName??fieldName">
    </app-validation-error> 
  </ng-container>
</div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlTimepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlTimepickerComponent),
      multi: true
    }
  ],
})
export class FormControlTimepickerComponent {

  @Input() labelName?: string;
  @Input() required?: boolean;
  @Input() placeholder!: string;
  @Input() stepsMinuts?: number = 15;
  @Input() FastNavigation?: boolean;
  @Input() minTime!: Date;
  @Input() maxTime!: Date;
  @Input() inputDate?: Date;
  @Input() isDisabled =false;
  @Input() fieldName?: string;

  timeFormat: string;
  steps!: any;
  control: AbstractControl | undefined;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled: boolean;
  selectedDate: Date;
  readOnlyValue = false

  constructor() {
    this.timeFormat = MASK_PATTERN.time;
    this.steps = { minute: this.stepsMinuts };
    this.placeholder = ''
    this.disabled = false;
    this.selectedDate = this.inputDate ?? new Date();
    this.minTime =new Date(2000, 1, 1, 0, 0);
    this.maxTime = new Date(2000, 1, 1, 23, 0);
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    if (value instanceof Date) {
      this.selectedDate = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

}
