<div class="row btn-page">
  <form>
    <label class="mb-2" *ngIf="labelName">{{ labelName }}
      <span class="required_field ms-1" *ngIf="required">*</span>
    </label>
    <div class="col-12" [class.has-error]="hasError()">
      <div class="input-group position-relative">
        <input class="form-control form-control-has-error" [placeholder]="placeholder" name="d2" #c2="ngModel"
          [(ngModel)]="selectedDate" ngbDatepicker #d2="ngbDatepicker" [maxDate]="maxDate" [minDate]="minDate"
          (dateSelect)="datePickerChangeEvent()" (input)="datePickerChangeEvent()" (blur)="onTouched()" maxlength="10" />
        <div class="bi bi-calendar3 position-absolute right-0 py-2 px-2 border-start z-index-9 cursor-pointer"
          (click)="d2.toggle()">
          <i class="fa fa-calendar"></i>
        </div>
      </div>
    </div>
    <ng-container *ngIf="hasError()">
      <app-validation-error [errors]="control?.errors" [fieldName]="labelName">
      </app-validation-error>
    </ng-container>
  </form>
</div>