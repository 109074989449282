import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
  AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-form-control-checkbox',
  template: `
          <div class="form-group">

            <div class="checkbox checkbox-fill d-inline">
              <input 
                type="checkbox" 
                [name]="uniqueId ? labelName + '_' + uniqueId : labelName" 
                [id]="uniqueId ? labelName + '_' + uniqueId : labelName"
                [checked]="value"
                [disabled]="disabled"

                (change)="onCheckboxChange($event)" 
                (blur)="onTouched()"
              />
              <label [for]="uniqueId ? labelName + '_' + uniqueId : labelName" class="cr d-inline">{{labelName}}</label>
            </div>

            <ng-container *ngIf="hasError()">
              <app-validation-error [errors]="control?.errors" [fieldName]="labelName">
              </app-validation-error>
            </ng-container>
             
          </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlCheckboxComponent),
      multi: true
    }
  ],
})
export class FormControlCheckboxComponent implements ControlValueAccessor, Validator {

  @Input() labelName!: string;
  @Input() controlName?: string;
  @Input() formControlName?: string;
  @Input() uniqueId?: number;

  @Output() checkBoxChangeEvent = new EventEmitter<boolean>();

  value: boolean;
  control: AbstractControl | undefined;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled: boolean;

  constructor() {
    this.disabled = false;
    this.value = false;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value ?? false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCheckboxChange(event: any) {
    this.value = (event.target as HTMLInputElement).checked;
    this.onChange(this.value);
    this.control?.markAsTouched();
    this.checkBoxChangeEvent.emit(this.value);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }
}