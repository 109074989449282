import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CustomWizardService } from '../custom-wizard.service';
import { wizardHederNavAction, wizardStepAction } from '../../../types/shared-types';
import { MaskingType } from '../../../constant/shared-constant';
import { CommonUtilsService } from '../../../utils/common-utils.service';
import { CustomValidatorService } from '../../../utils/custom-validator.service';


@Component({
  selector: 'app-third',
  templateUrl: './third.component.html',
})

export class ThirdComponent implements OnInit, OnDestroy {

  activeStepIndex = 0;
  primaryInformation: FormGroup;
  maskingType = MaskingType;

  @Output() stepSuccessEvent = new EventEmitter<any>();
  @Output() closeOverlayEvent = new EventEmitter<any>();
  private subscriptions$ = new Subscription();

  constructor(
    private customWizardService: CustomWizardService,
    private fb: FormBuilder,
    private commonUtilsService: CommonUtilsService,
  ) {

    this.primaryInformation = this.fb.group({
      firstName: ['', [Validators.required, CustomValidatorService.generalNameValidator()]],
      lastName: ['', [Validators.required, CustomValidatorService.generalNameValidator()]],
      email: ['', [Validators.required, CustomValidatorService.emailValidator()]],
      phone: ['', CustomValidatorService.phoneValidator(true)]
    })
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.customWizardService.currentStepIndex$.subscribe((activeStepIndex: number) => this.getStepIndex(activeStepIndex)));

    this.subscriptions$.add(
      this.customWizardService.currentStepActions$.subscribe((stepAction: wizardStepAction) => this.handleStepAction(stepAction))
    );
    this.subscriptions$.add(
      this.customWizardService.headerStepNavigation$.subscribe((headerStepAction: wizardHederNavAction) => this.handleHeaderStepNavigation(headerStepAction))
    );
  }

  getStepIndex(activeStepIndex: number) {
    this.activeStepIndex = activeStepIndex;
  }

  handleHeaderStepNavigation(headerStepAction: wizardHederNavAction) {
    if (!!headerStepAction) {
      if (headerStepAction.stepIndex < this.activeStepIndex) {
        this.goToPreviousStep(headerStepAction.stepIndex)
      }
      if (headerStepAction.activeStepIndex === this.activeStepIndex) {
        this.activeStepIndex = headerStepAction.stepIndex;
        this.submitForm()
      }
    }
  }

  handleStepAction(stepAction: wizardStepAction) {
    if (!!stepAction) {
      if (stepAction.title == "Primary Contact") {
        this.activeStepIndex = stepAction.stepIndex;
        this.handleFirstStepAction(stepAction.action.value);
      }
    }
  }

  handleFirstStepAction(actionValue: string): void {
    switch (actionValue) {
      case 'finish':
        this.submitForm()
        break;
      case 'back':
        this.goToPreviousStep();
        break;
      default:
        return
    }
  }

  submitForm() {
    if (this.primaryInformation.valid) {
      this.goToNextStep();
    }
    else {
      this.commonUtilsService.validateAllFormFields(this.primaryInformation);
    }
  }

  goToNextStep() {
    this.closeOverlayEvent.emit();
  }

  goToPreviousStep(index?: number) {
    this.activeStepIndex = index ?? this.activeStepIndex - 1;
    this.stepSuccessEvent.emit(this.activeStepIndex);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
