import { createAction, props } from "@ngrx/store";
import { ChiefComplaintType } from "./chief-complaints.state";

export const invokeChiefComplaints = createAction('[GetChiefComplaints API] Invoke start loading');
export const chiefComplaintsSuccess = createAction('[GetChiefComplaints] API Success', props<{ chiefComplaints: ChiefComplaintType[] }>());
export const chiefComplaintsFailure = createAction('[GetChiefComplaints] API Failure', props<{ errorMessage: string }>());

export const startChiefComplaintsLoading = createAction('[ChiefComplaints] start loading');
export const chiefComplaintsCloseSuccessAlert = createAction('[ChiefComplaints] close success alert');
export const chiefComplaintsCloseErrorAlert = createAction('[ChiefComplaints] close error alert');
export const chiefComplaintsStopLoading = createAction('[ChiefComplaints] stop loading');

export const invokeUpdateChiefComplaints = createAction('[SaveChiefComplaint] Invoke', props<{ chiefComplaint: ChiefComplaintType, successMessage: string }>());
export const updateChiefComplaintSuccess = createAction('[SaveChiefComplaint] API Success with alert message', props<{ chiefComplaints: ChiefComplaintType[] }>());
export const updateChiefComplaintFailure = createAction('[SaveChiefComplaint] API Failure', props<{ errorMessage: string }>());
export const selectedChiefComplaint = createAction('[ChiefComplaint] selected chief complaint', props<{ chiefComplaint: ChiefComplaintType }>());
export const resetChiefComplaintState = createAction('[ChiefComplaint] clear chief complaint state');