import { Store } from '@ngrx/store';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { selectOnCallInprogressphoneNumber } from '../../../store/phone-store/phone-call.selector';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { Router } from '@angular/router';
import { patientDashboardNavigateAsset } from 'src/app/features/patientdashboard/constant/patient-dashboard-api-asset';

declare const IVLDisconnect: any;
declare const sendTone: any;
declare const Mute: any;
declare const Hold: any;
//declare const Transfer: any;

@Component({
  selector: 'app-call-progress',
  templateUrl: './call-progress.component.html',
  styleUrls: ['./call-progress.component.scss']
})
export class CallProgressComponent  extends BaseDestroyComponent  implements OnInit {

  @Input() public sDisplayName!: string;
  @Input() public activeContact: any;
  @Input() public phoneNumber!: string;
  @Input() public bIsIncomingCall!: boolean;
  @Input() public bIsCallInProgress!: boolean;
  @Input() public sCallConnectTime!: string;
  @Input() public patientId!: number;

  @Output() public oAcceptCallClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public oEndCallClicked: EventEmitter<void> = new EventEmitter<void>();

  public bShowIncomingCallUI = false;
  public sCallStatus = 'Calling';
  public bIsMuted = false;
  public bShowDialpad = false;
  public bShowTransferDialpad = false;
  public callTime!: string;
  onCallInprogressphoneNumber = '';

  selectOnCallInprogressphoneNumber$ = this.store.select(selectOnCallInprogressphoneNumber);
  
  constructor(private store: Store, private router: Router,) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.selectOnCallInprogressphoneNumber$.subscribe((data: string) =>
      this.onCallInprogressphoneNumber = data
    ));

    if (this.sDisplayName.trim() !== '') {
      this.sDisplayName;
    } else if (this.phoneNumber) {
      this.sDisplayName = this.phoneNumber.slice(0, 2);
    }
    else {
      this.phoneNumber = this.activeContact?.primaryPhone;
      this.sDisplayName = this.activeContact?.patientLastName ? this.activeContact.patientLastName[0] : '';
      this.sDisplayName += this.activeContact?.patientFirstName ? this.activeContact.patientFirstName[0] : ''
    }

    if (this.bIsCallInProgress) {
      this.sCallStatus = 'Incoming call';
      this.fnSetCallConnectTime();
    }
    else {
      if (this.bIsIncomingCall) {
        this.sCallStatus = 'Incoming call';
        this.bShowIncomingCallUI = true;
      } else {
        setTimeout(() => {
          this.fnSetCallConnectTime();
          this.sCallStatus = 'Ringing';
        }, 750);
      }
    }
  }

  public onMuteUnmuteClick(): void {
    this.bIsMuted = !this.bIsMuted;
    Mute();
  }

  public onDialpadClick(): void {
    this.bShowDialpad = true;
  }

  public onTransferClick(): void {
    this.bShowTransferDialpad = true;
  }

  public onHoldClick(): void {
    Hold();
  }

  public onHideClick(): void {
    this.bShowDialpad = false;
    this.bShowTransferDialpad = false;
  }

  public acceptCall(): void {
    this.oAcceptCallClicked.emit();
  }

  public endCall(): void {
    if (this.bShowTransferDialpad) {
      // Transfer("2404796811");
      return;
    }
    try {
      // disconnect call
      if (this.onCallInprogressphoneNumber && this.phoneNumber == this.onCallInprogressphoneNumber)
        IVLDisconnect();
      else if (!this.onCallInprogressphoneNumber)
        IVLDisconnect();
    }
    catch (e) {
      console.log(e);
    }
    this.oEndCallClicked.emit();
  }

  public sendUserInput(input: number) {
    sendTone(input);
  }

  private fnSetCallConnectTime(): void {
    if (this.sCallConnectTime) {
      const sConnectTime = new Date(this.sCallConnectTime).getTime();
      const elapsedTime = Math.floor((Date.now() - sConnectTime) / 1000);
      this.callTime = this.toHoursAndMinutes(elapsedTime);

      setInterval(() => {
        const newElapsedTime = Math.floor((Date.now() - sConnectTime) / 1000)
        this.callTime = this.toHoursAndMinutes(newElapsedTime);
      }, 1000);
    }
  }

  private toHoursAndMinutes(totalSeconds: any) {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    let sSeconds = '', sMins = '', sHours = '';

    if (seconds < 10) sSeconds = '0' + seconds;
    else sSeconds = seconds.toString();

    const hours = Math.floor(totalMinutes / 60);
    if (hours < 10) sHours = '0' + hours;
    else sHours = hours.toString();

    const minutes = totalMinutes % 60;
    if (minutes < 10) sMins = '0' + minutes;
    else sMins = minutes.toString();

    return sHours + ":" + sMins + ":" + sSeconds;
  }

  goToPatientSummary() {
    if (this.patientId && this.patientId > 0)
      this.router.navigate([patientDashboardNavigateAsset.goToPatientSummary, this.patientId, 'patientsummary', this.patientId]);
  }

  @HostListener('document:EndOfCandidatesIVLSocketOnClose', ['$event'])
  fnHandleIVLSocketOnCloseEvent(event: any) {
    if (this.sCallStatus === 'Ringing')
      this.endCall();
  }
}
