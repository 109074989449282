import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { selectTimeZoneErrorMessage, selectTimeZoneList } from '../../../store/core-lookup-store/core-lookup.selector';
import { invokeGetTimeZones } from '../../../store/core-lookup-store/core-lookup.reducer';
import { TimeZone } from '../../../store/core-lookup-store/core-info';

@Component({
  selector: 'app-timezone-dropdown',
  template: `
            <div class="form-group" [class.has-error]="hasError()">

              <label  *ngIf="label">{{ label }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>

              <ng-select 
                [items]="timeZones$ | async" 
                [closeOnSelect]="true"
                [searchable]="true" 
                bindLabel="timeZoneName" 
                placeholder="Select Time Zone"
                bindValue='timeZoneId'
                [ngModel]="updatedValue"

                (ngModelChange)="selectBoxChanged($event)"
                (blur)="onTouched()"
              >
              </ng-select>

              <div class="validation_msg" *ngIf="errorMessage$ | async" >
                <span> error : {{errorMessage$ | async}}</span>
              </div>

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="label">
                </app-validation-error>
              </ng-container>

            </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimezoneDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimezoneDropdownComponent),
      multi: true
    }
  ],
})
export class TimezoneDropdownComponent implements ControlValueAccessor, Validator, OnInit {

  @Input() label?: string;
  @Input() required?: boolean | false;

  control: AbstractControl | undefined;
  updatedValue: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled!: boolean;

  timeZones$: any = this.store.pipe(select(selectTimeZoneList));
  errorMessage$: any = this.store.pipe(select(selectTimeZoneErrorMessage));

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(invokeGetTimeZones());
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.updatedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeEvent(event: any) {
    this.control?.markAsTouched();
    this.onChange(event)
  }

  selectBoxChanged(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }
}