<app-card [cardTitle]="'Address Information'">

    <form [formGroup]="addressInformation">

        <div class="row">

            <div class="col-lg-4">
                <app-form-control-textbox label="Address 1" formControlName="practiceAddress1" [maxlength]="101"
                    [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-4">
                <app-form-control-textbox label="Address 2" formControlName="practiceAddress2" [maxlength]="101">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-4">
                <app-form-control-textbox label="City" formControlName="practiceCity" [maxlength]="101" [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-4">
                <app-form-control-textbox label="State" formControlName="practiceState" [maxlength]="101" [required]="true">
                </app-form-control-textbox>
            </div>

            <div class="col-lg-4">
                <app-form-control-textbox label="Zip Code" formControlName="practiceZip" [maxlength]="5"
                    [required]="true" numericOnly>
                </app-form-control-textbox>
            </div>

            <div class="col-lg-12">
                <div class="border bg-waring p-2">
                    <pre>{{this.addressInformation.value | json}}</pre>
                    <div>
                        <span class="fw-bold">Form Status : </span>
                        <span class="fw-bold"
                            [ngClass]="{'text-danger':!this.addressInformation.valid,'text-success': this.addressInformation.valid}">
                            {{this.addressInformation.status}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </form>

</app-card>