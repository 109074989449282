import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAuthService } from '../services/user-auth.sevice';
import { UserPermission } from '../store/state/auth-user';

@Directive({
  selector: '[appUserHasPermission]'
})
export class UserHasPermissionDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: UserAuthService
  ) {}

  @Input() set appUserHasPermission(permission: UserPermission) {
    const hasPermission = this.authService.hasPermission([permission]);

    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}