import { ErrorHandler, Injectable } from "@angular/core";
import { BrowserLoggerService } from "./logger.service";

@Injectable({
    providedIn: 'root'
  })
export class GlobalErrorHandlerService extends ErrorHandler {

    constructor(private loggerService: BrowserLoggerService) {
        super();
    }

    override handleError(error: any) {
        this.loggerService.error(error); 
    }
}