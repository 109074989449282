import { Injectable, Injector, createNgModule } from '@angular/core';
import { templateCategory } from '../../letter-template/constant/letter-template.constant';
import { FollowUpSubCategoryName } from '../../patient-communication/constant/patient-communication.constant';

@Injectable({
    providedIn: 'root'
})
export class DynamicModuleService {
    constructor(
        private injector: Injector
    ) { }

    async loadModuleAndComponent(
        moduleName: string,
        componentRef: any,
        moduleRef: any,
        container: { createComponent: (arg0: any) => any },
        input: any
    ) {
        componentRef?.destroy();

        if (!moduleRef) {
            const importedModule = await this.getModulePath(moduleName);
            moduleRef = createNgModule(importedModule[Object.keys(importedModule)[0]], this.injector);
        }

        if (moduleRef) {
            const importedComponent = await this.getComponentPath(moduleName);
            componentRef = container?.createComponent(importedComponent[Object.keys(importedComponent)[0]]);

            this.setComponentInput(moduleName, componentRef, input);
        }

        return componentRef;
    }

    private setComponentInput(moduleName: string, componentRef: any, input: any) {
        if (moduleName === "servicecontract" || moduleName === "chiefcomplaint") {
            componentRef.instance.patientInfo = input.patientInfo;
            if (moduleName === "chiefcomplaint") {
                componentRef.instance.patientWizardLandingPage = input.patientInfo.patientWizardLandingPage;
            }
            if (moduleName === "servicecontract") {
                if (input.patientInfo.patientExamId)
                    componentRef.instance.examTreatmentContract = input.patientInfo;
                if (input.patientInfo.patientTreatmentPlanId)
                    componentRef.instance.selectdTreatmentContract = input.patientInfo.patientTreatmentPlanId;
                if (input.patientInfo.wizardStep)
                    componentRef.instance.wizardLandingPage = input.patientInfo.wizardStep;
            }
        } else if (moduleName === "insurance" || moduleName === "patientform" || moduleName === "medicalhistory" || moduleName === "primarycontact" || moduleName === "schedule") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.patientName = input.patientInfo.patientName;
            componentRef.instance.loadPatientInsurance = true
            if (moduleName === "schedule") {
                componentRef.instance.patientAppointmentLocationId = input.patientInfo.patientAppointmentLocationId;
                componentRef.instance.scheduleDate = input.patientInfo.patientAppointmentTime;
            }
        }
        else if (moduleName === "payment") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.contractId = input.patientInfo.contractId;
        }
        else if (moduleName === "insuranceBenefit") {
            componentRef.instance.patientInsurance = input.patientInsurance;
            componentRef.instance.patientId = input.patientInsurance.patientId;
        }
        else if (moduleName === "manageexam") {
            componentRef.instance.patientExam = input.patientInfo;
            componentRef.instance.isFromDashboard = true;
        }
        else if (moduleName === "patientInsuranceClaim") {
            componentRef.instance.patientId = input.patientId;
            componentRef.instance.patientInsuranceId = input.patientInsuranceId;
            componentRef.instance.patientLastClaimId = input.patientLastClaimId;
            componentRef.instance.patientInvoiceId = input.patientInvoiceId;
            componentRef.instance.isNewClaim = input.isNewClaim;
            componentRef.instance.isResubmitClaim = input.isResubmitClaim
        }
        else if (moduleName === "sendNotification") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.patientName = input.patientInfo.patientName;
        }
        else if (moduleName === "addNotes") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.patientName = input.patientInfo.patientName;
        }
        else if (moduleName === "resendPatientForm") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.patientName = input.patientInfo.patientName;
            componentRef.instance.isResendPatientForm = true;
            componentRef.instance.defaultContentTemplateCategoryName = templateCategory.InCompletePatientForm;
        }
        else if (moduleName === "payRemittance") {
            componentRef.instance.remittancePaymentInput = input;
        }
        else if (moduleName === "paymentFollowUp") {
            componentRef.instance.patientId = input.followUpData.patientId;
            componentRef.instance.patientName = input.followUpData.patientName;
            componentRef.instance.followUpSubCategoryName = FollowUpSubCategoryName.PastDueFollowup
            componentRef.instance.followUpData = input.followUpData;
        }
        else if (moduleName === "insuranceFollowUp") {
            componentRef.instance.patientId = input.followUpData.patientId;
            componentRef.instance.patientName = input.followUpData.patientName;
            componentRef.instance.followUpSubCategoryName = FollowUpSubCategoryName.InsuranceFollowup
            componentRef.instance.followUpData = input.followUpData;
        }
        else if (moduleName === "patientStatus") {
            componentRef.instance.patientId = input.patientId;
            componentRef.instance.patientStatusId = input.patientStatusId;
            componentRef.instance.patientStatusIds = input.patientStatusIds;
        }
        else if (moduleName === "patientExam") {
            componentRef.instance.patientExam = input.patientExam;
            componentRef.instance.examResultIds = input.examResultIds;
            componentRef.instance.isFromLiveStatus = input.isFromLiveStatus;
        }
        else if (moduleName === "patientAgreement") {
            componentRef.instance.practicePolicies = input.practicePolicies;
            componentRef.instance.patientId = input.patientId;
            componentRef.instance.patientLanguagePreference= input.patientLanguagePreference;
        }
        else if (moduleName === "appointmentSearch") {
            componentRef.instance.practiceLocationId = input.practiceLocationId;
            componentRef.instance.isFromLiveStatus = input.isFromLiveStatus;
            componentRef.instance.practiceAppointmentTypeId = input.appointmentTypeId;
            componentRef.instance.patientId = input.patientId;
            componentRef.instance.providerId = input.providerId;
            componentRef.instance.nextVistDate = input.nextVistDate;
        }
        else if (moduleName === "appointmentfollowup") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.patientName = input.patientInfo.patientName;
            componentRef.instance.followUpSubCategoryName = FollowUpSubCategoryName.AppointmentFollowup
            componentRef.instance.followUpData = input.patientInfo;
        }
        else if (moduleName === "signathomefollowup") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.patientName = input.patientInfo.patientName;
            componentRef.instance.followUpSubCategoryName = FollowUpSubCategoryName.SignAtHomeFollowup
            componentRef.instance.followUpData = input.patientInfo;
        }
        else if (moduleName === "sendWelcomeEmail") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.patientName = input.patientInfo.patientName;
            componentRef.instance.isResendWelcomeEmail = true;
            componentRef.instance.defaultContentTemplateCategoryName = templateCategory.UserWelcomeEmail;
        }
        else if (moduleName === "insuranceverification") {
            componentRef.instance.patientId = input.followUpData.patientId;
            componentRef.instance.patientName = input.followUpData.patientName;
            componentRef.instance.followUpSubCategoryName = FollowUpSubCategoryName.InsuranceVerification
            componentRef.instance.followUpData = input.followUpData;
        }
        else if (moduleName === "manageAssess") {
            componentRef.instance.patientId = input.patientInfo.patientId;
            componentRef.instance.exam = input.patientInfo
        }
        else if (moduleName === "addReferallpatient") {
            componentRef.instance.patientInfo = input.patientSummaryModel;
        }
        else if (moduleName === "showInvoices") {
            componentRef.instance.patientInfo = input.patientInfo;
            componentRef.instance.patientName = input.patientInfo.patientName;
        }
        else if (moduleName === "insuranceDetails") {
            componentRef.instance.patientInfo = input;
            componentRef.instance.patientName = input.patientName;
            componentRef.instance.patientId = input.patientId
        }
    }

    async getModulePath(moduleName: string): Promise<any> {
        switch (moduleName) {
            case "servicecontract":
                return await import("../../servicecontract/service-contract-feature.module");
            case "insurance":
                return await import("../../insurance/insurance-feature.module");
            case "patientform":
                return await import("../../practiceform/practice-form.module");
            case "medicalhistory":
                return await import("../../patient/patient-information-feature.module");
            case "chiefcomplaint":
                return await import("../../patient/patient-information-feature.module");
            case "primarycontact":
                return await import("../../patient/patient-information-feature.module");
            case "schedule":
                return await import("../../patient/patient-information-feature.module");
            case "payment":
                return await import("../../payment/payment-feature.module");
            case "insuranceBenefit":
                return await import("../../insurance/insurance-feature.module");
            case "manageexam":
                return await import("../../scheduler/module/manage-exam.module");
            case "patientInsuranceClaim":
                return await import("../../insurance/module/insurance-claim.core.module");
            case "sendNotification":
                return await import("../../patient-communication/patient-communication-module" );
            case "addNotes":
                return await import("../../patient-communication/patient-communication-module");
            case ("resendPatientForm"):
                return await import("../../patient-communication/patient-communication-module");
            case "payRemittance":
                return await import("../../payment/payment-feature.module");
            case "paymentFollowUp":
                return await import("../../patient-communication/patient-communication-module");
            case "insuranceFollowUp":
                return await import("../../patient-communication/patient-communication-module");
            case "signathomefollowup":
                return await import("../../patient-communication/patient-communication-module");
            case "appointmentfollowup":
                return await import("../../patient-communication/patient-communication-module");
            case "patientStatus":
                return await import("../../patient/module/patient-information-core.module");
            case "patientExam":
                return await import("../../scheduler/module/patient-exam.module");
            case "appointmentSearch":
                return await import("../../scheduler/module/scheduler.core.module");
            case "patientAgreement":
                return await import("../../patient/module/patient-information-core.module");
            case "sendWelcomeEmail":
                return await import("../../patient-communication/patient-communication-module" );
            case "insuranceverification":
                return await import("../../patient-communication/patient-communication-module");
            case "manageAssess":
                return await import("../../practiceform/practice-form.module");
            case "addReferallpatient":
                return await import("../../patient/patient-information-feature.module");
            case "showInvoices":
                return await import("../../contract-plan-dashboard/contract-plan-dashboard-feature.module");
            case "insuranceDetails":
                return await import("../../insurance/insurance-feature.module");
            default:
            return "";
        }
    }

    async getComponentPath(moduleName: string): Promise<any> {
        switch (moduleName) {
            case "servicecontract":
                return await import("../../servicecontract/component/sign-service-contract/service-contract-wizard/service-contract-wizard.component");
            case "insurance":
                return await import("../../insurance/component/patient-insurance-overlay/patient-insurance-overlay.component");
            case "patientform":
                return await import("../../practiceform/component/patient-form-overlay/patient-form-overlay.component");
            case "medicalhistory":
                return await import("../../patient/component/patient-medical-history-overlay-wizard/patient-medical-history-overlay-wizard.component");
            case "chiefcomplaint":
                return await import("../../patient/component/patient-overlay-wizard/patient-overlay-wizard.component");
            case "primarycontact":
                return await import("../../patient/component/patient-primary-contact-overlay/patient-primary-contact-overlay.component");
            case "schedule":
                return await import("../../patient/component/patient-schedule-overlay/patient-schedule-overlay.component");
            case "payment":
                return await import("../../payment/components/patient-payment-overlay/patient-payment-overlay.component");
            case "insuranceBenefit":
                return await import("../../insurance/component/insurance-benefit-overlay/insurance-benefit-overlay.component");
            case "manageexam":
                    return await import("../../scheduler/component/exam/manage-exam-overlay-wizard/manage-exam-overlay-wizard.component");
            case "patientInsuranceClaim":
                return await import("../../insurance/component/EDS/patient-insurance-claim-overlay/patient-insurance-claim-overlay.component");
            case "addNotes":
                return await import("../../patient-communication/component/add-notes-overlay/add-notes-overlay.component");
            case "sendNotification":
                return await import("../../patient-communication/component/send-notification-overlay/send-notification-overlay.component");
            case "resendPatientForm":
                return await import("../../patient-communication/component/send-notification-overlay/send-notification-overlay.component");
            case "payRemittance":
                return await import("../../payment/components/insurance-payment/insurance-payment.component")
            case "paymentFollowUp":
                return await import("../../patient-communication/component/follow-up-overlay/follow-up-overlay.component");
            case "insuranceFollowUp":
                return await import("../../patient-communication/component/follow-up-overlay/follow-up-overlay.component");
            case "appointmentfollowup":
                return await import("../../patient-communication/component/follow-up-overlay/follow-up-overlay.component");
            case "signathomefollowup":
                return await import("../../patient-communication/component/follow-up-overlay/follow-up-overlay.component");
            case "patientStatus":
                return await import("../../patient/component/patient-status/patient-status.component");
            case "patientExam":
                return await import("../../scheduler/component/exam/patient-exam/patient-exam.component");
            case "appointmentSearch":
                return await import("../../scheduler/component/appointment-slot-search/appointment-slot-search.component");
            case "patientAgreement":
                return await import("../../patient/component/patient-live-status-agreement/patient-live-status-agreement.component");
            case "sendWelcomeEmail":
                return await import("../../patient-communication/component/send-notification-overlay/send-notification-overlay.component");
            case "insuranceverification":
                return await import("../../patient-communication/component/follow-up-overlay/follow-up-overlay.component");
            case "manageAssess":
                return await import("../../practiceform/component/manage-assessment-overlay/manage-assessment-overlay.component");
            case "addReferallpatient":
                return await import("../../patient/component/patient-overlay-wizard/patient-overlay-wizard.component");
            case "showInvoices":
                return await import("../../contract-plan-dashboard/component/installment-plan-invoice-overlay/installment-plan-invoice-overlay.component");
            case "insuranceDetails":
                return await import("../../insurance/component/insurance-details-overlay/insurance-details-overlay.component");
            default:
            return "";
        }
    }

}