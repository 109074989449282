import { Component } from "@angular/core";
import { KendoGridService } from "../../services/kendo-grid.service";
import { SortDescriptor, State, orderBy } from "@progress/kendo-data-query";
import { GridComponent, GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { browserNames, defaultSortColumn, formatPattern, reportConst, sortingValues } from "src/app/features/report/constant/report-filter-constant";
import { BrowserService } from "../../services/browser.service";
import { NavigateURLService } from "../../services/navigate-url.service";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";

@Component({
    template: ``,
})

export class KendoGridComponent {
    state: State | undefined;
    grid: GridComponent | undefined;
    gridDateResult: GridDataResult = { data: [], total: 0 };

    sizes: number[];
    buttonCount: number;
    isExportPdf = false;
    isExportExcel = false;
    browserName!: string;
    browserNames = browserNames;
    formatPattern = formatPattern;
    defaultSortColumn = defaultSortColumn;
    gridData: any[] = [];
    hideColumn = true;
    sortColumn = "";
    filePrefix = "";
    reportConst = reportConst;
    sortOrder: 'asc' | 'desc' = sortingValues.asc;

    constructor(
        public gridService: KendoGridService,
        private browserService: BrowserService,
        private navigateURLService: NavigateURLService,
    ) {
        this.sizes = this.gridService.getGridPageSizes();
        this.exportAllData = this.exportAllData.bind(this);
        this.buttonCount = this.gridService.getGridButtonCount();
        this.browserName = this.browserService.getBrowserName();
    }

    pageChange(event: PageChangeEvent): void {
        if (this.state) {
            this.state = this.gridService.setGridStatePagination(this.state, event.skip, event.take);
            this.loadGrid(this.gridData);
        }
    }

    sortChange(sort: SortDescriptor[]): void {
        if (!sort[0].dir) {
            sort[0].dir = 'asc'
        }
        if (this.state) {
            this.state.sort = sort
            this.state = this.gridService.setGridStateSort(this.state);
        }
        this.loadGrid(this.gridData);
    }

    loadGrid(gridData: any) {
        if (this.state) {
            const gridDataOrderBy = gridData && gridData.length > 0 ? orderBy([...gridData], this.state.sort!) : [];
            if (gridDataOrderBy.length < this.state.skip!)
                this.state.skip = 0;
            this.gridDateResult = this.gridService.resetAfterReport(gridDataOrderBy, this.state);
        }
    }

    resetGrid() {
        this.state = this.gridService.getDefaultGridState(this.sortColumn, this.sortOrder);
        this.loadGrid(this.gridData);
    }

    public getCurrentDate() {
        return this.gridService.getCurrentDate();
    }

    public exportPdfExcel() {
        this.gridService.exportPdfExcel(this.grid, this.isExportPdf, this.isExportExcel)
        this.isExportPdf = false;
        this.isExportExcel = false;
    }

    public openPatient(patientId: number) {
        return this.navigateURLService.getPatientSummaryURL(patientId);
    }

    public exportAllData(): ExcelExportData {
        const result: ExcelExportData = {
            data: this.gridData
        };
        return result;
    }

    getExportPDFFileName() {
        return this.gridService.getExportPDFFileName(this.filePrefix);
    }

    getExportExcelFileName() {
        return this.gridService.getExportExcelFileName(this.filePrefix);
    }
}