import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';

import { ChatPatientsTabType, ChatReadEvent, MediaType, PatientChat, PatientChatCollection, PatientChatStateEvent } from '../../../store/chat-store/chat.state';
import { chatSendSms, invokeMarkAsRead, invokePatientChatCollection, resetChosenPatientChat, resetPatientChatFromMarkAsReadSignalR, resetPatientChatFromSignalR } from '../../../store/chat-store/chat.action';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { selectChatPageStateEvent, selectChatReadEvent, selectChosenPatientChat, selectPatientChatCollections, selectTrackingPatientChat } from '../../../store/chat-store/chat.selector';
import { DateUtilService } from 'src/app/features/shared/services/date-util.service';
import { PatientCommunicationActionService } from '../../../service/patient-communication-action-service';
import { PatientCommunication } from '../../../constant/patient-communication.constant';
import { ChatHistoryService } from '../../../service/chat-history-service';
import { CustomValidatorService } from 'src/app/features/shared/utils/custom-validator.service';

export interface MenuItem {
  id: number,
  label: string;
  subItems?: MenuItem[];
}

@Component({
  selector: 'app-chat-contents',
  templateUrl: './chat-contents.component.html',
  styleUrls: ['./chat-contents.component.scss'],
})
export class ChatContentsComponent extends BaseDestroyComponent  implements OnInit {
  public readonly ChatPatientsTabType: any = ChatPatientsTabType;
  public readonly MediaType: any = MediaType;

  @Input() public patientCommunication!: any;
  @Input() public popupIndex!: number;
  @Output() onClose = new EventEmitter<number>();

  @ViewChild('msgForm') msgForm!: NgForm;
  @ViewChildren('msgInput') msgInput:any;
  
  activeContact: any;
  public oCurrentUser: any;
  public chatCollection: any;
  loadingCollection = true;
  minimizeChat: boolean = false;
  items: MenuItem[] = [];
  patientCommunications: PatientCommunication[] = [];

  activePatientChat$= this.store.select(selectChosenPatientChat);
  selectPatientChatCollections$= this.store.select(selectPatientChatCollections);
  chatPageStateEvent$ = this.store.select(selectChatPageStateEvent);
  selectTrackingPatientChat$ = this.store.select(selectTrackingPatientChat);
  selectChatReadEvent$ = this.store.select(selectChatReadEvent);

  constructor(
    private store : Store,
    private authService: AuthService,
    private dateUtilService: DateUtilService,
    private patientCommunicationActionService: PatientCommunicationActionService,
    private chatHistoryService: ChatHistoryService) {
    super();
  }

  initMsgForm() {
    setTimeout(() => {
      this.msgForm?.reset();
      this.msgInput.first.nativeElement.focus();
      this.scrollToBottom();
    });
  }

  ngOnInit() {
    this.bindActivePaitentChat(this.patientCommunication);
    this.subscriptions$.add(this.chatPageStateEvent$.subscribe((data?: PatientChatStateEvent) => this.handleChatPageEventState(data)));
    this.subscriptions$.add(this.selectPatientChatCollections$.subscribe((data?: PatientChatCollection) => this.bindPaitentChatCollection(data)));
    this.subscriptions$.add(this.selectTrackingPatientChat$.subscribe((data?: PatientChat | null) => { if (data) { this.bindTrackingPatientChat(data); } }));
    this.subscriptions$.add(this.patientCommunicationActionService.patientCommunications$.subscribe((data: PatientCommunication[]) => this.bindPatientCommunication(data)));
    this.subscriptions$.add(this.selectChatReadEvent$.subscribe((data?: ChatReadEvent| null) => this.bindChatReadEvent(data)));
  }
  
  bindActivePaitentChat(data?: any) {
    if (data) {
      this.activeContact = data;
      this.store.dispatch(invokePatientChatCollection({ patientId: this.activeContact.patientId, patientPhone: this.activeContact.patientPrimaryPhone}))
    }
  }

  async handleChatPageEventState(data: PatientChatStateEvent | undefined) {
    if (!data) return;
    if (data && (data.patientPhone === this.activeContact.patientPrimaryPhone))
      this.loadingCollection = data.patientChatloading ?? false
  }

  bindPaitentChatCollection(data?: PatientChatCollection) {
    if (data && this.activeContact && (data.patientPhone === this.activeContact.patientPrimaryPhone)) {
      this.chatCollection = data.patients ?? [];
      this.updateChatView();
    }
  }

  bindTrackingPatientChat(data: PatientChat) {
    if (data && this.activeContact && (data.patientPhone === this.activeContact.patientPrimaryPhone) && this.chatCollection) {
      const formatDateWithTime = this.chatHistoryService.getFormattedDateWithTime(data.time);
      const updatedPatientChat = { ...data, time: formatDateWithTime }
      this.chatCollection = [...this.chatCollection, updatedPatientChat];
      this.updateChatView();
      this.store.dispatch(resetPatientChatFromSignalR({ patientChat: data }));
    }
  }

  bindPatientCommunication(data: PatientCommunication[]) {
    this.patientCommunications = data;
    const indexes = this.patientCommunications
      .map((communication, index) =>
        communication.communicationType === 'PatientChat' &&
          communication.patientPrimaryPhone === this.activeContact.patientPrimaryPhone ? index : -1)
      .find(index => index !== -1);
    this.popupIndex = indexes ?? 0;
  }

  sendMessage(e:any) {
    let bIsEmptyString = true;

    for(let i=0;i<this.msgForm.form.value.message.length;i++) {
      if(this.msgForm.form.value.message[i] !== '\n') {
        bIsEmptyString = false;
        break;
      }
    }
    if (bIsEmptyString) {
      return;
    }

    const chat = {
      isOutbound: true,
      textMessage: this.msgForm.form.value.message,
      time: this.dateUtilService.GetApplicationCurrentDateTime(),
    };

    this.msgForm?.reset();
    const phone = this.activeContact.patientPrimaryPhone;
    this.store.dispatch(chatSendSms({communicationSms: {
      patientId: this.activeContact?.patientId, toNumber: phone, text: chat.textMessage, practiceLocationId: this.authService.getLocationIdFromToken(), media: [],
      practiceId: this.authService.getCurrentPracticeId()
    }, patientChat : {
      ...chat,
      patientChatCollectionId: 0,
      patientId:  this.activeContact?.patientId,
      practiceId: this.authService.getCurrentPracticeId(),
      isRead: false,
      isInbound: false,
      isMMS: false,
      fileHtml: '',
      fileName: '',
      url: '',
      isArchived: false,
      patientPhone: this.activeContact?.patientPrimaryPhone
    }}));
    this.initMsgForm();
  }

  @ViewChild('bottomElement') bottomElementRef!: ElementRef;
  scrollToBottom() {
    setTimeout(() => {
      if (this.bottomElementRef && this.bottomElementRef.nativeElement)
        this.bottomElementRef.nativeElement.scrollTop = this.bottomElementRef.nativeElement.scrollHeight;
    }, 100);
  }

  public fnOnFileSelect(e: Event): void {
    // this.oFullLoadingDlgRef = this.oMatDlg.open(this.oFullLoadingDlg,
    //   {
    //     disableClose: true,
    //     enterAnimationDuration: "0ms",
    //     panelClass: "mat-dialog-full-view-loading",
    //     scrollStrategy: this.sso.noop()
    //   });

    // this.arrFilePreviewObj = [];
    // this.arrSelectedFiles = (e.target as HTMLInputElement).files;
    // let arrObs = [];

    // for (let i = 0; i < this.arrSelectedFiles.length; i++) {

    //   const oUploadObs = this.oVoiceSvc.uploadMedia(this.arrSelectedFiles[i], this.activeContact.patientId, this.activeContact.primaryPhone);
    //   arrObs.push(oUploadObs);
    // }

    // let nSuccessCount = 1;
    // merge(arrObs).subscribe((data: any) => {
    //   data.subscribe((oUploadMediaData: any) => {
    //     this.arrFilePreviewObj.push({data: oUploadMediaData, textMessage: ''});

    //     if(nSuccessCount === arrObs.length) {
          
    //       this.fnCancelFullLoading();

    //         this.oSendFilePreviewDlgRef = this.oMatDlg.open(this.oSendFilePreviewDlg, 
    //           {
    //             panelClass: "mat-dialog-zero-p",
    //             scrollStrategy: this.sso.noop(),
    //             width: "950px",
    //             height: "575px"
    //           });
        
    //         this.oSendFilePreviewDlgRef.afterClosed().subscribe((bConfirm: boolean) => {
    //           if(undefined === bConfirm) {
    //             this.fnCancelSendFile();
    //           }
    //         })
    //     } else {
          
    //       nSuccessCount++;
    //     }
    //   });
    // })

  }

  public fnOnOkClickOfSendFilePreview(): void {

    // this.oFullLoadingDlgRef = this.oMatDlg.open(this.oFullLoadingDlg,
    //   {
    //     disableClose: true,
    //     enterAnimationDuration: "0ms",
    //     panelClass: "mat-dialog-full-view-loading",
    //     scrollStrategy: this.sso.noop()
    //   });

    // this.fnCancelSendFile(); 

    // const oMedia: any[] = this.arrFilePreviewObj.map(oFile=> oFile.data.url);

    // let files: any[] = [];
    // this.arrFilePreviewObj.forEach((d) => {
    //   files.push({ url: d.data.url, fileName: d.data.fileName });
    // });

    // const oFileObj = this.arrFilePreviewObj[0];

    // const oChatMsgs = this.arrFilePreviewObj.map(oFileMsg => {
    //   const chat = {
    //     isOutbound: true,
    //     isMMS: true,
    //     fileName: oFileMsg.data.fileName,
    //     textMessage: '',
    //     url: oFileMsg.data.url,
    //     time: new Date().toISOString(),
    //   };
    //   return chat;
    // });

    // console.log(files);

    // let phone = this.activeContact.primaryPhone || this.activeContact.patientPhone;
    // this.oVoiceSvc.sendSms(this.activeContact.patientId, phone, this.oCurrentUser.location.practiceLocationId, oFileObj.textMessage, oMedia, files).subscribe((response) => {
    //   // this.fnCancelSendFile(); 
    //   this.chatCollection.push(...oChatMsgs);
    //   this.initMsgForm();
    //   this.fnCancelFullLoading();
    // });

  }

  public fnCancelSendFile(): void {
    // if(!!this.oSendFilePreviewDlgRef)
    //   this.oSendFilePreviewDlgRef.close();
  }

  public fnCancelFullLoading(): void {
    // if (!!this.oFullLoadingDlgRef)
    //   this.oFullLoadingDlgRef.close();
  }

  public fnHandleMenuItemClick(item: MenuItem) {
    setTimeout(() => {
      const chat = {
        isOutbound: true,
        textMessage: item.label,
        time: this.dateUtilService.GetApplicationCurrentDateTime(),
      };

      const phone = this.activeContact.patientPrimaryPhone;

      this.store.dispatch(chatSendSms({communicationSms: {
        patientId: this.activeContact?.patientId, toNumber: phone, text: chat.textMessage, practiceLocationId: this.authService.getLocationIdFromToken(), media: [],
        practiceId: this.authService.getCurrentPracticeId()
      }, patientChat : {
        ...chat,
        patientChatCollectionId: 0,
        patientId:  this.activeContact?.patientId,
        practiceId: this.authService.getCurrentPracticeId(),
        isRead: false,
        isInbound: false,
        isMMS: false,
        fileHtml: '',
        fileName: '',
        url: '',
        isArchived: false,
        patientPhone: this.activeContact?.patientPrimaryPhone
      }}));
      this.initMsgForm();
    }, 0);
  }

  public fnGetFileType(oChat:any): MediaType | void {
    // let mediaType: MediaType = MediaType.Unsupported;
    // if (!!oChat && !!oChat.url) {
    //   const extension = oChat.url.substring(oChat.url.lastIndexOf('.'), oChat.url.lastIndexOf('?'));
    //   if (arrAllowedImageTypes.includes(extension))
    //     mediaType = MediaType.Image;
    //   else if (arrAllowedVideoTypes.includes(extension)) {
    //     mediaType = MediaType.Video;
    //   } else if (arrAllowedDocTypes.includes(extension)) {
    //     mediaType = MediaType.Document;
    //   }
    // }
    // return mediaType;
  }

  public openImageInNewTab(oChat:any): void {
    // if(!oChat.url) return;
    // window.open(oChat.url, "_blank");
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetChosenPatientChat())
  }

  closeModal() {
    this.onClose.emit(this.popupIndex);
  }

  minimizeChatPopup() {
    this.minimizeChat = !this.minimizeChat;
  }


  private setSeparatorDate() {
    let separatorDate = '';
    this.chatCollection = this.chatCollection.map((message: PatientChat) => {
      const messageDate = this.chatHistoryService.formatDateToDDMonthYYYY(new Date(message.time));
      if (separatorDate !== messageDate) {
        separatorDate = messageDate;
        return {
          separatorStarted: true,
          separatorDate: this.chatHistoryService.getChatSeparatorByDate(new Date(messageDate)),
          ...message
        };
      }
      return { separatorStarted: false, separatorDate: '', ...message };
    });
  }

  private setSeparatorForPatientAvatarsByDate() {
    let separatorDate = '';
    this.chatCollection = this.chatCollection.map((message: PatientChat) => {
      const messageDate = message.time.toString();
      if (separatorDate !== messageDate && message.isInbound) {
        separatorDate = messageDate;
        return {
          separatorPatientAvatarStarted: true,
          ...message
        };
      }
      return { separatorPatientAvatarStarted: false, ...message };
    });
  }

  private setSeparatorForPracticeAvatarsByDate() {
    let separatorDate = '';
    this.chatCollection = this.chatCollection.map((message: PatientChat) => {
      const messageDate = message.time.toString();
      if (separatorDate !== messageDate && message.isOutbound) {
        separatorDate = messageDate;
        return {
          separatorPracticeAvatarStarted: true,
          ...message
        };
      }
      return { separatorPracticeAvatarStarted: false, ...message };
    });
  }

  private setUnreadStatus() {
    let isUnreadStarted = false;
    this.chatCollection = this.chatCollection.map((message: PatientChat) => {
      if (!isUnreadStarted && !message.isRead && message.isInbound) {
        isUnreadStarted = true;
        return { isUnreadStarted: true, ...message };
      }
      return { isUnreadStarted: false, ...message };
    });
  }

  
  public updateChatView(): void {
    this.setSeparatorDate();
    this.setUnreadStatus();
    this.setSeparatorForPatientAvatarsByDate();
    this.setSeparatorForPracticeAvatarsByDate();
    this.scrollToBottom();
  }

  onMessageTextareaClick() {
    if (this.chatCollection && this.chatCollection.filter((_: PatientChat) => _.isUnreadStarted).length > 0)
      this.store.dispatch(invokeMarkAsRead({
        chatReadEvent: {
          phoneNumber: this.activeContact?.patientPrimaryPhone,
          patientId: 0,
          practiceId: this.authService.getCurrentPracticeId(),
          practiceLocationId: this.authService.getLocationIdFromToken(),
          patientChatCollectionId: 0
        }
      }));
  }

  bindChatReadEvent(data?: ChatReadEvent | null) {
    if (data && this.activeContact && (data.phoneNumber === this.activeContact.patientPrimaryPhone) && this.chatCollection) {
      this.chatCollection = this.chatCollection.map((message: PatientChat) => {
        if (!message.isRead)
          return { ...message, isRead: true, isUnreadStarted: false };
        return { ...message };
      });
      this.updateChatView();
      this.store.dispatch(resetPatientChatFromMarkAsReadSignalR());
    }
  }

  onCallClick() {
    if (!this.activeContact.patientPrimaryPhone) return;
    const patientCommunication: PatientCommunication = {
      communicationType: 'PatientCallDial',
      patientFirstName: this.activeContact.patientFirstName,
      patientLastName: this.activeContact.patientLastName,
      patientName: `${this.activeContact.patientLastName}, ${this.activeContact.patientFirstName}`,
      patientId: this.activeContact.patientId,
      patientPrimaryPhone: CustomValidatorService.removeMobileNumberFormatting(this.activeContact.patientPrimaryPhone) ?? '',
    }
    this.patientCommunicationActionService.onCallClick(patientCommunication);
  }
}

