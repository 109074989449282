import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { PracticeInfoAPIService } from "../../api/practice-info-api.service";
import { PracticeFeatureConfigFailure, PracticeFeatureConfigSuccess, invokePracticeFeatureConfig } from "../action/practice-application-feature.action";
import { withLatestFrom, filter, switchMap, map, catchError, of } from "rxjs";
import { selectPracticeFeaturesInfo } from "../selector/practice-application-feature.selector";

@Injectable()
export class PracticeApplicationFeatureEffect {
    constructor(
        private actions$: Actions,
        private practiceInfoAPIService: PracticeInfoAPIService,
        private store: Store,
    ) { }

    getPracticeFeature$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokePracticeFeatureConfig),
            withLatestFrom(this.store.select(selectPracticeFeaturesInfo)),
            filter(([_, practiceFeature]) => practiceFeature.practiceId === 0),
            switchMap(([action, _]) => {
                return this.practiceInfoAPIService.getPracticeFeature(action.practiceId).pipe(
                    map(practiceFeatures => PracticeFeatureConfigSuccess({ practiceFeatures })),
                    catchError(errorMessage => of(PracticeFeatureConfigFailure({ errorMessage: errorMessage })))
                );
            })
        )
    });
}