import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { practiceRoleApiAsset } from '../constant/practice-role-asset';
import {
    PermissionOperations, PermissionTypes, PracticeModules,
    PracticeUserList, PracticeUserPermissions, PracticeUserRoles,
    PracticeRolePermissions, SavePracticeUserPermission, SavePracticeRolePermission, PracticeModuleFeature, PracticeUserPermission
} from '../store/user-roles-store/user-role-state';

@Injectable({
	providedIn: 'root'
})

export class PracticeUserRoleAPIService {

    constructor(private http: HttpClient) { }

    getPracticesRoles() {
        return this.http.get<PracticeUserRoles[]>(practiceRoleApiAsset.getPracticesRoles);
    }

    getPracticeUsers(practiceId: number) {
        const params = new HttpParams().set('practiceId', practiceId);
        return this.http.get<PracticeUserList[]>(practiceRoleApiAsset.getPracticeUsers, { params: params });
    }

    getPermissionTypes() {
        return this.http.get<PermissionTypes[]>(practiceRoleApiAsset.getPermissionTypes);
    }

    getPracticeModules() {
        return this.http.get<PracticeModules[]>(practiceRoleApiAsset.getPracticeModules);
    }

    getPracticeModuleFeatures() {
        return this.http.get<PracticeModuleFeature[]>(practiceRoleApiAsset.getPracticeModuleFeatures);
    }

    getPermissionOperations() {
        return this.http.get<PermissionOperations[]>(practiceRoleApiAsset.getPermissionOperations);
    }

    savePracticeRole(newRoleInfo: PracticeUserRoles) {
        return this.http.post<PracticeUserRoles[]>(practiceRoleApiAsset.savePracticeRole, newRoleInfo);
    }

    getPracticeRolePermissions(practiceId: string, roleId: string) {
        const params = new HttpParams().set('practiceId', practiceId).set('roleId', roleId);
        return this.http.get<PracticeRolePermissions[]>(practiceRoleApiAsset.getPracticeRolePermissions, { params: params });
    }

    getPracticeUserPermissions(practiceId: string, userId: string) {
        const params = new HttpParams().set('practiceId', practiceId).set('userId', userId);
        return this.http.get<PracticeUserPermissions[]>(practiceRoleApiAsset.getPracticeUserPermissions, { params: params });
    }

    savePracticeUserPermission(userPermissions: SavePracticeUserPermission) {
        return this.http.post<PracticeUserPermissions[]>(practiceRoleApiAsset.savePracticeUserPermission, userPermissions);
    }

    savePracticeRolePermission(rolePermissions: SavePracticeRolePermission) {
        return this.http.post<PracticeRolePermissions[]>(practiceRoleApiAsset.savePracticeRolePermission, rolePermissions);
    }

    getLoggedInUserPermissions(practiceId: string, roleId: string){
        const params = new HttpParams().set('practiceId', practiceId).set('roleId', roleId);
        return this.http.get<PracticeUserPermission>(practiceRoleApiAsset.getLoggedInUserPermissions, { params: params });
    }
}