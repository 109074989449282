import { HubConnectionState } from "@microsoft/signalr"

export const signalRConnectionStatus = {
  connected: {
    name: HubConnectionState.Connected,
    shortTerm: "CN",
    image: "assets/images/svg/signal-Connected.svg"
  },
  connecting: {
    name: HubConnectionState.Connecting,
    shortTerm: "CG",
    image: "assets/images/svg/signal-Connecting.svg"
  },
  disconnected: {
    name: HubConnectionState.Disconnected,
    shortTerm: "DC",
    image: null
  },
  disconnecting: {
    name: HubConnectionState.Disconnecting,
    shortTerm: "DG",
    image: "assets/images/svg/signal-Disconnecting.svg"
  },
  reconnecting: {
    name: HubConnectionState.Reconnecting,
    shortTerm: "RC",
    image: null
  },
  connectionError: {
    name: "Error",
    shortTerm: "ER",
    image: "assets/images/svg/signal-Error.svg"
  },
}

export const signalRConnectionMessages = {
  createdConnection: "The SignalR Hub connection is created.",
  connected: "The SignalR connection is connected.",
  connecting: "The SignalR connection is connecting.",
  disconnected: "The SignalR connection is disconnected.",
  disconnecting: "The SignalR connection is disconnecting.",
  reconnecting: "The SignalR connection is reconnecting.",
  connectingError: "Error while starting connection: ",
  reconnectingError: "Error while reconnecting connection: ",
  closingError: "Error while closing connection: ",
  locationSubscribeError: "We are attempting to subscribe to the location in SignalR; unfortunately, we are unable to do so.",
  locationUnSubscribeError: "We are attempting to unsubscribe to the location in SignalR; unfortunately, we are unable to do so."
}

export const signalRSubscribtionMethods = {
  groupSubscribe: "GroupSubscribe",
  groupUnSubscribe: "GroupUnSubscribe",
  groupSubscribeByPracticeId:"GroupSubscribeByPracticeId",
  groupUnSubscribeByPracticeId:"GroupUnSubscribeByPracticeId"

}

export const appointmentSignalREvent = "ProcessPracticeScheduleChangedEvent"

export const signalrLocationSubscriptionStatus = {
  subscribed: {
    name: "subscribed",
    shortTerm: "SS",
    color: "Green",
    colorCode: "#2ed8b6"
  },
  unsubscribed: {
    name: "unSubscribed",
    shortTerm: "SF",
    color: "Orange",
    colorCode: "#ffb64d"
  },
}