import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/features/user/services/user-auth.sevice';

export interface Permission {
  moduleName: string;
  featureName: string;
  operationName: string;
}
@Component({
  template: ''
})
export class OverlayBaseComponent implements OnInit {

  permissionSet: Permission[] = [];

  isOverlayAuthorized: boolean = true;

  constructor(private userAuthService: UserAuthService) { }

  ngOnInit(): void {
    if (this.permissionSet.length > 0) {
      this.isOverlayAuthorized = this.userAuthService.hasPermission(this.permissionSet);
    }
  }
}
