import { createAction, props } from "@ngrx/store";
import {
    InstallmentPlanSummary, PatientFrp, PatientLedger, PatientLedgerFilter,
    PatientPaymentTransaction,
    PatientSummaryModel, PatientTreatmentContract, PatientTreatmentInstallmentPlan, PatientTreatmentInvoice,
    PatientTreatmentInvoiceHistory
} from "./contract-plan-dashboard.state";

export const invokedPatientSummaryAPI = createAction('[TreatmentContractPlans GetPatientById API] invoke GetPatientById ', props<{ patientId: number }>());
export const patientSummaryAPISuccess = createAction('[GetPatientById API] GetPatientById Success', props<{ patientSummaryData: PatientSummaryModel[] }>());
export const patientSummaryAPIFailure = createAction('[GetPatientById API] GetPatientById Failure', props<{ errorMessage: string }>());

export const invokePatientTreatmentContractPlansAPI = createAction('[GetPatientTreatmentContractPlans API] PatientTreatmentContractPlans invoke Action', props<{ patientId: number }>());
export const patientTreatmentContractPlansAPISuccess = createAction('[GetPatientTreatmentContractPlans API] Success', props<{ patientTreatmentContractPlans: PatientTreatmentContract[] }>());
export const patientTreatmentContractPlansAPIFailure = createAction('[GetPatientTreatmentContractPlans API] Failure', props<{ errorMessage: string }>());

export const startPatientTreatmentContractLoading = createAction('[Patient Treatment Contract] Loading');
export const closePatientTreatmentContractSuccessAlert = createAction('[Patient Treatment Contract] Completed Success Toast');
export const closePatientTreatmentContractErrorAlert = createAction('[Patient Treatment Contract] Error Alert');
export const patientTreatmentContractStopLoading = createAction('[Patient Treatment Contract] stop loading');

export const invokePatientTreatmentInstallmentPlans = createAction('[PatientTreatmentInstallmentPlans] PatientTreatmentContractPlans invoke Action', props<{ patientTreatmentInstallmentPlans: PatientTreatmentInstallmentPlan[] }>());
export const invokeSelectedPatientTreatmentInstallmentPlan = createAction('[SelctPatientTreatmentInstallmentPlan] PatientTreatmentContractPlans invoke Action', props<{ patientTreatmentInstallmentPlan: PatientTreatmentInstallmentPlan }>());
export const invokeSelectedInstallmentPlanInvoice = createAction('[SelectedInstallmentPlanInvoice] PatientTreatmentContractPlans invoke Action', props<{ PatientTreatmentInvoices: PatientTreatmentInvoice[] }>());
export const patientTreatmentContractPlanStateDestroy = createAction('[patientTreatmentContractPlanStateDestroy]invoke destory');
export const invokeSelectedInstallmentPlanSummary = createAction('[SelectedInstallmentPlanSummary] PatientTreatmentContractPlans invoke Action', props<{ installmentPlanSummary: InstallmentPlanSummary }>());

export const invokeGetPatientFrp = createAction('[Dashboard GetPatientFrpList API] Invoke GetPatientFrpList API', props<{ patientId: number }>());
export const GetPatientFrpSuccess = createAction('[Dashboard GetPatientFrpList API] GetPatientFrpList API Success', props<{ patientFrpList: PatientFrp[] }>());
export const GetPatientFrpFailure = createAction('[Dashboard GetPatientFrpList API] GetPatientFrpList API Failure', props<{ errorMessage: string }>());

export const invokePatientTreatmentContractLedgersAPI = createAction('[PatientTreatmentContractLedger API] PatientTreatmentContractPlans invoke Action', props<{ patientLedgerFilter: PatientLedgerFilter }>());
export const patientTreatmentContractLedgerAPISuccess = createAction('[PatientTreatmentContractLedger API] Success', props<{ patientLedger: PatientLedger[] }>());
export const patientTreatmentContractLedgerAPIFailure = createAction('[PatientTreatmentContractLedger API] Failure', props<{ errorMessage: string }>());

export const startPatientTreatmentContractLedgerLoading = createAction('[Patient Treatment Contract Ledger] Loading');
export const patientTreatmentContractLedgerStopLoading = createAction('[Patient Treatment Contract Ledger] stop loading');

export const invokeGetPatientTreatmentInvoiceHistoryAPI = createAction('[GetPatientTreatmentInvoiceHistory API] PatientTreatmentContractPlans invoke Action', props<{ patientId: number, patientTreatmentInvoiceId: number }>());
export const getPatientTreatmentInvoiceHistoryAPISuccess = createAction('[GetPatientTreatmentInvoiceHistory API] Success', props<{ patientTreatmentInvoiceHistory: PatientTreatmentInvoiceHistory[] }>());
export const getPatientTreatmentInvoiceHistoryAPIFailure = createAction('[GetPatientTreatmentInvoiceHistory API] Failure', props<{ errorMessage: string }>());

export const invokeGetPatientTreatmentInvoiceReceiptHistoryAPI = createAction('[GetPatientTreatmentInvoiceReceiptHistory API] PatientTreatmentContractPlans invoke Action', props<{ patientId: number, patientTreatmentContractId: number }>());
export const getPatientTreatmentInvoiceReceiptHistoryAPISuccess = createAction('[GetPatientTreatmentInvoiceReceiptHistory API] Success', props<{ patientTreatmentInvoiceReceiptHistory: PatientTreatmentInvoiceHistory[] }>());
export const getPatientTreatmentInvoiceReceiptHistoryAPIFailure = createAction('[GetPatientTreatmentInvoiceReceiptHistory API] Failure', props<{ errorMessage: string }>());

export const invokePatientPaymentTransactionsAPI = createAction('[GetPatientPaymentTransactions API] PatientTreatmentContractPlans invoke Action', props<{ patientId: number, patientTreatmentContractId: number }>());
export const patientPaymentTransactionsAPISuccess = createAction('[GetPatientPaymentTransactions API] Success', props<{ patientPaymentTransaction: PatientPaymentTransaction[] }>());
export const patientPaymentTransactionsAPIFailure = createAction('[GetPatientPaymentTransactions API] Failure', props<{ errorMessage: string }>());
