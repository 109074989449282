import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SignalRState } from './signalr.state'; 

export const selectSignalRState = createFeatureSelector<SignalRState>('signalR');

// Select individual properties from the SignalR state
export const selectConnectionStatus = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.connectionStatus
);

export const selectConnectionStateEvents = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.connectionStateEvent
);

export const selectConnectionUpdateDate = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.connectionUpdatedDate
);

export const selectScheduleMessage = createSelector(
  selectSignalRState,
  (state: SignalRState) =>  ({ event: state.scheduleEventState.eventName, data: state.scheduleEventState.scheduleMessage }) 
);

export const selectSignalrScheduleErrorStateEvent = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.scheduleEventState.scheduleErrorStateEvent
);

export const selectLocationSubscriptionStateEvent = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.scheduleEventState
);

export const selectSubcripedLocation = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.scheduleEventState.subscribeLocation
);

export const selectCallEventState = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.callEventState
);  

export const selectChatEventState = createSelector(
  selectSignalRState,
  (state: SignalRState) => state.chatEventState
);