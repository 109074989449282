import { Component, Input } from '@angular/core';
import { PatientTreatmentInstallmentPlan } from '../../store/contract-plan-dashboard/contract-plan-dashboard.state';

@Component({
  selector: 'app-contract-ledger-print',
  templateUrl: './contract-ledger-print.component.html',
})
export class ContractLedgerPrintComponent {
  @Input() LedgerDetails: any[] = [];
  @Input() creditDebitPlan: any = {};
  @Input() isWalletLedger: boolean = false;
  @Input() totalCharge: number = 0;
  @Input() totalPrincipal: number = 0;
  @Input() totalInterest: number = 0;
  @Input() totalBalance: number = 0;
  @Input() totalPaymentCollected: number = 0;
  @Input() selectedPatientTreatmentInstallmentPlan!: PatientTreatmentInstallmentPlan;
}
