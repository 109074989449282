<div [ngClass]="{'card p-2':this.pageIdentifier === this.patientSearchType.bulkEmailPatientSearch}">
    <div *ngIf="isPageLoading">
        <app-table-skeleton [rows]="5"></app-table-skeleton>
    </div>
    <kendo-grid *ngIf="!isPageLoading" [selectable]="{ enabled: allowToSelectPatient,checkboxOnly: true, mode: 'multiple'}" class="opms-k-grid" [data]="patientSearchData" [pageSize]="state.take"
      kendoGridSelectBy="patientId" (selectionChange)="onSelectionChange()" [(selectedKeys)]="selectedPatientIds"  [skip]="state.skip" [sortable]="true" [sort]="state.sort" [pageable]="true"
        (dataStateChange)="dataStateChange($event)" [loading]="isLoading">

        <!-- responsive div start here -->
        <ng-template kendoGridDetailTemplate let-dataItem *ngIf="isDesktop">
            <div class="mt-1 mb-2"><b>Contact :</b>
                <div>{{ dataItem.patientPrimaryPhone | phoneNumber }}</div>
                <div *ngIf="dataItem.patientSecondaryPhone">{{ dataItem.patientSecondaryPhone | phoneNumber }}</div>
            </div>
            <div class="mt-1 mb-2"><b>Location :</b> {{dataItem.practiceLocationName}}</div>
            <div class="mt-1 mb-2"><b>Status :</b> {{dataItem.patientStatusName}}</div>
            <div class="d-flex mt-1 mb-2">
                <app-patient-search-formstatus [patientInfo]="dataItem" [tippyZIndex]="9999"/>
            </div>
        </ng-template>
        <!-- responsive div end here -->
        
        <kendo-grid-checkbox-column [width]="35" *ngIf="allowToSelectPatient"  (selectAllChange)="onSelectAllChange($event)"  [showSelectAll]="true">
            <ng-template kendoGridHeaderTemplate>
                <input class="k-checkbox k-checkbox-md k-rounded-md"
                    id="selectAllCheckboxId"
                    type="checkbox"
                    kendoCheckBox
                    kendoGridSelectAllCheckbox
                    (selectAllChange)="onSelectAllChange($event)"
                />
            </ng-template> 
        </kendo-grid-checkbox-column>
        <kendo-grid-column field="patientName" title="Patient" [width]="200">
            <ng-template kendoGridCellTemplate let-patient>
                <div class="d-flex gap-1 justify-content-between flex-wrap">
                    <a class="btn btn-link text-left" (click)="goToPatientSummary(patient.patientId)"
                        [routerLink]="redirectToPatientSummaryDashboardRoute(patient.patientId)">{{patient.patientName}}</a>
                    <div class="text-left">
                        <span class="badge badge-light-info" *ngIf="patient.isActive; else inactive">Active</span>
                        <ng-template #inactive><span class="badge badge-light-danger">Inactive</span></ng-template>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="patientDob" title="DOB" [width]="100">
            <ng-template kendoGridCellTemplate let-patient>
                <div>{{ patient.patientDob | date:'MM/dd/yyyy' }}</div>
                <div>Age: {{ patient.patientAge }}</div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="patientPrimaryEmailAddress" title="Email Address" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="patientPrimaryPhone" title="Contact" [width]="150"
            media="xl">
            <ng-template kendoGridCellTemplate let-patient>
                <div>{{ patient.patientPrimaryPhone | phoneNumber }}</div>
                <div *ngIf="patient.patientSecondaryPhone">{{ patient.patientSecondaryPhone | phoneNumber }}</div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="practiceLocationName" title="Location" [width]="150" media="xl">
        </kendo-grid-column>
        <kendo-grid-column field="patientStatusName" title="Status" [width]="180" media="xl">
        </kendo-grid-column>
        <kendo-grid-column title="Form Status" [width]="275" media="xl">
            <ng-template kendoGridCellTemplate let-patient>
                <div class="d-flex justify-content-between">
                    <app-patient-search-formstatus [patientInfo]="patient" [tippyZIndex]="9999"/>
                </div>
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-data>
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>

    </kendo-grid>
</div>