import { createAction, createReducer, on, props } from '@ngrx/store';
import { DocumentCategory, DocumentCoreState } from './document-core-state';

export const getDocumentCategories = createAction('[DocumentCategories API] Get DocumentCategories API');
export const getDocumentCategoriesSuccess = createAction('[DocumentCategories API] DocumentCategories API Success', props<{ documentCategories: DocumentCategory[] }>());
export const getDocumentCategoriesFailure = createAction('[DocumentCategories API] DocumentCategories API Failure', props<{ errorMessage: string }>());

export const initialState: Readonly<DocumentCoreState> = {};

export const documentCoreReducer = createReducer(
  initialState,
  on(getDocumentCategoriesSuccess, (state, { documentCategories }): DocumentCoreState => ({
    ...state,
    documentCategoryInfo: { ...state.documentCategoryInfo, documentCategories: documentCategories }
  })),
  on(getDocumentCategoriesFailure, (state, { errorMessage }): DocumentCoreState => {
    return ({
      ...state,
      documentCategoryInfo: {
        ...state.documentCategoryInfo,
        documentCoreStateEvent: {
          ...state.documentCategoryInfo?.documentCoreStateEvent,
          errorMessage: "Failed to load the Document Categories" + (errorMessage ? " - " + errorMessage : '')
        }
      }
    });
  }),
);