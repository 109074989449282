import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryState, TimeZone } from '../store/core-lookup-store/core-info';
import { practiceCoreApiAssert } from '../constant/shared-api-asset';

@Injectable({
	providedIn: 'root'
})

export class PracticeCoreAPIService {

	constructor(private http: HttpClient) { }

	getStates() {
		return this.http.get<CountryState[]>(practiceCoreApiAssert.getStates);
	}

	getTimeZones() {
		return this.http.get<TimeZone[]>(practiceCoreApiAssert.getTimeZones);
	}
}