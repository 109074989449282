
export interface ChatState {
  receivedPatientChatEvent?: ReceivedPatientChatEvent;
  chatStateEvent: PatientChatStateEvent;
  recentPatientChats: RecentPatientChat[];
  chosenPatientChat?: ChosenPatientChat;
  patientChatCollection?: PatientChatCollection;
  patientChatCollections: PatientChatCollection[];
  unreadPatientsChatCollection: UnreadPatientsChatCollection[];
  trackingPatientChat : PatientChat | null
  chatReadEvent : ChatReadEvent | null
}

export interface ReceivedPatientChatEvent {
  patient: PatientChatEvent;
  patientChatCollection: [];
  numberOfUnreadPatients: number;
  patientId: number;
  patientPhone: string
}

export interface PatientChatEvent {
    patientId: number;
    patientGuid: string;
    userId: number | null;
    practiceId: number;
    patientAddress1: string;
    patientAddress2: string;
    patientCity: string;
    patientState: string;
    patientZip: string;
    patientDisplayName: string;
    patientGender: string;
    patientMaritalStatus: string;
    patientPreferedName: string;
    primaryPhoneExtension: string;
    primaryPhoneType: string;
    secondaryEmail: string;
    secondaryPhone: string;
    secondaryPhoneExtension: string;
    secondaryPhoneType: string;
    patientFirstName: string;
    patientLastName: string;
    patientDob: Date | null;
    patientAge: number;
    patientEmail: string;
    patientPhone: string;
    patientPrimaryContactFirstName: string;
    patientPrimaryContactLastName: string;
    patientPrimaryContactEmail: string;
    patientPrimaryContactPhone: string;
    patientFRPFirstName: string;
    patientFRPLastName: string;
    patientFRPEmail: string;
    patientFRPPhone: string;
    patientNextAppointmentTime: Date | null;
    patientNextAppointmentLocation: string | null;
    patientLastAppointmentTime: Date | null;
    patientLastAppointmentLocation: string | null;
    receivedMessage: string;
   
}

export interface RecentPatientChat {
  patientId: number;
  practiceId: number;
  patientFirstName: string;
  patientLastName: string;
  practiceName: string | null;
  practiceNumber: string | null;
  callLogApiKey: string | null;
  textApiKey: string | null;
  dob: Date | string; // Adjust type based on usage
  primaryPhone: string;
  emailAddress: string;
  chatReceivedDate: Date | string; // Adjust type based on usage
  numberOfUnReadMessages: number;
  textMessage: string;
  isInbound: boolean;
  isOutbound: boolean;
  isMMS: boolean;
  isArchived: boolean;
  automatedTemplateName: string | null;
}

export interface PatientChat {
  patientChatCollectionId: number;
  patientId: number;
  practiceId: number;
  textMessage: string;
  isRead: boolean;
  isOutbound: boolean;
  isInbound: boolean;
  isMMS: boolean;
  time: Date;
  fileHtml: string;
  fileName: string;
  url: string;
  isArchived: boolean;
  patientPhone: string;
  separatorStarted?: boolean;
  separatorDate?: string;
  isUnreadStarted?: boolean;
  separatorPatientAvatarStarted?: boolean;
  separatorPracticeAvatarStarted?: boolean;

}

export interface ChosenPatientChat {
  patientId: number;
  patientPhone : string;
  patientFirstName : string;
  patientLastName : string;
  patientPrimaryPhone?: string;
  patientSecondaryPhone?: string;
  patientChatCollection: PatientChat[];
  patientFormattedPrimaryPhone?: string;
}

export enum ChatPatientsTabType {
  All = 0,
  Recent = 1,
  Unread = 2,
  Archived = 3,
  Chat_Contents = 4
}


export enum MediaType {
  Unsupported = -1,
  Audio = 1,  
  Document = 2,
  Image = 3,
  Video = 4
}

export interface PatientChatCollection {
  patients: PatientChat[];
  numberOfUnreadPatients: number;
  patientId?: number,
  patientPhone?:string
}

export interface PatientChatStateEvent {
  errorMessage?: string,
  showErrorAlert?: boolean,
  showSuccessToast?:boolean;
  toastMessage?:string;
  successMessage?:string
  loading?: boolean,
  isFormSubmitted?: boolean,
  showSuccessAlert?: boolean
  patientChatloading?: boolean
  patientId?: number,
  patientPhone?:string
  phoneNumberSearchloading?: boolean
  chatLogloading?: boolean
}

export interface ChatReadEvent {
  phoneNumber?: string;
  patientId?: number;
  practiceId?: number;
  practiceLocationId?: number;
  patientChatCollectionId?: number;
}


export interface UnreadPatientsChatCollection {
  patientId: number,
  practiceId: number,
  practiceLocationId: number,
  patientFirstName: string,
  patientLastName: string,
  patientName: string,
  practiceName: string,
  practiceNumber: string,
  callLogApiKey: string,
  textApiKey: string,
  dob: string,
  primaryPhone: string,
  emailAddress: string,
  chatReceivedDate: string,
  numberOfUnReadMessages: number,
  textMessage: string,
  isInbound: boolean,
  isOutbound: boolean,
  isMMS: boolean,
  isArchived: boolean
}