import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { uploadDocument, uploadComplete, uploadProgress, uploadFailure } from './document-upload.reducer';
import { DocumentAPIService } from '../../api/document-api.service';
import { DocumentUpload } from './document-upload.state';
import { of } from 'rxjs';

@Injectable()
export class DocumentUploadEffects {
  constructor(
    private actions$: Actions,
    private documentAPIService: DocumentAPIService
  ) { }

  uploadFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadDocument),
      mergeMap((action) =>
        this.documentAPIService.uploadDocument(action.formData,action.uploadType).pipe(
          map((uploadResponse: DocumentUpload) => {
            if (uploadResponse.progress === 100 && uploadResponse.documentUploadResponse) {
              return uploadComplete({ document: action.document, documentUploadResponse: uploadResponse.documentUploadResponse });
            } else {
              return uploadProgress({ document: action.document, progress: uploadResponse.progress });
            }
          }),
          catchError((error) => of((uploadFailure({ document: action.document, error: error.errorMessage }))))
        )
      )
    )
  }
  );
}