import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { patientCommunicationApiAsset } from "../constant/patient-communication-api-asset";
import { AnsweredCallDialCallInfo, DialCallInfo, DialCallToken, RecentCallLogs } from "../store/phone-store/phone-call.state";

@Injectable()

export class PhoneCallApiService {

    constructor(private http: HttpClient) { }
   
    public getDialToken(model: DialCallInfo) {
        return this.http.post<DialCallToken>(patientCommunicationApiAsset.getDialToken, model);
    }
    public callAnswered(model: AnsweredCallDialCallInfo) {
        return this.http.post<any>(patientCommunicationApiAsset.callAnswered, model);
    }
    
    public getRecentCallLogs(practiceId: number, practiceLocationId: number, startDate: string | null, endDate: string | null) {
        const param = { 'practiceId': practiceId.toString(), 'practiceLocationId': '', 'startDate': startDate ?? '', 'endDate': endDate ?? '' }
        return this.http.get<RecentCallLogs[]>(patientCommunicationApiAsset.getRecentCallLogs, { params: param });
    }
}

