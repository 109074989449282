import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { invokeGetMenu, updateMenuId, updateSelectedSubMenuItem } from 'src/app/theme/store/menu/menu.actions';
import { PracticeIdSelectionService } from './practiceid.selection.service';
import { PatientIdSelectionService } from './patientid.selection.service';
import { MenuService } from 'src/app/theme/store/menu/menu.service';
import { AuthService } from '../../user/services/authentication/auth.service';
import { AppSessionStorageService } from './app-session-storage.service';
import { applicationStorageKeys, menuKeys } from '../../user/constant/user-role-constant';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
//todo: need to refactor this service. We need to move the code from the constructor to a method. The service should not have any logic in the constructor.
//todo: the consumer of this service then have to call the method to subscribe to the router events.
export class RouteChangeService {

  private subscriptions$ = new Subscription();

  constructor(
    private router: Router,
    private store: Store,
    private practiceIdSelectionService: PracticeIdSelectionService,
    private patientIdSelectionService: PatientIdSelectionService,
    private menuService: MenuService,
    private sessionStorageService: AppSessionStorageService,
    private authService: AuthService
  ) {
      this.subscriptions$.add(
        this.router.events
          .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) => {
            this.patientIdSelectionService.updateMenuPatientId();
            this.store.dispatch(invokeGetMenu());
            const urlKeys = event.url.split('/').filter(x=> x);
            
            if (urlKeys.includes(menuKeys.practice)) {
              if (this.authService.getIsSuperAdminRoleFromToken()) {
                this.sessionStorageService.setItem(applicationStorageKeys.selectedPracticeIdBySA, urlKeys[urlKeys.length - 1]);
              } else {
                if (urlKeys.length > 2) {
                  urlKeys[urlKeys.length - 1] = this.authService.getPracticeIDFromToken();
                }
                event.url = urlKeys.join('/');
                this.router.navigate([event.url]);
              }
            }
            const menu = this.menuService.mainMenuItems.find(item => {
              if (event.url === item.url){
                if(item.title === menuKeys.report)
                  this.store.dispatch(updateSelectedSubMenuItem({menuItem: item}))
                    
                return true;
              } else {
                const matchingChildren = item.children!.filter(x => {
                  if(x.children){
                      const filteredChildren = x.children!.find(x =>event.url.includes( x.url))
                      if(filteredChildren){
                        this.store.dispatch(updateSelectedSubMenuItem({menuItem: filteredChildren}))
                      }
                        return filteredChildren ? true : false
                  }
                  else{
                    return x.url.includes(event.url.split('/')[1]);
                  }
                })
                return matchingChildren.length > 0
              }
            });

            if (menu) {
              this.store.dispatch(updateMenuId({ menuId: menu.id }));
            }
          }));

        this.practiceIdSelectionService.updateMenuPracticeId();
    }

    unsubscribe(): void {
      this.subscriptions$.unsubscribe();
    }
}