<ng-container *ngIf="!item.hidden">
  <li ngbDropdown container="body" [ngClass]="item.classes" *ngIf="item.url && !item.external">
    <div ngbDropdownToggle class="dropdown-remove mb-0 pb-0">
      <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="getRoutelink(item)" (click)="menuClick(item)"
    [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="pcoded-mtext d-flex justify-content-center" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{ item.title }}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
    </div>
    <ul ngbDropdownMenu class="bg-white shadow submenu px-0" [hidden]="!activeSubMenus || activeSubMenus.length === 0">
      <ng-container *ngFor="let submenu of activeSubMenus; let i = index">
        <li [ngClass]="submenu.classes" class="d-flex p-0 m-0 w-100">
          <a class="w-100 p-2" [target]="submenu.target ? '_blank' : '_self'" [routerLink]="[submenu.url]"  (click)="subMenuClick(submenu)"
            [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">{{submenu.title}}</a>
        </li>
      </ng-container>
    </ul>
  </li>
  <ng-template #itemIcon>
    <span *ngIf="item.icon" class="pcoded-micon d-flex justify-content-center me-0 w-100"><img class="wid-25" [src]="item.icon"/></span>
  </ng-template>
  <ng-template #itemBadge>
    <span *ngIf="item.badge && themeLayout === 'vertical'" class="pcoded-badge badge" [ngClass]="item.badge.type">
      {{ item.badge.title }}
    </span>
    <span *ngIf="item.badge && themeLayout === 'horizontal'" class="badge label" [ngClass]="item.badge.type">
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>
