<div class="card">
    <div class="card-header" *ngIf="!hidHeader" [ngClass]="headerClass">
        <h5 *ngIf="!customHeader">{{cardTitle}}</h5>
        <ng-content *ngIf="customHeader" select=".app-card-header"></ng-content>
    </div>
    <div class="card-body" [ngClass]="blockClass">
        <ng-content></ng-content>
    </div>
    <div class="card-footer" *ngIf="isCardFooter" [ngClass]="footerClass">
        <ng-content select=".app-card-footer"></ng-content>
    </div>
</div>
<!-- developer notes

    if we want custom header and footer need write like below
    
    <div class="app-card-footer">

    </div>
    <div class="app-card-footer">

    </div>
 -->