import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../../services/authentication/auth.service';
import { logIn, logInSuccess, logInFailure, logoutSuccess, logout, validateEmail, validateEmailSuccess, logoutFailure } from '../actions/authentication.action';
import { PracticeIdSelectionService } from 'src/app/features/shared/services/practiceid.selection.service';
import { UserRoleService } from '../../services/user-role.service';
import { PracticeFeaturePermissionService } from '../../services/practice-feature-permission-service';

@Injectable()
export class AuthenticationEffect {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private practiceIdSelectionService : PracticeIdSelectionService,
    private userRoleService: UserRoleService,
    private practiceFeatureService: PracticeFeaturePermissionService
  ) { }

  login$ = createEffect(() =>{
    return this.actions$.pipe(
      ofType(logIn),
      exhaustMap(action =>
        this.authService.login(action.username, action.password, action.loginLocationId).pipe(
          switchMap(authUser => {
            if (authUser.valid) {
              return this.authService.setUserPermissionAndPracticeFeatures().pipe(
                map((permission) => {
                  if (authUser.valid) {
                    const accessToken: any  =  jwt_decode(authUser.accessToken!) 
                    this.userRoleService.loadLandingPageByUserRolePermission(accessToken['Role'],permission,action.returnUrl)
                  }
                  this.practiceIdSelectionService.updateMenuPracticeId();
                  return logInSuccess({ authUser });
                }),
                catchError(error => of(logInFailure({ error })))
              );
            } else{
              return of(logInFailure({ error: authUser.errorMessage ? authUser.errorMessage : 'The user name and password are invalid.' }));
            }
          }),
          catchError(error => of(logInFailure({ error })))
        )
      )
    )
    }
  );

  validateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateEmail),
      exhaustMap(action => this.authService.validateEmail(action.username).pipe(
        map(userInfo => validateEmailSuccess({ user: userInfo })),
        catchError(error => of(logInFailure({ error })))
      )
      )
    )
  );


  Logout$ = createEffect((): any => this.actions$.pipe(
    ofType(logout),
    switchMap(() => this.authService.logout().pipe(
      tap(() => {
        this.userRoleService.redirectToAndReload('/login');
      }),
      map(() => logoutSuccess())
      ,
      catchError((error) => {
        return of(logoutFailure({ error: error.message }));
      })
    )
    )));
}