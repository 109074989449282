import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DocumentCoreState } from "./document-core-state";

export const selectDocumentCoreInfo = createFeatureSelector<DocumentCoreState>('documentCoreStore');

export const selectDocumentCategories = createSelector(
  selectDocumentCoreInfo, documentCoreState => { return documentCoreState?.documentCategoryInfo?.documentCategories; }
);

export const selectDocumentCategoriesErrorMessage = createSelector(
  selectDocumentCoreInfo, documentCoreState => {
    return documentCoreState?.documentCategoryInfo?.documentCoreStateEvent?.errorMessage;
});