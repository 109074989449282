export const documentMessageConstants = {
    notFoundCategoryOrSubCategory: 'Sorry, the category for uploading files could not be found. Please try again later.',
    incompletedProgress: 'Please wait! until the file upload is complete.',
    requiredFile: 'Please upload at least one file to continue.',
    dragfolderWarning:'Folders cannot be uploaded. Please drag and drop individual files.'
}

export const documentGuidNullValue: string | null = "00000000-0000-0000-0000-000000000000"

export const uploadType={
    toothImage:'toothImage',
    document:'document',
 }