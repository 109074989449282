import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';
import { CountConfiguration, DatasetFilterConfiguration,FilterControl,FilterEventArgument } from '../../types/filter-dataset';

@Component({
  selector: 'app-filter-dataset',
  template: `
    <div class="row mb-1 align-items-end">
        <ng-container *ngFor="let control of filterItems.controls">
            <div *ngIf="control.type === 'textbox'" class="col-lg-6">
                <div class="position-relative w-100">
                    <app-form-control-textbox [(ngModel)]="control.value" [label]="control.name"
                        [maxlength]="control.maxLength ?? 100000"
                        (onInputChange)="textBoxChanged($event,control)"></app-form-control-textbox>
                </div>
            </div>
        </ng-container>

        <div class="col-lg-6">
            <div class="d-flex gap-2 mb-3">
                <ng-container *ngFor="let control of filterItems.controls">
                    <div *ngIf="control.type === 'togglebutton'">
                        <div class="position-relative w-100">
                            <app-form-control-toggle-button [(ngModel)]="control.value" [labelName]="control.name" class="remove-space"
                                (checkBoxChangeEvent)="checkBoxChanged($event,control)"></app-form-control-toggle-button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div> 
        <div class="col-xl-12 d-flex justify-content-end mb-2">
            <div class="btn btn-light-secondary h-35" (click)="clearFilterData()">
                Clear
            </div>
        </div>
        <div class="d-flex gap-2">
            <ng-container *ngFor="let countConfiguration of filterItems.countConfigurations">
                <div class="d-flex justify-content-start gap-2 mb-2 align-items-center">
                    <b>{{countConfiguration.name}} :</b>
                    <div
                        class="wid-30 hei-30 bg-light-primary text-primary d-flex align-items-center justify-content-center rounded-3 ms-2">
                        {{countConfigurationAction(countConfiguration)}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
  `
})

export class FilterDatasetComponent implements OnChanges {
    @Input() filterItems: DatasetFilterConfiguration<any> = {data: null, controls: []};
    @Output() filterDataSetEvent = new EventEmitter<FilterEventArgument<any>>();
    dataCopy: any;

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) { 
        if(changes['filterItems']){
            this.filterItemsChanged(changes['filterItems'].currentValue);
        }
    }

    filterItemsChanged(currentValue: DatasetFilterConfiguration<any>) {
        this.filterItems = currentValue;
        this.dataCopy = this.filterItems.data;
    }

    textBoxChanged(event: any,control: FilterControl<any>) {
        control.value = event;
        this.callFilterControlActionForAllControls();
    }

    checkBoxChanged(event: any,control: FilterControl<any>) {
        control.value = event;
        this.callFilterControlActionForAllControls();
    }

    callFilterControlActionForAllControls() {
        let filteredData = [...this.dataCopy];
        this.filterItems.controls.forEach(control => {
            if(control.filterAction){
                filteredData = control
                    .filterAction({data: filteredData, value: control.value, filterConfiguration: this.filterItems});
            }
        });
        this.filterDataSetEvent.emit({data: filteredData, value: null, filterConfiguration: this.filterItems});
    }

    countConfigurationAction(countConfiguration: CountConfiguration<any>) {
        return countConfiguration.countAction(
            {data: this.filterItems.data, value: countConfiguration.value, filterConfiguration: this.filterItems});
    }

    clearFilterData() {
        const data = [...this.dataCopy];
        this.filterItems.controls.forEach(control => {
            if(control.type === 'togglebutton')
                control.value = false;
            if(control.type === 'textbox')
                control.value = '';
        });
        this.filterDataSetEvent.emit({data: data, value: null, filterConfiguration: this.filterItems});
    }
}