import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-steps-skeleton',
  template: `
            <app-card [hidHeader]="true">
              <div class="skeleton m-0 p-0">
                  <div class="row justify-content-center payment-method-hide">
                      <div class="col-lg-3 d-flex flex-column align-items-center">
                          <div class="line m-b-5 text-center" style="height:30px;width:30px; border-radius:50%"></div>
                          <div class="line m-b-20 text-center" style="height:20px;width:70%"></div>
                      </div>
                      <div class="col-lg-3 d-flex flex-column align-items-center">
                      <div class="line m-b-5 text-center" style="height:30px;width:30px; border-radius:50%"></div>
                      <div class="line m-b-20 text-center" style="height:20px;width:70%"></div>
                      </div>
                      <div class="col-lg-3 d-flex flex-column align-items-center">
                      <div class="line m-b-5 text-center" style="height:30px;width:30px; border-radius:50%"></div>
                      <div class="line m-b-20 text-center" style="height:20px;width:70%"></div>
                      </div>
                  </div>

                  <div class="row mt-3 payment-method-hide">
                        <div class="col-xl-4 col-lg-5 col-sm-12 mb-3">
                            <div class="line m-b-20 text-center" style="height:20px;width:30%"></div>
                            <div class="row justify-content-between m-0 p-0">
                                <div class="col-6 d-flex align-items-center gap-3">
                                    <div class="line text-center wid-35" style="height:35px;border-radius:50%"></div>
                                    <div class="line text-center" style="height:25px;width:30%"></div>
                                </div>
                                <div class="col-6 m-0 p-0">
                                    <div class="skeleton m-0 p-0">
                                        <div class="line text-center mb-2 ms-auto" style="height:20px;width:30%"></div>
                                        <div class="line text-center ms-auto" style="height:20px;width:30%"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="line text-center mt-4" style="height:80px;width:100%"></div>
                        </div>
                        <div class="col-xl-5 col-lg-7 col-sm-12 mb-3">
                            <div class="line m-b-10 text-center" style="height:20px;width:30%"></div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="line text-center mt-1" style="height:130px;width:100%"></div>
                                </div>
                                <div class="col-6">
                                    <div class="line text-center mt-1" style="height:130px;width:100%"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-sm-12">
                            <div class="line m-b-10 text-center" style="height:20px;width:30%"></div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="line text-center mt-1" style="height:70px;width:100%"></div>
                                </div>
                                <div class="col-6">
                                    <div class="line text-center mt-1" style="height:70px;width:100%"></div>
                                </div>
                                <div class="col-6">
                                    <div class="line text-center mt-1" style="height:70px;width:100%"></div>
                                </div>
                                <div class="col-6">
                                    <div class="line text-center mt-1" style="height:70px;width:100%"></div>
                                </div>
                            </div>
                        </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-9 col-lg-12">
                        <div class="line m-b-10 text-center" style="height:20px;width:15%"></div>
                        <div class="d-flex flex-wrap gap-2">
                            <div class="line m-b-10 text-center" style="height:120px;width:170px"></div>
                            <div class="line m-b-10 text-center" style="height:120px;width:170px"></div>
                            <div class="line m-b-10 text-center" style="height:120px;width:170px"></div>
                            <div class="line m-b-10 text-center" style="height:120px;width:170px"></div>
                            <div class="line m-b-10 text-center" style="height:120px;width:170px"></div>
                            <div class="line m-b-10 text-center" style="height:120px;width:170px"></div>
                        </div>
                        <div class="line m-t-10 m-b-10 text-center" style="height:20px;width:15%"></div>
                        <div class="d-flex flex-wrap gap-2">
                            <div class="line m-b-10 text-center" style="height:38px;width:120px"></div>
                            <div class="line m-b-10 text-center" style="height:38px;width:120px"></div>
                            <div class="line m-b-10 text-center" style="height:38px;width:120px"></div>
                            <div class="line m-b-10 text-center" style="height:38px;width:120px"></div>
                            <div class="line m-b-10 text-center" style="height:38px;width:120px"></div>
                            <div class="line m-b-10 text-center" style="height:38px;width:120px"></div>
                        </div>
                        <div class="line m-t-10 m-b-10 text-center" style="height:20px;width:15%"></div>
                        <div class="line m-t-10 m-b-10 text-center" style="height:50px;width:100%"></div>
                    </div>
                  </div>
              </div>
          </app-card>
          `,
})
export class PaymentStepsSkeletonComponent {
}
