import { createAction, props } from "@ngrx/store";
import { GetPatientLiveAppointmentsByDate, PatientAllergy } from "./patient-live-status-state";
import { Appointment, Appointments, PracticeLocation, SchedulerConfiguration } from "src/app/features/scheduler/store/scheduler-store/scheduler-state";


export const invokeGetPatientLiveAppointmentsByDate = createAction(
    '[invokeGetPatientLiveAppointmentsByDate API] Invoke',
    props<{ GetPatientAppointmentsByDate: GetPatientLiveAppointmentsByDate }>()
);

export const getPatientAppointmentsByDateSuccess = createAction(
    '[GetPatientLiveAppointmentsByDate API] Success',
    props<{ appointments: Appointments[] }>()
);

export const getPatientAppointmentsByDateFailure = createAction(
    '[GetPatientLiveAppointmentsByDate API] Failure',
    props<{ error: string }>()
);

export const invokePatientLiveSaveStatusAppointment = createAction('[PatientLiveStatusSaveAppointment API] Invoke',
    props<{ appointment: Appointment, action: string, }>());

export const savePatientLiveSaveStatusAppointmentSuccess = createAction(
    '[PatientLiveStatusSaveAppointment API] Success',
    props<{ appointment: Appointments, action: string, }>()
);

export const savePatientLiveSaveStatusAppointmentFailure = createAction(
    '[PatientLiveStatusSaveAppointment API] Failure',
    props<{ error: string }>()
);

//GetPracticeChairConfig
export const invokeGetPracticeChairConfigforPatientLiveStatus = createAction(
    '[GetPracticeChairConfig API patientLiveStatus] Invoke',
    props<{ practiceId: number, practiceLocationId: number }>()
);

export const getPracticeChairConfigforPatientLiveStatusSuccess = createAction(
    '[GetPracticeChairConfig API patientLiveStatus] Success',
    props<{ schedulerConfiguration: SchedulerConfiguration[] }>()
);

export const getPracticeChairConfigforPatientLiveStatusFailure = createAction(
    '[GetPracticeChairConfig API patientLiveStatus] Failure',
    props<{ error: string }>()
);

export const resetPatientLiveStatusStateEvents = createAction('[GetPracticeChairConfig API patientLiveStatus] Failure');

// signalR Actions
export const updateAppointmentsToliveStatusStore = createAction(
    '[updateAppointmentsToliveStatusStore] updateAppointmentsToliveStatusStore',
    props<{ appointment: Appointments }>()
);

export const removeAppointmentsToliveStatusStore = createAction(
    '[removeAppointmentsToliveStatusStore] removeAppointmentsToliveStatusStore',
    props<{ appointment: Appointments }>()
);

export const invokeGetPracticeLocationsforLiveStatus = createAction('[GetPracticeLocations API] live Status locations Invoke');

export const getPracticeLocationsforLiveStatusSuccess = createAction(
    '[GetPracticeLocations API] live Status locations Success',
    props<{ practiceLocation: PracticeLocation[] }>()
);

export const getPracticeLocationsforLiveStatusFailure = createAction(
    '[GetPracticeLocations API] live Status locations Failure',
    props<{ error: string }>()
);

export const resetPatientLiveStatusStore = createAction('[Reset PatientLiveStatus Store] Reset PatientLiveStatus Store');
export const resetLiveStatusAppointmentsAndChairConfig = createAction('[Reset PatientLiveStatus Appointment Chair] Reset Appointment Chair');

export const patientLiveStatusStartLoading = createAction('[patientLiveStatusStartLoading] patientLiveStatusStartLoading');
export const patientLiveStatusStopLoading = createAction('[patientLiveStatusStopLoading] patientLiveStatusStopLoading');

export const getPatientAllergies = createAction('[GetPatientAllergies API] Invoke',
    props<{ patientId: number }>()
);
export const getPatientAllergiesSuccess = createAction('[GetPatientAllergies API] Success',
    props<{ patientAllergies: PatientAllergy[] }>()
);
export const getPatientAllergiesFailure = createAction('[GetPatientAllergies API] Failure',
    props<{ error: string }>()
);
export const updateClipboardAppointmentToliveStatusStore = createAction(
    '[updateClipboardAppointmentToliveStatusStore] updateClipboardAppointmentToliveStatusStore',
    props<{ appointment: any }>()
);
export const updateHasFutureAppointmentToliveStatusStore = createAction(
    '[updateHasFutireAppointmentToliveStatusStore] updateHasFutireAppointmentToliveStatusStore',
    props<{ appointment: any }>()
);