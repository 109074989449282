import { createAction, props } from "@ngrx/store";
import { CancelTreatmentContract, ClinicalDashboardFilter, OtherDashboardFilter, PatientAppointmentCount, PracticeDashboardAppointmentResult } from "./clinical-dashboard.state";

export const invokeGetPatientAppointmentClinicalDashboard = createAction('[GetPatientAppointmentClinicalDashboard API] invoke Get Patient Appointments Dashboard', props<{ dashboardFilter: ClinicalDashboardFilter }>());
export const getPatientAppointmentClinicalDashboardSuccess = createAction('[GetPatientAppointmentClinicalDashboard API] GetPatientAppointmentClinicalDashboard Success', props<{ practiceExamResult: PracticeDashboardAppointmentResult[] }>());
export const getPatientAppointmentClinicalDashboardFailure = createAction('[GetPatientAppointmentClinicalDashboard API] GetPatientAppointmentClinicalDashboard Failure', props<{ errorMessage: string }>());

export const invokeGetPatientAppointmentClinicalDashboardSummary = createAction('[invokeGetDashboardAppointmentsInfo API] invoke GetDashboard Appointments Info', props<{ dashboardFilter: ClinicalDashboardFilter }>());
export const getPatientAppointmentClinicalDashboardSummarySuccess = createAction('[invokeGetDashboardAppointmentsInfo API] GetDashboardAppointmentsInfo Success', props<{ dashboardCount: PatientAppointmentCount[] }>());
export const getPatientAppointmentClinicalDashboardSummaryFailure = createAction('[invokeGetDashboardAppointmentsInfo API] GetDashboardAppointmentsInfo Failure', props<{ errorMessage: string }>());

export const clearClinicalDashboardError = createAction('[clearClinicalDashboardError] GetClinicalDashboard Error');

export const clearClinicalDashboardStore = createAction('[ClinicalDashboard] clear clinical dashboard store');

export const clearDashboardCountStore = createAction('[DashboardCountStore] clear tracker count store');

export const invokeGetPatientAttentionWithNoExam = createAction('[GetPatientWithNoExam API] invoke Get Patient With No Exam Dashboard'
    , props<{ dashboardFilter: OtherDashboardFilter }>());

export const invokeGetPatientAttentionClinicalDashboard = createAction('[GetPatientAttentionClinicalDashboard API] invoke Get Patient Attention Dashboard'
    , props<{ dashboardFilter: OtherDashboardFilter }>());

export const invokeGetPatientTreatmentRecommenedNoAppointments = createAction('[GetPatientTreatmentRecommendedNoAppointments API] invoke Get Patient Attention Dashboard'
    , props<{ dashboardFilter: OtherDashboardFilter }>());

export const invokeGetStartsWithNoAppointment = createAction('[GetStartsWithNoAppointment API] invoke Get Patient Attention Dashboard'
    , props<{ dashboardFilter: OtherDashboardFilter }>());

export const invokeGetNoTreatmentAttentionDashboardSummary = createAction('[Get GetNoTreatmentAttentionDashboardSummary API] invoke Get Patient NoTreatment Attention Dashboard'
    , props<{ dashboardFilter: OtherDashboardFilter }>());

export const getAttentionBucketDashboardSuccess = createAction('[AttentionBucketDashboard] Attention Bucket Success', props<{ practiceExamResult: PracticeDashboardAppointmentResult[] }>());
export const getAttentionBucketDashboardFailure = createAction('[AttentionBucketDashboard] Attention Bucket Failure', props<{ errorMessage: string }>());
export const clearAttentionBucketDashboardError = createAction('[AttentionBucketDashboard] Clear Attention Bucket Error');
export const clearAttentionBucketDashboard = createAction('[AttentionBucketDashboard] clear Attention Bucket store');

export const clinicalDashboardFilterChanged =  createAction('[ClinicalDashboard] clinical store filter changed',  props<{ clinicalDashboardFilter: ClinicalDashboardFilter }>());
export const updatePatientAppointmentClinicalDashboardResults = createAction('[updatePatientAppointmentClinicalDashboard] results', props<{ practiceExamResult: any }>());

export const invokeGetPatientPaymentPlanDashboard = createAction('[GetPatientPaymentPlanDashboard API] Invoke  API', props<{ dashboardFilter: any }>());
export const invokeGetPracticeSignAtHomeDashboard = createAction('[GetPracticeSignAtHomeDashboard API] Invoke  API', props<{ dashboardFilter: any }>());

export const invokeDeactivateTreatmentContract = createAction('[DeactivateTreatmentContract] API Invoke', props<{ cancelTreatmentContract: CancelTreatmentContract }>());
export const deactivateTreatmentContractSuccess = createAction('[DeactivateTreatmentContract] API Success', props<{ success: boolean }>());
export const updatePracticePaymentPlanDashboardResult = createAction('[UpdatePatientPaymentPlanDashboardResult] PracticeContractDashboard Results', props<{ dashboardFilterResult: any }>());
export const updatePracticeSignAtHomeDashboardResults = createAction('[UpdatePracticeSignAtHomeDashboardResults] PracticeContractDashboard Results', props<{ dashboardFilterResult : any }>());

export const invokeGetPatientTrackerCount = createAction('[GetPatientTrackerCount] Invoke API', props<{ dashboardFilter: OtherDashboardFilter }>());
export const invokedashboardCount = createAction('[GetdashboardCount] Invoke API', props<{ dashboardFilter: any }>());
export const getGetPatientTrackerCountSuccess = createAction('[GetPatientTrackerCount API] GetPatientTrackerCount Success', props<{ trackerCount: PatientAppointmentCount[] }>());
export const getGetPatientTrackerCountFailure = createAction('[GetPatientTrackerCount API] GetPatientTrackerCount Failure', props<{ errorMessage: string }>());
export const getGetdashboardCountSuccess = createAction('[GetdashboardCount API] GetdashboardCount Success', props<{ trackerCount: PatientAppointmentCount[] }>());
export const getGetdashboardCountFailure = createAction('[GetdashboardCount API] GetdashboardCount Failure', props<{ errorMessage: string }>());

export const attentionBucketDashboardCloseSuccessAlert = createAction('[PracticeContractDashboard] close success Alert');