import { Injectable } from '@angular/core';
import {
  Appointment, Appointments, AvailableAppointmentSlots, PatientAppointmentStatuses, PracticeAppointmentType,
  PracticeLocation, PracticeProvider, ScheduleChairConfiguration
} from '../store/scheduler-store/scheduler-state';
import { appointmentAction, commonEditorOptions, scheduleConstants, scheduleStorageKey } from '../constant/scheduler-constant';
import { appointmentStatus, appointmentStatusID } from '../../shared/constant/shared-constant';
import { AppSessionStorageService } from '../../shared/services/app-session-storage.service';
import { CommonUtilsService } from '../../shared/utils/common-utils.service';
import { AppLocalStorageService } from '../../shared/services/app-local-storage.service';
import { applicationStorageKeys } from '../../user/constant/user-role-constant';
import { NavigateURLService } from '../../shared/services/navigate-url.service';

@Injectable()
export class SchedulerService {

  constructor(
    private appSessionStorageService: AppSessionStorageService,
    private commonUtilsService: CommonUtilsService,
    private appLocalStorageService: AppLocalStorageService,
    private navigateURLService: NavigateURLService
  ) {
  }

  calculateEndDate(startDate: Date, duration: number): Date {
    const millisecondsPerMinute = 60000;
    const endDate = new Date(startDate.getTime() + (duration * millisecondsPerMinute));
    return endDate;
  }

  getDurationList(start: number, end: number, interval: number) {
    let durationList = [];
    for (let i = start; i <= end; i += interval) {
      durationList.push({ value: i, label: i.toString() });
    }

    return durationList;
  }

  calculateMinMaxAppointmentTime(startDate: Date, hour: number) {
    const date = new Date(startDate);
    date.setHours(hour, 0);
    return date;
  }

  convertToDateTime(dateStr: string, timeStr: string): string {
    const formattedDate = dateStr.split('T')[0];
    const formattedTime = timeStr.split('T')[1];
    const customFormat = `${formattedDate}T${formattedTime}.000Z`;
    return customFormat;
  }

  convertToCustomFormat(dateObj: Date): string {
    const year = dateObj.getFullYear();
    const month = this.addLeadingZero(dateObj.getMonth() + 1);
    const day = this.addLeadingZero(dateObj.getDate());
    const hours = this.addLeadingZero(dateObj.getHours());
    const minutes = this.addLeadingZero(dateObj.getMinutes());
    const seconds = this.addLeadingZero(dateObj.getSeconds());

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  loadDatePickerOptions(startDate: Date, isVisible?: boolean) {
    return {
      type: 'date',
      value: startDate,
      dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
      min: new Date(),
      readOnly: isVisible ?? false,

    };
  }

  loadTimePickerOptions(startDate: Date, startDayHour: number, endDayHour: number, isVisible?: boolean) {
    const minTime = this.calculateMinMaxAppointmentTime(new Date(startDate), startDayHour);
    const maxTime = this.calculateMinMaxAppointmentTime(new Date(startDate), endDayHour);

    return {
      type: 'time',
      value: new Date(startDate),
      dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
      min: minTime,
      max: maxTime,
      dropDownOptions: {
        maxHeight: '250px',
      },
      readOnly: isVisible ?? false,
    };
  }

  loadChairEditorOptions(chair: ScheduleChairConfiguration[], isVisible: boolean) {
    return {
      dataSource: chair,
      displayExpr: 'chairName',
      valueExpr: 'practiceChairId',
      searchExpr: 'chairName',
      onOptionChanged: "onChairOptionChanged",
      readOnly: isVisible,
      hint: isVisible ? "The position is a read-only field; you can't edit it." : '',
      ...commonEditorOptions,
    }
  }

  loadAppointmentStatusEditorOptions(appointmentTypes: PatientAppointmentStatuses[], isVisible: boolean, event: any) {
    return {
      dataSource: appointmentTypes,
      displayExpr: 'patientAppointmentStatusName',
      valueExpr: 'patientAppointmentStatusId',
      searchExpr: 'patientAppointmentStatusName',
      readOnly: isVisible,
      hint: isVisible ? "The appointment status is a read-only field; you can't edit it." : '',
      onValueChanged: event,
      ...commonEditorOptions,
    }
  }

  loadPracticeLocationOptions(locatios: PracticeLocation[]) {
    return {
      dataSource: locatios,
      displayExpr: 'practiceLocationName',
      valueExpr: 'practiceLocationId',
      searchExpr: 'practiceLocationName',
      readOnly: true,
      hint: "The location is a read-only field; you can't edit it.",
      ...commonEditorOptions
    }
  }

  loadProviderEditorOptions(provider: PracticeProvider[], isVisible?: boolean) {
    return {
      dataSource: provider,
      displayExpr: 'providerName',
      valueExpr: 'providerId',
      searchExpr: 'providerName',
      readOnly: isVisible ?? false,
      ...commonEditorOptions
    }
  }

  loadPracticeChairOptions(chairs: ScheduleChairConfiguration[]) {
    return {
      dataSource: chairs,
      displayExpr: 'chairName',
      valueExpr: 'practiceChairId',
      selectionMode: "multiple",
      showSelectionControls: true,
      selectedItemKeys: [],
      onOptionChanged: "onChairOptionChanged",
    }
  }

  calculateAppointmentDurationInMinutes(start: Date, end: Date): number {
    const durationInMilliseconds = end.getTime() - start.getTime();
    return Math.floor(durationInMilliseconds / (1000 * 60));
  }

  isNotLessThanSelectedDate(selectedDate: string): boolean {
    const currentDate = new Date();
    const selectedDateObject = new Date(selectedDate);
    currentDate.setHours(0, 0, 0, 0);
    selectedDateObject.setHours(0, 0, 0, 0);
    return selectedDateObject < currentDate;
  }

  findSuccessMessage(actionType: string) {
    const successMessages = {
      [appointmentAction.cancel]: scheduleConstants.appointmentCancelSuccess,
      [appointmentAction.delete]: scheduleConstants.appointmentDeleteSuccess,
      [appointmentAction.update]: scheduleConstants.appointmentUpdateSuccess,
      [appointmentAction.create]: scheduleConstants.appointmentCreateSuccess,
      [appointmentAction.noShow]: scheduleConstants.appointmentNoShowSuccessMessage,
      [appointmentAction.reschedule]: scheduleConstants.appointmentRescheduleSuccess,
      [appointmentAction.confirm]: scheduleConstants.appointmentConfirmedSuccessMessage,
      [appointmentAction.unconfirm]: scheduleConstants.appointmentUnconfirmedSuccessMessage,
      [appointmentAction.checkIn]: scheduleConstants.appointmentCheckInSuccessMessage,
      [appointmentAction.checkout]: scheduleConstants.appointmentCheckoutSuccessMessage,
      [appointmentAction.clipboardDrag]: scheduleConstants.appointmentUpdateSuccess,
      [appointmentAction.clipboardDrop]: scheduleConstants.clipboardAppointmentUpdateSuccess,
      [appointmentAction.clipboardRemove]: scheduleConstants.clipboardAppointmentDeleteSuccess,
      
      
    };
    return successMessages[actionType] || '';
  }

  updateLocationIdToStorage(locationId: number) {
    this.appSessionStorageService.setItem(scheduleStorageKey, locationId.toString());
  }

  getScheduleLocationFromStorage() {
    return Number(this.appSessionStorageService.getItem(scheduleStorageKey)) || Number(this.appLocalStorageService.getItem(applicationStorageKeys.loginUserLocationId));
  }

  mapAppointmentModel(appointmnet: Appointment) {

    const timeNow = this.convertToCustomFormat(new Date());

    switch (appointmnet.patientAppointmentStatusId) {
      case appointmentStatusID.checkIn:
        appointmnet.patientCheckInTime = timeNow;
        break;
      case appointmentStatusID.seated:
        appointmnet.patientSeatedTime = timeNow;
        break;
      case appointmentStatusID.checkout:
        appointmnet.patientCheckOutTime = timeNow;
        break;
      case appointmentStatusID.completed:
        appointmnet.patientDismissedTime = timeNow;
        break;
    }

    return {
      patientAppointmentId: appointmnet.patientAppointmentId,
      practiceId: appointmnet.practiceId,
      practiceLocationId: appointmnet.practiceLocationId,
      patientId: appointmnet.patientId,
      providerId: appointmnet.providerId,
      practiceAppointmentTypeId: appointmnet.practiceAppointmentTypeId,
      patientAppointmentTime: appointmnet.patientAppointmentTime,
      patientAppointmentDuration: appointmnet.patientAppointmentDuration,
      patientAppointmentStatusId: appointmnet.patientAppointmentStatusId,
      patientAppointmentNotes: appointmnet.patientAppointmentNotes,
      patientCheckInTime: appointmnet.patientCheckInTime,
      patientSeatedTime: appointmnet.patientSeatedTime,
      patientCheckOutTime: appointmnet.patientCheckOutTime,
      patientDismissedTime: appointmnet.patientDismissedTime,
      patientCurrentLocation: appointmnet.patientCurrentLocation,
      isAddedToClipboard: appointmnet.isAddedToClipboard,
      isVirtualAppointment: appointmnet.isVirtualAppointment,
      isActive: appointmnet.isActive,
      isAppointmentConfirmed: appointmnet.isAppointmentConfirmed,
      originalChairId: appointmnet.originalChairId,
      actualChairId: appointmnet.actualChairId,
      chairEvent: appointmnet.chairEvent,
      priority: appointmnet.priority,
      isPatientHasAllergy: appointmnet.isPatientHasAllergy,
      practicePolicies: appointmnet.practicePolicies,
      practiceAppointmentTypePatientStatuses: appointmnet.practiceAppointmentTypePatientStatuses,
      patientExamResults: appointmnet.patientExamResults,
      hasMiscTreatmentPlan: appointmnet.hasMiscTreatmentPlan,
      hasFutureAppointment: appointmnet.hasFutureAppointment,
      nvprocedureWeeks: appointmnet.nvprocedureWeeks,
      nvprocedure: appointmnet.nvprocedure,
      nextVisitAppointmentTypeId: appointmnet.nextVisitAppointmentTypeId,
      durationInMinutes: appointmnet.durationInMinutes,
      dw: appointmnet.dw
    }
  }

  mapAppointmentStatusId(value: string) {
    const statusMappings = {
      [appointmentStatus.checkIn]: appointmentStatusID.checkIn,
      [appointmentStatus.checkout]: appointmentStatusID.checkout,
      [appointmentStatus.completed]: appointmentStatusID.completed,
      [appointmentStatus.seated]: appointmentStatusID.seated,
      [appointmentStatus.active]: appointmentStatusID.active
    };
    return statusMappings[value];
  }

  isValidPracticeAndLocation(practiceId: number, scheduleLocationId: number, appointment: Appointment) {
    return (
      appointment && appointment.practiceId === practiceId && appointment.practiceLocationId === scheduleLocationId
    );
  }

  isScheduleDate(scheduleStartDate: Date, scheduleEndDate: Date, appointmentDate: string) {
    const startDateObject = new Date(scheduleStartDate);
    const endDateObject = new Date(scheduleEndDate);
    const selectedDateObject = new Date(appointmentDate);

    startDateObject.setHours(0, 0, 0, 0);
    endDateObject.setHours(0, 0, 0, 0);
    selectedDateObject.setHours(0, 0, 0, 0);

    return startDateObject.getTime() <= selectedDateObject.getTime() &&
      selectedDateObject.getTime() <= endDateObject.getTime();
  }

  LoadAppointmentTypeEditorOptions(practiceAppointmentType: PracticeAppointmentType[], isVisible: boolean, event: any) {
    return {
      dataSource: practiceAppointmentType,
      displayExpr: 'practiceAppointmentName',
      valueExpr: 'practiceAppointmentTypeId',
      onValueChanged: event,
      readOnly: isVisible ?? false,
      searchExpr: 'practiceAppointmentName',
      itemTemplate: "appointmentTypeTemplate",
      ...commonEditorOptions
    }
  }

  createScheduleConfigPostParams(practiceId: number, locationId: number, startDate: Date, endDate: Date) {
    return {
      practiceId: practiceId,
      practiceLocationId: locationId,
      startDate: this.commonUtilsService.formatDate(startDate),
      endDate: this.commonUtilsService.formatDate(endDate),
    }
  }

  mapAvailableSlotAppointmentModel(apptSlot: AvailableAppointmentSlots) {
    return {
      startDate: new Date(apptSlot.availableStartDateTime),
      endDate: new Date(apptSlot.availableEndDateTime),
      chairId: apptSlot.chairId,
      practiceLocationId: apptSlot.practiceLocationId,
      practiceAppointmentTypeId: apptSlot.practiceAppointmentTypeId,
      patientAppointmentDuration: apptSlot.slotDuration
    }
  }

  mapChairModel(chairs: ScheduleChairConfiguration[]) {
    const chairModel = [...chairs].map((chair: ScheduleChairConfiguration) => ({
      ...chair,
      text: chair.chairName,
      id: chair.practiceChairId
    }));

    return chairModel;
  }

  mapScheduleAppointmentModel(appointments: Appointments[]) {
    const mappedAppointmentModel = [...appointments].map((appointment: Appointments) => {
      const startDate = new Date(appointment.patientAppointmentTime);
      const endDate = this.calculateEndDate(startDate, appointment.patientAppointmentDuration);
      return {
        ...appointment,
        chairId: appointment.originalChairId,
        startDate: startDate,
        endDate: endDate
      };
    });
    return mappedAppointmentModel;
  }

  getElapseTime(patientSeatedTime: string) {
    const seatedTime = new Date(patientSeatedTime);
    const currentTime = new Date();
    const elapseTime = Math.abs(currentTime.getTime() - seatedTime.getTime());
    return Math.floor(elapseTime / 1000);
  }

  getRemainingTime(patientSeatedTime: string, patientAppointmentDuration: number) {
    const elapseTime = this.getElapseTime(patientSeatedTime);
    const remainingTime = patientAppointmentDuration * 60 - elapseTime;
    return remainingTime > 0 ? remainingTime : 0;
  }
  
  isAppointmentAlreadyExistinClipboard(clipboardAppointments: Appointments[], appointment: Appointments) {
    return clipboardAppointments.some( (appt) => appt.patientAppointmentId === appointment.patientAppointmentId)
  }

  getAppointmentModel(){
    let appointment: any = {
      patientAppointmentId: 0,
      practiceId: null,
      practiceLocationId: null,
      patientId: null,
      providerId: null,
      practiceAppointmentTypeId: null,
      appointmentStartDate: null,
      appointmentStartTime: null,
      patientAppointmentDuration: null,
      patientAppointmentStatusId: 1,
      patientAppointmentNotes: "",
      originalChairId: null,
      actualChairId: null,
      patientCheckInTime: null,
      patientSeatedTime: null,
      patientCheckOutTime: null,
      patientDismissedTime: null,
      patientCurrentLocation: "",
      isAddedToClipboard: false,
      isVirtualAppointment: false,
      isActive: true,
      isAppointmentConfirmed: null,
      patientName: '',
    };
    return appointment;
  }

  setAppointmentCreationProperties(appointmentCreation: any, appointmentCellData: any, practiceId: number, locationId: number) {
    appointmentCreation.practiceId = practiceId;
    appointmentCreation.patientName = !appointmentCellData.patientName
      ? null
      : `${appointmentCellData.patientName}${appointmentCellData.patientFormattedDob ? `, ${appointmentCellData.patientFormattedDob}` : ''}` || null;
    appointmentCreation.patientId = appointmentCellData.patientId || null
    appointmentCreation.practiceLocationId = appointmentCellData.practiceLocationId || locationId;
    appointmentCreation.originalChairId = appointmentCellData.chairId;
    appointmentCreation.appointmentStartDate = this.convertToCustomFormat(appointmentCellData.startDate);
    appointmentCreation.appointmentStartTime = this.convertToCustomFormat(appointmentCellData.startDate);
    appointmentCreation.practiceAppointmentTypeId = appointmentCellData.practiceAppointmentTypeId || null;
    appointmentCreation.patientAppointmentDuration = appointmentCellData.patientAppointmentDuration || null;
    return appointmentCreation;
  }

  navigateToTXCard(patientId: number) {
    const newTabUrl = this.navigateURLService.getTreamentCardSummaryURL(patientId);
    window.open(newTabUrl, '_blank')
  }
}