import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInsightService } from './services/app-insights';
import { GlobalErrorHandlerService } from './services/global-error-handler';
import { BrowserLoggerService } from './services/logger.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth-interceptor';
import { HttpErrorIntercept } from './services/http-error.interceptor';
import { UserFeatureModule } from 'src/app/features/user/user-feature.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    UserFeatureModule
  ],
  providers: [
    BrowserLoggerService,
    AppInsightService,
   
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorIntercept, multi: true }
  ],
})
export class CoreModule { }
