import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  selectPatientTreatmentContractPlantateEvent, selectPatientLedger,
  selectinstallmentPlanSummary, selectPatientTreatmentInstallmentPlan, selectPatientSummaryData
} from '../../store/contract-plan-dashboard/contract-plan-dashboard.selector';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { closePatientTreatmentContractErrorAlert, startPatientTreatmentContractLedgerLoading } from '../../store/contract-plan-dashboard/contract-plan-dashboard.action';
import {
  InstallmentPlanSummary, PatientLedger, PatientLedgerSummary, PatientSummaryModel, PatientTreatmentContractPlantateEvent
  , PatientTreatmentInstallmentPlan, WalletOptions
} from '../../store/contract-plan-dashboard/contract-plan-dashboard.state';
import { TreatmentContractPlanService } from '../../services/contract-plan-dashboard.service';
import { TRANSACTION_CATEGORIES, TRANSACTION_TYPES, creditDebitPlan } from '../../constant/contract-plan.constant';
import { DownloadDocumentFileService } from 'src/app/features/document/service/download-document-file.service';
import { selectdocumentDownloadInfoState } from 'src/app/features/document/store/document-download-store/document-download.selector';
import { DocumentDownloadInfoState, documentDownloadCloseErrorAlert } from 'src/app/features/document/store/document-download-store/document-download.reducer';

@Component({
  selector: 'app-contract-ledger',
  templateUrl: './contract-ledger.component.html',
  styleUrls: ['./contract-ledger.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ContractLedgerComponent {
  patientId = 0;
  practiceId: number;
  isLoading: boolean | undefined;
  totalCharge = 0;
  totalPaymentCollected = 0;
  totalPrincipal = 0;
  totalInterest = 0;
  totalBalance = 0;
  isLedgerDetailsCollapsed = false;
  creditDebitPlan = creditDebitPlan;

  selectedPatientTreatmentInstallmentPlan!: PatientTreatmentInstallmentPlan;
  selectedPatientTreatmentContractLedger: PatientLedger[] = [];
  selectedPatientTreatmentContractLedgerCopy: PatientLedger[] = [];
  selectedInstallmentPlanSummary!: InstallmentPlanSummary;
  selectedpatientLedgerSummary!: PatientLedgerSummary;
  walletOptions: WalletOptions[] = [];
  selectdWalletOptions!: WalletOptions | undefined;
  patientSummary!: PatientSummaryModel;

  selectPatientTreatmentInstallmentPlan$ = this.store.select(selectPatientTreatmentInstallmentPlan);
  selectPatientTreatmentContractPlantateEvent$ = this.store.select(selectPatientTreatmentContractPlantateEvent);
  selectpatientLedger$ = this.store.select(selectPatientLedger);
  selectinstallmentPlanSummary$ = this.store.select(selectinstallmentPlanSummary);
  patientSummaryData$ = this.store.select(selectPatientSummaryData);
  selectdocumentDownloadInfoState$ = this.store.select(selectdocumentDownloadInfoState);

  private subscriptions$ = new Subscription();

  @Input() isWalletLedger: boolean = false;
  @Input() hideLedgerSummary: boolean = false;

  constructor(private store: Store,
    private route: ActivatedRoute,
    private alert: AlertService,
    private authService: AuthService,
    private contractPlanService: TreatmentContractPlanService,
    private downloadFileService: DownloadDocumentFileService
  ) {
    this.practiceId = this.authService.getPracticeIDFromToken();
    this.subscriptions$.add(this.route.paramMap.subscribe(params => this.bindInitialValues(params)));
  }

  ngOnInit(): void {
    this.store.dispatch(startPatientTreatmentContractLedgerLoading());
    this.subscriptions$.add(this.patientSummaryData$.subscribe(async (data: PatientSummaryModel) => this.bindPatientSummaryInformation(data)));
    this.subscriptions$.add(this.selectPatientTreatmentInstallmentPlan$.subscribe(async (data: PatientTreatmentInstallmentPlan) => this.bindPatientTreatmentInstallmentPlan(data)));
    this.subscriptions$.add(this.selectpatientLedger$.subscribe(async (data: PatientLedger[]) => this.bindPatientLedger(data)));
    this.subscriptions$.add(this.selectPatientTreatmentContractPlantateEvent$.subscribe(async (data: PatientTreatmentContractPlantateEvent) => this.bindTreatmentContractPlantateEvent(data)));
    this.subscriptions$.add(this.selectinstallmentPlanSummary$.subscribe(async (data: InstallmentPlanSummary) => this.bindInstallmentPlanSummary(data)));
    this.subscriptions$.add(this.selectdocumentDownloadInfoState$.subscribe((data?: DocumentDownloadInfoState) => this.handleDocumentDownloadInfoState(data)));
  }

  async bindPatientSummaryInformation(patientSummaryModel: PatientSummaryModel) {
    if ((patientSummaryModel) && patientSummaryModel.patientId > 0) {
      this.patientSummary = patientSummaryModel;
      if(!this.walletOptions.some(option => option.bindValue === patientSummaryModel.patientId)){
        this.walletOptions.push({
          bindLabel: patientSummaryModel.patientName,
          bindValue: patientSummaryModel.patientId,
          isInsurance: false,
          walletAmount: 0
        });
      }
    }
  }

  bindInitialValues(params: ParamMap) {
    this.patientId = Number(params.get('id'));
  }

  bindPatientTreatmentInstallmentPlan(data: PatientTreatmentInstallmentPlan) {
    this.selectedPatientTreatmentInstallmentPlan = data;
  }

  bindPatientLedger(data: PatientLedger[]) {
    if (data.length === 0) return;
    this.selectedPatientTreatmentContractLedgerCopy = [...data];
    this.selectedPatientTreatmentContractLedgerCopy = this.contractPlanService.mapPatientLedgerByPatientPaymentTransaction(this.selectedPatientTreatmentContractLedgerCopy)
      .sort((a, b) => {
        const firstDate = new Date(a.transactionDate).getTime();
        const lastDate = new Date(b.transactionDate).getTime();
        return firstDate - lastDate;
      })
      .map((item) => ({
        ...item, showLedgerSubRow: false
      }));
    this.selectedPatientTreatmentContractLedger = this.selectedPatientTreatmentContractLedgerCopy.filter(x=> x.transactionDescription != creditDebitPlan.creditPaymentPlan && x.transactionDescription != creditDebitPlan.debitPaymentPlan);
    this.calculateBalances(this.selectedPatientTreatmentContractLedger);
    this.calculateLedgerSummary(this.selectedPatientTreatmentContractLedger);
    if (this.isWalletLedger) {
      this.setupWalletOption();
      this.calculateWalletLedgerBalance();
      const patientWalletOption = this.walletOptions.find(_ => (!_.isInsurance));
      this.selectWalletLedger(patientWalletOption);
    }
  }

  private setupWalletOption() { 
    if (this.selectedPatientTreatmentContractLedger.length > 0) {
      const insuranceLedger = this.selectedPatientTreatmentContractLedger.filter(_ => _.practiceInsuranceCompanyId);
      insuranceLedger.forEach(insuranceObj => {
        if (!this.isDuplicate(insuranceObj, this.walletOptions)) {
          this.walletOptions.push({
            bindLabel: insuranceObj.practiceInsuranceCompanyName ?? '',
            bindValue: insuranceObj.practiceInsuranceCompanyId,
            isInsurance: true,
            walletAmount: 0
          });
        }
      });
    }
  }

  calculateWalletLedgerBalance() {
    this.walletOptions.map(item => {
      if (!item.isInsurance) {
        const patientLedger = this.selectedPatientTreatmentContractLedgerCopy.filter(_ => !_.practiceInsuranceCompanyId);
        this.calculateBalances(patientLedger)
        item.walletAmount = this.totalBalance;
      }
      if (item.isInsurance) {
        const insuranceLedger = this.selectedPatientTreatmentContractLedgerCopy.filter(_ => _.practiceInsuranceCompanyId === item.bindValue);
        this.calculateBalances(insuranceLedger)
        item.walletAmount = this.totalBalance;
      }
    });
  }

  isDuplicate(insuranceObj: PatientLedger, walletOptions: WalletOptions[]) {
    return walletOptions.some(option => option.bindValue === insuranceObj.practiceInsuranceCompanyId);
  }

  bindTreatmentContractPlantateEvent(data: PatientTreatmentContractPlantateEvent) {
    this.isLoading = data.loadingContractPlanLedger;
    if (data.showSuccessAlert) {
    }
    if (data.showErrorAlert) {
      this.alert.failureAlert(data.errorMessage);
      this.store.dispatch(closePatientTreatmentContractErrorAlert());
    }
  }

  bindInstallmentPlanSummary(data: InstallmentPlanSummary) {
    this.selectedInstallmentPlanSummary = data
  }

  calculateBalances(treatmentContractLedger: PatientLedger[]): void {
    let balance = 0;
    this.totalCharge = 0;
    this.totalPaymentCollected = 0;
    this.totalPrincipal = 0;
    this.totalInterest = 0;
    this.totalBalance = 0;
    this.selectedPatientTreatmentContractLedger = treatmentContractLedger.map(ledger => {
      if (ledger.transactionType === TRANSACTION_TYPES.DEBIT) {
        balance += ledger.transactionAmount;
        if (ledger.transactionCategory === TRANSACTION_CATEGORIES.CHARGE
          || ledger.transactionCategory === TRANSACTION_CATEGORIES.ADJUSTMENT) {
          this.totalCharge += ledger.transactionAmount;
        }
        if (ledger.transactionCategory === TRANSACTION_CATEGORIES.REFUND) {
          this.totalPaymentCollected -= ledger.transactionAmount;
        }
        this.totalPrincipal += ledger.principletransactionAmount;
        this.totalInterest += ledger.interestTransactionAmount;
      }
      else if (ledger.transactionType === TRANSACTION_TYPES.CREDIT) {
        balance -= ledger.transactionAmount;

        if (ledger.transactionCategory != TRANSACTION_CATEGORIES.PAYMENT) {
          this.totalCharge -= ledger.transactionAmount;
        }
        if (ledger.transactionCategory == TRANSACTION_CATEGORIES.PAYMENT) {
          this.totalPaymentCollected += ledger.transactionAmount;
        }
        this.totalPrincipal -= ledger.principletransactionAmount;
        this.totalInterest -= ledger.interestTransactionAmount;
      }
      const formattedBalance = parseFloat(balance.toFixed(2));
      this.totalCharge = parseFloat(this.totalCharge.toFixed(2));
      this.totalPaymentCollected = parseFloat(this.totalPaymentCollected.toFixed(2));
      this.totalPrincipal = parseFloat(this.totalPrincipal.toFixed(2));
      this.totalInterest = parseFloat(this.totalInterest.toFixed(2));
      this.totalBalance = parseFloat(balance.toFixed(2));

      return { ...ledger, balance: formattedBalance };
    });
  }

  calculateLedgerSummary(treatmentContractLedger: PatientLedger[]) {
    const summary = treatmentContractLedger.reduce((accumulator, ledger) => {
      if (ledger.transactionCategory === TRANSACTION_CATEGORIES.CHARGE) {
        if (ledger.transactionType === TRANSACTION_TYPES.DEBIT) {
          accumulator.totalCharges += ledger.transactionAmount;
          accumulator.totalProduction += ledger.transactionAmount;
        } else if (ledger.transactionType === TRANSACTION_TYPES.CREDIT) {
          if (!ledger.practiceInsuranceCompanyId) {
            accumulator.totalDiscount += ledger.transactionAmount;
            accumulator.totalProduction -= ledger.transactionAmount;
          } else {
            accumulator.totalInsuranceCoverage += ledger.transactionAmount;
            accumulator.totalProduction -= ledger.transactionAmount;
          }
        }
      } else if (ledger.transactionCategory === TRANSACTION_CATEGORIES.ADJUSTMENT
        && ledger.transactionDescription != creditDebitPlan.creditPaymentPlan && ledger.transactionDescription !== creditDebitPlan.debitPaymentPlan) {
        if (ledger.transactionType === TRANSACTION_TYPES.DEBIT) {
          accumulator.totalDebitAdjustment += ledger.transactionAmount;
          accumulator.responsibility += ledger.transactionAmount;
        } else if (ledger.transactionType === TRANSACTION_TYPES.CREDIT) {
          accumulator.totalCreditAdjustment += ledger.transactionAmount;
          accumulator.responsibility -= ledger.transactionAmount;
          if (ledger.practiceInsuranceCompanyId) {
            accumulator.totalInsuranceCoverage += ledger.transactionAmount;
          }
        }
      } else if (ledger.transactionCategory === TRANSACTION_CATEGORIES.PAYMENT) {
        if (ledger.transactionType === TRANSACTION_TYPES.CREDIT) {
          accumulator.totalPaymentCollected += ledger.transactionAmount;
          accumulator.totalBalance -= ledger.transactionAmount;
        }
      } else if (ledger.transactionCategory === TRANSACTION_CATEGORIES.REFUND) {
        if (ledger.transactionType === TRANSACTION_TYPES.DEBIT) {
          accumulator.totalRefund += ledger.transactionAmount;
          accumulator.totalBalance += ledger.transactionAmount;
        }
      }
      return accumulator;
    }, {
      totalCharges: 0,
      totalDiscount: 0,
      totalInsuranceCoverage: 0,
      totalProduction: 0,
      totalDebitAdjustment: 0,
      totalCreditAdjustment: 0,
      responsibility: 0,
      totalPaymentCollected: 0,
      totalRefund: 0,
      totalBalance: 0,
    });

    const formatValue = (value: number) => parseFloat(value.toFixed(2));

    const patientLedgerSummary = {
      totalCharges: formatValue(summary.totalCharges),
      totalDiscount: formatValue(summary.totalDiscount),
      totalInsuranceCoverage: formatValue(summary.totalInsuranceCoverage),
      totalProduction: formatValue(summary.totalProduction),
      totalDebitAdjustment: formatValue(summary.totalDebitAdjustment),
      totalCreditAdjustment: formatValue(summary.totalCreditAdjustment),
      resposibility: formatValue((summary.totalProduction + summary.responsibility)),
      totalPaymentCollected: formatValue(summary.totalPaymentCollected),
      totalRefund: formatValue(summary.totalRefund),
      totalBalance: formatValue((summary.totalProduction + summary.responsibility) + summary.totalBalance),
      responsiblePerson: '',
    };
    this.selectedpatientLedgerSummary = patientLedgerSummary;
  }

  isShowLedgerTableRow(ledger: any) {
    this.selectedPatientTreatmentContractLedger.map(x => {
      if (ledger.patientLedgerId == x.patientLedgerId)
        x.showLedgerSubRow = !x.showLedgerSubRow
    });
  }

  selectWalletLedger(options: WalletOptions | undefined) {
    this.selectdWalletOptions = options;
    if (options?.isInsurance) {
      this.selectedPatientTreatmentContractLedger = this.selectedPatientTreatmentContractLedgerCopy.filter(
        _ => _.practiceInsuranceCompanyId === options.bindValue
      )
    }
    if (!options?.isInsurance) {
      this.selectedPatientTreatmentContractLedger = this.selectedPatientTreatmentContractLedgerCopy.filter(
        _ => !_.practiceInsuranceCompanyId
      )
    }
    this.calculateBalances(this.selectedPatientTreatmentContractLedger);
    this.calculateLedgerSummary(this.selectedPatientTreatmentContractLedger);
  }

  printLedger() {
    const fileTitle = this.isWalletLedger ? 'Wallet Ledger Details' : 'Ledger Details';
    const fileName = `${this.patientSummary.patientName} - ${fileTitle}`;

    if (this.isWalletLedger) {
      document.title = fileName;
    }
    else {
      document.title = `${this.patientSummary.patientName} - ${this.selectedPatientTreatmentInstallmentPlan.practiceTreatmentOptionName} - ${fileTitle}`;
    }

    window.addEventListener('afterprint', this.resetTitle);
    window.print();
  }

  resetTitle() {
    document.title = 'ORTHO PMS';
  }

  downloadDocument(documentGuid: string, documentName?: string) {
    if (documentGuid)
      this.downloadFileService.downloadDocument(documentGuid, documentName ? documentName : '')
    else{
      this.alert.failureToast("Document not found")
    }
  }

  async handleDocumentDownloadInfoState(data?: DocumentDownloadInfoState) {
    if (data && data.errorMessage) {
      await this.alert.failureAlert(data.errorMessage);
      this.store.dispatch(documentDownloadCloseErrorAlert());
    }
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}