import { Pipe, PipeTransform } from "@angular/core";
import { REGEX_PATTERN } from "../../shared/constant/shared-pattern-regex-asset";

@Pipe({
    name: 'phoneNumber'
})

export class PhoneNumberPipe implements PipeTransform {

    transform(phoneNumber: string | null | undefined): string {
        return phoneNumber ? phoneNumber.replace(REGEX_PATTERN.usMobile, '($1) $2-$3') : '';
    }
} 