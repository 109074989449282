import { ViewChild, ViewContainerRef, Injector, Component, NgModuleRef, createNgModule, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { DebounceService } from 'src/app/features/shared/services/debounce-service';
import { selectConvertPatientReferralInformation } from 'src/app/features/dashboard/store/online-schedule/online-schedule.selector';
import { ConvertPatientReferraInformation } from 'src/app/features/dashboard/store/online-schedule/online-schedule.state';

@Component({
  selector: 'app-patient-overlay-wizard-loader',
  template: `
              <div class="header_menu_icon m-l-10" title="Add a new patient" (click)="patientMenuClick()" [ngClass]="{'disabled-div': addPatientClicked}">
                  <i class="fa fa-solid fa-user-plus"></i>
              </div>
              <ng-container #patientWizardRef></ng-container>
            `,
})
export class PatientOverlayWizardLoaderComponent {
  @ViewChild('patientWizardRef', { read: ViewContainerRef, static: true }) _patientwizardcontainer!: ViewContainerRef;

  showPatientwizard = false;
  addPatientClicked = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patientModuleRef!: NgModuleRef<any>;
  patientOverlayComponentRef: any;

  private subscriptions$ = new Subscription();
  selectConvertPatientReferralInformation$ = this.store.select(selectConvertPatientReferralInformation);

  constructor(private injector: Injector, private alert: AlertService, private offcanvasService: NgbOffcanvas, private store: Store,
    private debounceService: DebounceService) {
  }

  ngOnInit() {
    this.subscriptions$.add(this.selectConvertPatientReferralInformation$.subscribe((data: ConvertPatientReferraInformation | null) => this.bindConvertReferralPatientPersonalInfo(data)));
  }

  bindConvertReferralPatientPersonalInfo(data: ConvertPatientReferraInformation | null) {
    this.addPatientClicked = false;
    if (data && data.practiceReferralId > 0) {
      this.addPatientClicked = true;
    }
  }

  async loadPatientInformationModuleComponent() {
    try {
      this.offcanvasService.dismiss();
      this.patientOverlayComponentRef?.destroy();

      if (!this.patientModuleRef) {
        const patientInformationFeatureModuleRef = await import('../../../../features/patient/patient-information-feature.module');
        this.patientModuleRef = createNgModule(patientInformationFeatureModuleRef.PatientInformationFeatureModule, this.injector);
      }
      if (this.patientModuleRef) {
        const patientOverlayWizardComponentRef = await import('../../../../features/patient/component/patient-overlay-wizard/patient-overlay-wizard.component');
        this.patientOverlayComponentRef = this._patientwizardcontainer.createComponent(patientOverlayWizardComponentRef.PatientOverlayWizardComponent);
        this.patientOverlayComponentRef.instance.isShowInLay = true;
        this.patientOverlayComponentRef.instance.closePatientOverlayWizard.subscribe(() => {
          this.addPatientClicked = false;
        });
      }
    }
    catch (ex) {
      this.alert.warningAlertAutoClose("We have experienced an issue while loading your request. Please try again later.");
    }
    finally {
    }
  }

  handleClick() {
    if (this.addPatientClicked) return;
    this.showPatientwizard = !this.showPatientwizard;
    this.addPatientClicked = true;
    this.loadPatientInformationModuleComponent();
  }

  patientMenuClick() {
    this.debounceService.debounce('headerMenuClick', () => {
      this.handleClick();
    });
  }

  hendleAddPatientMenu(isClicked: boolean){
    this.addPatientClicked = isClicked;
  }
}