import { createAction, props } from "@ngrx/store";
import { DentalHygienes } from "./dental-hygienes.state";

export const invokeDentalHygienes = createAction(
    '[GetDentalHygienes] Invoke GetDentalHygienes start loading'
);

export const getDentalHygienesSuccess = createAction(
    '[GetDentalHygienes] Success', props<{ dentalHygienes: DentalHygienes[] }>()
);

export const getDentalHygienesFailure = createAction(
    '[GetDentalHygienes] Failure', props<{ errorMessage: string }>()
);

export const invokeUpdateDentalHygiene = createAction(
    '[SaveDentalHygiene] invoke', props<{ dentalHygienes: DentalHygienes, successMessage: string }>()
);

export const updateDentalHygieneSuccess = createAction(
    '[SaveDentalHygiene] Success', props<{ dentalHygienes: DentalHygienes[] }>()
);

export const updateDentalHygieneFailure = createAction(
    '[SaveDentalHygiene] Failure', props<{ errorMessage: string }>()
);

export const startDentalHygieneLoading = createAction(
    '[start] DentalHygienes  loading'
);

export const dentalHygieneCloseSuccessAlert = createAction(
    '[Completed] DentalHygienes  Success Alert'
);

export const dentalHygieneCloseErrorAlert = createAction(
    '[Error] DentalHygienes Alert'
);

export const resetDentalHygieneState = createAction('[DentalHygiene] clear dental hygiene state');