import { createAction, props } from '@ngrx/store';
import { PracticeUserLocation,  PracticeUser, User, ForgotPassword, ResetPassword, ChangePassword, UpdateSignature, GetUsersByRoles} from './practice-user.state'

export const invokePracticeUserLocation = createAction('[GetPracticeUserLocation] Invoke ');
export const invokePracticeUserLocationSuccess = createAction('[GetPracticeUserLocation] API Success', props<{ practiceUserLocations: PracticeUserLocation[] }>());
export const invokePracticeUserLocationFailure = createAction('[GetPracticeUserLocation] API Failure', props<{ errorMessage: string }>());

export const invokeCreatePracticeUser = createAction('[CreateUser] Invoke ', props<{ practiceUser: PracticeUser, successMessage: string  }>());
export const invokeCreatePracticeUserSuccess = createAction('[CreateUser] API Success', props<{ practiceUser: User }>());
export const invokeCreatePracticeUserFailure = createAction('[CreateUser] API Failure', props<{ errorMessage: string }>());

export const invokeUpdatePracticeUser = createAction('[UpdateUser] Invoke ', props<{ practiceUser: PracticeUser, successMessage: string  }>());
export const invokeUpdatePracticeUserSuccess = createAction('[UpdateUser] API Success', props<{ practiceUser: User }>());
export const invokeUpdatePracticeUserFailure = createAction('[UpdateUser] API Failure', props<{ errorMessage: string }>());


export const invokeGetAllUsers = createAction('[GetAllUsers] invoke', props<{ practiceId: number}>());
export const invokeGetAllUsersSuccess = createAction('[GetAllUsers] API Success', props<{ users: User[] }>());
export const invokeGetAllUsersFailure = createAction('[GetAllUsers] API Failure', props<{ errorMessage: string }>());

export const invokeGetPracticeUserByEmail = createAction('[GetUserByEmail] Invoke ', props<{ email: string}>());
export const invokeGetPracticeUserByEmailSuccess = createAction('[GetUserByEmail] API Success', props<{ practiceUser: User }>());
export const invokeGetPracticeUserByEmailFailure = createAction('[GetUserByEmail] API Failure', props<{ errorMessage: string }>());

export const invokeMarkAsActiveUser = createAction('[MarkAsActive] Invoke ', props<{ email: string, successMessage: string  }>());
export const invokeMarkAsActiveSuccess = createAction('[MarkAsActive] API Success', props<{ practiceUser: User }>());
export const invokeMarkAsActiveFailure = createAction('[MarkAsActive] API Failure', props<{ errorMessage: string }>());


export const invokeMarkAsInActiveUser = createAction('[MarkAsInActiveUser] Invoke ', props<{ email: string, successMessage: string  }>());
export const invokeMarkAsInActiveUserSuccess = createAction('[MarkAsInActiveUser] API Success', props<{ practiceUser: User }>());
export const invokeMarkAsInActiveUserFailure = createAction('[MarkAsInActiveUser] API Failure', props<{ errorMessage: string }>());

export const invokeUserForgotPassword = createAction('[ForgotPassword] Invoke ', props<{ email: string, successMessage: string  }>());
export const invokeUserForgotPasswordSuccess = createAction('[ForgotPassword] API Success', props<{ forgotPassword: ForgotPassword }>());
export const invokeUserForgotPasswordFailure = createAction('[ForgotPassword] API Failure', props<{ errorMessage: string }>());

export const invokeUserResetPassword = createAction('[ResetPassword] Invoke ', props<{ resetPassword: ResetPassword, successMessage: string  }>());
export const invokeUserResetPasswordSuccess = createAction('[ResetPassword] API Success', props<{ response: User }>());
export const invokeUserResetPasswordFailure = createAction('[ResetPassword] API Failure', props<{ errorMessage: string }>());

export const invokeUserChangePassword = createAction('[ChangePassword] Invoke ', props<{ changePassword: ChangePassword, successMessage: string  }>());
export const invokeUserChangePasswordSuccess = createAction('[ChangePassword] API Success', props<{ response: User }>());
export const invokeUserChangePasswordFailure = createAction('[ChangePassword] API Failure', props<{ errorMessage: string }>());

export const invokePracticeUserCloseSuccessAlert = createAction('[PracticeUserCloseSuccessAlert] close success alert');
export const invokePracticeUserCloseErrorAlert = createAction('[PracticeUserCloseErrorAlert] close error alert');
export const resetPracticeUserState = createAction('[resetPracticeUserState]');

export const invokeGetUserEmailByUserID = createAction('[GetUserEmailById] Invoke ', props<{ userId: string}>());
export const invokeGetUserEmailByUserIDSuccess = createAction('[GetUserEmailById] API Success', props<{ userEmail: string }>());
export const invokeGetUserEmailByUserIDFailure = createAction('[GetUserEmailById] API Failure', props<{ errorMessage: string }>());

export const invokeGeneratePasswordResetTokenByEmail = createAction('[GeneratePasswordResetTokenByEmail] Invoke ', props<{ email: string}>());
export const invokeGeneratePasswordResetTokenByEmailSuccess = createAction('[GeneratePasswordResetTokenByEmail] API Success', props<{ token: string }>());
export const invokeGeneratePasswordResetTokenByEmailFailure = createAction('[GeneratePasswordResetTokenByEmail] API Failure', props<{ errorMessage: string }>());

export const invokeUpdateSignature = createAction('[UpdateSignature] Invoke ', props<{ updateSignature: UpdateSignature}>());
export const invokeUpdateSignatureSuccess = createAction('[UpdateSignature] API Success', props<{ practiceUser: User }>());
export const invokeUpdateSignatureFailure = createAction('[UpdateSignature] API Failure', props<{ errorMessage: string }>());


export const invokeValidateEmail = createAction('[ValidateEmail] Invoke ', props<{ email: string}>());
export const invokeValidateEmailSuccess = createAction('[ValidateEmail] API Success', props<{ response: any }>());
export const invokeValidateEmailFailure = createAction('[ValidateEmail] API Failure', props<{ errorMessage: string }>());

export const validateSecurityPin = createAction('[validateSecurityPin] validateSecurityPin', props<{securityPin: any }>());
export const validateSecurityPinSuccess = createAction('[validateSecurityPin] validateSecurityPin API Success', props<{isValidPin: boolean }>());
export const validateSecurityPinFailure = createAction('[validateSecurityPin] validateSecurityPin API Failure', props<{errorMessage: string }>());

export const resetSecurityPinState = createAction('[resetSecurityPinState] resetSecurityPinState');

export const invokeGetUsersByRoles = createAction('[GetUsersByRoles] invoke', props<{ getUsersByRoles: GetUsersByRoles[]}>());
export const invokeGetUsersByRolesSuccess = createAction('[GetUsersByRoles] API Success', props<{ users: User[] }>());
export const invokeGetUsersByRolesFailure = createAction('[GetUsersByRoles] API Failure', props<{ errorMessage: string }>());