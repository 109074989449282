import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MenuService } from './menu.service';
import { getMenuItemsFailure, getMenuItemsSuccess, invokeGetMenu, } from './menu.actions';
import { of } from 'rxjs';

@Injectable()

export class MenuEffects {

  constructor(
    private actions$: Actions,
    private menuService: MenuService
  ) { }

  invokeGetMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeGetMenu),
      mergeMap(() => {
          return this.menuService.getMainMenu().pipe(
            map((menuItems) => getMenuItemsSuccess({ payload: menuItems })),
            catchError((error) => of(getMenuItemsFailure({ error: error })))
          );
        }
      )
    )}
  );
}



