import { environment } from "src/environments/environment";

const PATIENT_IMAGE_API_BASE_URL = environment.application.api_base_patient_image_url;
const DOCUMENT_API_BASE_URL = environment.application.api_base_document_url;
const PRACTICE_API_BASE_URL = environment.application.api_base_practice_url;

function patientImageApiUrl(endpoint: string) {return `${PATIENT_IMAGE_API_BASE_URL}${endpoint}`}
function documentApiUrl(endpoint: string) {return `${DOCUMENT_API_BASE_URL}${endpoint}`}
function practiceApiUrl(endpoint: string) {return `${PRACTICE_API_BASE_URL}${endpoint}`}

export const documentApiAsset = {
    saveDocument: documentApiUrl("Document/SaveDocument"),
    getDocumentStream: documentApiUrl("Document/GetDocumentStream"),
    getDocumentSubcategories: practiceApiUrl("Core/GetDocumentSubCategories"),
    savePatientImage: patientImageApiUrl("PatientImage/SavePatientImage"),
    downloadDocumentForUnAuthurizedUser: documentApiUrl("Document/GetDocumentStreamByGuid"),
}