<div class="locator_section position-relative align-items-center gap-2">
    <div class="d-flex gap-2">
        <app-patient-overlay-wizard-loader  *appUserHasPermission="patientFeatureCreatePermission"/>
    <a *ngFor="let item of headerMenuItems" [hidden]="item.hidden" 
        [title]="item.title" [class]="item.classes!" [routerLinkActive]="['active']" 
        [routerLink]="item.url ? item.url : null"
        class="header_menu_icon header_menu_icon header_menu_icon-tab header_pay_icon" 
        (click)="menuClickHandler(item)" [ngClass]="{'disabled-div': item.disable === true}">
        <img class="wid-25" [src]="item.icon">
    </a>
    </div>
    <div class="header-search">
        <app-patient-search-suggession-loader *appUserHasPermission="patientSearchFeaturePermission"/>
    </div>
</div>

<ng-template #scheduleOverlay></ng-template>
<ng-template #paymentOverlay></ng-template>