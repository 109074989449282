<div cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
[ngClass]="!minimizeChat ? 'new-chat-root':'minimize-dailer'">
    <div class="header d-flex align-items-center justify-content-between px-2 py-2 content-header"
    [ngClass]="!minimizeChat ? 'border-bottom ':''" #matDlgHeader>
        <h6 class="m-0 text-title h6 flex-grow-1">Chat</h6>
        <div class="d-flex align-items-center">
            <!-- <i class="feather {{ !minimizeChat ? 'icon-minimize' : ' icon-maximize' }} f-20 cursor-pointer" (click)="minimizeChatPopup()"></i> -->
            <i class="feather icon-x f-20 cursor-pointer" aria-label="Close" (click)="closeModal()"></i>
        </div>
    </div>
    <div class="d-flex flex-column mt-2" [class.d-none]="minimizeChat">

        <ng-container *ngIf="dialerClick === chatActions.chat">
            <div class="content pt-2">
            
                <div *ngIf="!bShowProgress; else progress" class="container-fluid" style="font-size: 10px;">
                    <div class="row">
                        <form [formGroup]="newChat">
                        
                            <div class="row justify-content-center px-2">
                                <app-form-control-textmask label="Mobile number" placeholder="Phone" formControlName="patientPhone"
                                    [appNumberMask]="maskingType.Phone" [required]="true">
                                </app-form-control-textmask>
                                <div class="footer mt-2 buttons-container justify-content-end align-items-center px-2">
                                    <button type="button" class="btn btn-primary text-nowrap" [disabled]="!newChat.valid"
                                        (click)="onStartChatClick()" [disabled]="phoneNumberSearchloading">
                                        <span *ngIf="!phoneNumberSearchloading"> Start chat</span>
                                        <span *ngIf="phoneNumberSearchloading" class="spinner-border spinner-border-sm me-2"></span>
                                        <span *ngIf="phoneNumberSearchloading">Loading...</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
    
                <ng-template #progress>
                    <div class="k-block text-center bg-transparent border-0">
                        <kendo-loader [type]="'infinite-spinner'" [themeColor]="'info'"
                            [size]="'medium'"></kendo-loader>
                    </div>
                </ng-template>
    
            </div>
        </ng-container>

        <ng-container *ngIf="dialerClick === chatActions.recent">
            <ng-container *ngIf="recentChatloading" [ngTemplateOutlet]="chatSkeletonTemplate"></ng-container>
            <ng-container *ngIf="!recentChatloading && arrRecentPatients.length > 0">
                <div class="calllog custom-scroll-y ps-0 pe-2 mx-2">
                    <div class="row py-2" *ngFor="let contact of arrRecentPatients;let i = index">
                        <div class="col-9">
                            <div class="cl-main-us d-flex">
                                <div class="position-relative">
                                    <div class="us-icon-cl">
                                        <div class="d-flex wid-35 hei-35 align-items-center justify-content-center bg-primary text-white img-radius f-12 circledata0" ngClass="circledata{{i%4}}">
                                            {{contact.patientFirstName.charAt(0)}}{{contact.patientLastName.charAt(0)}}</div>
                                    </div>
                                    <div class=""  [ngClass]="contact.numberOfUnReadMessages === 0 ? '' : 'wid-15 hei-15 bg-danger position-absolute img-radius blink_message_indicator'"></div>
                                </div>
                                <div class="cl-text ps-3">
                                    <h6 class="mb-1 " [ngClass]="contact.numberOfUnReadMessages === 0 ? 'f-w-500' : 'fw-bold'">{{contact.patientLastName}} {{contact.patientFirstName}}</h6>
                                    <p class="mb-0 text-wrap text-break">{{ contact.isMMS ? 'MMS': contact.textMessage?.length > 50 ?
                                        contact.textMessage.substring(0, 50)+'...' : contact.textMessage }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 align-items-center">
                            <div class="d-flex align-items-center cl-icons gap-1">
                                <i class="feather icon-phone f-20 text-primary cursor-pointer" (click)="onCallClick(contact)" *applicationFeature="'isVoIpenabled'"></i>
                                <i class="feather icon-message-square text-secondary f-20 cursor-pointer" (click)="onChatClick(contact)"></i>
                                <!-- <div title="Make a Call" (click)="onCallClick(contact)">
                                    <img src="assets/images/icons/missed-call.png" width="20" height="20" class="img-fluid">
                                </div>
                                <div title="Text Message" (click)="onChatClick(contact)">
                                    <img src="assets/images/icons/chat.png" width="20" height="20" class="img-fluid">
                                </div> -->
            
                            </div>
                            <span *ngIf="contact.numberOfUnReadMessages > 0"
                                class="badge bg-danger badge-top text-white">{{contact.numberOfUnReadMessages}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!recentChatloading && arrRecentPatients.length ===0"  [ngTemplateOutlet]="noRecordFoundTemplate">
            </ng-container>
        </ng-container>

        <ng-container *ngIf="dialerClick === chatActions.unread">
            <ng-container *ngIf="recentChatloading" [ngTemplateOutlet]="chatSkeletonTemplate"></ng-container>
            <ng-container *ngIf="!recentChatloading && this.unreadPatients.length > 0">
                <div class="calllog custom-scroll-y ps-0 pe-2 mx-2">
                    <div class="row py-2" *ngFor="let contact of this.unreadPatients;let i = index">
                        <div class="col-9">
                            <div class="cl-main-us d-flex">
                                <div class="position-relative">
                                    <div class="us-icon-cl">
                                        <div class="d-flex wid-35 hei-35 align-items-center justify-content-center bg-primary text-white img-radius f-12 circledata0" ngClass="circledata{{i%4}}">
                                            {{contact.patientFirstName.charAt(0)}}{{contact.patientLastName.charAt(0)}}</div>
                                    </div>
                                    <div class=""  [ngClass]="contact.numberOfUnReadMessages === 0 ? '' : 'wid-15 hei-15 bg-danger position-absolute img-radius blink_message_indicator'"></div>
                                </div>
                                <div class="cl-text ps-3">
                                    <h6 class="mb-1">{{contact.patientLastName}} {{contact.patientFirstName}}</h6>
                                    <p class="mb-0 text-wrap text-break">{{ contact.isMMS ? 'MMS': contact.textMessage?.length > 50 ?
                                        contact.textMessage.substring(0, 50)+'...' : contact.textMessage }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 align-items-center">
                            <div class="d-flex align-items-center cl-icons">
                                <i class="feather icon-message-square text-secondary f-20 cursor-pointer" (click)="onChatClick(contact)"></i>
                            </div>
                            <span *ngIf="contact.numberOfUnReadMessages > 0"
                                class="badge bg-danger badge-top text-white">{{contact.numberOfUnReadMessages}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!recentChatloading && unreadPatients.length ===0"  [ngTemplateOutlet]="noRecordFoundTemplate">
            </ng-container>
        </ng-container>

        <ng-container *ngIf="dialerClick === chatActions.contact">
            <app-patient-search-suggestion [isFromChat]="true" class="search-patient-common"></app-patient-search-suggestion>
        </ng-container>

        <div class="bottommenu border-top">
            <div class="bmenuitem cursor-pointer position-relative" [ngClass]="{'active': dialerClick === chatActions.recent}" (click)="fnShowData(chatActions.recent)">
                <i class="fa fa-solid  f-14 fa-history"></i>
                <p class="mt-1 f-12">Recent</p>
                <div class=""  [ngClass]="hasUnReadChat ? 'wid-15 hei-15 bg-danger position-absolute img-radius blink_message_indicator recent_blink' : ''"></div>
            </div>
            <div class="bmenuitem cursor-pointer position-relative" [ngClass]="{'active': dialerClick === chatActions.unread}" (click)="fnShowData(chatActions.unread)">
                <i class="fa fa-solid  f-14 fa-history"></i>
                <p class="mt-1 f-12">Unread</p>
            </div>
            <div class="bmenuitem cursor-pointer" [ngClass]="{'active': dialerClick === chatActions.chat}" (click)="fnShowData(chatActions.chat)">
                <i class="fa fa-solid f-14 fa-keyboard"></i>
                <p class="mt-1 f-12">Number</p>
            </div>
            <div class="bmenuitem cursor-pointer" [ngClass]="{'active': dialerClick === chatActions.contact}" (click)="fnShowData(chatActions.contact)">
                <i class="fa fa-solid f-14 fa-user"></i>
                <p class="mt-1 f-12">Patients</p>
            </div>
        </div>
    </div>
</div>


<ng-template #chatSkeletonTemplate>
    <div class="mt-3">
        <app-lookup-edit-list-skeleton class="remove-card-shadow remove-padding-skeleton" [fields]="4"></app-lookup-edit-list-skeleton>
    </div>
</ng-template>

<ng-template #noRecordFoundTemplate>
    <div class="w-100 text-center p-3">
        <img src="../../../../../assets/images/svg/no-search-icon.svg" class="w-25" />
        <h5 class="ms-2 f-18 mt-2"> No Record Found</h5>
    </div>
</ng-template>