<div class="image-viewer">
    <ng-container *ngIf="viewerImageList[currentImageIndex]?.imageSrc">
        <div>
            <h4 class="fw-bold">{{viewerImageList[currentImageIndex].documentName}} <span
                    class="fw-normal">{{currentImageIndex+1}}/{{viewerImageList.length}}</span></h4>
        </div>
        <div class="position-relative w-100">
            <div class="image-container mx-auto" #imageContainer>
                <img class="img-fluid" [ngClass]="zoomLevel>1?'zoomed':''"
                    [src]="viewerImageList[currentImageIndex].imageSrc" [style.transform]="'scale(' + zoomLevel + ')'">
            </div>
            <div class="d-flex justify-content-between align-items-center position-absolute w-100"
                style="top:0;bottom:0;">
                <i class="fa fa-solid fa-chevron-circle-left f-30 cursor-pointer" (click)="previousImage()"></i>
                <i class="fa fa-solid fa-chevron-circle-right f-30 cursor-pointer" (click)="nextImage()"></i>
            </div>
        </div>
        <div class="controls position-absolute align-items-center">
            <i class="fa fa-solid fa-search-plus cursor-pointer f-22 me-3" (click)="zoomIn()"></i>
            <i class="fa fa-solid fa-search-minus cursor-pointer f-22 me-3" (click)="zoomOut()"></i>
            <button (click)="closeViewer()" *ngIf="!hideCloseIcon"
                class="wid-30 hei-30 img-radius btn btn-light-primary d-flex justify-content-center align-items-center">
                <i class="feather icon-x cursor-pointer"></i></button>
        </div>
    </ng-container>

    <ng-container *ngIf="!viewerImageList[currentImageIndex]?.imageSrc">
        <div class="image-container">
            <img class="img-fluid" [src]="defaultImagePath">
        </div>
    </ng-container>

    <ng-container>
        <div class="image-list-scroll">
            <div class="d-flex justify-content-center">
                <div *ngFor="let image of viewerImageList;let i=index">
                    <div class="image-sub-view cursor-pointer my-1"
                        [ngClass]="i===currentImageIndex?'border-danger shadow':''" (click)="changeImage(i)">
                        <img class="img-fluid" [src]="image?.imageSrc || defaultImagePath">
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>