import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChairLiveStatusService {

  private chairOpened: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  chairCloseEvent(isChairOpened: boolean): void {
    this.chairOpened.next(isChairOpened);
  }

  get chairOpenedResponse$() {
    return this.chairOpened.asObservable();
  }
}
