import { createReducer, on } from '@ngrx/store';
import { patientLiveStatus } from './patient-live-status-state';
import {
    getPatientAllergies,
    getPatientAllergiesFailure,
    getPatientAllergiesSuccess,
    getPatientAppointmentsByDateSuccess, getPracticeChairConfigforPatientLiveStatusSuccess,
    getPracticeLocationsforLiveStatusFailure,
    getPracticeLocationsforLiveStatusSuccess,
    invokeGetPracticeLocationsforLiveStatus,
    invokePatientLiveSaveStatusAppointment,
    patientLiveStatusStartLoading,
    patientLiveStatusStopLoading,
    removeAppointmentsToliveStatusStore,
    resetLiveStatusAppointmentsAndChairConfig,
    resetPatientLiveStatusStateEvents,
    resetPatientLiveStatusStore,
    savePatientLiveSaveStatusAppointmentFailure,
    savePatientLiveSaveStatusAppointmentSuccess,
    updateAppointmentsToliveStatusStore,
    updateClipboardAppointmentToliveStatusStore,
    updateHasFutureAppointmentToliveStatusStore
} from './patient-live-status-action';

const chairConfigObjects = {
    practiceChairConfigId: 0,
    practiceId: 0,
    practiceLocationId: 0,
    chairStyle: '',
    chairFlowBarSequence: '',
    startTime: '',
    endTime: '',
    isActive: false,
    chairs: [],
    cellDuration: 0
}


export const initialState: patientLiveStatus = {
    patientAppointments: [],
    patientLiveStatusStateEvents: {
        errorMessage: "",
        showErrorAlert: false,
        loading: false,
        isFormSubmitted: false,
        showSuccessAlert: false,
        successToastMessage: '',
        actionType: '',
        isLoadingLocations: false,
        isLoadingAppointmentHistory: false,
        isSubmitted: false
    },
    practiceChairConfigurations: {
        ...chairConfigObjects
    },
    practiceLocation: [],
    patientAllergies: [],
    patientAllergiesStateEvents: {
        errorMessage: "",
        showErrorAlert: false,
        loading: false,
        isFormSubmitted: false,
        showSuccessAlert: false
    },
};

export const patientLiveStatusReducer = createReducer(
    initialState,

    on(getPatientAppointmentsByDateSuccess, (state, { appointments }): patientLiveStatus => ({
        ...state,
        patientAppointments: appointments,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            isLoadingLocations: false,
            loading: false
        },
    })),

    on(savePatientLiveSaveStatusAppointmentSuccess, (state, { appointment, action }) => {
        const updatedAppointments = state.patientAppointments.map(appt => {
            if (appt.patientAppointmentId === appointment.patientAppointmentId) {
                return { ...appt, ...appointment };
            }
            return appt;
        });
        return {
            ...state,
            patientAppointments: updatedAppointments,
            patientLiveStatusStateEvents: {
                ...state.patientLiveStatusStateEvents,
                actionType: action,
                isFormSubmitted:false
            },
        };
    }),

    on(getPracticeChairConfigforPatientLiveStatusSuccess, (state, { schedulerConfiguration }): patientLiveStatus => {
        const updatedScheduleConfig = {
            ...schedulerConfiguration[0],
            chairs: schedulerConfiguration[0]?.chairs?.filter(chair => chair.isActive === true)
        };

        return {
            ...state,
            practiceChairConfigurations: updatedScheduleConfig,
        }
    }),

    on(resetPatientLiveStatusStateEvents, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            loading: false,
            actionType: ''
        },
    })),

    on(updateAppointmentsToliveStatusStore, (state: patientLiveStatus, { appointment }) => {
        const existingAppointments = [...state.patientAppointments];
        const existingIndex = existingAppointments.findIndex(appt => appt.patientAppointmentId === appointment.patientAppointmentId);
        const updatedAppointments = [...existingAppointments];
        if (existingIndex !== -1) {
            updatedAppointments[existingIndex] = appointment;
        } else {
            updatedAppointments.push(appointment);
        }
        const updatedState = {
            ...state,
            patientAppointments: updatedAppointments,
        };
        return updatedState;
    }),


    on(removeAppointmentsToliveStatusStore, (state: patientLiveStatus, { appointment }) => {
        const existingAppointments = [...state.patientAppointments];
        const filteredAppointments = existingAppointments.filter(appt => appt.patientAppointmentId !== appointment.patientAppointmentId);
        const updatedState = {
            ...state,
            patientAppointments: filteredAppointments,
        };
        return updatedState;
    }),

    on(invokeGetPracticeLocationsforLiveStatus, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            isLoadingLocations: true
        },
    })),

    on(getPracticeLocationsforLiveStatusSuccess, (state, { practiceLocation }): patientLiveStatus => ({
        ...state,
        practiceLocation: practiceLocation.filter(location => location.isActive),
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            isLoadingLocations: false,
            loading: false,
        },
    })),

    on(getPracticeLocationsforLiveStatusFailure, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            isLoadingLocations: false,
            loading: false,
        },
    })),

    on(patientLiveStatusStartLoading, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            loading: true,
        },
    })),

    on(patientLiveStatusStopLoading, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            loading: false,
        },
    })),

    on(patientLiveStatusStopLoading, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            loading: false,
        },
    })),

    on(resetLiveStatusAppointmentsAndChairConfig, (state): patientLiveStatus => ({
        ...state,
        practiceChairConfigurations: {
            ...chairConfigObjects
        },
        patientAppointments: []
    })),

    on(getPatientAllergies, (state): patientLiveStatus => ({
        ...state,
        patientAllergiesStateEvents: {
            ...state.patientAllergiesStateEvents,
            loading: true,
        },
    })),

    on(getPatientAllergiesSuccess, (state, { patientAllergies }): patientLiveStatus => ({
        ...state,
        patientAllergies: patientAllergies,
        patientAllergiesStateEvents: {
            ...state.patientAllergiesStateEvents,
            loading: false,
        },
    })),

    on(getPatientAllergiesFailure, (state, action): patientLiveStatus => ({
        ...state,
        patientAllergiesStateEvents: {
            ...state.patientAllergiesStateEvents,
            showErrorAlert: true,
            errorMessage: action.error,
            loading: false,
        },
    })),
    on(updateClipboardAppointmentToliveStatusStore, (state: patientLiveStatus, { appointment }) => {
        const existingAppointments = [...state.patientAppointments];
        const appointmentIndex = existingAppointments.findIndex(appt => appt.patientAppointmentId === appointment.patientAppointmentId);
        if (appointmentIndex !== -1) {
            if (existingAppointments[appointmentIndex].practiceLocationId !== appointment.practiceLocationId) {
                existingAppointments.splice(appointmentIndex, 1);
            }
        }
        const updatedState = {
            ...state,
            patientAppointments: existingAppointments,
        };
        return updatedState;
    }),
    on(invokePatientLiveSaveStatusAppointment, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            isFormSubmitted: true
        },
    })),
    on(savePatientLiveSaveStatusAppointmentFailure, (state): patientLiveStatus => ({
        ...state,
        patientLiveStatusStateEvents: {
            ...state.patientLiveStatusStateEvents,
            isFormSubmitted: false
        },
    })),
    on(updateHasFutureAppointmentToliveStatusStore, (state: patientLiveStatus, { appointment }) => {
        const existingAppointments = [...state.patientAppointments];
        const updatedAppointments = existingAppointments.map(item => {
            if (item.patientId === appointment.patientId) {
              return { ...item, hasFutureAppointment: appointment.hasFutureAppointment };
            } else {
              return item;
            }
          });
        const updatedState = {
            ...state,
            patientAppointments: updatedAppointments,
        };
        return updatedState;
    }),
    
    on(resetPatientLiveStatusStore, () => initialState),
);