import { Injectable } from "@angular/core";
import { UserPermission } from "../store/state/auth-user";
import { AuthService } from "./authentication/auth.service";
import { ModulePermission, PracticeUserPermission } from "../../practice/store/user-roles-store/user-role-state";
import { sharedFeaturePermission } from "../constant/shared-module-feature";
import { featureName, moduleName, operationName, roles } from "../constant/user-role-constant";

@Injectable({ providedIn: 'root' })
export class UserAuthService {
    permission: PracticeUserPermission  | undefined;
    permissions: PracticeUserPermission [] | undefined;
    isPatientUser : boolean = false
    constructor(private authService: AuthService) { }

    hasPermission(userPermissions: UserPermission[] | undefined): boolean {
        this.isPatientUser = this.authService.getIsPatientUserRoleFromToken();
        if (this.authService.getToken()) {
            this.permission = this.authService.getUserRolesAndPermission();
        }
    
        if (!userPermissions || userPermissions.length === 0) return false;
        if (!this.permission || !this.permission.modulePermissions) return false;
    
        let hasPermissionOnNewPermission = false;
        this.mapSharedFeaturePermission(this.permission.modulePermissions);
        for (const userPermission of userPermissions) {
            for (const modulePermission of this.permission.modulePermissions) {
                if (userPermission.moduleName === modulePermission.moduleName  || (!userPermission.moduleName && userPermission.featureName!.includes(featureName.patientSearch))) {
                    for (const featurePermission of modulePermission.featurePermissions) {
                        if (userPermission.featureName === featurePermission.featureName && (!this.isPatientUser && (featurePermission.operations.includes(operationName.all) || userPermission.operationName!.includes(operationName.all)) ||
                                featurePermission.operations.includes(userPermission.operationName!)
                            )) {
                            hasPermissionOnNewPermission = true;
                            break;
                        }
                    }
                }
                if (hasPermissionOnNewPermission) break;
            }
            if (hasPermissionOnNewPermission) break;
        }
    
        return hasPermissionOnNewPermission;
    }
    
    mapSharedFeaturePermission(modulePermissions: ModulePermission[]) {
        if (this.authService.getUserRoleFromToken() == roles.patient || this.authService.getUserRoleFromToken() == roles.patientFRP
            || this.authService.getUserRoleFromToken() == roles.selfCheckIn) return;
        const sharedFeaturePermissions = sharedFeaturePermission;
        let patientSearchFeaturePermission = sharedFeaturePermissions.find(x => !x.moduleName)?.featurePermissions.find(x => x.featureName.includes(featureName.patientSearch));
        let patientInformationFeaturePermission = sharedFeaturePermissions.find(x => !x.moduleName)?.featurePermissions.find(x => x.featureName.includes(featureName.patient));
        modulePermissions.forEach(module => {
            switch (module.moduleName) {
                case moduleName.scheduler:
                case moduleName.patient:
                case moduleName.serviceContract:
                case moduleName.paymentManagement:
                case moduleName.insurance:
                case moduleName.report:
                    if (patientSearchFeaturePermission && !module.featurePermissions.some(x => x.featureName == patientSearchFeaturePermission?.featureName)) {
                        module.featurePermissions.push(patientSearchFeaturePermission!)
                    }
                    const isPatientFeaturePermissionExist = module.featurePermissions.some(x => x.featureName.includes(featureName.patient));
                    if (patientInformationFeaturePermission && !isPatientFeaturePermissionExist) {
                        module.featurePermissions.push(patientInformationFeaturePermission)
                    }
                    return;
                default:
                    break;
            }
        });
    }
}