import { environment } from "src/environments/environment";

const PRACTICE_API_BASE_URL = environment.application.api_base_practice_url;

function practiceApiUrl(endpoint: string) {
    return `${PRACTICE_API_BASE_URL}${endpoint}`;
}

export const practiceCoreApiAssert = {
    getStates: practiceApiUrl("Core/GetStates"),
    getTimeZones: practiceApiUrl("Core/GetTimeZones")
}