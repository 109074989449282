import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors, Validator
} from '@angular/forms';

@Component({
  selector: 'app-form-control-textmask',
  template: `
            <div class="form-group" [class.has-error]="hasError()">

              <label [for]="label" *ngIf="label">{{ label }}
                <span *ngIf="required" class="required_field ms-1">*</span>
              </label>

              <input 
                type="text" 
                [class]="'form-control ' + (label ? '' : 'm_t')" 
                [value]="value" 
                [placeholder]="setPlaceHolder"
                [disabled]="disabledField || disabled"
                [id]="label"
                
                (input)="onInputEvent($event)"
                (focus)="onFocusEvent()"
                (blur)="onBlurEvent()"
              />

              <ng-container *ngIf="hasError()">
                <app-validation-error [errors]="control?.errors" [fieldName]="errorMessage ? errorMessage :  !label ? placeholder : label">
                </app-validation-error>
              </ng-container>
            </div>
           `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlTextmaskComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlTextmaskComponent),
      multi: true
    }
  ],
})
export class FormControlTextmaskComponent implements ControlValueAccessor, Validator {

  @Input() label!: string;
  @Input() required?: boolean | false;
  @Input() placeholder?: string;
  @Input() focusPlaceholder?: string;
  @Input() disabledField?: boolean | false;
  @Input() errorMessage!: string;

  setPlaceHolder: string | undefined
  value: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  control: AbstractControl | undefined;
  disabled: boolean;


  constructor() {
    this.disabled = false;
    this.label = '';
  }

  ngOnInit() {
    this.setPlaceHolder = this.placeholder || this.label;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateTheValue(event: any) {
    this.value = (event.target as HTMLInputElement).value;
    this.onChange(this.value);
    this.control?.markAsTouched();
  }

  onInputEvent(event: any): void {
    this.updateTheValue(event);
  }

  onBlurEvent(): void {
    this.onTouched()
    this.setPlaceHolder = this.placeholder || this.label;
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

  onFocusEvent() {
    this.setPlaceHolder = this.focusPlaceholder || this.placeholder || this.label;
  }

}