import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedFeatureModule } from 'src/app/features/shared/shared-feature.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ChatDialogMenuComponent } from './chat-dialog-menu/chat-dialog-menu.component';
import { ChatApiService } from '../../api/chat.api.service';
import { NewChatComponent } from './new-chat/new-chat.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { chatReducer } from '../../store/chat-store/chat.reducer';
import { ChatEffects } from '../../store/chat-store/chat.effect';
import { ChatContentsComponent } from './chat-contents/chat-contents.component';
import { PatientDashboardModule } from 'src/app/features/patientdashboard/patient-dashboard.module';

@NgModule({
  declarations: [
    ChatDialogMenuComponent,
    NewChatComponent,
    ChatContentsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IndicatorsModule,
    ReactiveFormsModule,
    SharedFeatureModule,
    StoreModule.forFeature('chatStore', chatReducer),
    EffectsModule.forFeature([ChatEffects]),
    PatientDashboardModule
  ],
  exports: [
    ChatDialogMenuComponent
  ],
  providers: [
    ChatApiService
  ],
})
export class ChatModule { }
