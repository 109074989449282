import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppInsightService } from './app-insights';

interface LoggingFunction {
  (value: any, ...rest: any[]): void;
}
export interface LoggerService {
  info: LoggingFunction;
  log: LoggingFunction;
  warn: LoggingFunction;
  error: LoggingFunction;
}

@Injectable()
export class BrowserLoggerService implements LoggerService {
    constructor(private appInsightService: AppInsightService) {
    }
    info(value: any, ...rest: any[]): void {
    if(environment.production === false || !environment.application.appInsightsLog) {
      console.info(value, rest);
    } else {
        this.appInsightService.logTrace(value);
    }
  }

  log(value: any, ...rest: any[]): void {
    if (environment.production === false || !environment.application.appInsightsLog) {
    } else {
        this.appInsightService.logTrace(value);
    }
  }

  warn(value: any, ...rest: any[]): void {
    if (environment.production === false || !environment.application.appInsightsLog) {
      console.warn(value, rest);
    } else {
        this.appInsightService.logException(value);
    }
  }

  error(value: any, ...rest: any[]): void {
    if (environment.production === false || !environment.application.appInsightsLog) {
      console.error(value, rest);
    } else {
        this.appInsightService.logException(value);
    }
  }
}