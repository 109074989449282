import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-control-audioplayer',
  template: `
            <div class="d-flex gap-2">
            <label *ngIf="label">{{ label }} </label>

            <i *ngIf = "audioType === 'isCallRecording'" class="feather text-success f-22 cursor-pointer" title = "Call Recording" [ngClass]="iconPlayClass" (click)="playAudio()"></i>
            <div *ngIf= "audioType === 'isVoiceMail'" (click)="playAudio()" title = "Voicemail">
              <div *ngIf= "!isPlaying" class="position-relative mt-2 cursor-pointer" >
                <i class="feather icon-play position-absolute f-12"
                    style="top:-9px;left:6px;color:#bfa087"></i>
                <i class="feather icon-voicemail f-22" style="color:#bfa087"></i>
              </div>
              <span *ngIf= "isPlaying">
              <i class="feather icon-pause f-22 cursor-pointer" style="color:#bfa087"></i>
            </span>
          </div>
          
            <audio [src]="sourceUrl"  type = "audio/mpeg" [controls]="audioType === 'isPlayer'" #audioPlayerRef></audio>
        </div>
            `,
})

export class FormControlAudioplayerComponent {

  @Input() label!: string;
  @Input() errorMessage!: string;
  @Input() required?: boolean | false;
  @Input() placeholder?: string;
  @Input() requiredField?: boolean | false;
  @Input() src!: string | undefined;
  @Input() audioType!: string;

  isPlaying: boolean = false;
  sourceUrl: string | undefined;
  @ViewChild('audioPlayerRef') audioPlayerRef: any;

  constructor() { }

  ngOnInit() {
    this.sourceUrl = this.src;
  }

  ngAfterViewInit() {
    this.audioPlayerRef?.nativeElement.addEventListener('ended', () => {
      this.isPlaying = false;
    });
    this.audioPlayerRef.nativeElement.addEventListener('pause', () => {
      this.isPlaying = false;
    });
  }

  get iconPlayClass(): string {
    if (!this.isPlaying) {
      return 'icon-play'
    }
    return this.audioPlayerRef?.nativeElement?.paused ? 'icon-play' : 'icon-pause';
  }

  playAudio(): void {
    const audioPlayer = this.audioPlayerRef.nativeElement as HTMLAudioElement;
    const currentlyPlayingAudio = document.querySelector('audio[playing]');

    if (currentlyPlayingAudio && currentlyPlayingAudio !== audioPlayer) {
      (currentlyPlayingAudio as HTMLAudioElement).pause();
      currentlyPlayingAudio.removeAttribute('playing');
    }

    if (this.isPlaying) {
      audioPlayer.pause();
    } else {
      audioPlayer.play();
    }

    if (!audioPlayer.paused) {
      audioPlayer.setAttribute('playing', 'true');
    } else {
      audioPlayer.removeAttribute('playing');
    }

    this.isPlaying = !this.isPlaying;
  }

  ngOnDestroy() {
    if (this.isPlaying) {
      this.audioPlayerRef.nativeElement.pause();
      this.audioPlayerRef.nativeElement.removeAttribute('playing');
      this.isPlaying = false;
    }
    this.audioPlayerRef?.nativeElement.removeEventListener('ended', () => {
      this.isPlaying = false;
    });

    this.audioPlayerRef.nativeElement.removeEventListener('pause', () => {
      this.isPlaying = false;
    });
  }
}