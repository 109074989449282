import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, of, switchMap } from 'rxjs';
import { PracticeInfoAPIService } from '../../api/practice-info-api.service';
import {
  invokeGetPractices, GetPracticesSuccess,
  GetPracticesFailure,
  invokeGetPracticesByEmail,
  GetPracticesByEmailFailure,
  GetPracticesByEmailSuccess
} from '../action/practice-list.action';


@Injectable()
export class PracticeListEffect {
  constructor(
    private actions$: Actions,
    private practiceListAPIService: PracticeInfoAPIService
  ) { }

  invokeGetPractices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeGetPractices),
      switchMap(() => {
        return this.practiceListAPIService.getPracticesDetails().pipe(
          map(practiceListDetails => GetPracticesSuccess({ practiceListDetails })),
          catchError(errorMessage => of(GetPracticesFailure({ errorMessage: errorMessage })))
        );
      })
    )
  }
  )
  invokeGetPracticesByEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokeGetPracticesByEmail),
      switchMap((action) => {
        return this.practiceListAPIService.getPracticesByEmail(action.email).pipe(
          map(practiceListDetails => GetPracticesByEmailSuccess({ practiceListDetails })),
          catchError(errorMessage => of(GetPracticesByEmailFailure({ errorMessage: errorMessage })))
        );
      })
    )
  }
  )
}