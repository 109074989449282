// Angular Import
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { GradientConfig } from 'src/app/app-config';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { resetMenuState, selectedPracticeId, updateMenuId, updateSelectedSubMenuItem } from 'src/app/theme/store/menu/menu.actions';
import { MenuItem } from 'src/app/theme/store/menu/menu.state';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { invokeGetPracticesByEmail } from 'src/app/features/practice/store/action/practice-list.action';
import { selectPracticesList } from 'src/app/features/practice/store/selector/practice-info.selector';
import { practiceFeaturesInformation, PracticePersonalInformation } from 'src/app/features/practice/store/state/practice-info';
import { applicationStorageKeys, featureName, moduleName, operationName, roles, routeNames } from 'src/app/features/user/constant/user-role-constant';
import { AppSessionStorageService } from 'src/app/features/shared/services/app-session-storage.service';
import { selectLocations } from 'src/app/features/practice/store/selector/practice-core-selector';
import { PracticeLocation } from 'src/app/features/patientdashboard/types/patient-search-type';
import { AppLocalStorageService } from 'src/app/features/shared/services/app-local-storage.service';
import { clearPracticeApplicationFeatureState } from 'src/app/features/practice/store/action/practice-application-feature.action';
import { selectPracticeFeaturesInfo } from 'src/app/features/practice/store/selector/practice-application-feature.selector';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NavBarComponent implements OnInit, OnDestroy {
  // public props
  gradientConfig;
  menuClass: boolean;
  collapseStyle: string;
  windowWidth: number;

  // private props
  closeResult = 'string';
  menulist!: MenuItem[];
  loggedInUserName: string = "";
  isSuperAdmin: boolean = false;
  isPatientUser = false;
  showSideNavigation = false;

  @Output() NavCollapsedMob = new EventEmitter();
  @Output() showChair = new EventEmitter<boolean>();
  @Output() patientLiveStatusEvent = new EventEmitter<boolean>();

  practicesForm!: FormGroup;
  IsVisiblePatientLiveStatusPanel = false;
  locationId!: number;
  practices: PracticePersonalInformation[] = [];
  loggedInUserEmail: string = '';

  private subscriptions$ = new Subscription();
  selectPracticesList$ = this.store.select((selectPracticesList));
  selectlocations$: any = this.store.select((selectLocations));
  
  liveLocationId!: number;
  practiceLocations: PracticeLocation[] =[];
  practiceListFeaturePermission = { moduleName: moduleName.practice, featureName: featureName.practiceList, operationName: operationName.create }
  patientLiveStatusFeaturePermission = { moduleName: moduleName.scheduler, featureName: featureName.schedule, operationName: operationName.create }
  patientChatDashboardPermission = { moduleName: moduleName.patient, featureName: featureName.patient, operationName: operationName.create }
  practiceFeature$ = this.store.select((selectPracticeFeaturesInfo));
  isPatientLiveStatusCheckoutEnabled = false;

  // constructor
  constructor(
    private offcanvasService: NgbOffcanvas,
    private store: Store,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private sessionStorageService: AppSessionStorageService,
    private router: Router,
    private localStorageService: AppLocalStorageService
  ) {
    this.practicesForm = this.formBuilder.group({
      practiceId: [null]
    })
    //todo: storage event should not subscribed here. It should be moved into app local storages service which is already created.
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === 'authUser') {
        if (!this.isSuperAdmin) {
          this.sessionStorageService.setItem(applicationStorageKeys.selectedPracticeIdBySA, this.authService.getPracticeIDFromToken())
        }
        //todo: reload should not be called here. It should be moved into AppRefreshService.
        location.reload();
      }
    });
    this.loggedInUserName = this.authService.getUserPreferredNameFromToken();
    const loggedInUserRole = this.authService.getUserRoleFromToken();
    this.isSuperAdmin = loggedInUserRole === roles.superAdmin;
    this.gradientConfig = GradientConfig;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
    this.loggedInUserEmail = this.authService.getEmailFromToken();
  }

  ngOnInit(): void {
    this.isPatientUser = this.authService.getIsPatientUserRoleFromToken();
    if (this.isSuperAdmin) {
      this.store.dispatch(invokeGetPracticesByEmail({ email: this.loggedInUserEmail }));
    }
    this.subscriptions$.add(this.selectlocations$.subscribe((data: PracticeLocation[]) => { this.bindPracticeLocations(data);  }));
    this.subscriptions$.add(this.selectPracticesList$.subscribe((data: PracticePersonalInformation[]) => this.bindPractices(data)));
    this.sessionStorageService.getObservable(applicationStorageKeys.selectedPracticeIdBySA).subscribe((value: string) => {
      this.practicesForm.patchValue({
        practiceId: Number(value),
      });
    });
    this.subscriptions$.add(this.practiceFeature$.subscribe((features: practiceFeaturesInformation) => { this.getPracticeFeatures(features);  }));

  }

  bindPracticeLocations(data: PracticeLocation[]) {
    if(data && data.length > 0) {
        this.practiceLocations = [...data].sort((a, b) =>
          a.practiceLocationName!.localeCompare(b.practiceLocationName!));
        this.setLocation();
    }
  }

  navigateToChatDashboard() {
    this.router.navigate(['/chat']); 
  }

  setLocation() {
    const logginedLocationId = this.authService.getLocationIdFromToken();
    if(!logginedLocationId ){
        this.localStorageService.setItem(applicationStorageKeys.loginUserLocationId, this.practiceLocations[0].practiceLocationId.toString())
    }
  }

  // private method
  open(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { animation: true, panelClass: 'left_side_menu', position: 'end' });
  }

  // public method
  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = this.menuClass ? 'block' : 'none';
  }

  clickMenu(item: MenuItem) {
    this.store.dispatch(updateMenuId({ menuId: item.id }));
    this.store.dispatch(updateSelectedSubMenuItem({ menuItem: item.children![0] }))
    if (this.isSuperAdmin)
      this.store.dispatch(selectedPracticeId({ practiceId: this.practicesForm.value.practiceId }));
  }

  bindPractices(data: PracticePersonalInformation[]) {
    if (data && data.length > 0) {
      this.practices = [...data].sort((a, b) => a.practiceId - b.practiceId);
      const localStoragePracticeId = sessionStorage.getItem(applicationStorageKeys.selectedPracticeIdBySA);
      if (localStoragePracticeId) {
        this.practicesForm.patchValue({
          practiceId: Number(localStoragePracticeId)
        });
      } else {
        this.practicesForm.patchValue({
          practiceId: Number(this.practices[0].practiceId.toString())
        });
        this.sessionStorageService.setItem(applicationStorageKeys.selectedPracticeIdBySA, this.practices[0].practiceId.toString())
      }
    }
  }

  showHidePatientLiveStatus() {
    this.patientLiveStatusEvent.emit();
  }

  onPracticeChange() {
    const selectedPractice = this.practices.find(x => x.practiceId === this.practicesForm.value.practiceId);
    if (selectedPractice) {
      this.sessionStorageService.setItem(applicationStorageKeys.selectedPracticeIdBySA, selectedPractice!.practiceId.toString());
      this.router.navigate([routeNames.superAdminPractice, this.practicesForm.value.practiceId]);
    }
  }

  showChairLiveStatus() {
    this.showChair.emit()
  }

  getLiveLocationID(locationId: number) {
    this.liveLocationId = locationId;
  }

  getPracticeFeatures(features: practiceFeaturesInformation) {
    if (!features) return;
    this.isPatientLiveStatusCheckoutEnabled = features.isPatientLiveStatusCheckoutEnabled || false;
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    this.store.dispatch(resetMenuState());
    this.store.dispatch(clearPracticeApplicationFeatureState());
  }
}