import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popup-list-modal',
  template: `
  <app-popup-modal
  [title]="title"
  [size]="size"
  [closeModel]="showModal"
  (onClose)="handleClose()"
>
  <input
    type="text"
    (input)="onSearch($event)"
    [placeholder]="placeholder"
    class="form-control mb-2"
  />

  <ng-scrollbar visibility="hover" class="mt-3 mb-2 box-height-300">
    <div class="d-flex flex-wrap gap-3">
      <div *ngIf="options.length === 0 || showNoRecord" class="d-flex align-items-center justify-content-center w-100">
        <app-no-record class="no-record-img"></app-no-record>
      </div>
      <ng-container *ngFor="let option of filteredOptions" class="mb-3 me-3">
        <ng-container *ngIf="option.isActive">
          <label
            class="d-flex align-items-center justify-content-center border border-primary rounded-3 py-2 px-3 cursor-pointer"
            [ngClass]="{'wid-110': slice,'bg-primary text-white': option.selected, 'text-primary': !option.selected}">
            <input
              type="checkbox"
              [(ngModel)]="option.selected"
              [name]="getDynamicProperty(option, optionName)" 
              [id]="getDynamicProperty(option, optionId)"
              [checked]="option.selected"
              class="d-none"
            />
            {{ slice ? (getDynamicProperty(option, optionName) | slice:0:5) : getDynamicProperty(option, optionName) }}
          </label>
        </ng-container>
      </ng-container>
    </div>
  </ng-scrollbar>

  <button type="button" class="btn btn-primary float-end my-3" (click)="onOkClick()">
    Ok
  </button>
</app-popup-modal>
  `,
})

export class PopupListModalComponent {
  @Input() title: string = '';
  @Input() size?: string = 'large';
  @Input() showModal: boolean = false;
  @Input() placeholder?: string = 'Search';
  @Input() optionName: string = '';
  @Input() optionId: string = '';
  @Input() options: any[] = [];
  @Input() slice: boolean = false

  @Output() closeModal = new EventEmitter<void>();
  @Output() okClick = new EventEmitter<any[]>();

  searchQuery: string = '';
  filteredOptions: any[] = [];
  showNoRecord!: boolean;

  ngOnInit() {
    this.filteredOptions = this.options.sort((a, b) =>
      this.getDynamicProperty(a, this.optionName).localeCompare(this.getDynamicProperty(b, this.optionName))
    );
  }

  onSearch(event: Event) {
    this.searchQuery = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredOptions = this.options.filter(option =>
      this.getDynamicProperty(option, this.optionName).toLowerCase().includes(this.searchQuery)
    );
    this.showNoRecord = this.filteredOptions.length>0 ? false : true;
  }

  handleClose() {
    this.closeModal.emit();
  }

  onOkClick() {
    this.okClick.emit(this.filteredOptions.filter(option => option.selected));
  }

  getDynamicProperty(option: any, propertyName: string): any {
    return option[propertyName];
  }
}