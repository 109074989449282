import packageInfo from '../../package.json';

export const environment = {
  appVersion: packageInfo.version,
  production: false,
  application: {
    api_base_user_url: 'https://demousermanagementapi.orthopms.com/api/',
    api_base_core_url: 'https://democoreapi.orthopms.com/api/',
    api_base_communication_url: 'https://democommunicationapi.orthopms.com/api/',
    api_base_practice_url: 'https://demopracticeapi.orthopms.com/api/',
    api_base_document_url: 'https://demodocumentapi.orthopms.com/api/',
    api_base_patient_url: 'https://demopatientapi.orthopms.com/api/',
    api_base_patient_image_url: 'https://demopatientimagingapi.orthopms.com/api/',
    api_base_payment_url: 'https://demopaymentapi.orthopms.com/api/',
    api_base_signalr_api_url: "https://demosignalrapi.orthopms.com/api/",
    api_base_schedule_url: "https://demoscheduleapi.orthopms.com/api/",
    api_base_report_url: "https://demoscheduleapi.orthopms.com/api/",
    api_base_report_api_url: "https://demoreportapi.orthopms.com/api/",
    api_base_signalr_url: "https://demosignalrapi.orthopms.com/",
    web_base_url:"https://demo.orthopms.com/",
    appInsightsLog: true,
    refreshThreshold: 1500,
    isRefreshToken: false,
    documentUploadMaxFiles: 10,
    schedulerConfig: {
      cellDuration: 15,
      signalRKickOffIntervalTimeInMs: 60000,
    },
    rcmEmailIds: ['mathewroy5540@gmail.com'],
    nuveiAPIEnvironment: 'int',
    txcardAutoSaveInterval: 2000,
    hideTechnicalError: false,
  },
  appInsights: {
    instrumentationKey: '0e9cbc3d-790c-4a2b-abab-8ba3eed31190'
  },
  devexpress: {
    licenceKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImY5YjU1YmQ5LTU1MzMtNDAwOS05MDgzLTJmYTU5NzA5NjI2YSIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjMyCn0=.UJUAfa4vpX5F3SzfgFjo718Ix3PfsRACUIKOPCNPo3iGHE6ABdT9z1c4htt9QVgC+eNfbDJVOEJXW63FQu/2aqyzWDtGPpHSOAI1NnNBz1t1mvm3s3NHjFQoENCaG+Z5dq6ycA=='
  },
  dynamSoft: {
    licenseKey: 'f0068ZQAAADbkUrF7vOZHNl9rtOOn+2L7fNHDBUAKaAuzxN9Sr9YZTSVh7+nnvN15Yp4LbhQ+ETPbUNzrYCHXoIHBRJ/PqU4='
  },
};
