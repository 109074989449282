import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { wizardHederNavAction, wizardStepAction } from '../../../types/shared-types';
import { CustomWizardService } from '../custom-wizard.service';
import { CommonUtilsService } from '../../../utils/common-utils.service';
import { CustomValidatorService } from '../../../utils/custom-validator.service';


@Component({
  selector: 'app-second',
  templateUrl: './second.component.html',
})
export class SecondComponent implements OnInit, OnDestroy {

  activeStepIndex = 0;
  addressInformation: FormGroup;

  @Output() stepSuccessEvent = new EventEmitter<any>();
  private subscriptions$ = new Subscription();

  constructor(
    private customWizardService: CustomWizardService,
    private fb: FormBuilder,
    private commonUtilsService: CommonUtilsService,
  ) {

    this.addressInformation = this.fb.group({
      practiceAddress1: ['', [Validators.required, CustomValidatorService.address1Validator()]],
      practiceAddress2: ['', [CustomValidatorService.address2Validator()]],
      practiceCity: ['', [Validators.required, CustomValidatorService.cityValidator()]],
      practiceState: ['', Validators.required],
      practiceZip: ['', [Validators.required, CustomValidatorService.zipCodeValidator()]],
    })

  }

  ngOnInit(): void {
    this.subscriptions$.add(this.customWizardService.currentStepIndex$.subscribe((activeStepIndex: number) => this.getStepIndex(activeStepIndex)));
    this.subscriptions$.add(
      this.customWizardService.currentStepActions$.subscribe((stepAction: wizardStepAction) => this.handleStepAction(stepAction))
    );
    this.subscriptions$.add(
      this.customWizardService.headerStepNavigation$.subscribe((headerStepAction: wizardHederNavAction) => this.handleHeaderStepNavigation(headerStepAction))
    );
  }

  getStepIndex(activeStepIndex: number) {
    this.activeStepIndex = activeStepIndex;
  }

  handleStepAction(stepAction: wizardStepAction) {
    if (!!stepAction) {
      if (stepAction.title == "Address Information") {
        this.handleFirstStepAction(stepAction.action.value);
      }
    }
  }

  handleHeaderStepNavigation(headerStepAction: wizardHederNavAction) {
    if (!!headerStepAction) {
      if (headerStepAction.stepIndex < this.activeStepIndex) {
        this.goToPreviousStep(headerStepAction.stepIndex)
      }
      if (headerStepAction.activeStepIndex === this.activeStepIndex) {
        this.submitForm()
      }
    }
  }

  handleFirstStepAction(actionValue: string): void {
    switch (actionValue) {
      case 'next':
        this.submitForm()
        break;
      case 'back':
        this.goToPreviousStep();
        break;
      default:
        return
    }
  }

  submitForm() {
    if (this.addressInformation.valid) {
      this.goToNextStep();
    }
    else {
      this.commonUtilsService.validateAllFormFields(this.addressInformation);
    }
  }

  goToNextStep() {
    this.activeStepIndex = this.activeStepIndex + 1;
    this.stepSuccessEvent.emit(this.activeStepIndex);
  }

  goToPreviousStep(index?: number) {
    this.activeStepIndex = index ?? this.activeStepIndex - 1;
    this.stepSuccessEvent.emit(this.activeStepIndex);
  }


  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
