import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MenuItem, MenuNavigation } from './menu.state';
import { MenuService } from './menu.service';

export const selectMenuStore = createFeatureSelector<MenuNavigation>('menuStore');

export const selectMenuList = createSelector(
  selectMenuStore,
  (state: MenuNavigation) => state.menuItems
);

export const selectMenuId = createSelector(
  selectMenuStore,
  (state: MenuNavigation) => state.selectedMenuId
);

export function selectsubMenuItems(menuService: MenuService) {
  return createSelector(
    selectMenuStore,
    (state: MenuNavigation) => {
      const selectedMenuId = state.selectedMenuId;
      const selectedPracticeId = state.selectedPracticeId;
      const selectedPatientId = state.selectedPatientId;
      return state.menuItems
        .filter(item => item.id === selectedMenuId)
        .map(submenu => submenu.children)[0]
        ?.map((item: MenuItem) => ({
          ...item,
          url: item.url
            ? item.url.replace(/:id/g, (match) => {
              switch (selectedMenuId) {
                case menuService.mainMenuItems.find(item => item.title === 'Practice')?.id:
                  return selectedPracticeId.toString();
                case menuService.mainMenuItems.find(item => item.title === 'Patient')?.id:
                  return selectedPatientId.toString();
                case menuService.mainMenuItems.find(item => item.title === 'Admin')?.id:
                  return selectedPracticeId.toString();
                default:
                  return match;
              }
            })
            : item.url,
        }));
    }
  );
}

export const selectSelectedPracticeId = createSelector(
  selectMenuStore,
  (state: MenuNavigation) => state.selectedPracticeId
);

export const selectSelectedPatintId = createSelector(
  selectMenuStore,
  (state: MenuNavigation) => state.selectedPatientId
);

export const selectSelectedSubMenuItem = createSelector(
  selectMenuStore,
  (state: MenuNavigation) => state.selectedSubMenuItem
);