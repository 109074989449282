import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IconModule } from '@progress/kendo-angular-icons';

import { PatientSearchEffect } from './store/effects/patient-search.effects';
import { PatientSearchSuggestionComponent } from './components/patient-search-suggestion/patient-search-suggestion.component';
import { PatientSearchFilterComponent } from './components/patient-search-filter/patient-search-filter.component';
import { patientSearchReducer } from './store/reducers/patient-search.reducer';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { SharedFeatureModule } from '../shared/shared-feature.module';
import { PatientSearchResultComponent } from './components/patient-search-result/patient-search-result.component';
import { PatientDashboardCoreModule } from './module/patient-dashboard.core.module';
import { RouterModule } from '@angular/router';
import { practiceApplicationFeaturesReducer } from '../practice/store/reducer/practice-application-feature.reducer';
import { PracticeApplicationFeatureEffect } from '../practice/store/effect/practice-application-feature.effect';
import { SharedFeatureKendoModule } from '../shared/shared-feature-kendo.module';
import { UserFeatureCoreModule } from '../user/user-feature-core.module';

@NgModule({
  declarations: [
    PatientSearchSuggestionComponent,
    PatientSearchFilterComponent,
    PatientSearchResultComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
    SharedFeatureModule,
    SharedFeatureKendoModule,
    NgClickOutsideDirective,
    PatientDashboardCoreModule,
    UserFeatureCoreModule,
    StoreModule.forFeature('patientSearchStore', patientSearchReducer),
    StoreModule.forFeature('practiceApplicationFeatureStore', practiceApplicationFeaturesReducer),
    EffectsModule.forFeature([PatientSearchEffect, PracticeApplicationFeatureEffect]),
    GridModule,
    PopupModule,
    ButtonsModule,
    IconModule,
    RouterModule
  ],
  providers: [
  ],
  exports: [
    PatientSearchSuggestionComponent, PatientSearchFilterComponent, PatientSearchResultComponent
  ]
})

export class PatientDashboardModule {

}