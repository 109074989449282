import { createReducer, on, createAction, props } from '@ngrx/store';

export interface DocumentState {
  documentsDownloadInfo?: DocumentDownloadInfo[];
  documentDownloadInfoStateEvent?: DocumentDownloadInfoState
}

export interface DocumentDownloadInfo {
  documentData: string;
  downloadedDocumentGuid: string;
}

export interface DocumentDownloadInfoState{
  errorMessage:string
}

const initialState: DocumentState = {};

export const getImage = createAction('[ImageDownload] Download Document', props<{ documentGuid: string }>());
export const documentDownload = createAction('[DocumentDownload] Download Document', props<{ documentGuid: string, fileName:string }>());
export const documentDownloaded = createAction('[DocumentDownload] Document Downloaded', props<{ documentData: string, downloadedDocumentGuid: string }>());
export const documentDownloadFailed = createAction('[DocumentDownload] Document Download Failed', props<{ erroeMessage: string }>());

export const getImageForUnAuthurizedUser = createAction('[ImageDownload For UnAuthurizedUser] Download Document', props<{ documentGuid: string }>());
export const documentDownloadCloseErrorAlert = createAction('[ImageDownload] document Download CloseError Alert');

export const documentDownloadReducer = createReducer(
  initialState,
  on(documentDownloaded, (state, { documentData, downloadedDocumentGuid }): DocumentState => ({
    ...state,
    documentsDownloadInfo: [
      ...(state?.documentsDownloadInfo || []),
      { documentData, downloadedDocumentGuid }
    ]
  })),
  on(documentDownloadFailed, (state, action): DocumentState => ({
    ...state,
    documentDownloadInfoStateEvent: {
      ...state.documentDownloadInfoStateEvent,
      errorMessage: action.erroeMessage
    }
})),
  on(documentDownloadCloseErrorAlert, (state, { }): DocumentState => ({
    ...state,
    documentDownloadInfoStateEvent: {
      ...state.documentDownloadInfoStateEvent,
      errorMessage: ''
    }
  })),
);

