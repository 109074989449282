import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '../components/authentication/login/login.component';
import { ChangepasswordComponent } from '../components/authentication/changepassword/changepassword.component';
import { ResetpasswordComponent } from '../components/authentication/resetpassword/resetpassword.component';
import { ForgotpasswordComponent } from '../components/authentication/forgotpassword/forgotpassword.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'change-password',
    component: ChangepasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetpasswordComponent,
  },
  {
    path: 'forgot-Password',
    component: ForgotpasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
