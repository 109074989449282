import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-inline-text-box',
  template: `
      <div class="position-relative inline-edit-right-space">
        <input 
          type="text" 
          class="form-control" 
          [required]="isRequired" 
          [pattern]="pattern" 
          [(ngModel)]="value"
          #inputField="ngModel" 
          [maxlength]="maxLength" 
          [attr.title]="title ? title : null"
        />

        <app-validation-error *ngIf="inputField?.invalid && (inputField?.touched || inputField?.dirty)"
            [errors]="inputField?.errors" [fieldName]="fieldName">
        </app-validation-error>

        <div class="position-absolute text-box-with-button" [ngClass]="{'pe-4': inputField.invalid, 'pe-2': !inputField.invalid}">
            <i class="fa fa-regular fa-save f-16 text-primary cursor-pointer"
                *ngIf="value && value.trim() !== '' && inputField?.valid" (click)="onSaveClick()"></i>
            <i class="feather icon-x f-16 ms-2 text-c-red cursor-pointer" (click)="onCancelClick()"></i>
        </div>
      </div>`
})
export class InlineTextBoxComponent {
  @Input() value!: string;
  @Input() fieldName!: string;
  @Input() isRequired!: boolean;
  @Input() pattern!: string;
  @Input() maxLength!: number;
  @Input() title?: string;

  @Output() saveClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();

  onSaveClick() {
    this.saveClick.emit(this.value);
  }

  onCancelClick(): void {
    this.cancelClick.emit();
  }
}
