import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { menuReducer } from './store/menu/menu.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MenuEffects } from './store/menu/menu.effect';
import { MenuService } from './store/menu/menu.service';
import { AuthService } from '../features/user/services/authentication/auth.service';
import { SharedFeatureKendoModule } from '../features/shared/shared-feature-kendo.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedFeatureKendoModule,
    StoreModule.forFeature('menuStore', menuReducer),
    EffectsModule.forFeature([MenuEffects]),
  ],
  providers: [
    MenuService,
    AuthService
  ]
})
export class ThemeModule { }
