import { Component, Input, ViewEncapsulation } from '@angular/core';
import { GradientConfig } from 'src/app/app-config';
import { Router } from '@angular/router';
import { MenuItem } from 'src/app/theme/store/menu/menu.state';
import { Store } from '@ngrx/store';
import { selectSelectedPatintId } from 'src/app/theme/store/menu/menu.selector';
import { Subscription } from 'rxjs';
import { selectMenuId, selectMenuList, selectSelectedSubMenuItem } from 'src/app/theme/store/menu/menu.selector';
import { updateSelectedSubMenuItem } from 'src/app/theme/store/menu/menu.actions';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NavItemComponent {

  @Input() item!: MenuItem;
  gradientConfig;
  themeLayout: string;
  practiceId!: '';
  currentPatientId: number = 0;
  menuList: MenuItem[] =[]; 
  selectedMenuId: number = 0;
  selectedSubMenuItem: MenuItem | undefined

  activeSubMenus: MenuItem[] | undefined; 
  isSubmenuActive: boolean = false;
  

  private subscriptions$ = new Subscription();
  selectMenuList$ = this.store.select(selectMenuList);
  selectMenuId$ = this.store.select(selectMenuId);
  selectedSubMenuItem$ = this.store.select(selectSelectedSubMenuItem);
  selectSelectedPatintId$ = this.store.select((selectSelectedPatintId));
  
  constructor(
    private router: Router,
    private store: Store,
  ) {
    this.gradientConfig = GradientConfig;
    this.themeLayout = GradientConfig.layout;
  }

  ngOnInit(): void{
    this.subscriptions$.add(this.selectSelectedPatintId$.subscribe((data: number) => this.bindselectSelectedPatintId(data)));
    this.subscriptions$.add(this.selectMenuList$.subscribe((data: MenuItem[]) => this.bindMenus(data)));
    this.subscriptions$.add(this.selectMenuId$.subscribe((data: number) => this.bindSelectedMenuId(data)));
    this.subscriptions$.add(this.selectedSubMenuItem$.subscribe((data: MenuItem) => this.bindSelectedSubMenuItem(data)));
  }
  bindMenus(data: MenuItem[]){
    if(data && data.length >0)
       this.menuList = data;
  }
  bindSelectedMenuId(data: number){
    this.selectedMenuId = data;
  }
  bindSelectedSubMenuItem(data: MenuItem){
    this.selectedSubMenuItem = data;
  }
  menuClick(menuItem: MenuItem) {
    const currentMenu = this.menuList.find(x=> x.id === this.selectedMenuId && x.title === 'Report' );
    if(currentMenu){
       const currentSubMenu = currentMenu.children?.find(x=> x.id === menuItem.id);
       this.activeSubMenus = currentSubMenu ? currentSubMenu.children : undefined;
    }
    if(!this.activeSubMenus ||  this.activeSubMenus.length === 0){
      let path = menuItem.url;
      if (path) {
        path = path.replace(':id', this.currentPatientId.toString())
      }
      this.router.navigate([path]);
    }
  }
  getRoutelink(item: any){
     const isReportMenu = this.menuList.some(x=> x.id === this.selectedMenuId && x.title === 'Report');
     if(!isReportMenu)
        return item.url
  }
  getModuleName(menuItem: MenuItem) {
    const url = menuItem.url?.split('/');
    return url?.[0];
  }

  subMenuClick(item: MenuItem){
    this.store.dispatch(updateSelectedSubMenuItem({menuItem: item}))
  }

  bindselectSelectedPatintId(data: number) {
    this.currentPatientId = data;
  }
}
