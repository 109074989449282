import { Pipe, PipeTransform } from '@angular/core';
import { DateUtilService } from '../services/date-util.service';

@Pipe({
  name: 'shortmonthformatteddatepipe'
})
export class ShortMonthFormattedDatePipe implements PipeTransform {

  constructor(private dateUtilService: DateUtilService){}
  
  transform(inputDate: string | null | undefined): string {
    return this.dateUtilService.getShortMonthFormattedDate(inputDate);
  }
}