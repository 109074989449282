import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthUser } from '../state/auth-user';

export const selectAuthUser = createFeatureSelector<AuthUser>('authenticationStore');

export const selectAuthStateEvents = createSelector(
    selectAuthUser,
    (state: AuthUser) => state.authStateEvents
);

export const selectIsValidUserEmail = createSelector(
    selectAuthUser,
    (state: AuthUser) => state.isValidUserEmail
);
export const selectValidUserInfo = createSelector(
    selectAuthUser,
    (state: AuthUser) => state.validUserInfo
);

export const selectLoggedOut = createSelector(
    selectAuthUser,
    (state: AuthUser) => state.valid === false 
);