import {
  CURRENT_PHYSICAL_HEALTH,
  ELIGIBILITY_VERIFICATION_TYPES,
  GENDER_TYPE, INSURANCE_COB, INSURANCE_GROUP_NETWORK_TYPES, INSURANCE_NETWORK_TYPES, INSURANCE_STATUSES, LANGUAGE_TYPE, LAST_DENTAL_APPOINTMENT, MARITIAL_TYPE,
  NETWORK_DISCOUNT_TYPE,
  PATIENT_PREGNANT,
  PHONE_NUMBERTYPES,
  RELATIONSHIP, YES_NO,
} from "../types/shared-types";

export const WEEK_LIST = [
  { day: "Sunday", value: "Sun", key: 1 },
  { day: "Monday", value: "Mon", key: 2 },
  { day: "Tuesday", value: "Tue", key: 3 },
  { day: "Wednesday", value: "Wed", key: 4 },
  { day: "Thursday", value: "Thu", key: 5 },
  { day: "Friday", value: "Fri", key: 6 },
  { day: "Saturday", value: "Sat", key: 7 },
]

export const WEEKS = {
  sunday: "Sunday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday"
}


export const NAME_PREFIX: string[] = [
  'Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.', 'Miss.', 'Sir.'
];

export const NAME_SUFFIX: string[] = [
  'Jr', 'Sr', 'III', 'IV', 'Esq'
];

export const ALERT_TYPES = {
  success: "success",
  error: 'error',
  warning: 'warning',
  info: 'info',
  question: 'question'
}

export const GENDER_TYPES: GENDER_TYPE[] = [
  { name: 'Female', value: 'Female' },
  { name: 'Male', value: 'Male' },
  { name: 'Other', value: 'Other' }
];

export const LANGUAGES: LANGUAGE_TYPE[] = [
  { name: 'English', value: 'English' },
  { name: 'Spanish', value: 'Spanish' },
];

export const MARITAL_STATUS: MARITIAL_TYPE[] = [
  { name: 'Single', value: 'Single' },
  { name: 'Married', value: 'Married' },
  { name: 'Divorced', value: 'Divorced' },
  { name: 'Separated', value: 'Separated' },
  { name: 'Widowed', value: 'Widowed' },
];

export const RELATIONSHIP_TYPE: RELATIONSHIP[] = [
  { name: 'Spouse', value: 'Spouse' },
  { name: 'Parent', value: 'Parent' },
  { name: 'Child', value: 'Child' },
  { name: 'Sibling', value: 'Sibling' },
  { name: 'Friend', value: 'Friend' },
  { name: 'Colleague', value: 'Colleague' },
  { name: 'Self', value: 'Self' },
];

export const YES_NO_OPTIONS: YES_NO[] = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export const PHONE_NUMBER_TYPE: PHONE_NUMBERTYPES[] = [
  { name: 'Home', value: 'Home' },
  { name: 'Work', value: 'Work' },
  { name: 'Mobile', value: 'Mobile' },
];

export const FOCUS_PLACE_HOLDER = {
  firstName: 'John',
  lastName: 'Smith',
  phoneNumber: '(999)999-9999',
  phoneNumberExt: '8888',
  ssn: '123-45-6789',
  treatmentName: 'Appliance Only',
  treatmentLength: '12',
  currency: '99999',
  treatmentType: 'Comprehensive',
  treatmentExpense: 'Panoramic',
  miscProduct: 'Tooth Brush',
  courtesy: 'Groupon',
}

export const INSURANCE_GROUP_NETWORK_TYPE: INSURANCE_GROUP_NETWORK_TYPES[] = [
  { name: 'PPO', value: 'PPO' },
  { name: 'Premier', value: 'Premier' },
  { name: 'DHMO', value: 'DHMO' },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const INSURANCE_NETWORK_TYPE: INSURANCE_NETWORK_TYPES[] = [
  { name: 'In Network', value: 'In Network' },
  { name: 'Out of Network', value: 'Out of Network' }
]

export const ELIGIBILITY_VERIFICATION_TYPE: ELIGIBILITY_VERIFICATION_TYPES[] = [
  { name: "Electronic Check", value: 1 },
  { name: "Automated Electronic Check", value: 2 },
  { name: "Website", value: 3 },
  { name: "Fax", value: 4 },
  { name: "Carrier Representative (Phone)", value: 5 },
  { name: "Other", value: 6 },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const INSURANCE_Cob: INSURANCE_COB[] = [
  { name: 'Standard', value: 'Standard' },
  { name: 'Non-Duplication', value: 'Non-Duplication' },
  { name: 'Birthday Rule', value: 'Birthday Rule' }
]

export const INSURANCE_STATUS: INSURANCE_STATUSES[] = [
  { name: 'Verification in Progress', value: 1 },
  { name: 'Verification Paused', value: 2 },
  { name: 'Verified', value: 3 },
  { name: 'Pre-Authorization Required', value: 4 },
  { name: 'Unable to Verify', value: 5 },
  { name: 'Not enough Information', value: 6 },
  { name: 'No Insurance', value: 7 },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const EDITOR_SIZE = {
  small: 'sm',
  medium: 'md',
  large: 'lg'
}
export const NETWORK_DISCOUNT_TYPES: NETWORK_DISCOUNT_TYPE[] = [
  { name: 'Treatment Option', value: 1 },
  { name: 'Additional Cost', value: 2 },
  { name: 'Misc Product', value: 3 },
  { name: 'Procedure Code', value: 4 },
].sort((a, b) => a.name > b.name ? 1 : -1)


export const LAST_DENTAL_APPOINTMENTS: LAST_DENTAL_APPOINTMENT[] = [
  { name: 'Less than 6 Months', value: 'Less than 6 Months' },
  { name: 'Between 6 Months and 12 Months', value: 'Between 6 Months and 12 Months' },
  { name: 'Between 12 Months and 18 Months', value: 'Between 12 Months and 18 Months' },
  { name: 'Between 18 Months and 24 Months', value: 'Between 18 Months and 24 Months' },
  { name: 'Longer than 24 Months', value: 'Longer than 24 Months' },
]

export const CURRENT_PHYSICAL_HEALTHS: CURRENT_PHYSICAL_HEALTH[] = [
  { name: 'Good', value: 'Good' },
  { name: 'Fair', value: 'Fair' },
  { name: 'Poor', value: 'Poor' },
]

export const PATIENT_PREGNANT_TYPES: PATIENT_PREGNANT[] = [
  { name: 'Unsure', value: 'Unsure' },
  { name: 'No', value: 'No' },
  { name: 'Yes', value: 'Yes' },
]
export const appointmentStatus = {
  active: "Active",
  checkIn: "CheckIn",
  seated: 'Seated',
  checkout: "Checkout",
  completed: "Completed",
  cancelled: "Cancelled",
  noshow: "Noshow",
  patientCanceled: "PatientCanceled",
  practiceCanceled: "PracticeCanceled"
}

export const appointmentStatusID = {
  active: 1,
  cancel: 2,
  checkIn: 3,
  checkout: 4,
  completed: 5,
  delete: 6,
  noShow: 7,
  patientCancel: 8,
  practiceCancel: 9,
  reSchedule: 10,
  seated: 11,

}

export const appointmentStatusesForRemoving: number[] = [
  appointmentStatusID.practiceCancel,
  appointmentStatusID.patientCancel,
  appointmentStatusID.cancel,
  appointmentStatusID.delete,
]

export const deviceWidth = {
  mobile: 480,
  mobilePortrait: 700,
  iPadPortrait: 820,
  tablet: 991,
  desktop: 1200,
  desktopMd: 1400,
  desktopXl: 1700,
  desktopXXl: 2000

}

export const amountFormat = {
  decimal: '1.2-2'
}

export const dateFormat = {
  dateWithTime: 'yyyy-MM-ddTHH:mm:ss',
  date: 'yyyy-MM-dd',
  datepickerDate: 'MM/DD/YYYY',
  monthDateFormat: "MM/dd/YYYY"
}

export const RELATIONSHIPS = {
  Spouse: 'Spouse',
  Parent: 'Parent',
  Child: 'Child',
  Sibling: 'Sibling',
  Friend: 'Friend',
  Colleague: 'Colleague',
  Self: 'Self',
  Other: 'Other',
  Dependant: 'Dependant'
}

export const RELATIONSHIP_TYPE_EXTENSION = [...RELATIONSHIP_TYPE,
{ name: "Other", value: "Other" } as RELATIONSHIP,
{ name: "Dependant", value: "Dependant" } as RELATIONSHIP
];

export const ASSOSIATE_PATIENT_RELATIONSHIP = [...RELATIONSHIP_TYPE_EXTENSION,
{ name: "Parent/Child", value: "Parent/Child" } as RELATIONSHIP,
];

export const PaymentFollowupReasonTypes =
{
  Decline: "Decline",
  Pastdue: "Past due",
  Other: "Other"
}

export const dayTypes = {
  day: 'Day',
  week: 'Week',
  month: 'Month'
}  

export const AppointmentFollowupReasonTypes =
{
  Decline: "No appointments",
  Other: "Other",
  Observation: "Observation"
}

export const SignAtHomeFollowupReasonTypes =
{
  Decline: "Pending sign at home",
  Other: "Other"
}

export const InsuranceVerificationFollowupReasonTypes =
{
  ContactingInsuranceCompany: "Contacting insurance company",
  AdditionalInformationNeeded: "Additional information needed",
  AdditionalDocumentNeeded: "Additional Document Needed",
  Reverify: "Reverify",
  Other: "Other"
}

export enum MaskingType  {
  Phone = 'phone',
  TaxId = 'taxId',
  SSN = 'ssn',
  DOB = 'dob'
}