import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { selectLocationSubscriptionStateEvent } from 'src/app/infrastructure/signalr/store/signalr.selector';
import { signalrLocationSubscriptionStatus } from 'src/app/infrastructure/core/constant/signalr.constant';

@Component({
  selector: 'app-location-subscription-status',
  template: `
          <span class="badge f-w-900"  
            [ngStyle]="{'color':this.subscriptionStatus.colorCode}" 
            [ngxTippy]="this.subscriptionStatus.title" 
            [tippyProps]="{ trigger: 'mouseenter'}"
          >
           <i class="fa fa-solid f-18 fa-caret-down"></i>
          </span>
  `,
})
export class LocationSubscriptionStatusComponent implements OnInit, OnDestroy {

  locationSubscriptionStateEvent$ = this.store.select(selectLocationSubscriptionStateEvent);
  private subscriptions$ = new Subscription();
  isSubscribed = false;

  subscriptionStatus = {
    status: "",
    colorCode: "",
    shortTerm: "",
    title: ""
  }

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.subscribeAndHandleUpdates(this.locationSubscriptionStateEvent$, this.locationSubscriptionStateEvent.bind(this));
  }

  subscribeAndHandleUpdates<T>(observable: Observable<T>, updateFunction: (data: T) => void) {
    this.subscriptions$.add(observable.subscribe(updateFunction));
  }

  locationSubscriptionStateEvent(data: any) {
    if (!data) return;
    this.subscriptionStatus = this.createConnectionStatusObject(data.isLocationSubscribed)
  }

  createConnectionStatusObject(status: string) {
    const subscriptionStatus = status ? signalrLocationSubscriptionStatus.subscribed : signalrLocationSubscriptionStatus.unsubscribed;

  return {
    status: status,
    shortTerm: subscriptionStatus.shortTerm,
    colorCode: subscriptionStatus.colorCode,
    title: `Location ${status ? 'subscribed' : 'unsubscribed'}`
  };
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}