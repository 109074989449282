import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-browser-download-button',
  template: `
    <div  (click)="onDownload()">
        <ng-container *ngIf="!isDownloading else spinner">
            <div
              class="btn-light-success wid-35 hei-35 d-flex justify-content-center align-items-center img-radius cursor-pointer">
                <i class="fa fa-solid fa-download f-16" title="download"></i>
            </div>
        </ng-container>
        <ng-template #spinner>
            <span class="bg-success wid-35 hei-35 d-flex justify-content-center align-items-center img-radius">
                <img src="assets/images/svg/spinner-loader.svg" width="20">
              </span>
        </ng-template>
    </div>
`,
})

export class BrowserDownloadButtonComponent {
  @Input() isDownloading = false;

  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>()

  onDownload(): void {
    if (this.isDownloading === false) {
      this.isDownloading = true;
      this.clickEvent.emit();

      setTimeout(() => {
        this.isDownloading = false;
      }, 2000);
    }
  }

}
