import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserFeatureModule } from 'src/app/features/user/user-feature.module';
import { signalrReducer } from './store/signalr.reducer';
import { SignalrEffects } from './store/signalr.effect';
import { SignalrService } from './services/signalr.service';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    UserFeatureModule,
    StoreModule.forFeature('signalR', signalrReducer),
    EffectsModule.forFeature([SignalrEffects]),
  ],
  providers: [
    SignalrService
  ],
})
export class SignalrModule { }
