import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-print-html-content',
  templateUrl: './print-html-content.component.html',
})
export class PrintHtmlContentComponent {

  @Input() htmlcontent: string = '';
  sanitizedContent!: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer) {
  } 

  ngOnInit(): void {
    this.sanitizeAndBindHtml();
  }

  sanitizeAndBindHtml() {
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlcontent);
  }

}
