import { createReducer, on } from '@ngrx/store';
import { PracticeList } from '../state/practice-info';
import {
    GetPracticesByEmailFailure,
    GetPracticesByEmailSuccess,
    GetPracticesSuccess,
    invokeGetPracticesByEmail,
} from '../action/practice-list.action';
import { HigherOrderReducerConfig, withStartLoading } from 'src/app/features/shared/store/core-api.reducer';

export const initialState: PracticeList = {
    practiceListModel: [],
    practiceListStateEvent: {
        errorMessage: "",
        showErrorAlert: false,
        loading: false,
        isFormSubmitted: false,
        showSuccessAlert: false
    }
}

export const basePracticeListReducer = createReducer(
    initialState,
    on(GetPracticesSuccess, (state, { practiceListDetails }) : PracticeList => ({
        ...state,
        practiceListModel: practiceListDetails
    })),
    on(GetPracticesByEmailSuccess, (state, { practiceListDetails }) : PracticeList => ({
        ...state,
        practiceListModel: practiceListDetails
    })),
    on(GetPracticesByEmailFailure, (state, { errorMessage }) : PracticeList => ({
        ...state,
       practiceListStateEvent:{
        errorMessage : errorMessage,
        showErrorAlert: true
       } 
    })),
)

const config: HigherOrderReducerConfig = {
    loadingProperty: 'practiceListStateEvent', // Adjust the property name according to the actual property used on each page
  };
export const PracticeListReducer = withStartLoading(basePracticeListReducer,config);