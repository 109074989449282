import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-no-record',
    template: `
            <div class="d-flex justify-content-center position-relative mb-4 flex-column align-items-center">
                <img src="assets/images/norecord.png" class="img-fluid" width="150">
                <p class="mb-0 fw-bold text-center">{{noRecordtext}}</p>
            </div>
          
        `,
})
export class NoRecordComponent {
 @Input() noRecordtext?='No Information Found'
}
