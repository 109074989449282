import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lookup-edit-list-skeleton',
  template: `
            <app-card [hidHeader]="true">
              <ng-container *ngFor="let count of repeatHtmlElement(fields)">
                  <div class="d-flex skeleton m-b-10 p-0 m-0">
                      <div class="square m-b-20 avator_skeleton"></div>
                      <div class="skeleton m-0 p-0">
                        <div class="line title_line_skeleton"></div>
                        <div class="line m-t-10 box_line_skeleton"></div>
                      </div>
                  </div>
              </ng-container>
            </app-card>
  `,
  styles: [`
  .title_line_skeleton{height:10px;width:70%}
  .box_line_skeleton{height:35px;width:100%}
  .avator_skeleton{height:50px;width:60px;margin-right:10px;border-radius:100%}
  .m-b-10{margin-bottom:10px !important}
  `]
})
export class LookupEditListSkeletonComponent {

  @Input() fields: number | undefined;

  constructor() {
    this.fields = 10;
  }

  repeatHtmlElement(count: number | undefined): Array<number> {
    return Array(count);
  }
}
