import { createFeatureSelector, createSelector } from "@ngrx/store"
import {  OnlineSchedulingDashboardState} from "./online-schedule.state"

const selectOnlineScheduleState= createFeatureSelector<OnlineSchedulingDashboardState>('OnlineSchedulingDashboardStore')

export const selectOnlineSchedulingPatientStateEvent = createSelector(
    selectOnlineScheduleState,
    state => state.onlineSchedulingDashboardStateEvent
)

export const selectShowOnlineSchedulePatientDashboard = createSelector(
    selectOnlineScheduleState,
    state => state.showOnlineScheduleDashboard
)

export const selectPracticeOnlineScheduleing = createSelector(
    selectOnlineScheduleState,
    state => state.onlineScheduleInformation
)

export const selectOnlinePracticeReferralConfiguration = createSelector(
    selectOnlineScheduleState,
    state => state.onlinePracticeReferralConfiguration
)

export const selectOnlinePatientReferrals = createSelector(
    selectOnlineScheduleState,
    state => state.onlinePatientReferrals
)

export const selectConvertPatientReferralInformation = createSelector(
    selectOnlineScheduleState,
    state => state.convertPatientInformation
)

export const selectOnlineSchedulingPatient = createSelector(
    selectOnlineScheduleState,
    state => state.OnlineSchedulingPatient
)
