export const TRANSACTION_TYPES = { DEBIT: 'Debit', CREDIT: 'Credit' };

export const TRANSACTION_CATEGORIES = { CHARGE: 'Charge', REFUND: 'Refund', ADJUSTMENT: 'Adjustment', PAYMENT: 'Payment' };

export const INVOICE_STATUS = { OPEN: 'Open', PAID: 'Paid'}

export const creditDebitPlan = {
    creditPaymentPlan: "CreditPaymentPlan",
    debitPaymentPlan: "DebitPaymentPlan"
}

export const lendingType = {
    principle: 'Principle',
    interest: 'Interest'
}

export const invoiceMessage = {
    achPendingPaymentWarning: 'An existing payment is in progress. Do you want to continue paying it again?'
}

export const Ach_invoice_Status = {
    pending: 'Pending',
    achInProgress: 'ACH In Progress'
}