import { createAction, props } from "@ngrx/store";
import { Habits } from "./habits.state";

export const invokeHabits = createAction(
    '[GetHabits] Invoke GetHabits start loading'
);

export const getHabitsSuccess = createAction(
    '[GetHabits] Success', props<{ habits: Habits[] }>()
);

export const getHabitsFailure = createAction(
    '[GetHabits] Failure', props<{ errorMessage: string }>()
);

export const invokeUpdateHabit = createAction(
    '[SaveHabits] invoke', props<{ habits: Habits, successMessage: string }>()
);

export const updateHabitSuccess = createAction(
    '[SaveHabit] Success', props<{ habits: Habits[] }>()
);

export const updateHabitFailure = createAction(
    '[SaveHabit] Failure', props<{ errorMessage: string }>()
)

export const startHabitsLoading = createAction(
    '[start] Habits loading'
);

export const habitsCloseSuccessAlert = createAction(
    '[Completed]  Habits Success Alert'
);

export const habitsCloseErrorAlert = createAction(
    '[Error] Habits Alert'
);

export const medicalConditionsFormSubmitted = createAction(
    '[MedicalConditions] form Submit Key'
);

export const resetHabitsState = createAction('[Habits] clear habits state');