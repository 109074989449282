import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';

import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { selectDocumentsDownloadInfo } from '../../store/document-download-store/document-download.selector';
import { ImageViewer } from '../../store/image-viewer-store/image-viewer-info';
import { DocumentDownloadInfo, getImage } from '../../store/document-download-store/document-download.reducer';
import { documentGuidNullValue } from '../../constant/document-message.constant';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent extends BaseDestroyComponent implements OnInit, OnChanges {
  constructor(private store: Store, private sanitizer: DomSanitizer) {
    super();
  }

  @Input() selectedImageGuid!: string;
  @Input() imageList!: ImageViewer[];
  @Input() hideCloseIcon: boolean = false;
  @Output() closeImageViewer = new EventEmitter<void>();

  @ViewChild('imageContainer') imageContainer!: ElementRef;
  @HostBinding('class.grabbing') isGrabbing = false;

  defaultImagePath = "assets/images/tooth-imaging/miscellaneous-12.png";
  viewerImageList!: ImageViewer[];
  currentImageIndex = 0;
  
  isDragging = false;
  isImageZoomed = false;
  
  zoomLevel = 1;
  maxZoomLevel = 3;
  minZoomLevel = 0.5;
  mouseX = 0;
  mouseY = 0;
  initialMouseX = 0;
  initialMouseY = 0;
  initialScrollLeft = 0;
  initialScrollTop = 0;
  
  documentDownloadInfoList?: DocumentDownloadInfo[];
  documentData$ = this.store.select(selectDocumentsDownloadInfo);
  
  ngOnInit() {
    this.viewerImageList = this.imageList;
    this.subscriptions$.add(this.documentData$.subscribe((data: DocumentDownloadInfo[] | undefined) => this.bindImage(data)));
    const index = this.viewerImageList.findIndex(x => x.documentGuid === this.selectedImageGuid)
    this.changeImage(index);
    this.handleDownload();
  }

  changeImage(index: number) {
    this.currentImageIndex = index;
    this.zoomLevel = 1
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedImageGuid'] && changes['selectedImageGuid'].firstChange === false) {
      const index = this.viewerImageList.findIndex(x => x.documentGuid === this.selectedImageGuid) ?? 0
      this.changeImage(index);
    }
    if (changes['imageList'] && changes['imageList'].firstChange === false) {
      if (this.viewerImageList[0].documentGuid !== this.imageList[0].documentGuid) {
        this.viewerImageList = this.imageList;
        this.handleDownload()
        const index = this.viewerImageList.findIndex(x => x.documentGuid === this.selectedImageGuid) ?? 0
        this.changeImage(index);
      }
    }
  }

  bindImage(data: DocumentDownloadInfo[] | undefined): void {
    if (data && this.viewerImageList) {
      this.documentDownloadInfoList = data;

      this.viewerImageList = this.viewerImageList.map((item) => {
        const documentDownloadInfo = this.findDocument(item.documentGuid);
        const imageSrc = documentDownloadInfo ? this.sanitizer.bypassSecurityTrustUrl(this.updateItemImage(documentDownloadInfo.documentData)) : undefined;
        return { ...item, imageSrc };
      });
    }
  }

  private findDocument(documentGuid: string) {
    return this.documentDownloadInfoList?.find(info => info.downloadedDocumentGuid === documentGuid);
  }

  handleDownload() {
    for (const item of this.viewerImageList) {
      if (item.documentGuid && item.documentGuid !== documentGuidNullValue) {
        const documentDownloadInfo = this.findDocument(item.documentGuid);
        documentDownloadInfo ?
          (item.imageSrc = this.safeUrl(this.updateItemImage(documentDownloadInfo.documentData)))
          : this.store.dispatch(getImage({ documentGuid: item.documentGuid }));
      }
      else
        item.imageSrc = this.defaultImagePath
    }
  }

  updateItemImage(documentData: string) {
    return documentData === 'imageNotFound' ? 'assets/images/maintenance/image-notfound.png' : documentData
  }

  private safeUrl(dataUrl: string): SafeUrl | undefined {
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }

  nextImage() {
    if (this.currentImageIndex < this.viewerImageList.length - 1) {
      this.currentImageIndex++;
      this.zoomLevel = 1
    }
  }

  previousImage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
      this.zoomLevel = 1
    }
  }

  zoomIn() {
    if (this.zoomLevel < this.maxZoomLevel) {
      this.zoomLevel += 0.1;
      this.isImageZoomed = true;
      if (this.zoomLevel > this.maxZoomLevel) {
        this.zoomLevel = this.maxZoomLevel;
      }
      this.adjustScrollPosition();
    }
  }

  zoomOut() {
    if (this.zoomLevel > this.minZoomLevel) {
      this.zoomLevel -= 0.1;
      if (this.zoomLevel < this.minZoomLevel) {
        this.zoomLevel = this.minZoomLevel;
      }
      this.adjustScrollPosition();
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.isImageZoomed) {
      return;
    }
    event.preventDefault();
    this.isGrabbing = true;
    this.isDragging = true;
    this.initialMouseX = event.clientX;
    this.initialMouseY = event.clientY;
    this.initialScrollLeft = this.imageContainer.nativeElement.scrollLeft;
    this.initialScrollTop = this.imageContainer.nativeElement.scrollTop;
  }

  @HostListener('mouseup')
  onMouseUp() {
    if (this.isDragging) {
      this.isGrabbing = false;
      this.isDragging = false;
    }
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();
    if (event.deltaY > 0) {
      this.zoomOut();
    } else if (event.deltaY < 0) {
      this.zoomIn();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    event.preventDefault();
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;

    if (this.isDragging) {
      const container = this.imageContainer.nativeElement;
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      const imageWidth = container.scrollWidth;
      const imageHeight = container.scrollHeight;
      const maxScrollLeft = imageWidth - containerWidth;
      const maxScrollTop = imageHeight - containerHeight;
      const deltaX = event.clientX - this.initialMouseX;
      const deltaY = event.clientY - this.initialMouseY;
      let scrollLeft = this.initialScrollLeft - deltaX;
      let scrollTop = this.initialScrollTop - deltaY;

      scrollLeft = Math.max(0, Math.min(scrollLeft, maxScrollLeft));
      scrollTop = Math.max(0, Math.min(scrollTop, maxScrollTop));

      container.scrollLeft = scrollLeft;
      container.scrollTop = scrollTop;
    }
  }

  private adjustScrollPosition() {
    const container = this.imageContainer.nativeElement;
    const containerHeight = container.clientHeight;
    const imageHeight = container.scrollHeight;
    const maxScrollTop = imageHeight - containerHeight;
    const currentScrollTop = container.scrollTop;

    if (currentScrollTop < 0) {
      container.scrollTop = 0;
    } else if (currentScrollTop > maxScrollTop) {
      container.scrollTop = maxScrollTop;
    }
  }

  closeViewer():void{
    this.closeImageViewer.emit();
  }
}