<div *ngIf="errors.length > 0">
    <h6>We're sorry for the inconvenience, but it seems that we're having trouble accessing your webcam. Unfortunately,
        we're unable to establish a connection at the moment. To resolve this issue, please reach out to your
        administrator, who will be able to assist you further. Thank you for your understanding.</h6>
    <div *ngFor="let error of errors">
        <div class="error-message">{{error | json}}</div>
    </div>
</div>
<div class="d-flex text-center justify-content-center" *ngIf="!errors || errors.length===0">
    <div style="width: 95%;" class="webcamUpload">
        <div *ngIf="!webcamImage">
            <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                (initError)="handleInitError($event)"></webcam>
            <div class="btn btn-light-primary mt-3" (click)="triggerSnapshot();" title="Take a picture">
                <i class="feather icon-camera me-2"></i>Take a picture
            </div>
        </div>
        <div class="snapshot" *ngIf="webcamImage">
            <img [src]="webcamImage.imageAsDataUrl" width="100%" />
            <div class="d-flex mt-3">
                <div class="btn btn-light-secondary" (click)="retake();" title="Retake">Retake
                </div>
                <div class="btn btn-light-primary ms-auto" (click)="handleCapturedImage();" title="Upload">Upload
                </div>
            </div>
        </div>
    </div>
</div>