<div class="dialer-container" [ngClass]="{'call-in-progress': bCallInProgress}" >
    <div class="phone-display px-3">
        <div class="form-group my-1">
            <input type="text" class="form-control text-center custom-input" [(ngModel)]="phoneNumber" #phoneNumberInput="ngModel" autofocus>
            <!-- <div *ngIf="phoneNumberInput.errors?.['required'] && (phoneNumberInput.dirty || phoneNumberInput.touched)"
                class="text-danger">
                <div>Phone number is required.</div>
            </div> -->
            <!-- <div *ngIf="phoneNumberInput.errors?.['pattern'] && (phoneNumberInput.dirty || phoneNumberInput.touched)"
                class="text-danger">
                <div>Invalid mobile number format.</div>
            </div> -->
        </div>
    </div>
    <div class="dialpad">
        <div class="number-btn rounded-circle" (click)="addNumber('1')">
            <div class="number">1</div><div class="alphabet"></div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('2')">
            <div class="number">2</div><div class="alphabet">ABC</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('3')">
            <div class="number">3</div><div class="alphabet">DEF</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('4')">
            <div class="number">4</div><div class="alphabet">GHI</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('5')">
            <div class="number">5</div><div class="alphabet">JKL</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('6')">
            <div class="number">6</div><div class="alphabet">MNO</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('7')">
            <div class="number">7</div><div class="alphabet">PQRS</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('8')">
            <div class="number">8</div><div class="alphabet">TUV</div>
        </div>
        <div class="number-btn rounded-circle" (click)="addNumber('9')">
            <div class="number">9</div><div class="alphabet">WXYZ</div>
        </div>
        <div class="number-btn rounded-circle pt-2" (click)="$event.stopPropagation()">*</div>
        <div class="number-btn rounded-circle" (click)="addNumber('0')">
            <div class="number">0</div><div class="alphabet">+</div>
        </div>
        <div class="number-btn rounded-circle" (click)="$event.stopPropagation()">#</div>
        <div class="hide-btn">
            <ng-content></ng-content>
        </div>
        <div [ngClass]="{'end-call': bCallInProgress}" class="call-btn rounded-circle" (click)="makeCall()"
            title="{{bCallInProgress ? 'End call' : 'Call'}}" [ngClass]=" (phoneNumberInput.invalid || phoneNumber.length < 10)? ' disabled-div' : ''">
            <i class="fa fa-solid fa-phone"> </i>
        </div>
        <div class="number-btn rounded-circle">
        <i class="fa  fa-solid fa-backspace clear-btn" (click)="clear()" title="Clear">
        </i>
        </div>
    </div>
</div>