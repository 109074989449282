import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { wizardSteps } from '../../../types/shared-types';
import { CustomWizardService } from '../custom-wizard.service';

@Component({
  selector: 'app-sample-wizard',
  templateUrl: './sample-wizard.component.html',
})
export class SampleWizardComponent implements OnInit, OnDestroy {

  @Output() closeOverlayEvent = new EventEmitter<any>();

  wizardSteps: wizardSteps[] = [
    {
      title: 'Personal Information',
      description: 'Description for first',
      actions: [
        {
          text: 'Next',
          value: 'next',
          color: 'btn-primary'
        }
      ]
    },
    {
      title: 'Address Information',
      description: 'Description for second',
      actions: [
        {
          text: 'Back',
          value: 'back',
          color: 'btn-secondary'
        },
        {
          text: 'Next',
          value: 'next',
          color: 'btn-primary'
        }

      ]
    },
    {
      title: 'Primary Contact',
      description: 'Description for third',
      actions: [
        {
          text: 'Back',
          value: 'back',
          color: 'btn-secondary'
        },
        {
          text: 'Finish',
          value: 'finish',
          color: 'btn-primary'
        }
      ]
    }
  ]

  constructor(
    private customWizardService: CustomWizardService
  ) {

  }

  ngOnInit(): void {

  }

  handleSuccessEvent(index: number) {
    this.customWizardService.setStepIndex(index);
  }

  closeOverlay() {
    this.closeOverlayEvent.emit();
  }

  ngOnDestroy(): void {
    this.customWizardService.setStepIndex(0);
  }
}
