import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'patientAge'
})
export class PatientAgePipe implements PipeTransform {
  transform(patientDob: string): string {
    const dob = new Date(patientDob);
    const currentDate = new Date();
    let yearsDiff = currentDate.getFullYear() - dob.getFullYear();
    let monthsDiff = currentDate.getMonth() - dob.getMonth();
    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff += 12;
    }
    return `${yearsDiff} Years ${monthsDiff} Months`;
  }
}