<div class="col-lg-12">
    <form [formGroup]="patientAppointmentForm" (ngSubmit)="saveAppointment()">
        <app-form-control-selectbox [items]="sheduleConfigurations" label='Position' placeholder="select position"
            bindValue="practiceChairId" bindLabel="chairName" formControlName="position" [required]="true"
            [clearable]="false" (changeEvent)="chairChanged($event)">
        </app-form-control-selectbox>
        <ng-container *ngIf="IsAppointmentExistInsameChair">
            <div class="validation_msg"><span>{{chairWarningMessage}}</span></div>
        </ng-container>
        <app-form-control-submit class="text-right" labelName="Save" [isSubmitted]="isFormSubmitted" [parentForm]="patientAppointmentForm">
        </app-form-control-submit>
    </form>
</div>