import {  Component } from '@angular/core';

@Component({
  selector: 'app-patient-search-suggession-loader',
  template: `<div class="search-suggestion">
              <div class="search-container">
                
                <app-patient-search-suggestion [isFromChat]="false"></app-patient-search-suggestion>
              </div>
            </div>`
})

export class PatientSearchSuggesstionLoaderComponent   {
}