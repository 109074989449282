import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { SharedModule } from 'src/app/theme/shared/shared.module';
import { logIn, resetErrorMessage, validateEmail } from '../../../store/actions/authentication.action';
import { selectAuthStateEvents, selectAuthUser, selectValidUserInfo } from '../../../store/selectors/authentication.selector';
import { AuthService } from "../../../services/authentication/auth.service"
import { UserStateEvents, ValidUserInfo } from '../../../store/state/auth-user';
import { PracticeCoreModule } from 'src/app/features/practice/module/practice-core.module';
import { BrowserLoggerService } from '../../../../../infrastructure/core/services/logger.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { userRoles } from '../../../constant/user-role-constant';
import { selectLocations } from 'src/app/features/practice/store/selector/practice-core-selector';
import { PracticeLocation } from 'src/app/features/practice/store/state/practice-location';
import { UserRoleService } from '../../../services/user-role.service';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  imports: [CommonModule, RouterModule, SharedModule, PracticeCoreModule],
})
export class LoginComponent implements OnInit, OnDestroy {
  returnUrl!: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private authService: AuthService,
    private loggerService: BrowserLoggerService,
    private offcanvasService: NgbOffcanvas,
    private userRoleService: UserRoleService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      locationId: ['']
    });
  }
  private subscriptions$ = new Subscription();

  authUser$ = this.store.select((selectAuthUser));
  validUserInfo$ = this.store.select((selectValidUserInfo));
  stateEvents$ = this.store.select((selectAuthStateEvents));
  selectlocations$: any = this.store.select((selectLocations));

  passwordInputType = "password";
  loginForm!: FormGroup;
  isFormSubmitted = false;
  errorMessage = '';
  classList!: { toggle: (arg0: string) => void };
  showLocation = false;
  isLoading = false;
  isSignInEnabled: boolean = true;
  isEmailValid = false;
  userRoleByEmail = "";
  practiceLocations:  PracticeLocation[] = [];


  ngOnInit() {
    this.showLocation = false;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    //todo: use app session storage service
    sessionStorage.clear();
    this.offcanvasService.dismiss();
    this.subscriptions$.add(this.authUser$.subscribe(e => { this.errorMessage = e?.authStateEvents?.errorMessage ?? "" }));
    if (this.authService.getToken()) {
      const permission = this.authService.getUserRolesAndPermission();
      this.userRoleService.loadLandingPageByUserRolePermission(this.authService.getUserRoleFromToken(), permission, this.returnUrl)
    }
    this.subscriptions$.add(this.stateEvents$.subscribe(async (data) => this.stateEventChanged(data)));
    this.subscriptions$.add(this.validUserInfo$.subscribe(async (data: ValidUserInfo) => this.bindValidUserInfo(data)));
    this.subscriptions$.add(this.selectlocations$.subscribe((data: PracticeLocation[]) => { this.bindPracticeLocations(data);  }));
  }

  bindPracticeLocations(data: PracticeLocation[]){
    this.practiceLocations = [...data].sort((a, b) =>
    a.practiceLocationName!.localeCompare(b.practiceLocationName!));
  }

  async bindValidUserInfo(data: ValidUserInfo) {
    if(data && data.isValid){
      this.userRoleByEmail = data.userRoleName;
      this.showLocation = !userRoles.noAccessUserRoles.some(x=> x === data.userRoleName);
      this.isSignInEnabled = false
      this.isEmailValid = true;
    }
    else 
     this.isEmailValid = false;
  }

  async stateEventChanged(data: UserStateEvents) {
    this.isFormSubmitted = data.isFormSubmitted ?? false;
    this.errorMessage = data.errorMessage ?? '';
    this.isLoading = data.isFormSubmitted!;
  }

  togglePasswordEvent() {
    this.passwordInputType === 'password' ? this.passwordInputType = 'text' : this.passwordInputType = 'password'
  }

  get formField() {
    return this.loginForm.controls;
  }

  login() {
    this.loggerService.info("Login attempt Username-" + this.loginForm.value.username);
    this.isFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.errorMessage = '';
    this.store.dispatch(logIn(
      {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
        loginLocationId: this.loginForm.value.locationId ? this.loginForm.value.locationId : this.practiceLocations[0] ? this.practiceLocations[0].practiceLocationId : "",
        returnUrl: this.returnUrl
      }
    ));
  }

  onUserNameBlur() {
    this.errorMessage = '';
    if (this.loginForm.get('username')?.valid) {
      this.isSignInEnabled =  true;
      this.store.dispatch(validateEmail({ username: this.loginForm.value.username }));
    }
    localStorage.clear();
  }

  loginEmailChange(){
    this.store.dispatch(resetErrorMessage());
    this.errorMessage = '';
    this.showLocation = false;
    this.authService.updateLocationIdToStorage(0);
  }

  clearErrorMessage(){
    this.store.dispatch(resetErrorMessage());
    this.errorMessage = '';
    this.showLocation = this.isEmailValid && this.userRoleByEmail  ? !userRoles.noAccessUserRoles.some(x=> x === this.userRoleByEmail) : this.isEmailValid;
  }

  ngOnDestroy(): void {
    this.errorMessage = '';
    this.subscriptions$.unsubscribe();
  }

  onKeyDown(event: KeyboardEvent) {
    if ((event.key === 'z' && event.ctrlKey)) {
      event.preventDefault();
    }
  }
}
