import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { WEEK_LIST } from "src/app/features/shared/constant/shared-constant";
import { DateUtilService } from "src/app/features/shared/services/date-util.service";


@Component({
    selector: 'app-monthly-calendar',
    styles: [
        `
    .schedule-background{ background:#FFFFFF;  border:1px solid #ccc; width:275px; border-radius:4px;}
    .schedule-header {border: 2px solid #EEEEEE;height:30px ;width:30px;background: #fff;border-radius: 8px; } 
    .schedule-body{border: 2px solid #EEEEEE; background: #fff;border-radius: 8px;padding:5px 5px;cursor:pointer; }
    .schedule-months{color:#1D1929;}
    .schedule-date{color:#707070;}
    .schedule-header-border{border-bottom:3px dotted #EEEEEE;}
    .calander-day{border: 2px solid transparent; border-radius:5px;position: relative;}
    .calander-day:hover{cursor:pointer;}
    .calander-day.not-current-month{color:#BBBABF;}
    .calander-day.selected{font-weight:bold;color:#000;}
    .calander-day.selected:after{content:"";
    width:5px;
    height:5px;
    border-radius:50%;
    background: #000;
    position:absolute;
    bottom:0 }
    .calander-day.current-month{color:#707070;}
    .calander-day.today{color:#0667BA; border-color:#0667BA;}
    .month-details{width:200px;}
    .calander-day.today-landingPage{border-color:#252525;}
    .height_222{height: 222px;}
    .calander-day.past-date, .calander-day.today-date, .calander-day.not-current-month { color: #ccc; pointer-events: none; }
`   
    ],

    template: `
    <div class="mt-2 schedule-background">
        <div>
            <div class="d-flex px-2 gap-2 pt-2 align-items-center justify-content-center hide-online-schedule">
                <select class="schedule-body w-100" (change)="changeMonth($event)">
                    <option  *ngFor="let month of months; index as i" [value]="i" [selected]="date!.getMonth() === i">
                        {{ month }}
                    </option>
                </select>
                <select class="schedule-body" (change)="changeYear($event)">
                    <option *ngFor="let year of years" [value]="year" [selected]="date!.getFullYear() === year">
                        {{ year }}
                    </option>
                </select>
            </div>

            <div class="d-flex gap-1 align-items-center schedule-header-border p-2">
            <button class="schedule-header" (click)="previousYear()" *ngIf="!isWeeklySelected" [disabled] ="disablePastDayNavigation && isPastYear"
                ngxTippy="Previous Year" [tippyProps]="{ trigger: 'mouseenter'}"><span class="f-12"><i class="fa fa-angle-double-left"></i></span></button>
            <button class="schedule-header" (click)="previousMonth()" [disabled] ="disablePastDayNavigation && isPastMonth"
                ngxTippy="Previous Month" [tippyProps]="{ trigger: 'mouseenter'}"><span class="f-12"><i class="fa fa-angle-left"></i></span></button>
            <button class="schedule-header" (click)="previousWeek()"  *ngIf="isWeeklySelected" [disabled] ="disablePastDayNavigation && isPastWeek"
                ngxTippy="Previous Week" [tippyProps]="{ trigger: 'mouseenter'}"><span class="f-12"><i class="fa fa-angle-left"></i></span></button>
            <div  class="d-flex justify-content-center month-details">
                <h6 class="f-14 schedule-months mb-0">{{getMonth()}}</h6>
                <h6 class="f-14 schedule-date mb-0 p-l-5">{{getYear()}}</h6>
                </div>
                <button class="schedule-header" (click)="nextWeek()" ngxTippy="Next Week" *ngIf="isWeeklySelected" [tippyProps]="{ trigger: 'mouseenter'}"><i class="fa fa-angle-right"></i></button>
                <button class="schedule-header" (click)="nextMonth()" ngxTippy="Next Month"  [tippyProps]="{ trigger: 'mouseenter'}"><i class="fa fa-angle-right"></i></button>
                <button class="schedule-header" (click)="nextYear()" *ngIf="!isWeeklySelected" ngxTippy="Next Year"  [tippyProps]="{ trigger: 'mouseenter'}"><i class="fa fa-angle-double-right"></i></button>
            </div>
        </div>
        <div *ngIf="isLoader" class="height_222">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
                <i class="fa fa-spinner fa-spin f-28 text-primary"></i>
            </div>
        </div>
        <div *ngIf="!isLoader">
        <div class="p-3 pt-1">
    <div class="d-flex gap-2 justify-content-between fw-bold">
        <div class="d-flex wid-30 hei-30 align-items-center justify-content-center" *ngFor="let week of dayNames">
            {{ week.value }}
        </div>
    </div>
    <div class="schedule-date d-flex flex-column gap-2">
        <div *ngFor="let week of days" class="d-flex gap-2 justify-content-between">
            <div *ngFor="let day of week" [ngStyle]="{ 'border-color': day.backgroundColor, color: day.backgroundColor }"
                class="d-flex wid-25 hei-28 align-items-center justify-content-center calander-day f-12"
                [class.current-month]="day.inCurrentMonth"
                [class.selected]="!isWeeklySelected && selectedDate?.getTime() === day.date.getTime()"
                [class.today]="!isWeeklySelected && isToday(day)"
                [class.today-landingPage]="isWeeklySelected && isToday(day)"
                [class.not-current-month]="!day.inCurrentMonth" 
                [class.past-date]="disablePastDates && isPastToday(day.date)"
                [class.today-date]="disableToday && isToday(day)"(click)="selectDate(day)">
                {{ day.date.getDate() }}
            </div>
        </div>
    </div>
</div>

        </div>
    </div>
    `
})
export class MonthlyCalendarComponent implements OnInit {
    date: Date | null = null;
    selectedDate: Date | null = null;
    years: number[] = [];
    days: Day[][] = [];
    isPastYear: boolean = false;
    isPastMonth: boolean = false;
    isPastWeek: boolean = false;

    @Input() monthlyTemplate: { date: Date, backgroundColor: string, foregroundColor: string }[] = [];
    @Input() isLoader: boolean = false;
    @Input() schedulerSelected?: Date = undefined;
    @Input() isWeeklySelected :boolean = false;
    @Input() disablePastDates: boolean = false;
    @Input() disableToday: boolean = false;
    @Input() disablePastDayNavigation: boolean = false;

    @Output() dateSelected = new EventEmitter<Date>();
    @Output() monthChanged = new EventEmitter<Date>();
    @Output() weekChanged = new EventEmitter<Date>();

    constructor(private dateUtilService: DateUtilService) {
        this.date = this.getDefaultCurrentDateForMonthlyCalendar();
    }

    ngOnInit(): void {
        if(!this.schedulerSelected)
            this.date = this.getDefaultCurrentDateForMonthlyCalendar();
        const YEARS_RANGE = 10;
        this.updateDays();
        this.disablePastNavigation();
        const currentYear = new Date().getFullYear();
        this.years = Array.from({ length: YEARS_RANGE }, (_, i) => currentYear - 3 + i);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['schedulerSelected']) {
            this.selectedDate = changes['schedulerSelected'].currentValue;
            this.date = changes['schedulerSelected'].currentValue;
        }
        this.updateDays();
        this.disablePastNavigation();
        
    }

    private getDefaultCurrentDateForMonthlyCalendar(): Date {
        return this.disableToday? this.getTomorrowsDate() : this.getTodaysDate();
    }

    daysArray(): Day[][] {
        if (!this.date) return [];
    
        const firstDay = this.dateUtilService.getFirstDayOfTheMonth(this.date);
        const daysInMonth = this.dateUtilService.getDaysInMonth(this.date);
        const daysInLastMonth = this.dateUtilService.getDaysInLastMonth(this.date);
    
        const days: Day[] = Array.from({ length: daysInMonth }, (_, i) => ({
            date: new Date(this.date!.getFullYear(), this.date!.getMonth(), i + 1),
            inCurrentMonth: true,
            backgroundColor: '',
            foregroundColor: ''
        }));
    
        for (let i = 0; i < firstDay; i++) {
            days.unshift({
                date: new Date(this.date!.getFullYear(), this.date!.getMonth() - 1, daysInLastMonth - i),
                inCurrentMonth: false,
                backgroundColor: '',
                foregroundColor: ''
            });
        }
    
        while (days.length < 42) {
            let nextDay = days.filter(x => x.date.getMonth() === (this.date!.getMonth() + 1) % 12).length + 1;
            days.push({
                date: new Date(this.date.getFullYear(), this.date.getMonth() + 1, nextDay),
                inCurrentMonth: false,
                backgroundColor: '',
                foregroundColor: ''
            });
        }
    
        for (let i = 0; i < days.length; i++) {
            const template = this.monthlyTemplate.find(t => t.date.getTime() === days[i].date.getTime());
            if (template) {
                days[i].backgroundColor = template.backgroundColor;
                days[i].foregroundColor = template.foregroundColor;
            }
        }
    
        // Group days into weeks
        const weeks: Day[][] = [];
        for (let i = 0; i < days.length; i += 7) {
            weeks.push(days.slice(i, i + 7));
        }
    
        return weeks;
    }

    selectDate(day: { date: Date, inCurrentMonth: boolean }) {
        if (day.inCurrentMonth &&
            (!this.disablePastDates || !this.isPastToday(day.date)) &&
            (!this.disableToday || !this.isToday(day))) {
            this.selectedDate = day.date;
            this.date = this.selectedDate;
            this.dateSelected.emit(day.date);
        }
    }

    getMonth(): string | null {
        return this.date ? this.date.toLocaleString('default', { month: 'long' }) : null;
    }

    getYear(): number | null {
        return this.date ? this.date.getFullYear() : null;
    }

    nextMonth() {
        if (!this.date) return;
        const NEXT_MONTH = 1;
        this.date = new Date(this.date.getFullYear(), this.date.getMonth() + NEXT_MONTH, 1);
        this.monthChanged.emit(this.date);
        this.updateDays();
    }

    previousMonth() {
        if (!this.date) return;
        let previousMonthBeginningDate = this.getPreviousMonthBeginningDate(this.date);
        if(this.disablePastDayNavigation && previousMonthBeginningDate < this.getTodaysDate()){
            previousMonthBeginningDate = this.getDefaultCurrentDateForMonthlyCalendar();
        }
        this.date = previousMonthBeginningDate;
        this.monthChanged.emit(this.date);
        this.updateDays();
        this.disablePastNavigation();
    }

    private getTodaysDate(): Date {
        const today = this.dateUtilService.GetApplicationCurrentDateTime();
        this.dateUtilService.setStartHoursOfTheDay(today);
        return today;
    }

    private getTomorrowsDate(): Date {
        const tomorrow = this.dateUtilService.addDaysWithDateAsDate(this.getTodaysDate(),1);
        this.dateUtilService.setStartHoursOfTheDay(tomorrow);
        return tomorrow;
    }

    private getPreviousMonthBeginningDate(date : Date): Date {
        const PREVIOUS_MONTH = -1;
        return new Date(date.getFullYear(), date.getMonth() + PREVIOUS_MONTH, 1)
    }

    previousYear() {
        if (!this.date) return;
        this.date = new Date(this.date.getFullYear() - 1, this.date.getMonth(), 1);
        this.monthChanged.emit(this.date);
        this.updateDays();
        this.disablePastNavigation();
    }

    nextYear() {
        if (!this.date) return;
        this.date = new Date(this.date.getFullYear() + 1, this.date.getMonth(), 1);
        this.monthChanged.emit(this.date);
        this.updateDays();
    }

    updateDays() {
        this.days = this.daysArray();
    }

    changeMonth(event: any) {
        if (!this.date) return;
        this.date = new Date(this.date.getFullYear(), +event.target.value, 1);
        this.monthChanged.emit(this.date);
        this.updateDays();
        this.disablePastNavigation();
    }

    changeYear(event: any) {
        if (!this.date) return;
        const year = event.target.value;
        this.date = new Date(+year, this.date.getMonth(), 1);
        this.monthChanged.emit(this.date);
        this.updateDays();
        this.disablePastNavigation();
    }

    nextWeek(){
        if(this.date){
            this.date = this.dateUtilService.addWeeksWithDate(this.date,1);
            this.weekChanged.emit(this.date);
        }
        this.updateDays();
    }

    previousWeek(){
        if(this.date){
            this.date = this.getPreviousWeekBeginningDate(this.date);
            this.weekChanged.emit(this.date);
            this.updateDays();
            this.disablePastNavigation();
        }
    }

    isToday(day: { date: Date }) {
        const today = this.getTodaysDate();
        return day.date.getDate() === today.getDate() &&
            day.date.getMonth() === today.getMonth() &&
            day.date.getFullYear() === today.getFullYear();
    }

    isPastToday(date: Date): boolean {
        const today = this.getTodaysDate();
        this.dateUtilService.setStartHoursOfTheDay(today);
        return date < today;
    }

    isPastBeginningOfTheCurrentMonth(previousMonthBeginningDate: Date): boolean {
        const currentMonthBeginningDate = this.dateUtilService.GetStartDateOfTheMonthAsDate(this.getTodaysDate());
        this.dateUtilService.setStartHoursOfTheDay(currentMonthBeginningDate);
        return previousMonthBeginningDate < currentMonthBeginningDate;
    }

    isPastBeginningOfTheCurrentYear(previousYearBeginningDate: Date): boolean {
        const currentYearBeginningDate = this.dateUtilService.GetStartDateOfTheYearAsDate(this.getTodaysDate());
        this.dateUtilService.setStartHoursOfTheDay(currentYearBeginningDate);
        return previousYearBeginningDate < currentYearBeginningDate;
    }

    getPreviousWeekBeginningDate(currentDate: Date): Date {
        const previousWeekBeginningDate = new Date(currentDate);
        previousWeekBeginningDate.setDate(currentDate.getDate() - 7);
        const today = this.getTodaysDate();
        if(this.disablePastDates && previousWeekBeginningDate <= today)
            return this.getDefaultCurrentDateForMonthlyCalendar();
        else
            return previousWeekBeginningDate;
    }

    getPreviousWeekBeginningDateForDisable(currentDate: Date): Date {
        const previousWeekBeginningDate = new Date(currentDate);
        previousWeekBeginningDate.setDate(currentDate.getDate() - 7);
        const today = this.getTodaysDate();
        const dayDifference = this.dateUtilService.getTimeDifferenceInDays(today,currentDate);
        if(this.isCurrentDateInNextMonth(currentDate) &&  dayDifference > 0 && dayDifference <= 7)
            return this.getDefaultCurrentDateForMonthlyCalendar();
        else
            return previousWeekBeginningDate;
    }

    private isCurrentDateInNextMonth(currentDate: Date): boolean {
        return currentDate.getMonth() === this.getTodaysDate().getMonth() + 1;
    }

    disablePastNavigation() {
        if(!this.disablePastDayNavigation) return;
        const currentDate = this.date!;
        const previousMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        const previousYearDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
        const previousWeekDate = this.getPreviousWeekBeginningDateForDisable(currentDate);

        this.isPastYear = this.isPastBeginningOfTheCurrentYear(previousYearDate);
        this.isPastWeek = this.isPastToday(previousWeekDate);
        this.isPastMonth = this.isPastBeginningOfTheCurrentMonth(previousMonthStartDate);
      }


    months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('default', { month: 'long' }));

    get monthName() {
        if (!this.date) return;
        return this.date.toLocaleString('default', { month: 'long' });
    }

    get dayNames() {
        return WEEK_LIST;
    }

}

interface Day {
    date: Date;
    inCurrentMonth: boolean;
    backgroundColor: string,
    foregroundColor: string
}