  <div class="navbar-content shadow">
    <ng-scrollbar *ngIf="this.gradientConfig['layout'] === 'vertical'" (clickOutside)="navMob()" [excludeBeforeClick]="true"
  [exclude]="'#mobile-collapse'" class="position-relative" id="nav-ps-gradient-able" class="logout-scroll" style="height: calc(100vh - 110px);">
    <ul class="nav pcoded-inner-navbar pcoder-inner-navbar-customize " (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of childernMenu">
        <app-nav-item class="cursor-pointer" *ngIf="item.type === 'item'" [item]="item"></app-nav-item>
      </ng-container>
      
    </ul>
  </ng-scrollbar>
  </div>
