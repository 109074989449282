import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaskingType } from '../../../constant/shared-constant';
import { CustomValidatorService } from '../../../utils/custom-validator.service';

@Component({
  selector: 'app-first-child',
  templateUrl: './first-child.component.html',
})
export class FirstChildComponent {

  aditionalInformation: FormGroup;
  maskingType = MaskingType;

  constructor(
    private fb: FormBuilder,
  ) {

    this.aditionalInformation = this.fb.group({
      preferredName: ['', [Validators.required, CustomValidatorService.generalNameValidator()]],
      secondaryEmail: ['', [Validators.required, CustomValidatorService.emailValidator()]],
      secondaryPhone: ['', CustomValidatorService.phoneValidator(true)]
    })

  }

}