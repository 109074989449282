import { createAction, createReducer, on, props } from '@ngrx/store';
import { CoreState, CountryState, TimeZone } from './core-info';

export const invokeGetStates = createAction('[GetStates API] Invoke GetStates API');
export const getStatesSuccess = createAction('[GetStates API] GetStates API Success', props<{ states: CountryState[] }>());
export const getStatesFailure = createAction('[GetStates API] GetStates API Failure', props<{ errorMessage: string }>());

export const invokeGetTimeZones = createAction('[GetTimeZones API] Invoke GetTimeZones API');
export const getTimeZonesSuccess = createAction('[GetTimeZones API] GetTimeZones API Success', props<{ timeZones: TimeZone[] }>());
export const getTimeZonesFailure = createAction('[GetTimeZones API] GetTimeZones API Failure', props<{ errorMessage: string }>());

export const initialState: Readonly<CoreState> = {};

export const coreLookupReducer = createReducer(
  initialState,
  on(getStatesSuccess, (state, { states }): CoreState => ({
    ...state,
    usStateInfo: { ...state.usStateInfo, usStates: states }
  })),
  on(getStatesFailure, (state, { errorMessage }): CoreState => ({
    ...state,
    usStateInfo: {
      ...state.usStateInfo,
      coreStateEvent: {
        ...state.usStateInfo?.coreStateEvent,
        errorMessage: "Failed to load the US states" + (errorMessage ? " - " + errorMessage : '')
      }
    }
  })),
  on(getTimeZonesSuccess, (state, { timeZones }): CoreState => ({
    ...state,
    timeZoneInfo: { ...state.timeZoneInfo, timeZones: timeZones }
  })),
  on(getTimeZonesFailure, (state, { errorMessage }): CoreState => ({
    ...state,
    timeZoneInfo: {
      ...state.timeZoneInfo,
      coreStateEvent: {
        ...state.timeZoneInfo?.coreStateEvent,
        errorMessage: "Failed to load the time zones" + (errorMessage ? " - " + errorMessage : '')
      }
    }
  })),
);