import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const names = value.split(',');
      const initials = names.map(name => name.trim().charAt(0).toUpperCase()).join('');
      return initials;
    }
    return '';
  }
}
