// Messages with placeholders for the positive keywords
export const patientCommunicationConstants = {
    patientCommunicationSavedSuccess: `Your patient notification is scheduled and will be sent shortly.`,
    bulkPatientCommunicationSavedSuccess: `Your bulk patient notification is scheduled and will be sent shortly.`,
    patientCommunicationNoteSavedSuccess: `Patient note saved successfully.`,
    patientImmediateCommunicationSuccess: `Message sent successfully via {{channels}}.`,
    patientImmediateCommunicationFailed: `Message sending via {{channels}} failed.`,
    communicationCategoryIsRequired: 'Communication category is required.',
    communicationModeIsRequired: `Communication mode is required.`,
    immdiateCommunicationException: `There could be an error while sending the communication. Please try again.`,
    communicationChannelIsRequired: 'Please select at least one channel.',
    practiceLocationCommunicationPhoneNumberIsRequired: "Sorry, we couldn't find communication phone number for the selected practice location. Please contact your administrator for assistance.",
    patientFollowUpCommunicationSavedSuccess: `Follow-up details saved successfully.`,
    communicationSubCategoryIsRequired: 'Communication sub category is required.',
    communicationLocationIsRequired: 'Please choose a location before initiating the call.',
    communicationMaxPopup : "You've reached the maximum number of popup alert notifications allowed.",
    communicationInprogressCall: "You're already engaged in a call.",
};

export enum communicationCategoryName {
   Notification = "Notification",
   Notes = "Notes",
   FollowUp = "Follow-Up",
   IncompleteForms = "Incomplete Forms",
   WelcomeEmail = "On Boarding",
   Other = "Other"
}
 
export const communicationCategories = [
   { name: "Follow-Up", value: 6 }
]

export const communicationTypes = [
   { name: "Notes", value: 7 }
]
 
export const communicationSubCategories = [
   { name: "Appointment Followup", value: 4 }
]

 export enum communicationTypeName {
    Email = "Email",
    Text = "Text",
    PushNotification = "Push Notification",
    Multi = 'Multi',
    Other = 'Other',
    Phone = "Phone",
    Notes = "Notes",
    Call = "Call"
 }


 export enum channelType {
    Email = "Email",
    Sms = "SMS",
    PushNotification = "Push Notification",
    Phone = "Phone",
    Notes = "Notes"
 }

 export const communicationTypeImage = [
   {type: communicationTypeName.Email, imageSrc: 'assets/images/svg/email@.svg'} ,
   {type: communicationTypeName.Text, imageSrc: 'assets/images/svg/text-msg.svg'} ,
   {type: communicationTypeName.PushNotification, imageSrc: 'assets/images/svg/phone-mode.svg'}, 
   {type: communicationTypeName.Multi, imageSrc: 'assets/images/svg/multi-mode.svg'} ,
   {type: communicationTypeName.Other, imageSrc: 'assets/images/svg/other-mode.svg'},
   {type: communicationTypeName.Notes, imageSrc: 'assets/images/svg/notes-mode.svg'},
   {type: communicationTypeName.Call, imageSrc: 'assets/images/svg/call-mode.svg'}
];
export const defaultImagesrc = "assets/images/svg/text-msg.svg";
export enum communicationStatusMessage {
    Success = "Success",
    PartialSuccess = "PartialSuccess",
    Sending = "Sending",
    Failed = "Failed",
}

export enum FollowUpSubCategoryName {
    PastDueFollowup = "Past Due Followup",
    InsuranceFollowup = "Insurance Followup",
    AppointmentFollowup = "Appointment Followup",
    SignAtHomeFollowup ="Sign At Home Followup",
    InsuranceVerification = "Insurance Verification"
 }

export enum FollowupStatusName {
   DoNotPursue = "Do Not Pursue",
   DeclineTreatment = "Decline Treatment",
   Paused = "Paused",
   NotifyOverduetoPracticeAdmin= "Notify Overdue to Practice Admin"
}

export const phoneCallLogConstants = {
   patientCommunicationOtherCategoryId: 13,
   patientCommunicationPhoneTypeId:6,
   systemUserName: 'System, User',
   systemUserInitial: 'SU'
}
 
export interface PatientCommunication {
   communicationType?: string,
   patientFirstName?: string,
   patientLastName?: string,
   patientName?: string,
   patientId?: number,
   patientPrimaryPhone?: string,
   callId?: string
}

export const followupFilterDetails = [
   { followType: 'Notes', controlName: 'notes', displayName: 'Notes', isSubCategory: false },
   { followType: 'Notification', controlName: 'notification', displayName: 'Notification', isSubCategory: false },
   { followType: 'Past Due Followup', controlName: 'pastDueFollowup', displayName: 'Past Due Follow-up', isSubCategory: true },
   { followType: 'Appointment Followup', controlName: 'appointmentFollowup', displayName: 'Appointment Follow-up', isSubCategory: true },
   { followType: 'Sign At Home Followup', controlName: 'signAtHomeFollowup', displayName: 'Sign At Home Follow-up', isSubCategory: true },
   { followType: 'Insurance Followup', controlName: 'insuranceFollowup', displayName: 'Insurance Follow-up', isSubCategory: true },
   { followType: 'Insurance Verification', controlName: 'insuranceVerificationFollowup', displayName: 'Insurance Verification', isSubCategory: true }
]

export const insuranceVerificationEmailSubject = "Insurance Verification" 
export const insuranceVerificationHtmlContentToRcm = `<div style="width:550px; margin: 0 auto; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #222; background-color:#f1f1f1; border:0.5px solid #0667BA; ">
<div style="padding: 20px 15px; color:#0667BA; padding-bottom: 0; text-align: center;">
   <h1 style="font-size: 30px; margin: 0; padding: 0; text-align: center;margin-bottom:15px">Insurance Verification</h1>
   <img style="width: 30%;" src="https://orthosoftweb.azurewebsites.net/assets/email-templates/opms-appointment-reminder.png">
</div>
<div style="padding: 20px 15px;background-color: #0667BA;color:#fff">
   <p style="font-size: 20px; margin: 0; padding: 0; margin-bottom: 10px; font-weight: bold;  ">Hello RCM Administrator,</p>
   <p style="font-size: 18px; margin: 0; padding: 0; margin-bottom: 10px;"> This is to inform you that the insurance verification for the following patient is ready for processing:</p>
   <div style="background:#fff;color:#FFB64D;border-radius:10px;padding:15px;margin-bottom:15px">
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Patient Name: <b style="margin-left:3px;">{{PatientLastName}}, {{PatientFirstName}}</b></p>
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Insurance Company Name: <b style="margin-left:3px;">{{InsuranceCompanyName}}</b></p>
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Practice Name: <b style="margin-left:3px;">{{PracticeName}}</b></p>
   </div>
   <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left;">If you have any questions or need further assistance, please feel free to contact us.</p>
   <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left;font-weight:bold;font-size:16px">Practice Information:</p>
   <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Practice Name: <b style="margin-left:3px;"><span> {{PracticeName}} </span></b></p>
   <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Contact Number: <b style="margin-left:3px;"><span> {{PracticeMainPhoneNumber}} </span></b></p>
   <div style="text-align: center; padding-top: 10px; border-top:1px solid #fff">
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: center;"><b>Thank you for your attention.</b></p>
      <div style="clear:both"></div>
      <div style="width:100%;float:right">
         <p style="margin: 0; padding: 0; margin-bottom: 5px; text-align: right;">Best regards,</p>
         <p style="margin: 0; padding: 0;text-align: right;"><b>{{PracticeName}}</b></p>
      </div>
      <div style="clear:both"></div>
   </div>
</div>
</div>`

export const insuranceVerificationHtmlContentToPractice = `<div style="width:550px; margin: 0 auto; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #222; background-color:#f1f1f1; border:0.5px solid #0667BA; ">
<div style="padding: 20px 15px; color:#0667BA; padding-bottom: 0; text-align: center;">
   <h1 style="font-size: 30px; margin: 0; padding: 0; text-align: center;margin-bottom:15px">Insurance Verification</h1>
   <img style="width: 30%;" src="https://orthosoftweb.azurewebsites.net/assets/email-templates/opms-appointment-reminder.png">
</div>
<div style="padding: 20px 15px;background-color: #0667BA;color:#fff">
   <p style="font-size: 20px; margin: 0; padding: 0; margin-bottom: 10px; font-weight: bold;  ">Hello {{PracticeName}} Administrator,</p>
   <p style="font-size: 18px; margin: 0; padding: 0; margin-bottom: 10px;"> The RCM administrators have processed an insurance verification and wanted to share information with you regarding the following insurance and patient:</p>
   <div style="background:#fff;color:#FFB64D;border-radius:10px;padding:15px;margin-bottom:15px">
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Patient Name: <b style="margin-left:3px;">{{PatientLastName}}, {{PatientFirstName}}</b></p>
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Company Name: <b style="margin-left:3px;">{{InsuranceCompanyName}}</b></p>
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Practice Name: <b style="margin-left:3px;">{{PracticeName}}</b></p>
   </div>
   <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left;">If you have any questions or need further assistance, please feel free to contact us.</p>
   <div style="text-align: center; padding-top: 10px; border-top:1px solid #fff">
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: center;"><b>Thank you for your attention.</b></p>
      <div style="clear:both"></div>
      <div style="width:100%;float:right">
         <p style="margin: 0; padding: 0; margin-bottom: 5px; text-align: right;">Best regards,</p>
         <p style="margin: 0; padding: 0;text-align: right;"><b>RCM Administrators</b></p>
      </div>
      <div style="clear:both"></div>
   </div>
</div>
</div>`

export const paymentFollowupEmailSubject = "Past Due"
export const insurancePaymentFollowupHtmlContentToPractice =`<div style="width:550px; margin: 0 auto; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #222; background-color:#f1f1f1; border:0.5px solid #0667BA; ">
<div style="padding: 20px 15px; color:#0667BA; padding-bottom: 0; text-align: center;">
   <h1 style="font-size: 30px; margin: 0; padding: 0; text-align: center;margin-bottom:15px">RCM Payment Due Notification</h1>
   <img style="width: 30%;" src="https://demo.orthopms.com/assets/email-templates/opms-appointment-reminder.png">
</div>
<div style="padding: 20px 15px;background-color: #0667BA;color:#fff">
   <p style="font-size: 20px; margin: 0; padding: 0; margin-bottom: 10px; font-weight: bold;  ">Hello Practice Administrator,</p>
   <p style="font-size: 18px; margin: 0; padding: 0; margin-bottom: 10px;"> This is to inform you that the payment due for the following patient has been notified:</p>
   <div style="background:#fff;color:#FFB64D;border-radius:10px;padding:15px;margin-bottom:15px">
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Patient Name: <b style="margin-left:3px;">{{PatientLastName}}, {{PatientFirstName}}</b></p>
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Practice Name: <b style="margin-left:3px;">{{PracticeName}}</b></p>
   </div>
   <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left;">If you have any questions or need further assistance, please feel free to contact us.</p>
   <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left;font-weight:bold;font-size:16px">Practice Information:</p>
   <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: left;">Contact Number: <b style="margin-left:3px;"><span> {{PracticeMainPhoneNumber}} </span></b> </p>
   <div style="text-align: center; padding-top: 10px; border-top:1px solid #fff">
      <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: center;"><b>Thank you for your attention.</b></p>
      <div style="clear:both"></div>
      <div style="width:100%;float:right">
         <p style="margin: 0; padding: 0; margin-bottom: 5px; text-align: right;">Best regards,</p>
         <p style="margin: 0; padding: 0;text-align: right;"><b>RCM Administrators</b></p>
      </div>
      <div style="clear:both"></div>
   </div>
</div>
</div>`

export const chatActionGenericKeys  = {
   contact: 'contact',
   chat: 'chat',
   unread: 'unread',
   recent: 'recent'
};