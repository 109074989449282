// Angular Import
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RouteChangeService } from './features/shared/services/route-change-service';
import config from 'devextreme/core/config'; 
import { environment } from "src/environments/environment";
declare const dialerWireEvents: any;

const DEVEXPRESS_LICENCE_KEY = environment.devexpress.licenceKey;
config({ licenseKey:DEVEXPRESS_LICENCE_KEY });

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {
  private subscriptions$ = new Subscription();
  subscription!: Subscription;

  // constructor
  constructor(private router: Router, private routeChangeService : RouteChangeService) { }
  // life cycle event
  ngOnInit() {
    this.subscriptions$.add(this.router.events.subscribe((evt: any) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    }));
    
    dialerWireEvents();
  }
  
  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
    this.routeChangeService.unsubscribe();
  }

}
