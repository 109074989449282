import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "./auth.service"
import { inject } from '@angular/core';
import { UserRoleService } from '../user-role.service';

export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const userRoleService = inject(UserRoleService);
    const router = inject(Router);
    const token = authService.getToken();
    
    if (!token) {
        router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    if (state.url === '/') {
        const permission = authService.getUserRolesAndPermission();
        userRoleService.loadLandingPageByUserRolePermission(authService.getUserRoleFromToken(), permission)
    }
    return true;
}
