import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chat-skeleton',
  template: `
  <div>
    <ng-container *ngFor="let count of repeatHtmlElement(fields)">
        <div class="d-flex justify-content-end mt-2 pe-3">
        <div class="chat-Inbound-width wid-150">
          <div class="skeleton p-0">
              <div class="line m-b-5 w-100 hei-40 chat-outgoing" style="height:60px"></div>
          </div>
        </div>
      </div>
      <div class="chat-outbound-width wid-150 ps-2">
        <div class="skeleton p-0">
          <div class="line m-b-5 w-100 hei-40 chat-ingoing" style="height:60px"></div>
        </div>
      </div>
    </ng-container>
  </div>
`,
styles: [`
.chat-outgoing{border-radius: 10px 0px 10px 10px;}
.chat-ingoing{border-radius: 0px 10px 10px 10px;}
  `]
})
export class ChatSkeletonComponent {
  @Input() fields: number | undefined;

  repeatHtmlElement(count: number | undefined): Array<number> {
    return Array(count);
  }
}
