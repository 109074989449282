import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class HtmlbuildService {

    replacePlaceholders(htmlString: string, data: { [key: string]: string }): string {
        return htmlString.replace(/\{\{(.*?)\}\}/g, (match, key) => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                return data[key];
            }
            return match;
        });
    }

    addHeadersAndStyleInHtml(bodyContent: string, componentStyles: string) {
        return `<!DOCTYPE html>
                <html>
                   <head>
                   <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>   
                     <meta http-equiv='content-type' content='text/html; charset=utf-8' />
                     <style>
                       ${componentStyles}
                     </style>
                   </head>
                   <body class='pdfContent'>
                     ${bodyContent}
                   </body>
                </html>`
    }
}