import { createReducer, on } from '@ngrx/store';
import { AuthUser } from '../state/auth-user';
import { logIn, logInFailure, logInSuccess, logoutFailure, logoutSuccess, resetErrorMessage, validateEmail, validateEmailFailure, validateEmailSuccess } from '../actions/authentication.action';

export const initialState: Readonly<AuthUser> = {
  valid: false,
  email: null,
  accessToken: null,
  refreshToken: null,
  authStateEvents: {
    errorMessage: "",
    isFormSubmitted: false,
  },
  errorMessage: undefined,
  validUserInfo:{
    email:"",
    isDefaultRole:false,
    isValid: false,
    userRoleGuid:"",
    userRoleName:""
  }
};

export const AuthenticationReducer = createReducer(
  initialState,
  on(logInSuccess, (state, { authUser }): AuthUser => {
    return {
      valid: authUser.valid,
      email: authUser.email,
      accessToken: authUser.accessToken,
      refreshToken: authUser.refreshToken,
      authStateEvents: {
        errorMessage: authUser.errorMessage ? authUser.errorMessage : "",
        isFormSubmitted: false,
      },
      errorMessage:  authUser.errorMessage,
      validUserInfo: initialState.validUserInfo
    }
  }),
  on(logInFailure, (state, { error }): AuthUser => {
    return {
      ...state,
      accessToken: null,
      refreshToken: null,
      authStateEvents: {
        errorMessage: error ?? 'The user name and password are invalid.',
        isFormSubmitted: false,
      }
    };
  }),
  on(logIn, (state): AuthUser => ({
    ...state,
    authStateEvents: {
      ...state.authStateEvents,
      isFormSubmitted: true
    }
  })),
  on(logoutSuccess, (): AuthUser => initialState),

  on(logoutFailure, (state, { error }): AuthUser => {
    return {
      ...state,
      authStateEvents: {
        errorMessage: error ?? 'Failed to logout.',
        isFormSubmitted: false,
      }
    };
  }),
  
  on(validateEmailSuccess, (state, action): AuthUser => ({
    ...state,
    validUserInfo: action.user,
    authStateEvents: {
      ...state.authStateEvents,
      errorMessage: !action.user.isValid ? 'The user name is invalid.' : "",
    }
})),
 
  on(validateEmailFailure, (state, { error }): AuthUser => {
    return {
      ...state,
      validUserInfo : initialState.validUserInfo,
      isValidUserEmail: false,
      authStateEvents: {
        ...state.authStateEvents,
        errorMessage: error ?? 'The user name validation was failed.',
      }
    };
  }),
  on(validateEmail, (state, { username }): AuthUser => {
    return {
      ...state, isValidUserEmail: false, email: username
    }
  }),
  on(resetErrorMessage, (state): AuthUser => ({
    ...state,
    authStateEvents: {
      ...state.authStateEvents,
      errorMessage: ''
    }
  })),
);