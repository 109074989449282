import { Appointments } from "../../scheduler/store/scheduler-store/scheduler-state"
import { appointmentStatus } from "../../shared/constant/shared-constant"

export const patientLiveStatus = {
    stage1: "Scheduled",
    stage2: "Check-In",
    stage3: "Seated",
    stage4: "Check-Out",
    stage5: 'Dismissed'
}

export interface patientLiveStatusPanel {
    value: string,
    label: string,
    shorthandLabel: string,
    appointments: Appointments[],
    image: string,
    className: string
}

export const patientLiveStatusPanels: patientLiveStatusPanel[] = [
    {
        value: appointmentStatus.active,
        label: patientLiveStatus.stage1,
        shorthandLabel: "Scheduled",
        image: "assets/images/scheduler-icon.png",
        className: "bg-success",
        appointments: [],
    },
    {
        value: appointmentStatus.checkIn,
        label: patientLiveStatus.stage2,
        shorthandLabel: "Check-in",
        image: "assets/images/check-in-icon.png",
        className: "bg-danger",
        appointments: []
    },
    {
        value: appointmentStatus.seated,
        label: patientLiveStatus.stage3,
        shorthandLabel: "Seated",
        image: "assets/images/chair-icon.png",
        className: "bg-primary",
        appointments: []
    },
    {
        value: appointmentStatus.checkout,
        label: patientLiveStatus.stage4,
        shorthandLabel: "Check-out",
        image: "assets/images/dismissed-icon.png",
        className: "bg-secondary",
        appointments: []
    },
    {
        value: appointmentStatus.completed,
        label: patientLiveStatus.stage5,
        shorthandLabel: "Dismissed",
        image: "assets/images/dismissed-icon.png",
        className: "bg-secondary",
        appointments: []
    }
]

export const checkInStatus = {
    early: 'early',
    late: 'late',
    onTime: 'on time'
}

export interface chairLiveStatusPanel {
    practiceChairId: number,
    chairName: string,
    chairDescription: string,
    isActive: boolean,
    appointments: Appointments[]
}

export const chairStatusMessages = {
    appointmentExistsAlertMessage: "The live chair already has an appointment. You can't move it. Please choose a different live chair.",
}

export const liveLocationStorageKey = "liveLocationId"

export const placeholderChairColor = [
    {
        color:'linear-gradient(to left, #d6d8d9 0%, #e8dbd0 100%), radial-gradient(88% 271%, #E8DBD0 0%, #E8DBD0 1%, #E8DBD0 100%), radial-gradient(29% 100%, #E8DBD0 0%, #E8DBD0 100%)'
    },
    {
        color:'linear-gradient(to left, #e3bdf5 0%, #d5f7f012 100%), radial-gradient(88% 271%, #D5F7F0 0%, #D5F7F0 1%, #D5F7F0 100%), radial-gradient(29% 100%, #D5F7F0 0%, #D5F7F0 100%)'
    },
    {
        color:'linear-gradient(to left, #D9EBFF 0%, #F8C1DA 100%), radial-gradient(88% 271%, #F8C1DA 0%, #F8C1DA 1%, #E8DBD0 100%), radial-gradient(29% 100%, #F8C1DA 0%, #F8C1DA 100%)'
    },
    {
        color:'linear-gradient(to left, #d5f7f0 0%, #E8DBD0 100%), radial-gradient(88% 271%, #E8DBD0 0%, #E8DBD0 1%, #E8DBD0 100%), radial-gradient(29% 100%, #E8DBD0 0%, #E8DBD0 100%)'
    },
    {
        color:'linear-gradient(to left, #D5BDFB 0%, #D6D8D9 100%), radial-gradient(88% 271%, #D6D8D9 0%, #D6D8D9 1%, #D6D8D9 100%), radial-gradient(29% 100%, #D6D8D9 0%, #D6D8D9 100%)'
    },
    {
        color:'linear-gradient(to left, #ffbd5c54 0%, #D7CBEE 100%), radial-gradient(88% 271%, rgb(232, 219, 208) 0%, #D7CBEE 1%, #D7CBEE 100%), radial-gradient(29% 100%, #D7CBEE 0%, #D7CBEE 100%)'
    },
    {
        color:'linear-gradient(to left, #fedce1 0%, rgb(232, 219, 208) 100%), radial-gradient(88% 271%, #FFF0DB 0%, #FFF0DB 1%, #FFF0DB 100%), radial-gradient(29% 100%, #FFF0DB 0%, #FFF0DB 100%)'
    }
]

export const flowBarAppointmentsSortBy = {
    appointmentTime: 'By Appointment Time',
    checkInTime: 'By Check in Time'
}