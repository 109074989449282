import { Component, OnInit, HostListener, HostBinding, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { State } from "@progress/kendo-data-query";
import { GridDataResult, DataStateChangeEvent, SelectAllCheckboxState } from "@progress/kendo-angular-grid";
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { PatientBasicInfoModel, PatientSearchFilterModel, PatientSearchResultModel, PatientSearchStateEvent } from '../../types/patient-search-type';
import { selectPatientSearchFilter, selectPatientSearcResult, selectPatientSearchStateEvent, selectBulkEmailPatientSearchFilter, selectBulkEmailPatientSearchStateEvent, selectBulkEmailPatientSearcResult, selectBulkEmailBasicPatientSearcResult } from '../../store/selectors/patient-search.selector';
import { invokedBulkBasicPatientSearchAPI, invokedBulkEmailPatientSearchAPI, invokedPatientSearchAPI } from '../../store/actions/patient-search.action';
import { patientDashboardNavigateAsset, patientSearchTypes } from '../../constant/patient-dashboard-api-asset';
import { selectedPatientId } from 'src/app/theme/store/menu/menu.actions';
import { PopupService } from '@progress/kendo-angular-popup';
import { IconsService } from '@progress/kendo-angular-icons';
import { ResizeBatchService } from '@progress/kendo-angular-common';
import { NavigateURLService } from 'src/app/features/shared/services/navigate-url.service';
import { ScreenSizeService } from 'src/app/features/shared/services/screen-size.service';
import { deviceWidth } from 'src/app/features/shared/constant/shared-constant';


@Component({
  providers: [
    PopupService,IconsService,ResizeBatchService
  ],
  selector: 'app-patient-search-result',
  templateUrl: './patient-search-result.component.html',
})

export class PatientSearchResultComponent implements OnInit, OnDestroy {
  @Input() allowToSelectPatient = false;
  @Input() pageIdentifier = patientSearchTypes.patientSearch;
  @Output() selectionChange = new EventEmitter<PatientBasicInfoModel[]>();
  //kendo grid settings
  public state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "patientId", dir: "asc" }]
  };
  public sizes = [10, 20, 50, 100];
  public buttonCount = 3;
  public isLoading = false;
  public isPageLoading = false;
  patientSearchType = patientSearchTypes;
  isResponsive = false;
  isDesktop = false;

  public patientSearchData: GridDataResult = { data: [], total: 0 };
  patientSearchFilter: FormGroup;

  patientSearchFilter$ = this.store.select(selectPatientSearchFilter);
  isLoading$ = this.store.select(selectPatientSearchStateEvent);
  patientSearcResult$ = this.store.select(selectPatientSearcResult);
  
  bulkEmailPatientSearchFilter$ = this.store.select(selectBulkEmailPatientSearchFilter);
  bulkEmailPatientSearcResult$ = this.store.select(selectBulkEmailPatientSearcResult);
  bulkStateEvent$ = this.store.select(selectBulkEmailPatientSearchStateEvent);

  bulkEmailBasicPatientSearcResult$ = this.store.select(selectBulkEmailBasicPatientSearcResult);


  private subscriptions$ = new Subscription();

  @Output() closeSearchResultOverlay = new EventEmitter<boolean>();
  selectedPatientIds: number[] = []; 
  selectedPatientsBasicInfo: PatientBasicInfoModel[] = []; 
  selectAllPatientBasicInfo?: PatientBasicInfoModel[];
 
  constructor(private store: Store,
    private formBuilder: FormBuilder,
    private router: Router,
    private navigateURLService: NavigateURLService,
    private screenSizeService: ScreenSizeService) {
    this.patientSearchFilter = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      patientName: [''],
      patientPreferredName: [''],
      patientDOB: [''],
      patientPrimaryPhone: [''],
      patientPrimaryEmailAddress: [''],
      patientFinancialResponsiblePersonFirstName: [''],
      patientFinancialResponsiblePersonLastName: [''],
      patientFinancialResponsiblePerson: [''],
      patientStatusId: [''],
      selectedPatientStatusId: [[]],
      practiceLocationId: [''],
      selectedPracticeLocations: [[]],
      SortOrder: [''],
      SortColumn: [''],
      tackAfter: [''],
      pageSize: [''],
      patientId: [''],
      showInActivePatients: [false],
      filter: [null],
      fromDate: [''],
      toDate: [''],
      fromDays: [''],
      toDays: [''],
    });
  }

  ngOnInit(): void {
    const bulkEmail =patientSearchTypes.bulkEmailPatientSearch;
    if(this.pageIdentifier === bulkEmail){
      this.subscriptions$.add(this.bulkEmailPatientSearchFilter$.subscribe(async (data: PatientSearchFilterModel) =>await this.bindPatientSearchFilterInformation(data)))
      this.subscriptions$.add(this.bulkStateEvent$.subscribe(async (data: PatientSearchStateEvent) => this.handlePatientSearchStateEvent(data)));
      this.subscriptions$.add(this.bulkEmailBasicPatientSearcResult$.subscribe(async (data?: PatientBasicInfoModel[]) => this.bindSelectAllInformation(data)));
      this.sizes = [10, 20, 50, 100];
    }
    else{
      this.subscriptions$.add(this.patientSearchFilter$.subscribe(async (data: PatientSearchFilterModel) => await this.bindPatientSearchFilterInformation(data)))
      this.subscriptions$.add(this.isLoading$.subscribe(async (data: PatientSearchStateEvent) => this.handlePatientSearchStateEvent(data)));
    }
    this.updateScreenWidth();
    this.screenSizeService.screenWidth$.subscribe((screenInfo) => {
      if (screenInfo[0] <= deviceWidth.desktop ) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
      }
    });
  }

  
  loadPatientSearchInformation(state: State, formSubmitted=true): void {
    this.isPageLoading = true;
    const takeValue = state.take || 10;
    if (state.sort && state.sort.length > 0) {
      this.patientSearchFilter.patchValue({
        SortOrder: state.sort[0].dir,
        SortColumn: state.sort[0].field,
        pageSize: state.take,
        tackAfter: state.skip ? (state.skip / takeValue) + 1 : 1,
      })
    }
    const bulkEmail =patientSearchTypes.bulkEmailPatientSearch;
    if (this.pageIdentifier === bulkEmail) {
      if(formSubmitted) this.store.dispatch(invokedBulkEmailPatientSearchAPI({ patientSearchFilter: this.patientSearchFilter.value, pageIdentifier: this.pageIdentifier }));
      
      this.subscriptions$.add(this.bulkEmailPatientSearcResult$.subscribe(async (patientSearchResults: PatientSearchResultModel[]) => {
        this.patientSearchData = { data: patientSearchResults && patientSearchResults.length > 0 ? patientSearchResults : [], total: patientSearchResults && patientSearchResults.length > 0 ? patientSearchResults[0].totalPatients : 0 };
        this.isPageLoading = false;
      }));
    }
    else {
      this.store.dispatch(invokedPatientSearchAPI({ patientSearchFilter: this.patientSearchFilter.value, pageIdentifier: this.pageIdentifier }));
      this.subscriptions$.add(this.patientSearcResult$.subscribe(async (patientSearchResults: PatientSearchResultModel[]) => {
        this.patientSearchData = { data: patientSearchResults && patientSearchResults.length > 0 ? patientSearchResults : [], total: patientSearchResults && patientSearchResults.length > 0 ? patientSearchResults[0].totalPatients : 0 };
        this.isPageLoading = false;
      }));
    }
  }

  async handlePatientSearchStateEvent(patientSearchStateEvent: PatientSearchStateEvent) {
    this.isLoading = patientSearchStateEvent.loading || false;
  }

  async bindPatientSearchFilterInformation(data: PatientSearchFilterModel) {
    if (data) {
      this.patientSearchFilter.patchValue(data)
      this.state = {
        skip: 0,
        take: 10,
        sort: [{ field: "patientId", dir: "asc" }]
      };
      this.selectedPatientIds=[];
      this.loadPatientSearchInformation(this.state, data.formSubmitted??false);
    }
  }

  public dataStateChange(stateChange: DataStateChangeEvent): void {
    this.state = stateChange;
    if (this.state.sort) {
      this.state.sort = this.state.sort.map((s) => ({
        ...s,
        dir: s.dir ?? 'asc',
      }));
    }
    this.loadPatientSearchInformation(this.state);
  }

  //resposive table
  @HostListener('window:resize')
  onWindowResize(): void {
    this.updateScreenWidth();
  }

  @HostBinding('class.navigable')
  get navigableClass(): boolean {
    return this.isResponsive;
  }

  updateScreenWidth(): void {
    const screenWidth = window.innerWidth;
    this.isResponsive = screenWidth < 1025;
  }

  goToPatientSummary(patientId: number) {
    this.router.navigate([patientDashboardNavigateAsset.goToPatientSummary, patientId,'patientsummary',patientId]);
    this.store.dispatch(selectedPatientId({patientId}));
    this.closeSearchResultOverlay.emit(true);
  }

  redirectToPatientSummaryDashboardRoute(patientId: number){
    return this.navigateURLService.getPatientSummaryURL(patientId);
  }

  private updateSelectedPatients(data: PatientBasicInfoModel[]): void {
    const newSelectedPatients = data.filter(
      (patient) => this.selectedPatientIds.includes(patient.patientId) &&
      !this.selectedPatientsBasicInfo.some((info) => info.patientId === patient.patientId)
    );
    this.selectedPatientsBasicInfo = [...this.selectedPatientsBasicInfo, ...newSelectedPatients];
    this.selectedPatientsBasicInfo = this.selectedPatientsBasicInfo.filter(
      (patient) => this.selectedPatientIds.includes(patient.patientId)
    );
  }
  
  onSelectionChange(): void {
    this.updateSelectedPatients(this.patientSearchData.data);
    this.selectionChange.emit(this.selectedPatientsBasicInfo);
  }
  
  bindSelectAllInformation(data?: PatientBasicInfoModel[]): void {
    if (data && data.length > 0) {
      this.selectedPatientIds = data.map((x) => x.patientId);
      this.updateSelectedPatients(data);
      this.selectionChange.emit(this.selectedPatientsBasicInfo);
    }
  }
  
  onSelectAllChange(checkedState: any): void {
      if (checkedState === "checked") {
        this.store.dispatch(invokedBulkBasicPatientSearchAPI({ patientSearchFilter: this.patientSearchFilter.value, pageIdentifier: this.pageIdentifier }));
      } else {
        this.selectedPatientIds = [];
        this.selectedPatientsBasicInfo=[]
        this.onSelectionChange()
      }
    }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
