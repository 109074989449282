import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

    transform(value: string): string {
        if (!value) return '';

        const dateParts = value.split(/[- :\/]/);
        const dateObject = new Date(+dateParts[2], +dateParts[0] - 1, +dateParts[1], +dateParts[3], +dateParts[4]);

        const seconds = Math.floor((+new Date() - +dateObject) / 1000);

        if (seconds < 60) {
            //return seconds + ' sec ago';
            return 'now';
        }

        const minutes = Math.floor(seconds / 60);

        if (minutes < 60) {
            return minutes + (minutes === 1 ? ' min ago' : ' mins ago');
        }

        const hours = Math.floor(minutes / 60);

        if (hours < 24) {
            // return hours + (hours === 1 ? ' hour ago' : ' hours ago');
            return this.formatTime(dateObject)
        }

        const days = Math.floor(hours / 24);

        if (days === 1) {
            return 'Yesterday ' + this.formatTime(dateObject);
        }

        if (days < 7) {
            return this.formatDay(dateObject) + ' ' + this.formatTime(dateObject);
        }

        return this.formatDate(dateObject) + ', ' + this.formatTime(dateObject);
    }

    private formatTime(date: Date): string {
        let hours = date.getHours();
        let minutes: string | number = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return hours + ':' + minutes + ' ' + ampm;
    }

    private formatDay(date: Date): string {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[date.getDay()];
    }

    private formatDate(date: Date): string {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        return month + ' ' + day;
    }

}
