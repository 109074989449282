import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-control-submit',
  template: `
        <div class="col-lg-12 p-0" [formGroup]="parentForm">
          <button type="button" *ngIf="cancelButton" (click)="cancelButtonClicked.emit()"
          class="btn btn-outline-primary me-3">{{cancelButtonText}}</button>

          <button class="btn btn-submit"  [ngClass]="submittedBtnCssClass ? submittedBtnCssClass : 'btn-light-primary'"  type="submit"  (click)="onClick.emit()" [disabled]="isSubmitted">
            <span *ngIf="isSubmitted" class="spinner-border spinner-border-sm me-1"></span>
            <span *ngIf="!isSubmitted">{{labelName}}</span>
            <span *ngIf="isSubmitted">Loading...</span>
          </button>
        </div>
`,
})

export class FormControlSubmitComponent {
  @Input() labelName!: string;
  @Input() parentForm!: FormGroup;
  @Input() isSubmitted: boolean | undefined;
  @Input() submittedBtnCssClass =  'btn-light-primary' ;

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>()

  @Input() cancelButton!: boolean;
  @Output() cancelButtonClicked = new EventEmitter<boolean>();
  @Input() cancelButtonText!: string;

  constructor() {
    this.cancelButtonText = 'Cancel';
  }
}
